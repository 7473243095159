import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import AssignmentSyncReminder from "../../components/LessonAssignments/AssignmentSyncReminder";
import AssignmentsTable from "../../components/LessonAssignments/AssignmentsTable";
import CreateAssignmentModal from "../../components/LessonAssignments/CreateAssignment/CreateAssignmentModal";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";
import FA from "../fa";
import config from "../../common/config";
import { getUserSubscriptions } from "../../common/helpers";

import { lessonViewerWindowRef as lessonViewerWindowRefA } from "../../redux/actions";
import { openRequestedTab, lessonViewerMainPageUrl } from "../../services/http";

const mapStateToProps = (state, ownProps?) => ({
  lessonViewerWindowRef: state.gui.lessonViewerWindowRef,
});

const LessonAssignments = ({
  history,
  api,
  lessonViewerWindowRef,
  dispatch,
}) => {
  const [creatingAssignment, setCreatingAssignment] = useState(false);
  const [assignmentsData, setAssignmentsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [numberOfAssignments, setNumberOfAssignments] = useState(0);
  const [endIndex, setEndIndex] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [showSyncReminder, setShowSyncReminder] = useState(false);
  const [expandedAssignments, setExpandedAssignments] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [hasLCTSubscription, setHasLCTSubscription] = useState(0);

  /**
   * fetchAssignments will fetch assignments to display based on page, filter,
   * and # per page settings. Sorting is done via backend.
   */
  const fetchAssignments = () => {
    setLoading(true);
    setErrorMsg("");
    api
      .fetchAssignments(numberPerPage, currentPage, filter)
      .then((res) => {
        console.log("fetch assignments res:", res);
        setAssignmentsData(res.results);
        setStartIndex(res.start_index);
        setEndIndex(res.end_index);
        setNumberOfAssignments(res.count);
        setLoading(false);

        if (initialLoad) {
          setInitialLoad(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setErrorMsg(
          "An error occurred while fetching assignments. Please try again later."
        );
      });
  };

  useEffect(() => {
    const checkForLCTSubscription = async () => {
      try {
        const subscriptions = await getUserSubscriptions({ api });
        const hasLCTSub = subscriptions.includes("lct");
        setHasLCTSubscription(hasLCTSub);
      } catch (error) {
        console.error("Error fetching user subscriptions:", error);
      }
    };

    checkForLCTSubscription();
  }, []);

  useEffect(() => {
    fetchAssignments();
  }, [numberPerPage, currentPage, filter]);

  return (
    <div>
      <MainDashboard>
        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <p>Lesson Assignments</p>
            <div className='d-flex justify-content-end'>
              <button
                className='btn btn-primary btn-sm'
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </button>
            </div>
          </div>
          <div className='common_table_padding'>
            {showSyncReminder && (
              <AssignmentSyncReminder shouldShowFunc={setShowSyncReminder} />
            )}

            {creatingAssignment && (
              <CreateAssignmentModal
                shouldShowFunc={setCreatingAssignment}
                fetchAssignments={fetchAssignments}
                showSyncReminder={showSyncReminder}
                setShowSyncReminder={setShowSyncReminder}
              />
            )}

            {errorMsg ? (
              <div className='alert alert-danger' role='alert'>
                {errorMsg}
              </div>
            ) : null}

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 180px 180px 180px",
                width: "100%",
              }}
            >
              <div>
                <p style={{ margin: 0 }}>
                  View existing groups of assigned lessons for your learners, or
                  create new ones.
                </p>
                <p style={{ margin: "10px 0 0 0" }}>
                  Click on an assignment to expand it. You can view scores by
                  learners or lessons.
                </p>
              </div>

              <button
                onClick={() => {
                  window.open("https://mylessons.abiis-world.com/", "_blank");
                }}
                assignment-data-title={
                  !hasLCTSubscription
                    ? "Requires an active LCT subscription."
                    : null
                }
                style={{ height: "fit-content", position: "relative" }}
                className={`btn btn-sm mr-1 ${
                  hasLCTSubscription ? "btn-primary" : "btn-secondary"
                }`}
                disabled={!hasLCTSubscription}
              >
                Create a new lesson...&nbsp;
                <FA icon='external-link-alt' color='white' />
              </button>

              <button
                style={{ height: "fit-content" }}
                className='btn btn-primary btn-sm'
                onClick={() => {
                  const tabRef = openRequestedTab({
                    url: lessonViewerMainPageUrl(),
                    windowRef: lessonViewerWindowRef,
                    windowName: config.LESSONVIEWER_WINDOW_NAME,
                  });
                  dispatch(lessonViewerWindowRefA(tabRef));
                }}
              >
                Preview Lessons...&nbsp;
                <FA icon='external-link-alt' color='white' />
              </button>
              <button
                style={{ height: "fit-content" }}
                className='btn btn-primary btn-sm ml-1'
                onClick={() => {
                  setCreatingAssignment(true);
                }}
              >
                Add new assignment...
                <FA icon='plus' color='white' />
              </button>
            </div>

            <AssignmentsTable
              initialLoad={initialLoad}
              fetchAssignments={fetchAssignments}
              assignmentsData={assignmentsData}
              setAssignmentsData={setAssignmentsData}
              loading={loading}
              filter={filter}
              setFilter={setFilter}
              numberPerPage={numberPerPage}
              setNumberPerPage={setNumberPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              startIndex={startIndex}
              endIndex={endIndex}
              numberOfAssignments={numberOfAssignments}
              showSyncReminder={showSyncReminder}
              setShowSyncReminder={setShowSyncReminder}
              expandedAssignments={expandedAssignments}
              setExpandedAssignments={setExpandedAssignments}
            />
          </div>
        </div>
      </MainDashboard>
    </div>
  );
};

export default compose(connect(mapStateToProps)(withAPI(LessonAssignments)));
