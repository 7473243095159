import React from "react";
import FAW from "../../../../../containers/faw";

/**
 * LearnerActions is the list of buttons available for the learner. This is not
 * guaranteed to be only one button in the future.
 */
const LearnerActions = ({
  learner,
  sid,
  nameVals,
  setSelectedSearchVal,
  setActiveTab,
  setViewLearnerInClass,
}) => {
  return (
    <div className='actions actions_container'>
      <p style={{padding: "5px"}}>
        <button
          className='btn btn-sm btn-primary actions_reports_btn'
          data-title='View Reports'
          onClick={() => {
            setSelectedSearchVal({
              ...learner,
              type: "learner",
              label: `${nameVals.first_name} ${nameVals.last_name}`,
              value: sid,
            });
            setActiveTab("learner");
            setViewLearnerInClass(true);
          }}
        >
          <FAW icon='chart-bar' />
        </button>
      </p>
    </div>
  );
};

export default LearnerActions;
