import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from "react-helmet";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import config from '../common/config'
import {
  robotType as robotTypeA,
} from '../redux/actions';

import withAPI from '../services/api';
import FA from '../containers/fa';

// import howtoAccount from '../assets/images/howto_account.png';
// import howtoWifi from '../assets/images/howto_wifi.png';

import Gate from '../partials/Gate';
import './Welcome.css';


const Welcome = ({api, dispatch}) => {

  const [robotType, setRobotType] = useState(null);

  function jsonToURI(json) {
    return encodeURIComponent(JSON.stringify(json));
  }
  function uriToJSON(urijson) {
    return JSON.parse(decodeURIComponent(urijson));
  }

  const getRobotType = () => new Promise( (resolve) => {
    let tempRobotType = sessionStorage.getItem("robotType") || null;
    if (tempRobotType !== config.ABII_HOME && tempRobotType !== config.ABII_SCHOOL) {
      api.fetchRobotType().then((resp_json) => {
        tempRobotType = resp_json.robot_type;
        sessionStorage.setItem("robotType", tempRobotType);
        dispatch(robotTypeA(tempRobotType))
        setRobotType(tempRobotType)
        resolve('done');
      }).catch(err => {
        console.error(err)
        resolve('done');
      })
    } else {
      dispatch(robotTypeA(tempRobotType));
      setRobotType(tempRobotType)
      resolve('done');
    }
  });


  const [shouldShowLoginButton, setShouldShowLoginButton] = useState(false);

  useEffect(() => {
    if (config.isDevelopment) {
      setShouldShowLoginButton(true);
    } else if (config.usingHTTPS) {
      // Not catching error here - worst case
      // the video doesn't show.
      // Check is enforced on login page though
      getRobotType().then(() => {
        setShouldShowLoginButton(true);
      })
    } else {
      setShouldShowLoginButton(false);
    }
  }, []);


  // currently put both here in casewe get here before
  // robotType is loaded
  let isSchoolVersion = robotType===config.ABII_SCHOOL;
  let isHomeVersion = robotType===config.ABII_HOME;


  // For Login with Google button
  const handleGoogleLogin = useCallback(async () => {
    const Google = {
      CLIENT_ID: '127288955795-njqka4j2odpr3j40rqcmcr32mbn5ju1v.apps.googleusercontent.com',
      SCOPE: 'profile email',
      REDIRECT_URI: 'http://localhost:3000/sso-landing/',
    }
    const qParams = [
      `client_id=${Google.CLIENT_ID}`,
      `redirect_uri=${Google.REDIRECT_URI}`,
      `scope=${Google.SCOPE}`,
      `prompt=consent`,
      `state=google`,
      `response_type=code`,
    ].join("&");

    try {
      //const url = `https://accounts.google.com/o/oauth2/auth?${qParams}`;
      const url = `http://localhost:3000/google-login/?state=eyJzb21la2V5Ijoic29tZXZhbHVlIn0=`
      window.location.assign(url);
    } catch (e) {
      console.error(e);
    };
  }, []);


  return (
    <Gate boxWidthClass="col-md-6">
      <div className="login_box form-content container form-group">
        <div className="row">
          <div className="col">
            <h1 style={{"textAlign": "center"}}>Welcome!</h1>
          </div>
        </div>
        {!shouldShowLoginButton &&
          <div>
            <div className="row">
              <h4 style={{"margin": "24px auto"}}>
                <FA color="gray" icon="spinner" spin />
                <span style={{"margin": "24px"}}>Loading ...</span>
              </h4>
            </div>
            <div className="row">
              <p style={{"margin": "16px auto"}}>This may take a minute if ABii doesn't have access to wifi ...</p>
            </div>
          </div>
        }
        {shouldShowLoginButton &&
          <div>
            
            <div className="row btns-row">
              <div className="col-12 text-center">
                <Link className="btn btn-lg fs-14 btn-primary" to="/login" style={{"width": "80%", "marginBottom": "20px"}}>
                  Log in
                </Link>
              </div>
            </div>

            {/*
            <div className="row btns-row">
              <div className="col-12 text-center">
                <button className="btn btn-lg btn-primary" onClick={handleGoogleLogin}>
                  {"Login with Google"}
                </button>
              </div>
            </div>
            */}

            <div className="row shutdown-row">
              <div className="col-12 text-center">
		            <p> If you are trying to log into your robot, you must first connect to the wifi network for that robot.</p>
              </div>
            </div>

          </div>
        }
      </div>
    </Gate>
  );
}

export default compose(
  withAPI
)(Welcome);
