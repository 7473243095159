import { MenuItem, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import React, { useEffect, useState, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import config from "../../common/config";
import { useDebounce } from "use-debounce";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import CreateEditContactModal from "./CreateEditContactModal";
import DeleteContactModal from "./DeleteContactModal";
import CreateSuperAdminSchoolModal from "./Schools/CreateSuperAdminSchoolModal";
import "./OrgContactsModal.scss";

const mapStateToProps = (state, ownProps?) => ({});

const OrgContactsModal = ({
  api,
  org,
  isDashboardPage = false,
  setOrgContactsViewingId,
  setSchoolRefreshFromContacts,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [filterString, setFilterString] = useState("");
  const [debouncedFilterString] = useDebounce(filterString, 1000);
  const [filterSchool, setFilterSchool] = useState("");
  const [filterRole, setFilterRole] = useState("");
  const [filteredOtherContacts, setFilteredOtherContacts] = useState([]);
  const [contactToEdit, setContactToEdit] = useState(null);
  const [creatingNewContact, setCreatingNewContact] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [primaryContact, setPrimaryContact] = useState({});
  const [fetchingContacts, setFetchingContacts] = useState(false);
  const [otherContacts, setOtherContacts] = useState([{}]);
  const [schoolsInOrg, setSchoolsInOrg] = useState([]);
  const [showingMoreNotes, setShowingMoreNotes] = useState([]);
  const [showCreateSchoolModal, setShowCreateSchoolModal] = useState(false);

  const fetchContacts = () => {
    setFetchingContacts(true);
    api
      .fetchContacts(org.id)
      .then((resp_json) => {
        console.log("Response:", resp_json);
        if (resp_json.length < 1) {
          return;
        }
        let newOtherContacts = [];
        for (let i = 0; i < resp_json.length; i++) {
          if (resp_json[i].primary_contact) {
            setPrimaryContact(resp_json[i]);
          } else {
            newOtherContacts = [...newOtherContacts, resp_json[i]];
          }
        }
        setOtherContacts(newOtherContacts);
        setFetchingContacts(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchingContacts(false);
      });
  };

  const fetchAdminSchools = () => {
    api
      .fetchSuperAdminSchools(org.id)
      .then((resp) => {
        console.log(resp);
        setSchoolsInOrg(resp);
        setSchoolRefreshFromContacts(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  useState(() => {
    if (org.id) {
      console.log("fetching admin schools on useEffect org id");
      fetchAdminSchools();
    }
  }, [org.id]);

  useEffect(() => {
    let filteredOrgs_tmp = [];
    var orgCount = otherContacts.length;
    for (var i = 0; i < orgCount; i++) {
      const item = otherContacts[i];

      if (debouncedFilterString !== "") {
        if (
          !JSON.stringify(item.first_name)
            .toLowerCase()
            .includes(debouncedFilterString) &&
          !JSON.stringify(item.last_name)
            .toLowerCase()
            .includes(debouncedFilterString)
        ) {
          continue;
        }
      }

      if (filterSchool && item.school_or_facility !== filterSchool) {
        continue;
      }

      if (filterRole && item.position !== filterRole) {
        continue;
      }

      if (filterSchool === "school_or_facility" && filterRole) {
        if (filterRole === "unspecified" && item.position) {
          continue;
        } else if (
          filterRole !== "unspecified" &&
          filterRole !== item.position
        ) {
          continue;
        }
      }
      // Reached here / passed all filters,
      // Add to result array
      filteredOrgs_tmp.push(item);
    }
    setFilteredOtherContacts(filteredOrgs_tmp);
  }, [otherContacts, debouncedFilterString, filterSchool, filterRole]);

  const replacePrimaryContact = (newPrimaryContact) => {
    let oldPrimaryContact = primaryContact;
    if (!oldPrimaryContact.id) {
      console.log("no primary exists.");
      api
        .patchContactInfo(org.id, newPrimaryContact.id, {
          ...newPrimaryContact,
          primary_contact: true,
          organization: org.id,
        })
        .then((resp) => {
          console.log(resp);
          fetchContacts();
          return;
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }

    api
      .patchContactInfo(org.id, oldPrimaryContact.id, {
        ...oldPrimaryContact,
        primary_contact: false,
        organization: org.id,
      })
      .then((resp) => {
        console.log(resp);
        console.log(newPrimaryContact);
        api
          .patchContactInfo(org.id, newPrimaryContact.id, {
            ...newPrimaryContact,
            primary_contact: true,
            organization: org.id,
          })
          .then((resp) => {
            console.log(resp);
            fetchContacts();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CONTACT_CAPTIONS = {
    superintendent: "Superintendent",
    teacher: "Teacher",
    principal: "Principal",
    IT_or_sysadmin: "IT or Systems Admin",
    administrator: "Administrator",
    accounting: "Accounting",
    other: "Other",
  };

  const buildDropdownList = (type) => {
    if (type === "position") {
      let listOfExistingVals = [];
      let newList = otherContacts.map((contact, key) => {
        if (contact[type] && listOfExistingVals.indexOf(contact[type]) === -1) {
          listOfExistingVals = [...listOfExistingVals, contact[type]];
          return (
            <MenuItem key={key} value={contact[type]}>
              {type === "position"
                ? CONTACT_CAPTIONS[contact[type]]
                : findNameOfSchool(contact[type])}
            </MenuItem>
          );
        }
        return null;
      });
      return newList;
    } else if (type === "school_or_facility") {
      let newList = schoolsInOrg.map((value, key) => {
        return (
          <MenuItem key={key} value={value.id}>
            {value.name}
          </MenuItem>
        );
      });
      return newList;
    }
    return;
  };

  const findNameOfSchool = (id) => {
    let schoolName = null;
    schoolsInOrg.map((value, key) => {
      if (value.id === id) {
        schoolName = value.name;
      }
    });
    return schoolName;
  };

  const displayNotes = (notes, id) => {
    // displays the first 150 chars
    if (notes && notes.length > 150 && showingMoreNotes.indexOf(id) === -1) {
      return (
        <span style={{ wordBreak: "break-word" }}>
          {notes.substring(0, 150)}...
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowingMoreNotes([...showingMoreNotes, id]);
            }}
          >
            <br />
            <u>(Show more)</u>
          </span>
        </span>
      );
    }
    // displays the full length
    if (notes && notes.length > 150) {
      return (
        <span style={{ wordBreak: "break-word" }}>
          {notes}{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              let arr = JSON.parse(JSON.stringify(showingMoreNotes));
              arr.splice(showingMoreNotes.indexOf(id), 1);
              setShowingMoreNotes(arr);
            }}
          >
            <br />
            <u>(Show less)</u>
          </span>
        </span>
      );
    }
    // displays notes <= 150 chars
    return <span style={{ wordBreak: "break-word" }}>{notes}</span>;
  };

  const createContactBox = (key, contact, isPrimaryContact = false) => {
    return (
      <div className='contact_box' key={!isPrimaryContact ? key : null}>
        <div className='contact_box_info'>
          <p style={{ gridArea: "a" }}>
            {contact.first_name} {contact.last_name}
          </p>
          <p style={{ gridArea: "b" }}>
            <b>Phone #:</b> {contact.phone}
          </p>
          {/*<p style={{gridArea: "c"}}>
            <b>District:</b> {contact.district}
          </p>*/}
          <p style={{ gridArea: "c" }}>
            <b>Role:</b> {CONTACT_CAPTIONS[contact.position]}{" "}
            {contact.position === "other" && contact.position_detail
              ? `(${contact.position_detail})`
              : ""}
          </p>
          <p style={{ gridArea: "d" }}>
            {contact.address1}
            {contact.address2 ? (
              <span>
                <br />
                {contact.address2}
              </span>
            ) : null}
            <br />
            {contact.city}
            {contact.city && contact.state ? "," : null} {contact.state}{" "}
            {contact.zipcode}
          </p>
          <p style={{ gridArea: "e" }}>
            <b>Email:</b> {contact.email}
          </p>
          <p style={{ gridArea: "f" }}>
            <b>School:</b> {findNameOfSchool(contact.school_or_facility)}
          </p>

          <p style={{ gridArea: "h" }}>
            <b>Notes:</b> {displayNotes(contact.notes, contact.id)}
          </p>
        </div>
        <div className='contact_btns_container'>
          <button
            title='Make primary contact'
            className='btn btn-sm btn-primary'
            style={{
              opacity: !isPrimaryContact ? "1" : "0",
              cursor: !isPrimaryContact ? "pointer" : "unset",
            }}
            onClick={() => {
              if (!isPrimaryContact) {
                replacePrimaryContact(contact);
              }
            }}
          >
            <FA color='white' icon='star' />
          </button>

          <button
            title='Edit contact'
            className='btn btn-sm btn-primary'
            onClick={() => {
              setContactToEdit(contact);
            }}
          >
            <FA color='white' icon='edit' />
          </button>

          {!isPrimaryContact && (
            <button
              title='Delete contact'
              className='btn btn-sm btn-danger'
              onClick={() => {
                setContactToDelete(contact);
              }}
            >
              <FA color='white' icon='trash' />
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderOtherContactBoxes = () => {
    return filteredOtherContacts.map((contact, key) => {
      return createContactBox(key, contact);
    });
  };

  const resetFilters = () => {
    setFilterString("");
    setFilterRole("");
    setFilterSchool("");
  };

  return (
    <div className='d-inline-flex'>
      {isDashboardPage ? (
        <button
          className='org-actions btn btn-sm btn-primary'
          data-title='View Org Contact Info'
          onClick={() => {
            setShowPopup(!showPopup);
          }}
        >
          <FA icon='phone' color='white' />
        </button>
      ) : (
        <div style={{ marginBottom: "5px" }}>
          <button
            className='btn btn-primary'
            onClick={() => {
              setShowPopup(!showPopup);
              fetchAdminSchools();
            }}
          >
            View contact info...
          </button>
        </div>
      )}

      <div
        className='contacts_popup_graybox'
        style={{ display: showPopup || isDashboardPage ? "block" : "none" }}
      >
        <div id='contacts_popup_whitebox'>
          {showCreateSchoolModal && (
            <CreateSuperAdminSchoolModal
              orgId={org.id}
              shouldShowFunc={setShowCreateSchoolModal}
              refreshList={fetchAdminSchools}
            />
          )}
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <h5 style={{ marginBottom: "0px" }}>Contacts for {org.name}</h5>
            <div
              style={{
                cursor: "pointer",
                padding: "5px",
                width: "fit-content",
              }}
              onClick={() => {
                setShowPopup(!showPopup);
                if (setOrgContactsViewingId) {
                  setOrgContactsViewingId(null);
                }
                resetFilters();
              }}
            >
              <FA color='gray' icon='times' />
            </div>
          </div>

          <div id='contacts_container' className='visible-scrollbar'>
            <div className='other_contacts_header_flexbox'>
              <h5>Primary Contact:</h5>
            </div>
            {!fetchingContacts ? (
              <div>{createContactBox(0, primaryContact, true)}</div>
            ) : (
              <p>
                <FA color='gray' icon='spinner' spin /> Fetching contacts...
              </p>
            )}
            <div className='other_contacts_header_flexbox'>
              <div style={{ display: "flex", flexFlow: "column" }}>
                <h5>Other Contacts:</h5>

                {(contactToEdit || creatingNewContact) && (
                  <CreateEditContactModal
                    contactToEdit={contactToEdit}
                    setContactToEdit={setContactToEdit}
                    creatingNewContact={creatingNewContact}
                    setCreatingNewContact={setCreatingNewContact}
                    orgId={org.id}
                    fetchContacts={fetchContacts}
                    fetchAdminSchoolsForFilter={fetchAdminSchools}
                  />
                )}

                {contactToDelete && (
                  <DeleteContactModal
                    orgId={org.id}
                    contactToDelete={contactToDelete}
                    setContactToDelete={setContactToDelete}
                    fetchContacts={fetchContacts}
                  />
                )}

                <button
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    setShowPopup(true);
                    setCreatingNewContact(true);
                  }}
                >
                  Create new contact...
                </button>
              </div>
              <div id='contacts_filter_container'>
                <h5 style={{ marginBottom: "0px" }}>Filters:</h5>
                <TextField
                  margin='normal'
                  placeholder='Search by name...'
                  size='medium'
                  value={filterString}
                  onChange={(e) =>
                    setFilterString(e.target.value.toLowerCase())
                  }
                />

                <FormControl>
                  <InputLabel id='demo-simple-select-label'>Role:</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={filterRole || ""}
                    onChange={(e) => {
                      setFilterRole(e.target.value);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {buildDropdownList("position")}
                  </Select>
                </FormControl>

                <FormControl
                  style={{
                    opacity:
                      org.org_level === config.ORG_LEVEL_SCHOOL ? "0" : "",
                  }}
                >
                  <InputLabel id='demo-simple-select-label'>School:</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={filterSchool || ""}
                    disabled={org.org_level === config.ORG_LEVEL_SCHOOL}
                    onChange={(e) => {
                      if (e.target.value === "add_school") {
                        setShowCreateSchoolModal(true);
                        setFilterSchool("");
                        return;
                      }
                      setFilterSchool(e.target.value);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {org.org_level === config.ORG_LEVEL_DISTRICT &&
                      buildDropdownList("school_or_facility")}
                    <MenuItem value={"add_school"}>Add a school...</MenuItem>
                  </Select>
                </FormControl>
                <button
                  className='btn btn-primary btn-sm mt-3 reset_filters_btn'
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Reset Filters
                </button>
              </div>
            </div>

            {!fetchingContacts ? (
              renderOtherContactBoxes()
            ) : (
              <p>
                <FA color='gray' icon='spinner' spin /> Fetching contacts...
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(
  compose(connect(mapStateToProps), withAPI)(OrgContactsModal)
);
