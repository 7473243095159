import React from "react";
import PropTypes from "prop-types";

const Banner = ({ alertType, message, children }) => {
  return (
    <nav className={`alert-${alertType}`}>
      <ol className='breadcrumb custom_breadcrumb alert-warning'>
        <li className={`breadcrumb-item alert-${alertType}`}>
          {message}
          {children}
        </li>
      </ol>
    </nav>
  );
};

Banner.propTypes = {
  alertType: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Banner;
