import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {truncateStr} from "../../../common/utils";
import FA from "../../../containers/fa";
import {saleModalHeader as saleModalHeaderA} from "../../../redux/actions";
import "./SalesInfoRow.scss";

const mapStateToProps = (state, ownProps?) => ({
  saleDetailId: state.superAdmin.saleDetailId,
});

const SalesInfoRow = ({
  index,
  dispatch,
  saleObj,
  nullRow,
  saleDetailId,
  allSale,
}) => {
  const roundValue = (value) => {
    if (value) {
      return Math.round(value * 100) / 100;
    } else {
      return value;
    }
  };

  if (nullRow === true) {
    return <td colSpan={allSale ? 11 : 6}>No Sales Record.</td>;
  }

  return (
    <React.Fragment>
      <div
        className={allSale ? 'all_sales_table_row' : 'org_sales_table_row' }
        style={{backgroundColor: index % 2 === 0 ? "#f2f2f2" : ""}}
      >
        <p>{saleObj.id}</p>

        <p lang="en">
          {moment
            .utc(saleObj.last_status_changed_on)
            .local()
            .format("MMMM Do YYYY")}
        </p>

        <p>
          {saleObj.status_display}
          {saleObj.sale_returns.length > 0 && (
            <FA
              color='#ffc107'
              icon='caret-square-left'
              className='ml-2'
              title='Has Returns'
            />
          )}
          {saleObj.sale_type === "renewal" && (
            <span data-title='Renewal Sale'>
              <FA
                color='#ffc107'
                icon='bolt'
                className='ml-2'
                alt='Renewal Sale'
              />
            </span>
          )}
        </p>

        {allSale ? (
          <React.Fragment>
            <p>
              {saleObj.organization_info.name}
              {saleObj.school_display ? (
                <span data-title={saleObj.school_display}>
                  <br></br>({truncateStr(saleObj.school_display, 24)})
                </span>
              ) : null}
            </p>

            <p>
              $
              {saleObj.dollar_values
                ? saleObj.dollar_values.robot_value
                : " - "}
            </p>

            <p>
              ${saleObj.dollar_values ? saleObj.dollar_values.pd_value : " - "}
            </p>

            <p>
              $
              {saleObj.dollar_values
                ? saleObj.dollar_values.subscription_value
                : " - "}
            </p>

            <p>
              $
              {saleObj.total_sale_value
                ? roundValue(saleObj.sale_value_after_returns)
                : " - "}
            </p>

            <p style={{whiteSpace: "nowrap"}}>
              {saleObj.delivered_pds} / {saleObj.total_pds || 0}
            </p>

            <p>
              {saleObj.it_dept_setup ? (
                <span>
                  <FA color='green' icon='check-circle' /> Yes{" "}
                </span>
              ) : (
                <span>
                  No <FA color='#ffc107' icon='exclamation-circle' />
                </span>
              )}
            </p>

            <p>{saleObj.organization_info.state}</p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>
              {saleObj.school_display ? (
                <span data-title={saleObj.school_display}>
                  {truncateStr(saleObj.school_display, 32)}
                </span>
              ) : (
                " - "
              )}
            </p>

            <p>{saleObj.robot_count_after_returns}</p>

            <p>{saleObj.total_pds}</p>

            <p>{saleObj.lct_count}</p>
          </React.Fragment>
        )}

        <p style={{ position: 'relative'}}>
          <button
            className='btn btn-sm btn-primary'
            assignment-data-title='Show more'
            onClick={() => {
              dispatch(
                saleModalHeaderA({
                  id: saleObj.id,
                  orgInfo: {
                    orgType: saleObj.organization_info.category,
                    orgId: saleObj.organization_info.id,
                    taxRate: saleObj.organization_info.tax_rate,
                    orgLevel: saleObj.organization_info.org_level,
                  },
                })
              );
            }}
          >
            <FA icon='search' color='white' />
          </button>
        </p>
      </div>
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(SalesInfoRow);
