import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import withAPI from "../../services/api";
import FAW from "../../containers/faw";
import FA from "../../containers/fa";
import "./RobotRow.scss";

const RobotRow = ({ api, rbt, refreshList, match }) => {
  const [delSpin, setDelSpin] = useState(false);

  const handleRemoval = () => {
    setDelSpin(true);
    api.removeRobotTagFromRobot(match.params.tagId, rbt.serial).then(() => {
      refreshList();
    });
  };

  return (
    <tr key={rbt.serial}>
      <td>
        <span>{rbt.serial && rbt.wifi}</span>
      </td>
      <td name='Actions'>
        <span>
          <div className='datatable_actions'>
            <div className='d-flex align-items-start common-align-button action-btns'>
              {!delSpin && (
                <button
                  data-title='Remove from Robot Tag'
                  className='btn btn-md btn-danger'
                  onClick={() => handleRemoval()}
                >
                  <FAW icon='trash' />
                </button>
              )}
              {delSpin && (
                <button
                  data-title='Remove from Robot Tag'
                  className='btn btn-md btn-danger'
                  onClick={() => handleRemoval()}
                >
                  <FA icon='spinner' spin />
                </button>
              )}
            </div>
          </div>
        </span>
      </td>
    </tr>
  );
};

export default compose(withAPI, withRouter)(RobotRow);
