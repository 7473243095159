import React from 'react';

import Gate from '../partials/Gate';
import WiFiForm from '../components/WiFiForm'


function WiFiSetup() {
  return (
    <Gate boxWidthClass="col-lg-5 col-md-8">
      <div className="login_box form-content container form-group">
        <div className="row">
          <div className="col">
            <h1 style={{"textAlign": "center"}}>Wifi Setup</h1>
          </div>
        </div>
        <div className="row">
          <WiFiForm />
        </div>
      </div>
    </Gate>
  );
}

export default WiFiSetup;
