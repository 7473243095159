import React, { useState } from "react";
import "../Carousels.scss";
import "./LearnerBySessionCarousel.scss";
import ClassVsLearnerBarChart from "./ClassVsLearnerBarChart";
import LearnerPerformanceLineChart from "./LearnerPerformanceLineChart";
import FA from "../../../../containers/fa";
import BarChartShadowSvg from "../../BarChartChadowSvg";
import LearnerPerformanceTooltip from "./LearnerPerformanceTooltip";
import { useDebounce } from "use-debounce";

const LearnerBySessionCarousel = ({
  activeLearner,
  selectedLessonInstance,
  error,
  fetchingLessons,
  classAverageData,
  learnerLessonReport,
  lessonContextInfo,
  isHomeVersion,
  setAttentionNotRecorded,
  attentionNotRecorded,
  instancePerformance,
  instanceAttention,
}) => {
  const [currentChart, setCurrentChart] = useState("learnerPerformance");
  const [hover, setHover] = useState(false);
  const [currentHoverData, setCurrentHoverData] = useState(null);
  const [debouncedCurrentHoverData] = useDebounce(currentHoverData, 500);

  const convertQuestionTypes = (qType) => {
    // DSP / RCP should not appear
    if (qType === "TXT" || qType === "OP") {
      return "Fill in the Blank";
    } else if (qType === "SNG" || qType === "LRG") {
      return "Multiple Choice";
    } else if (qType === "IMC") {
      return "Multiple Choice Image";
    } else if (qType === "DSP" || qType === "DWS" || qType === "OPD") {
      return "Display";
    } else if (qType === "RCP") {
      return "Recap";
    } else if (qType === "VID") {
      return "Video";
    } else {
      return "N/A";
    }
  };

  return (
    <div
      className='carousel_container carousel_charts'
      style={{ gridArea: "b", width: "100%", position: "relative" }}
    >
      {activeLearner && selectedLessonInstance && !error && !fetchingLessons ? (
        <div>
          <LearnerPerformanceTooltip
            hover={hover}
            debouncedCurrentHoverData={debouncedCurrentHoverData}
            attentionNotRecorded={attentionNotRecorded}
            convertQuestionTypes={convertQuestionTypes}
          />

          <div className='carousel_charts_grid'>
            <ClassVsLearnerBarChart
              classAverageData={classAverageData}
              selectedLesson={selectedLessonInstance}
              isHomeVersion={isHomeVersion}
              currentChart={currentChart}
            />

            <LearnerPerformanceLineChart
              learnerReport={learnerLessonReport}
              lessonContextInfo={lessonContextInfo}
              selectedLesson={selectedLessonInstance}
              currentChart={currentChart}
              setCurrentChart={setCurrentChart}
              attentionNotRecorded={attentionNotRecorded}
              setAttentionNotRecorded={setAttentionNotRecorded}
              currentHoverData={currentHoverData}
              setCurrentHoverData={setCurrentHoverData}
              hover={hover}
              setHover={setHover}
              convertQuestionTypes={convertQuestionTypes}
              debouncedCurrentHoverData={debouncedCurrentHoverData}
              instancePerformance={instancePerformance}
              instanceAttention={instanceAttention}
            />
          </div>

          <div className='carousel_btns_container'>
            <button
              className={`carousel_btn ${
                currentChart === "classVsLearner" ? "selected" : "unselected"
              }`}
              onClick={() => setCurrentChart("classVsLearner")}
            />
            <button
              className={`carousel_btn ${
                currentChart === "learnerPerformance"
                  ? "selected"
                  : "unselected"
              }`}
              onClick={() => setCurrentChart("learnerPerformance")}
            />
          </div>
        </div>
      ) : fetchingLessons ? (
        <div className='default_carousel'>
          <FA color='gray' icon='spinner' spin /> Loading...
        </div>
      ) : activeLearner && !selectedLessonInstance && !error ? (
        <div className='default_carousel'>
          <BarChartShadowSvg />
          <p>Select a lesson and session.</p>
        </div>
      ) : error ? (
        <div className='default_carousel'>
          <BarChartShadowSvg />
          <p>An error occurred. Please try again later.</p>
        </div>
      ) : !activeLearner ? (
        <div className='default_carousel'>
          <BarChartShadowSvg />
          <p>Select a learner.</p>
        </div>
      ) : null}
    </div>
  );
};

export default LearnerBySessionCarousel;
