/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from "react";
import {
  filterLessonsBySubjectUnit,
  getHeaderClasses,
  findLearnerName,
  findLearnerFromSID,
} from "../../ConsolidatedReportsUtilities";
import "./LearnerScoresTable.scss";
import { sortedArray } from "../../../../common/utils";
import ClassScoresPagination from "../ClassScoresPagination";
import PdfDownloadBtn from "../PdfDownloadBtn";
import DefaultTable from "./DefaultTable";
import {
  findScoreForLesson,
  moveNullValuesToBottom,
  sortColumnsByLearner,
  sortRowsByColumn,
  splitLearnersForPagination,
  getGridTemplateColumns,
  getMinWidthOfRows,
  sortColumnsByStandard,
} from "./LearnerScoresTableUtilities";
import LessonAveragesRow from "./TableComponents/LessonAveragesRow";
import HeadersRow from "./TableComponents/HeadersRow";
import LearnerRow from "./TableComponents/LearnerRow";
import { Switch, Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import SortStandardsCheckboxes from "./SortStandardsCheckboxes";

const LearnerScoresTable = ({
  learnersList,
  activeSubject,
  activeUnit,
  reports,
  lessons,
  ready,
  groupingLessons,
  setShowOverlay,
  reportsErr,
  setSelectedSearchVal,
  setActiveTab,
  listOfUnits,
  activeClass,
  setViewLearnerInClass,
}) => {
  const [learnersListWithScores, setLearnersListWithScores] = useState(null);
  const [rows, setRows] = useState([]);
  const [sortType, setSortType] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [sortLessonAvgsAsc, setSortLessonAvgsAsc] = useState(true);
  const [sortedLearnerIdAsc, setSortedLearnerIdAsc] = useState(true);
  const [columnToSort, setColumnToSort] = useState(null);
  const [lessonsToSort, setLessonsToSort] = useState(null);
  const [learnersPerPage, setLearnersPerPage] = useState("All");
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfLearners, setNumberOfLearners] = useState(0);
  const [sortedLearnerId, setSortedLearnerId] = useState(null);
  const [showStandardInfo, setShowStandardinfo] = useState(true);
  const [sortingStandardsAsc, setSortingStandardsAsc] = useState(true);

  useEffect(() => {
    organizeScores();
    if (!activeSubject) return;
    if (activeSubject && activeSubject.label.startsWith("SEL")) {
      setShowStandardinfo(false);
    } else {
      setShowStandardinfo(true);
    }
    setRows(createLearnerRows());
  }, [
    activeSubject,
    activeUnit,
    listOfUnits,
    reports,
    lessons,
    learnersPerPage,
    currentPage,
  ]);

  useEffect(() => {
    setRows(createLearnerRows());
  }, [showStandardInfo]);

  useEffect(() => {
    if (reportsErr) setLearnersListWithScores(null);
  }, [reportsErr]);

  /**
   * Toggles the column currently sorted and the direction if necessary. Not
   * applied to the final row, lesson averages, which is handled separately.
   * @param {Array} lessons
   * @param {String} columnName
   */
  const handleColumnHeaderClick = (lessons, columnName) => {
    setColumnToSort(columnName);
    setLessonsToSort(lessons);
    if (sortType === columnName) {
      if (sortType !== "lessonAverage" && sortType !== "learnerAverage") {
        setSortAsc(!sortAsc);
      }
    } else {
      setSortType(columnName);
      setSortAsc(false);
    }
  };

  useEffect(() => {
    sortRowsByColumn(
      learnersListWithScores,
      lessonsToSort,
      columnToSort,
      sortAsc,
      false,
      setLearnersListWithScores
    );
    setRows(createLearnerRows());
  }, [
    sortType,
    sortAsc,
    sortLessonAvgsAsc,
    sortedLearnerIdAsc,
    sortedLearnerId,
    sortingStandardsAsc,
  ]);

  useEffect(() => {
    setRows(createLearnerRows());
  }, [learnersListWithScores]);

  /**
   * getFilteredLessons collects all the options with asms filtered out. It
   * also calculates the average of the displayed lessons for sorting and sorts
   * by average. The lesson averages are always sorted.
   *
   * [{
   *    average: 33,
   *    group: "MATH K - Geometry"
   *    label: "Where's That Shape? - TUT"
   *    value: "I981"
   *  }, ...]
   * @param {Array} lessonsCopy, an object arr of lessons passed in
   * @returns {Array} object arr of filtered, sorted lesson options
   */
  const getFilteredLessons = (lessons) => {
    let lessonsCopy = lessons;
    lessonsCopy = filterLessonsBySubjectUnit(
      lessons,
      activeSubject,
      activeUnit
    );
    // getting the options fields in the filtered lessons. Filter out asms
    let filteredLessons = [];
    for (let i = 0; i < lessonsCopy.length; i++) {
      let tutOptions = lessonsCopy[i].options.filter(
        (val) => val.label.indexOf("- ASM") === -1
      );
      filteredLessons = filteredLessons.concat(tutOptions);
    }
    if (!learnersListWithScores) return [];
    // for each lesson...
    for (let index = 0; index < filteredLessons.length; index++) {
      let label = filteredLessons[index].label;
      let scoreAvg = null;
      let learnerCount = 0;
      // for each learner per lesson...
      for (let i = 0; i < learnersListWithScores.length; i++) {
        let scoreVal = findScoreForLesson(
          learnersListWithScores[i].scores,
          label
        );
        if (scoreVal !== null) {
          if (scoreAvg === null) scoreAvg = 0;
          scoreAvg = scoreAvg + scoreVal;
          learnerCount++;
        }
      }
      // calculate the average for that lesson.
      filteredLessons[index].average =
        scoreAvg !== null
          ? Math.round((scoreAvg / learnerCount) * 10) / 10
          : null;
    }

    // sorts by average.
    filteredLessons = sortedArray(
      filteredLessons,
      "average",
      sortLessonAvgsAsc,
      true
    );

    if (sortedLearnerId !== null) {
      filteredLessons = sortColumnsByLearner(
        sortedLearnerId,
        filteredLessons,
        findLearnerFromSID,
        learnersListWithScores,
        sortedLearnerIdAsc
      );
    } else if (sortingStandardsAsc !== null) {
      filteredLessons = sortColumnsByStandard(
        filteredLessons,
        sortingStandardsAsc
      );
    }

    if (sortingStandardsAsc === null) {
      return moveNullValuesToBottom(filteredLessons, "average");
    } else {
      return filteredLessons;
    }
  };

  /**
   * organizeScores takes the list of reports and categorizes them by sid.
   * [{sid: 88,
   *    first_name: nameStr,
   *    scores: [
   *          {lesson_name: 'A Fishy Trick', perf: 100},
   *          {lesson_name: 'Just Plain Batty!', perf: 100}
   *        ],
   *    average: 50
   *  }, ...]
   */
  const organizeScores = () => {
    let learners = reports;
    if (reports) {
      let filteredLessons = getFilteredLessons(lessons);
      // finds the learner's scores, loops through them and calculates avg.
      let newLearnersListWithScores = Object.keys(learners).map(
        (val, index) => {
          let scoresAddedUp = null;
          let numberOfValidLessons = 0;
          let avg = null;
          for (let index1 = 0; index1 < filteredLessons.length; index1++) {
            let lessonScore = findScoreForLesson(
              learners[val].scores,
              filteredLessons[index1].label
            );
            if (lessonScore !== null) {
              if (avg === null) avg = 0;
              numberOfValidLessons++;
              if (scoresAddedUp === null) scoresAddedUp = 0;
              scoresAddedUp = scoresAddedUp + lessonScore;
              avg = scoresAddedUp / numberOfValidLessons;
            }
          }
          return {
            sid: val,
            first_name: findLearnerName(val, learnersList).first_name,
            scores: learners[val].scores,
            average: avg !== null ? Math.round(avg * 10) / 10 : null,
          };
        }
      );
      setNumberOfLearners(newLearnersListWithScores.length);
      splitLearnersForPagination(
        newLearnersListWithScores,
        learnersPerPage,
        setNumberOfPages,
        setLearnersListWithScores,
        currentPage,
        columnToSort,
        sortAsc,
        lessonsToSort
      );
    }
  };

  // shortens inline css. Only last entry will have a border.
  const hasBorder = (index, len) =>
    index === len - 1 ? "2px solid rgba(0, 0, 0, 0.3)" : "";

  /**
   * createLearnerRows iterates through the learners and creates one row for
   * each, populated with only the scores for lessons that fall within
   * the subject and unit filters.
   * @returns {html} the header and learner rows for the scores table.
   */
  const createLearnerRows = () => {
    if (!learnersListWithScores) return null;
    let filteredLessons = getFilteredLessons(lessons);
    let newRows = [];
    if (learnersListWithScores) {
      // loops through learners
      newRows = learnersListWithScores.map((val, index) => {
        // returns a row for each learner.
        let nameVals = findLearnerName(val.sid, learnersList);
        return (
          <LearnerRow
            index={index}
            key={index}
            filteredLessons={filteredLessons}
            handleColumnHeaderClick={handleColumnHeaderClick}
            setSortedLearnerId={setSortedLearnerId}
            val={val}
            setSortedLearnerIdAsc={setSortedLearnerIdAsc}
            sortedLearnerIdAsc={sortedLearnerIdAsc}
            sortedLearnerId={sortedLearnerId}
            nameVals={nameVals}
            learnersListWithScores={learnersListWithScores}
            hasBorder={hasBorder}
            setSelectedSearchVal={setSelectedSearchVal}
            setActiveTab={setActiveTab}
            setViewLearnerInClass={setViewLearnerInClass}
            setSortingStandardsAsc={setSortingStandardsAsc}
          />
        );
      });
    }

    /* The containers of the header and data rows */
    return (
      <div id='learner_scores_rows_container'>
        <div
          className={`labels_row`}
          style={{
            gridTemplateColumns: getGridTemplateColumns(filteredLessons),
            minWidth: getMinWidthOfRows(filteredLessons),
          }}
        >
          <div
            className='name learners_label'
            onClick={() => {
              handleColumnHeaderClick(filteredLessons, "first_name");
            }}
          >
            <span
              className={getHeaderClasses("first_name", sortType, sortAsc)}
              style={{ textAlign: "center", justifyContent: "center" }}
            >
              Learners
            </span>
          </div>
          {filteredLessons.length !== 0 ? (
            <HeadersRow
              filteredLessons={filteredLessons}
              hasBorder={hasBorder}
              handleColumnHeaderClick={handleColumnHeaderClick}
              sortType={sortType}
              sortAsc={sortAsc}
              listOfUnits={listOfUnits}
              showStandardInfo={showStandardInfo}
            />
          ) : (
            <div className='no_lessons_available'>
              No lessons available for this Subject/Unit.
            </div>
          )}
          <div
            style={{
              borderRight:
                filteredLessons.length <= 15 && filteredLessons.length !== 0
                  ? "1px solid rgba(0,0,0,0.2)"
                  : "",
            }}
          />
          <div
            className='data average_label'
            onClick={() => {
              handleColumnHeaderClick(filteredLessons, "average");
            }}
          >
            <span
              className={getHeaderClasses("average", sortType, sortAsc)}
              style={{ alignItems: "flex-end", height: "100%" }}
            >
              Average
            </span>
          </div>
          <p className='actions' style={{ alignSelf: "end" }}>
            Actions
          </p>
        </div>
        {newRows}
        <LessonAveragesRow
          filteredLessons={filteredLessons}
          hasBorder={hasBorder}
          handleColumnHeaderClick={handleColumnHeaderClick}
          setSortedLearnerId={setSortedLearnerId}
          setSortLessonAvgsAsc={setSortLessonAvgsAsc}
          sortLessonAvgsAsc={sortLessonAvgsAsc}
          sortType={sortType}
          setSortingStandardsAsc={setSortingStandardsAsc}
        />
      </div>
    );
  };

  const showingDefaultTable = () => {
    return (
      !ready ||
      reportsErr ||
      groupingLessons ||
      !activeSubject ||
      !activeSubject.label ||
      !learnersList ||
      learnersList.length === 0
    );
  };

  // if something is being fetched, there are no learners, or a subject hasn't
  // been chosen, it chooses DefaultTable.
  return (
    <div id='learner_scores_table_container' className='table_contents'>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ margin: "10px 10px 10px 0px", minWidth: "max-content" }}>
          <b>Class Scores</b>
        </h4>
        {!showingDefaultTable() && (
          <p style={{ margin: 0, justifySelf: "flex-end" }}>
            Click on the headers to sort by a column or row.
          </p>
        )}
      </div>
      {showingDefaultTable() ? (
        <DefaultTable
          ready={ready}
          groupingLessons={groupingLessons}
          learnersList={learnersList}
        />
      ) : (
        <div>
          <div id='scores_table_header' className='has-data'>
            <p id='class_scores_subject_label'>
              <b>
                {activeSubject ? activeSubject.label : ""} Tutorials{" "}
                {activeUnit ? `- ${activeUnit.label}` : ""}
              </b>
            </p>
            <ClassScoresPagination
              learnersPerPage={learnersPerPage}
              setLearnersPerPage={setLearnersPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              numberOfPages={numberOfPages}
              numberOfLearners={numberOfLearners}
              setSortedLearnerId={setSortedLearnerId}
              sortedLearnerId={sortedLearnerId}
              learnersList={learnersList}
            />
            {activeSubject && !activeSubject.label.startsWith("SEL") && (
              <div id='standards_switch_container'>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Typography>Show Standards Info</Typography>
                  <Switch
                    defaultChecked={showStandardInfo}
                    color={"primary"}
                    value={showStandardInfo}
                    onChange={() => setShowStandardinfo(!showStandardInfo)}
                  />
                </Stack>
              </div>
            )}

            {showStandardInfo && (
              <SortStandardsCheckboxes
                sortingStandardsAsc={sortingStandardsAsc}
                setSortingStandardsAsc={setSortingStandardsAsc}
                setSortedLearnerId={setSortedLearnerId}
              />
            )}

            <div id='pdf_class_btn_container'>
              <PdfDownloadBtn
                btnCaption='Download Class Report...'
                enableBtn={learnersList && learnersList.length > 0}
                setShowOverlay={setShowOverlay}
                type='class'
                activeClass={activeClass}
              />
            </div>
          </div>
          <div id='scores_table_container' className='visible-scrollbar'>
            <div id='scores_table'>{rows}</div>
            <div id='scores_table_footer'></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(LearnerScoresTable);
