import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import React from "react";
import {Pie} from "react-chartjs-2";
import {roundValues} from "../../common/utils";

ChartJS.register(ArcElement, Tooltip, Legend);

const ImpactStatsLatest = ({pieChartData, subject, avgMastered = null}) => {
  // returns a Pie element

  const learnerCount = pieChartData.extra
    ? pieChartData.extra.learnerCount
    : null;

  const getOffsetMultiplierValue = (index) => {
    let data = pieChartData.datasets[0].data;
    let sum = data[0] + data[1] + data[2];
    if (sum === 0) {
      return 8;
    }
    if ((data[index]/sum) > 0.2) {
      return 4;
    }
    if ((data[0]/sum) < .15 || (data[1]/sum) < .15 || (data[2]/sum) < .15) {
      return 8;
    }
    return 4;
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        align: "end",
        offset: (ctx) => {
          let alt = (-1) ** ctx.dataIndex;
          return alt * ctx.dataIndex * getOffsetMultiplierValue(ctx.dataIndex);
        },
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(1) + "%";
          return percentage;
        },
      },
      tooltip: {
        mode: "index",
        intersect: true,
        callbacks: {
          label: function (context) {
            let label = context.label;
            const totalCount = context.dataset.data.reduce((a, b) => a + b);

            if (learnerCount) {
              const avgValue = roundValues(context.parsed / learnerCount, 1);
              label = [
                label + ":",
                `${context.parsed}/${totalCount} standards (${avgValue} per learner)`,
              ];
            } else {
              label += `: ${context.parsed}/${totalCount} standards`;
            }
            return label;
          },
        },
      },
      legend: {
        position: "bottom",
        align: "center",
      },
      title: {
        display: true,
        text: subject
          ? `${subject} Standards Mastered To Date`
          : "Standards Mastered To Date",
        font: {
          size: 15,
        },
        padding: {
          top: 10,
          bottom: learnerCount ? 5 : 15,
        },
      },
      subtitle: {
        display: learnerCount,
        text: avgMastered
          ? [
              `${learnerCount ? learnerCount : ""} active learner(s)`,
              `${avgMastered} mastered standards per learner (on average)`,
            ]
          : `${learnerCount ? learnerCount : ""} active learner(s)`,
        padding: {
          bottom: 15,
        },
      },
    },
  };

  return <Pie data={pieChartData} options={pieChartOptions} />;
};

export default ImpactStatsLatest;
