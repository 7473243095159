import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FA from "../../containers/fa";
import { updateUserSchools } from "../../redux/actions";
import withAPI from "../../services/api";
import UserInputFieldDataRow from "./UserInputFieldDataRow";
import config from "../../common/config";
import { areNonObjectArraysEqual, searchObjectArray } from "../../common/utils";
import { schoolOptionsToInfo } from "../InputFields/helpers";
import MultiSchoolReactSelect from "../InputFields/MultiSchoolReactSelect.js";
import UserRoleSelect from "../InputFields/UserRoleSelect.js";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const UserEditModal = ({
  setCurrentEditUser,
  api,
  currentEditUser,
  fetchUsers,
  user,
  dispatch,
}) => {
  const [error, setError] = useState(false);
  const [newUserData, setNewUserData] = useState({
    first_name: currentEditUser.first_name,
    last_name: currentEditUser.last_name,
    email: currentEditUser.email,
    username: currentEditUser.username,
    usertype: currentEditUser.usertype,
    school: currentEditUser.school,
    orgunits: currentEditUser.orgunits,
  });
  const [spin, setSpin] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [moveAssociatedObjects, setMoveAssociatedObjects] = useState(false);
  const [haveSchoolsChanged, setHaveSchoolsChanged] = useState(false);
  const [userHasAssociatedObjects, setUserHasAssociatedObjects] = useState(false);

  useEffect(() => {
    if (currentEditUser) {
      let objectCount = currentEditUser.enrolled_learners + currentEditUser.robotCount;
      setUserHasAssociatedObjects(objectCount > 0);

      if (currentEditUser.clever_synced) {
        setDisabled(true)
      }
    }
  }, [currentEditUser]);

  const updateUserFields = (fieldName, value) => {
    let dataTmp = { ...newUserData };
    dataTmp[fieldName] = value;
    setNewUserData(dataTmp);
  };

  const handlePatchUser = () => {
    setSpin(true);
    if (
      newUserData.usertype !== "orgadmin" &&
      newUserData.orgunits.length === 0
    ) {
      setError("School is required for Teachers and School Admins.");
      return;
    } else {
      api
        .patchUserInfo(currentEditUser.id, {
          first_name: newUserData.first_name,
          last_name: newUserData.last_name,
          username: newUserData.email,
          email: newUserData.email,
          userprofile: {
            usertype: newUserData.usertype,
            orgunits: newUserData.orgunits.map((ou) => ou.id),
          },
          also_move_associated: moveAssociatedObjects,
        })
        .then((info) => {
          const currentEditUserOrgunitIds = currentEditUser.orgunits.map(
            (ou) => ou.id
          );
          const newOrgUnitIds = info.userprofile.orgunits.map((ou) => ou.id);

          // if updating own orgunits, also update redux state
          if (
            currentEditUser.username === user.user.username &&
            !areNonObjectArraysEqual(newOrgUnitIds, currentEditUserOrgunitIds)
          ) {
            dispatch(updateUserSchools(info.userprofile.orgunits));
          }
          console.log("updated");
          fetchUsers();
          setCurrentEditUser(null);
          setSpin(false);
        })
        .catch((e) => {
          console.log(e);
          if (e.message.includes("username already exists")) {
            setError("That email is already taken.");
          } else if (e.message.includes("valid email address")) {
            setError("Please enter a valid email address.");
          } else {
            setError("Something went wrong... Please try again.");
          }
          setSpin(false);
        });
    }
  };

  const updateUserSchool = (selectedSchools) => {
    if (selectedSchools && selectedSchools.length > 0) {
      let updatedValues = schoolOptionsToInfo(selectedSchools);
      updateUserFields("orgunits", updatedValues);
    } else {
      updateUserFields("orgunits", []);
    }

    setHaveSchoolsChanged(checkIfSchoolsChanged(selectedSchools));
  };

  const checkIfSchoolsChanged = (selectedSchools) => {
    console.log("currentEditUser:", currentEditUser);
    //console.log("currentEditUser.orgunits", currentEditUser.orgunits);
    console.log("selectedSchools", selectedSchools);

    let currentEditUserOrgunitIds = currentEditUser.orgunits.map((ou) => ou.id);
    let selectedOrgunitIds = selectedSchools.map((ou) => ou.id);

    return !areNonObjectArraysEqual(
      currentEditUserOrgunitIds,
      selectedOrgunitIds
    );
  };

  // TODO: move this to a separate reusable yes/no popup component
  function ReassignConfirmationPopup({ handleSubmit }) {
    return (
      <>
        <div className='pdf-upload'></div>
        <div className='pdf-upload-wrapper-small'>
          <div className='common_border'>
            <div className='common_heading' style={{ margin: "0px" }}>
              <p>{"Reassign Robots and Learners"}</p>
            </div>

            <div className='common_dashboard_bg' style={{ margin: "0px" }}>
              <div className='row'>
                <div className='col-12'>
                  <strong>
                    {
                    "You have changed the School(s) associated with this user. Would you like to update the School assignments for this user's Robots and Learners as well?"
                    }
                  </strong>
                  <br />
                  <br />
                  {
                    "If Yes, this user’s Learners and Robots will also be reassigned to the selected School(s)."
                  }
                  <br />
                  <br />
                  {
                    "If No, the Learners and Robots associated with this user will retain their old School assignments and may no longer be associated with this user."
                  }
                  <br />
                  <div className='d-flex mt-4'>
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={() => {
                        handleSubmit(true);
                      }}
                    >
                      {"Yes, reassign"}
                    </button>
                    <button
                      className='btn btn-sm btn-danger ml-4'
                      onClick={() => {
                        handleSubmit(false);
                      }}
                    >
                      {"No, don't reassign"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper' style={{ display: "block" }}>
        {showReassignModal &&
          ReassignConfirmationPopup({
            handleSubmit: (val) => {
              console.log("move objects: ", val);
              setShowReassignModal(false);
              setMoveAssociatedObjects(val);
            },
          })}
        <div className='common_border'>
          <div className='common_heading'>
            <p>Edit User</p>
            <button
              autoFocus
              className='pdf_popup_close'
              onClick={() => {
                setCurrentEditUser(null);
              }}
            >
              <FA icon='times' />
            </button>
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <div>
                  <table
                    className='table table-sm'
                    style={{ verticalAlign: "middle", borderTop: "none" }}
                  >
                    <tbody>
                      <tr>
                        {currentEditUser.isSSOUser ||
                        currentEditUser.isPlaceholder ? (
                          <td
                            className='item-label'
                            style={{
                              verticalAlign: "middle",
                              borderTopStyle: "none",
                            }}
                          >
                            First Name
                          </td>
                        ) : (
                          <td className='item-label'>
                            <label htmlFor='user-edit-first_name'>First Name</label>
                            {disabled && <span className="text-info">*</span>}
                          </td>
                        )}
                        <td>
                          <UserInputFieldDataRow
                            editMode={
                              !(
                                currentEditUser.isSSOUser ||
                                currentEditUser.isPlaceholder
                              )
                            }
                            user={newUserData}
                            updateUserFields={updateUserFields}
                            fieldName='first_name'
                            disabled={disabled}
                          />
                        </td>
                      </tr>
                      <tr>
                        {currentEditUser.isSSOUser ||
                        currentEditUser.isPlaceholder ? (
                          <td
                            className='item-label'
                            style={{
                              verticalAlign: "middle",
                              borderTopStyle: "none",
                            }}
                          >
                            Last Name
                          </td>
                        ) : (
                          <td className='item-label'>
                            <label htmlFor='user-edit-last_name'>Last Name</label>
                            {disabled && <span className="text-info">*</span>}
                          </td>
                        )}
                        <td>
                          <UserInputFieldDataRow
                            editMode={
                              !(
                                currentEditUser.isSSOUser ||
                                currentEditUser.isPlaceholder
                              )
                            }
                            user={newUserData}
                            updateUserFields={updateUserFields}
                            fieldName='last_name'
                            disabled={disabled}
                          />
                        </td>
                      </tr>
                      <tr>
                        {currentEditUser.isSSOUser ||
                        currentEditUser.isPlaceholder ? (
                          <React.Fragment>
                            <td
                              className='item-label'
                              style={{
                                verticalAlign: "middle",
                                borderTopStyle: "none",
                              }}
                            >
                              <label for='user-edit-email'>Email</label>
                            </td>
                            <td>
                              <UserInputFieldDataRow
                                editMode={false}
                                user={{
                                  ...newUserData,
                                  email: newUserData.email.replace(
                                    /^(placeholder\+)/,
                                    ""
                                  ),
                                }}
                                updateUserFields={updateUserFields}
                                fieldName='email'
                              />
                            </td>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <td className='item-label'>
                              <label htmlFor='user-edit-email'>Email</label>
                              {disabled && <span className="text-info">*</span>}
                            </td>
                            <td>
                              <UserInputFieldDataRow
                                editMode={
                                  !(
                                    currentEditUser.isSSOUser ||
                                    currentEditUser.isPlaceholder
                                  )
                                }
                                user={newUserData}
                                updateUserFields={updateUserFields}
                                fieldName='email'
                                disabled={disabled}
                              />
                            </td>
                          </React.Fragment>
                        )}
                      </tr>
                      {currentEditUser.email !== newUserData.email && (
                        <tr>
                          <td colSpan='2' className='alert alert-warning'>
                            Note: The teacher will need to login with this new
                            email.
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td className='item-label'>
                          <label htmlFor='school-select'>School(s)</label>
                          {disabled && <span className="text-info">*</span>}
                        </td>
                        <td style={{ width: "350px" }}>
                          <MultiSchoolReactSelect
                            handleChange={updateUserSchool}
                            value={newUserData.orgunits}
                            disabled={user.usertype === config.TEACHER_USERTYPE || disabled}
                            showAria={false}
                            handleBlur={() => {
                              if (haveSchoolsChanged && userHasAssociatedObjects) {
                                setShowReassignModal(true);
                              }
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='item-label'>User Role</td>
                        <td>
                          <span style={{ display: "block" }}>
                            <UserRoleSelect
                              onChange={(e) => {
                                console.log(
                                  "onChange received value:",
                                  e.value
                                );
                                updateUserFields("usertype", e.value);
                              }}
                              selected={searchObjectArray(
                                newUserData.usertype,
                                "value",
                                config.USERROLES
                              )}
                            />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {error && <div className='alert alert-danger'>{error}</div>}

                  <div className='d-flex'>
                    <button
                      className='btn btn-sm btn-primary mr-2'
                      onClick={() => {
                        handlePatchUser();
                      }}
                      disabled={showReassignModal || spin}
                    >
                      {spin ? (
                        <span>
                          <FA
                            color='white'
                            icon='spinner'
                            spin
                            className='mr-1'
                          />
                          Saving ...
                        </span>
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setCurrentEditUser(null);
                      }}
                      className='btn btn-sm btn-danger'
                      disabled={spin}
                    >
                      Cancel
                    </button>
                  </div>

                  { disabled && (
                  <div className='d-flex'>
                    <span className="mt-3 mb-0">
                      <span className="text-info"><b>*</b></span>
                      {" User info is synced from Clever and cannot be edited."}
                    </span>
                  </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(UserEditModal);
