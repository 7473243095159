import React from "react";
import {roundValues} from "./../../common/utils";
import "./Donut.scss";

/**
 * The donut is a circle-shaped chart that is color coded according
 * to percentages of data, much like a pie chart.
 * @returns {html} a tiny donut chart in the lesson assignment info
 * collapsible row.
 * @source https://stackoverflow.com/a/28345637/9457623
 */
const Donut = ({
  percentFill = null,
  totalLearners = 0,
  learnersCompleted = 0,
}) => {
  let caption = "";
  if (percentFill !== null) {
    caption = `${roundValues(percentFill * 100, 1)}%`;
  } else {
    caption = `${learnersCompleted}/${totalLearners}`;
  }

  const getStrokeColor = () => {
    let percent = "";
    if (percentFill !== null) {
      percent = percentFill;
    } else {
      percent = learnersCompleted / totalLearners;
    }
    if (percentFill === null && totalLearners === 0) return "";
    if (percent <= 0.33) return "#CB444A";
    if (percent <= 0.66) return "#FFC107";
    return "#77C75B";
  };

  return (
    <div className='donut_container'>
      <p className='donut_caption'>{caption}</p>
      <svg
        className='donut'
        width='60'
        height='60'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g>
          <title>Layer 1</title>
          <circle
            r='20.7'
            cy='30'
            cx='30'
            strokeWidth='7'
            stroke='#f2f2f2'
            fill='none'
          />
          <circle
            className='circle_colored'
            style={{
              strokeDashoffset:
                130 -
                130 *
                  (percentFill !== null
                    ? percentFill
                    : learnersCompleted / totalLearners),
            }} // out of 120, the circumference
            r='20.7'
            cy='30'
            cx='30'
            strokeWidth='7'
            stroke={getStrokeColor()}
            fill='none'
          />
        </g>
      </svg>
    </div>
  );
};

export default Donut;
