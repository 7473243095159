import React from "react";
import PropTypes from "prop-types";

function SubscriptionStatus({ subscription }) {
  const getStatus = () => {
    let status = "";

    if (subscription.pending_renewal) {
      status = "Pending Renewal";
    } else if (
      !subscription.finalized &&
      Date.parse(subscription.end_date) > Date.now()
    ) {
      status = "Processing";
    } else if (subscription.active) {
      status = "Active";
    } else if (!subscription.end_date) {
      status = "Awaiting Activation";
    } else {
      status = "Expired";
    }

    return status;
  };
  return <p>{getStatus()}</p>;
}

export default SubscriptionStatus;

SubscriptionStatus.propTypes = {
  subscription: PropTypes.object.isRequired,
};
