import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import config from '../../common/config';
import withAPI from '../../services/api';

import FAW from '../../containers/faw';
import FA from '../../containers/fa';
import RegistrationCodeRow from '../ManageAllRegistrationCodes/RegistrationCodeRow';
import AddRegCodeModal from '../ManageAllRegistrationCodes/AddRegCodeModal';


const OrgRegCodesTable = ({ api, orgId, saleId, orgName }) => {

  const [ready, setReady] = useState(false);
  const [regcodes, setRegcodes] = useState([]);
  const [codesToShow, setCodesToShow] = useState([]);
  
  const [spin, setSpin] = useState(true);
  const [error, setError] = useState(null);
  const [showAddRegCodeModal, setShowAddRegCodeModal] = useState(false);
  const [showAllCode, setShowAllCode] = useState(false);

  useEffect(() => {
    refreshRegCodeList();
  }, []);

  const refreshRegCodeList = () => {
    console.log("Asking for regcodes in org:",orgId);
    api.fetchRegCodesInOrg(orgId).then((r) => {
      setRegcodes(r);
      setReady(true);
      setSpin(false);
      console.log("OrgRegCodeTable got Activation Codes:", r)
    });
  }


  // TODO: if we need somewhere to show all regcodes, then we need to add a switch
  // to toggle showAllCode. Currently defaults to False and stays that way.

  useEffect(() => {
    // filter codes attached to this sale
    if (showAllCode) {
      setCodesToShow(regcodes);
    } else {
      const codesInSale = regcodes.filter(code => code.sale === saleId);
      setCodesToShow(codesInSale);
    }
  }, [regcodes, showAllCode]);


  return (
    <div className="common_border">
      <div className="common_heading d-flex justify-content-between align-items-center">
        <h4>Activation Codes</h4>
        <button className="btn btn-primary" onClick={() => setShowAddRegCodeModal(true)}>Add Activation Code</button>
      </div>

      <div className="common_dashboard_bg">
          <div className="row">
              <div className="col-12">
                  <div className="row mb-10">
                  </div>
                  { error &&
                    <div className="alert alert-danger" role="alert">{error}</div>
                  }
                  {spin &&
                    <div><h4><FA color="gray" icon="spinner" spin /> Loading Activation Codes...</h4></div>
                  }

                  {!spin && <div className="card">
                      <div className="progress-linear school_listing manage_learner_table" role="progressbar">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th className="datatable-header-cell">Activation Code</th>
                                <th># Robots Max</th>
                                <th># Robots Associated</th>
                                <th>Cloud Accounts?</th>
                                <th>Send Email</th>
                                <th>Edit</th>
                              </tr>
                            </thead>
                            <tbody className='sales_detail_table_tbody'>
                            {codesToShow.length > 0 ?
                              codesToShow.map((rc) => (
                                <RegistrationCodeRow
                                  key={rc.id}
                                  regcode={rc}
                                  refreshRegCodeList={refreshRegCodeList} />
                              ))
                              :
                              <RegistrationCodeRow nullRow={true} />
                            }
                            </tbody>
                          </table>

                      </div>
                  </div>}
              </div>
          </div>
      </div>

      {showAddRegCodeModal &&
        <AddRegCodeModal 
          shouldShowFunc={setShowAddRegCodeModal}
          orgId={orgId}
          saleId={saleId}
          orgName={orgName}
          refreshRegCodeList={refreshRegCodeList}
        />
      }

    </div>
  )
}

export default compose(
  withAPI
)(OrgRegCodesTable)
