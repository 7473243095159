const hasValidCharacters = (str) => {
  let regex = /[^a-zA-Z0-9+\-_.@]/;
  if (regex.test(str)) {
    return false;
  }
  return true;
};

export const getNameFieldErrMsg = (name) => {
  if (!name) {
    return "Field cannot be empty.";
  } else if (!hasValidCharacters(name)) {
    return "Must be alphanumeric (numbers, letters, +, -, _, ., @)";
  }
  return null;
};

export const getEmailFieldErrMsg = (email) => {
  if (!emailFieldIsValid(email)) {
    if (!email) {
      return "Field cannot be empty.";
    } else if (!hasValidCharacters(email)) {
      return "Must be alphanumeric (numbers, letters, +, -, _, ., @)";
    } else {
      return "Invalid email format.";
    }
  } else {
    return null;
  }
};

export const emailFieldIsValid = (email) => {
  let indexOne = email.lastIndexOf("@");
  let indexTwo = email.lastIndexOf(".");
  if (
    !(
      indexOne > 0 &&
      indexTwo > 0 &&
      indexOne < indexTwo &&
      indexTwo < email.length - 2
    ) ||
    !hasValidCharacters(email)
  ) {
    return false;
  }
  return true;
};

export const disableSaveBtn = (errors) => {
  if (
    errors.username ||
    errors.first_name ||
    errors.last_name ||
    errors.email
  ) {
    return true;
  }
  return false;
};

export const getUsernameFieldErrMsg = (username, index) => {
  if (!username) {
    return "Field cannot be empty.";
  } else if (!hasValidCharacters(username)) {
    return "Must be alphanumeric (numbers, letters, +, -, _, ., @)";
  } else if (username.length < 6) {
    return "Username must be at least 6 characters long.";
  }
  return null;
};
