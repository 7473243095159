import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import config from "../../common/config";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import withAPI from "../../services/api";
import SmallInputPopupContainer from "../UtilComps/SmallInputPopup";

const mapStateToProps = (state, ownProps?) => ({
  userId: state.auth.userId,
  user: state.auth.user,
});

const NewClassButton = ({
  api,
  disabled,
  user,
  addClassToCreator,
  autoClick,
  refreshList,
  extraBtnClasses,
  manageTeacherPageId = null, // for classes on Manage Teacher pages
}) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(false);
  const [className, setClassName] = useState("");

  const handleAddNewClass = (e, newClassId) => {
    setBusy(true);
    e.preventDefault();

    // First create new UserTag
    // Then apply the UserTag to logged-in User
    // Or to the teacher we're managing
    const tagData = { name: className, is_classroom: true };
    api
      .createUserTag(tagData)
      .then((resp) => {
        return resp.usertag.id;
      })
      .then((newClassId) => {
        if (
          user.usertype === config.TEACHER_USERTYPE ||
          user.usertype === config.ORGADMIN_USERTYPE ||
          user.usertype === config.ORGUNITADMIN_USERTYPE ||
          addClassToCreator
        ) {
          let idToAddClasses = manageTeacherPageId
            ? manageTeacherPageId
            : user.user.id;
          api.applyUserTagToUser(newClassId, idToAddClasses).then((resp) => {
            setShowModal(false);
            setError(null);
            setBusy(false);
            setClassName("");
            refreshList();
          });
        } else {
          setShowModal(false);
          setError(null);
          setBusy(false);
          setClassName("");
          refreshList();
        }
      })
      .catch((e) => {
        setError(e);
        setBusy(false);
        setClassName("");
      });
  };

  const handleClick = () => {
    if (!disabled) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (autoClick) {
      handleClick();
    }
  }, [autoClick]);

  return (
    <div
      style={{
        marginBottom: "10px",
        marginLeft: manageTeacherPageId ? "15px" : "-15px",
        display: "inline",
      }}
    >
      <button
        onClick={() => {
          handleClick();
        }}
        title='Add New Class'
        className={
          "btn btn-primary " +
          (disabled ? "disabled " : " ") +
          (extraBtnClasses ? extraBtnClasses : "")
        }
      >
        Add New Class &nbsp; <FAW icon='plus-square' />
      </button>
      {showModal && (
        <SmallInputPopupContainer
          popupHeader='Add New Class'
          busy={busy}
          setShowModal={setShowModal}
        >
          {error && (
            <div className='alert alert-danger'>
              Something went wrong adding a new class.
            </div>
          )}
          <form className='combined-label-inputs'>
            <div className='form-content form-group'>
              <div
                className='form_icons'
                style={{ display: "flex", height: "unset" }}
              >
                <label style={{ height: "inherit" }}>
                  <FA icon='file' />
                </label>
                <input
                  required
                  className='form-control input-underline input-lg'
                  placeholder='New Class Name'
                  name='New Class Name'
                  disabled={busy}
                  type='text'
                  value={className}
                  onChange={(e) => setClassName(e.target.value)}
                />
              </div>
              {busy ? (
                <div>
                  <h4>
                    <FA color='gray' icon='spinner' spin /> Adding class...
                  </h4>
                </div>
              ) : null}
            </div>
            <div style={{ display: "flex", gridGap: "10px" }}>
              <button
                onClick={handleAddNewClass}
                className='btn btn-sm btn-primary'
                disabled={busy}
              >
                Create Class
              </button>
              <button
                disabled={busy}
                onClick={() => {
                  setShowModal(false);
                  setClassName("");
                }}
                className='btn btn-sm btn-danger'
              >
                Cancel
              </button>
            </div>
          </form>
        </SmallInputPopupContainer>
      )}
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(NewClassButton);
