import React from 'react';
import ReactDOM from 'react-dom';
import reduxStore from './redux/store';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={reduxStore}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
