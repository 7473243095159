import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { cleanPlaceholderEmail } from "../../common/utils";
import FA from "../../containers/fa";
import { getUserRoleLabel } from "../../common/utils";
import { allowedUsertypeOptions } from "../InputFields/helpers";
import clever from "../../assets/images/clever.ico";

const mapStateToProps = (state, ownProps) => ({
  loggedInUser: state.auth.user,
  localBotsOnly: state.auth.localBotsOnly,
  orgStatus: state.auth.orgStatus,
});

const ManageTeachersRow = ({
  user,
  index,
  orgStatus,
  loggedInUser,
  localBotsOnly,
  setCurrentEditUser,
  setAssignRobotUserId,
  setEmailToChangePw,
  setEmailToResend,
  setUsernameToChangePw,
  setAssignSubscriptionUserId,
  setUserIdToChangePw,
  setExistingSubscriptions,
  setShowPasswordModal,
  setUserIdToResend,
  setCheckProviders,
  setShowResendModal,
}) => {
  const getColorCodeName = (user) => {
    if (orgStatus !== "active") {
      return "";
    } else if (
      user.recent_lessons === null ||
      user.enrolled_learners === null ||
      user.enrolled_learners === 0
    ) {
      return "";
    }
    const ratio = user.recent_lessons / user.enrolled_learners;
    if (ratio <= 0) {
      return "red";
    } else if (ratio >= 1) {
      return "green";
    } else {
      return "yellow";
    }
  };

  const getIcon = (user) => {
    const color = getColorCodeName(user);
    if (color === "green") {
      return <FA icon='check' className='has-wifi status-icon' />;
    } else if (color === "yellow") {
      return (
        <FA icon='exclamation-circle' color='#ffc107' className='status-icon' />
      );
    } else if (color === "red") {
      return <FA icon='times' className='no-wifi status-icon' />;
    }
    return "";
  };

  const getColorCodeStr = (user) => {
    const color = getColorCodeName(user);
    if (color === "green") {
      return "#D9ECDB";
    } else if (color === "red") {
      return "#F3D8DA";
    } else if (color === "yellow") {
      return "#FDF2D1";
    }
    return "";
  };

  /**
   * user.subscriptionsRaw is currently in format [['lct', 1]]
   * @param {Object} user
   * @returns {Array} of strings
   */
  const getSubscriptionsList = (user) => {
    let list = [];
    for (let i = 0; i < user.subscriptionsRaw.length; i++) {
      if (i === 0) {
        list.push(user.subscriptionsRaw[i][0].toUpperCase());
      } else {
        list.push(`, ${user.subscriptionsRaw[i][0].toUpperCase()}`);
      }
    }
    return list;
  };

  const userRoleLabel = getUserRoleLabel(user.usertype);
  const combinedSchoolNames = user.orgunits.map(ouId => ouId.name).join(", ");

  /**
   * When viewing a row for a more privileged user (eg SchoolAdmin viewing an
   * OrgAdmin), certain buttons should be disabled/hidden
   */
  const morePrivilegedUser = () => {
    let b = !allowedUsertypeOptions(loggedInUser.usertype)
      .map((ut) => ut.value)
      .includes(user.usertype);
    return b;
  };

  return (
    <div
      className={`users_table users_table_row ${
        index % 2 === 0 ? "gray_row" : ""
      }`}
      key={user.key}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          textAlign: "left",
        }}
      >
        {user.isPlaceholder ? (
          <React.Fragment>{`Invited`}</React.Fragment>
        ) : (
          <React.Fragment>
            {user.last_name}, {user.first_name}
          </React.Fragment>
        )}
      </span>
      <div
        style={{
          width: "100%",
          textAlign: "left",
          paddingLeft: "0px",
        }}
      >
        <span>
          {user.isPlaceholder ? (
            cleanPlaceholderEmail(user.email)
          ) : (
            <span>
              {user.email}
              {user.has_sso_login && (
                <img
                  style={{width: '1rem', marginLeft: '0.25rem'}}
                  alt='clever'
                  title='Clever-synced'
                  src={clever}
                />
              )}
            </span>
          )}
        </span>
      </div>
      <div
        style={{
          width: "100%",
          textAlign: "left",
          paddingLeft: "0px",
        }}
      >
        {combinedSchoolNames}
      </div>
      <div style={{ justifySelf: "center" }}>{user.robotCount}</div>

      <div style={{ justifySelf: "center" }}>{user.enrolled_learners}</div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: getColorCodeStr(user),
        }}
      >
        <p>
          {user.recent_lessons}
          <span className='ml-1'>{getIcon(user)}</span>
        </p>
      </div>

      <div>{getSubscriptionsList(user)}</div>
      <div style={{ justifySelf: "center" }}>{userRoleLabel}</div>

      <div className='td_buttons'>
        {!morePrivilegedUser() &&
          <span style={{ position: "relative" }}>
            <button
              className='btn btn-sm btn-primary admin_settings_btn'
              disabled={morePrivilegedUser()}
              onClick={() => {
                setCurrentEditUser(user);
              }}
              assignment-data-title='Edit User'
            >
              <FA color='white' icon='edit' />
            </button>
          </span>
        }

        <span style={{ position: "relative" }}>
          <button
            className='btn btn-sm btn-primary admin_settings_btn'
            onClick={() => {
              setAssignRobotUserId(user.id);
            }}
            assignment-data-title='Assign Robots'
          >
            <FA color='white' icon='robot' />
          </button>
        </span>
        {localBotsOnly !== true && !morePrivilegedUser() && (
          <span style={{ position: "relative" }}>
            <button
              className='btn btn-sm btn-primary admin_settings_btn'
              onClick={() => {
                setAssignSubscriptionUserId(user.id);
                setExistingSubscriptions(user.subscriptionsRaw);
              }}
              assignment-data-title='Manage Subscriptions'
            >
              <FA color='white' icon='plus-circle' />
            </button>
          </span>
        )}

        {localBotsOnly !== true && !user.isPlaceholder && !user.isSSOUser && !morePrivilegedUser() && (
          <span style={{ position: "relative" }}>
            <button
              className='btn btn-sm btn-primary admin_settings_btn'
              assignment-data-title='Reset Password'
              placement='top'
              onClick={() => {
                setEmailToChangePw(user.email);
                setUsernameToChangePw(user.username);
                setUserIdToChangePw(user.id);
                setShowPasswordModal(true);
              }}
            >
              <FA color='white' icon='key' />
            </button>
          </span>
        )}

        {localBotsOnly !== true &&
          !(user.onlySSOLogin && !user.isPlaceholder) && !morePrivilegedUser() && (
            <span style={{ position: "relative" }}>
              <button
                className='btn btn-sm btn-primary admin_settings_btn'
                assignment-data-title='Resend Welcome Email'
                placement='top'
                onClick={() => {
                  if (user.isPlaceholder) {
                    setEmailToResend(cleanPlaceholderEmail(user.email));
                  } else {
                    setEmailToResend(user.email);
                  }
                  setUserIdToResend(user.id);
                  setCheckProviders(user.isPlaceholder);
                  setShowResendModal(true);
                }}
              >
                <FA color='white' icon='envelope' />
              </button>
            </span>
          )}
        <span style={{ position: "relative" }}>
          <Link
            to={"/admin/manage-teacher/" + user.id}
            className='btn btn-sm btn-primary admin_settings_btn'
            assignment-data-title='Manage Teacher'
            placement='top'
          >
            <FA color='white' icon='tasks' />
          </Link>
        </span>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps))(ManageTeachersRow);
