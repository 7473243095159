import React, { useState, useEffect } from "react";
import FAW from "../../containers/fa";
import withAPI from "../../services/api";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const UserWelcomeResendModal = ({
  userIdToResend,
  email,
  setShowResendModal,
  checkProviders,
  api,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [spin, setSpin] = useState(null);
  const [providers, setProviders] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);

  useEffect(() => {
    // check if we need to render a list of Providers to choose from
    if (checkProviders) {
      api
        .checkTeacherWelcomeEmailOptions(userIdToResend)
        .then((res) => {
          setLoading(false);
          setProviders(res.providers);
          if (res.providers.length > 0) {
            setSelectedProvider(res.providers[0]);
          }
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [checkProviders]);

  const handleSubmit = () => {
    setSpin(true);
    console.log("Triggering resend for", userIdToResend);
    api
      .triggerTeacherWelcomeEmail(userIdToResend, selectedProvider)
      .then(() => {
        console.log("resent welcome email");
        setSuccess(true);
        setSpin(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setSpin(false);
      });
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Resend Welcome Email</p>

            <ClosePopupBtn closePopupFunc={setShowResendModal} />
          </div>
          <div className='common_dashboard_bg'>
            {loading && (
              <div className='alert alert-info'>
                <FAW icon='spinner' spin /> Loading ...
              </div>
            )}

            {!loading && (
              <div className='row'>
                <div className='col-12'>
                  {error && (
                    <div>
                      {spin === null ? (
                        <div className='alert alert-danger'>
                          Something went wrong ... Please try again later.
                        </div>
                      ) : (
                        <div className='alert alert-danger'>
                          Something went wrong when resending Welcome Email to{" "}
                          {email}. Please try again later.
                        </div>
                      )}
                      <div className='d-flex'>
                        <button
                          onClick={() => {
                            setShowResendModal(false);
                          }}
                          className='btn btn-md btn-primary'
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  )}

                  {success && (
                    <div>
                      <div className='alert alert-success'>
                        Welcome Email has been sent to {email}.
                      </div>
                      <div className='d-flex'>
                        <button
                          onClick={() => {
                            setShowResendModal(false);
                          }}
                          className='btn btn-md btn-primary'
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  )}

                  {!error && !success && (
                    <div>
                      <p>
                        Would you like to resend the Welcome Email to this user?
                        An email will be sent to {email} with instructions to
                        set up the account as well as the password.
                      </p>

                      {providers && providers.length > 0 && (
                        <div className='mb-4'>
                          <p className='mb-2'>
                            This user has been invited as an SSO user. Please
                            select the SSO provider so that the proper
                            instructions can be sent to the user.
                          </p>

                          <select
                            disabled={spin}
                            className='form-control input-lg'
                            onChange={(e) => {
                              setSelectedProvider(e.target.value);
                            }}
                          >
                            {providers.map((provider) => (
                              <option key={provider} value={provider}>
                                {provider.charAt(0).toUpperCase() +
                                  provider.slice(1)}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      {spin ? (
                        <div className='alert alert-info'>
                          <FAW icon='spinner' spin /> Sending welcome email ...
                        </div>
                      ) : (
                        <div className='d-flex'>
                          <button
                            onClick={() => {
                              handleSubmit();
                            }}
                            className='btn btn-sm btn-primary'
                            disabled={
                              providers &&
                              providers.length > 0 &&
                              selectedProvider === null
                            }
                          >
                            Yes, resend email
                          </button>
                          <button
                            onClick={() => {
                              setShowResendModal(false);
                            }}
                            className='btn btn-sm btn-danger ml-4'
                          >
                            No, take me back
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAPI(UserWelcomeResendModal);
