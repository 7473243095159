import React from 'react';

import logo from '../assets/images/logo.png';
import './Gate.scss';

const Gate = ({children, boxWidthClass, isLogin=false}) => {
  return (
    <div className="login-page">
      <div className="d-flex justify-content-center login_bg_color align-items-center" style={{height: "100%"}}>
        <div className={(isLogin ? 'login_gate ' : '') + "loginbox " + (boxWidthClass || (isLogin ? '' : "col-md-4")) }>
          <div className="form-group text-center">
            <div><img src={logo} alt="Logo"/></div>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Gate;
