import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../common/config';
import withAPI from '../../services/api';
import MainDashboard from '../../partials/Main';
import OrgSalesTable from '../../components/SuperAdmin/OrgSalesTable';
import {useTitle} from '../../common/utils';


import FA from '../fa';

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const AllSales = ({ api, user, match }) => {

  useTitle('Sales');

  let history = useHistory();

  return (
    <div>
      <MainDashboard>
          <div className="common_border">
            <OrgSalesTable
              allSale={true}
            />
          </div>

      </MainDashboard>
    </div>
  );


}

export default compose(
  connect(mapStateToProps),
  withAPI
)(AllSales);
