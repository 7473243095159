import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import './WiFiForm.css';

import config from '../common/config';

import FA from '../containers/fa';
import FAW from '../containers/faw';
import WiFiRebootModal from './WiFiRebootModal.js';

import withAPI from '../services/api';

const WiFiForm = ({api}) => {
  const [selectedSSID, setSelectedSSID] = useState(null);
  const [pass, setPass] = useState("");
  const [username, setUsername] = useState("");

  const [showNext, setShowNext] = useState(false);

  const [SSIDs, setSSIDs] = useState([]);
  const [success, setSuccess] = useState(false);

  const [spinAPs, setSpinAPs] = useState(false);
  const [spinConnect, setSpinConnect] = useState(false);

  const [pwNeeded, setPwNeeded] = useState(false);
  const [usernameNeeded, setUsernameNeeded] = useState(false);

  const [error, setError] = useState(null);

  const [wifiStatus, setWifiStatus] = useState('');
  const [fullAbiiAccess, setFullAbiiAccess] = useState(null);
  const [checkingWifiStatus, setCheckingWifiStatus] = useState(true);
  
  const checkWifi = (fullCheck=false, forceCheck=false, performAudio=false) => new Promise( (resolve) => {
    api.fetchWifiStatus(fullCheck, forceCheck).then((status) => {

      // do audio on first try
      if (performAudio === true) {
        if (status['associated'] === true) {
          if (status['critical_cons'] && status['critical_cons']['connected'] === false) {
            api.wifinoresources();
          }
        } else {
          api.wifiselectaudio();
        }
      }

      console.log(status)
      let linkQuality = '0/100';
      if (status['details'] && status['details']['Link Quality']) {
        linkQuality = status['details']['Link Quality'];
      }

      let wifiName = 'Unknown';
      if (status['details'] && status['details']['ESSID']) {
        wifiName = status['details']['ESSID'];
      }
      let newState = {
        linkQuality: linkQuality,
        name: wifiName,
      };
      
      if (status['associated']===true) {
        newState.associated = true
        setWifiStatus(newState)
      } else {
        setWifiStatus({
          associated: false,
        })
      }
      if (fullCheck === true) {
        if (status['critical_cons'] && status['critical_cons']['connected'] != null) {
          setFullAbiiAccess(status['critical_cons']['connected']);
        }
      }
      resolve('standard');
    }).catch((error) => {
      console.log(error);
      console.log('Error checking wifi');
      resolve('standard');
      setWifiStatus({
        associated: false,
      })
    })
  })

  useEffect(() => {
    
    let fetchWifiTimeout = null;
    const fetchWifiScheduler = (fullCheck=true, forceCheck=false, performAudio=false) => {
      checkWifi(fullCheck, forceCheck, performAudio).then( (data) => {
        if (checkingWifiStatus === true) {
          setCheckingWifiStatus(false);
        }
        if (data === 'is_checking') {
          console.log("wifi_controller is checking connections. Will check again in 20 seconds.")
          fetchWifiTimeout = setTimeout(fetchWifiScheduler, 20000);
        } else {
          console.log("Checked wifi. Check again in 5 seconds.")
          fetchWifiTimeout = setTimeout(fetchWifiScheduler, 5000);
        }
      });
    };

    // First iteration: force full check with audio
    fetchWifiScheduler(true, true, true);
    // clear timer on unmount
    return () => {
      // clearInterval(intervalCheckWifi);
      clearTimeout(fetchWifiTimeout);
    }
  }, []);


  const ssidForSelected = () => SSIDs.find((x) => x.bssid == selectedSSID)

  useEffect(() => {
    setSpinAPs(true);
    api.wifiScan().then((resp_json) => {
      resp_json.sort((x, y) => {
        if (x.ssid.toUpperCase() < y.ssid.toUpperCase())
          return -1;
        else if (x.ssid.toUpperCase() > y.ssid.toUpperCase())
          return 1;
        return 0;
      });
      setSSIDs(resp_json);
      setSpinAPs(false);
      setError(null);
    }).catch((e) => {
      setSpinAPs(false);
      setError("Something went wrong finding access points.");
    });
  }, []);

  useEffect(() => {
    if (selectedSSID) {
      const SSIDObj = ssidForSelected();
      if (SSIDObj) {
        if (!SSIDObj.user_input) {
          setPwNeeded(false);
          setUsernameNeeded(false);
          return;
        }

        if (SSIDObj.user_input.indexOf("password") !== -1 ||
            SSIDObj.user_input.indexOf("psk") !== -1) {
          setPwNeeded(true);
          api.p_wificonnect();
        } else {
          setPwNeeded(false);
        }

        if (SSIDObj.user_input.indexOf("identity") !== -1) {
          setUsernameNeeded(true);
          api.pu_wificonnect();
        } else {
          setUsernameNeeded(false);
        }
      }
    }
  }, [selectedSSID]);

  const onSubmit = (e) => {
    e.preventDefault();

    const SSIDObj = ssidForSelected();
    if (!SSIDObj) {
      setError("Please select an access point.");
      return
    }

    if (pwNeeded && (!pass || pass === "")) {
      setError("Please specify a password.");
      return
    }

    if (pwNeeded && pass.length < 8) {
      setError("Passwords must be at least 8 characters long.");
      return
    }

    if (usernameNeeded && (!username || username === "")) {
      setError("Please specify a Username.");
      return
    }

    setSpinConnect(true);
    let data = {
      "ssid": SSIDObj.ssid
    }

    if (usernameNeeded) {
      data.identity = username
      data.password = pass
    } else if (!usernameNeeded && pwNeeded) {
      data.psk = pass
    }

    api.wifiConfig(data).then((resp_json) => {
      setSpinConnect(false);
      if (resp_json.status === 'success') {
        setSuccess(true);
        setError(null);
        setShowNext(true);
      } else if (resp_json.status === 'mixed') {
        setSuccess(false);
        setError("ABii has connected to wifi, but can't reach the internet. Please check your firewall or other security settings.");
      } else {
        setSuccess(false);
        setError("Having trouble connecting to your network. Please make sure your password is correct.");
      }
    }).catch((e) => {
      setSpinConnect(false);
      setSuccess(false);
      setError("Having trouble connecting to your network. It could be a firewall or other security issue.");
    });

    return false;
  }


  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12">
          {config.usingHTTPS && <Link to="/">&laquo; Back</Link>}
        </div>
      </div>
      <div className="row" style={{"width": "100%"}}>
        <div className="col-12">
          {success && <WiFiRebootModal />}
          <form name="LogInForm">
            <div className="form-content form-group container">

              <div className="row">
                
              {checkingWifiStatus ? 
                <div id="wifi-status-box">
                  Current Wifi Status: <FAW icon="wifi" className="wifi-status-icon default-wifi" /><br/>
                  <span>
                    <FAW icon="spinner" spin style={{"color": "black", "marginRight": "16px"}} />
                    Checking Wifi Status...
                  </span>
                </div>
                :
                <div id="wifi-status-box">
                  {wifiStatus.associated ?
                    <span>
                      Current Wifi Status: <FAW icon="wifi" className="has-wifi wifi-status-icon" /><br/>
                      <span>
                        {"Wifi Name: " + (wifiStatus ? wifiStatus.name : 'Unknown')} <br/>
                        {"Signal Strength: " + (wifiStatus ? wifiStatus.linkQuality : '0/100')} <br/>
                        ABii Access:  
                        { fullAbiiAccess ? 
                          <span> <FAW icon="check" className="has-wifi" /> Yes </span>
                          :
                          <span> <FAW icon="times" className="no-wifi" /> No, <Link to="check-access/"> check details </Link> </span>
                        }
                      </span>
                    </span>
                    :
                    <span>
                      Current Wifi Status: <FAW icon="wifi" className="no-wifi wifi-status-icon" /><br/>
                      <span>
                        No Wifi Connection
                      </span>
                    </span>
                  }
                </div>
              }
                
              </div>

              <div className="row">
                <div className="form_icons center-div">
                  <label>{spinAPs
                    ? <FA icon="spinner" spin />
                    : <FA icon="wifi" />}</label>
                  <select disabled={spinAPs} onChange={(e) => setSelectedSSID(e.target.value)} className="input-lg input-underline form-control">
                      <option>{spinAPs
                        ? "Scanning..."
                        : "Select Access Point"}</option>
                      {SSIDs.map((ssid, i) => (
                        <option key={i} value={ssid.bssid}>{ssid.ssid}</option>
                      ))}
                    </select>
                </div>
              </div>
              {usernameNeeded &&
                <div className="row ">
                  <div className="form_icons center-div">
                    <label><FA icon="user" /></label>
                    <input required className="form-control input-underline input-lg"
                           disabled={!usernameNeeded} placeholder="Username" type="text" value={username} onChange={(e) => setUsername(e.target.value)}/>
                  </div>
                </div>
              }
              {pwNeeded &&
                <div className="row">
                  <div className="form_icons center-div">
                    <label><FA icon="lock" /></label>
                    <input required className="form-control input-underline input-lg"
                           disabled={!pwNeeded} placeholder="Password" type="password" value={pass} onChange={(e) => setPass(e.target.value)}/>
                  </div>
                </div>
              }
              {error &&
                <div className="row d-flex">
                  <div className="alert alert-danger center-div" role="alert">{error}</div>
                </div>
              }
              {success &&
                <div className="row d-flex">
                  <div className="alert alert-success center-div" role="alert">Successfully connected.</div>
                </div>
              }
              <div className="row d-flex justify-content-between align-items-center">
                {showNext && <Link to="/login" className="btn btn-lg fs-14 btn-primary col-md-5 center-div">
                  Next
                </Link>}
                {!showNext && <button type="submit" onClick={onSubmit} className="btn btn-lg fs-14 btn-primary col-md-5 center-div">
                  {!spinConnect
                   ? <span>Connect</span>
                   : <FAW icon="spinner" spin />}
                </button>}
              </div>
            </div>

              <Link to="/check-access" id="check-access-link"> Detailed Access to ABii Resources </Link>
          </form>
        </div>

      </div>
    </div>
  );
}

export default compose(
  withAPI
)(WiFiForm);
