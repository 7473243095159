import React from "react";
import {Link} from "react-router-dom";
import config from "../../common/config";
import {cleanPlaceholderEmail} from "../../common/utils";
import FAW from "../../containers/faw";

const AccountRow = ({
  account,
  nullRow,
  showActions,
  index,
  setEmailToResend,
  setUserIdToResend,
  setShowResendModal,
  setCheckProviders,
}) => {
  if (nullRow === true) {
    return <div>No accounts found.</div>;
  }

  const isPlaceholder = account.userprofile.is_placeholder;
  const emailDisplay = isPlaceholder
    ? cleanPlaceholderEmail(account.email)
    : account.email;

  return (
    <div
      key={account.id}
      className='account_row_data'
      style={{backgroundColor: index % 2 === 0 ? "rgba(0, 0, 0, 0.05)" : ""}}
    >
      <div style={{display: 'block', textAlign: "left"}}>
        {isPlaceholder ? `(Invited) ${emailDisplay}` : account.username}

        {account.userprofile.has_sso_login && (
          <span data-title='Linked with SSO'>
            <FAW
              icon='project-diagram'
              style={{color: "#4aa5d7", marginLeft: "4px"}}
            />
          </span>
        )}
      </div>
      <div style={{textAlign: "left"}}>{account.first_name}</div>
      <div style={{textAlign: "left"}}>{account.last_name}</div>
      <div style={{textAlign: "left"}}>{account.userprofile.usertype}</div>

      <div style={{textAlign: "left"}}>
        {account.userprofile.robot_tags
          ? account.userprofile.robot_tags.length
          : 0}
      </div>
      <div style={{textAlign: "left"}}>
        {account.userprofile.enrolled_learners}
      </div>
      <div style={{textAlign: "left"}}>
        {account.userprofile.recent_lessons}
      </div>

      <div style={{textAlign: "left", position: "relative"}}>
        {showActions.includes("email") &&
          account.userprofile.usertype === config.TEACHER_USERTYPE && (
            <button
              className='btn btn-sm btn-primary admin_settings_btn m-1 '
              subscription-data-title='Resend Welcome Email'
              placement='top'
              onClick={() => {
                setEmailToResend(emailDisplay);
                setUserIdToResend(account.id);
                setCheckProviders(isPlaceholder);
                setShowResendModal(true);
              }}
            >
              <FAW icon='envelope' />
            </button>
          )}
        {showActions.includes("manage") && (
          <Link
            to={"/admin/manage-teacher/" + account.id}
            className='btn btn-sm btn-primary admin_settings_btn m-1 '
            subscription-data-title='Manage Teacher'
            placement='top'
          >
            <FAW icon='tasks' />
          </Link>
        )}
      </div>
    </div>
  );
};

export default AccountRow;
