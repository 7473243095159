import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";

import config from "../../common/config";

const ForcePWResetModal = ({ closeModal }) => {

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper-medium">
        <div className="common_border">
          <div className="common_heading">
            <p>{"Account Security Warning"}</p>
          </div>
          <div className="common_dashboard_bg" style={{maxHeight: '525px', overflowY: 'scroll'}}>

            <div>
              <p className='mb-0'>
                {"The password you currently use to login to abiis-world.com appears in a list of passwords that has been exposed as part of a data breach."}
              </p>
              <p className='my-2'>
                {"This does "}
                <strong>not</strong>
                {" mean that your abiis-world.com account has been compromised!"}
              </p>
              <p className='my-2'>
                {"In order to keep your account as secure as possible, please go to our settings page and reset your password now."}
              </p>
              <div className='my-3'>
                <Link className="btn btn-primary mr-3" onClick={closeModal} to={'/settings'}> Reset My Password </Link>
                <button className="btn btn-secondary" onClick={closeModal}> Ignore </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ForcePWResetModal;
