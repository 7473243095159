import config from '../common/config';

import { resolveWithFull, handleErr, fetchWithError } from './http';

const handleLogin = (promise) => {
  return promise.then((resp_json) => {
    sessionStorage.setItem('authToken', resp_json.token);
    sessionStorage.setItem('user', JSON.stringify(resp_json.profile));
    sessionStorage.setItem('organization', JSON.stringify(resp_json.organization));
    sessionStorage.setItem('studentCap', resp_json.profile.student_cap);
    sessionStorage.setItem('hasRobots', JSON.stringify(resp_json.has_robot));
    sessionStorage.setItem('hasAccounts', resp_json.has_accounts);
    sessionStorage.setItem('localBotsOnly', resp_json.profile.organization.local_bots_only);
    sessionStorage.setItem('forcePWReset', resp_json.profile.force_pw_reset);
    sessionStorage.setItem('orgStatus', resp_json.profile.organization.status);
    console.log("auth logged in userId: ",resp_json.id);
    sessionStorage.setItem('userId', resp_json.id);
    return [
      resp_json.profile,
      resp_json.token,
      resp_json.organization,
      resp_json.has_robot,
      resp_json.has_accounts,
      resp_json.profile.organization.local_bots_only,
      resp_json.profile.force_pw_reset,
      resp_json.profile.organization.status,
    ];
  });
}

const logout = () => {
  return new Promise((resolve) => {
    // Clear session storage
    var authToken = sessionStorage.getItem('authToken');
    var user = JSON.parse(sessionStorage.getItem('user'))
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('organization');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('homeClassId');
    sessionStorage.removeItem('studentCount');
    sessionStorage.removeItem('studentCap');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('hasRobots');
    sessionStorage.removeItem('hasAccounts');
    sessionStorage.removeItem('seenNoRobot');
    sessionStorage.removeItem('seenNoAccount');
    sessionStorage.removeItem('localBotsOnly');
    sessionStorage.removeItem('forcePWReset');
    sessionStorage.removeItem('seenForcePWReset');
    sessionStorage.removeItem('orgStatus');
    // If we have an authToken, also ping backend to clear session cookies
    if (user && user.has_sso_login && !user.can_login_with_password && authToken) {
      const data = {
        method: 'POST',
        headers: { 'Authorization': `Token ${authToken}` },
        body: null,
      }
      fetchWithError(`${config.apiBaseUrl}/api/oauth/logout/`, data).then((resp) => {
        console.log("sso-logout returned:",resp);
        resolve();
      }).catch((e) => {
        console.log("Error hitting /api/oauth/logout/:",e);
        resolve();
      });
    } else if (authToken) {
      const data = {
        method: 'POST',
        headers: { 'Authorization': `Token ${authToken}` },
        body: null,
      }
      fetchWithError(`${config.apiUrl}/logout/`, data).then((resp) => {
        resolve();
      }).catch((e) => {
        console.log("Error hitting /logout/:",e);
        resolve();
      });
    } else {
      // Even if no authtoken, hit Logout to clear any leftover Session cookies
      // Normally, Logout by GET is bad practice, however, we might to do it
      // that way in order to avoid the effects of a leftover csrftoken
      const data = {
        method: 'GET',
        body: null,
      }
      fetchWithError(`${config.apiUrl}/logout/`, data).then((resp) => {
        resolve();
      }).catch((e) => {
        console.log("Error hitting /logout/:",e);
        resolve();
      });
      //resolve();
    }
  })
}

//const register = ({isTeacher, email, username, pass, askRobotInfo, organizationName, fullName}) => {
const register = ({email, username, pass, orgCode, firstName, lastName}) => {

  
  // School: OrgAdmins
  // Home: Teacher
  // So, let backend decide base on orgCode
  let postData = {
    user: {
      email: email,
      username: username,
      password: pass,
      first_name: firstName,
      last_name: lastName,
    },
    organization_code: orgCode
  }

  const data = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(postData)
  }

  return handleLogin(fetchWithError(`${config.apiUrl}/register/`, data));
}

const login = (username, password) => {
  const data = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({username, password})
  }

  return handleLogin(fetchWithError(`${config.apiUrl}/login/`, data));
}

const superAdminLoginAsOrgAdmin = (orgId, token) => {
  // sends request with (SuperAdmin's) token
  // and then logs in as the (Secret OrgAdmin) user returned from backend
  const data = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`
    },
    body: JSON.stringify({"orgId": orgId})
  }

  return handleLogin(fetchWithError(`${config.apiUrl}/login-as-orgadmin/`, data));
}

const SSOLogin = (profileParams) => {
  const prom = new Promise((resolve) => {
    resolve(profileParams);
  });
  return handleLogin(prom);
}

const learnerLogin = (token, username, password, batch_id) => {
  const data = {
    username: username,
    password: password,
    batch_id: batch_id
  }
  const http = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`
    },
    body: JSON.stringify(data)
  }

  return fetchWithError(`${config.apiUrl}/learner/login/`, http);
}

export const authService = {
  register,
  login,
  learnerLogin,
  handleLogin,
  logout,
  superAdminLoginAsOrgAdmin,
  SSOLogin,
};
