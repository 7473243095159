import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";

// Also used for single add
const BulkAddRobotsModal = ({
  api,
  orgId,
  match,
  rbtSerials,
  shouldShowFunc,
  refreshList,
  orgName,
}) => {
  const [shouldSpin, setShouldSpin] = useState(false);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  // addWithCloudAccounts set to null, so ignored on backend for now
  const [addWithCloudAccounts, setAddWithCloudAccounts] = useState(null);

  if (orgId) {
    orgId = orgId;
  } else {
    orgId = match.params.orgId;
  }

  useEffect(() => {
    setSuccess(false);
    setError(false);
  }, [rbtSerials]);

  const bulkAddRobots = () => {
    setError(false);
    setSuccess(false);
    setErrMsg(null);
    setShouldSpin(true);
    console.log("Adding robots ", rbtSerials, "to org ", orgId);
    let data = {
      serials: rbtSerials,
      org_id: orgId,
      cloud_accounts: addWithCloudAccounts,
    };
    api
      .bulkAssignRobotsToOrganization(data)
      .then((resp) => {
        setShouldSpin(false);
        setSuccess(true);
      })
      .catch((err) => {
        setErrMsg("Unknown error.");
        setError(true);
        console.log("Error Bulk Adding Robots:  ", err);
      });
  };

  const refreshAndClose = () => {
    refreshList();
    shouldShowFunc(false);
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            {rbtSerials.length > 1 ? (
              <p>Bulk Assign Robots</p>
            ) : (
              <p>Assign Robot</p>
            )}

            <div
              className='pdf_popup_close'
              onClick={() => shouldShowFunc(false)}
            >
              <FA icon='times' />
            </div>
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && <div className='alert alert-danger'>{errMsg}</div>}

                {success && (
                  <div className='alert alert-success' role='alert'>
                    Successfully assigned the robot(s)!
                  </div>
                )}

                {rbtSerials.length > 0 ? (
                  <div className=''>
                    <div>
                      <p className='mb-1'>
                        Assigning {rbtSerials.length} Robot(s) to{" "}
                        <strong>{orgName}</strong>:
                      </p>
                      <ol>
                        {rbtSerials.map((serial) => (
                          <li key={serial}>{serial}</li>
                        ))}
                      </ol>
                    </div>
                    {success ? (
                      <div className='mt-4'>
                        <button
                          onClick={() => refreshAndClose()}
                          className='btn btn-sm btn-primary'
                        >
                          Okay
                        </button>
                      </div>
                    ) : (
                      <div className='mt-4'>
                        <button
                          onClick={bulkAddRobots}
                          className='btn btn-sm btn-primary'
                        >
                          Confirm and Assign
                        </button>
                        <button
                          onClick={() => shouldShowFunc(false)}
                          className='btn btn-sm btn-danger ml-2'
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className=''>
                    <div>
                      <p>Please select some robots before assigning.</p>
                    </div>
                    <div className='mt-3'>
                      <button
                        onClick={() => shouldShowFunc(false)}
                        className='btn btn-sm btn-primary'
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                )}

                <div className='alert alert-info mt-3' role='alert'>
                  After assignment, please remember to attach the robot(s) to
                  appropriate subscription(s) via the Subscriptions section.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, withAPI)(BulkAddRobotsModal);
