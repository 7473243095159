import React, {useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import withAPI from "../../services/api";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  userId: state.auth.userId,
  unsyncedLessons: state.status.unsyncedLessons,
  qsyncerSyncing: state.status.qsyncerSyncing,
  robotType: state.status.robotType,
  securityQPending: state.status.securityQPending,
  syncBtnClicked: state.status.syncBtnClicked,
});

const UserDeleteModal = ({
  setShowDeleteModal,
  api,
  userToDelete,
  fetchUsers,
}) => {
  const [error, setError] = useState(false);

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Remove User</p>
            <ClosePopupBtn closePopupFunc={setShowDeleteModal} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && (
                  <div>
                    <div className='alert alert-danger'>
                      Something went wrong removing the user.
                    </div>
                    <div className='d-flex'>
                      <button
                        onClick={() => {
                          setShowDeleteModal(false);
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}

                {!error && (
                  <div>
                    <p>
                      Are you sure you want to delete this user? This action
                      cannot be reversed.
                    </p>
                    <div className='d-flex'>
                      <button
                        onClick={() => {
                          setShowDeleteModal(false);
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        No, take me back
                      </button>
                      <button
                        onClick={() => {
                          api
                            .deleteUser(userToDelete)
                            .then(() => {
                              console.log("deleted acct");
                              fetchUsers();
                            })
                            .catch((e) => {
                              console.log(e);
                              setError(true);
                            });
                          if (!error) {
                            setShowDeleteModal(false);
                          }
                        }}
                        className='btn btn-sm btn-danger ml-4'
                      >
                        Yes, remove user
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(UserDeleteModal);
