import React, {useState} from "react";
import {compose} from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const LearnerRowDeleteModal = ({
  api,
  shouldShowFunc,
  learnerId,
  callback,
  classId,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    if (classId) {
      api
        .removeUserTagFromLearner(classId, learnerId)
        .then(() => {
          shouldShowFunc(false);
          if (callback) {
            callback();
          }
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    } else {
      api
        .deleteLearner(learnerId)
        .then(() => {
          shouldShowFunc(false);
          if (callback) {
            callback();
          }
          setLoading(false);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    }
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Remove Learner</p>
            <ClosePopupBtn closePopupFunc={shouldShowFunc} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {loading ? (
                  <div className='col-12'>
                    <h4>
                      <FA color='gray' icon='spinner' spin /> Removing Learner
                      ...
                    </h4>
                  </div>
                ) : (
                  <div className='col-12'>
                    {error && (
                      <div className='alert alert-danger'>
                        Something went wrong removing the learner.
                      </div>
                    )}

                    {!error && (
                      <div style={{textAlign: "center"}}>
                        {classId ? (
                          <div style={{marginBottom: "10px"}}>
                            <p>
                              Are you sure you want to remove this learner from
                              this class?
                            </p>
                            <p>
                              <strong>Note: </strong>If this learner is not in
                              any of your other classes, the learner will be
                              removed from your open assignments (Not Started,
                              Postponed, or In-Progress).
                            </p>
                          </div>
                        ) : (
                          <div style={{marginBottom: "10px"}}>
                            <p>Are you sure you want to remove this learner?</p>
                          </div>
                        )}

                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "50% 50%",
                          }}
                        >
                          <button
                            className='btn btn-sm btn-primary'
                            style={{width: "fit-content", margin: "auto"}}
                            onClick={() => shouldShowFunc(false)}
                          >
                            No, take me back
                          </button>
                          <button
                            className='btn btn-sm btn-danger ml-4'
                            style={{width: "fit-content", margin: "auto"}}
                            onClick={handleDelete}
                          >
                            Yes, remove learner
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI)(LearnerRowDeleteModal);
