import React, {useState} from "react";
import withAPI from "../../services/api";

import "./LearnerResetPWModal.scss";

import FA from "../../containers/fa";

const LearnerResetPWModal = ({
  api,
  setPWFunc,
  shouldShowFunc,
  learnerFirstName,
  learnerLastName,
  clsId,
  learnerId,
  setAutosaveSuccess,
  setShowAutosave,
  setShouldSpin,
}) => {
  const [pw1, setPW1] = useState("");
  const [pw2, setPW2] = useState("");
  const [error, setError] = useState(null);

  const handleRename = () => {
    setError(null);
    setShowAutosave(true);
    if (pw1 === "" || pw2 === "") {
      setError("Password cannot be blank.");
      return;
    } else if (pw1 !== pw2) {
      setError("Passwords must match.");
      return;
    } else if (pw1.length < 4) {
      setError("Passwords must be at least 4 digits.");
      return;
    }

    api
      .patchLearnerInfo(learnerId, {student_pwd: pw1})
      .then(() => {
        console.log("FINISHED PATCHING LEARNER INFO");
        setPWFunc(pw1);
        shouldShowFunc(false);
        setAutosaveSuccess(true);
        setShowAutosave(false);
        setShouldSpin(true); // will trigger the refresh of the classes
      })
      .catch(() => {
        setError("Something went wrong resetting learner's password.");
        setAutosaveSuccess(false);
        setShowAutosave(false);
      });
  };
  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>
              Set New Password for {learnerFirstName} {learnerLastName}
            </p>
            <div
              className='pdf_popup_close'
              onClick={() => shouldShowFunc(false)}
            >
              <FA icon='times' />
            </div>
          </div>
          <div className='reset_pw_modal common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && <div className='alert alert-danger'>{error}</div>}
                <div className='form-content form-group'>
                  <h5>Please set a password (at least 4 digits).</h5>
                  <input
                    name='password'
                    className='form-control'
                    type='password'
                    placeholder='Password'
                    autoComplete='new-password'
                    value={pw1}
                    onChange={(e) => setPW1(e.target.value)}
                  />
                  <input
                    name='password-again'
                    className='form-control'
                    type='password'
                    placeholder='Password (Again)'
                    autoComplete='new-password'
                    value={pw2}
                    onChange={(e) => setPW2(e.target.value)}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gridGap: "10px",
                    }}
                  >
                    <button
                      onClick={handleRename}
                      className='btn btn-lg fs-14 btn-primary'
                    >
                      Set New Password
                    </button>
                    <button
                      onClick={() => {
                        shouldShowFunc(false);
                      }}
                      className='btn btn-lg fs-14 btn-danger'
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className='alert alert-warning'>
                  <span>
                    Note: Resetting the learner's password may affect other
                    teachers if the learner is in multiple classes.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAPI(LearnerResetPWModal);
