import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import FA from '../containers/fa';
import FAW from '../containers/faw';

const ActivationForm = ({history, dispatch}) => {
  const [code, setCode] = useState("");
  const [spin, setSpin] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    setSpin(true);
    //TODO: Actually activate Abii here.
    history.push('/teacher/dashboard');
    return false;
  }

  return (
    <form>
      <div className="form-content form-group container">
        <div className="row">
          <div className="form_icons">
            <label><FA icon="lock" /></label>
            <input required className="form-control input-underline input-lg"
                   placeholder="Activation Code" type="text" value={code} onChange={(e) => setCode(e.target.value)}/>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          <button type="submit" onClick={onSubmit} className="btn btn-lg fs-14 btn-primary col-md-5">
          {error && <div className="alert alert-danger">{error}</div>}
          {spin
            ? <FAW icon="spinner" spin />
            : <span>Activate</span>
          }
          </button>
        </div>
      </div>
    </form>

  );
}

export default compose(
  withRouter,
  connect()
)(ActivationForm);
