import {sortedArray} from "../../../../common/utils";
/**
 * If a score in the scores list exists for the given lesson name, it returns
 * @param {array} scores
 * @param {string} lessonName
 * @returns
 */
export const findScoreForLesson = (learnersListWithScores, lessonName) => {
  let score = null;
  for (let i = 0; i < learnersListWithScores.length; i++) {
    if (lessonName.indexOf(learnersListWithScores[i].lesson_name) !== -1) {
      score = learnersListWithScores[i].perf;
    }
  }
  return score;
};

/**
 *
 * @param {Array} arr1, either filteredLessons if arr2 is not initialized, or
 * an array of objects containing scores and the indices from which they came
 * in filteredLessons. arr2 will only be initialized if the purpose is for
 * sorting lessons by an individiual learner's scores.
 * @param {*} valueToCheck
 * @param {Array} arr2 filteredLessons
 * @returns
 */
export const moveNullValuesToBottom = (arr1, valueToCheck, arr2 = null) => {
  // ensures null vals are always at the end.
  let newAvgsWithoutNulls = [];
  let newAvgsWithNulls = [];
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i][valueToCheck] !== null) {
      arr2
        ? newAvgsWithoutNulls.push(arr2[arr1[i].index])
        : newAvgsWithoutNulls.push(arr1[i]);
    } else {
      arr2
        ? newAvgsWithNulls.push(arr2[arr1[i].index])
        : newAvgsWithNulls.push(arr1[i]);
    }
  }
  return [...newAvgsWithoutNulls, ...newAvgsWithNulls];
};

export const sortColumnsByLearner = (
  id,
  filteredLessons,
  findLearnerFromSID,
  learnersListWithScores,
  sortedLearnerIdAsc
) => {
  let learner = findLearnerFromSID(id, learnersListWithScores);
  let filteredScores = filteredLessons.map((value, index1) => {
    // if the pagination dropdown changes and the learner isn't on the new
    // list, default to null. They aren't going to show up anyway.
    let lessonScore = null;
    if (learner) {
      lessonScore = findScoreForLesson(
        learner.scores,
        filteredLessons[index1].label
      );
    }
    return {index: index1, score: lessonScore};
  });

  filteredScores = sortedArray(
    filteredScores,
    "score",
    sortedLearnerIdAsc,
    true
  );

  return moveNullValuesToBottom(filteredScores, "score", filteredLessons);
};

export const sortColumnsByStandard = (
  filteredLessons,
  sortingStandardsAsc
) => {
  let newFilteredLessons = sortedArray(
    filteredLessons,
    "standard",
    sortingStandardsAsc,
    false
  );
 return newFilteredLessons;
};

/**
 * SortRows will sort the rows by first_name, average, or by lesson column.
 * The func splitLearnersForPagination will invoke this sort but requires
 * a returned value, not a state change, so a bool was added to differentiate.
 * @param {Array} lessons
 * @param {String} columnName
 */
export const sortRowsByColumn = (
  learnersListWithScores,
  lessons,
  columnName,
  sortAsc,
  forPagination = false,
  setLearnersListWithScores = null
) => {
  if (!lessons || !columnName || sortAsc === null) {
    if (forPagination) return learnersListWithScores;
    return;
  }
  let newLearnersListWithScores = [];
  if (columnName === "first_name") {
    if (forPagination) {
      return sortedArray(learnersListWithScores, "first_name", !sortAsc);
    }
    setLearnersListWithScores(
      sortedArray(learnersListWithScores, "first_name", !sortAsc)
    );
    return;
  } else if (columnName === "average") {
    newLearnersListWithScores = sortedArray(
      learnersListWithScores,
      "average",
      sortAsc,
      true
    );
    newLearnersListWithScores = moveNullValuesToBottom(
      newLearnersListWithScores,
      "average"
    );

    if (forPagination) {
      return newLearnersListWithScores;
    }
    setLearnersListWithScores(newLearnersListWithScores);
    return;
  } else if (
    columnName === "lessonAverage" ||
    columnName === "learnerAverage"
  ) {
    // this is a column sort, so no need to resort rows.
    if (forPagination) return learnersListWithScores;
    return;
  } else {
    //sorting by lesson column
    for (let index = 0; index < learnersListWithScores.length; index++) {
      for (let index1 = 0; index1 < lessons.length; index1++) {
        // returns null or a number
        if (lessons[index1].label.indexOf(columnName) !== -1) {
          let lessonScore = findScoreForLesson(
            learnersListWithScores[index].scores,
            lessons[index1].label
          );
          newLearnersListWithScores = [
            ...newLearnersListWithScores,
            {
              index: index,
              sid: learnersListWithScores[index].sid,
              score: lessonScore,
            },
          ];
        }
      }
    }
    newLearnersListWithScores = sortedArray(
      newLearnersListWithScores,
      "score",
      sortAsc,
      true
    );
  }

  // match up sids and ensure nulls are at the bottom.
  let newScoresWithoutNulls = [];
  let newScoresWithNulls = [];
  for (let a = 0; a < newLearnersListWithScores.length; a++) {
    if (columnName !== "average" && newLearnersListWithScores[a].score) {
      newScoresWithoutNulls.push(
        learnersListWithScores[newLearnersListWithScores[a].index]
      );
    } else {
      newScoresWithNulls.push(
        learnersListWithScores[newLearnersListWithScores[a].index]
      );
    }
  }

  if (forPagination) {
    newLearnersListWithScores = [
      ...newScoresWithoutNulls,
      ...newScoresWithNulls,
    ];

    return newLearnersListWithScores;
  } else {
    setLearnersListWithScores([
      ...newScoresWithoutNulls,
      ...newScoresWithNulls,
    ]);
  }
};

/**
 * Separates an array into chunks, given an array and a chunk size.
 * Source: stackoverflow.com/questions/8495687/split-array-into-chunks
 */
export const splitLearnersForPagination = (
  newLearnersListWithScores,
  learnersPerPage,
  setNumberOfPages,
  setLearnersListWithScores,
  currentPage,
  columnToSort = null,
  sortAsc = null,
  lessons
) => {
  if (newLearnersListWithScores.length >= learnersPerPage) {
    const result = newLearnersListWithScores.reduce(
      (resultArray, item, index) => {
        const chunkIndex = Math.floor(index / learnersPerPage);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      },
      []
    );
    setNumberOfPages(result.length);
    setLearnersListWithScores(
      sortRowsByColumn(
        result[currentPage],
        lessons,
        columnToSort,
        sortAsc,
        true
      )
    );
  } else {
    setNumberOfPages(1);
    setLearnersListWithScores(
      sortRowsByColumn(
        newLearnersListWithScores,
        lessons,
        columnToSort,
        sortAsc,
        true
      )
    );
  }
};

/**
 * Determines how short the width of the table can go and still display data.
 * @param {array} lessonsList
 * @returns
 */
 export const getMinWidthOfRows = (lessonsList) => {
  let val = 160;
  for (let i = 0; i < lessonsList.length; i++) {
    val = val + 28;
  }
  val = val + 240;
  return val;
};

/**
 * Ensures the gridlayout is uniform for each row and fills 100% of the
 * parent width, regardless of the number of lessons or string sizes.
 * @param {array} lessonsList
 * @returns
 */
 export const getGridTemplateColumns = (lessonsList) => {
  let str = "160px ";
  if (lessonsList.length === 0)
    return "160px calc(100% - 300px) auto 70px 70px";
  for (let i = 0; i < lessonsList.length; i++) {
    if (lessonsList.length <= 15) {
      str = str + `30px `;
    } else {
      str = str + `calc((100% - 300px) / ${lessonsList.length})`;
    }
  }
  if (lessonsList.length <= 15) {
    str = str + ` calc(100% - ${300 + 30 * lessonsList.length}px) 70px 70px`;
  } else {
    str = str + ` auto 70px 70px`;
  }

  return str;
};