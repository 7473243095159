import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {compose} from "redux";

import FA from "../../../containers/fa";
import withAPI from "../../../services/api";

import OrgSubscriptionsTable from "../../ManageAllSubscriptions/OrgSubscriptionsTable.js";
import OrgRegCodesTable from "../OrgRegCodesTable.js";
import SalesInfoDetailTab from "./SalesInfoDetailTab.js";
import SalesInfoMoreTab from "./SalesInfoMoreTab.js";
import SalesInfoPdTab from "./SalesInfoPdTab.js";
import SalesInfoReturnTab from "./SalesInfoReturnTab.js";
import SalesInfoStatusChanges from "./SalesInfoStatusChanges.js";

import {saleModalHeader as saleModalHeaderA} from "../../../redux/actions";

const mapStateToProps = (state, ownProps?) => ({
  saleDetailId: state.superAdmin.saleDetailId,
  refreshSaleDetail: state.superAdmin.refreshSaleDetail,
});

const SalesInfoDetailModal = ({
  api,
  dispatch,
  saleDetailId,
  refreshSaleDetail,
}) => {
  const [shouldSpin, setShouldSpin] = useState(true);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [isNewSale, setIsNewSale] = useState(false);
  const [saleObj, setSaleObj] = useState({});
  const [tabIndex, setTabIndex] = useState(0);

  const handleSaleObjId = () => {
    if (saleDetailId === "new" || saleDetailId === "newRenew") {
      setIsNewSale(true);
      setSaleObj({});
      setShouldSpin(false);
    } else if (saleDetailId !== null) {
      setIsNewSale(false);
      setShouldSpin(true);
      api.fetchSaleDetail(saleDetailId).then((res) => {
        setSaleObj(res);
        setShouldSpin(false);
      }).catch((error) => {
        console.log(error);
        setError(error);
        setErrMsg('An error occurred. Please try again later.');
      });
    }
  };

  const handleCloseModal = () => {
    setTabIndex(0);
    dispatch(
      saleModalHeaderA({
        id: null,
        orgInfo: {},
      })
    );
  };

  useEffect(() => {
    setTabIndex(0);
  }, [saleDetailId]);

  useEffect(() => {
    handleSaleObjId();
  }, [saleDetailId, refreshSaleDetail]);

  if (saleDetailId === null) {
    return null;
  }
  console.log("saleObj.status_display", saleObj.status_display);
  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper-large">
        <div className="common_border">
          <div className="common_heading">
            <p>
              Sales Detail
              {saleObj && saleObj.organization && (
                <span className="ml-2">
                  (Organization: {saleObj.organization.name})
                </span>
              )}
              {saleObj && saleObj.sale_type && (
                <span className="ml-2" style={{ textTransform: "capitalize" }}>
                  ({saleObj.sale_type} Sale)
                </span>
              )}
            </p>
            <div
              className="pdf_popup_close"
              onClick={() => {
                handleCloseModal();
              }}
            >
              <FA icon="times" />
            </div>
          </div>
          <div
            className="common_dashboard_bg table-scroll-wrapper"
            style={{ height: "min(575px, (100vh - 60px))" }}
          >
            <div className="row">
              <div className="col-12">
                {error && <div className="alert alert-danger">{errMsg}</div>}

                {shouldSpin && (
                  <div className="alert alert-info" role="alert">
                    Loading ...
                  </div>
                )}
              </div>
            </div>

            {!shouldSpin && (
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>Sales Details</Tab>
                  {isNewSale === false && (
                    <React.Fragment>
                      <Tab>Status Changes</Tab>
                      {saleObj.sale_type === "regular" && <Tab>PDs</Tab>}
                      <Tab>Subscriptions</Tab>
                      <Tab>Activation Code</Tab>
                      {saleObj.sale_type === "regular" && <Tab>Tech Setup</Tab>}
                      {saleObj.can_edit_items === false &&
                        saleObj.sale_type === "regular" && <Tab>Returns</Tab>}
                    </React.Fragment>
                  )}
                </TabList>

                <TabPanel>
                  <SalesInfoDetailTab saleObj={saleObj} />
                </TabPanel>

                {isNewSale === false && (
                  <React.Fragment>
                    <TabPanel>
                      <SalesInfoStatusChanges
                        statusChangeLogs={saleObj.status_logs}
                        saleId={saleObj.id}
                        setTabIndex={setTabIndex}
                      />
                    </TabPanel>
                    {saleObj.sale_type === "regular" && (
                      <TabPanel>
                        <SalesInfoPdTab saleObj={saleObj} />
                      </TabPanel>
                    )}
                    <TabPanel>
                      <OrgSubscriptionsTable
                        orgId={saleObj.organization.id}
                        saleId={saleObj.id}
                        orgName={saleObj.organization.name}
                        canCollapse={false}
                        saleIsFinalizedState={
                          saleObj.status_display === "Invoice Sent" ||
                          saleObj.status_display === "Invoice Paid"
                        }
                        hideExpirationSummarySection={true}
                      />
                    </TabPanel>
                    <TabPanel>
                      <OrgRegCodesTable
                        orgId={saleObj.organization.id}
                        saleId={saleObj.id}
                        orgName={saleObj.organization.name}
                      />
                    </TabPanel>
                    {saleObj.sale_type === "regular" && (
                      <TabPanel>
                        <SalesInfoMoreTab saleObj={saleObj} />
                      </TabPanel>
                    )}
                    {saleObj.can_edit_items === false &&
                      saleObj.sale_type === "regular" && (
                        <TabPanel>
                          <SalesInfoReturnTab saleObj={saleObj} />
                        </TabPanel>
                      )}
                  </React.Fragment>
                )}
              </Tabs>
            )}

            <button
              className="btn btn-danger float-right mt-3"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(SalesInfoDetailModal);
