import React from "react";

const UserInputFieldDataRow = ({
  user,
  updateUserFields,
  fieldName,
  editMode,
  disabled
}) => {
  return (
    <div>
      {editMode ?
        <React.Fragment>
          <input
            id={'user-edit-' + fieldName}
            className='form-control input-sm'
            type={fieldName === "email" ? "email" : "text"}
            value={user[fieldName]}
            name={fieldName}
            disabled={disabled}
            onChange={(e) => {
              updateUserFields(fieldName, e.target.value);
            }}
          />
        </React.Fragment>
        :
        <span>{user[fieldName]}</span>
      }
    </div>
  );
};

export default UserInputFieldDataRow;
