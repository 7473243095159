import { STUDENT_COUNT, CLASS_ID } from "../actionTypes";

import config from '../../common/config';


const initialState = {
  homeClassId: sessionStorage.getItem("homeClassId") || null,
  studentCount: parseInt(sessionStorage.getItem("studentCount")) || 0
}

const homeVersion = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_COUNT:
      return Object.assign({}, state, {
        studentCount: action.payload
      })
    case CLASS_ID:
      return Object.assign({}, state, {
        homeClassId: action.payload
      })
    default:
      return state
  }
}

export default homeVersion;
