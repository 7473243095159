import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { useTitle } from "../../common/utils";
import SchoolTable from "../../components/ManageAllSchools/SchoolTable";
import OrgSubscriptionsTable from "../../components/ManageAllSubscriptions/OrgSubscriptionsTable.js";
import OrgInfoRow from "../../components/SuperAdmin/OrgInfoRow";
import FA from "../../containers/fa";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";
import "./Dashboard.scss";
import config from "../../common/config";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  localBotsOnly: state.auth.localBotsOnly,
});

const OrganizationInfo = ({ api, user, localBotsOnly }) => {
  useTitle("My Organization");
  const [shouldSpin, setShouldSpin] = useState(true);
  const [error, setError] = useState(null);
  const [orgInfo, setOrgInfo] = useState({});
  const [orgInfoSectionLoaded, setOrgInfoSectionLoaded] = useState(false);
  const [schoolsSectionLoaded, setSchoolsSectionloaded] = useState(false);
  const [subscriptionsSectionLoaded, setSubscriptionsSectionLoaded] =
    useState(false);

  const orderedRows = [
    "name",
    "organization_code",
    "num_robots",
    "num_teachers",
    "enrolled_learners",
  ];

  const rowToLabelMap = {
    name: "Organization Name",
    organization_code: "Organization Code",
    num_teachers: "Teacher/Admin Accounts",
    num_robots: "Activated Robots",
    enrolled_learners:
      user.usertype === config.ORGUNITADMIN_USERTYPE
        ? "Enrolled Learners"
        : "Enrolled Learners / Limit",
    schools: "Schools",
  };

  if (user.usertype === config.ORGUNITADMIN_USERTYPE) {
    // don't show organization_code
    orderedRows.splice(1, 1);
    // add a new row for School(s) in place of organization_code
    orderedRows.splice(1, 0, "schools");
  }

  const emptyOrg = orderedRows.reduce((o, key) => ({ ...o, [key]: "" }), {});
  const [editableOrg, setEditableOrg] = useState(emptyOrg);

  let history = useHistory();

  useEffect(() => {
    console.log("editableOrg", editableOrg);
  }, [editableOrg]);

  useEffect(() => {
    let queryParam = null;
    let fetch = null;

    if (user.usertype === config.ORGUNITADMIN_USERTYPE) {
      fetch = api.fetchOrgUnitAdminInfo;
    } else {
      fetch = api.fetchOrganizationInfo;
      queryParam = user.organization.id;
    }

    fetch(queryParam)
      .then((obj) => {
        console.log("OrganiztionInfo got OrgInfo: ", obj);
        let newOrg = orderedRows.reduce(
          (o, key) => ({ ...o, [key]: obj[key] }),
          {}
        );
        let processedOrg = null;
        if (obj.max_enrolled_learners) {
          const enrolledLearners =
            user.usertype === config.ORGUNITADMIN_USERTYPE
              ? obj.enrolled_learner_count
              : obj.enrolled_learner_count + " / " + obj.max_enrolled_learners;
          processedOrg = {
            ...obj,
            enrolled_learners: enrolledLearners,
          };
        } else {
          const enrolledLearners =
            user.usertype === config.ORGUNITADMIN_USERTYPE
              ? obj.enrolled_learner_count
              : obj.enrolled_learner_count + " / -";
          processedOrg = {
            ...obj,
            enrolled_learners: enrolledLearners,
          };
        }
        setOrgInfo(processedOrg);
        setEditableOrg(newOrg);
        setShouldSpin(false);
        setOrgInfoSectionLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }, []);

  return (
    <div>
      <MainDashboard>
        <div className='common_heading d-flex justify-content-between align-items-center'>
          <h4>Organization Info</h4>
          &nbsp;
          <button
            className='btn btn-sm btn-primary'
            onClick={() => history.goBack()}
          >
            {" "}
            Back{" "}
          </button>
        </div>
        <div className='common_dashboard_bg'>
          <div className='row'>
            <div className='col-12'>
              <div className='row mb-10'></div>
              {error && (
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              )}
              {shouldSpin && (
                <div>
                  <h4>
                    <FA color='gray' icon='spinner' spin /> Loading Organization
                    info...
                  </h4>
                </div>
              )}

              {!shouldSpin && (
                <div className='card'>
                  <div
                    className='progress-linear school_listing manage_learner_table table-scroll-wrapper'
                    role='progressbar'
                  >
                    <table className='table table-striped table-sm'>
                      <tbody>
                        {orderedRows.map((k) => (
                          <React.Fragment key={k}>
                            <OrgInfoRow
                              fieldName={k}
                              key={k}
                              label={rowToLabelMap[k] || k}
                              value={orgInfo[k]}
                              instanceId={user.organization.id}
                              patchAPI={api.patchOrganizationInfo}
                              canEdit={false}
                            />
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {user.usertype !== config.ORGUNITADMIN_USERTYPE && (
          <SchoolTable setSchoolsSectionloaded={setSchoolsSectionloaded} />
        )}
        {localBotsOnly !== true && (
          <OrgSubscriptionsTable
            orgId={user.organization.id}
            saleIsFinalizedState={true}
            setSubscriptionsSectionLoaded={setSubscriptionsSectionLoaded}
            allSectionsLoaded={
              orgInfoSectionLoaded &&
              schoolsSectionLoaded &&
              subscriptionsSectionLoaded
            }
          />
        )}
      </MainDashboard>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(OrganizationInfo);
