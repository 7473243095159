import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import Gate from '../partials/Gate';
import withAPI from '../services/api';


import FA from '../containers/fa';

// Is this page being used?

const NewSecurityOnLogin = ({ api, dispatch, history }) => {
  const [error, setError] = useState(null);
  const [securitySpinner, setSecuritySpinner] = useState(false);

  const [questionID, setQuestionID] = useState("");
  const [questionChoices, setQuestionChoices] = useState([]);
  const [answer, setAnswer] = useState("");

  const handleSubmit = () => {
  }

  useEffect(() => {
        api.securityaudio();
  }, []);


  return (
    <Gate>
      <div className="login_box">
        <div className="form-group">
          <h1>New Security Question</h1>
          <p>Set a security question in case you forget your password.</p>
        </div>
        <form noValidate autoComplete="off">
          <div className="form-content form-group container">
            {error &&
              <div className="row">
                  <div className="alert alert-danger">{error}</div>
              </div>
            }
            <div className="row">
              <select value={questionID} onChange={(e) => setQuestionID(e.target.value)} className="form-control input-lg" >
                <option value="">Select Security Question</option>
                {questionChoices.map((question) => (
                  <option key={question[0]} value={question[0]}>{question[1]}</option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="form_icons icon_button icon_button_container">
                <label><FA icon="question"/></label>
                <input type="text" onChange={(e) => setAnswer(e.target.value)} className="form-control input-lg" value={answer} />
              </div>
            </div>
            <div className="row d-flex justify-content-between align-items-center">
              <button type="submit" onClick={handleSubmit} className="btn btn-lg fs-14 btn-primary col-md-5">
                {securitySpinner
                  ? <FA color="white" icon="spinner" spin/>
                  : <span>Submit</span>
                }
              </button>
            </div>
          </div>
        </form>
      </div>
    </Gate>
  )
}

export default compose(
  withRouter,
  connect(),
  withAPI,
)(NewSecurityOnLogin);
