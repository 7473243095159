import {
  CLASSES_TO_REFRESH,
} from "../actionTypes";

const initialState = {
  refreshClassArr: [],
};

const classes = (state = initialState, action) => {
  switch (action.type) {
    case CLASSES_TO_REFRESH:
      let newArr = state.refreshClassArr;
      if (action.payload.action === 'add') {
        newArr = newArr.concat(action.payload.classes);
      } else {
        newArr = newArr.filter((el) => !action.payload.classes.includes(el));
      }
      return Object.assign({}, state, {
        refreshClassArr: newArr
      });
    default:
      return state;
  }
};

export default classes;
