import React, {useState, useEffect} from "react";
import {Bar} from "react-chartjs-2";
const roundNum = (num) => {
  return Math.round(num * 100);
};

const ClassVsLearnerBarChart = ({
  classAverageData,
  selectedLesson,
  isHomeVersion,
  currentChart,
}) => {
  const [parsedData, setParsedData] = useState([]);
  const chartData = {
    labels: isHomeVersion
      ? ["Learner Attention", "Learner Performance"]
      : [
          "Class Attention",
          "Class Performance",
          "Learner Attention",
          "Learner Performance",
        ],
    datasets: [
      {
        label: selectedLesson.lessonName,
        data: parsedData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
      },
    ],
  };

  useEffect(() => {
    if (!classAverageData) return;

    let newClassAttnData = roundNum(classAverageData.sum_attention);
    let newClassPerformData = roundNum(classAverageData.sum_performance);
    let newStudentAttnData = roundNum(classAverageData.sum_attention_self);
    let newStudentPerformData = roundNum(classAverageData.sum_performance_self);

    if (isHomeVersion) {
      setParsedData([newStudentAttnData, newStudentPerformData]);
    } else {
      setParsedData([
        newClassAttnData,
        newClassPerformData,
        newStudentAttnData,
        newStudentPerformData,
      ]);
    }
  }, [classAverageData]);

  return (
    <Bar
      data={chartData}
      id='class_vs_learner_bar_chart'
      className='carousel_chart'
      style={{left: currentChart === "classVsLearner" ? "0px" : "-100%"}}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
        },
        scales: {
          y: {
            ticks: {
              callback: (yValue) => {
                return Math.round(yValue) + "%";
              },
            },
            beginAtZero: true,
            suggestedMax: 100,
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: selectedLesson.lessonName,
            font: {
              size: 12,
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.dataset.label + ": " + context.parsed.y + "%";
              },
            },
          },
        },
      }}
    />
  );
};

export default ClassVsLearnerBarChart;
