import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { refreshSaleDetail as refreshSaleDetailA } from "../../../redux/actions";

import withAPI from "../../../services/api";
import FA from "../../../containers/fa";

const mapStateToProps = (state, ownProps?) => ({
  refreshSaleDetail: state.superAdmin.refreshSaleDetail,
  saleDetailOrgInfo: state.superAdmin.saleDetailOrgInfo,
});

const SalesInfoReturnTab = ({
  dispatch,
  api,
  saleObj,
  saleDetailOrgInfo,
  refreshSaleDetail,
}) => {
  const [shouldSpin, setShouldSpin] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [saleReturns, setSaleReturns] = useState(null);
  const [addNewReturn, setAddNewReturn] = useState(false);
  const [newReturnRobotCount, setNewReturnRobotCount] = useState(0);
  const [newReturnRestockFee, setNewReturnRestockFee] = useState(0);
  const [newReturnRefundValue, setNewReturnRefundValue] = useState(0);
  const [newReturnDate, setNewReturnDate] = useState(null);
  const [newReturnNote, setNewReturnNote] = useState("");

  const roundValues = (value, roundTo = 2) => {
    const p = Math.pow(10, roundTo);
    return Math.round(value * p + Number.EPSILON) / p;
  };

  const fetchSaleReturns = () => {
    if (saleObj.id) {
      api
        .fetchSaleReturns(saleObj.id)
        .then((res) => {
          console.log(res);
          setSaleReturns(res);
        })
        .catch((e) => {
          console.log("Error fetching returns.");
          console.log(e);
        });
    }
  };

  useEffect(() => {
    fetchSaleReturns();
  }, [saleObj]);

  const calcOriginalValue = (robotCount) => {
    return roundValues(
      robotCount * saleObj.price_snapshot[`${saleDetailOrgInfo.orgType}_robot`],
      2
    );
  };

  useEffect(() => {
    setNewReturnRestockFee(
      roundValues(calcOriginalValue(newReturnRobotCount) * 0.2, 2)
    );
  }, [newReturnRobotCount]);

  useEffect(() => {
    const refundValue =
      calcOriginalValue(newReturnRobotCount) - newReturnRestockFee;
    setNewReturnRefundValue(roundValues(refundValue, 2));
  }, [newReturnRobotCount, newReturnRestockFee]);

  const resetNewReturnForm = () => {
    setNewReturnDate(null);
    setNewReturnRobotCount(0);
    setNewReturnRestockFee(0);
    setNewReturnNote("");
  };

  const handleSaveChanges = () => {
    const postData = {
      return_date: newReturnDate.toISOString(),
      robot_count: newReturnRobotCount,
      restocking_fee: newReturnRestockFee,
      refund_amount: newReturnRefundValue,
      notes: newReturnNote,
    };
    console.log(postData);
    postData["sale"] = saleObj.id;
    setShouldSpin(true);
    setError(false);
    setSuccess(false);
    api
      .createSaleReturns(postData)
      .then((res) => {
        fetchSaleReturns();
        resetNewReturnForm();
        setShouldSpin(false);
        setSuccess(true);
        setAddNewReturn(false);
        dispatch(refreshSaleDetailA(!refreshSaleDetail));
      })
      .catch((e) => {
        console.log("Error creating return...");
        console.log(e);
        setShouldSpin(false);
        setError(true);
      });
  };

  if (!saleObj.price_snapshot || !saleDetailOrgInfo.orgType) {
    return null;
  }

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div className='mb-2 d-flex justify-content-between'>
            <h4>Returns</h4>
          </div>
          <table className='table table-striped table-sm'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Return Count</th>
                <th>Original Sale Value</th>
                <th>Restocking Fee</th>
                <th>Refund</th>
              </tr>
            </thead>
            <tbody className='sales_detail_table_tbody'>
              {saleReturns === null ? (
                <tr>
                  <td colSpan='5'>Loading ...</td>
                </tr>
              ) : (
                <>
                  {saleReturns.length > 0 ? (
                    saleReturns.map((returnItem) => (
                      <React.Fragment key={returnItem.id}>
                        <tr>
                          <td className='item-value'>
                            {moment
                              .utc(returnItem.return_date)
                              .local()
                              .format("MMMM Do YYYY")}
                          </td>
                          <td className='item-value'>
                            {returnItem.robot_count}
                          </td>
                          <td className='item-value'>
                            ${calcOriginalValue(returnItem.robot_count)}
                          </td>
                          <td className='item-value'>
                            ${roundValues(returnItem.restocking_fee)}
                          </td>
                          <td className='item-value'>
                            ${roundValues(returnItem.refund_amount)}
                          </td>
                        </tr>
                        <tr>
                          <td className='item-label'></td>
                          <td className='item-value' colSpan='4'>
                            Notes: {returnItem.notes || "N/A"}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan='5'>No Returns.</td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          {shouldSpin && (
            <div className='mb-2'>
              <FA color='gray' className='mr-2' icon='spinner' spin />
              Creating return record ...
            </div>
          )}

          {!shouldSpin && (
            <>
              <div className='mb-2'>
                {addNewReturn ? (
                  <div>
                    <button
                      className='btn btn-sm btn-warning'
                      onClick={() => {
                        handleSaveChanges();
                      }}
                    >
                      Save New Return
                    </button>
                    <button
                      className='btn btn-sm btn-danger ml-2'
                      onClick={() => {
                        setAddNewReturn(false);
                        resetNewReturnForm();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button
                    className='btn btn-sm btn-primary'
                    onClick={() => {
                      setAddNewReturn(true);
                    }}
                  >
                    Add New Return
                  </button>
                )}
              </div>
              {error && (
                <div className='alert alert-danger'>
                  Something went wrong ... Please try again or contact tech
                  team.
                </div>
              )}

              {success && (
                <div className='alert alert-success'>
                  Successfully created return record.
                </div>
              )}
              {addNewReturn && (
                <div className='mb-4'>
                  {saleObj.robot_count_after_returns < newReturnRobotCount && (
                    <div className='alert alert-warning'>
                      Return Count is greater than remaining robots in the sale.
                      Please check if the value you've entered is correct.
                    </div>
                  )}
                  <table className='table table-striped table-sm'>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Return Count</th>
                        <th>Original Sale Value</th>
                        <th>Restocking Fee</th>
                        <th>Refund</th>
                      </tr>
                    </thead>
                    <tbody className='sales_detail_table_tbody'>
                      <tr>
                        <td>
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            className='px-3'
                          >
                            <DatePicker
                              value={newReturnDate}
                              onChange={setNewReturnDate}
                            />
                          </MuiPickersUtilsProvider>
                        </td>
                        <td>
                          <TextField
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            size='small'
                            type='number'
                            step='1'
                            value={
                              newReturnRobotCount > 0 ? newReturnRobotCount : ""
                            }
                            onChange={(e) => {
                              setNewReturnRobotCount(
                                roundValues(e.target.value, 0)
                              );
                            }}
                          />
                        </td>
                        <td>$ {calcOriginalValue(newReturnRobotCount)}</td>
                        <td>
                          $
                          <TextField
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            size='small'
                            type='number'
                            value={
                              newReturnRestockFee > 0 ? newReturnRestockFee : ""
                            }
                            onChange={(e) => {
                              setNewReturnRestockFee(
                                roundValues(e.target.value, 2)
                              );
                            }}
                          />
                        </td>
                        <td>$ {newReturnRefundValue}</td>
                      </tr>
                      <tr>
                        <td>Notes</td>
                        <td className='item-value' colSpan='4'>
                          <TextField
                            style={{ width: "100%" }}
                            size='small'
                            multiline
                            minRows={2}
                            maxRows={4}
                            value={newReturnNote}
                            onChange={(e) => {
                              setNewReturnNote(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(SalesInfoReturnTab);
