import React, {useState, useEffect, useRef} from "react";
import {Chart, Filler} from "chart.js";
import {Line, getElementAtEvent} from "react-chartjs-2";
import ClosePopupBtn from "../../../UtilComps/ClosePopupBtn";
Chart.register(Filler);

/**
 * roundNum rounds the num for the percent.
 * @param {Integer} num
 * @returns
 **/
const roundNum = (num) => {
  return Math.round(num * 100);
};
/**
 * Performs a deep search to check both values and order of values.
 * @source https://www.codegrepper.com/code-examples/javascript/react+check+is+a+object+are+equal+javascript
 * @param  {...Object} objects
 * @return boolean
 */
const isEqual = (...objects) =>
  objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));

const LearnerPerformanceLineChart = ({
  learnerReport,
  lessonContextInfo,
  currentChart,
  setCurrentChart,
  attentionNotRecorded,
  setAttentionNotRecorded,
  selectedLesson,
  currentHoverData,
  setCurrentHoverData,
  hover,
  setHover,
  convertQuestionTypes,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const [disableHover, setDisableHover] = useState(false);
  const [parsedPerformData, setParsedPerformData] = useState([]);
  const [parsedAttnData, setParsedAttnData] = useState([]);
  const chartRef = useRef();

  /**
   * Gets the labels for the x axis of the line chart. If only one question
   * was answered, it's 0 1 2 so the data is in the middle of the chart.
   * @param {Object} data
   * @return {Array} arr of numbers, representing the questions
   */
  const getLabels = (data) => {
    if (data && data.length === 1) {
      return [0, 1, 2];
    } else if (data && data.length > 1) {
      return data.map((val, index) => index + 1);
    }
    return [""];
  };

  /**
   * chartDataWithAttn is the data passed into the <Line> tag. Two dataset
   * entries means two lines, but if there's no attn tracked, this data
   * is swapped for chartDataWithoutAttn.
   */
  const chartDataWithAttn = {
    labels: getLabels(parsedPerformData),
    datasets: [
      {
        label: "Performance",
        data: parsedPerformData,
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        pointHoverRadius: 10,
        lineTension: 0.5,
      },
      {
        label: "Attention",
        data: parsedAttnData,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        pointHoverRadius: 10,
        lineTension: 0.5,
      },
    ],
  };

  /**
   * chartDataWithAttn but without the attention data.
   */
  const chartDataWithoutAttn = {
    labels: getLabels(parsedPerformData),
    datasets: [
      {
        label: "Performance",
        data: parsedPerformData,
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        pointHoverRadius: 10,
        lineTension: 0.5,
      },
    ],
  };

  /**
   * onClickPoint sets the activeQuestionId and showModal for the question
   * detail popup, if a data point was hit.
   * @param {Object} event
   */
  const onClickPoint = (event) => {
    let datasets = chartRef.current.data.datasets;
    let elem = getElementAtEvent(chartRef.current, event);
    if (elem.length === 0) return;
    setActiveQuestionId(
      lessonContextInfo.type === "TUT"
        ? datasets[elem[0].datasetIndex].data[elem[0].index].real_q
        : datasets[elem[0].datasetIndex].data[elem[0].index].real_q - 1
    );
    setShowModal(true);
  };

  /**
   * onHoverPoint determines if elements is populated (a data point is hovered
   * over) and sets currentHoverData with an object containing the attn and
   * performance data for that x axis value. This is to display it in the white
   * box in the top left of the chart.
   * @param {Object} event
   * @param {Array} elements data points hit by the event. Only takes the first
   * @param {*} chart interacted, aka the line chart.
   */
  const onHoverPoint = (event, elements, chart) => {
    if (!chart || !elements || elements.length === 0) return;
    let datasets = chart.data.datasets; // obj array len 1 or 2
    if (chart && datasets && elements) {
      let realQ = datasets[0].data[elements[0].index].real_q;
      let screenData = null;
      for (let i = 0; i < learnerReport.steps.length; i++) {
        if (learnerReport.steps[i].question === realQ) {
          screenData = learnerReport.steps[i];
        }
      }

      let newHoverData = {
        performance: screenData.performance,
        attention: roundNum(screenData.attention),
        real_q: realQ,
        hovered_index: elements[0].index,
        stepQuestion:
          lessonContextInfo.questions[
            lessonContextInfo.type === "TUT" ? realQ : realQ - 1
          ],
      };
      if (!isEqual(newHoverData, currentHoverData)) {
        setCurrentHoverData(newHoverData);
      }
    }

    if (elements.length > 0 && !hover && !disableHover) {
      setHover(true);
    } else if (elements.length === 0 && hover && !disableHover) {
      setHover(false);
    }
  };

  useEffect(() => {
    setAttentionNotRecorded(false);
    if (!learnerReport || !lessonContextInfo) return;

    let questionsInContext = Object.keys(lessonContextInfo.questions);
    let performance_all;
    let attention_all;
    if (lessonContextInfo.type === "TUT") {
      console.log('learnerReport', learnerReport);
      performance_all = learnerReport.steps
        .filter((x) => questionsInContext.includes(x.question.toString()))
        .map((x) => ({
          q: x.question,
          performance: x.performance === null ? null : roundNum(x.performance),
        }));
      attention_all = learnerReport.steps
        .filter((x) => questionsInContext.includes(x.question.toString()))
        .map((x) => ({
          q: x.question,
          attention: roundNum(x.attention, "attention"),
        }));
    } else {
      // if ASM, learnerReport's question id is 1-indexed
      performance_all = learnerReport.steps
        .filter((x) => questionsInContext.includes((x.question - 1).toString()))
        .map((x) => ({
          q: x.question,
          performance: x.performance === null ? null : roundNum(x.performance),
        }));
      attention_all = learnerReport.steps
        .filter((x) => questionsInContext.includes((x.question - 1).toString()))
        .map((x) => ({
          q: x.question,
          attention: roundNum(x.attention, "attention"),
        }));
    }

    // val.q here is the index of the screen (for TUT)
    // -1 so we start from 1, but we need to keep track of real_q (val.q) to match
    // to the correct question context sent to us from backend.
    // ASM is already starting from 1

    setParsedAttnData(
      attention_all.map((val, id) => ({
        x: id + 1,
        y: val.attention,
        real_q: val.q,
      }))
    );
    setParsedPerformData(
      performance_all.map((val, id) => ({
        x: id + 1,
        y: val.performance,
        real_q: val.q,
      }))
    );
  }, [learnerReport]);

  useEffect(() => {
    if (parsedAttnData.find((x) => x.y > 0.0) === undefined) {
      setAttentionNotRecorded(true);
    }
  }, [parsedAttnData]);

  const isNoResponseScreen = (type) => {
    if (["DSP", "VID", "OPD"].indexOf(type) !== -1) {
      return true;
    }
    return false;
  };

  return (
    <div
      id='learner_performance_line_chart'
      className='carousel_chart'
      style={{left: currentChart === "learnerPerformance" ? "0px" : "100%"}}
    >
      <div style={{height: "100%"}}>
        <div
          style={{
            zIndex: "1",
            height: "100%",
            display: "grid",
            gridTemplateColumns: "100%",
          }}
        >
          <div
            className='chart-container'
            onMouseLeave={() => {
              if (hover) {
                setHover(false);
              }
            }}
            id='overtime-chart'
          >
            <Line
              ref={chartRef}
              onClick={onClickPoint}
              options={{
                spanGaps: true,
                responsive: true,
                maintainAspectRatio: false,
                onHover: onHoverPoint,
                hover: {
                  mode: "index",
                  intersect: false,
                },
                interaction: {
                  intersect: false,
                },
                scales: {
                  y: {
                    ticks: {
                      callback: (yValue) => {
                        return Math.round(yValue) + "%";
                      },
                    },
                    beginAtZero: true,
                    suggestedMax: 100,
                  },
                },

                plugins: {
                  datalabels: {
                    display: false,
                  },
                  tooltip: {
                    enabled: false,
                  },
                  title: {
                    display: true,
                    text: attentionNotRecorded
                      ? [
                          selectedLesson.label,
                          `Average Performance = ${
                            learnerReport &&
                            roundNum(learnerReport.avg_perf)
                          }%`,
                        ]
                      : [
                          selectedLesson.label,
                          `Average Performance = ${
                            learnerReport &&
                            roundNum(learnerReport.avg_perf)
                          }%`,
                          `Average Attention = ${
                            learnerReport &&
                            roundNum(learnerReport.avg_attn)
                          }%`,
                        ],
                    font: {
                      size: 12,
                    },
                  },
                },
              }}
              data={
                attentionNotRecorded ? chartDataWithoutAttn : chartDataWithAttn
              }
            />
          </div>

          <p style={{display: "flex", alignItems: "flex-end"}} className='mx-4'>
            Hide Hover Tips:
            <input
              className='ml-3'
              name='disableHover'
              type='checkbox'
              checked={disableHover}
              onChange={(e) => {
                setDisableHover(!disableHover);
              }}
              onFocus={() => setCurrentChart("learnerPerformance")}
            />
          </p>
        </div>
      </div>

      {showModal && (
        <div className='pdf-upload'>
          <div className='pdf-upload-wrapper' style={{zIndex: "99"}}>
            <div className='common_border'>
              <div className='common_heading'>
                <p>Question Info</p>

                <ClosePopupBtn closePopupFunc={setShowModal} />
              </div>
              <div className='common_dashboard_bg'>
                <div className='row'>
                  <div className='col-4'>
                    <div className='tight-div'>
                      <p>Screen Type:</p>

                      {!isNoResponseScreen(
                        lessonContextInfo.questions[activeQuestionId].type
                      ) && <p>Question Text:</p>}
                    </div>
                  </div>

                  <div className='col-8'>
                    <div className='tight-div'>
                      <p className='text-truncate'>
                        {convertQuestionTypes(
                          lessonContextInfo.questions[activeQuestionId].type
                        )}
                      </p>
                      {!isNoResponseScreen(
                        lessonContextInfo.questions[activeQuestionId].type
                      ) ? (
                        <p
                          className='line_chart_tooltip_question_text'
                          dangerouslySetInnerHTML={{
                            __html:
                              lessonContextInfo.questions[activeQuestionId]
                                .text,
                          }}
                        />
                      ) : (
                        <p className='line_chart_tooltip_question_text'>
                          This screen does not require a learner answer.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row mt-5'>
                  <div className='col-12 text-right'>
                    <button
                      onClick={() => setShowModal(false)}
                      className='btn btn-primary'
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LearnerPerformanceLineChart;
