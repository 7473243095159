import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Gate from '../../partials/Gate';
import config from "../../common/config";
import { authService } from '../../services/auth';
import {
  login,
  studentCap as studentCapA,
  userOrgSettings as userOrgSettingsA,
} from '../../redux/actions';
import FA from "../fa";


const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  token: state.auth.token,
  orgSettings: state.status.userOrgSettings,
});


const AsOrgAdminRedirect = ({user, token, dispatch, match, history}) => {

  const [canCreateUser, setCanCreateUser] = useState(false);

  useEffect(() => {
    // confirm is super admin (also checked on backend)
    if (user.usertype === config.SUPERADMIN_USERTYPE) {
      // Send request to backend to get secret org admin info
      authService.superAdminLoginAsOrgAdmin(match.params.orgId, token).then(
            ([user, token, organization, hasRobots, hasAccounts, localBotsOnly, forcePWReset, orgStatus]) => {
        dispatch(login(user, token, hasRobots, hasAccounts, localBotsOnly, forcePWReset, orgStatus));
        dispatch(studentCapA(user.student_cap))
        if (user.organization.settings) {
          dispatch(userOrgSettingsA(user.organization.settings));
        }
        sessionStorage.setItem('firstLogin', true);
        if (user.usertype === config.ORGADMIN_USERTYPE){
          history.push('/admin/dashboard');
        } else {
          console.log('Did not get back Org Admin')
        }
      })
    } else {
      console.log('User Is NOT SUPERADMIN_USERTYPE')
    }
  }, [])

  return (
    <Gate>
      <div className="login_box">
        <div className="form-group">
          <h4>
            <FA icon='spinner' spin />
            <span className='ml-1'>Redirecting ...</span>
          </h4>
        </div>
      </div>
    </Gate>
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(AsOrgAdminRedirect);
