import React from "react";

const BarChartShadowSvg = () => {
  return (
    <svg
    className='bar_chart_shadow'
    preserveAspectRatio='none'
    viewBox='0 0 165.4 97.6'
  >
    <rect x='6.8' y='47.3' className='st0' width='30' height='49' />
    <rect x='47.5' y='62.3' className='st0' width='30' height='34' />
    <rect x='87.7' y='23.5' className='st0' width='30' height='72.8' />
    <rect x='128.4' y='40.5' className='st0' width='30' height='55.8' />
  </svg>
  )
};

export default BarChartShadowSvg;