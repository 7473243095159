import React, { useState, useEffect } from 'react';
import withAPI from '../../services/api';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import FA from '../../containers/fa';


const AddRegCodeModal = ({api, history, shouldShowFunc, orgId, saleId, orgName, refreshRegCodeList}) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [spin, setSpin] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [maxRobots, setMaxRobots] = useState(0);
  const [cloudAccounts, setCloudAccounts] = useState(true);

  const handleCreate = () => {
    setError(false);
    setErrMsg(null);
    setSpin(true)
    let postData = {
      "organization": orgId,
      "max_robots": maxRobots,
      "cloud_accounts": cloudAccounts,
    }
    if (saleId) {
      postData['sale'] = saleId;
    }
    api.createRegCode(postData).then((data) => {
      refreshRegCodeList();
      setSuccess(true);
      console.log("Create op returned: ", data)
      shouldShowFunc(false);
    }).catch(() => {
      setErrMsg("Unknown error.");
      setError(true);
    });
  }

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Generate Activation Code for {orgName}</p>
            <div className="pdf_popup_close" onClick={() => shouldShowFunc(false)}><FA icon="times" /></div>
          </div>
          <div className="common_dashboard_bg">
            <div className="row">
              <div className="col-12">
                {error && <div className="alert alert-danger">{errMsg}</div>}
    	          Max Robots:<input required className="form-control input-underline input-lg" type="number"
    	            placeholder="Max Robots" onChange={(e) => setMaxRobots(e.target.value)} value={maxRobots} />
                <div className="mt-2">
                  <button onClick={handleCreate} className="btn btn-sm btn-primary" >Create</button>
                  <button onClick={() => shouldShowFunc(false)} className="btn btn-sm btn-danger ml-2" >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)}

export default compose(withRouter, withAPI)(AddRegCodeModal);
