import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { useDebounce } from "use-debounce";
import { sortedArray, useTitle } from "../../common/utils";
import AssignRobotModal from "../../components/AdminDashboard/AssignRobotModal";
import AssignSubscriptionModal from "../../components/AdminDashboard/AssignSubscriptionModal";
import InviteSSOUserModal from "../../components/AdminDashboard/InviteSSOUserModal";
import ManageTeachersFilters from "../../components/AdminDashboard/ManageTeachersFilters";
import ManageTeachersRow from "../../components/AdminDashboard/ManageTeachersRow";
import OnSaveErrorModal from "../../components/AdminDashboard/OnSaveErrorModal";
import UserCreateModal from "../../components/AdminDashboard/UserCreateModal";
import UserEditModal from "../../components/AdminDashboard/UserEditModal";
import UserPasswordResetModal from "../../components/AdminDashboard/UserPasswordResetModal";
import UserWelcomeResendModal from "../../components/AdminDashboard/UserWelcomeResendModal";
import InfoPopup from "../../components/UtilComps/InfoPopup";
import PaginationBar from "../../components/UtilComps/PaginationBar.js";
import FA from "../../containers/fa";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";
import "./ManageTeachers.scss";
import config from "../../common/config";
import { getAllSchoolInfo } from "../../components/InputFields/helpers";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  userOrgSettings: state.status.userOrgSettings,
});

const ManageTeachers = ({ api, user, userOrgSettings }) => {
  useTitle("Users");

  const [paginationInfo, setPaginationInfo] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [userFields, setUserFields] = useState([]);
  const [editableSchools, setEditableSchools] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showInviteSSOModal, setShowInviteSSOModal] = useState(false);
  const [currentEditUser, setCurrentEditUser] = useState(null);
  const [showOnSaveErrorModal, setShowOnSaveErrorModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [usernameToChangePw, setUsernameToChangePw] = useState(null);
  const [userIdToChangePw, setUserIdToChangePw] = useState(null);
  const [emailToChangePw, setEmailToChangePw] = useState(null);
  const [userIdToResend, setUserIdToResend] = useState(null);
  const [emailToResend, setEmailToResend] = useState(null);
  const [showResendModal, setShowResendModal] = useState(null);
  const [checkProviders, setCheckProviders] = useState(null);
  const [ready, setReady] = useState(false);
  const [showCreateUserButton, setShowCreateUserButton] = useState(true);
  const [showInviteSSOUserButton, setShowInviteSSOUserButton] = useState(false);
  const [sortType, setSortType] = useState("last_name");
  const [sortAsc, setSortAsc] = useState(true);
  const [sortTouched, setSortTouched] = useState(false);
  const [filterString, setFilterString] = useState(null);
  const [fetchItemsTrigger, setFetchItemsTrigger] = useState(0);
  const [debouncedFetchItemsTrigger] = useDebounce(fetchItemsTrigger, 1000);
  const [filterSchool, setFilterSchool] = useState("");
  const [filterSubscription, setFilterSubscription] = useState("");
  const [filterUserrole, setFilterUserrole] = useState("");
  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    header: "",
    text: "",
  });

  let history = useHistory();

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.from === "usage_stat"
    ) {
      setSortType("userprofile__recent_lessons");
    }
  }, [history]);

  // robot and subscription assignments
  const [assignRobotUserId, setAssignRobotUserId] = useState(null);
  const [assignSubscriptionUserId, setAssignSubscriptionUserId] =
    useState(null);
  const [existingSubscriptions, setExistingSubscriptions] = useState([]);

  // If redirected from popup
  useEffect(() => {
    const searchStr = window.location.search;
    const qParams = new URLSearchParams(searchStr);
    const openTgt = qParams.get("open");
    if (openTgt === "addTeacher") {
      setShowCreateModal(true);
    }
  }, []);

  const fetchUsers = (pageNum) => {
    let queryParams = {
      include_subscriptions: true,
      page_size: pageSize,
      sort: (sortAsc ? "" : "-") + sortType,
      q: filterString,
      orgunit: filterSchool,
      subscription_type: filterSubscription,
      userrole: filterUserrole,
    };
    queryParams.page = pageNum;
    // skip nulls,  empty strings, false values
    queryParams = Object.entries(queryParams).filter(([k, v]) => v);
    setReady(false);
    api.fetchAllVisibleUsers(queryParams).then((objects) => {
      // Key is based on order, so must sort before assigning keys
      // (sorted on backend)
      let newArr = objects.results.map((user, key) => {
        return {
          id: user.id,
          key: key,
          usertype: user.userprofile.usertype,
          recent_lessons: user.userprofile.recent_lessons,
          enrolled_learners: user.userprofile.enrolled_learners,
          email: user.email,
          username: user.username,
          first_name: user.first_name,
          last_name: user.last_name,
          organization: user.userprofile.organization,
          orgunits: user.userprofile.orgunits,
          clever_synced: user.clever_synced,
          saveBtnText: "Save",
          enableSave: false,
          errors: {
            first_name: null,
            last_name: null,
            username: null,
            email: null,
          },
          modifiedFields: [],
          robotCount: user.robot_count,
          subscriptionsRaw: user.subscriptions,
          isPlaceholder: user.userprofile.is_placeholder,
          onlySSOLogin: !user.userprofile.can_login_with_password,
          has_sso_login: user.userprofile.has_sso_login,
        };
      });
      setUserFields(newArr);
      // until we find a better way to deep copy...
      delete objects["results"];
      setPaginationInfo(objects);
      setReady(true);
    });
  };

  useEffect(() => {
    getAllSchoolInfo(api).then((schls) => {
      let sortedSchools = sortedArray(schls, "name");
      console.log("user:", user);
      console.log("schools:", sortedSchools);
      if (user.usertype === config.ORGUNITADMIN_USERTYPE) {
        // only own OrgUnit/Schools are editable
        const ownSchoolIds = user.orgunits.map((ou) => ou.id);
        const mySchools = sortedSchools.filter((school) => {
          return ownSchoolIds.includes(school.id);
        });
        console.log("ownSchoolIds:", ownSchoolIds);
        console.log("mySchools:", mySchools);
        setEditableSchools([...mySchools, { id: "none", name: "None" }]);
      } else {
        setEditableSchools([...sortedSchools, { id: "none", name: "None" }]);
      }
    });
  }, [user]);

  useEffect(() => {
    setFetchItemsTrigger(fetchItemsTrigger + 1);
  }, [
    pageSize,
    filterSchool,
    filterSubscription,
    filterUserrole,
    filterString,
  ]);

  useEffect(() => {
    if (debouncedFetchItemsTrigger > 0) fetchUsers();
  }, [debouncedFetchItemsTrigger]);

  // sort rows
  useEffect(() => {
    if (sortTouched === true) {
      fetchUsers();
    }
  }, [sortType, sortAsc]);

  useEffect(() => {
    if (userOrgSettings) {
      // show Create User button as long as login_with_password_allowed is not set as False
      setShowCreateUserButton(
        [true, null, undefined].includes(
          userOrgSettings.login_with_password_allowed
        )
      );
      // show Invite SSO User button when allowed SSO providers are defined
      setShowInviteSSOUserButton(
        userOrgSettings.allowed_sso_providers &&
          userOrgSettings.allowed_sso_providers.length > 0
      );
    }
  }, []);

  const handleColumnHeaderClick = (column) => {
    setSortTouched(true);
    if (sortType === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortType(column);
      setSortAsc(false);
    }
  };

  const getHeaderClasses = (column) => {
    let classes = "sortable";
    if (sortType === column) {
      if (sortAsc === false) {
        classes += " headerSortDown";
      } else {
        classes += " headerSortUp";
      }
    } else {
      classes += " headerSort";
    }
    return classes;
  };

  return (
    <MainDashboard>
      {showPopupInfo && (
        <InfoPopup popupInfo={popupInfo} setShowPopupInfo={setShowPopupInfo} />
      )}

      {currentEditUser ? (
        <UserEditModal
          currentEditUser={currentEditUser}
          fetchUsers={fetchUsers}
          setCurrentEditUser={setCurrentEditUser}
        />
      ) : null}
      {showCreateModal ? (
        <UserCreateModal
          fetchUsers={fetchUsers}
          setShowCreateModal={setShowCreateModal}
        />
      ) : null}
      {showInviteSSOModal ? (
        <InviteSSOUserModal
          fetchUsers={fetchUsers}
          setShowModal={setShowInviteSSOModal}
          orgSettings={userOrgSettings}
        />
      ) : null}
      {showPasswordModal ? (
        <UserPasswordResetModal
          usernameToChangePw={usernameToChangePw}
          userIdToChangePw={userIdToChangePw}
          email={emailToChangePw}
          setShowPasswordModal={setShowPasswordModal}
        />
      ) : null}
      {showResendModal && (
        <UserWelcomeResendModal
          userIdToResend={userIdToResend}
          email={emailToResend}
          setShowResendModal={setShowResendModal}
          checkProviders={checkProviders}
        />
      )}
      {showOnSaveErrorModal ? (
        <OnSaveErrorModal
          errMsg={null}
          setShowOnSaveErrorModal={setShowOnSaveErrorModal}
        />
      ) : null}

      {assignRobotUserId && (
        <AssignRobotModal
          assignUserId={assignRobotUserId}
          setShowModal={setAssignRobotUserId}
          refreshList={fetchUsers}
        />
      )}

      {assignSubscriptionUserId && (
        <AssignSubscriptionModal
          assignUserId={assignSubscriptionUserId}
          existingSubscriptions={existingSubscriptions}
          setShowModal={setAssignSubscriptionUserId}
          refreshList={fetchUsers}
        />
      )}

      <div className='common_border'>
        <div className='common_heading d-flex justify-content-between align-items-center'>
          <p>Teachers and Administrators</p>
        </div>
      </div>
      <div className='common_table_padding'>
        <div className='row batch-row'>
          <div className='progress-linear' style={{ width: "100%" }}>
            {showCreateUserButton && (
              <button
                className='btn btn-primary'
                style={{ marginBottom: "10px" }}
                onClick={() => {
                  setShowCreateModal(true);
                }}
              >
                Create a New User
              </button>
            )}

            {showInviteSSOUserButton && (
              <button
                className='btn btn-primary'
                style={{ marginBottom: "10px", marginLeft: "10px" }}
                onClick={() => {
                  setShowInviteSSOModal(true);
                }}
              >
                Invite SSO User
              </button>
            )}

            <ManageTeachersFilters
              filterString={filterString}
              setFilterString={setFilterString}
              filterSchool={filterSchool}
              setFilterSchool={setFilterSchool}
              filterSubscription={filterSubscription}
              setFilterSubscription={setFilterSubscription}
              filterUserrole={filterUserrole}
              orgunits={editableSchools}
              setFilterUserrole={setFilterUserrole}
            />

            <PaginationBar
              paginationInfo={paginationInfo}
              fetchPageFunc={fetchUsers}
              loading={!ready}
              setPageSize={setPageSize}
              pageSize={pageSize}
            />
          </div>

          <div
            className='visible-scrollbar'
            style={{ width: "100%", overflowX: "auto" }}
          >
            <div
              id='users_table_rows_container'
              className='table table-striped card'
            >
              <div className='users_table users_table_headers'>
                <p
                  className={getHeaderClasses("last_name")}
                  onClick={() => {
                    handleColumnHeaderClick("last_name");
                  }}
                >
                  Name
                </p>
                <p
                  className={getHeaderClasses("email")}
                  onClick={() => {
                    handleColumnHeaderClick("email");
                  }}
                >
                  Email
                </p>
                <p>School</p>
                <p># of Robots</p>
                <p>Enrolled Learners</p>
                <span
                  id='avg_lessons_hr'
                  className={getHeaderClasses("userprofile__recent_lessons")}
                >
                  <p
                    onClick={() => {
                      handleColumnHeaderClick("userprofile__recent_lessons");
                    }}
                  >
                    Recent Lessons
                  </p>

                  <button
                    className='info-circle-btn'
                    onClick={() => {
                      setPopupInfo({
                        header: "Recent Lessons",
                        html: (
                          <div>
                            <p>
                              This represents the number of lessons taken by
                              this teacher's active learners in the last 30
                              days.
                            </p>
                            <p>
                              Cells are color-coded according to the "Recent
                              Lessons" - "Enrolled Learners" ratio. Green cells
                              represent a ratio that is greater than 1. Yellow
                              cells indicate a ratio between 0 and 1. Users with
                              no Recent Lessons (but have Enrolled Learners) are
                              highlighted in Red.
                            </p>
                          </div>
                        ),
                      });
                      setShowPopupInfo(true);
                    }}
                  >
                    <FA
                      icon='info-circle'
                      className='info-circle robots_table_hr'
                    />
                  </button>
                </span>
                <p>Subscriptions</p>
                <p>User Role</p>
                <p className='actions_label' style={{ paddingLeft: "23px" }}>
                  Actions
                </p>
              </div>

              {Array.isArray(userFields) &&
                userFields.map((user, index) => (
                  <ManageTeachersRow
                    user={user}
                    key={user.id}
                    index={index}
                    setCurrentEditUser={setCurrentEditUser}
                    setAssignRobotUserId={setAssignRobotUserId}
                    setEmailToChangePw={setEmailToChangePw}
                    setEmailToResend={setEmailToResend}
                    setUsernameToChangePw={setUsernameToChangePw}
                    setAssignSubscriptionUserId={setAssignSubscriptionUserId}
                    setUserIdToChangePw={setUserIdToChangePw}
                    setExistingSubscriptions={setExistingSubscriptions}
                    setShowPasswordModal={setShowPasswordModal}
                    setUserIdToResend={setUserIdToResend}
                    setCheckProviders={setCheckProviders}
                    setShowResendModal={setShowResendModal}
                  />
                ))}
            </div>
          </div>

          <PaginationBar
            paginationInfo={paginationInfo}
            fetchPageFunc={fetchUsers}
            loading={!ready}
            pageSize={pageSize}
          />
        </div>

        {userFields.length === 0 ? (
          ready ? (
            <div>No users found ...</div>
          ) : (
            <div>
              <FA color='gray' icon='spinner' spin />
              Loading Users ...
            </div>
          )
        ) : null}
      </div>
    </MainDashboard>
  );
};

export default compose(connect(mapStateToProps), withAPI)(ManageTeachers);
