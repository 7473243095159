import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import withAPI from "../../services/api";
import FA from "../../containers/fa";
import { searchObjectArray } from "../../common/utils";
import AddSchoolModal from "./AddSchoolModal.js";
import UserInputFieldDataRow from "./UserInputFieldDataRow";
import MultiSchoolReactSelect from "../InputFields/MultiSchoolReactSelect.js";
import UserRoleSelect from "../InputFields/UserRoleSelect.js";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";
import config from "../../common/config";
import { schoolOptionsToInfo } from "../InputFields/helpers";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const InviteSSOUserModal = ({
  api,
  user,
  fetchUsers,
  setShowModal,
  orgSettings,
}) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const [showAddSchoolModal, setShowAddSchoolModal] = useState(false);
  const [refreshSchoolList, setRefreshSchoolList] = useState(false);

  const [newUserData, setNewUserData] = useState({
    email: "",
    send_welcome: true,
    school: null,
    orgunits: [],
    userrole: null,
  });

  const updateUserFields = (fieldName, value) => {
    console.log(
      "updateUserFields updating field ",
      fieldName,
      " with value ",
      value
    );
    let dataTmp = { ...newUserData };
    if (fieldName === "orgunits") {
      dataTmp["orgunits"] = schoolOptionsToInfo(value);
      console.log("Setting orgunits:", schoolOptionsToInfo(value));
      setNewUserData(dataTmp);
    } else {
      dataTmp[fieldName] = value;
      setNewUserData(dataTmp);
    }
  };

  const handleInviteUser = () => {
    // If provider is unset and default is available, set it with default value
    var invitationData = { ...newUserData };
    invitationData["orgunits"] = newUserData["orgunits"].map(
      (option) => option.id
    );
    if (newUserData.provider === undefined && getDefaultProvider()) {
      invitationData["provider"] = getDefaultProvider();
    }
    setError(null);
    setSending(true);
    setSuccess(false);
    api
      .inviteSSOUser(invitationData)
      .then(() => {
        setSending(false);
        setSuccess(true);
        console.log("Invited:", invitationData.email);
        fetchUsers();
      })
      .catch((e) => {
        setSending(false);
        console.log(e);
        if (e.message) {
          setError(e.message);
        } else {
          setError("Something went wrong... Please try again.");
        }
      });
  };

  const getDefaultProvider = () => {
    if (orgSettings && orgSettings.default_sso_provider) {
      return orgSettings.default_sso_provider;
    } else {
      return null;
    }
  };
  const getOtherProviders = () => {
    if (orgSettings && orgSettings.allowed_sso_providers) {
      return orgSettings.allowed_sso_providers.filter(
        (provider) => provider !== getDefaultProvider()
      );
    } else {
      return [];
    }
  };

  const defaultProviderOption = () => {
    let defaultProvider = getDefaultProvider();
    if (defaultProvider) {
      return (
        <option key={defaultProvider} value={defaultProvider}>
          {defaultProvider.charAt(0).toUpperCase() + defaultProvider.slice(1)}
        </option>
      );
    } else {
      return null;
    }
  };
  const otherProviderOptions = () => {
    let otherProviders = getOtherProviders();
    return otherProviders.map((provider) => (
      <option key={provider} value={provider}>
        {provider.charAt(0).toUpperCase() + provider.slice(1)}
      </option>
    ));
  };

  const providerDropdown = () => {
    return (
      <select
        className='form-control input-lg'
        onChange={(e) => {
          updateUserFields("provider", e.target.value);
        }}
      >
        {defaultProviderOption()}
        {otherProviderOptions()}
      </select>
    );
  };

  const renderUserRoleHelperText = () => {
    let role = searchObjectArray(
      newUserData.userrole,
      "value",
      config.USERROLES
    );
    if (role) {
      return <p>{role.info}</p>;
    }
    return null;
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        {showAddSchoolModal && (
          <AddSchoolModal
            setShowAddSchoolModal={setShowAddSchoolModal}
            triggerRefreshSchoolList={() =>
              setRefreshSchoolList(!refreshSchoolList)
            }
          />
        )}

        <div className='common_border'>
          {/* Header row */}
          <div className='common_heading'>
            <p>Invite User</p>
            <ClosePopupBtn closePopupFunc={setShowModal} />
          </div>

          {/* Main body */}
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <div>
                  <table className='table table-sm'>
                    <tbody>
                      <tr>
                        <td className='item-label'>
                          <p className='mb-0'>School</p>
                          {user &&
                            user.usertype === config.ORGADMIN_USERTYPE && (
                              <span
                                className='like-link'
                                onClick={() => setShowAddSchoolModal(true)}
                              >
                                Create New School?
                              </span>
                            )}
                        </td>
                        <td style={{ width: "300px" }}>
                          <MultiSchoolReactSelect
                            handleChange={(val) => {
                              updateUserFields("orgunits", val);
                            }}
                            value={newUserData.orgunits}
                            refreshSchoolList={refreshSchoolList}
                            disabled={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        {/* TODO: need to add info letting user know about the different roles */}
                        <td className='item-label'>User Role</td>
                        <td>
                          <div style={{ "max-width": "450px" }}>
                            <UserRoleSelect
                              onChange={(e) => {
                                updateUserFields("userrole", e.value);
                              }}
                              selected={searchObjectArray(
                                newUserData.userrole,
                                "value",
                                config.USERROLES
                              )}
                            />
                            {renderUserRoleHelperText() && (
                              <div className='ma-2'>
                                {renderUserRoleHelperText()}
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='item-label'>Email</td>
                        <td>
                          <UserInputFieldDataRow
                            editMode={true}
                            user={newUserData}
                            updateUserFields={updateUserFields}
                            fieldName='email'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='item-label'>SSO Provider</td>
                        <td>{providerDropdown()}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className='d-flex'>
                    {!sending && (
                      <button
                        className='btn btn-sm btn-primary mr-2'
                        onClick={() => {
                          handleInviteUser();
                        }}
                        disabled={
                          !newUserData.orgunits ||
                          !newUserData.email ||
                          !newUserData.userrole
                        }
                      >
                        Send invitation
                      </button>
                    )}
                    {sending && (
                      <button className='btn btn-sm btn-primary mr-2'>
                        <FA icon='spinner' spin />
                        {" Sending ..."}
                      </button>
                    )}
                    <button
                      className='btn btn-sm btn-danger'
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      {success ? (
                        <React.Fragment>{"Close"}</React.Fragment>
                      ) : (
                        <React.Fragment>{"Cancel"}</React.Fragment>
                      )}
                    </button>
                  </div>

                  {error && (
                    <div className='alert alert-danger my-2'>{error}</div>
                  )}

                  {success && (
                    <div className='alert alert-success my-2'>
                      Invitation sent successfully!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(InviteSSOUserModal);
