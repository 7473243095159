import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import config from "../../common/config";

const NoAccountModal = ({category, usertype, closeModal}) => {
  const [messageType, setMessageType] = useState(null);

  useEffect(() => {
    console.log(usertype, category);
    if (
      usertype === config.ORGADMIN_USERTYPE &&
      category === config.ABII_SCHOOL.toLowerCase()
    ) {
      setMessageType("school_orgadmin");
    } else if (
      usertype === config.TEACHER_USERTYPE &&
      category === config.ABII_SCHOOL.toLowerCase()
    ) {
      setMessageType("school_teacher");
    } else if (
      usertype === config.TEACHER_USERTYPE &&
      category === config.ABII_HOME.toLowerCase()
    ) {
      setMessageType("home_teacher");
    }
  }, [usertype, category]);

  const getDstLink = (tgtQuery = "") => {
    if (messageType === "school_teacher") {
      return "/teacher/dashboard" + tgtQuery;
    } else if (messageType === "school_orgadmin") {
      return "/admin/manage-teachers" + tgtQuery;
    } else if (messageType === "home_teacher") {
      return "/home/dashboard" + tgtQuery;
    } else {
      return "/";
    }
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper-large'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Getting Started</p>
          </div>
          <div
            className='common_dashboard_bg'
            style={{maxHeight: "525px", overflowY: "scroll"}}
          >
            <h4>Welcome!</h4>
            {messageType === "school_orgadmin" && (
              <div>
                <p className='mb-0'>
                  You have robots but need to assign them to teachers.
                </p>
                <p>
                  For additional information and instructions on how to complete
                  this setup, refer to your Welcome Kit.
                </p>
                <div>
                  <Link
                    className='btn btn-primary mr-3'
                    onClick={closeModal}
                    to={getDstLink("?open=addTeacher")}
                  >
                    {" "}
                    Add Teachers{" "}
                  </Link>
                  <button className='btn btn-danger' onClick={closeModal}>
                    {" "}
                    No, I'll do it later{" "}
                  </button>
                </div>
              </div>
            )}

            {messageType === "school_teacher" && (
              <div>
                <p className='mb-0'>You don't have any learners yet!</p>
                <p>
                  For additional information and instructions on how to complete
                  this setup, refer to your Welcome Kit.
                </p>
                <div>
                  <Link
                    className='btn btn-primary mr-3'
                    onClick={closeModal}
                    to={getDstLink("?open=addClass")}
                  >
                    {" "}
                    Add Class{" "}
                  </Link>
                  <button className='btn btn-danger' onClick={closeModal}>
                    {" "}
                    No, I'll do it later{" "}
                  </button>
                </div>
              </div>
            )}

            {messageType === "home_teacher" && (
              <div>
                <p className='mb-0'>You don't have any learners yet!</p>
                <p>
                  For additional information and instructions on how to complete
                  this setup, refer to your Welcome Kit.
                </p>
                <div>
                  <Link
                    className='btn btn-primary mr-3'
                    onClick={closeModal}
                    to={getDstLink("?open=addLearner")}
                  >
                    {" "}
                    Add Class{" "}
                  </Link>
                  <button className='btn btn-danger' onClick={closeModal}>
                    {" "}
                    No, I'll do it later{" "}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAccountModal;
