export const filterLessonsBySubjectUnit = (
  lessons,
  activeSubject,
  activeUnit
) => {
  // getting the lessons by filtered subject and unit
  if (!activeSubject) return lessons;
  let lessonsCopy = [];
  for (let i = 0; i < lessons.length; i++) {
    if (lessons[i].label.indexOf(activeSubject.label) !== -1) {
      if (activeUnit) {
        if (
          lessons[i].label.indexOf(activeUnit.label) !== -1 ||
          activeUnit.value === "All Units"
        ) {
          lessonsCopy = lessonsCopy.concat(lessons[i]);
        }
      } else {
        // no unit but satisfied the selected subject
        lessonsCopy = lessonsCopy.concat(lessons[i]);
      }
    }
  }
  return lessonsCopy;
};

export const getHeaderClasses = (column, sortType, sortAsc) => {
  let classes = "sortable";
  if (sortType === column) {
    if (sortAsc === false) {
      classes += " headerSortDown";
    } else {
      classes += " headerSortUp";
    }
  }
  /* else {
    classes += " headerSort";
  }*/
  return classes;
};

/**
 * Iterages through the keys of the learnersList. The keys represent the
 * learner id. If there's a match with the given id, then the learner name
 * is returned for display in the table.
 * @param {int} id
 * @returns String of the learner name. This string should never be empty.
 */
export const findLearnerName = (id, learnersList) => {
  if (!learnersList) return "";
  let name = "";
  for (let key of Object.keys(learnersList)) {
    if (id.toString() === learnersList[key].sid.toString()) {
      name = {
        first_name: learnersList[key].first_name,
        last_name: learnersList[key].last_name,
      };
    }
  }
  return name;
};

/**
 * Iterages through the keys of the learnersList. The keys represent the
 * learner id. If there's a match with the given id, then the learner name
 * is returned for display in the table.
 * @param {int} id
 * @returns The learner object.
 */
 export const findLearnerFromSID = (id, learnersList) => {
  if (!learnersList) return null;
  let learner = null;
  for (let key of Object.keys(learnersList)) {
    if (id.toString() === learnersList[key].sid.toString()) {
      learner = learnersList[key];
    }
  }
  return learner;
};

/**
 * Determines the color of the box the score is in.
 * @param {int} lessonScore
 * @returns
 */
export const getDataColor = (lessonScore) => {
  return lessonScore === null
    ? ""
    : lessonScore >= 75
    ? "green"
    : lessonScore > 50
    ? "yellow"
    : lessonScore >= 0
    ? "red"
    : "";
};
