import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {compose} from "redux";
import logo from "../assets/images/logo.png";
import {Logout} from "../containers";
import FA from "../containers/fa";
import "../partials/DashboardHeader.scss";
import {showSidebar as showSidebarA} from "../redux/actions";
import withAPI from "../services/api";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  showSidebar: state.gui.showSidebar,
  unsyncedLessons: state.status.unsyncedLessons,
  wifiConnected: state.status.wifiConnected,
  syncRefreshed: state.status.syncRefreshed,
  qsyncerSyncing: state.status.qsyncerSyncing,
  lastSuccessfulSync: state.status.lastSuccessfulSync,
});

const DashboardHeader = ({user, showSidebar, dispatch}) => {
  const [showToprightbar, setShowToprightbar] = useState(false);

  const toggleSidebarF = (e) => {
    dispatch(showSidebarA(!showSidebar));
  };
  const topRightBarRef = useRef(null);

  const hideTopRightBar = (e) => {
    if (
      topRightBarRef.current &&
      !topRightBarRef.current.contains(e.target) &&
      !document
        .getElementById("navbar-hamburger-desktop-toggle")
        .contains(e.target)
    ) {
      setShowToprightbar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", hideTopRightBar);
    return () => {
      document.removeEventListener("mousedown", hideTopRightBar);
    };
  }, []);

  return (
    <nav className='navbar navbar-expand-lg custom-primary fixed-top'>
      <Link className='navbar-brand' to='/'>
        <img alt='logo' src={logo} />
      </Link>
      <button
        id='navbar-hamburger-mobile-toggle'
        className='navbar-toggler'
        type='button'
        onClick={toggleSidebarF}
      >
        <FA icon='bars' color='black' />
      </button>

      <div className='collapse navbar-collapse'>
        <ul className='navbar-nav custom-navbar header ml-auto align-items-center'>
          <li>
            <span className='text-primary'>
              <FA color='black' icon='user' />
            </span>
          </li>
          <li>
            <span className='text-primary'>
              <Link to='/settings' className='bold_font mr-10'>
                &nbsp; {user.user.username}
              </Link>
            </span>
          </li>
          <li className='nav-item dropdown'>
            <button
              id='navbar-hamburger-desktop-toggle'
              className='btn'
              onClick={() => {
                setShowToprightbar(!showToprightbar);
              }}
            >
              <FA icon='bars' color='black' />
            </button>
            {showToprightbar && (
              <div
                ref={topRightBarRef}
                className='dropdown-menu-right dropdown-menu show'
              >
                <span>
                  <Logout color='black' />
                </span>
                <span>
                  <Link
                    to='/settings'
                    className='btn dropdown-item'
                    onClick={() => setShowToprightbar(false)}
                  >
                    <FA icon='cog' color='black' />
                    &nbsp; Settings
                  </Link>
                </span>
              </div>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default compose(connect(mapStateToProps), withAPI)(DashboardHeader);
