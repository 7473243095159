import {jsPDF} from "jspdf";
import React from "react";
import {addElement2Pdf, addScrollingElement2Pdf} from "../../../common/helpers";
import FA from "../../../containers/fa";

const PdfDownloadBtn = ({
  btnCaption,
  enableBtn,
  setShowOverlay,
  type,
  activeClass = null,
  activeLearner = null,
  selectedLessonName = null,
  selectedLessonInstance = null,
}) => {
  const class_pdf_download = async () => {
    setShowOverlay(true);
    // save current position, and scroll to top
    const xPos = window.scrollX;
    const yPos = window.scrollY;
    window.scroll(0, 0);

    let pdf = new jsPDF({
      format: "letter",
    });

    pdf.setFontSize(15);
    if (type === "lesson") {
      pdf.text("Class Lesson Report", 90, 8);
      pdf.text("Learner Performance + Attention Class Average", 60, 16);
    } else if (type === "class") {
      // class scores table
      pdf.text("Class Scores and Averages Report", 60, 8);
      pdf.text(activeClass.label, 60, 16);
    } else if (type === "learnerLessonSession") {
      // learner tab
      pdf.text("Learner Report", 90, 8);
      pdf.text(activeLearner.label, 10, 16);
      pdf.text(selectedLessonInstance.label, 10, 24);
    }

    let nextOffset = 30;
    if (type === "lesson") {
      nextOffset = await addElement2Pdf(pdf, "options_container", nextOffset);
      nextOffset = await addElement2Pdf(
        pdf,
        "class_averages_bar_chart",
        nextOffset
      );
    } else if (type === "class") {
      nextOffset = await addScrollingElement2Pdf(
        pdf,
        "learner_scores_rows_container",
        nextOffset
      );
    } else if (type === "learnerLessonSession") {
      nextOffset = await addElement2Pdf(
        pdf,
        "learner_session_options",
        nextOffset
      );
      nextOffset = await addElement2Pdf(
        pdf,
        "lesson_context_info_container",
        nextOffset
      );
      nextOffset = await addElement2Pdf(
        pdf,
        "class_vs_learner_bar_chart",
        nextOffset
      );
      nextOffset = await addElement2Pdf(
        pdf,
        "learner_performance_line_chart",
        nextOffset
      );
      nextOffset = await addElement2Pdf(
        pdf,
        "lesson_session_notes",
        nextOffset
      );
    }
    // format filename
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const timestamp = new Date(Date.now());
    pdf.save(
      `${
        type === "lesson"
          ? "class_lesson_report"
          : type === "class"
          ? "class_scores_report"
          : "learner_report"
      }_unit__${
        months[timestamp.getMonth()]
      }_${timestamp.getDate()}_${timestamp.getFullYear()}.pdf`
    );
    // scroll back to bottom
    window.scroll(xPos, yPos);
    setShowOverlay(false);
  };

  return (
    <button
      style={{
        fontSize: "90%",
        justifySelf: "end",
        flex: "1",
      }}
      title={btnCaption}
      disabled={!enableBtn}
      className={`btn-success btn`}
      onClick={() => {
        if (!enableBtn) return;
        class_pdf_download();
      }}
    >
      <span>
        <FA icon='download' color='white' /> {btnCaption}
      </span>
    </button>
  );
};

export default PdfDownloadBtn;
