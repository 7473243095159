import config from "../../common/config";

export const formatTitle = (context) => {
  let title = context[0].label || "";
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(title);
  date.setDate(date.getDate() - 30);
  if (title) {
    title = `${months[date.getMonth()]} ${date.getDate()} - ${title}`;
  }
  return title;
};

export const formatLabel = (user, data, context, typeOfData) => {
  let numerator,
    denominator = 0;
  let captions = ["", ""];
  const hoveredDataEntry =
    data[context.dataset.uncroppedIndexList[context.dataIndex]];

  if (typeOfData.indexOf("Robots") !== -1) {
    numerator = hoveredDataEntry.connected_robots_count
      ? hoveredDataEntry.connected_robots_count
      : 0;
    denominator = hoveredDataEntry.total_robots
      ? hoveredDataEntry.total_robots
      : 0;
    captions = ["robots", `have checked-in.`];
  } else if (typeOfData.indexOf("Organizations") !== -1) {
    numerator = hoveredDataEntry.active_organizations_count;
    denominator = hoveredDataEntry.total_organizations;
    captions = ["organizations", ""];
  } else if (typeOfData.indexOf("Learners") !== -1) {
    numerator = hoveredDataEntry.active_learners_count
      ? hoveredDataEntry.active_learners_count
      : 0;
    denominator = hoveredDataEntry.total_learners
      ? hoveredDataEntry.total_learners
      : 0;
    captions = ["learners", "has taken at least one lesson."];
  } else if (typeOfData.indexOf("Teachers") !== -1) {
    numerator = hoveredDataEntry.active_teachers_count
      ? hoveredDataEntry.active_teachers_count
      : 0;
    denominator = hoveredDataEntry.total_teachers
      ? hoveredDataEntry.total_teachers
      : 0;
    captions = ["teachers", "actively used the robots."];
  }

  if (user.usertype === config.SUPERADMIN_USERTYPE) {
    return `${context.formattedValue} ${captions[0]} ${
      typeOfData.indexOf("Organizations") !== -1
        ? "have active learners"
        : "are active"
    }.`;
  } else {
    return `${context.formattedValue}${
      user.usertype === config.ORGADMIN_USERTYPE
        ? `% (${numerator}/${denominator}) of`
        : user.usertype === config.ORGUNITADMIN_USERTYPE
        ? `% (${numerator}/${denominator}) of`
        : ""
    } ${captions[0]} ${captions[1]}`;
  }
};
