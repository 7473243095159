import moment from "moment";
import React, { useEffect, useState } from "react";
import FA from "../../containers/fa";
import "./../../containers/TeacherDashboard/Dashboard.scss";
import "./Assignment.scss";
import SelectedLessonInfoPopup from "./CreateAssignment/Steps/StepFour/SelectedLessonInfoPopup";
import Donut from "./Donut";
import ExpandedAssignmentInfo from "./ExpandedAssignmentContents/ExpandedAssignmentInfo";

const Assignment = ({
  index,
  initialLoad,
  assignment,
  expandedAssignments,
  setExpandedAssignments,
  setAssignmentToDelete,
  setAssignmentToEdit,
}) => {
  const [assignmentToggleEnabled, setAssignmentToggleEnabled] = useState(false);
  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [lessonForPopupInfo, setLessonForPopupInfo] = useState(null);
  /**
   * getAssignmentStatusColor determines the color of the status box.
   * @param {String} status
   * @returns {String}
   */
  const getAssignmentStatusColor = (status) => {
    if (status === "completed") return "green";
    if (status === "in_progress") return "yellow";
    if (status === "closed" || status === "postponed") return "gray";
    return "red";
  };

  /**
   * getAssignmentStatusLabel determines the text in the status box.
   * @param {String} status
   * @returns {String}
   */
  const getAssignmentStatusLabel = (status) => {
    if (status === "completed") return "Completed";
    if (status === "in_progress") return "In Progress";
    if (status === "closed") return "Closed";
    if (status === "postponed") return "Postponed";
    return "Not Started";
  };

  // ensures enough time passes for the expand/close animation for classes
  useEffect(() => {
    if (!assignmentToggleEnabled) {
      setTimeout(() => {
        setAssignmentToggleEnabled(true);
      }, 600);
    }
  }, [assignmentToggleEnabled]);

  /**
   * getClassHeight changes maxHeight, which facilitates the open/close anim.
   * @returns {String} the css value
   */
  const getClassHeight = () => {
    if (expandedAssignments.indexOf(assignment.id) !== -1) {
      return `${1015}px`;
    }
    return "0px";
  };

  /**
   * isExpanded returns true if the assignment was clicked to expand.
   * @returns {Boolean}
   */
  const isExpanded = () => {
    if (expandedAssignments.indexOf(assignment.id) !== -1) {
      return true;
    }
    return false;
  };

  /**
   * When an assignment is clicked, expandAssignment adds or removes it from
   * the list of expanded assignments and updates its css overflow.
   * @returns none
   */
  const expandAssignment = () => {
    let id = assignment.id;
    if (expandedAssignments.indexOf(id) === -1) {
      if (!assignmentToggleEnabled) return;
      setAssignmentToggleEnabled(false);
      setExpandedAssignments([id, ...expandedAssignments]);
      setTimeout(() => {
        document.getElementById("learners-wrapper-" + id).style.overflow = "";
      }, 500);
    } else {
      if (!assignmentToggleEnabled) return;
      setAssignmentToggleEnabled(false);
      setExpandedAssignments(expandedAssignments.filter((val) => val !== id));
      document.getElementById("learners-wrapper-" + id).style.overflow =
        "hidden";
    }
  };

  useEffect(() => {
    if (index === 0 && initialLoad) {
      setTimeout(() => {
        let id = assignment.id;
        setAssignmentToggleEnabled(false);
        setExpandedAssignments([id, ...expandedAssignments]);
        setTimeout(() => {
          document.getElementById("learners-wrapper-" + id).style.overflow = "";
        }, 500);
      }, 600);
    }
  }, []);

  return (
    <div>
      {showPopupInfo && (
        <SelectedLessonInfoPopup
          lesson={lessonForPopupInfo}
          setShowingLessonInfo={setShowPopupInfo}
        />
      )}
      <div className='assignments_table_row' onClick={() => expandAssignment()}>
        <div
          className='assignment-name-and-triangle-grid'
          style={{ paddingLeft: "10px" }}
        >
          <button
            className={`triangle-${
              expandedAssignments.indexOf(assignment.id) !== -1 ? "up" : "down"
            }`}
          />
          <h5 className='assignment_name_data'>{assignment.name}</h5>
        </div>

        <p
          className={`assignment_status_${getAssignmentStatusColor(
            assignment.status
          )} hide_on_ipad`}
        >
          {getAssignmentStatusLabel(assignment.status)}
        </p>

        <div className='assignment_data_with_zoom_label'>
          <p className='show_on_ipad_zoom' style={{ marginRight: "5px" }}>
            <b>Created:</b>
          </p>
          <p>{moment(assignment.created_at).format("L")}</p>
        </div>

        <div className='assignment_data_with_zoom_label'>
          <p className='show_on_ipad_zoom' style={{ marginRight: "5px" }}>
            <b>Time Frame:</b>
          </p>
          <p>
            {moment(assignment.start_time).format("L")} -{" "}
            {assignment.end_time !== null
              ? moment(assignment.end_time).format("L")
              : "(No end date)"}
          </p>
        </div>

        <div className='assignment_data_with_zoom_label'>
          <p className='show_on_ipad_zoom' style={{ marginRight: "5px" }}>
            <b>Overall Completion:</b>
          </p>
          <Donut percentFill={assignment.overall_completion_perc} />
        </div>

        <div className='assignment_data_with_zoom_label'>
          <p className='show_on_ipad_zoom' style={{ marginRight: "5px" }}>
            <b>Learners Finished:</b>
          </p>
          <Donut
            totalLearners={assignment.learners_count}
            learnersCompleted={assignment.learners_finished}
          />
        </div>

        <div className='assignment_data_with_zoom_label'>
          <p className='show_on_ipad_zoom' style={{ marginRight: "5px" }}>
            <b>Learners:</b>
          </p>
          <p>{assignment.learners_count}</p>
        </div>

        <div className='assignment_data_with_zoom_label'>
          <p className='show_on_ipad_zoom' style={{ marginRight: "5px" }}>
            <b>Lessons:</b>
          </p>
          <p>{assignment.lessons_count}</p>
        </div>

        <p className='hide_on_ipad'>
          <button
            className='btn btn-sm btn-primary assignment_actions_btn'
            assignment-data-title='Edit Assignment'
            onClick={(e) => {
              e.stopPropagation(); // won't expand/close the assignment view
              setAssignmentToEdit(assignment);
            }}
          >
            <FA icon='edit' color='white' />
          </button>
          <button
            className='btn btn-sm btn-danger assignment_actions_btn'
            assignment-data-title='Delete Assignment'
            onClick={(e) => {
              e.stopPropagation(); // won't expand/close the assignment view
              setAssignmentToDelete(assignment);
            }}
          >
            <FA icon='trash' color='white' />
          </button>
        </p>
      </div>

      <div
        className='assignment_actions_ipad show_on_ipad'
        onClick={() => expandAssignment()}
      >
        <p
          className={`assignment_status_${getAssignmentStatusColor(
            assignment.status
          )}`}
        >
          {getAssignmentStatusLabel(assignment.status)}
        </p>

        <p>
          <b>Actions: </b>
        </p>
        <p>
          <button
            className='btn btn-sm btn-primary assignment_actions_btn'
            assignment-data-title='Edit Assignment'
            onClick={(e) => {
              e.stopPropagation(); // won't expand/close the assignment view
              setAssignmentToEdit(assignment);
            }}
          >
            <FA icon='edit' color='white' />
          </button>
          <button
            className='btn btn-sm btn-danger assignment_actions_btn'
            assignment-data-title='Delete Assignment'
            onClick={(e) => {
              e.stopPropagation(); // won't expand/close the assignment view
              setAssignmentToDelete(assignment);
            }}
          >
            <FA icon='trash' color='white' />
          </button>
        </p>
      </div>

      <div
        id={`learners-wrapper-${assignment.id}`}
        className='expanded_assignment_container'
        style={{
          overflow: isExpanded() ? "none" : "hidden",
          maxHeight: getClassHeight(),
        }}
      >
        <ExpandedAssignmentInfo
          assignment={assignment}
          setLessonForPopupInfo={setLessonForPopupInfo}
          setShowPopupInfo={setShowPopupInfo}
        />
      </div>
    </div>
  );
};

export default Assignment;
