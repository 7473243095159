import React, { useState, useEffect } from 'react';
import IdleTimer from 'react-idle-timer'
import { authService } from '../services/auth';
import { logout } from '../redux/actions';
import { Logout } from '../containers';
import './AutoLogout.scss'


class IdleAlert extends React.Component {
  constructor(props) {
    super(props)
    this.countDownMax = 60
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
    this.onAction = this._onAction.bind(this)
    this.countDownTimer = null
    this.state = {
      showLogoutModal: false,
      countDownSecs: this.countDownMax,
    };
  }

  showModal = () => {
    this.setState({
      showLogoutModal: true
    })
  };

  hideModal = () => {
    this.setState({
      showLogoutModal: false
    })
  };

  handleClose = () => {
    console.log('Then I shall not sleep.')
    this.hideModal();
    this.resetTimer();

  }

  activateCountdown = () => {
    console.log('Countdown started.')
    this.showModal();
    this.resetTimer();
    this.startTimer();
  }

  startTimer = () => {
    this.countDownTimer = setInterval(this.countDown, 1000);
  }

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.countDownSecs - 1;
    this.setState({
      countDownSecs: seconds,
    });
    // Check if we're at zero.
    // console.log(seconds)
    if (seconds < 1) { 
      clearInterval(this.countDownTimer);
      // log out and show a message explaining
      // Borrow from Logout.js?
      authService.logout().then(() => {
        this.hideModal();
        document.location.href = "/logged-out";
      })

    }
  }

  resetTimer = () => {
    // in case anything happens and we got multiple countdowns
    clearInterval(this.countDownTimer);
    this.setState({
      countDownSecs: this.countDownMax,
    });
  }

  render() {
    return(
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 60} />
        <AutoLogoutModal
          showLogoutModal={this.state.showLogoutModal}
          handleClose={this.handleClose}
          countDownSecs={this.state.countDownSecs} />
      </div>
      )
  }

  _onIdle(e) {
    console.log('user is idle')
    this.activateCountdown();
  }

  _onAction(e) {
    // console.log('user actioned!')
  }
}




class AutoLogoutModal extends React.Component {

  render() {
      return (
      <div className={this.props.showLogoutModal ? "modal display-block" : "modal display-none"}>
        <section className="modal-main">
          <p>Looks like it's time for me to go to sleep... </p>
          <p>If you want to keep me awake, please click on "Keep me awake" below.</p>
          <button className="btn btn-primary" id="keep-alive-btn" onClick={this.props.handleClose}>Keep me awake</button>

          <p className="sleep-countdown"> Sleeping in {this.props.countDownSecs} seconds.</p>
        </section>
      </div>
    );
  };
};




export default IdleAlert;
