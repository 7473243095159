import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { useDebounce } from "use-debounce";

import config from "../../common/config";
import CheckboxFilter from "../../containers/ManageSubscriptions/Filters/CheckboxFilter.jsx";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import RobotRow from "../ManageAllRobots/RobotRow";
import InfoPopup from "../UtilComps/InfoPopup";
import PaginationBar from "../UtilComps/PaginationBar.js";

const OrgRobotsTable = ({ api, orgId, canCollapse }) => {
  const [robots, setRobots] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [spin, setSpin] = useState(true);
  const [error, setError] = useState(null);

  const [filterString, setFilterString] = useState(null);
  const [debouncedFilterString] = useDebounce(filterString, 1000);

  const [robotSummaryStats, setRobotSummaryStats] = useState(null);
  const [infoText, setInfoText] = useState(null);
  const [reloadRobots, setReloadRobots] = useState(false);

  const [sortType, setSortType] = useState("wifi");
  const [sortAsc, setSortAsc] = useState(false);

  const [collapse, setCollapse] = useState(false);

  const [filterExpiredRobots, setFilterExpiredRobots] = useState(false);
  const [filterExpiringRobots, setFilterExpiringRobots] = useState(false);
  const [filterExcludeExpiredRobots, setFilterExcludeExpiredRobots] =
    useState(false);

  const handleExcludeFilterChange = (val) => {
    setFilterExcludeExpiredRobots(val);
    setFilterExpiredRobots(false);
    setFilterExpiringRobots(false);
  };
  const handleExpiringRobotsFilterChange = (val) => {
    setFilterExpiringRobots(val);
    setFilterExcludeExpiredRobots(false);
    setFilterExpiredRobots(false);
  };
  const handleExpiredRobotsFilterChange = (val) => {
    setFilterExpiredRobots(val);
    setFilterExcludeExpiredRobots(false);
    setFilterExpiringRobots(false);
  };

  const fetchRobots = (pageNum, forceCalcStats = false) => {
    const calcStats =
      forceCalcStats || (robotSummaryStats === null ? true : false);
    let queryParams = {
      q: debouncedFilterString || "",
      sort: (sortAsc ? "" : "-") + sortType,
      calc_stats: calcStats,
      page_size: pageSize,
      get_expired_robots: filterExpiredRobots,
      get_expiring_robots: filterExpiringRobots,
      get_non_expired_robots: filterExcludeExpiredRobots,
    };

    if (pageNum) {
      queryParams["page"] = pageNum;
    }

    if (orgId) {
      queryParams.org_id = orgId;
    }

    // skip nulls,  empty strings, false values
    queryParams = Object.entries(queryParams).filter(([, v]) => v);

    console.log("Asking for robots in org:", queryParams);
    setSpin(true);
    try {
      api.fetchRobotsInOrg(queryParams).then((r) => {
        setRobots(r.results);
        delete r["results"];
        if (calcStats) {
          setRobotSummaryStats(r.stats);
        }
        delete r["stats"];
        setPaginationInfo(r);
        setSpin(false);
        console.log("OrgRobotsTable got Robots:", r);
      });
    } catch (e) {
      console.log("Error fetching robots", e);
      setError("There was an error fetching the robots. Please try again.");
      setSpin(false);
    }
  };

  useEffect(() => {
    fetchRobots();
  }, [sortType, sortAsc, pageSize, reloadRobots]);

  //force calc stats on robot filter change
  useEffect(() => {
    fetchRobots(null, true);
  }, [filterExpiringRobots, filterExpiredRobots, filterExcludeExpiredRobots]);

  useEffect(() => {
    if (debouncedFilterString !== null) {
      fetchRobots(null, true);
    }
  }, [debouncedFilterString]);

  const handleColumnHeaderClick = (column) => {
    if (sortType === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortType(column);
      setSortAsc(false);
    }
  };

  const getHeaderClasses = (column) => {
    let classes = "sortable";
    if (sortType === column) {
      if (sortAsc === false) {
        classes += " headerSortDown";
      } else {
        classes += " headerSortUp";
      }
    } else {
      classes += " headerSort";
    }
    return classes;
  };

  const handleExportRobots = () => {
    // can become more involved later..
    api.exportRobots({ orgId: orgId });
  };

  function triggerReloadRobots() {
    console.log("Triggering Refresh of Robot List");
    setReloadRobots(!reloadRobots);
  }

  return (
    <div className='common_border'>
      {infoText && (
        <InfoPopup popupInfo={infoText} setShowPopupInfo={setInfoText} />
      )}

      <div
        className='common_heading d-flex justify-content-between align-items-center'
        style={{ cursor: "pointer" }}
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <span>
          <h4>
            {canCollapse && (
              <span className='mr-2'>
                {collapse ? (
                  <FA color='gray' icon='chevron-right' />
                ) : (
                  <FA color='gray' icon='chevron-down' />
                )}
              </span>
            )}
            Robots
          </h4>
        </span>
      </div>

      {!collapse && (
        <div className='common_dashboard_bg'>
          <div className='row'>
            <div className='col-12'>
              <div>
                <div className='row'>
                  <div style={{ padding: "0px 15px 0px 15px" }}>
                    <div className='d-flex'>
                      <div className='d-flex justify-content-end'>
                        <label className='pt-2 mr-2'>
                          <FA icon='search' />
                        </label>
                        &nbsp;
                        <input
                          placeholder='Search ...'
                          className='form-control input-underline'
                          style={{ minWidth: "80px" }}
                          value={filterString || ""}
                          onChange={(e) =>
                            setFilterString(e.target.value.toLowerCase())
                          }
                        />
                        <Link
                          className='btn btn-primary ml-4'
                          to={`/super/org/${orgId}/add-robot`}
                        >
                          Add Robots
                        </Link>
                        {robotSummaryStats !== null && (
                          <button
                            className={"btn btn-primary float-right ml-4"}
                            data-title={
                              robotSummaryStats.robots_with_issues_count > 0
                                ? "Export to check robots with issues"
                                : ""
                            }
                            onClick={() => {
                              handleExportRobots();
                            }}
                          >
                            Export All{" "}
                            {robotSummaryStats.robots_with_issues_count > 0 && (
                              <FA icon='exclamation-circle' color='yellow' />
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                    {robots && (
                      <div>
                        {robotSummaryStats !== null && paginationInfo && (
                          <div className='mt-2'>
                            <h5 style={{ marginBottom: "0px" }}>
                              Subscription Filters:
                            </h5>
                            <CheckboxFilter
                              filterLabel='Expiring Soon'
                              checked={filterExpiringRobots}
                              setChecked={handleExpiringRobotsFilterChange}
                              checkBoxHoverText='Only show expiring robots.'
                              popupHeaderText='Expiring Robots'
                              popupBodyText='Filters for robots with subscriptions that are expiring in the next 60 days.'
                            />
                            <CheckboxFilter
                              filterLabel='Expired'
                              checked={filterExpiredRobots}
                              setChecked={handleExpiredRobotsFilterChange}
                              checkBoxHoverText='Only show expired robots.'
                              popupHeaderText='Expired Robots'
                              popupBodyText='Filters for robots with expired subscriptions.'
                            />
                            <CheckboxFilter
                              filterLabel='Exclude Expired'
                              checked={filterExcludeExpiredRobots}
                              setChecked={handleExcludeFilterChange}
                              checkBoxHoverText='Only show robots that are not expired.'
                              popupHeaderText='Exclude Expired Robots'
                              popupBodyText='Filters for robots that do not have expired subscriptions.'
                            />
                            <h5 className='mt-3'>
                              Total Count: {paginationInfo.count}
                            </h5>
                            {robotSummaryStats.active_robot_stat && (
                              <p className='my-0'>
                                <span className='mr-2'>
                                  Checked-in online in the past 30 days:
                                </span>
                                <span className='bold_font'>
                                  {robotSummaryStats.active_robot_stat.count} /{" "}
                                  {paginationInfo.count} (
                                  {robotSummaryStats.active_robot_stat.perc}%)
                                </span>
                              </p>
                            )}
                            {robotSummaryStats.active_learner_count !==
                              null && (
                              <p className='my-0'>
                                <span className='mr-2'>
                                  Number of learners with lesson activity in the
                                  past 30 days:
                                </span>
                                <span className='bold_font'>
                                  {robotSummaryStats.active_learner_count}
                                </span>
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PaginationBar
            paginationInfo={paginationInfo}
            fetchPageFunc={fetchRobots}
            loading={spin}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />

          <div className='card'>
            <div className='card'>
              <div
                className='progress-linear school_listing manage_learner_table table-scroll-wrapper'
                role='progressbar'
              >
                <div
                  className='robots_table_row_super_admin'
                  style={{ backgroundColor: "white", fontWeight: "bold" }}
                >
                  <div>
                    <p></p>
                  </div>
                  <div
                    className={getHeaderClasses("online")}
                    onClick={() => {
                      handleColumnHeaderClick("online");
                    }}
                  >
                    Online Now
                  </div>
                  <div
                    className={getHeaderClasses("wifi")}
                    onClick={() => {
                      handleColumnHeaderClick("wifi");
                    }}
                    style={{ justifyContent: "left" }}
                  >
                    Wifi Name
                  </div>
                  <div
                    className={getHeaderClasses("code_ver")}
                    onClick={() => {
                      handleColumnHeaderClick("code_ver");
                    }}
                  >
                    Version
                  </div>

                  <div
                    className={getHeaderClasses("end_date")}
                    onClick={() => {
                      handleColumnHeaderClick("end_date");
                    }}
                  >
                    Expiration Date
                  </div>

                  <div
                    className={getHeaderClasses("last_code_update")}
                    onClick={() => {
                      handleColumnHeaderClick("last_code_update");
                    }}
                  >
                    Last Software Update
                  </div>
                  <div
                    className={getHeaderClasses("last_lesson_update")}
                    onClick={() => {
                      handleColumnHeaderClick("last_lesson_update");
                    }}
                  >
                    Last Lesson Update
                  </div>
                  <div
                    className={getHeaderClasses("last_synced_on")}
                    onClick={() => {
                      handleColumnHeaderClick("last_synced_on");
                    }}
                  >
                    Last Online Check-in
                  </div>

                  <div
                    className={getHeaderClasses("cloud_accounts")}
                    onClick={() => {
                      handleColumnHeaderClick("cloud_accounts");
                    }}
                  >
                    Cloud Accounts?
                  </div>
                  <div style={{ justifyContent: "left" }}>School</div>
                  <div>
                    Total Learners
                    <FA
                      icon='info-circle'
                      className='info-circle'
                      title='Number of learners that have taken lessons.'
                      onClick={() => {
                        setInfoText({
                          header: "Total Learners",
                          text: "Number of learners that have taken lessons.",
                        });
                      }}
                    />
                  </div>
                  <div>
                    Total Lessons
                    <FA
                      icon='info-circle'
                      className='info-circle'
                      title='Total number of lessons taken.'
                      onClick={() => {
                        setInfoText({
                          header: "Total Lessons",
                          text: "Total number of lessons taken.",
                        });
                      }}
                    />
                  </div>
                  <div>Remove from Org</div>
                </div>

                {error && (
                  <div className='alert alert-danger' role='alert'>
                    {error}
                  </div>
                )}

                {!error && (
                  <div>
                    {robots &&
                      paginationInfo &&
                      robots.map((rbt, ind) => (
                        <RobotRow
                          key={rbt.serial}
                          rbt={rbt}
                          ind={paginationInfo.start_index + ind}
                          showRemove={true}
                          schoolOrgAdminView={false}
                          superAdminOrgPageView={true}
                          superAdminView={true}
                          showOnlineNow={true}
                          usertype={config.SUPERADMIN_USERTYPE}
                          refreshFunction={triggerReloadRobots}
                        />
                      ))}
                    {spin && robots.length === 0 && (
                      <p colSpan='100%'>
                        <FA color='gray' icon='spinner' spin /> Loading
                        robots...
                      </p>
                    )}
                    {!spin && robots.length === 0 && (
                      <p colSpan='100%'>No robots found.</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <PaginationBar
            paginationInfo={paginationInfo}
            fetchPageFunc={fetchRobots}
            loading={spin}
            pageSize={pageSize}
          />
        </div>
      )}
    </div>
  );
};

export default compose(withAPI)(OrgRobotsTable);
