import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { compose } from "redux";
import config from "./common/config";
import PrivateRoute from "./components/PrivateRoute";
import {
  ABiiResource,
  ActivateAbii,
  AddRobotToTag,
  AddTeacherToTag,
  AdminManageClasses,
  OrgPage,
  AddRobotToOrg,
  MergeOrganization,
  ForgotPassword,
  ForgotUsername,
  LearnerLogin,
  LoggedOut,
  Login,
  ManageAllLearners,
  ManageAllRobots,
  ManageTeachers,
  NewSecurityOnLogin,
  OrganizationInfo,
  PrivacyPolicy,
  RobotPage,
  RobotTagPage,
  Register,
  Settings,
  StandalonePasswordReset,
  SyncHistory,
  TeacherClass,
  TeacherDashboard,
  AddTeacherToClass,
  SubscriptionInfoContainer,
  RenewalInfoContainer,
  PasswordResetFromEmail,
  Welcome,
  WiFiLoggedOut,
  WiFiSetup,
  AllSales,
  AllSubscriptions,
  TeacherAccountPage,
  AsOrgAdminRedirect,
  GoogleLogin,
  CleverLogin,
  CleverCallback,
  UsageImpactStats,
} from "./containers";
import ConsolidatedReports from "./containers/ConsolidatedReports/ConsolidatedReports";
import LessonAssignments from "./containers/LessonAssignment/LessonAssignments";
import OrgsList from "./containers/SuperAdmin/OrgsList";
import withAPI from "./services/api";
import "./styles/app.scss";
import ScrollToTop from "./ScrollToTop";

library.add(fas);
console.log("Page start loading...");
console.log(new Date().toLocaleString());
console.log("We are on: " + window.location.href);

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  robotType: state.status.robotType,
});

const App = ({ api, user, robotType }) => {
  const reroute = () => {
    if (
      user &&
      user.usertype === config.TEACHER_USERTYPE &&
      user.organization.category === config.ABII_SCHOOL.toLowerCase()
    ) {
      return <Redirect to='/teacher/dashboard' />;
    } else if (
      user &&
      user.usertype === config.TEACHER_USERTYPE &&
      user.organization.category === config.ABII_HOME.toLowerCase()
    ) {
      return <Redirect to='/home/dashboard' />;
    } else if (user && user.usertype === config.ORGADMIN_USERTYPE) {
      return <Redirect to='/admin/dashboard' />;
    } else if (user && user.usertype === config.ORGUNITADMIN_USERTYPE) {
      return <Redirect to='/admin/dashboard' />;
    } else if (user && user.usertype === config.SUPERADMIN_USERTYPE) {
      return <Redirect to='/super/dashboard' />;
    } else {
      return <Welcome />;
    }
  };

  useEffect(() => {
    console.log("CONFIGURATION: ", config);
  }, []);

  return (
    <section className='main-container main-scroll-wrapper'>
      <Router>
        <ScrollToTop />
        <Route path='/clever-login' component={CleverLogin} />
        <Route path='/clever-sso-login' component={CleverLogin} />
        <Route path='/clever-securesync-login' component={CleverLogin} />
        <Route path='/clever-callback' component={CleverCallback} />
        <Route path='/google-login' component={GoogleLogin} />
        <Route path='/register' component={Register} />
        <Route path='/' exact render={reroute} />
        <Route path='/dashboard' exact render={reroute} />
        <PrivateRoute path='/settings' component={Settings} />
        <PrivateRoute
          path='/new-security-question'
          component={NewSecurityOnLogin}
        />
        <PrivateRoute
          path='/password-reset'
          component={StandalonePasswordReset}
        />
        <Route
          path='/password-reset-email'
          component={PasswordResetFromEmail}
        />
        <PrivateRoute path='/teacher/dashboard' component={TeacherDashboard} />
        <PrivateRoute
          path='/teacher/assignments'
          component={LessonAssignments}
        />
        <PrivateRoute path='/admin/dashboard' component={UsageImpactStats} />
        <PrivateRoute
          path='/admin/manage-classes'
          component={AdminManageClasses}
        />
        <PrivateRoute
          path='/admin/manage-teachers'
          component={ManageTeachers}
        />
        <PrivateRoute
          path='/admin/manage-teacher/:teacherId'
          exact
          component={TeacherAccountPage}
        />
        <PrivateRoute path='/admin/my-org' component={OrganizationInfo} />
        <PrivateRoute
          path='/admin/subscriptions'
          exact
          component={AllSubscriptions}
        />
        <PrivateRoute
          path='/admin/subscription/:subscriptionType'
          exact
          component={SubscriptionInfoContainer}
        />
        <PrivateRoute
          path='/admin/subscription/:subscriptionType/:subId'
          exact
          component={SubscriptionInfoContainer}
        />
        <PrivateRoute
          path='/admin/my-robots/'
          exact
          component={ManageAllRobots}
        />
        <PrivateRoute
          path='/admin/my-classes/'
          exact
          component={AdminManageClasses}
        />
        <PrivateRoute path='/user/sync-history' component={SyncHistory} />
        <PrivateRoute
          path='/user/classes/:clsId'
          exact
          component={TeacherClass}
        />
        <PrivateRoute
          path='/user/classes/:tagId/add_teachers'
          exact
          component={AddTeacherToClass}
        />
        <PrivateRoute
          path='/user/classes/:clsId/learner/:learnerUsername/:learnerId/login'
          exact
          component={LearnerLogin}
        />
        <PrivateRoute
          path='/user/reports'
          exact
          component={ConsolidatedReports}
        />
        <PrivateRoute
          path='/user/reports/:classOrLearnerId'
          exact
          component={ConsolidatedReports}
        />
        <PrivateRoute
          path='/user/all-learners'
          exact
          component={ManageAllLearners}
        />
        <PrivateRoute path='/super/dashboard' component={UsageImpactStats} />
        <PrivateRoute path='/super/merge-org' component={MergeOrganization} />
        <PrivateRoute path='/super/orgs-list' exact component={OrgsList} />
        <PrivateRoute path='/super/org/:orgId' exact component={OrgPage} />
        <PrivateRoute
          path='/super/org/as-orgadmin/:orgId'
          exact
          component={AsOrgAdminRedirect}
        />
        <PrivateRoute
          path='/super/org/:orgId/add-robot'
          exact
          component={AddRobotToOrg}
        />
        <PrivateRoute path='/super/sales' exact component={AllSales} />
        <PrivateRoute
          path='/super/subscriptions'
          exact
          component={AllSubscriptions}
        />
        <PrivateRoute
          path='/super/subscription/:orgId/:subscriptionType'
          exact
          component={SubscriptionInfoContainer}
        />
        <PrivateRoute
          path='/renewal/:renewalId'
          exact
          component={RenewalInfoContainer}
        />
        <PrivateRoute path='/robots' exact component={ManageAllRobots} />
        <PrivateRoute path='/robot/:serial' exact component={RobotPage} />
        <PrivateRoute path='/robottag/:tagId' exact component={RobotTagPage} />
        <PrivateRoute
          path='/robottag/:tagId/add-robots'
          exact
          component={AddRobotToTag}
        />
        <PrivateRoute
          path='/robottag/:tagId/add-teachers'
          exact
          component={AddTeacherToTag}
        />
        <Route path='/privacy-policy' component={PrivacyPolicy} />
        <Route path='/setup-wifi' component={WiFiSetup} />
        <Route path='/check-access' component={ABiiResource} />
        <Route path='/activate' component={ActivateAbii} />
        <Route path='/login' exact component={Login} />
        <Route path='/forgot-password' exact component={ForgotPassword} />
        <Route path='/forgot-username' exact component={ForgotUsername} />
        <Route path='/logged-out' exact component={LoggedOut} />
        <Route path='/wifi-logged-out' exact component={WiFiLoggedOut} />
      </Router>
    </section>
  );
};

export default compose(connect(mapStateToProps), withAPI)(App);
