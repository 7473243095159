import React from "react";
import {
  getDataColor,
  getHeaderClasses
} from "../../../ConsolidatedReportsUtilities";
import {
  findScoreForLesson, getGridTemplateColumns,
  getMinWidthOfRows
} from "../LearnerScoresTableUtilities";
import LearnerActions from "./LearnerActions";
const LearnerRow = ({
  index,
  filteredLessons,
  handleColumnHeaderClick,
  setSortedLearnerId,
  val,
  setSortedLearnerIdAsc,
  sortedLearnerIdAsc,
  sortedLearnerId,
  nameVals,
  learnersListWithScores,
  hasBorder,
  setSelectedSearchVal,
  setActiveTab,
  setViewLearnerInClass,
  setSortingStandardsAsc
}) => {
  return (
    <div
      className='reports_row'
      key={index}
      style={{
        backgroundColor: index % 2 === 0 ? "#F2F2F2" : "",
        gridTemplateColumns: getGridTemplateColumns(filteredLessons),
        minWidth: getMinWidthOfRows(filteredLessons),
      }}
    >
      <div
        className='data score name'
        onClick={() => {
          handleColumnHeaderClick(filteredLessons, "learnerAverage");
          setSortedLearnerId(val.sid);
          setSortedLearnerIdAsc(!sortedLearnerIdAsc);
          setSortingStandardsAsc(null);
        }}
      >
        <p
          className={`${getHeaderClasses(
            val.sid,
            sortedLearnerId,
            sortedLearnerIdAsc
          )}`}
        >{`${nameVals.first_name} ${nameVals.last_name}`}</p>
      </div>

      {/* loops through the lessons to be displayed and gets the learner
    scores for each. */}
      {filteredLessons.length !== 0 ? (
        filteredLessons.map((value, index1) => {
          let lessonScore = findScoreForLesson(
            learnersListWithScores[index].scores,
            filteredLessons[index1].label
          );
          // return an entry in the table for each lesson
          return (
            <div
              key={index1}
              className={`data score ${getDataColor(lessonScore)}`}
              style={{
                borderRight: hasBorder(index1, filteredLessons.length),
              }}
            >
              {lessonScore !== null ? lessonScore : "-"}
            </div>
          );
        })
      ) : (
        <div className='data score dash'>-</div>
      )}
      {filteredLessons.length <= 15 && filteredLessons.length !== 0 ? (
        <div className='score_hr'>
        </div>
      ) : (
        <div />
      )}
      {/* Last column of data: Learner average. If learner hasn't taken
    any lessons, it shows up as '-'. If it's a valid number, it shows
    and changes color according to the score. */}
      <div
        className={`data score learner_averages ${getDataColor(
          val.average
        )}`}
      >
        <p style={{fontWeight: "bold"}}>{val.average !== null ? val.average : "-"}</p>
      </div>

      <LearnerActions
        learner={val}
        sid={val.sid}
        nameVals={nameVals}
        setSelectedSearchVal={setSelectedSearchVal}
        setActiveTab={setActiveTab}
        setViewLearnerInClass={setViewLearnerInClass}
      />
    </div>
  );
};

export default LearnerRow;
