import React, { useState, useEffect } from "react";
//import { useCookies } from 'react-cookie';
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { authService } from "../services/auth";
import {
  logout,
  showPasswordReset as showPasswordResetAction,
  securityQPending as securityQPendingA,
} from "../redux/actions";
import LoginWithCleverButton from "../components/UtilComps/LoginWithCleverButton";
import LoginWithGoogleButton from "../components/UtilComps/LoginWithGoogleButton";
import config from "../common/config.js";
import { useTitle } from "../common/utils.js";
import FA from "../containers/fa";
import TeacherResetPWModal from "../components/TeacherDashboard/TeacherResetPWModal";
import DeleteSelfModal from "../components/TeacherDashboard/DeleteSelfModal";
import DisconnectLinkedAccountModal from "../components/TeacherDashboard/DisconnectLinkedAccountModal";
import withAPI from "../services/api";
import MainDashboard from "../partials/Main";
import { getUserSubscriptions } from "../common/helpers";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  orgSettings: state.status.userOrgSettings,
  showPasswordReset: state.gui.showPasswordReset,
});

const Settings = ({
  api,
  showPasswordReset,
  history,
  user,
  orgSettings,
  dispatch,
}) => {
  useTitle("Settings");

  const [showAnswer, setShowAnswer] = useState(false);
  const [answer, setAnswer] = useState("");
  const [questionID, setQuestionID] = useState("A");
  const [currentQID, setCurrentQID] = useState("A");
  const [questionChoices, setQuestionChoices] = useState([["A", "No Choices"]]);
  const [disableSave, setDisableSave] = useState(true);
  const [settingQuestion, setSettingQuestion] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [showDeleteSelf, setShowDeleteSelf] = useState(false);

  const showPWModalF = (e) => {
    dispatch(showPasswordResetAction(!showPasswordReset));
  };

  const SUBSCRIPTION_TYPE_INSTRUCTIONS = {
    lct: (
      <span>
        Lesson Creation Tool. You can use this account's email and password to
        login and create lessons at
        <a href='https://mylessons.abiis-world.com' target='_blank'>
          <strong> mylessons.abiis-world.com</strong>
        </a>
      </span>
    ),
  };

  const doLogout = (e) => {
    e.preventDefault();
    authService.logout().then(() => {
      dispatch(logout());
      history.push("/");
    });
  };

  const handleSecQuestionChange = (e) => {
    if (e.target.value === questionID) {
      return;
    }
    setQuestionID(e.target.value);
    setAnswer("");
    setDisableSave(true);
  };

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);
    if (e.target.value.length === 0 || e.target.value === "") {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  const saveSecurityQuestion = () => {
    setError(null);
    setSuccess(null);
    if (!questionID) {
      setError("Please choose a question.");
      return;
    }

    if (!answer || answer.length === 0 || answer === "") {
      setError("Answer cannot be blank.");
      return;
    }

    setShowAnswer(false);
    api
      .saveSecurityQuestion(user.user.username, questionID, answer)
      .then(() => {
        setSuccess("Security Question Saved!");
        setCurrentQID(questionID);
        // check to ensure that the question saved correctly.
        api.fetchAuthdQuestionForUser(user.user.username).then((question) => {
          // dispatch this info so other componets could use it
          console.log("UPDATING securityQPending from settings");
          dispatch(securityQPendingA(question.question === null));
          if (question.question === null) {
            console.log("null question");
            console.log(question.toString());
          } else {
            console.log("Question is not null");
            console.log(question);
          }
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    api.fetchAuthdQuestionForUser(user.user.username).then((question) => {
      setQuestionChoices(question.choices);
      setQuestionID(question.question || "A");
      setCurrentQID(question.question || "A");
    });
  }, []);

  const [userSubscriptions, setUserSubscriptions] = useState(null);

  useEffect(() => {
    const checkForUserSubscriptions = async () => {
      try {
        const subscriptions = await getUserSubscriptions({ api });
        setUserSubscriptions(subscriptions);
      } catch (error) {
        console.error("Error fetching user subscriptions:", error);
      }
    };

    checkForUserSubscriptions();
  }, []);

  const [connectedAccounts, setConnectedAccounts] = useState(null);
  const [showConnectedAccounts, setShowConnectedAccounts] = useState(false);
  const [showDisconnectAccountModal, setShowDisconnectAccountModal] =
    useState(false);
  const [refreshAcctList, setRefreshAcctList] = useState(false);

  useEffect(() => {
    api
      .fetchConnectedAccounts()
      .then((res) => {
        console.log("Connected Accounts: ", res);
        setConnectedAccounts(res);
      })
      .catch((err) => {
        console.log("Error fetching connnected accounts");
        console.log(err);
      });
  }, [refreshAcctList]);

  const renderProviderIcon = (provider) => {
    if (provider === "clever") {
      return (
        <div className='a'>
          <div id='clever_icon'>
            <p>C</p>
          </div>
        </div>
      );
    } else if (provider === "google") {
      return (
        <div id='google_icon'>
          <span className='icon'></span>
        </div>
      );
    }
  };

  const getAccountEmail = (provider, extraData) => {
    try {
      if (provider.includes("clever")) {
        return extraData.data.email;
      } else if (provider === "google") {
        return extraData.email;
      }
    } catch {
      return '';
    }
  };

  const renderConnectedAccounts = () => {
    if (user && user.has_sso_login) {
      return showConnectedAccounts ? (
        <div>
          {connectedAccounts.map((acct) => {
            var extra_data = acct.extra_data_json;
            console.log("extra_data parsed:", extra_data);
            return (
              <React.Fragment key={acct.id}>
                <div className='d-flex align-items-center'>
                  {renderProviderIcon(acct.provider)}
                  <div className='ml-2'>
                    &nbsp;
                    {`${acct.provider} - ${getAccountEmail(
                      acct.provider,
                      extra_data
                    )}`}
                    {user && !user.is_clever_synced &&
                      <>
                        {" - "}
                        <Link
                          to='#'
                          onClick={() => setShowDisconnectAccountModal(acct.id)}
                        >
                          {"Disconnect"}
                        </Link>
                      </>
                    }
                  </div>
                  {showDisconnectAccountModal === acct.id && (
                    <DisconnectLinkedAccountModal
                      linkedAccount={acct}
                      accountCount={connectedAccounts.length}
                      setShowSelf={setShowDisconnectAccountModal}
                      refreshAccountList={() => {
                        console.log("refreshAccountList firing...");
                        setRefreshAcctList(!refreshAcctList);
                      }}
                    />
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <button
          onClick={() => setShowConnectedAccounts(true)}
          className='btn btn-md btn-primary'
        >
          {"Show Linked Accounts"}
        </button>
      );
    } else {
      return null;
    }
  };

  const renderQuestion = () => {
    if (user && !user.can_login_with_password) return null;
    return settingQuestion ? (
      <div className='mt-2 form-content form-group'>
        <span>
          <h5>{"Setting Security Question:"}</h5>
        </span>
        <span>
          <label>Security Question:</label>
          {error && <div className='alert alert-danger'>{error}</div>}
          {success && <div className='alert alert-success'>{success}</div>}
          <select
            aria-label='Select Security Question'
            value={questionID || "none"}
            className='form-control input-lg'
            onChange={(e) => handleSecQuestionChange(e)}
          >
            <option value='none' disabled hidden>
              Select a Question
            </option>
            {questionChoices.map((question) => (
              <option key={question[0]} value={question[0]}>
                {question[1]}
              </option>
            ))}
          </select>
        </span>
        <span>
          <label>Security Answer:</label>
          <div className='form_icons icon_button icon_button_container'>
            <button
              disabled={!answer}
              className='btn btn-sm btn-primary'
              onClick={() => setShowAnswer(!showAnswer)}
              title='Show Answer'
              placement='top'
            >
              <FA color='white' icon='eye' />
            </button>

            {showAnswer ? (
              <input
                aria-label='Enter answer. Answer is not case sensitive.'
                name='aecurity-question-answer'
                type='text'
                className='form-control input-lg'
                value={answer || ""}
                onChange={(e) => {
                  handleAnswerChange(e);
                }}
              />
            ) : (
              <input
                aria-label='Enter answer. Answer is not case sensitive.'
                name='aecurity-question-answer'
                type='password'
                autoComplete='new-password'
                className='form-control input-lg'
                value={answer || ""}
                onChange={(e) => {
                  handleAnswerChange(e);
                }}
              />
            )}
          </div>
          <div>
            <p>
              <strong>Note:</strong> Security answer is <strong>not</strong>{" "}
              case sensitive.
            </p>
          </div>
        </span>
        <p>
          <button
            disabled={disableSave}
            onClick={() => saveSecurityQuestion()}
            className='btn btn-md btn-primary'
          >
            Save
          </button>
          <button
            className='btn btn-md btn-primary ml-2'
            onClick={() => {
              setQuestionID(currentQID);
              setAnswer("");
              setSettingQuestion(false);
            }}
          >
            Cancel
          </button>
        </p>
      </div>
    ) : (
      <React.Fragment>
        {questionID ? (
          <p>
            <button
              onClick={() => setSettingQuestion(true)}
              className='btn btn-md btn-primary'
            >
              {"Edit Security Question"}
            </button>
          </p>
        ) : (
          <p>
            <button
              onClick={() => setSettingQuestion(true)}
              className='btn btn-md btn-primary'
            >
              {"Create New Security Question"}
            </button>
          </p>
        )}
      </React.Fragment>
    );
  };

  return (
    <MainDashboard>
      <div className='common_border'>
        <div className='common_heading d-flex justify-content-between align-items-center'>
          <p>Settings</p>
        </div>
      </div>
      <div className='common_table_padding'>
        <div className='row batch-row'>
          <div className='progress-linear col-lg-6'>
            <h4>User Options</h4>
            {user && (
              <div>
                <p>
                  <label>Email:</label>{" "}
                  <input
                    className='form-control input-lg'
                    type='text'
                    disabled
                    value={user.user.email}
                  />
                </p>

                {userSubscriptions && userSubscriptions.length > 0 && (
                  <div>
                    <hr />
                    <h4>My Subscriptions</h4>
                    <ul>
                      {userSubscriptions.map((item) => (
                        <li>
                          <h5>
                            {config.SUBSCRIPTION_TYPE_DISPLAY_NAMES[item]}
                          </h5>
                          <p>{SUBSCRIPTION_TYPE_INSTRUCTIONS[item]}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}

            <hr />
            <h4>{"Account Security"}</h4>
            <div className='mt-2'>
              <button onClick={doLogout} className='btn btn-md btn-primary'>
                Logout
              </button>
            </div>
            {user && user.can_login_with_password && (
              <React.Fragment>
                <div className='mt-2'>
                  <div>
                    {showPasswordReset && (
                      <TeacherResetPWModal shouldShowFunc={showPWModalF} />
                    )}
                    {user && user.can_login_with_password && (
                      <button
                        className='btn btn-md btn-primary'
                        onClick={showPWModalF}
                      >
                        Reset Password
                      </button>
                    )}
                  </div>
                </div>
                <div className='mt-2'>{renderQuestion()}</div>
              </React.Fragment>
            )}

            {user &&
              ((orgSettings.allowed_sso_providers &&
                orgSettings.allowed_sso_providers.length > 0) ||
                user.usertype === "superadmin") && (
                <div>
                  <hr />
                  <div className='d-flex align-items-center justify-items-left'>
                    {connectedAccounts && connectedAccounts.length > 0 && (
                      <React.Fragment>
                        <div>
                          <h4>{"Linked accounts"}</h4>
                        </div>
                        {showConnectedAccounts && (
                          <div className='ml-2'>
                            <Link
                              to='#'
                              onClick={() => setShowConnectedAccounts(false)}
                            >
                              {"hide"}
                            </Link>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  {connectedAccounts && renderConnectedAccounts()}
                  <h4 className='mt-2'>{"Link accounts"}</h4>
                  <LoginWithCleverButton linkToExisting={true} />
                  {user.usertype === "superadmin" && (
                    <div className='mt-2'>
                      <LoginWithGoogleButton linkToExisting={true} />
                    </div>
                  )}
                </div>
              )}

            <hr />
            <div className='mt-5'>
              {showDeleteSelf && (
                <DeleteSelfModal setShowDeleteSelf={setShowDeleteSelf} />
              )}
              <button
                className='btn btn-md btn-danger'
                onClick={() => {
                  setShowDeleteSelf(true);
                }}
              >
                {"Permanently Delete My Account"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainDashboard>
  );
};

export default compose(withRouter, connect(mapStateToProps), withAPI)(Settings);
