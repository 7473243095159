import React from "react";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { allowedUsertypeOptions } from "./helpers";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const UserRoleSelect = ({ user, onChange, selected, header }) => {
  const [allOptions, setAllOptions] = useState([]);
  const [editRoleAllowed, setEditRoleAllowed] = useState(true);
  useEffect(() => {
    if (user) {
      setAllOptions(allowedUsertypeOptions(user.usertype));
      if (selected) {
        setEditRoleAllowed(
          allowedUsertypeOptions(user.usertype)
            .map((ut) => ut.value)
            .includes(selected.value)
        );
      }
    }
  }, [user, selected]);

  return (
    <>
      <span>{header}</span>
      <Select
        value={selected}
        options={allOptions}
        onChange={onChange}
        isDisabled={!editRoleAllowed}
        placeholder='User Role'
        aria-label='User Role'
      />
    </>
  );
};

export default compose(connect(mapStateToProps))(UserRoleSelect);
