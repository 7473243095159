import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { compose } from "redux";
import { securityQPending as securityQPendingA } from "../redux/actions";

import ForcePWResetModal from "../components/AppModals/ForcePWResetModal.js";
import NoAccountModal from "../components/AppModals/NoAccountModal.js";
import NoRobotModal from "../components/AppModals/NoRobotModal.js";
import SalesInfoDetailModal from "../components/SuperAdmin/Sales/SalesInfoDetailModal.js";
import Banner from "../components/UtilComps/Banner.jsx";
import withAPI from "../services/api";
import IdleAlertPlugIn from "./AutoLogout";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";

import config from "../common/config";
import "./Main.scss";

import { setRobotDataForBanners } from "../common/helpers";
import {
  getDateTimeStringPerGivenFormat,
  sortObjArrByDateTimeStringAsc,
} from "../common/utils";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  unsyncedLessons: state.status.unsyncedLessons,
  wifiConnected: state.status.wifiConnected,
  securityQPending: state.status.securityQPending,
  qsyncerSyncing: state.status.qsyncerSyncing,
  hasRobots: state.auth.hasRobots,
  hasAccounts: state.auth.hasAccounts,
  localBotsOnly: state.auth.localBotsOnly,
  forcePWReset: state.auth.forcePWReset,
  expiredRobots: state.robot.expiredRobots,
  expiringRobots: state.robot.expiringRobots,
  hasRenewalsWithoutRobots: state.robot.hasRenewalsWithoutRobots,
});

const MainDashboard = ({
  api,
  user,
  children,
  dispatch,
  securityQPending,
  unsyncedLessons,
  wifiConnected,
  qsyncerSyncing,
  hasRobots,
  hasAccounts,
  localBotsOnly,
  forcePWReset,
  expiredRobots,
  expiringRobots,
  hasRenewalsWithoutRobots,
}) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Don't show the security question alert on the settings page.
    if (
      window.location.pathname !== "/settings" &&
      user !== null &&
      (typeof securityQPending === "undefined" || securityQPending === null)
    ) {
      api.fetchAuthdQuestionForUser(user.user.username).then((question) => {
        console.log("UPDATING securityQPending");
        dispatch(securityQPendingA(question.question === null));
      });
    }
  }, [
    api,
    dispatch,
    qsyncerSyncing,
    securityQPending,
    unsyncedLessons,
    user,
    //user.user.username,
    wifiConnected,
  ]);

  useEffect(() => {
    const userType = user.usertype;
    const orgId = user.organization.id;
    if (user.usertype !== config.SUPERADMIN_USERTYPE) {
      console.log("TRIGGERING API CALL FOR ROBOT BANNERS");
      setRobotDataForBanners({ api, dispatch, userType, orgId });
    }
  }, [user]);

  // this will only trigger if we are already on a page with the target div.
  useEffect(() => {
    if (location.state?.scrollToSection) {
      const element = document.querySelector(location.state.scrollToSection);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location, history]);

  const noRobots = () => {
    // org admin or home user: org level
    // school teacher: user level
    return (
      (user &&
        user.usertype === config.TEACHER_USERTYPE &&
        user.organization.category === config.ABII_SCHOOL.toLowerCase() &&
        !hasRobots.user) ||
      (user &&
        user.organization.category === config.ABII_HOME.toLowerCase() &&
        !hasRobots.org) ||
      (user && user.usertype === config.ORGADMIN_USERTYPE && !hasRobots.org)
    );
  };

  const showNoAccountModal = () => {
    return (
      !localBotsOnly &&
      !noRobots() &&
      !hasAccounts &&
      user &&
      [config.ORGADMIN_USERTYPE, config.TEACHER_USERTYPE].includes(
        user.usertype
      ) &&
      sessionStorage.getItem("seenNoAccount") !== "yes" &&
      forceHideNoAccountModal === false
    );
  };

  const showNoRobotModal = () => {
    return (
      !localBotsOnly &&
      !user.user.username.endsWith(config.SUPERADMIN_AS_ORGADMIN_POSTFIX) &&
      noRobots() &&
      sessionStorage.getItem("seenNoRobot") !== "yes" &&
      forceHideNoRobotModal === false
    );
  };

  const [forceHideNoAccountModal, setForceHideNoAccountModal] = useState(false);
  const [forceHideNoRobotModal, setForceHideNoRobotModal] = useState(false);
  const closeNoAccountModal = () => {
    sessionStorage.setItem("seenNoAccount", "yes");
    setForceHideNoAccountModal(true);
  };

  const closeNoRobotModal = () => {
    sessionStorage.setItem("seenNoRobot", "yes");
    setForceHideNoRobotModal(true);
  };

  const [forceHideForcePWResetModal, setForceHideForcePWResetModal] =
    useState(false);
  const showForcePWResetModal = () => {
    return (
      forcePWReset &&
      sessionStorage.getItem("seenForcePWReset") !== "yes" &&
      forceHideForcePWResetModal === false
    );
  };
  const closeForcePWResetModal = () => {
    sessionStorage.setItem("seenForcePWReset", "yes");
    setForceHideForcePWResetModal(true);
  };

  if (!user) {
    document.location.href = "/";
  }

  const [showSyncReminder, setShowSyncReminder] = useState(false);

  const performSyncCheck = () => {
    console.log("Performing Sync Check");
    api
      .fetchIfSyncAvailable()
      .then((res) => {
        const now = new Date();
        sessionStorage.setItem("lastCheckedSync", now);
        console.log(res);
        setShowSyncReminder(res.sync_available);
      })
      .catch((e) => {
        console.log("[Error performSyncCheck]: ", e);
      });
  };

  useEffect(() => {
    // Perform a check only if lastChecked over 15 mins AND hideUntil is null or in the past
    const lastCheckedSync = sessionStorage.getItem("lastCheckedSync");
    const hideSyncReminderUntil = localStorage.getItem("hideSyncReminderUntil");
    const now = new Date();
    if (
      hideSyncReminderUntil === null ||
      now > Date.parse(hideSyncReminderUntil)
    ) {
      if (
        lastCheckedSync === null ||
        now - Date.parse(lastCheckedSync) >
          1000 * 60 * config.SYNC_CHECK_FREQUENCY
      ) {
        performSyncCheck();
      }
    }
  }, []);

  const handleHideSyncReminder = () => {
    const now = new Date();
    now.setDate(now.getDate() + 1);
    localStorage.setItem("hideSyncReminderUntil", now);
    setShowSyncReminder(false);
  };

  const [bannerAlertType, setBannerAlertType] = useState("");
  const [bannerMessage, setBannerMessage] = useState("");

  const createExpiredAndExpiringRobotsMessageObj = () => {
    let message = "";
    let alertType = "";

    if (hasRenewalsWithoutRobots) {
      message += "You have ";
      message += getRenewalsWithoutRobotsText();
      message += `In order to keep your robots up to date, they must be
      associated with an active subscription.`;
      alertType = "warning";
    } else if (expiredRobots && expiredRobots.length > 0) {
      setBannerMessage();
      message += "You have ";
      message += getExpiredRobotsText();
      alertType = "danger";

      if (expiringRobots && expiringRobots.length > 0) {
        message += " You also have ";
        message += getExpiringSubsText();
      }
    } else if (expiringRobots && expiringRobots.length > 0) {
      message += "You have ";
      message += getExpiringSubsText();
      alertType = "warning";
    }
    setBannerMessage(message);
    setBannerAlertType(alertType);
  };

  const getRenewalsWithoutRobotsText = () => {
    return "renewed subscriptions that have no robots attached! ";
  };

  const getExpiredRobotsText = () => {
    return "expired robots that can no longer receive updates!";
  };

  const getExpiringSubsText = () => {
    const robotsSortedByExpirationDate = sortObjArrByDateTimeStringAsc(
      expiringRobots,
      "end_date"
    );

    const formattedExpirationDate = getDateTimeStringPerGivenFormat(
      robotsSortedByExpirationDate[0].end_date,
      "M/D/YYYY"
    );

    return `subscriptions expiring as soon as ${formattedExpirationDate}. Robots
    without an active subscription will not be able to enjoy the latest
    software and lesson content updates.`;
  };

  const ExpiredAndExpiringRobotsBanner = () => {
    if (!expiredRobots || !expiringRobots) return null;
    createExpiredAndExpiringRobotsMessageObj();
    if (bannerMessage && user.usertype === config.TEACHER_USERTYPE) {
      return (
        <Banner
          alertType={bannerAlertType}
          message={`${bannerMessage} Please contact your organization's admin to let
          them know your robots need to be renewed.`}
        />
      );
    } else if (
      bannerMessage &&
      (user.usertype === config.ORGADMIN_USERTYPE ||
        user.usertype === config.ORGUNITADMIN_USERTYPE)
    ) {
      return (
        <Banner alertType={bannerAlertType} message={`${bannerMessage} `}>
          <Link
            to={{
              pathname: "/admin/my-org",
              state: { scrollToSection: "#org-subscriptions-table-contents" },
            }}
          >
            Click here to manage your organization's subscriptions.
          </Link>
        </Banner>
      );
    }
    return null;
  };

  return (
    <div>
      <DashboardHeader />
      <DashboardSidebar />
      <IdleAlertPlugIn />

      <section>
        {showNoRobotModal() && (
          <NoRobotModal
            category={user.organization.category}
            usertype={user.usertype}
            closeModal={closeNoRobotModal}
          />
        )}

        {showNoAccountModal() && (
          <NoAccountModal
            category={user.organization.category}
            usertype={user.usertype}
            closeModal={closeNoAccountModal}
          />
        )}

        {showForcePWResetModal() && (
          <ForcePWResetModal closeModal={closeForcePWResetModal} />
        )}

        {user.user.username.endsWith(config.SUPERADMIN_AS_ORGADMIN_POSTFIX) && (
          <Banner
            message={`You are inspecting an Org Admin's view as a secret user.
            Please treat this view as read-only.`}
            alertType='danger'
          />
        )}

        {showSyncReminder && (
          <nav className='alert-warning'>
            <ol className='breadcrumb custom_breadcrumb alert-warning'>
              <li className='breadcrumb-item alert-warning'>
                Once you're done managing your classes and learners, reboot or
                initiate a sync from your robots to have the latest changes
                reflected.
                <br />
                <button
                  style={{ border: "none", backgroundColor: "transparent" }}
                  className='like-link p-0'
                  onClick={() => {
                    setShowSyncReminder(false);
                  }}
                >
                  Got it
                </button>
                <button
                  style={{ border: "none", backgroundColor: "transparent" }}
                  className='ml-3 like-link p-0'
                  onClick={handleHideSyncReminder}
                >
                  Hide for a day
                </button>
              </li>
            </ol>
          </nav>
        )}

        {securityQPending &&
          user.can_login_with_password &&
          window.location.pathname !== "/settings" && (
            <Banner
              alertType='warning'
              message={`Setup your security question in case you ever forget
              your password. `}
            >
              <Link to='/settings'>
                Head to the settings page to set it up.
              </Link>
            </Banner>
          )}

        <ExpiredAndExpiringRobotsBanner />

        <div className='common_padding_space' style={{ minHeight: "80vh" }}>
          {children}
        </div>
        <SalesInfoDetailModal />
      </section>
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(MainDashboard);
