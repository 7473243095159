import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OAuthService, OAuthProviders } from '../services/oauth';

import withAPI from '../services/api';
import FA from '../containers/fa';

import Gate from '../partials/Gate';
import './Welcome.css';

const CleverCallback = ({ api, history, location, dispatch }) => {

  function jsonToURI(json) {
    return encodeURIComponent(JSON.stringify(json));
  }
  function uriToJSON(urijson) {
    return JSON.parse(decodeURIComponent(urijson));
  }

  useEffect(() => {
    // instantiate OAuthService provider instance
    const provider = new OAuthService(OAuthProviders.clever);
    console.log("provider REDIRECT_URL:",provider.makeAuthUrl({}));

    // Try and pass the token back to the opener
    var code = (location.search.match(/code=([^&]+)/) || [])[1];
    console.log("Found code:",code);
    var state = (location.search.match(/state=([^&]+)/) || [])[1];
    console.log("Found state:",state);
    if (state) {
      //var decodedState = JSON.parse(atob(state));
      var decodedState = uriToJSON(state);
      console.log("Decoded state:",decodedState);
    };
    var referrer = document.referrer;
    console.log("referrer url:",referrer);

    console.log("crossOriginIsolated:",window.crossOriginIsolated);

    if (code) {
        provider.processToken(code)
    } else {
      console.log("No code found. Sad...");
    }
  }, []);


  return (
    <Gate boxWidthClass="col-md-6">
      <div className="login_box form-content container form-group">
        <div className="row">
          <div className="col">
            <h1 style={{"textAlign": "center"}}>{"Logging in..."}</h1>
          </div>
        </div>
      </div>
    </Gate>
  );
}

export default compose(
  withAPI
)(CleverCallback);
