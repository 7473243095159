import { Switch, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import FA from "../../containers/fa";
import propTypes from "prop-types";

const AccommodationInfoTab = ({
  setInfoText,
  setEditLearner,
  editLearner,
  api,
  refreshLearners,
}) => {
  const [enableReadingPassageAudio, setEnableReadingPassageAudio] =
    useState(false);

  const saveEnableReadingPassageAudio = (val) => {
    const data = {
      accommodation_info: {
        enable_reading_passage_audio: val,
      },
    };
    api.patchLearnerInfo(editLearner.id, data).then((updatedInfo) => {
      setEditLearner(updatedInfo);
      refreshLearners();
    });
  };

  useEffect(() => {
    setEnableReadingPassageAudio(
      editLearner.accommodation_info?.enable_reading_passage_audio ?? false
    );
  }, [editLearner]);

  return (
    <>
      <div className='mb-2 d-flex justify-content-between'>
        <h4>Accommodations Settings</h4>
      </div>
      <table className='table table-striped table-sm'>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "middle" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography>Enable Reading Passage Audio</Typography>
                <span
                  style={{ marginLeft: "5px" }}
                  className='info-circle'
                  onClick={() => {
                    setInfoText({
                      header: "Enable Reading Passage Audio",
                      text: `This accommodation allows the student to press a button to have ABii read longer passages
                          aloud to them. This button will appear in Reading lessons in grades 2-5 in lessons that contain
                          longer passages.`,
                    });
                  }}
                >
                  <FA name icon='info-circle' />
                </span>
              </div>
            </td>
            <td>
              <Switch
                color={"primary"}
                checked={enableReadingPassageAudio}
                onChange={() => {
                  saveEnableReadingPassageAudio(!enableReadingPassageAudio);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        className='btn btn-md btn-warning float-right'
        style={{ marginTop: "150px" }}
        onClick={() => {
          setEditLearner(null);
        }}
      >
        Close
      </button>
    </>
  );
};

propTypes.AccommodationInfoTab = {
  setInfoText: propTypes.func,
  setEditLearner: propTypes.func,
  editLearner: propTypes.object,
  api: propTypes.object,
  refreshLearners: propTypes.func,
};

export default AccommodationInfoTab;
