import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import FA from "../../../containers/fa";
import withAPI from "../../../services/api";

const mapStateToProps = (state, ownProps?) => ({
  refreshSaleDetail: state.superAdmin.refreshSaleDetail,
});

const SalesInfoMoreTab = ({ dispatch, api, saleObj }) => {
  const [editITForm, setEditITForm] = useState(false);
  const [ITForm, setITForm] = useState({});
  const [newITForm, setNewITForm] = useState({});
  const [spin, setSpin] = useState(false);
  const [error, setError] = useState("");

  const fetchLatestITForm = () => {
    if (saleObj.id) {
      setSpin(true);
      api
        .fetchITSetup(saleObj.id)
        .then((res) => {
          console.log(res.data);
          setITForm(res.data);
          setNewITForm(res.data);
          setSpin(false);
        })
        .catch((error) => {
          console.log("Error fetching prices.");
          console.log(error);
          setError(error);
          setSpin(false);
        });
    }
  };

  useEffect(() => {
    fetchLatestITForm();
  }, [saleObj]);

  const resetITForm = () => {
    let newITFormTmp = { ...ITForm };
    setNewITForm(newITFormTmp);
  };

  const updateITform = (e, field) => {
    let newITFormTmp = { ...newITForm };
    if (field === "it_dept_setup" || field === "it_dept_contacted") {
      newITFormTmp[field] = e.target.checked;
    } else {
      newITFormTmp[field] = e.target.value;
    }
    setNewITForm(newITFormTmp);
  };

  const renderEditableCell = (field) => {
    if (editITForm) {
      return (
        <td className='item-value'>
          <TextField
            size='small'
            type='text'
            value={newITForm[field] || ""}
            onChange={(e) => {
              updateITform(e, field);
            }}
          />
        </td>
      );
    } else {
      return <td className='item-value'>{newITForm[field] || ""}</td>;
    }
  };

  const renderNoteCell = (field) => {
    if (editITForm) {
      return (
        <td className='item-value' colSpan='3'>
          <TextField
            style={{ width: "100%" }}
            size='small'
            multiline
            minRows={2}
            maxRows={5}
            type='text'
            value={newITForm[field] || ""}
            onChange={(e) => {
              updateITform(e, field);
            }}
          />
        </td>
      );
    } else {
      return (
        <td className='item-value' colSpan='3'>
          {newITForm[field] || ""}
        </td>
      );
    }
  };

  const renderCheckboxCell = (field) => {
    if (editITForm) {
      return (
        <td>
          <input
            type='checkbox'
            checked={newITForm[field] || false}
            onChange={(e) => {
              updateITform(e, field);
            }}
            disabled={editITForm === false}
          />
        </td>
      );
    } else {
      if (newITForm[field] === true) {
        return (
          <td>
            <FA color='green' icon='check-circle' /> Yes{" "}
          </td>
        );
      } else {
        return (
          <td>
            <FA color='#ffc107' icon='exclamation-circle' /> No{" "}
          </td>
        );
      }
    }
  };

  const handleSaveChanges = () => {
    const postData = { ...newITForm };
    postData["sale_id"] = saleObj.id;
    setSpin(true);
    api
      .patchITSetup(postData)
      .then((res) => {
        setEditITForm(false);
        fetchLatestITForm();
      })
      .catch((error) => {
        console.log("Error fetching prices.");
        console.log(error);
        setError(error);
        setSpin(false);
      });
  };

  if (error) {
    return (
      <div className='alert alert-danger' role='alert'>
        {error}
      </div>
    );
  }

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div className='mb-2 d-flex justify-content-between'>
            <h4>IT Department</h4>
            {editITForm ? (
              <div>
                <button
                  className='btn btn-sm btn-primary'
                  disabled={spin}
                  onClick={() => {
                    handleSaveChanges();
                  }}
                >
                  {spin ? (
                    <FA icon='spinner' color='white' spin />
                  ) : (
                    <FA icon='save' color='white' />
                  )}{" "}
                  Save Changes
                </button>
                <button
                  className='btn btn-sm btn-danger ml-2'
                  onClick={() => {
                    setEditITForm(false);
                    resetITForm();
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button
                className='btn btn-sm btn-primary'
                onClick={() => {
                  setEditITForm(true);
                }}
              >
                Edit
              </button>
            )}
          </div>
          <table className='table table-striped table-sm'>
            <tbody className='sales_detail_table_tbody'>
              <tr>
                <td className='item-label'>Contact Name</td>
                {renderEditableCell("contact_name")}
                <td className='item-label'>Phone</td>
                {renderEditableCell("phone")}
              </tr>
              <tr>
                <td className='item-label'>Email</td>
                {renderEditableCell("email")}
              </tr>
              <tr>
                <td className='item-label'>Contacted?</td>
                {renderCheckboxCell("it_dept_contacted")}
                <td className='item-label'>Fully setup?</td>
                {renderCheckboxCell("it_dept_setup")}
              </tr>
              <tr>
                <td className='item-label'>Notes</td>
                {renderNoteCell("it_dept_setup_notes")}
              </tr>
              <tr>
                <td className='item-label'>Calendar</td>
                <td className='item-value' colSpan='3'>
                  <a
                    target='_blank'
                    href='https://calendar.google.com/'
                    rel='noreferrer'
                  >
                    Link
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(SalesInfoMoreTab);
