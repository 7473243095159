import React, {useEffect, useState, useRef} from "react";
import {connect, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {compose} from "redux";
import sidebarMenuPNG from "../assets/images/sidebar-menu.png";
import config from "../common/config";
import FA from "../containers/fa";
import {
  showSidebar as showSidebarA,
  lessonViewerWindowRef as lessonViewerWindowRefA,
} from "../redux/actions";
import {
  launchLessonViewer, openRequestedTab, lessonViewerMainPageUrl,
} from "../services/http";
import "./DashboardSidebar.scss";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  showSidebar: state.gui.showSidebar,
  lessonViewerWindowRef: state.gui.lessonViewerWindowRef,
  robotType: state.status.robotType,
  hasRobots: state.auth.hasRobots,
  localBotsOnly: state.auth.localBotsOnly,
});

const DashboardSidebar = ({
  user,
  showSidebar,
  lessonViewerWindowRef,
  hasRobots,
  localBotsOnly,
  dispatch,
}) => {
  const classNames = () => {
    let names = ["sidebar"];
    if (showSidebar) names.push("showSidebar");
    return names.join(" ");
  };

  const lessonViewerTab = useSelector((state) => state.gui.lessonViewerWindowRef)
  console.log("on load lessonViewerTab: ",lessonViewerTab);
  //const [windowRef, setWindowRef] = useState(null);
  const sidebarRef = useRef(null);

  const closeSidebar = (e) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(e.target) &&
      !document
        .getElementById("navbar-hamburger-mobile-toggle")
        .contains(e.target)
    ) {
      dispatch(showSidebarA(false));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeSidebar);
    return () => {
      document.removeEventListener("mousedown", closeSidebar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <nav ref={sidebarRef} className={classNames()}>
        <div className='list-group'>
          {/* SCHOOL OrgAdmins/OrgUnitAdmins */}
          {/* School Org All Robots */}
          {user &&
            (
              user.usertype === config.ORGADMIN_USERTYPE |
              user.usertype === config.ORGUNITADMIN_USERTYPE
            ) &&
            user.organization.category !== config.ABII_HOME.toLowerCase() && (
              <React.Fragment>
                <h5 style={{color: "white"}} className='pl-2 mt-3 '>
                  {" "}
                  Admin Pages{" "}
                </h5>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/admin/dashboard'
                  className='list-group-item'
                >
                  <FA icon='chart-bar' color='white' />
                  &nbsp; Dashboard
                </Link>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/robots'
                  className='list-group-item'
                >
                  <FA icon='robot' color='white' />
                  &nbsp; Robots
                </Link>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/admin/manage-teachers'
                  className='list-group-item'
                >
                  <FA icon='chalkboard-teacher' color='white' />
                  &nbsp; Users
                </Link>
                {localBotsOnly !== true && (
                  <Link
                    onClick={() => dispatch(showSidebarA(false))}
                    to='/user/all-learners'
                    className='list-group-item'
                  >
                    <FA icon='user-graduate' color='white' />
                    &nbsp; All Learners
                  </Link>
                )}
                <button
                  onClick={() => {
                    console.log("Running onClick with viewerWindow from state: ",lessonViewerWindowRef);
                    console.log("Running onClick with useSelect ref: ",lessonViewerTab);
                    dispatch(showSidebarA(false));
                    const tabRef = openRequestedTab({
                        url: lessonViewerMainPageUrl(),
                        windowRef: lessonViewerWindowRef,
                        windowName: config.LESSONVIEWER_WINDOW_NAME,
                    })
                    console.log("Got tabRef:",tabRef);
                    dispatch(lessonViewerWindowRefA(tabRef));
                    console.log("tabRef.closed: ",tabRef.closed);
                    setTimeout(() => {
                      console.log("after 5 secs: tabRef.closed: ",tabRef.closed);
                      //tabRef.close();
                    }, 5000);
                  }}
                  className='list-group-item'
                >
                  <FA icon='external-link-alt' color='white' />
                  &nbsp; Preview Lessons
                </button>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/admin/my-org'
                  className='list-group-item'
                >
                  <FA icon='school' color='white' />
                  &nbsp; My Organization
                </Link>
              </React.Fragment>
            )}

          {/* School Orgadmin/OrgUnitAdmin as Teacher Pages */}
          {user &&
            (
              user.usertype === config.ORGADMIN_USERTYPE |
              user.usertype === config.ORGUNITADMIN_USERTYPE
            ) &&
            hasRobots.user &&
            user.organization.category !== config.ABII_HOME.toLowerCase() && (
              <React.Fragment>
                <h5 style={{color: "white"}} className='pl-2 mt-3 '>
                  {" "}
                  Teacher Pages{" "}
                </h5>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/admin/my-robots'
                  className='list-group-item'
                >
                  <FA icon='robot' color='white' />
                  &nbsp; My Robots
                </Link>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/admin/my-classes'
                  className='list-group-item'
                >
                  <FA icon='chalkboard' color='white' />
                  &nbsp; My Classes
                </Link>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/user/reports'
                  className='list-group-item'
                >
                  <FA icon='chart-line' color='white' />
                  &nbsp; Reports
                </Link>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/teacher/assignments'
                  className='list-group-item mb-3'
                >
                  <FA icon='tasks' color='white' />
                  &nbsp; Assignments
                </Link>
              </React.Fragment>
            )}

          {/* HOME Org Dashboard and MyRobot */}
          {user &&
            user.organization.category === config.ABII_HOME.toLowerCase() &&
            user.usertype !== config.SUPERADMIN_USERTYPE && (
              <Link
                onClick={() => dispatch(showSidebarA(false))}
                to='/home/dashboard'
                className='list-group-item'
              >
                <FA icon='tasks' color='white' />
                &nbsp; Dashboard
              </Link>
            )}
          {user &&
            user.organization.category === config.ABII_HOME.toLowerCase() &&
            user.usertype !== config.SUPERADMIN_USERTYPE && (
              <Link
                onClick={() => dispatch(showSidebarA(false))}
                to='/home/my-robot'
                className='list-group-item'
              >
                <FA icon='robot' color='white' />
                &nbsp; My Robot
              </Link>
            )}
          {/* SCHOOL Teachers */}
          {user &&
            user.usertype === config.TEACHER_USERTYPE &&
            user.organization.category !== config.ABII_HOME.toLowerCase() && (
              <Link
                onClick={() => dispatch(showSidebarA(false))}
                to='/teacher/dashboard'
                className='list-group-item'
              >
                <FA icon='chalkboard' color='white' />
                &nbsp; Classes
              </Link>
            )}
          {/* School Org TEACHER All Robots */}
          {user &&
            user.usertype === config.TEACHER_USERTYPE &&
            user.organization.category !== config.ABII_HOME.toLowerCase() && (
              <React.Fragment>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/robots'
                  className='list-group-item'
                >
                  <FA icon='robot' color='white' />
                  &nbsp; Robots
                </Link>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/user/reports'
                  className='list-group-item'
                >
                  <FA icon='chart-line' color='white' />
                  &nbsp; Reports
                </Link>
                <Link
                  onClick={() => dispatch(showSidebarA(false))}
                  to='/teacher/assignments'
                  className='list-group-item mb-3'
                >
                  <FA icon='tasks' color='white' />
                  &nbsp; Assignments
                </Link>
              </React.Fragment>
            )}

          {/* Super Admin */}
          {user && user.usertype === config.SUPERADMIN_USERTYPE && (
            <React.Fragment>
              <Link
                onClick={() => dispatch(showSidebarA(false))}
                to='/super/dashboard'
                className='list-group-item'
              >
                <FA icon='chart-bar' color='white' />
                &nbsp; Usage Stats
              </Link>

              <Link
                onClick={() => dispatch(showSidebarA(false))}
                to='/super/orgs-list'
                className='list-group-item'
              >
                <FA icon='tasks' color='white' />
                &nbsp; Organizations
              </Link>

              <Link
                onClick={() => dispatch(showSidebarA(false))}
                to='/robots'
                className='list-group-item'
              >
                <FA icon='robot' color='white' />
                &nbsp; Robots
              </Link>

              <Link
                onClick={() => dispatch(showSidebarA(false))}
                to='/super/sales'
                className='list-group-item'
              >
                <FA icon='file-invoice-dollar' color='white' />
                &nbsp; Sales
              </Link>

              <Link
                onClick={() => dispatch(showSidebarA(false))}
                to='/super/subscriptions'
                className='list-group-item'
              >
                <FA icon='calendar-alt' color='white' />
                &nbsp; Subscriptions
              </Link>
              <button
                onClick={() => {
                  dispatch(showSidebarA(false));
                  const tabRef = openRequestedTab({
                      url: lessonViewerMainPageUrl(),
                      windowRef: lessonViewerWindowRef,
                      windowName: config.LESSONVIEWER_WINDOW_NAME,
                  })
                  dispatch(lessonViewerWindowRefA(tabRef));
                }}
                className='list-group-item'
              >
                <FA icon='external-link-alt' color='white' />
                &nbsp; Preview Lessons
              </button>
            </React.Fragment>
          )}

          {/* All users */}
          {user && (
            <Link
              onClick={() => dispatch(showSidebarA(false))}
              to='/settings'
              className='list-group-item'
            >
              <FA icon='cog' color='white' />
              &nbsp; Settings
            </Link>
          )}
        </div>

        <div className='bottom_logo'>
          <img alt='bottom logo' src={sidebarMenuPNG} />
        </div>
      </nav>
    </div>
  );
};

export default compose(connect(mapStateToProps))(DashboardSidebar);
