import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { compose } from "redux";
import config from "../../common/config";
import { setRobotDataForBanners } from "../../common/helpers";
import {
  getDateTimeStringPerGivenFormat,
  sortObjArrByDateTimeStringAsc,
} from "../../common/utils";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import SubscriptionRow from "./SubscriptionRow";
import SubscriptionSelectionModal from "./SubscriptionSelectionModal";
import "./SubscriptionStyles.scss";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  refreshSubscriptionItems: state.superAdmin.refreshSubscriptionItems,
  expiredRobots: state.robot.expiredRobots,
  expiringRobots: state.robot.expiringRobots,
  hasRenewalsWithoutRobots: state.robot.hasRenewalsWithoutRobots,
});

// This is available to Super Admins and Org Admins
const OrgSubscriptionsTable = ({
  api,
  orgId,
  saleId,
  user,
  fetchAll,
  refreshSubscriptionItems,
  canCollapse,
  saleIsFinalizedState,
  expiredRobots,
  expiringRobots,
  hasRenewalsWithoutRobots,
  setSubscriptionsSectionLoaded,
  allSectionsLoaded,
  dispatch,
  hideExpirationSummarySection = false,
}) => {
  // Fetch subscriptions either by org or sale
  const subscriptionLabelMap = config.SUBSCRIPTION_TYPE_DISPLAY_NAMES;
  const [lctSubscriptions, setLctSubscriptions] = useState([]);
  const [premiumCareSubscriptions, setPremiumCareSubscriptions] = useState([]);
  const [abiiContentPackageSubscriptions, setAbiiContentPackageSubscriptions] =
    useState([]);
  const [subscriptionStats, setSubscriptionStats] = useState({});
  const [showSubscriptionRenewal, setShowSubscriptionRenewal] = useState(false);
  const [showSAUpdateReminder, setShowSAUpdateReminder] = useState(false);
  const [spin, setSpin] = useState(true);
  const [error, setError] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const [scrolledToSubscriptions, setScrolledToSubscriptions] = useState(false);
  const tableHeaderRef = useRef(null);
  const location = useLocation();

  const getLinkMap = (subscriptionType) => {
    if (
      user.usertype === config.ORGADMIN_USERTYPE ||
      user.usertype === config.ORGUNITADMIN_USERTYPE
    ) {
      return `/admin/subscription/${subscriptionType}`;
    } else if (user.usertype === config.SUPERADMIN_USERTYPE) {
      return `/super/subscription/${orgId}/${subscriptionType}`;
    }
    return "";
  };

  useEffect(() => {
    refreshSubscriptionList();
  }, [refreshSubscriptionItems]);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    if (user && user.usertype === config.SUPERADMIN_USERTYPE) {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(false);
    }
  }, [user]);

  // TODO: since we already call this in Main.js we might be to just use the state in the redux store instead of calling this again
  useEffect(() => {
    const userType = user.usertype;
    setRobotDataForBanners({ api, dispatch, userType, orgId });
  }, [user, orgId]);

  useEffect(() => {
    if (
      !scrolledToSubscriptions &&
      location.state &&
      location.state.scrollToSection &&
      tableHeaderRef.current &&
      allSectionsLoaded
    ) {
      tableHeaderRef.current.scrollIntoView({ behavior: "smooth" });
      setScrolledToSubscriptions(true);
    }
  }, [tableHeaderRef.current, location]);

  const refreshSubscriptionList = () => {
    let queryParams = {};
    if (!fetchAll) {
      if (saleId) {
        queryParams.saleId = saleId;
      } else {
        queryParams.orgId = orgId;
      }
    }

    // skip nulls,  empty strings, false values
    queryParams = Object.entries(queryParams).filter(([k, v]) => v);
    console.log("Asking for Subscriptions in Sale or Org:", "..");
    api
      .fetchSubscriptions(queryParams)
      .then((res) => {
        const subscriptions = res.results;
        const subsAfterRemovingProcessedRenewals = subscriptions.filter(
          (sub) => sub.processed_renewal == null
        );

        const lctSubs = subsAfterRemovingProcessedRenewals.filter(
          (item) => item.subscription_type === config.LCT_SUB_TYPE
        );
        setLctSubscriptions(lctSubs);

        const premiumcareSubs = subsAfterRemovingProcessedRenewals.filter(
          (item) => item.subscription_type === config.PREMIUMCARE_SUB_TYPE
        );
        setPremiumCareSubscriptions(premiumcareSubs);

        const abiiContentPackageSubs =
          subsAfterRemovingProcessedRenewals.filter(
            (item) =>
              item.subscription_type === config.ABII_CONTENT_PACKAGE_SUB_TYPE
          );
        setAbiiContentPackageSubscriptions(abiiContentPackageSubs);

        setSubscriptionStats(res.stats);
        setSpin(false);
        if (setSubscriptionsSectionLoaded) {
          setSubscriptionsSectionLoaded(true);
        }
      })
      .catch((error) => {
        setError(error);
        console.log(error);
        setSpin(false);
      });
  };

  const getRobotBannerJSX = () => {
    let jsx = null;
    if (hasRenewalsWithoutRobots) {
      jsx = (
        <div className='alert alert-warning'>
          <FA icon='exclamation-circle' color='#ffc107' />{" "}
          {isSuperAdmin ? "This" : "Your"} organization has subscriptions that
          have been renewed but have no robots attached. Please click on the
          button next to the alert icon in the <b>Action</b> column in the table
          below to add robots to your renewed subscriptions.
        </div>
      );
    } else if (
      (expiredRobots && expiredRobots.length > 0) ||
      (expiringRobots && expiringRobots.length > 0)
    ) {
      jsx = (
        <div className={`alert alert-${getBannerType()}`}>
          {getIconForSummary()}
          {createRobotSummaryMsgJSX()}
        </div>
      );
    }
    return jsx;
  };

  const createRobotSummaryMsgJSX = () => {
    let summaryText = `${isSuperAdmin ? " This" : " Your"} organization has `;

    if (expiredRobots && expiredRobots.length > 0) {
      summaryText += getExpiredSubsText();
    }
    if (expiringRobots && expiringRobots.length > 0) {
      summaryText += getExpiringSubsText();
    }

    const emailLink =
      '<a href="mailto:sales@myvanrobot.com">sales@myvanrobot.com</a>';

    if (user.usertype === "orgadmin") {
      summaryText += ` Please contact ${emailLink} as soon as possible to renew your subscriptions!`;
    }

    return <span dangerouslySetInnerHTML={{ __html: summaryText }} />;
  };

  const getExpiredSubsText = () => {
    return `subscriptions covering ${expiredRobots.length} robot(s) that are expired.`;
  };

  const getExpiringSubsText = () => {
    let text = "";

    const robotsSortedByExpirationDate = sortObjArrByDateTimeStringAsc(
      expiringRobots,
      "end_date"
    );

    if (expiredRobots && expiredRobots.length > 0) {
      text = " You also have ";
    }

    const formattedExpirationDate = getDateTimeStringPerGivenFormat(
      robotsSortedByExpirationDate[0].end_date,
      "M/D/YYYY"
    );

    text += `subscriptions covering ${expiringRobots.length} robot(s) that are expiring as soon as ${formattedExpirationDate}.`;
    return text;
  };

  const getIconForSummary = () => {
    let icon = null;
    if (expiredRobots && expiredRobots.length > 0) {
      icon = <FA icon='exclamation-circle' color='red' />;
    } else if (expiringRobots && expiringRobots.length > 0) {
      icon = <FA icon='exclamation-circle' color='#ffc107' />;
    }
    return icon;
  };

  const getBannerType = () => {
    let bannerType = "";
    if (expiredRobots && expiredRobots.length > 0) {
      bannerType = "danger";
    } else {
      bannerType = "warning";
    }
    return bannerType;
  };

  const overallPremiumCareUsage = subscriptionStats[config.PREMIUMCARE_SUB_TYPE]
    ? subscriptionStats[config.PREMIUMCARE_SUB_TYPE].used
    : 0;
  const overallAcpUsage = subscriptionStats[
    config.ABII_CONTENT_PACKAGE_SUB_TYPE
  ]
    ? subscriptionStats[config.ABII_CONTENT_PACKAGE_SUB_TYPE].used
    : 0;

  return (
    <div className='common_border'>
      <div
        className='common_heading d-flex justify-content-between align-items-center'
        style={{ cursor: canCollapse ? "pointer" : "" }}
        onClick={() => {
          if (canCollapse) setCollapse(!collapse);
        }}
      >
        <span>
          <h4 id='org-subscriptions-table-header'>
            {canCollapse && (
              <span className='mr-2'>
                {collapse ? (
                  <FA color='gray' icon='chevron-right' />
                ) : (
                  <FA color='gray' icon='chevron-down' />
                )}
              </span>
            )}
            Subscriptions
          </h4>
        </span>
        {isSuperAdmin === true && fetchAll && (
          <button
            className='btn btn-primary'
            onClick={(e) => {
              e.stopPropagation();
              api.exportSubscriptions();
            }}
          >
            Export Subscriptions
          </button>
        )}
        {isSuperAdmin === true && orgId && !saleId && (
          <button
            className='btn btn-primary'
            onClick={(e) => {
              e.stopPropagation();
              setShowSubscriptionRenewal(true);
            }}
          >
            Renew Subscriptions
          </button>
        )}
      </div>

      {showSubscriptionRenewal && (
        <SubscriptionSelectionModal
          setShowModal={setShowSubscriptionRenewal}
          orgId={orgId}
        />
      )}

      {!collapse && (
        <div className='common_dashboard_bg'>
          <div className='row'>
            <div className='col-12'>
              <div className='row mb-10'></div>
              {error && (
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              )}
              {spin && (
                <div>
                  <h4>
                    <FA color='gray' icon='spinner' spin /> Loading
                    Subscriptions...
                  </h4>
                </div>
              )}

              {!spin && !error && (
                <div id='org-subscriptions-table-contents' ref={tableHeaderRef}>
                  {hideExpirationSummarySection === false && (
                    <>{getRobotBannerJSX()}</>
                  )}

                  <div id='premiumcare_subscriptions'>
                    <div>
                      <h4 className='subscription-type'>ABii Subscriptions</h4>

                      {premiumCareSubscriptions.length > 0 ? (
                        <span>
                          {subscriptionStats[config.PREMIUMCARE_SUB_TYPE] && (
                            <p>
                              <span className='font-weight-bold'>
                                Overall Premium Care Usage:
                              </span>
                              {subscriptionStats[config.PREMIUMCARE_SUB_TYPE]
                                .limit > 0 ? (
                                <>
                                  <span className='mx-2 font-weight-bold'>
                                    {overallPremiumCareUsage <= 0
                                      ? 0
                                      : overallPremiumCareUsage}
                                  </span>
                                  out of
                                  <span className='mx-2 font-weight-bold'>
                                    {
                                      subscriptionStats[
                                        config.PREMIUMCARE_SUB_TYPE
                                      ].limit
                                    }
                                  </span>
                                </>
                              ) : (
                                <span className='mx-2 font-weight-bold'>
                                  {"0"}
                                </span>
                              )}
                              total slots from active subscriptions.
                            </p>
                          )}
                        </span>
                      ) : (
                        <p>
                          No {subscriptionLabelMap[config.PREMIUMCARE_SUB_TYPE]}{" "}
                          subscriptions available.
                        </p>
                      )}
                    </div>

                    <div id='lct_subscriptions' className='mb-2'>
                      <div>
                        {lctSubscriptions.length > 0 ? (
                          <div>
                            {subscriptionStats[config.LCT_SUB_TYPE] && (
                              <p>
                                <span className='font-weight-bold'>
                                  Overall LCT Usage:
                                </span>
                                {subscriptionStats[config.LCT_SUB_TYPE].limit >
                                0 ? (
                                  <>
                                    <span className='mx-2 font-weight-bold'>
                                      {
                                        subscriptionStats[config.LCT_SUB_TYPE]
                                          .used
                                      }
                                    </span>
                                    out of
                                    <span className='mx-2 font-weight-bold'>
                                      {
                                        subscriptionStats[config.LCT_SUB_TYPE]
                                          .limit
                                      }
                                    </span>
                                  </>
                                ) : (
                                  <span className='mx-2 font-weight-bold'>
                                    {"0"}
                                  </span>
                                )}
                                total slots from active subscriptions.
                                <Link
                                  className='btn btn-sm btn-primary m-1'
                                  to={getLinkMap(config.LCT_SUB_TYPE)}
                                >
                                  View/Manage Users...
                                </Link>
                              </p>
                            )}
                          </div>
                        ) : (
                          <p>
                            No {subscriptionLabelMap[config.LCT_SUB_TYPE]}{" "}
                            subscriptions available.
                          </p>
                        )}
                      </div>
                    </div>

                    {premiumCareSubscriptions.length > 0 && (
                      <div className='subscription-type-container'>
                        <div>
                          <div
                            className={`${
                              isSuperAdmin
                                ? "super_admin_subscription_row_hr"
                                : "subscription_row_hr"
                            }`}
                          >
                            {isSuperAdmin && <div>Sale Id</div>}
                            <div>Name</div>
                            <div>Status</div>
                            <div>Expiration Date</div>
                            <div>Total Slots</div>
                            <div>Used</div>
                            <div>Action</div>
                          </div>
                        </div>
                        <div>
                          {premiumCareSubscriptions.map((sub) => (
                            <SubscriptionRow
                              key={sub.id}
                              subscription={sub}
                              refreshSubscriptionList={refreshSubscriptionList}
                              isSuperAdmin={isSuperAdmin}
                              fetchAll={fetchAll}
                              extraFields={["utilized_count"]}
                              premiumcareOnly={true}
                              setShowSAUpdateReminder={setShowSAUpdateReminder}
                              saleIsFinalizedState={saleIsFinalizedState}
                              showRenewalWithoutRobotsIcon={
                                sub.is_renewal_without_robots
                              }
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div id='abii_content_package_subscriptions'>
                    <div id='abii-content-packages-hr'>
                      <h4 className='subscription-type'>
                        ABii Content Packages
                      </h4>
                      {abiiContentPackageSubscriptions.length > 0 ? (
                        <span>
                          {subscriptionStats[
                            config.ABII_CONTENT_PACKAGE_SUB_TYPE
                          ] && (
                            <p>
                              <span className='font-weight-bold'>
                                Overall ABii Content Package Usage:
                              </span>
                              {subscriptionStats[
                                config.ABII_CONTENT_PACKAGE_SUB_TYPE
                              ].limit > 0 ? (
                                <>
                                  <span className='mx-2 font-weight-bold'>
                                    {overallAcpUsage <= 0 ? 0 : overallAcpUsage}
                                  </span>
                                  out of
                                  <span className='mx-2 font-weight-bold'>
                                    {
                                      subscriptionStats[
                                        config.ABII_CONTENT_PACKAGE_SUB_TYPE
                                      ].limit
                                    }
                                  </span>
                                </>
                              ) : (
                                <span className='mx-2 font-weight-bold'>
                                  {"0"}
                                </span>
                              )}
                              total slots from active subscriptions.
                            </p>
                          )}
                        </span>
                      ) : (
                        <p>
                          No{" "}
                          {
                            subscriptionLabelMap[
                              config.ABII_CONTENT_PACKAGE_SUB_TYPE
                            ]
                          }{" "}
                          subscriptions available.
                        </p>
                      )}
                    </div>
                    {abiiContentPackageSubscriptions.length > 0 && (
                      <div>
                        <div
                          className={`${
                            isSuperAdmin
                              ? "super_admin_subscription_row_hr"
                              : "subscription_row_hr"
                          }`}
                        >
                          {isSuperAdmin && <div>Sale Id</div>}
                          <div>Name</div>
                          <div>Status</div>
                          <div>Expiration Date</div>
                          <div>Total Slots</div>
                          <div>Used</div>
                          <div>Action</div>
                        </div>
                        <div>
                          {abiiContentPackageSubscriptions.map((sub) => (
                            <SubscriptionRow
                              key={sub.id}
                              subscription={sub}
                              refreshSubscriptionList={refreshSubscriptionList}
                              isSuperAdmin={isSuperAdmin}
                              fetchAll={fetchAll}
                              extraFields={["utilized_count"]}
                              setShowSAUpdateReminder={setShowSAUpdateReminder}
                              saleIsFinalizedState={saleIsFinalizedState}
                              showRenewalWithoutRobotsIcon={
                                sub.is_renewal_without_robots
                              }
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(
  connect(mapStateToProps),
  withAPI
)(OrgSubscriptionsTable);
