import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { useDebounce } from "use-debounce";

import withAPI from "../../services/api";
import Grid from "@material-ui/core/Grid";

import { sortedArray } from "../../common/utils";
import MainDashboard from "../../partials/Main";
import RobotRow from "../../components/SuperAdmin/AddRobotRow.js";
import BulkAddRobotsModal from "../../components/SuperAdmin/BulkAddRobotsModal.js";
import { AntSwitch } from "../../components/SuperAdmin/utils.js";

import FA from "../../containers/fa";

import "./Dashboard.scss";

const AddRobotToOrg = ({ api, match }) => {
  const [orgInfo, setOrgInfo] = useState({});
  const [robots, setRobots] = useState([]);
  const [shouldSpin, setShouldSpin] = useState(true);
  const [error, setError] = useState(null);

  const [robotFilterString, setRobotFilterString] = useState("");
  const [filteredRobots, setFilteredRobots] = useState([]);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [debouncedFilterString] = useDebounce(robotFilterString, 1000);

  const [showAllRobots, setShowAllRobots] = useState(false);
  const [unassignedRobots, setUnassignedRobots] = useState([]);
  const [bulkAddList, setBulkAddList] = useState([]);
  const [enableBulkAdd, setEnableBulkAdd] = useState(false);
  const [showBulkAddModal, setShowBulkAddModal] = useState(false);

  const [sortType, setSortType] = useState("wifi");
  const [sortAsc, setSortAsc] = useState(false);
  const [sortTouched, setSortTouched] = useState(false);

  useEffect(() => {
    api
      .fetchOrganizationInfo(match.params.orgId)
      .then((resp) => {
        setOrgInfo(resp);
      })
      .catch((err) => {
        setShouldSpin(false);
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    setShouldSpin(true);
    try {
      api.fetchAllVisibleRobots({ show_all: true, move_robot_view: true }).then((objects) => {
        setRobots(objects.results);
        setShouldSpin(false);
      });
    } catch (err) {
      setShouldSpin(false);
      setError("There was an error fetching the robots. Please try again.");
    }
  }, [fetchTrigger]);

  useEffect(() => {
    if (debouncedFilterString === "") {
      setFilteredRobots(robots);
    } else {
      const results = robots.filter((r) =>
        JSON.stringify(r).includes(debouncedFilterString)
      );
      setFilteredRobots(results);
    }
  }, [debouncedFilterString, robots]);

  useEffect(() => {
    const results = filteredRobots.filter((r) => !r.organization_id);
    setUnassignedRobots(results);
  }, [filteredRobots]);

  function triggerListRefresh() {
    setFetchTrigger(!fetchTrigger);
  }

  const handleShowAllSwitch = () => {
    setShowAllRobots(!showAllRobots);
  };

  const handleBulkAddSwitch = () => {
    setEnableBulkAdd(!enableBulkAdd);
    setBulkAddList([]);
  };

  const toggleRobotToAdd = (robotSerial) => {
    console.log(bulkAddList);
    console.log(robotSerial);
    let index = bulkAddList.indexOf(robotSerial);
    console.log(index);
    if (index === -1) {
      bulkAddList.push(robotSerial);
      setBulkAddList(bulkAddList);
    } else {
      bulkAddList.splice(index, 1);
      setBulkAddList(bulkAddList);
    }
  };

  // sort rows
  useEffect(() => {
    if (sortTouched === true) {
      setFilteredRobots(sortedArray(filteredRobots, sortType, sortAsc));
    }
  }, [sortType, sortAsc]);

  const handleColumnHeaderClick = (column) => {
    setSortTouched(true);
    console.log(column, sortAsc);
    if (sortType === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortType(column);
      setSortAsc(false);
    }
  };

  const getHeaderClasses = (column) => {
    let classes = "sortable";
    if (sortType === column) {
      if (sortAsc === false) {
        classes += " headerSortDown";
      } else {
        classes += " headerSortUp";
      }
    }
    return classes;
  };

  return (
    <MainDashboard>
      <div className='common_border'>
        <div className='common_heading d-flex justify-content-between align-items-center'>
          <p>Organizations > {orgInfo.name} > Add Robots</p>
          <div className='d-flex justify-content-end'>
            <label>
              <FA icon='search' />
            </label>
            &nbsp;
            <input
              placeholder='Search ...'
              className='form-control input-underline'
              value={robotFilterString}
              onChange={(e) => setRobotFilterString(e.target.value)}
            />
            <Link
              className='btn btn-primary ml-2'
              to={"/super/org/" + orgInfo.id}
            >
              Back
            </Link>
          </div>
        </div>

        <div className='common_dashboard_bg pt-2'>
          <div className='row'>
            <div className='col-12'>
              <div className='row mb-10'></div>
              {error && (
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              )}
              {shouldSpin && (
                <div className='pt-5'>
                  <h4>
                    <FA color='gray' icon='spinner' spin /> Loading Robots...
                  </h4>
                </div>
              )}

              {!shouldSpin && (
                <div className='row mb-3'>
                  <div className='mb-2'>
                    <Grid
                      component='label'
                      container
                      alignItems='center'
                      spacing={1}
                    >
                      <Grid item>Show Unassigned Robots</Grid>
                      <Grid item>
                        <AntSwitch
                          name='ShowAllRobotsSwitch'
                          checked={showAllRobots}
                          onChange={handleShowAllSwitch}
                        />
                      </Grid>
                      <Grid item>Show All Robots</Grid>
                    </Grid>
                  </div>

                  <p className='line-break'>&nbsp;</p>

                  <div>
                    <Grid
                      component='label'
                      container
                      alignItems='center'
                      spacing={1}
                    >
                      <Grid item>Individual Add</Grid>
                      <Grid item>
                        <AntSwitch
                          name='ShowAllRobotsSwitch'
                          checked={enableBulkAdd}
                          onChange={handleBulkAddSwitch}
                        />
                      </Grid>
                      <Grid item>Bulk Add</Grid>
                    </Grid>

                    {enableBulkAdd && (
                      <button
                        className='btn btn-primary mt-2'
                        onClick={() => {
                          setShowBulkAddModal(true);
                        }}
                      >
                        Add Selected Robots
                      </button>
                    )}
                  </div>
                </div>
              )}
              {!shouldSpin && (
                <div className='card'>
                  <div
                    className='progress-linear school_listing manage_learner_table table-scroll-wrapper'
                    role='progressbar'
                  >
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th
                            className={getHeaderClasses("wifi")}
                            onClick={() => {
                              handleColumnHeaderClick("wifi");
                            }}
                          >
                            Wifi name
                          </th>
                          <th>MAC Address</th>
                          <th>Serial</th>
                          <th
                            className={getHeaderClasses("code_ver")}
                            onClick={() => {
                              handleColumnHeaderClick("code_ver");
                            }}
                          >
                            Code Version
                          </th>
                          <th>Organization</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {showAllRobots
                          ? filteredRobots.map((rbt) => (
                              <RobotRow
                                key={rbt.serial}
                                rbt={rbt}
                                refreshList={triggerListRefresh}
                                toggleRobotToAdd={toggleRobotToAdd}
                                enableBulkAdd={enableBulkAdd}
                                sourceOrgInfo={orgInfo}
                              />
                            ))
                          : unassignedRobots.map((rbt) => (
                              <RobotRow
                                key={rbt.serial}
                                rbt={rbt}
                                refreshList={triggerListRefresh}
                                toggleRobotToAdd={toggleRobotToAdd}
                                enableBulkAdd={enableBulkAdd}
                                sourceOrgInfo={orgInfo}
                              />
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showBulkAddModal && (
        <BulkAddRobotsModal
          rbtSerials={bulkAddList}
          shouldShowFunc={setShowBulkAddModal}
          refreshList={triggerListRefresh}
          orgName={orgInfo.name}
        />
      )}
    </MainDashboard>
  );
};

export default compose(withAPI)(AddRobotToOrg);
