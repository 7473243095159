import React, {useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";

import {
  showPasswordReset as showPasswordResetAction,
  forcePasswordReset as forcePasswordResetAction,
} from "../../redux/actions";
import withAPI from "../../services/api";

import FA from "../../containers/fa";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  forcePasswordReset: state.gui.forcePasswordReset,
});

const TeacherResetPWModal = ({
  api,
  dispatch,
  shouldShowFunc,
  forcePasswordReset,
  clsId,
}) => {
  const [pw1, setPW1] = useState("");
  const [pw2, setPW2] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [initialForcePasswordReset, setInitialForcePasswordReset] =
    useState(forcePasswordReset);

  const handleRename = () => {
    setError(null);

    if (pw1 === "" || pw2 === "") {
      setError("Password cannot be blank.");
      return;
    } else if (pw1 !== pw2) {
      setError("Passwords must match.");
      return;
    }

    api
      .resetUserPassword(pw1, pw2)
      .then(() => {
        setSuccess("Successfully reset password.");
        setError("");
        dispatch(forcePasswordResetAction(false));
      })
      .catch((e) => {
        // Rough way to parse ValidationError message
        let err = e.message.split(":");
        let arr = err[err.length - 1].split(",");
        if (arr.length > 0) {
          setError(
            <React.Fragment>
              {arr.map((val) => (
                <React.Fragment>
                  {val}
                  <br />
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        } else {
          setError("Something went wrong resetting your password.");
        }
      });
  };
  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Set New Password</p>
            {!forcePasswordReset && (
              <button
                className='pdf_popup_close'
                onClick={() => shouldShowFunc()}
              >
                <FA icon='times' />
              </button>
            )}
          </div>
          <div className='reset_pw_modal common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {!error && !success && (
                  <React.Fragment>
                    Your password must contain:
                    <ul>
                      <li>at least 8 characters</li>
                      <li>at least one lowercase letter, a-z</li>
                      <li>at least one uppercase letter, A-Z</li>
                      <li>at least one number, 0-9</li>
                    </ul>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {error && <div className='alert alert-danger'>{error}</div>}
                {success && (
                  <div className='alert alert-success'>{success}</div>
                )}
                <div className='form-content form-group'>
                  <input
                    name='new-password'
                    className='form-control'
                    type='password'
                    placeholder='Password'
                    autoComplete="new-password"
                    value={pw1}
                    onChange={(e) => setPW1(e.target.value)}
                  />
                  <input
                    name='new-password-again'
                    className='form-control'
                    type='password'
                    placeholder='Password (Again)'
                    autoComplete="new-password"
                    value={pw2}
                    onChange={(e) => setPW2(e.target.value)}
                  />
                  <div>
                    <button
                      onClick={handleRename}
                      className='btn btn-lg fs-14 btn-primary col-md-5 form-control'
                    >
                      Set New Password
                    </button>
                  </div>
                  {initialForcePasswordReset && (
                    <div>
                      <button
                        disabled={success === null}
                        onClick={() => shouldShowFunc()}
                        className='btn btn-lg fs-14 btn-primary col-md-5 form-control'
                      >
                        Close
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(TeacherResetPWModal);
