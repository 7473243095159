import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import { 
  unsyncedLessons as unsyncedLessonsA,
  syncRefreshed as syncRefreshedA,
  syncBtnClicked as syncBtnClickedA,
} from '../../redux/actions';


import withAPI from '../../services/api';
import FAW from '../../containers/faw';
import config from '../../common/config';

const mapStateToProps = (state, ownProps?) => ({
  token: state.auth.token,
  unsyncedLessons: state.status.unsyncedLessons,
  syncRefreshed: state.status.syncRefreshed,
  qsyncerSyncing: state.status.qsyncerSyncing,
  lastSuccessfulSync: state.status.lastSuccessfulSync,
})

const SyncButton = ({ api, token , dispatch, unsyncedLessons, displayStyle, syncRefreshed, qsyncerSyncing, lastSuccessfulSync}) => {

  const getBtnClass = () => {
    if (qsyncerSyncing) {
      return "btn-info";
    }
    if (unsyncedLessons == null) {
      return "btn-default";
    } else if (unsyncedLessons <= config.SYNC_THRESHOLDS.low) {
      return "btn-success";
    } else if (unsyncedLessons <= config.SYNC_THRESHOLDS.mid) {
      return "btn-warning";
    } else {
      return "btn-danger";
    }
  };

  const [syncText, setSyncText] = useState(null);
  const [syncTextFailed, setSyncTextFailed] = useState(false);
  const [spin, setSpin] = useState(false);

  const profilePushHandlerCB = (event) => {
    // setSyncEvents(events);
    const allData = JSON.parse(event.data);
    const eventType = allData.event_type
    const jsonData = allData.data
    console.log(allData)
    if (allData.event_type !== 'PUSH SESSION') {
      return
    }
    setSyncText(allData.msg);
    if (allData.is_last === 1) {
      api.unregisterSyncHandler(profilePushHandlerCB);
      setSyncText("Profile push is successful; Pulling updated Profiles.");
    }
  }

  const errHandler = (errorData) => {
    dispatch(syncBtnClickedA(false));
    setSpin(false);
    setSyncTextFailed(true);
    dispatch(syncRefreshedA(!syncRefreshed));
    if (errorData && errorData.error) {
      setSyncText(errorData.error);
    } else {
      console.log("Error in QSyncer: ", errorData);
      setSyncText("Having trouble syncing. Try again later.");
    }
  }

  useEffect(() => {
    if (lastSuccessfulSync) {
      setSyncText('Last successful Sync: ' + lastSuccessfulSync)
    }
    return () => {
      dispatch(syncBtnClickedA(false));
    }
  }, [lastSuccessfulSync]);

  const syncData = () => {
    dispatch(syncBtnClickedA(true));
    setSyncTextFailed(false);
    setSpin(true);
    setSyncText("Syncing...");

    // This looks kind of goofy but I want the promises to happen
    // deterministically and in order. We use SQLite on the backend,
    // which can't handle multi-threaded access.
    const syncPromise = new Promise((resolve, reject) => {
      api.syncPushUserAccountData(token).then((jsonData) => {
        if (jsonData.data && jsonData.data.error) {
          return reject(jsonData.data);
        }
        setSyncText("Account push successful; Pushing Profiles...")
        
        api.registerSyncHandler(profilePushHandlerCB);
        api.syncPushUserProfileData(token).then((jsonData) => {
          if (jsonData.data && jsonData.data.error) {
            return reject(jsonData.data);
          }
          api.syncPullUserProfileData(token).then((jsonData) => {
            setSyncText("Sync successful.")
            setSpin(false);
            setSyncTextFailed(false);
            dispatch(syncBtnClickedA(false));
            api.registerSyncHandler(syncBtnStatusHandlerCB);
            api.fetchLastSyncStatus()
            resolve();
          }).catch(errHandler);

        }).catch(errHandler);
      }).catch(errHandler);
    }).catch(errHandler);
  }

  const syncBtnStatusHandlerCB = (event) => {

    const allData = JSON.parse(event.data);
    if (allData.event_type !== 'LAST_SYNC_STATUS') {
      return
    }
    console.log(allData.data)
    const statusData = allData.data;
    const count = statusData['count'];
    if (count !== null) {
      dispatch(unsyncedLessonsA(count));
      // toggle to ensure a dispatch
      dispatch(syncRefreshedA(!syncRefreshed));
      api.unregisterSyncHandler(syncBtnStatusHandlerCB);
    }
  }


  const getAlertForSyncText = () => {
    if (qsyncerSyncing) {
      return null
    }
    if (syncText == null) {
      return (<div className="alert alert-secondary">Unsynced.</div>)
    } else if (syncTextFailed && syncText) {
      const readyState = api.qsyncerGetReadyState()
      if (readyState === 3) {
        // QSyncer is down.
        return (
          <div className="alert alert-warning">
            Could not sync. <Link to="/setup-wifi">Make sure wifi is setup correctly.</Link>
          </div>
        )
      } else if (readyState === 1) {
        // QSyncer is up, but we couldn't sync. Generic error message.
        if (syncText.includes("not connected to the internet")) {
          return (
            <div className="alert alert-warning">
              Could not sync. <Link to="/setup-wifi">Make sure wifi is setup correctly</Link> and has access to ABii web resources.
            </div>
          )
        }

        return (<div className="alert alert-warning">Some error occurred during syncing. Check Sync History below for details.</div>)
      } else {
        return (
          // Not really sure, maybe the socket is slow to connect?
          <div className="alert alert-warning">
            Having trouble syncing. Please try again.
          </div>
        )
      }
    } else {
      return (
        <div className="alert alert-primary">{syncText}</div>
      )
    }
  }

  return (
    <div>
      {displayStyle=='full' ?
        <div>
          <div style={{"paddingRight": "1em"}}>
              {spin || qsyncerSyncing
                ? 
                <button title="Sync Class Data" className={getBtnClass() + " btn"} style={{"fontSize": "24px"}}>
                  <span><FAW icon="spinner" spin /> Syncing ... Please do not refresh.</span>
                </button>
                : 
                <button onClick={syncData} title="Sync Class Data" className={getBtnClass() + " btn"} style={{"fontSize": "24px"}}>
                  <span><FAW icon="sync" /> SYNC NOW </span>
                </button>
              }
          </div>  
          <div style={{"paddingRight": "1em", "marginTop": "24px"}}>
            { getAlertForSyncText() }
          </div>
        </div>
        :
        <div className="d-flex">
          <div style={{"paddingRight": "1em"}} className="sync-text-div">
            { getAlertForSyncText() }
          </div>
          <div style={{"paddingRight": "1em"}}>
            {spin || qsyncerSyncing
              ? 
              <button title="Sync Class Data" className={getBtnClass() + " btn"}>
                <span><FAW icon="spinner" spin /> Syncing ... Please do not refresh.</span>
              </button>
              : 
              <button onClick={syncData} title="Sync Class Data" className={getBtnClass() + " btn"}>
                <span><FAW icon="sync" /> SYNC NOW </span>
              </button>
            }
          </div>  
        </div>
      }
    </div>
)}

export default compose(
  connect(mapStateToProps),
  withAPI,
)(SyncButton);
