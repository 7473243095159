import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {compose} from 'redux';

import LoginForm from '../components/LoginForm';
import Gate from '../partials/Gate';
import {logout} from '../redux/actions';
import {authService} from '../services/auth';
import {useTitle} from '../common/utils';
import './Login.scss';

const Login = ({dispatch}) => {

  useTitle('Login')

  const [canCreateUser, setCanCreateUser] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    // purge data here
    authService.logout().then(() => {
      dispatch(logout());
      //removeCookie('csrftoken');
      //removeCookie('messages');
    })
  }, [dispatch, removeCookie])

  return (
    <Gate isLogin={true}>
      <div id='login_whitebox' className="login_box visible-scrollbar">
      <Link id='login_back_btn' to="/">&laquo; Back</Link>
        <div id='login_hr_contents' className="form-group">
          <h1>Login</h1>
          {canCreateUser ?
            <p>Sign In to your account or <Link to="/register">create a new one.</Link></p>
            :
            <p>Sign In to your account.</p>
          }
        </div>
        <LoginForm
          setCanCreateUser={setCanCreateUser}
        />
      </div>
    </Gate>
  )
}

export default compose(
  withRouter,
  connect()
)(Login);
