import {
  LAST_SUCCESS_SYNC,
  QSYNCER_SYNCING,
  ROBOT_TYPE,
  SECURITY_QUESTION,
  STUDENT_CAP,
  SYNC_BTN_CLICKED,
  SYNC_REFRESHED,
  UNSYNCED_LESSONS,
  UNSYNCED_LESSONS_BY_LEARNER,
  USER_ORG_SETTINGS,
  WIFISTATUS,
} from "../actionTypes";

function initUserOrgSettings () {
  let u = JSON.parse(sessionStorage.getItem("user"));
  if (u && u.organization && u.organization.settings) {
    return u.organization.settings;
  } else {
    return {}
  }
}

const initialState = {
  wifiConnected: true,
  unsyncedLessons: null,
  unsyncedLessonsByLearner: null,
  userOrgSettings: initUserOrgSettings(),
  syncRefreshed: false,
  qsyncerSyncing: false,
  lastSuccessfulSync: null,
  robotType: sessionStorage.getItem("robotType") || null,
  securityQPending: null,
  studentCap: sessionStorage.getItem("studentCap") || null,
  syncBtnClicked: false,
};

const status = (state = initialState, action) => {
  switch (action.type) {
    case WIFISTATUS:
      return Object.assign({}, state, {
        wifiConnected: action.payload,
      });
    case UNSYNCED_LESSONS:
      return Object.assign({}, state, {
        unsyncedLessons: action.payload,
      });
    case UNSYNCED_LESSONS_BY_LEARNER:
      return Object.assign({}, state, {
        unsyncedLessonsByLearner: action.payload,
      });
    case SYNC_REFRESHED:
      return Object.assign({}, state, {
        syncRefreshed: action.payload,
      });
    case QSYNCER_SYNCING:
      return Object.assign({}, state, {
        qsyncerSyncing: action.payload,
      });
    case LAST_SUCCESS_SYNC:
      return Object.assign({}, state, {
        lastSuccessfulSync: action.payload,
      });
    case SECURITY_QUESTION:
      return Object.assign({}, state, {
        securityQPending: action.payload,
      });
    case ROBOT_TYPE:
      return Object.assign({}, state, {
        robotType: action.payload,
      });
    case STUDENT_CAP:
      return Object.assign({}, state, {
        studentCap: action.payload,
      });
    case SYNC_BTN_CLICKED:
      return Object.assign({}, state, {
        syncBtnClicked: action.payload,
      });
    case USER_ORG_SETTINGS:
      let newSettings = Object.assign({}, state.userOrgSettings, action.payload);
      return Object.assign({}, state, {userOrgSettings: newSettings});
    case "LOGOUT":
      // clear up states
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
};

export default status;
