import React, { useState, useEffect } from 'react';
import withAPI from '../../services/api';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import FA from '../../containers/fa';


const SendRegCodeEmailModal = ({api, shouldShowFunc, orgEmail, regCodeId}) => {
  const [error, setError] = useState(false);
  const [busy, setBusy] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [email, setEmail] = useState(orgEmail)

  const handleEmailClick = () => {
    setBusy(true);
    setError(false);
    setSuccess(false)
    setErrMsg(null);
    api.triggerOrgWelcomeEmail(email, regCodeId).then((data) => {
      console.log("Send RegCode op returned: ", data)
      setSuccess(true)
      setBusy(false);
    }).catch(() => {
      setErrMsg("Something went wrong... Please try again.");
      setError(true);
      setBusy(false);
    });
  }

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Send Email</p>
            <div className="pdf_popup_close" onClick={() => shouldShowFunc(false)}><FA icon="times" /></div>
          </div>
          <div className="common_dashboard_bg">
            <div className="row">
              <div className="col-12">
                {error && <div className="alert alert-danger">{errMsg}</div>}
                {success && <div className="alert alert-success">Activation Code is sent to {email}!</div>}
                
                <p>Send email with Activation Code and instructions.</p>
    	          
                Send to:
                <input required className="form-control input-underline input-lg"
    	            placeholder="Send to Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                <div className="mt-2">
                  <button onClick={handleEmailClick} className="btn btn-sm btn-primary" disabled={busy}>Send</button>
                  <button onClick={() => shouldShowFunc(false)} className="btn btn-sm btn-primary ml-2" disabled={busy}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)}

export default compose(withRouter, withAPI)(SendRegCodeEmailModal);
