import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import config from "../../../common/config";
import { sortedArray } from "../../../common/utils";
import FA from "../../../containers/fa";
import withAPI from "../../../services/api";
import RemoveObjectModal from "../RemoveObjectModal";
import AddUserToSubscriptionPopup from "./AddUserToSubscriptionPopup";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});

const SubDetailLCT = ({ api, user, subData, refreshSubs }) => {
  const superAdminView = user && user.usertype === config.SUPERADMIN_USERTYPE;
  const [showRemoveObjectModal, setShowRemoveObjectModal] = useState(false);
  const [removalParams, setRemovalParams] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [subscriptionAssignmentInfo, setSubscriptionAssignmentInfo] = useState(
    {}
  );
  const [reloadSub, setReloadSub] = useState(null);
  const [showAddObject, setShowAddObject] = useState(false);
  const [showAddUsersPopup, setshowAddUsersPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const orgLevelSubInfo = subData.org_level;
  const schoolLevelSubs = subData.object_list_in_schools;
  const sub_object_list = subData.object_list_in_schools || subData.object_list;

  useEffect(() => {
    const queryParams = {
      show_all: true,
    };
    setLoading(true);
    api
      .fetchAllVisibleUsers(queryParams)
      .then((objects) => {
        setAllUsers(objects);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError("An error has occurred. Please try again later.");
      });
  }, [subData]);

  useEffect(() => {
    if (reloadSub !== null) {
      refreshSubs();
    }
  }, [reloadSub]);

  /**
   * getUsersWithSubscription filters out all users in the org who are
   * already assigned to the lct subscriptions
   * @returns {Array} of users
   */
  const getUsersWithSubscription = () => {
    let usersWithSubscription = sortedArray(
      allUsers.filter((obj) => sub_object_list.includes(obj.userprofile.id)),
      "username",
      true
    );
    return usersWithSubscription;
  };

  /**
   * getUsersWithoutSubscription filters out all users in the org who are
   * not assigned to the lct subscriptions
   * @returns {Array} of users
   */
  const getUsersWithoutSubscription = () => {
    let usersWithSubscription = sortedArray(
      allUsers.filter(
        (obj) => !subData.object_list.includes(obj.userprofile.id)
      ),
      "username",
      true
    );
    return usersWithSubscription;
  };

  /**
   * getAssignableUsers gets subset of users that the logged-in user is allowed
   * to assign to the Subscription. This is necessary because SchoolAdmins can
   * only manage users in their schools, but can also view all OrgAdmins.
   */
  const getAssignableUsers = (users) => {
    let assignable = users.filter(
      (obj) => obj.userprofile.usertype !== "orgadmin"
    );
    return assignable;
  };

  useEffect(() => {
    if (subData.object_list) {
      const usersWithSubscription = getUsersWithSubscription();
      const usersWithoutSubscription = getUsersWithoutSubscription();
      const assignableUsers = getAssignableUsers(usersWithoutSubscription);
      if (schoolLevelSubs) {
        const populatedLimit = {
          object_name: "User",
          object_list: usersWithSubscription,
          max_objects: subData.max_objects_in_schools,
          addable_objects: assignableUsers,
        };
        setSubscriptionAssignmentInfo(populatedLimit);
      } else {
        const populatedLimit = {
          object_name: "User",
          object_list: usersWithSubscription,
          max_objects: subData.max_objects,
          addable_objects: usersWithoutSubscription,
        };
        setSubscriptionAssignmentInfo(populatedLimit);
      }
    }
  }, [subData, allUsers, orgLevelSubInfo]);

  function handleAddObject(toAdd) {
    let data = {
      subscription_type: "lct",
      object_type: "userprofile",
      action: "add",
      object_list: toAdd,
      org_id: user.organization.id,
    };
    api
      .manageSubscriptionObjects(data)
      .then(() => {
        setShowAddObject(false);
        setReloadSub(!reloadSub);
        setshowAddUsersPopup(false);
      })
      .catch((error) => {
        console.log(error);
        setshowAddUsersPopup(false);
      });
  }

  /**
   * renderUsersWithSubscription builds and returns table tag contents with
   * rows of users included in the lct subscriptions.
   * @returns {html} Table tag contents
   */
  function renderUsersWithSubscription() {
    let obj_list = subscriptionAssignmentInfo.object_list;
    console.log("obj_list:", obj_list);
    return obj_list && obj_list.length > 0 ? (
      obj_list.map((teacher) => (
        <tr key={teacher.username}>
          <td>{teacher.username}</td>
          <td>
            {subData.name === "lct" && teacher.userprofile.custom_lessons ? (
              <span>
                {" "}
                {teacher.userprofile.custom_lessons} lessons created{" "}
              </span>
            ) : (
              "N/A"
            )}
          </td>
          <td>
            {!superAdminView && (
              <button
                className='btn btn-sm btn-danger'
                onClick={() => {
                  setRemovalParams({
                    limit: subscriptionAssignmentInfo,
                    toRemove: teacher.id,
                  });
                  setShowRemoveObjectModal(true);
                }}
                title='Remove'
              >
                <FA color='white' icon='trash' />
              </button>
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan='3'>No users assigned yet.</td>
      </tr>
    );
  }

  function addObjectAllowed() {
    if (schoolLevelSubs) {
      return (
        subData.max_objects_in_schools > subData.object_list_in_schools.length
      );
    } else {
      return subData.max_objects > subData.object_list.length;
    }
  }

  /**
   * renderSubscriptionAssignmentInfo: The container and table tag for the
   * users currently assigned to the lct subscriptions. Also includes the
   * Add User button.
   * @returns {html} Header to the table and the table tag. Calls upon
   * renderUsersWithSubscription
   */
  function renderSubscriptionAssignmentInfo() {
    return subscriptionAssignmentInfo && subData.object_list ? (
      <div className='card'>
        <span className='py-3 mx-2 d-flex justify-content-between'>
          <strong>
            <h5>Users Assigned to the LCT Subscriptions</h5>
          </strong>
          {addObjectAllowed() && !superAdminView && (
            <span className='d-flex justify-content-between'>
              {!showAddObject && (
                <button
                  className='btn btn-sm btn-primary'
                  onClick={() => setshowAddUsersPopup(true)}
                  title='Add'
                >
                  <FA color='white' icon='plus' />
                  &nbsp;Add User
                </button>
              )}
              {showAddUsersPopup && (
                <AddUserToSubscriptionPopup
                  subscriptionAssignmentInfo={subscriptionAssignmentInfo}
                  handleAddObject={handleAddObject}
                  setshowAddUsersPopup={setshowAddUsersPopup}
                  usedSlots={
                    schoolLevelSubs
                      ? subData.object_list_in_schools.length
                      : subData.object_list.length
                  }
                  maxSlots={
                    schoolLevelSubs
                      ? subData.max_objects_in_schools
                      : subData.max_objects
                  }
                />
              )}
            </span>
          )}
        </span>
        <div
          className='progress-linear school_listing manage_learner_table table-scroll-wrapper'
          role='progressbar'
        >
          <table className='table table-striped table-sm'>
            <tbody>
              <tr>
                <th>Teacher</th>
                <th>Additional Info</th>
                <th></th>
              </tr>
              {!loading ? (
                renderUsersWithSubscription()
              ) : (
                <div>
                  <FA icon='spinner' spin /> Loading...
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    ) : null;
  }

  return (
    <div>
      {showRemoveObjectModal ? (
        <RemoveObjectModal
          setShowModal={setShowRemoveObjectModal}
          reloadObjects={() => setReloadSub(!reloadSub)}
          objectType='User'
          removalParams={removalParams}
          subscriptionType='lct'
        />
      ) : null}

      <div className='row'>
        <div className='col-12'>
          <div className='row mb-10'></div>
          {subData.object_list && (
            <div>
              <div>
                <h4>Overall LCT Usage</h4>
                <p>
                  <strong>Organization-wide Usage:</strong>
                  <span className='mx-2 font-weight-bold'>
                    {subData.object_list.length}
                  </span>
                  out of
                  <span className='mx-2 font-weight-bold'>
                    {subData.max_objects}
                  </span>
                  total slots from active subscriptions.
                </p>
                {user.usertype === config.ORGUNITADMIN_USERTYPE &&
                  (subData.max_objects_in_schools > 0 ? (
                    <p>
                      <strong>School-specific Usage:</strong>
                      <span className='mx-2 font-weight-bold'>
                        {subData.used_slots_in_schools}
                      </span>
                      out of
                      <span className='mx-2 font-weight-bold'>
                        {subData.max_objects_in_schools}
                      </span>
                      total slots from active subscriptions.
                    </p>
                  ) : (
                    <p>
                      <strong>School-specific Usage:</strong>
                      <span className='mx-2 font-weight-bold'>{"0"}</span>
                      total slots from active subscriptions.
                    </p>
                  ))}
                <p>
                  The info and table below cover all active subscriptions of the
                  same type.
                </p>
              </div>
              {!error ? (
                renderSubscriptionAssignmentInfo()
              ) : (
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(SubDetailLCT);
