import React, { useState, useEffect } from "react";
import withAPI from "../../services/api";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import { timeSince } from "../../common/utils";

const OnlineNowModal = ({
  api,
  rbt,
  shouldShowFunc,
  setShowUpdateRobotModal,
}) => {
  const [loadingStatusInfo, setLoadingStatusInfo] = useState(true);
  const [robotStatusInfo, setRobotStatusInfo] = useState(null);
  const [robotStatusAge, setRobotStatusAge] = useState(null);

  function fetchRobotStatus({ cacheOnly }) {
    api
      .fetchCachedRobotStatus(rbt.serial)
      .then((status_json) => {
        console.log("fetchCachedRobotStatus:", status_json);
        setRobotStatusInfo(status_json.status);
        if (status_json?.status || cacheOnly) {
          setLoadingStatusInfo(false);
        }
        if (status_json && !status_json.status && !cacheOnly) {
          // If no status message in cache and robot is online,
          // try to grab status directy from the robot's status file.
          // This is slower and more complicated on the backend
          api
            .fetchRobotStatusFile(rbt.serial)
            .then((status_file_json) => {
              console.log("fetchRobotStatusFile:", status_file_json);
              setRobotStatusInfo(status_file_json.status);
              setLoadingStatusInfo(false);
            })
            .catch((e) => {
              console.log("fetchRobotStatusFile Error'd:", e);
              setLoadingStatusInfo(false);
            });
        }
      })
      .catch((fromCacheError) => {
        console.log("fetchCachedRobotStatus Error'd:", fromCacheError);
        // But if that doesn't work, might be able to grab status directy from the robot's status file.
        if (!cacheOnly) {
          api
            .fetchRobotStatusFile(rbt.serial)
            .then((status_file_json) => {
              console.log("fetchRobotStatusFile:", status_file_json);
              setRobotStatusInfo(status_file_json.status);
              setLoadingStatusInfo(false);
            })
            .catch((e) => {
              console.log("fetchRobotStatusFile Error'd:", e);
              setLoadingStatusInfo(false);
            });
        }
      });
  }

  function getOnlineStatus({ primary }) {
    // Check whether this robot is online now
    api
      .fetchIsRobotOnline(rbt.serial)
      .then((online_json) => {
        // If this robot is online, we can actively check for a status message
        // Otherwise, we must rely on the latest stored in the cache
        if (online_json.online) {
          fetchRobotStatus({ cacheOnly: false });
        } else {
          fetchRobotStatus({ cacheOnly: true });
        }
      })
      .catch((e) => {
        console.log("isRobtOnline Error'd:", e);
      });
  }

  useEffect(() => {
    getOnlineStatus({ primary: true });
  }, []);

  useEffect(() => {
    // Whenever robotStatusInfo changes, interpret the UTC timestamp as something displayable
    if (robotStatusInfo?.timestamp) {
      let timeObj = new Date(robotStatusInfo.timestamp + "Z"); // append Z to ensure datetime gets parsed as UTC
      let delay = timeSince(timeObj);
      console.log(
        "Status posted at ",
        robotStatusInfo.timestamp,
        ", ",
        delay,
        " ago"
      );
      setRobotStatusAge(delay);
    }
  }, [robotStatusInfo]);

  function closeModal() {
    shouldShowFunc(false);
  }

  const getButtonDiv = () => {
    if (!rbt.can_update) {
      return (
        <button
          className='btn btn-md btn-secondary update-robot-modal-btn'
          style={{ fontSize: "24px" }}
          disabled={true}
        >
          <FAW icon='sync' />
          &nbsp;{"Subscription Expired"}
        </button>
      );
    } else if (robotStatusInfo) {
      return (
        <div className='status-button p-1 update-robot-modal-btn'>
          <button
            className='btn btn-md btn-primary'
            style={{ fontSize: "24px" }}
            disabled={!robotStatusInfo.update_allowed}
            onClick={() => {
              shouldShowFunc(false);
              setShowUpdateRobotModal(true);
            }}
          >
            <FAW icon='sync' />
            &nbsp;{"Update and Sync"}
          </button>
        </div>
      );
    } else {
      return (
        <div className='status-button update-robot-modal-btn'>
          <button
            className='btn btn-sm btn-primary'
            style={{ fontSize: "24px" }}
            onClick={() => {
              shouldShowFunc(false);
              setShowUpdateRobotModal(true);
            }}
          >
            <FAW icon='sync' />
            &nbsp;{"Update and Sync"}
          </button>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className='pdf-upload'></div>
      <div
        className='pdf-upload-wrapper'
        style={{ display: "block", position: "fixed" }}
      >
        <div className='common_border'>
          <div
            className='common_heading'
            style={{ position: "relative", margin: "0px" }}
          >
            <p>
              {"This robot, "}
              {rbt.wifi}
              {", is currently online"}
            </p>
            <button className='pdf_popup_close' onClick={() => closeModal()}>
              <FA icon='times' />
            </button>
          </div>
          <div className='common_dashboard_bg' style={{ margin: "0px" }}>
            <div className='row'>
              <div className='col-12'>
                {loadingStatusInfo && (
                  <div className='row ml-3'>
                    <FA color='black' icon='spinner' spin />
                    &nbsp;{"Loading status..."}
                  </div>
                )}

                {!loadingStatusInfo && rbt.online && (
                  <React.Fragment>
                    {rbt && rbt.online && (
                      <div className='d-flex justify-content-between align-items-center'>
                        {getButtonDiv()}

                        <div className='status-text p-1 d-flex align-items-center'>
                          <div>
                            {robotStatusInfo?.message && (
                              <React.Fragment>
                                {"Latest Status:"}
                                <br />
                                {robotStatusInfo.message}
                                {"."}&nbsp;{" ("}
                                {robotStatusAge}
                                {" ago)"}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {rbt &&
                      rbt.online &&
                      robotStatusInfo &&
                      !robotStatusInfo.update_allowed && (
                        <div className='p-1'>
                          {
                            "Updating/Syncing is not allowed when a robot is currently in use, or when an update/sync is already in progress..."
                          }
                        </div>
                      )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default compose(withRouter, withAPI)(OnlineNowModal);
