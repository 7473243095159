import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user
})

const PrivateRoute = ({ component: Component, user, ...rest}) => {
  return <Route {...rest} render={(props) => (
    user !== null
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
}

export default connect(
  mapStateToProps,
)(PrivateRoute);
