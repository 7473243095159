import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import "./UsageStatsRow.scss";

const mapStateToProps = (state, ownProps) => ({
  orgStatus: state.auth.orgStatus,
});

const StatsCircle = ({
  orgStatus,
  getAvgClassNames,
  val,
  type,
  activeChart,
  setActiveChart,
  setInfoText,
  isPercent = true,
}) => {
  const getAvgCaption = (typeOfData) => {
    if (typeOfData === "RobotsPerc") {
      return (
        <span>
          <button
            className='info-circle-btn'
            onClick={() => {
              setInfoText({
                header: "Connected Robots",
                text: `This represents the percentage of robots that have checked-in
                at least once in the last 30 days, out of the total number of robots.`,
              });
            }}
          >
            <FA icon='info-circle' className='info-circle robots_table_hr' />
          </button>
          Connected{" "}
          <Link to={{ pathname: "/robots", state: { from: "usage_stat" } }}>
            Robots
          </Link>
        </span>
      );
    } else if (typeOfData === "RobotsCount") {
      return (
        <span>
          <button
            className='info-circle-btn'
            onClick={() => {
              setInfoText({
                header: "Active Robots",
                text: `This represents the number of robots that submitted data for at least
              one lesson in the last 30 days.`,
              });
            }}
          >
            <FA icon='info-circle' className='info-circle robots_table_hr' />
          </button>
          Active{" "}
          <Link to={{ pathname: "/robots", state: { from: "usage_stat" } }}>
            Robots
          </Link>
        </span>
      );
    } else if (typeOfData === "TeachersPerc") {
      return (
        <span>
          <button
            className='info-circle-btn'
            onClick={() => {
              setInfoText({
                header: "Active Teachers",
                text: `This represents the percentage of teachers who are actively
                using the robots, out of all teachers assigned a robot, 
                in the last 30 days.`,
              });
            }}
          >
            <FA icon='info-circle' className='info-circle robots_table_hr' />
          </button>
          Active{" "}
          <Link
            to={{
              pathname: "/admin/manage-teachers",
              state: { from: "usage_stat" },
            }}
          >
            Teachers
          </Link>
        </span>
      );
    } else if (typeOfData === "LearnersPerc") {
      return (
        <span>
          <button
            className='info-circle-btn'
            onClick={() => {
              setInfoText({
                header: "Active Learners",
                text: `This represents the percentage of learners who 
                have taken at least one lesson in the last 30 days,
                out of all enrolled learners.`,
              });
            }}
          >
            <FA icon='info-circle' className='info-circle robots_table_hr' />
          </button>
          Active{" "}
          <Link
            to={{
              pathname: "/user/all-learners",
              state: { from: "usage_stat" },
            }}
          >
            Learners
          </Link>
        </span>
      );
    } else if (typeOfData === "LearnersCount") {
      return (
        <span>
          <button
            className='info-circle-btn'
            onClick={() => {
              setInfoText({
                header: "Active learners",
                text: `This represents the number of learners who 
                have submitted lesson data in the last 30 days.`,
              });
            }}
          >
            <FA icon='info-circle' className='info-circle robots_table_hr' />
          </button>
          Active learners
        </span>
      );
    } else if (typeOfData === "OrganizationsCount") {
      return (
        <span>
          <button
            className='info-circle-btn'
            onClick={() => {
              setInfoText({
                header: "School organizations with active learners",
                text: `This represents the number of school organizations that
                have at least one robot that has submitted lesson data in the
                last 30 days.`,
              });
            }}
          >
            <FA icon='info-circle' className='info-circle robots_table_hr' />
          </button>
          School{" "}
          <Link
            to={{
              pathname: "/super/orgs-list",
              state: { from: "usage_stat" },
            }}
          >
            organizations
          </Link>{" "}
          with active learners
        </span>
      );
    } else {
      return <span />;
    }
  };

  const getClassName = () => {
    var res = "stats_circle";
    if (isPercent && orgStatus === "active") {
      res += " " + getAvgClassNames(val);
    } else {
      res += " circle-gray";
    }
    if (activeChart === type) {
      res += " -active";
    }
    return res;
  };

  return (
    <div className='circle_container'>
      <div
        className={getClassName()}
        onClick={() => {
          setActiveChart(type);
        }}
      >
        {val}
        {isPercent && "%"}
      </div>
      {getAvgCaption(type)}
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(StatsCircle);
