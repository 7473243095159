import React, {useState} from "react";
import FA from "../../containers/fa";

const PaginationBar = ({
  paginationInfo,
  loading,
  fetchPageFunc,
  pageSize,
  setPageSize,
  showTotal,
}) => {
  // paginationInfo: next, previous, start_index, end_index...
  // fetchPageFunc: should take in pageNum as parameter and go fetch the page
  // loading: bool to disable nav buttons

  const [showOptions, setShowOptions] = useState(false);

  if (!paginationInfo) {
    return null;
  } else {
    return (
      <div className='w-100 mb-1 px-2'>
        <div className='row w-100'>
          <div className='col my-auto'>
            {paginationInfo.previous && (
              <button
                className='my-2 like-link'
                disabled={loading}
                style={{border: "none", backgroundColor: "transparent"}}
                onClick={() => {
                  fetchPageFunc(paginationInfo.previous);
                }}
              >
                Previous Page
              </button>
            )}
          </div>

          <div className='col-6 my-auto text-center'>
            {loading ? (
              <span>
                <FA icon='spinner' spin /> Loading ...
              </span>
            ) : (
              <span>
                Showing results {paginationInfo.start_index} to{" "}
                {paginationInfo.end_index}
                {showTotal !== false && (
                  <span className='ml-1'>(Total: {paginationInfo.count})</span>
                )}
              </span>
            )}
          </div>

          <div className='col pr-0 my-auto'>
            {setPageSize && (
              <button
                className='float-right btn pt-2'
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  padding: "0",
                }}
                onClick={() => {
                  setShowOptions(!showOptions);
                }}
              >
                <FA icon='cog' />
              </button>
            )}
            {paginationInfo.next && (
              <button
                className='like-link my-2 mr-0 float-right'
                disabled={loading}
                style={{border: "none", backgroundColor: "transparent"}}
                onClick={() => {
                  fetchPageFunc(paginationInfo.next);
                }}
              >
                Next Page
              </button>
            )}
          </div>
        </div>

        {setPageSize && showOptions && (
          <div className='row'>
            <div className='offset-9 col-3'>
              Results per page:
              <select
                className='form-control input-lg'
                defaultValue={pageSize || "30"}
                disabled={loading}
                onChange={(e) => {
                  setPageSize(e.target.value);
                  setShowOptions(false);
                }}
              >
                <option value='30'>30</option>
                <option value='60'>60</option>
                <option value='90'>90</option>
              </select>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default PaginationBar;
