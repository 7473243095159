import React, { useState, useEffect } from "react";
import Select from "react-select";

const GroupedLessonsSelect = ({
  groupedLessons,
  changeHandler,
  selectedValue,
}) => {
  let all_options = [];
  groupedLessons.forEach((v) => {
    if (v["options"]) {
      all_options = all_options.concat(v["options"]);
    }
  });

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    fontSize: 16,
    color: "#666",
    fontWeight: "bold",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const customStyles = {
    option: (styles) => {
      return {
        ...styles,
      };
    },
  };

  const formatGroupLabel = (data) => (
    <div
      style={groupStyles}
      onClick={() => {
        setActiveGroup(data.label);
      }}
    >
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  useEffect(() => {
    const defaultSelectedOption = all_options.find(
      (option) => option.value === selectedValue
    );

    if (defaultSelectedOption) {
      changeHandler(defaultSelectedOption.value, defaultSelectedOption.label);
    }
  }, [groupedLessons]);

  const [activeGroup, setActiveGroup] = useState("");

  return (
    <Select
      aria-label='Select a lesson'
      isSearchable={false}
      formatGroupLabel={formatGroupLabel}
      options={groupedLessons}
      styles={customStyles}
      onChange={(selected) => {
        changeHandler(selected.value, selected.label);
      }}
      value={all_options.find((option) => option.value === selectedValue)}
      placeholder='Select a lesson'
    />
  );
};

export default GroupedLessonsSelect;
