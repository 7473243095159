import React from "react";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import withAPI from "../../services/api";
import { compose } from "redux";
import { getAllSchoolInfo, makeSchoolOptions, editableSubset } from "./helpers";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const MultiSchoolReactSelect = ({
  api,
  user,
  handleChange,
  handleBlur,
  value,
  header,
  disabled,
  orgId,
  refreshSchoolList,
  maxMenuHeight = 190,
  autoselect = false,
}) => {
  const [selectableOptions, setSelectableOptions] = useState([]);
  const [fixedOptions, setFixedOptions] = useState([]);
  const [currentSelections, setCurrentSelections] = useState([]);
  const [isSchoolAutoSelected, setIsSchoolAutoSelected] = useState(autoselect);

  const onChange = (selectedOptions) => {
    // Override any fixed options that would be removed by the user taking action
    if (selectedOptions) {
      const ensuredFixedOptions = [
        ...new Set([...fixedOptions, ...selectedOptions]),
      ];
      console.log(
        "modal onChange overriding selected options with:",
        ensuredFixedOptions
      );
      handleChange(ensuredFixedOptions);
    } else {
      handleChange(fixedOptions);
    }
  };

  const [allSchools, setAllSchools] = useState([]);
  useEffect(() => {
    let params = new URLSearchParams();
    if (orgId) {
      params.append("organization", orgId);
    }
    getAllSchoolInfo(api, params).then((allSchoolValues) => {
      setAllSchools(allSchoolValues);
    });
  }, [refreshSchoolList]);

  useEffect(() => {
    if (allSchools.length > 0) {
      // Determine which schools are fixed vs editable
      const allEditableSchools = editableSubset(allSchools, user);
      const selectedEditableSchools = editableSubset(value, user);
      const editableIds = allEditableSchools.map((ou) => ou.id);
      const fixedSchools = value.filter(
        (objOU) => !editableIds.includes(objOU.id)
      );

      // convert school info into Options to pass into the Select component
      const fixedOptions = makeSchoolOptions(fixedSchools, { editable: false });
      const allEditableOptions = makeSchoolOptions(allEditableSchools);
      const selectedEditableOptions = makeSchoolOptions(
        selectedEditableSchools
      );
      const allAvailableOptions = [...fixedOptions, ...allEditableOptions];
      const selectedOptions = [...fixedOptions, ...selectedEditableOptions];

      setSelectableOptions(allAvailableOptions);
      setFixedOptions(fixedOptions);

      // Set default selection if there's only one school
      if (allAvailableOptions.length === 1 && isSchoolAutoSelected) {
        setIsSchoolAutoSelected(false);
        setCurrentSelections(allAvailableOptions);
        onChange(allAvailableOptions);
      } else {
        setCurrentSelections(selectedOptions);
      }
    }
  }, [value, allSchools]);

  return (
    <div className='multi-school-select'>
      <span>{header}</span>
      <Select
        value={currentSelections}
        isMulti
        maxMenuHeight={maxMenuHeight}
        closeMenuOnSelect={false}
        options={selectableOptions}
        onChange={onChange}
        placeholder='Select School(s)'
        aria-label='Select School(s)'
        isDisabled={disabled}
        onBlur={handleBlur}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 1000 }),
        }}
      />
    </div>
  );
};

export default compose(
  connect(mapStateToProps),
  withAPI
)(MultiSchoolReactSelect);
