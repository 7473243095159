import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const AddSchoolModal = ({ user, api, setShowAddSchoolModal, triggerRefreshSchoolList }) => {
  const [spin, setSpin] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [school, setSchool] = useState("");

  const handleSubmit = () => {
    setSpin(true);
    setError(false);
    setSuccess(false);
    let data = { name: school, organization: user.organization.id };
    api
      .createOrgUnit(data)
      .then((resp) => {
        setSpin(false);
        setSuccess(true);
        setSchool("");
        triggerRefreshSchoolList();
      })
      .catch((err) => {
        setSpin(false);
        setError(true);
        console.log("[Error] handleSubmit.CreateSchool", err);
      });
  };

  if (!user.organization) {
    return null;
  }

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Create School</p>
            <ClosePopupBtn closePopupFunc={setShowAddSchoolModal} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && (
                  <div className='alert alert-danger'>
                    Something went wrong... Please try again.
                  </div>
                )}

                {success && (
                  <div className='alert alert-success'>School added!</div>
                )}

                {spin ? (
                  <div className='alert'>
                    <FA color='gray' icon='spinner' spin />
                    Adding new school ...
                  </div>
                ) : (
                  <div>
                    <div className='mb-3'>
                      <TextField
                        placeholder='School Name'
                        type='text'
                        value={school}
                        onChange={(e) => {
                          setSchool(e.target.value);
                        }}
                      />
                    </div>

                    <div className='d-flex'>
                      <button
                        disabled={!school}
                        onClick={() => {
                          handleSubmit();
                        }}
                        className='btn btn-primary mr-4'
                      >
                        Create School
                      </button>
                      <button
                        className='btn btn-danger'
                        onClick={() => {
                          setShowAddSchoolModal(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(AddSchoolModal);
