import moment from "moment";
import config from "./config";
import { useEffect } from "react";

export const versionCompare = (a, b) => {
  const aParts = a.replace("v", "").split(".");
  const bParts = b.replace("v", "").split(".");
  for (var i = 0; i < aParts.length; ++i) {
    if (bParts.length == i) {
      return 1;
    }
    if (aParts[i] == bParts[i]) {
      continue;
    } else if (parseInt(aParts[i]) > parseInt(bParts[i])) {
      return 1;
    } else {
      return -1;
    }
  }
  if (aParts.length != bParts.length) {
    return -1;
  } else {
    return 1;
  }
};

export const sortedArray = (arr, colname, ascending = false, isNum = false) => {
  // console.log('sorting...');
  return [...arr].sort((a, b) => {
    let res;
    if (colname === "organization" || colname === "organization_type") {
      a = a.assignment;
      b = b.assignment;
    } else if (colname === "last_synced_on") {
      // datetime values
      a[colname] = a[colname] ? moment(a[colname]) : null;
      b[colname] = b[colname] ? moment(b[colname]) : null;
    }

    if (a[colname] && b[colname]) {
      if (colname === "code_ver") {
        res = versionCompare(a[colname], b[colname]);
      } else if (isNum || typeof a[colname] === "number") {
        res = a[colname] - b[colname];
      } else if (colname === "last_synced_on") {
        res = a[colname].diff(b[colname]);
      } else {
        res = a[colname].toString().localeCompare(b[colname].toString());
      }
    } else if (
      ["first_name", "last_name", "username", "standard"].indexOf(colname) !==
      -1
    ) {
      if (a.user && b.user) {
        res = a.user[colname]
          .toString()
          .localeCompare(b.user[colname].toString());
      } else if (!a.user && !b.user) {
        res = a[colname].toString().localeCompare(b[colname].toString());
      }
    } else if (a[colname]) {
      res = 1;
    } else if (b[colname]) {
      res = -1;
    } else {
      res = 1;
    }
    // console.log(a[colname], b[colname], res)
    if (ascending === false) {
      return -1 * res;
    } else {
      return res;
    }
  });
};

export const getTotalCount = (objs, prop = "total", roundTo = 0) => {
  // roundTo=0: integer
  const notNulls = objs.filter((item) => item[prop] !== null);
  const sum = notNulls.reduce(function (a, b) {
    return a + parseFloat(b[prop]);
  }, 0);
  const p = Math.pow(10, roundTo);
  return Math.round(sum * p) / p;
};

export const roundValues = (value, roundTo) => {
  if (value === "" || value === "-") {
    return value;
  }
  if (roundTo) {
    const p = Math.pow(10, roundTo);
    return Math.round(value * p) / p;
  } else {
    return value;
  }
};

export const getItemFromArray = (arr, prop, tgt) => {
  // From arr, return the first object with obj.prop === tgt
  //console.log("Searching for x[",prop,"] ===",tgt," in ",arr);
  for (let el of arr) {
    if (el[prop] === tgt) {
      return el;
    }
  }
};

export const getOrgLevelDisplay = (value) => {
  if (value === config.ORG_LEVEL_SCHOOL) {
    return "School-level Organization";
  } else if (value === config.ORG_LEVEL_DISTRICT) {
    return "District-level Organization";
  } else {
    return "N/A";
  }
};

export const getUserRoleLabel = (usertype) => {
  return config.USERROLES.find((role) => role.value === usertype).label;
};

export const timeSince = (date) => {
  //var seconds = Math.floor((new Date() - date) / 1000);
  console.log("timeSince received UTC date:", date.toUTCString());
  console.log("timeSince received local date:", date.toString());
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};

export const truncateStr = (str, charCount) => {
  if (str.length <= charCount) {
    return str;
  }
  return str.slice(0, charCount) + "...";
};

export const debugLog = (...args) => {
  if (config.isDevelopment) {
    console.log(...args);
  }
};

export const openInNewTab = (url) => {
  // Preserves sessionStorage/state in new tab
  const newWindow = window.open(url, "_blank");
  if (newWindow) newWindow.opener = null;
};

export const cleanPlaceholderEmail = (email) => {
  return email.replace(/^(placeholder\+)/, "");
};

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = config.siteTitle + " | " + title;
    return () => {
      document.title = prevTitle;
    };
  });
}

export const arrParams2Str = (key, arr) => {
  // converts from {key: <arrary of items>}
  // to key=item1&key=item2&key=item3&key=item4
  let res = "";
  arr.forEach((item) => {
    res += `${key}=${item}&`;
  });
  res = res.replace(/&$/, "");
  return res;
};

export const sortObjArrByDateTimeStringAsc = (arr, dateTimeProp) => {
  return arr.sort((a, b) => {
    return new Date(a[dateTimeProp]) - new Date(b[dateTimeProp]);
  });
};

export const getDateTimeStringPerGivenFormat = (dateTimeString, format) => {
  return moment.utc(dateTimeString).local().format(format);
};

export function removePrefix(inputString, prefix) {
  if (inputString.startsWith(prefix)) {
    return inputString.slice(prefix.length);
  } else {
    return inputString;
  }
}

export function searchObjectArray(val, prop, array) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][prop] === val) {
      return array[i];
    }
  }
  return null;
}

export function areNonObjectArraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  a.sort();
  b.sort();

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
