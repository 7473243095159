import React from 'react';
import Gate from '../partials/Gate';
import ActivationForm from '../components/ActivationForm';

const ActivateAbii = () => {
  return (
    <Gate>
      <div className="login_box form-content container form-group">
        <div className="row">
          <div className="col">
            <h1 style={{"text-align": "center"}}>Activate Abii</h1>
          </div>
        </div>
        <div className="row">
          <ActivationForm />
        </div>
      </div>
    </Gate>
  );
}

export default ActivateAbii;
