import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import config from '../../common/config';
import withAPI from '../../services/api';
import MainDashboard from '../../partials/Main';
import RobotRow from '../../components/ManageAllRobots/AddRobotRow.js'

import FAW from '../../containers/faw';
import FA from '../../containers/fa';

import './Dashboard.scss';


const AddRobotToTag = ({ api, match }) => {

  const [ready, setReady] = useState(false);
  const [tagInfo, setTagInfo] = useState({});
  const [robots, setRobots] = useState([]);
  const [shouldSpin, setShouldSpin] = useState(true);
  const [error, setError] = useState(null);
  const [robotFilterString, setRobotFilterString] = useState("")
  const [filteredRobots, setFilteredRobots] = useState([])
  const [fetchTrigger, setFetchTrigger] = useState(false)

  useEffect(() => {
    api.fetchRobotTagInfo(match.params.tagId).then((resp) => {
      setTagInfo(resp);
    })
    .catch((err) => {
      setShouldSpin(false);
      setError(err.message)
    });
  }, []);

  useEffect(() => {
    api.fetchAllVisibleRobots({show_all: true}).then((objects) => {
      setRobots(objects.results);
      setReady(true);
      setShouldSpin(false);
    });
  }, [fetchTrigger]);

  useEffect(() => {
    if (robotFilterString === "") {
      setFilteredRobots( robots )
    } else {
      const results = robots.filter(r => r.wifi.includes(robotFilterString))
      setFilteredRobots( results )
    }
  }, [robotFilterString, robots]);

  function triggerListRefresh() {
    setFetchTrigger(!fetchTrigger);
  };


  return (
    <div>
      <MainDashboard>
          <div className="common_border">
            <div className="common_heading d-flex justify-content-between align-items-center">
              <p>Robot Tags > {tagInfo.name} > Add Robots</p>
              <div className="d-flex justify-content-end">
                <label><FA icon="search" /></label>&nbsp;
                <input placeholder="Filter by Wifi name" className="form-control input-underline" value={robotFilterString}
                       onChange={(e) => setRobotFilterString(e.target.value)} />
                <Link className="btn btn-sm btn-primary" to={"/robottag/"+tagInfo.id} >Back</Link>
              </div>
            </div>

            <div className="common_dashboard_bg">
                <div className="row">
                    <div className="col-12">
                        <div className="row mb-10">
                        </div>
                        { error &&
                          <div className="alert alert-danger" role="alert">{error}</div>
                        }
                        {shouldSpin &&
                          <div><h4><FA color="gray" icon="spinner" spin /> Loading Robots...</h4></div>
                        }

                        {!shouldSpin && <div className="card">
                            <div className="progress-linear school_listing manage_learner_table" role="progressbar">
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="datatable-header-cell sortable ng-star-inserted">Wifi name</th>
                                      <th>MAC Address</th>
                                      <th>Serial</th>
                                      <th>Code Version</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {filteredRobots.map((rbt) => (
                                    <RobotRow key={rbt.serial} rbt={rbt} refreshList={triggerListRefresh} />
                                  ))}
                                  </tbody>
                                </table>

                            </div>
                        </div>}
                    </div>
                </div>
            </div>

          </div>

      </MainDashboard>
    </div>
  );

}

export default compose(
  withAPI
)(AddRobotToTag);
