import React, {memo} from "react";

/*
 * DefaultTable is displayed if the fields don't yet meet the
 * requirements to display learner scores.
 */
const DefaultTable = ({ready, groupingLessons, learnersList}) => {
  return (
    <div id='default_table'>
      <div id='scores_table'>
        <div id='learner_scores_rows_container'>
          <div className='labels_row default_table_labels_row'>
            <div className='name learners_label'>Learners</div>
            <div />
            <div className='data average_label default_table_avg_label'>
              <p>Average</p>
            </div>
            <p className='actions' style={{alignSelf: "end"}}>
              Actions
            </p>
          </div>
          <p className='default_table_msg'>
            {!ready || groupingLessons
              ? "Loading..."
              : !learnersList || (learnersList && learnersList.length === 0)
              ? "This class has no learners."
              : "Choose a subject to view learner reports."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(DefaultTable);