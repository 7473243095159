import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import FA from '../containers/fa';
import FAW from '../containers/faw';

import withAPI from '../services/api';

const WiFiRebootModal = ({api}) => {
  const [showSpin, setShowSpin] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();

    setShowSpin(true);
    api.wifiReboot();
    setTimeout(() => {
      document.location.href = "/login";
    }, 3000);
  }
  
   useEffect(() => {
        api.wifireboot();
    }, []);


  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Complete Setup</p>
          </div>
          <div className="reset_pw_modal common_dashboard_bg">
            <div className="row">
              <div className="col-12">
                <div className="form-content form-group">
                  <p>ABii needs to reboot to setup the new WiFi connection. This may take a few minutes, so hold tight.</p>
                  <p>Once ABii starts talking again, you'll need to rejoin ABii's wifi network and navigate back to the login page.</p>
                  {showSpin &&
                    <div>
                      <p><FA color="gray" icon="spinner" spin /> Rebooting...</p>
                    </div>
                  }
                  <div><button disabled={showSpin} onClick={handleClick} className="btn btn-lg fs-14 btn-primary col-md-5 form-control" >Reboot Now</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)}

export default compose(
  withAPI
)(WiFiRebootModal);
