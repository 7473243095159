import config from '../common/config';

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

var instance = null;
const getQSyncerConn = () => {
    const errHandler = () => {
        instance = null;
        sleep(3000).then(() => {
            getQSyncerConn();
        });
    }

    if (!instance) {
        console.log("Initializing QSyncer connection.");
        instance = new WebSocket(config.qsyncerWsUrl);

        instance.onerror = (e) => {
            console.log("QSyncer socket error: ", e);
            console.log("Sleeping then retrying connection.");
            errHandler();
        }
    }

    return instance;
}

export default getQSyncerConn;
