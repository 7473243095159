import {
  LOGIN,
  LOGOUT,
  HOME_REGISTER,
  REGISTER,
  ADD_ROW,
  CLEAR_ROWS,
  DELETE_ROW,
  UPDATE_ROW,
  SHOWSIDEBAR,
  SHOWPASSWORDRESET,
  FORCEPASSWORDRESET,
  LESSONVIEWERTAB,
  UNSYNCED_LESSONS,
  WIFISTATUS,
  SYNC_REFRESHED,
  QSYNCER_SYNCING,
  LAST_SUCCESS_SYNC,
  ROBOT_TYPE,
  STUDENT_COUNT,
  CLASS_ID,
  STUDENT_CAP,
  SECURITY_QUESTION,
  UNSYNCED_LESSONS_BY_LEARNER,
  SYNC_BTN_CLICKED,
  SALE_MODAL_HEADER,
  REFRESH_SALE_DETAIL,
  REFRESH_SUBSCRIPTION_ITEMS,
  HAS_ROBOTS,
  UPDATE_USER_SCHOOLS,
  CLASSES_TO_REFRESH,
  USER_ORG_SETTINGS,
} from "./actionTypes";

export const login = (
  user,
  token,
  hasRobots,
  hasAccounts,
  localBotsOnly,
  forcePWReset,
  orgStatus
) => ({
  type: LOGIN,
  payload: {
    user: user,
    token: token,
    hasRobots: hasRobots,
    hasAccounts: hasAccounts,
    localBotsOnly: localBotsOnly,
    forcePWReset: forcePWReset,
    orgStatus: orgStatus,
  },
});

export const register = (
  email,
  username,
  password,
  hasRobots,
  hasAccounts,
  localBotsOnly,
  forcePWReset,
  orgStatus
) => ({
  type: REGISTER,
  payload: {
    email: email,
    username: username,
    password: password,
    hasRobots: hasRobots,
    hasAccounts: hasAccounts,
    localBotsOnly: localBotsOnly,
    forcePWReset: forcePWReset,
    orgStatus: orgStatus,
  },
});

export const updateHasRobots = (hasRobotsDict) => ({
  type: HAS_ROBOTS,
  payload: hasRobotsDict,
});

export const updateUserSchools = (newSchoolsArray) => ({
  type: UPDATE_USER_SCHOOLS,
  payload: newSchoolsArray,
});

//export const register = (isTeacher, email, username, password) => ({
//  type: REGISTER,
//  payload: {isTeacher, email, username, password}
//});

export const homeRegister = (
  role,
  email,
  username,
  password,
  forcePWReset
) => ({
  type: HOME_REGISTER,
  payload: { role, email, username, password, forcePWReset },
});

export const logout = () => ({
  type: LOGOUT,
});

export const userOrgSettings = (settings) => ({
  type: USER_ORG_SETTINGS,
  payload: settings,
});

export const showSidebar = (shouldShowSidebar) => ({
  type: SHOWSIDEBAR,
  payload: shouldShowSidebar,
});

export const showPasswordReset = (shouldShow) => ({
  type: SHOWPASSWORDRESET,
  payload: shouldShow,
});

export const forcePasswordReset = (force) => ({
  type: FORCEPASSWORDRESET,
  payload: force,
});

export const lessonViewerWindowRef = (windowRef) => ({
  type: LESSONVIEWERTAB,
  payload: windowRef,
});

export const clearNewStudentRows = () => ({ type: CLEAR_ROWS });
export const deleteNewStudentRow = (rowId) => ({
  type: DELETE_ROW,
  payload: { id: rowId },
});
export const addNewStudentRow = (rowId) => ({
  type: ADD_ROW,
  payload: { id: rowId },
});
export const updateNewStudentRow = (rowId, data) => ({
  type: UPDATE_ROW,
  payload: {
    id: rowId,
    coppa: data.coppa,
    name: data.name,
    errors: data.errors,
  },
});

export const unsyncedLessons = (lessonCount) => ({
  type: UNSYNCED_LESSONS,
  payload: lessonCount,
});

export const unsyncedLessonsByLearner = (lessonCountByLearner) => ({
  type: UNSYNCED_LESSONS_BY_LEARNER,
  payload: lessonCountByLearner,
});

export const wifiConnected = (connected) => ({
  type: WIFISTATUS,
  payload: connected,
});

export const syncRefreshed = (refreshed) => ({
  type: SYNC_REFRESHED,
  payload: refreshed,
});

export const qsyncerSyncing = (is_syncing) => ({
  type: QSYNCER_SYNCING,
  payload: is_syncing,
});

export const syncBtnClicked = (is_syncing) => ({
  type: SYNC_BTN_CLICKED,
  payload: is_syncing,
});

export const lastSuccessfulSync = (datetime) => ({
  type: LAST_SUCCESS_SYNC,
  payload: datetime,
});

export const robotType = (type) => ({
  type: ROBOT_TYPE,
  payload: type,
});

export const securityQPending = (isPending) => ({
  type: SECURITY_QUESTION,
  payload: isPending,
});

export const studentCount = (count) => ({
  type: STUDENT_COUNT,
  payload: count,
});

export const homeClassId = (id) => ({
  type: CLASS_ID,
  payload: id,
});

export const studentCap = (number) => ({
  type: STUDENT_CAP,
  payload: number,
});

// cloud super admin
export const saleModalHeader = (data) => ({
  type: SALE_MODAL_HEADER,
  payload: {
    id: data.id,
    orgInfo: data.orgInfo,
    renewSubscriptionIds: data.renewSubscriptionIds,
  },
});

export const refreshSaleDetail = (flag) => ({
  type: REFRESH_SALE_DETAIL,
  payload: flag,
});

export const refreshSubscriptionItems = (flag) => ({
  type: REFRESH_SUBSCRIPTION_ITEMS,
  payload: flag,
});

export const classesToRefresh = (data) => ({
  type: CLASSES_TO_REFRESH,
  payload: {
    action: data.action,
    classes: data.classes,
  },
});

export const setExpiredAndExpiringRobots = (data) => ({
  type: "SET_EXPIRED_AND_EXPIRING_ROBOTS",
  payload: data,
});
export const setHasRenewalsWithoutRobots = (hasRenewalsWithoutRobots) => ({
  type: "SET_HAS_RENEWALS_WITHOUT_ROBOTS",
  payload: hasRenewalsWithoutRobots,
});
