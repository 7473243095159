import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import config from '../../common/config';
import withAPI from '../../services/api';
import MainDashboard from '../../partials/Main';
import TeacherRow from '../../components/TeacherDashboard/AddTeacherRow.js'

import FA from '../../containers/fa';

import './Dashboard.scss';


const AddTeacherToClass = ({ api, match }) => {

  const [ready, setReady] = useState(false);
  const [tagInfo, setTagInfo] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [shouldSpin, setShouldSpin] = useState(true);
  const [error, setError] = useState(null);
  const [filterString, setFilterString] = useState("")
  const [filteredTeachers, setFilteredTeachers] = useState([])
  const [fetchTrigger, setFetchTrigger] = useState(false)
  const [teacherSortType, setTeacherSortType] = useState("username")

  useEffect(() => {
    api.fetchUserTagInfo(match.params.tagId).then((resp) => {
      setTagInfo(resp);
    })
    .catch((err) => {
      setShouldSpin(false);
      setError(err.message)
    });
  }, []);

  useEffect(() => {
    const queryParams = {
      show_all: true,
    }
    api.fetchAllVisibleUsers(queryParams).then((objects) => {
      //const user_arr = objects.filter(usr => usr.userprofile.usertype === config.TEACHER_USERTYPE)
      //const sorted = sortedArray( user_arr, teacherSortType )
      const sorted = sortedArray( objects, teacherSortType )
      console.log("sorted Teachers: ",sorted); 
      setTeachers( sorted );
      setReady(true);
      setShouldSpin(false);
    });
  }, [fetchTrigger]);

  useEffect(() => {
    if (filterString === "") {
      setFilteredTeachers( teachers )
    } else {
      const results = teachers.filter(usr => usr.username.includes(filterString))
      setFilteredTeachers( results )
    }
  }, [filterString, teachers]);

  function triggerListRefresh() {
    setFetchTrigger(!fetchTrigger);
  };

  const sortedArray = (arr, colname) => {
    const sorted = [...arr].sort((a, b) => a[colname].localeCompare(b[colname]));
    return sorted;
  };


  return (
    <div>
      <MainDashboard>
          <div className="common_border">
            <div className="common_heading d-flex justify-content-between align-items-center">
              <p>Classes > {tagInfo.name} > Add Teachers</p>
              <div className="d-flex justify-content-end">
                <label><FA icon="search" /></label>&nbsp;
                <input placeholder="Filter by username" className="form-control input-underline" value={filterString}
                       onChange={(e) => setFilterString(e.target.value)} />
                <Link className="btn btn-sm btn-primary" to={"/user/classes/"+tagInfo.id} >Back</Link>
              </div>
            </div>

            <div className="common_dashboard_bg">
                <div className="row">
                    <div className="col-12">
                        <div className="row mb-10">
                        </div>
                        { error &&
                          <div className="alert alert-danger" role="alert">{error}</div>
                        }
                        {shouldSpin &&
                          <div><h4><FA color="gray" icon="spinner" spin /> Loading Teachers...</h4></div>
                        }

                        {!shouldSpin && <div className="card">
                            <div className="progress-linear school_listing manage_learner_table" role="progressbar">
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="datatable-header-cell sortable ng-star-inserted">First</th>
                                      <th>Last</th>
                                      <th>Username</th>
                                      <th>Add</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {filteredTeachers.map((usr) => (
                                    <TeacherRow key={usr.id} usr={usr} refreshList={triggerListRefresh} />
                                  ))}
                                  </tbody>
                                </table>

                            </div>
                        </div>}
                    </div>
                </div>
            </div>

          </div>

      </MainDashboard>
    </div>
  );


}

export default compose(
  withAPI
)(AddTeacherToClass);


