import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";

const AssignmentDeleteModal = ({
  api,
  shouldShowFunc,
  assignment,
  fetchAssignments,
}) => {
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    api
      .deleteAssignment(assignment.id)
      .then((res) => {
        fetchAssignments();
      })
      .catch((e) => {
        console.log(e);
        setErrMsg(e);
        setError(true);
        setLoading(false);
      });
    shouldShowFunc(false);
    setLoading(false);
    return;
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Delete {assignment.name}</p>
            <button
              className='pdf_popup_close'
              onClick={() => {
                if (loading) {
                  return;
                }
                shouldShowFunc(false);
              }}
            >
              <FA icon='times' />
            </button>
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && <div className='alert alert-danger'>{errMsg}</div>}
                {loading ? (
                  <div>
                    <p>
                      <FA color='gray' icon='spinner' spin /> Deleting class...
                    </p>
                  </div>
                ) : (
                  <p style={{textAlign: "center"}}>
                    Are you sure you want to delete this assignment?
                  </p>
                )}
                <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                  <button
                    style={{width: "fit-content", margin: "auto"}}
                    disabled={loading}
                    onClick={() => shouldShowFunc(false)}
                    className='btn btn-sm btn-primary'
                  >
                    No, take me back
                  </button>
                  <button
                    style={{width: "fit-content", margin: "auto"}}
                    disabled={loading}
                    onClick={handleDelete}
                    className='btn btn-sm btn-danger'
                  >
                    Yes, delete this assignment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, withAPI)(AssignmentDeleteModal);
