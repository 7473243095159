import React from "react";
const ReportsNoClassesErrMsg = ({user, config}) => {
  return (
    <div className='alert alert-warning' style={{margin: "5px"}} role='alert'>
      <b>Note: This feature requires at least one class to be created.</b>
      <br />
      You can create a class by visiting the{" "}
      <a
        href={
          user.usertype === config.ORGADMIN_USERTYPE
            ? "/admin/my-classes/"
            : "/teacher/dashboard"
        }
      >
        Classes
      </a>{" "}
      page and clicking the "Add New Class" button.
    </div>
  );
};

export default ReportsNoClassesErrMsg;
