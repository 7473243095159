import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import AddSchoolModal from "./AddSchoolModal.js";
import AssignRobotModal from "./AssignRobotModal.js";
import AssignSubscriptionModal from "./AssignSubscriptionModal.js";
import MessageModal from "./MessageModal.js";
import MultiSchoolReactSelect from "../InputFields/MultiSchoolReactSelect.js";
import UserRoleSelect from "../InputFields/UserRoleSelect.js";

import config from "../../common/config.js";
import { getUserRoleLabel } from "../../common/utils";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";
import {
  disableSaveBtn,
  emailFieldIsValid,
  getEmailFieldErrMsg,
  getNameFieldErrMsg,
} from "./Utils";
import { searchObjectArray } from "../../common/utils";
import { schoolOptionsToInfo } from "../InputFields/helpers";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  localBotsOnly: state.auth.localBotsOnly,
});

const UserCreateModal = ({
  fetchUsers,
  setShowCreateModal,
  api,
  user,
  localBotsOnly,
}) => {
  const totalSteps = 3;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [newUserErrors, setNewUserErrors] = useState({
    email: null,
    username: null,
    first_name: null,
    last_name: null,
    school: null,
  });

  const [selectedSchool, setSelectedSchool] = useState([]);
  const [userrole, setUserrole] = useState("");
  const defaultNewUser = {
    userprofile: {
      organization: 0,
      orgunits: [],
    },
    email: "",
    first_name: "",
    last_name: "",
  };
  const [newUser, setNewUser] = useState(defaultNewUser);

  const [showAddSchoolModal, setShowAddSchoolModal] = useState(false);
  const [refreshSchoolList, setRefreshSchoolList] = useState(false);
  const [showAssignRobotModal, setShowAssignRobotModal] = useState(false);
  const [assignedRobots, setAssignedRobots] = useState([]);
  const [showAssignSubscriptionModal, setShowAssignSubscriptionModal] =
    useState(false);
  const [assignedSubscriptions, setAssignedSubscriptions] = useState([]);
  const [addAnother, setAddAnother] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSpin, setShowSpin] = useState(false);

  let stepToButtonsMap = {
    1: ["next"],
    2: ["previous", "next"],
    3: ["previous", "finish", "finishAndAdd"],
  };

  // For local only orgs, skip subscription step
  if (localBotsOnly === true) {
    stepToButtonsMap = {
      1: ["next"],
      2: ["previous", "finish", "finishAndAdd"],
    };
  }

  const renderButton = (buttonName) => {
    // given current step and buttonName,
    // determine if we return true or false
    // (to render or not)
    const buttonsToRender = stepToButtonsMap[currentStep];
    return buttonsToRender.includes(buttonName) && !showConfirm;
  };

  const isNextDisabled = () => {
    // given current step,
    // determine if "Next" shall be disabled
    if (currentStep === 1) {
      if (
        disableSaveBtn(newUserErrors) ||
        selectedSchool.length === 0 ||
        userrole === "" ||
        newUser.email === "" ||
        newUser.first_name === "" ||
        newUser.last_name === ""
      ) {
        return true;
      }
    }
    return false;
  };

  const renderCurrentData = () => {
    const userRoleLabel = getUserRoleLabel(userrole);

    // renders a summary of what has been input so far
    return (
      <table className='table table-sm'>
        <tbody>
          <tr>
            <td className='item-label'>Teacher's Name</td>
            <td>
              {newUser.first_name} {newUser.last_name}
            </td>
          </tr>
          <tr>
            <td className='item-label'>School(s)</td>
            <td>{selectedSchool.map((ou) => ou.name).join(", ")}</td>
          </tr>
          <tr>
            <td className='item-label'>User Role</td>
            <td>{userRoleLabel}</td>
          </tr>
          <tr>
            <td className='item-label'>Email</td>
            <td>{newUser.email}</td>
          </tr>
          <tr>
            <td className='item-label'>Robot Assignment</td>
            <td>
              {assignedRobots.length === 0
                ? "None"
                : assignedRobots.slice(0, 2).join(", ")}
              {assignedRobots.length > 2 &&
                `, ... (${assignedRobots.length} total)`}
            </td>
          </tr>
          {localBotsOnly !== true && (
            <tr>
              <td className='item-label'>Subscriptions</td>
              <td>
                {assignedSubscriptions.length === 0
                  ? "None"
                  : assignedSubscriptions
                      .slice(0, 2)
                      .map(
                        (item) =>
                          config.SUBSCRIPTION_TYPE_DISPLAY_NAMES[item.name]
                      )
                      .join(", ")}
                {assignedSubscriptions.length > 2 &&
                  `, ... (${assignedSubscriptions.length} total)`}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const resetForm = () => {
    setNewUser(defaultNewUser);
    setSelectedSchool([]);
    setUserrole("");
    setAssignedRobots([]);
    setAssignedSubscriptions([]);
    setShowConfirm(false);
    setAddAnother(false);
  };

  const handleConfirm = () => {
    // formats and submits whatever data we have in state variables
    // to backend, to create the teacher (with associated robots & subscriptions)
    // TODO: NEEDS TO BE SERVER SIDE
    let tempPwd = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let length = 40;
    for (let i = 0; i < length; i++) {
      tempPwd += chars[Math.floor(Math.random() * chars.length)];
    }
    const postData = {
      userprofile: {
        organization: user.organization.id,
        orgunits: selectedSchool.map((ou) => ou.id),
        usertype: userrole,
      },
      email: newUser.email,
      first_name: newUser.first_name,
      last_name: newUser.last_name,
      password: tempPwd,
      robots: assignedRobots,
      subscriptions: assignedSubscriptions.map(
        (sub) => sub.subscription_type_id
      ),
    };
    setShowSpin(true);
    api
      .createUser(postData)
      .then((objects) => {
        console.log("made new acct");
        fetchUsers();
        if (localBotsOnly === true) {
          setSuccess(`User ${newUser.email} has been successfully created!`);
        } else {
          setSuccess(
            `User ${newUser.email} has been successfully created! Instructions for password setup have been sent to the email address ${newUser.email}.`
          );
        }
        setShowSpin(false);
      })
      .catch((e) => {
        setShowSpin(false);
        console.log(e.message);
        if (e.message.includes("username already exists")) {
          setError(
            "That email is already taken. Please try a different email."
          );
        }
      });
  };

  const clearMessages = () => {
    if (success) {
      if (addAnother) {
        // depending on addAnother, either close form or reset form
        resetForm();
        setCurrentStep(1);
      } else {
        setShowCreateModal(false);
      }
    }
    setError(null);
    setSuccess(null);
  };

  const renderUserRoleHelperText = () => {
    let role = searchObjectArray(userrole, "value", config.USERROLES);
    console.log("UserRole:", role);
    if (role) {
      return <p>{role.info}</p>;
    }
    return null;
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper-large'>
        {showAddSchoolModal && (
          <AddSchoolModal
            setShowAddSchoolModal={setShowAddSchoolModal}
            triggerRefreshSchoolList={() =>
              setRefreshSchoolList(!refreshSchoolList)
            }
          />
        )}

        {showAssignRobotModal && (
          <AssignRobotModal
            setShowModal={setShowAssignRobotModal}
            assignedRobots={assignedRobots}
            setAssignedRobots={setAssignedRobots}
          />
        )}

        {showAssignSubscriptionModal && (
          <AssignSubscriptionModal
            setShowModal={setShowAssignSubscriptionModal}
            assignedSubscriptions={assignedSubscriptions}
            setAssignedSubscriptions={setAssignedSubscriptions}
          />
        )}

        {(error || success) && (
          <MessageModal
            error={error}
            success={success}
            clearMessages={clearMessages}
          />
        )}

        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <p>
              Create User (Step {currentStep} of {totalSteps})
            </p>
            <ClosePopupBtn closePopupFunc={setShowCreateModal} />
          </div>
        </div>

        <div className='common_border'>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {/* Step 1 Starts */}
                {currentStep === 1 && (
                  <table className='table'>
                    <tbody>
                      <tr>
                        <td style={{ width: "300px" }}>
                          <MultiSchoolReactSelect
                            handleChange={(val) => {
                              let schoolInfo = schoolOptionsToInfo(val);
                              setSelectedSchool(schoolInfo);
                            }}
                            value={selectedSchool}
                            refreshSchoolList={refreshSchoolList}
                            autoselect={true}
                          />
                        </td>
                        <td>
                          <UserRoleSelect
                            onChange={(e) => {
                              setUserrole(e.value);
                            }}
                            selected={searchObjectArray(
                              userrole,
                              "value",
                              config.USERROLES
                            )}
                          />
                        </td>
                        <td>
                          <div style={{ "max-width": "375px" }}>
                            {renderUserRoleHelperText()}
                          </div>
                        </td>
                      </tr>
                      {user &&
                        ![
                          config.ORGUNITADMIN_USERTYPE,
                          config.TEACHER_USERTYPE,
                        ].includes(user.usertype) && (
                          <tr>
                            <span
                              className='like-link'
                              onClick={() => setShowAddSchoolModal(true)}
                              style={{ marginLeft: "15px" }}
                            >
                              Create New School?
                            </span>
                          </tr>
                        )}
                      <tr>
                        <td>
                          <input
                            className='form-control input-lg'
                            type='text'
                            name='First name'
                            placeholder='First name'
                            onChange={(e) => {
                              setNewUser({
                                ...newUser,
                                first_name: e.target.value,
                              });
                              let errMsg = getNameFieldErrMsg(e.target.value);
                              if (errMsg !== null) {
                                setNewUserErrors({
                                  ...newUserErrors,
                                  first_name: errMsg,
                                });
                              } else {
                                setNewUserErrors({
                                  ...newUserErrors,
                                  first_name: null,
                                });
                              }
                            }}
                            value={newUser.first_name}
                          />
                          {newUserErrors.first_name && (
                            <span className='admin_settings_err_msg'>
                              {newUserErrors.first_name}
                            </span>
                          )}
                        </td>

                        <td>
                          <input
                            className='form-control input-lg'
                            type='text'
                            name='Last name'
                            placeholder='Last name'
                            onChange={(e) => {
                              setNewUser({
                                ...newUser,
                                last_name: e.target.value,
                              });
                              let errMsg = getNameFieldErrMsg(e.target.value);
                              if (errMsg !== null) {
                                setNewUserErrors({
                                  ...newUserErrors,
                                  last_name: errMsg,
                                });
                              } else {
                                setNewUserErrors({
                                  ...newUserErrors,
                                  last_name: null,
                                });
                              }
                            }}
                            value={newUser.last_name}
                          />
                          {newUserErrors.last_name && (
                            <span className='admin_settings_err_msg'>
                              {newUserErrors.last_name}
                            </span>
                          )}
                        </td>

                        <td colSpan='2'>
                          <input
                            className='form-control input-lg'
                            type='email'
                            name='email'
                            placeholder='Email'
                            onChange={(e) => {
                              setNewUser({ ...newUser, email: e.target.value });

                              if (!emailFieldIsValid(e.target.value)) {
                                setNewUserErrors({
                                  ...newUserErrors,
                                  email: getEmailFieldErrMsg(e.target.value),
                                });
                              } else {
                                setNewUserErrors({
                                  ...newUserErrors,
                                  email: null,
                                });
                              }
                            }}
                            value={newUser.email}
                          />
                          {newUserErrors.email && (
                            <span className='admin_settings_err_msg'>
                              {newUserErrors.email}
                            </span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {/* Step 1 Ends */}

                {/* Step 2 Starts */}
                {currentStep === 2 && (
                  <div>
                    {renderCurrentData()}
                    <div className=''>
                      <span>
                        Do you want to assign <strong>Robots</strong> to this
                        teacher? You can do this later as well.
                      </span>
                      <button
                        className='btn btn-primary btn-sm ml-4'
                        onClick={() => {
                          setShowAssignRobotModal(true);
                        }}
                      >
                        Assign Robots
                      </button>
                    </div>
                  </div>
                )}
                {/* Step 2 Ends */}

                {/* Step 3 Starts */}
                {currentStep === 3 && (
                  <div>
                    {renderCurrentData()}

                    <div className=''>
                      <span>
                        Do you want to assign <strong>Subscriptions</strong> to
                        this teacher? You can do this later as well.
                      </span>
                      <button
                        className='btn btn-primary btn-sm ml-4'
                        onClick={() => {
                          setShowAssignSubscriptionModal(true);
                        }}
                      >
                        Assign Subscriptions
                      </button>
                    </div>
                  </div>
                )}
                {/* Step 3 Ends */}

                {showSpin && (
                  <div className='text-center mt-5 alert'>
                    <FA color='gray' icon='spinner' spin />
                    Processing ...
                  </div>
                )}

                {!showSpin && (
                  <div className='text-center mt-5'>
                    {renderButton("previous") && (
                      <button
                        className='btn btn-md btn-primary admin_settings_btn'
                        onClick={() => {
                          setCurrentStep(currentStep - 1);
                        }}
                      >
                        Go Back
                      </button>
                    )}
                    {renderButton("next") && (
                      <button
                        disabled={isNextDisabled()}
                        className='btn btn-md btn-primary admin_settings_btn'
                        onClick={() => {
                          setCurrentStep(currentStep + 1);
                        }}
                      >
                        Next
                      </button>
                    )}
                    {renderButton("finish") && (
                      <button
                        className='btn btn-md btn-success admin_settings_btn'
                        onClick={() => {
                          setAddAnother(false);
                          setShowConfirm(true);
                        }}
                      >
                        Finish
                      </button>
                    )}
                    {renderButton("finishAndAdd") && (
                      <button
                        className='btn btn-md btn-success admin_settings_btn'
                        onClick={() => {
                          setAddAnother(true);
                          setShowConfirm(true);
                        }}
                      >
                        Finish + Add Another Teacher
                      </button>
                    )}
                    {showConfirm && (
                      <button
                        className='btn btn-md btn-success admin_settings_btn'
                        onClick={handleConfirm}
                      >
                        Confirm:
                        {addAnother
                          ? " Create + Add Another Teacher"
                          : " Create Teacher"}
                      </button>
                    )}
                    <button
                      className={`btn btn-md btn-${
                        showConfirm ? "primary" : "danger"
                      } admin_settings_btn`}
                      onClick={() => {
                        if (showConfirm) {
                          setShowConfirm(false);
                        } else {
                          setShowCreateModal(false);
                        }
                      }}
                    >
                      {showConfirm ? "Make Changes..." : "Cancel"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(UserCreateModal);
