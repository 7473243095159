import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {compose} from "redux";

import withAPI from "../../services/api";
import FA from "../../containers/fa";
import "./RobotSummary.scss";

const RobotSummary = ({api, canExport}) => {
  const [shouldSpin, setShouldSpin] = useState(true);
  const [error, setError] = useState(false);
  const [robotStats, setRobotStats] = useState({});

  useEffect(() => {
    api
      .fetchRobotsSummary()
      .then((res) => {
        console.log("RobotSummary: ", res);
        setRobotStats(res);
        setShouldSpin(false);
      })
      .catch(() => {
        setShouldSpin(false);
        setError(
          "Something went wrong loading Robots summary ... Please try again."
        );
      });
  }, []);

  const handleExportRobots = () => {
    // can become more involved later..
    api.exportRobots();
  };

  return (
    <div className='common_border'>
      <div className='common_heading d-flex justify-content-between align-items-center'>
        <h4>Robot Summary</h4>
        {canExport && (
          <button
            className='btn btn-primary'
            onClick={() => {
              handleExportRobots();
            }}
          >
            Export Robots
          </button>
        )}
      </div>
      <div className='common_dashboard_bg'>
        <div className='row'>
          <div className='col-12'>
            {error && (
              <div className='alert alert-danger' role='alert'>
                {error}
              </div>
            )}
            {shouldSpin && (
              <div>
                <h4>
                  <FA color='gray' icon='spinner' spin /> Loading Robot
                  Summary...
                </h4>
              </div>
            )}
            {!shouldSpin && !error && (
              <div className='table table-striped'>
                <div id='robot_summary_hr'>
                  <p>Software Version</p>
                  <p>Count</p>
                  <p>Percentage</p>
                </div>

                <div
                  className='robot_summary_row'
                  style={{backgroundColor: "rgba(0,0,0,.05)"}}
                >
                  <p>{robotStats.latest.code_ver} or above</p>
                  <p>{robotStats.latest.count}</p>
                  <p>{Math.round(robotStats.latest.perc * 10000) / 100} %</p>
                </div>
                <div className='robot_summary_row'>
                  <p>
                    At or above {robotStats.check_1.code_ver}, below{" "}
                    {robotStats.latest.code_ver}
                  </p>
                  <p>{robotStats.check_1.count}</p>
                  <p>{Math.round(robotStats.check_1.perc * 10000) / 100} %</p>
                </div>
                <div
                  className='robot_summary_row'
                  style={{backgroundColor: "rgba(0,0,0,.05)"}}
                >
                  <p>
                    At or above {robotStats.check_3.code_ver}, below{" "}
                    {robotStats.check_1.code_ver}
                  </p>
                  <p>{robotStats.check_3.count}</p>
                  <p>{Math.round(robotStats.check_3.perc * 10000) / 100} %</p>
                </div>
                <div className='robot_summary_row'>
                  <p>Below {robotStats.oldest.code_ver}</p>
                  <p>{robotStats.oldest.count}</p>
                  <p>{Math.round(robotStats.oldest.perc * 10000) / 100} %</p>
                </div>
                <div
                  className='robot_summary_row'
                  style={{backgroundColor: "rgba(0,0,0,.05)"}}
                >
                  <p>
                    <strong>Total Count</strong>
                  </p>
                  <p>{robotStats.total_count}</p>
                  <p></p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI, withRouter)(RobotSummary);
