import UsageStatsRow from "./UsageStatsRow";
import Chart from "chart.js/auto";
import React, { useEffect, useState } from "react";
import { compose } from "redux";
import withAPI from "../../services/api";
import FA from "../../containers/fa";
import StatsCircle from "./StatsCircle";
import PopupChart from "./PopupChart";
import "./UsageStatsRow.scss";
import ChartDataLabels from "chartjs-plugin-datalabels";
import InfoPopup from "../UtilComps/InfoPopup";
import MultiSchoolReactSelect from "../InputFields/MultiSchoolReactSelect";
import config from "../../common/config";
Chart.register(ChartDataLabels);

const UsageStats = ({
  api,
  history,
  user,
  schoolsFilter,
  setSchoolsFilter,
}) => {
  const [ready, setReady] = useState(false);
  const [data, setData] = useState([{}]);
  const [teacherPercent, setTeacherPercent] = useState(null);
  const [learnerPercent, setLearnerPercent] = useState(null);
  const [robotPercent, setRobotPercent] = useState(null);
  const [robotCount, setRobotCount] = useState(null);
  const [orgsCount, setOrgsCount] = useState(null);
  const [learnersCount, setLearnersCount] = useState(null);
  const [activeChart, setActiveChart] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupChartData, setPopupChartData] = useState(null);
  const [infoText, setInfoText] = useState(null);
  const [chartType, setChartType] = useState("bar");

  useEffect(() => {
    setReady(false);
    let data = null;
    let fetch = null;

    switch (user.usertype) {
      case config.ORGADMIN_USERTYPE:
        setActiveChart("RobotsPerc");

        if (schoolsFilter.length === 1) {
          data = schoolsFilter[0].id;
          fetch = api.fetchUsageStatsForOrgUnit;
        } else if (schoolsFilter.length > 1) {
          data = schoolsFilter.map((school) => school.id);
          fetch = api.fetchHistoricUsageStatsForOrgUnit;
        } else {
          data = user.organization.id;
          fetch = api.fetchUsageStatsForAdmin;
        }
        break;
      case config.SUPERADMIN_USERTYPE:
        setActiveChart("RobotsCount");
        fetch = api.fetchUsageStatsForSuperAdmin;
        break;
      case config.ORGUNITADMIN_USERTYPE:
        setActiveChart("RobotsPerc");

        if (schoolsFilter.length === 1) {
          data = schoolsFilter[0].id;
          fetch = api.fetchUsageStatsForOrgUnit;
        } else if (schoolsFilter.length > 1) {
          data = schoolsFilter.map((school) => school.id);
          fetch = api.fetchHistoricUsageStatsForOrgUnit;
        } else {
          data = user.orgunits.map((orgunit) => orgunit.id);
          if (data.length === 1) {
            data = data[0];
            fetch = api.fetchUsageStatsForOrgUnit;
          } else {
            fetch = api.fetchHistoricUsageStatsForOrgUnit;
          }
        }
        break;
    }

    fetch(data)
      .then((res) => {
        console.log(res);
        setData(res.data);
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [schoolsFilter]);

  useEffect(() => {
    console.log(showPopup);
  }, [showPopup]);

  const getAvgClassNames = (avg) => {
    if (avg && avg >= 75) {
      return "circle circle-green";
    } else if (avg && avg < 75 && avg >= 40) {
      return "circle circle-yellow";
    } else {
      return "circle circle-red";
    }
  };

  const getAvgClassNamesOrgAdmin = (avg) => {
    if (avg && avg >= 75) {
      return "circle circle-green";
    } else if (avg && avg < 75 && avg >= 25) {
      return "circle circle-yellow";
    } else {
      return "circle circle-red";
    }
  };

  const handleSchoolChange = (schools) => {
    if (schools && schools.length > 0) {
      setSchoolsFilter(
        schools.map((option) => ({
          name: option.label,
          id: option.value,
        }))
      );
    } else {
      setSchoolsFilter([]);
    }
  };

  const isOrgOrOrgUnitAdmin =
    user.usertype === config.ORGADMIN_USERTYPE ||
    user.usertype === config.ORGUNITADMIN_USERTYPE;

  return (
    <div className='common_border'>
      <div className='common_heading d-flex justify-content-between align-items-center'>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "50% 50%",
            margin: "0px 15px 0px 15px",
            alignItems: "center",
          }}
        >
          <p>Usage Stats</p>
        </div>
      </div>

      {infoText && (
        <React.Fragment>
          <InfoPopup popupInfo={infoText} setShowPopupInfo={setInfoText} />
        </React.Fragment>
      )}

      <PopupChart
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        chartData={popupChartData}
        activeChart={activeChart}
        chartType={chartType}
        data={data}
      />

      <div className='common_table_padding'>
        <div className='row batch-row'>
          {ready === false && (
            <p>
              <FA color='gray' icon='spinner' spin />
              <span className='ml-2'>Loading Usage Stats ...</span>
            </p>
          )}

          {ready && (
            <div className='col-12'>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <p>Click on a circle to view change over time.</p>
                {isOrgOrOrgUnitAdmin && (
                  <div style={{ width: "500px", marginLeft: "auto" }}>
                    <span>Filter by School: </span>
                    <MultiSchoolReactSelect
                      value={schoolsFilter}
                      handleChange={handleSchoolChange}
                      maxMenuHeight={400}
                    />
                  </div>
                )}
              </div>
              <div id='stats_grid'>
                <div id='circles_container'>
                  {isOrgOrOrgUnitAdmin && (
                    <StatsCircle
                      getAvgClassNames={getAvgClassNamesOrgAdmin}
                      val={robotPercent}
                      type='RobotsPerc'
                      activeChart={activeChart}
                      setActiveChart={setActiveChart}
                      setInfoText={setInfoText}
                    />
                  )}
                  {isOrgOrOrgUnitAdmin && (
                    <StatsCircle
                      getAvgClassNames={getAvgClassNamesOrgAdmin}
                      val={teacherPercent}
                      type='TeachersPerc'
                      activeChart={activeChart}
                      setActiveChart={setActiveChart}
                      setInfoText={setInfoText}
                    />
                  )}

                  {isOrgOrOrgUnitAdmin && (
                    <StatsCircle
                      getAvgClassNames={getAvgClassNamesOrgAdmin}
                      val={learnerPercent}
                      type='LearnersPerc'
                      activeChart={activeChart}
                      setActiveChart={setActiveChart}
                      setInfoText={setInfoText}
                    />
                  )}

                  {user.usertype === "superadmin" && (
                    <StatsCircle
                      getAvgClassNames={getAvgClassNames}
                      val={robotCount}
                      type='RobotsCount'
                      activeChart={activeChart}
                      setActiveChart={setActiveChart}
                      setInfoText={setInfoText}
                      isPercent={false}
                    />
                  )}
                  {user.usertype === "superadmin" && (
                    <StatsCircle
                      getAvgClassNames={getAvgClassNames}
                      val={orgsCount}
                      type='OrganizationsCount'
                      activeChart={activeChart}
                      setActiveChart={setActiveChart}
                      setInfoText={setInfoText}
                      isPercent={false}
                    />
                  )}
                  {user.usertype === "superadmin" && (
                    <StatsCircle
                      getAvgClassNames={getAvgClassNames}
                      val={learnersCount}
                      type='LearnersCount'
                      activeChart={activeChart}
                      setActiveChart={setActiveChart}
                      setInfoText={setInfoText}
                      isPercent={false}
                    />
                  )}
                </div>

                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "fit-content",
                    margin: "auto",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      style={{ margin: "0px 10px 0px 0px", fontWeight: "bold" }}
                    >
                      View as:{" "}
                    </p>
                    <div style={{ marginRight: "15px" }}>
                      <input
                        type='checkbox'
                        style={{ marginRight: "5px" }}
                        checked={chartType === "bar"}
                        id='bar-chart-checkbox'
                        onChange={() => setChartType("bar")}
                      />
                      <label
                        htmlFor='bar-chart-checkbox'
                        style={{ marginBottom: "0px" }}
                      >
                        Bar Chart
                      </label>
                    </div>

                    <div>
                      <input
                        type='checkbox'
                        style={{ marginRight: "5px" }}
                        checked={chartType === "line"}
                        id='line-chart-checkbox'
                        onChange={() => setChartType("line")}
                      />
                      <label
                        htmlFor='line-chart-checkbox'
                        style={{ marginBottom: "0px" }}
                      >
                        Line Chart
                      </label>
                    </div>
                  </div>

                  <button
                    className='org-actions btn btn-sm btn-primary'
                    data-title='View more...'
                    aria-label='View more'
                    onClick={() => {
                      setShowPopup(true);
                    }}
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "25px",
                      zIndex: "1",
                    }}
                  >
                    <FA icon='search' color='white' />
                  </button>

                  {isOrgOrOrgUnitAdmin && (
                    <UsageStatsRow
                      typeOfData='RobotsPerc'
                      data={data}
                      setAvg={setRobotPercent}
                      activeChart={activeChart}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      setPopupChartData={setPopupChartData}
                      chartType={chartType}
                    />
                  )}
                  {isOrgOrOrgUnitAdmin && (
                    <UsageStatsRow
                      typeOfData='TeachersPerc'
                      data={data}
                      setAvg={setTeacherPercent}
                      activeChart={activeChart}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      setPopupChartData={setPopupChartData}
                      chartType={chartType}
                    />
                  )}
                  {isOrgOrOrgUnitAdmin && (
                    <UsageStatsRow
                      typeOfData='LearnersPerc'
                      data={data}
                      setAvg={setLearnerPercent}
                      activeChart={activeChart}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      setPopupChartData={setPopupChartData}
                      chartType={chartType}
                    />
                  )}

                  {user.usertype === "superadmin" && (
                    <UsageStatsRow
                      typeOfData='RobotsCount'
                      data={data}
                      setAvg={setRobotCount}
                      activeChart={activeChart}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      setPopupChartData={setPopupChartData}
                      isPercent={false}
                      chartType={chartType}
                    />
                  )}
                  {user.usertype === "superadmin" && (
                    <UsageStatsRow
                      typeOfData='OrganizationsCount'
                      data={data}
                      setAvg={setOrgsCount}
                      activeChart={activeChart}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      setPopupChartData={setPopupChartData}
                      isPercent={false}
                      chartType={chartType}
                    />
                  )}
                  {user.usertype === "superadmin" && (
                    <UsageStatsRow
                      typeOfData='LearnersCount'
                      data={data}
                      setAvg={setLearnersCount}
                      activeChart={activeChart}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      setPopupChartData={setPopupChartData}
                      isPercent={false}
                      chartType={chartType}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='row batch-row'>
          <div className='col-md-6 text-center'></div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI)(UsageStats);
