import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import withAPI from '../../services/api';
import FA from '../../containers/fa';
import MainDashboard from '../../partials/Main';
import SyncButton from '../../components/TeacherDashboard/SyncButton';
import config from '../../common/config';

import './SyncHistory.css'


const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  unsyncedLessons: state.status.unsyncedLessons,
  wifiConnected: state.status.wifiConnected,
  syncRefreshed: state.status.syncRefreshed,
  qsyncerSyncing: state.status.qsyncerSyncing,
});

const SyncHistory = ({ api, unsyncedLessons, wifiConnected, syncRefreshed, qsyncerSyncing}) => {
  const [syncEvents, setSyncEvents] = useState([]);
  const [shouldSpin, setShouldSpin] = useState(false);

  const syncHandlerCB = (event) => {
    const allData = JSON.parse(event.data);
    if (allData.event_type !== 'SYNC_EVENTS') {
      return
    }
    setShouldSpin(false);
    const eventType = allData.event_type
    const jsonData = allData.data
    if (jsonData.length > 0) {
      console.log("DATA:", jsonData);
      setSyncEvents(jsonData);
    }
  }

  useEffect(() => {
    if (!qsyncerSyncing) {
      setShouldSpin(true);
      api.qsyncerResolveWhenReady().then(() => {
        api.registerSyncHandler(syncHandlerCB);
        api.fetchSyncEvents();
      });
    }
    return () => {
      api.unregisterSyncHandler(syncHandlerCB);
    }
  }, [syncRefreshed, qsyncerSyncing]);


  const getLastSyncStatusDiv = () => {
    return(
      <div id="sync-status-wrapper">
        <h4 className="">Unsynced Sessions: {unsyncedLessons}</h4>

        {unsyncedLessons === 0 ? 
          <p className="update-low">Your data is all synced. <br/> ABii is good to go!</p>
          : null
        }

        {wifiConnected && unsyncedLessons > 0 && unsyncedLessons <= config.SYNC_THRESHOLDS.low ?
          <p className="update-low">ABii has unsynced data! <br/> You can sync your data by clicking on the Sync button above.</p>
          : null
        }

        {!wifiConnected && unsyncedLessons > 0 && unsyncedLessons <= config.SYNC_THRESHOLDS.low ?
          <p className="update-low">ABii has unsynced data! <br/> Set up wifi <Link to="/setup-wifi">here</Link> in order to sync your data.</p>
          : null
        }

        {wifiConnected && unsyncedLessons > config.SYNC_THRESHOLDS.low && unsyncedLessons <= config.SYNC_THRESHOLDS.mid ?
          <p className="update-mid">ABii's memory is filling up! <br/> Click on the Sync button above to sync now to free up some space.</p>
          : null
        }

        {!wifiConnected && unsyncedLessons > config.SYNC_THRESHOLDS.low && unsyncedLessons <= config.SYNC_THRESHOLDS.mid ?
          <p className="update-mid">ABii's memory is filling up! <br/> Set up wifi <Link to="/setup-wifi">here</Link> in order to sync to free up some space.</p>
          : null
        }

        {wifiConnected && unsyncedLessons > config.SYNC_THRESHOLDS.mid && unsyncedLessons <= config.SYNC_THRESHOLDS.high ?
          <p className="update-high">ABii's memory is almost full. <br/> Click on the Sync button above to sync now to make sure you can launch lessons!</p>
          : null
        }

        {!wifiConnected && unsyncedLessons > config.SYNC_THRESHOLDS.mid && unsyncedLessons <= config.SYNC_THRESHOLDS.high ?
          <p className="update-high">ABii's memory is almost full. <br/> Set up wifi <Link to="/setup-wifi">here</Link> now to make sure you can sync and launch lessons!</p>
          : null
        }

        {wifiConnected && unsyncedLessons > config.SYNC_THRESHOLDS.high ?
          <p className="update-high">ABii's memory is FULL! <br/> You must sync your data before you can launch lessons. <br/> Simply click on the Sync button above to perform a Sync.</p>
          : null
        }

        {!wifiConnected && unsyncedLessons > config.SYNC_THRESHOLDS.high ?
          <p className="update-high">ABii's memory is FULL. <br/> You must set up wifi <Link to="/setup-wifi">here</Link> to sync your data <br/> before you can launch new lessons.</p>
          : null
        }

      </div>
    )
  }
  // end getLastSyncStatusDiv


  return (
    <MainDashboard>
      <div className="common_border">
        <div className="common_heading d-flex justify-content-between align-items-center">
            <p>Sync Status</p>
        </div>
      </div>
      <div className="common_table_padding">
        <div>
          {qsyncerSyncing &&
            <div>
              <h4>ABii is currently syncing...</h4>
            </div>
          }

          {!qsyncerSyncing &&
            <div>
              <SyncButton
                displayStyle="full"
              />
              {getLastSyncStatusDiv()}
            </div>
          }
        </div>
      </div>

      <div className="common_border">
        <div className="common_heading d-flex justify-content-between align-items-center">
            <p>Sync History</p>
        </div>
      </div>
      <div className="common_table_padding">
      {(shouldSpin || qsyncerSyncing) &&
        <div><h4><FA color="gray" icon="spinner" spin /> Loading Sync History...</h4></div>
      }
        {!(shouldSpin||qsyncerSyncing) && <div className="row batch-row">
          <div className="progress-linear" id="sync-hist-wrapper">
            <table className="table table-striped" id="sync-history-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Status</th>
                  <th>Data Type</th>
                  <th>Push or Pull</th>
                </tr>
              </thead>
              <tbody>
                {syncEvents.map((x) => {
                  return (
                    <tr key={x.id}>
                      <td>{new Date(x.datetime).toDateString()}</td>
                      <td>{new Date(x.datetime).toLocaleTimeString()}</td>
                      <td>{x.synced
                        ? <FA icon="check" color="green" />
                        : <FA icon="times" color="red" />}</td>
                      <td>{x.sync_type}</td>
                      <td>{x.is_push
                        ? <FA icon="upload" />
                        : <FA icon="download" />
                      }</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* <p className="bold_font text-center">No data to sync.</p> */}

          </div>
        </div> }
      </div>
    </MainDashboard>
  );
}

export default compose(
  connect(mapStateToProps),
  withAPI,
)(SyncHistory);