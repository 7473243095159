import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React, {useState, useEffect} from "react";
import {compose} from "redux";
import moment from "moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import StateSelector from "../InputFields/StateSelector";
import withAPI from "../../services/api";
import FA from "../../containers/fa";

const OrgInfoRow = ({
  fieldName,
  label,
  value,
  patchAPI,
  instanceId,
  canEdit,
  refreshParent,
  settingParams,
}) => {
  const [saveSpin, setSaveSpin] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editableValue, setEditableValue] = useState(value);
  const [permValue, setPermValue] = useState(value);

  useEffect(() => {
    setPermValue(value);
    console.log(
      "OrgInfoRow received settingParams:",
      settingParams,
      " and value:",
      value
    );
  }, [value]);

  function mapValue(val) {
    if (val === true) {
      return "Yes";
    } else if (val === false) {
      return "No";
    } else if (val instanceof Array) {
      return val.join(", ");
    } else {
      return val;
    }
  }

  const handlePatch = () => {
    setSaveSpin(true);
    let data = null;
    if (settingParams) {
      if (editableValue === "None") {
        data = {settings: {[fieldName]: null}};
      } else {
        data = {settings: {[fieldName]: editableValue}};
      }
    } else {
      data = {[fieldName]: editableValue};
    }
    console.log("patchData:", data);
    patchAPI(instanceId, data)
      .then((resp) => {
        if (["state", "district"].includes(fieldName) && refreshParent) {
          refreshParent();
        }
        setSaveSpin(false);
        setEditable(false);
        setPermValue(editableValue);
        console.log("API returned", resp);
      })
      .catch((err) => {
        setSaveSpin(false);
        console.log("API error'd", err);
      });
  };

  function currentChoice() {
    return (
      <option key={value} value={value}>
        {value}
      </option>
    );
  }

  function otherChoices() {
    let unselectedChoiceValues = settingParams.choices.filter(
      (v) => v !== value
    );
    return unselectedChoiceValues.map((val) => (
      <option key={val} value={val}>
        {val}
      </option>
    ));
  }

  function renderChoices() {
    return (
      <select
        className=''
        onChange={(e) => {
          setEditableValue(e.target.value);
        }}
      >
        {currentChoice()}
        {value && settingParams.nullValAllowed && (
          <option key={-1} value={null}>
            None
          </option>
        )}
        {otherChoices()}
      </select>
    );
  }

  const handleMultipleChoiceSelection = (event) => {
    console.log(event);
    event.persist();
    setEditableValue(event.target.value);
  };

  function renderMultipleChoice() {
    return (
      <TextField
        select
        style={{width: "150px"}}
        variant='outlined'
        name={settingParams.name}
        id={settingParams.name}
        label={label}
        SelectProps={{
          multiple: true,
          value: editableValue || [],
          onChange: handleMultipleChoiceSelection,
        }}
      >
        {settingParams.choices.map((choice) => {
          console.log("rendering choice:", choice);
          return <MenuItem value={choice}>{choice}</MenuItem>;
        })}
      </TextField>
    );
  }

  function renderCheckbox() {
    console.log("Rendering checkbox, editableValue:", editableValue);
    return (
      <input
        type='checkbox'
        checked={editableValue}
        onChange={(e) => {
          setEditableValue(!editableValue);
        }}
      />
    );
  }

  function renderEditableSetting() {
    if (settingParams.type === "choice") {
      return renderChoices();
    } else if (settingParams.type === "boolean") {
      return renderCheckbox();
    } else if (settingParams.type === "multipleChoice") {
      return renderMultipleChoice();
    } else {
      return null;
    }
  }

  return editable ? (
    <tr key={label}>
      <td>
        <span>{label}</span>
      </td>
      <td>
        {settingParams && renderEditableSetting()}

        {!settingParams && (
          <React.Fragment>
            {fieldName === "state" ? (
              <StateSelector
                value={editableValue}
                onChangeHandle={(e) => setEditableValue(e.target.value)}
              />
            ) : fieldName === "activated_at" ? (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  value={editableValue}
                  onChange={setEditableValue}
                  style={{marginTop: "16px"}}
                />
              </MuiPickersUtilsProvider>
            ) : (
              <input
                type='text'
                placeholder={permValue}
                value={editableValue}
                onChange={(e) => setEditableValue(e.target.value)}
              />
            )}
          </React.Fragment>
        )}
      </td>
      <td>
        {saveSpin ? (
          <button className='btn btn-sm btn-secondary mr-2'>
            <FA color='white' icon='spinner' spin />
          </button>
        ) : (
          <button
            className='btn btn-sm btn-primary mr-2'
            onClick={() => handlePatch()}
          >
            <FA color='white' icon='save' />
          </button>
        )}
        <button
          className='btn btn-sm btn-danger'
          onClick={() => setEditable(false)}
        >
          <FA color='white' icon='times' />
        </button>
      </td>
    </tr>
  ) : (
    <tr key={label}>
      <td>
        <span style={{textTransform: "capitalize"}}>{label}</span>
      </td>
      <td>
        <span>
          {fieldName === "activated_at"
            ? permValue
              ? moment.utc(permValue).local().format("MMMM Do YYYY")
              : "-"
            : mapValue(permValue) || "-"}
        </span>
      </td>
      <td>
        {canEdit && (
          <button
            className='btn btn-sm btn-primary'
            onClick={() => setEditable(true)}
          >
            <FA color='white' icon='edit' />
          </button>
        )}
      </td>
    </tr>
  );
};

export default compose(withAPI)(OrgInfoRow);
