import React from "react";
import "./CirclePageIndicators.scss";

const CirclePageIndicators = ({currentPage}) => {
  return (
    <div id='circle_page_indicator_container' className="visible-scrollbar">
      <div id='circles_and_lines'>
        <div
          className={`create_assignment_circle circle_1 ${
            currentPage === 0 && "current_page"
          }`}
        ></div>
        <hr />
        <div
          className={`create_assignment_circle circle_2 ${
            currentPage === 1 && "current_page"
          }`}
        ></div>
        <hr />
        <div
          className={`create_assignment_circle circle_3 ${
            currentPage === 2 && "current_page"
          }`}
        ></div>
        <hr />
        <div
          className={`create_assignment_circle circle_4 ${
            currentPage === 3 && "current_page"
          }`}
        ></div>

        <p style={{gridArea: "e"}}>Select Lessons</p>
        <p style={{gridArea: "f"}}>Select Learners</p>
        <p style={{gridArea: "g"}}>Name & Date</p>
        <p style={{gridArea: "h"}}>Review & Submit</p>
      </div>
    </div>
  );
};

export default CirclePageIndicators;
