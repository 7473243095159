import React, {useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";

import {
  showPasswordReset as showPasswordResetAction,
  forcePasswordReset as forcePasswordResetAction,
} from "../redux/actions";
import withAPI from "../services/api";
import Gate from "../partials/Gate";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  forcePasswordReset: state.gui.forcePasswordReset,
});

const StandalonePasswordReset = ({
  api,
  dispatch,
  history,
  shouldShowFunc,
  forcePasswordReset,
  clsId,
}) => {
  const [pw1, setPW1] = useState("");
  const [pw2, setPW2] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [initialForcePasswordReset, setInitialForcePasswordReset] =
    useState(forcePasswordReset);

  const handleRename = () => {
    setError(null);

    if (pw1 === "" || pw2 === "") {
      setError("Password cannot be blank.");
      return;
    } else if (pw1.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    } else if (pw1 !== pw2) {
      setError("Passwords must match.");
      return;
    }

    // api.resetTeacherPassword(pw1, pw2).then(() => {
    api
      .resetUserPassword(pw1, pw2)
      .then(() => {
        setSuccess(true);
        setError("");
        dispatch(forcePasswordResetAction(false));
      })
      .catch((e) => {
        setSuccess(false);
        // Rough way to parse ValidationError message
        let err = e.message.split(":");
        let arr = err[err.length - 1].split(",");
        if (arr.length > 0) {
          setError(
            <React.Fragment>
              {arr.map((val) => (
                <React.Fragment>
                  {val}
                  <br />
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        } else {
          setError("Something went wrong resetting your password.");
        }
      });
  };
  return (
    <Gate>
      <div className='login_box'>
        <div className='form-group'>
          <h1>Set New Password</h1>
          <div className='form-content form-group container'>
            <div className='row'>
              {!error && !success && (
                <React.Fragment>
                  Your password must contain:
                  <ul>
                    <li>at least 8 characters</li>
                    <li>at least one lowercase letter, a-z</li>
                    <li>at least one uppercase letter, A-Z</li>
                    <li>at least one number, 0-9</li>
                  </ul>
                </React.Fragment>
              )}
            </div>
            <div className='row'>
              {error && <div className='alert alert-danger'>{error}</div>}
              {success && (
                <div className='alert alert-success'>
                  Successfully reset password.
                </div>
              )}
            </div>
            <div className='row'>
              <input
                className='form-control'
                type='password'
                placeholder='Password'
                autoComplete="new-password"
                value={pw1}
                onChange={(e) => setPW1(e.target.value)}
              />
            </div>
            <div className='row'>
              <input
                className='form-control'
                type='password'
                placeholder='Password (Again)'
                autoComplete="new-password"
                value={pw2}
                onChange={(e) => setPW2(e.target.value)}
              />
            </div>
            {success !== true && (
              <div className='row'>
                <button
                  onClick={handleRename}
                  className='btn btn-lg fs-14 btn-primary form-control'
                >
                  Save
                </button>
              </div>
            )}
            {success && (
              <div className='row'>
                <button
                  onClick={() => history.push("/")}
                  className='btn btn-lg fs-14 btn-primary form-control'
                >
                  Next &raquo;
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Gate>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(StandalonePasswordReset);
