import React, { useEffect, useState } from "react";
import { compose } from "redux";
import config from "../../common/config.js";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const AssignSubscriptionModal = ({
  api,
  setShowModal,
  assignedSubscriptions,
  setAssignedSubscriptions,
  assignUserId,
  existingSubscriptions,
  refreshList,
}) => {
  const [spin, setSpin] = useState(true);
  const [submitSpin, setSubmitSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState(
    assignedSubscriptions || []
  );
  const [selectedSubscriptions_ids, setSelectedSubscriptions_ids] = useState(
    []
  );

  /* If necessary, pull school-level object lists/max counts from Subscription
   * Info to display school-level LCT subscription info
   */
  const [orgLevelSubInfo, setOrgLevelSubInfo] = useState({});
  const [subAssignmentInfo, setSubAssignmentInfo] = useState({});
  useEffect(() => {
    if (subscriptions) {
      const adjustedSubInfo = subscriptions.map(subInfo => {
        if (subInfo.name === 'lct' && subInfo.max_objects_in_schools) {
          return {
            ...subInfo,
            object_list: subInfo.object_list_in_schools,
            max_objects: subInfo.max_objects_in_schools,
            used_slots: subInfo.used_slots_in_schools,
            remaining_slots: subInfo.max_objects_in_schools - subInfo.used_slots_in_schools,
          };
        }
        return subInfo;
      });
      setSubAssignmentInfo(adjustedSubInfo);
      let newSelected = adjustedSubInfo.filter((sub) => {
        if (existingSubscriptions == null) return false;

        for (let i = 0; i < existingSubscriptions.length; i++) {
          if (existingSubscriptions[i][0] === sub.name) return true;
        }
        return false;
      });
      setSelectedSubscriptions(newSelected);
    }
  }, [subscriptions]);

  useEffect(() => {
    console.log("Got existingSubscriptions for user:", existingSubscriptions);
    const queryParams = {
      obj_type: "userprofile",
      detail_view: true,
    };

    api
      .fetchSubscriptionsTypesInfo(queryParams)
      .then((objects) => {
        console.log("subscriptionsTypesInfo:", objects.data);
        setSubscriptions(objects.data);
        setSpin(false);
      })
      .catch((e) => {
        console.error(e);
        setError("An error occurred. Please try again later.");
        setSpin(false);
      });
  }, []);

  useEffect(() => {
    setSelectedSubscriptions_ids(
      selectedSubscriptions.map((item) => item.subscription_type_id)
    );
  }, [selectedSubscriptions]);

  const handleCheckboxChange = (subObj) => {
    // given a subObj subscription obj
    // add or remove subObj from selectedSubscriptions
    const selectedSubscriptions_tmp = [...selectedSubscriptions];
    if (!selectedSubscriptions_ids.includes(subObj.subscription_type_id)) {
      selectedSubscriptions_tmp.push(subObj);
    } else {
      selectedSubscriptions_tmp.splice(
        selectedSubscriptions_ids.indexOf(subObj.subscription_type_id),
        1
      );
    }
    setSelectedSubscriptions(selectedSubscriptions_tmp);
  };

  const handleSubmitToParent = () => {
    setAssignedSubscriptions(selectedSubscriptions);
    setShowModal(false);
  };

  const checkForIdInExistingSubs = (sub) => {
    if (existingSubscriptions == null) return false;

    for (let i = 0; i < existingSubscriptions.length; i++) {
      if (existingSubscriptions[i].indexOf(sub.subscription_type_id)) {
        return true;
      }
    }
    return false;
  };

  const handleSubmitToBackend = () => {
    setError("");
    setSubmitSpin(true);

    let subsToAdd = [];
    let subsToRemove = [];
    for (let i = 0; i < subscriptions.length; i++) {
      if (
        selectedSubscriptions.indexOf(subAssignmentInfo[i]) !== -1 &&
        !checkForIdInExistingSubs(subAssignmentInfo[i])
      ) {
        // sub was selected but not existing. Need to add.
        subsToAdd = [
          ...subsToAdd,
          (subscriptions[i].name, subscriptions[i].subscription_type_id),
        ];
      } else if (
        selectedSubscriptions.indexOf(subAssignmentInfo[i]) === -1 &&
        checkForIdInExistingSubs(subAssignmentInfo[i])
      ) {
        // sub was deselected but existing. Need to remove.
        subsToRemove = [
          ...subsToRemove,
          (subscriptions[i].name, subscriptions[i].subscription_type_id),
        ];
      }
    }

    let data = {
      user_id: assignUserId,
      add: subsToAdd,
      remove: subsToRemove,
    };

    api
      .manageUserSubscriptions(data)
      .then((res) => {
        setSubmitSpin(false);
        setSuccess(true);
        refreshList();
      })
      .catch((e) => {
        setSubmitSpin(false);
        setError("An error occurred. Please try again later.");
        console.log(e);
      });
  };

  const handleSubmit = () => {
    if (assignUserId) {
      handleSubmitToBackend();
    } else {
      handleSubmitToParent();
    }
  };

  const renderSlotsRemaining = (sub) => {
    if (
      /* object in existing sub, but box has been un-selected */
      selectedSubscriptions.indexOf(sub) === -1 &&
      checkForIdInExistingSubs(sub) 
    ) {
      return (
        <span>
          {sub.remaining_slots + 1} out of{" "}
          {sub.max_objects}
        </span>
      );
    } else if (
      /* object not in existing sub, but box is selected */
      selectedSubscriptions.indexOf(sub) !== -1 &&
      !checkForIdInExistingSubs(sub)
    ) {
      return (
        <span>
          {sub.remaining_slots - 1} out of{" "}
          {sub.max_objects}
        </span>
      );
    } else {
      return (
        <span>
          {sub.remaining_slots} out of{" "}
          {sub.max_objects}
        </span>
      );
    }
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper-medium'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Assign Subscriptions</p>
            <ClosePopupBtn closePopupFunc={setShowModal} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && <div className='alert alert-danger'>{error}</div>}

                {success && (
                  <div className='alert alert-success'>
                    Subscription assignment updated successfully!
                  </div>
                )}

                {spin || subscriptions === null ? (
                  <div className='alert'>
                    <FA color='gray' icon='spinner' spin />
                    Loading subscriptions ...
                  </div>
                ) : (
                  <div>
                    <div className='mb-3 table-scroll-wrapper'>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th>Subscription Type</th>
                            <th>Remaining Allowances</th>
                            <th>Assign</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subAssignmentInfo.map((sub, index) => (
                            <tr key={index}>
                              <td>
                                {
                                  config.SUBSCRIPTION_TYPE_DISPLAY_NAMES[
                                    sub.name
                                  ]
                                }
                              </td>
                              <td>
                                {renderSlotsRemaining(sub)}
                              </td>
                              <td>
                                <input
                                  type='checkbox'
                                  name='rbt-checkbox'
                                  className='form-control'
                                  defaultChecked={checkForIdInExistingSubs(sub)}
                                  onChange={(e) => {
                                    if (
                                      e.target.checked &&
                                      sub.remaining_slots <= 0 &&
                                      !checkForIdInExistingSubs(sub)
                                    ) {
                                      e.target.checked = false;
                                      setError("No remaining slots available.");
                                      return;
                                    }
                                    handleCheckboxChange(sub);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {submitSpin && (
                      <div className='alert'>
                        <FA color='gray' icon='spinner' spin />
                        Assigning subscriptions ...
                      </div>
                    )}

                    {!submitSpin && !success && (
                      <div className='d-flex'>
                        <button
                          onClick={() => {
                            handleSubmit();
                          }}
                          className='btn btn-primary mr-4'
                        >
                          Confirm
                        </button>
                        <button
                          className='btn btn-danger'
                          onClick={() => {
                            setShowModal(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {success && (
                      <button
                        className='btn btn-success'
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        Okay
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI)(AssignSubscriptionModal);
