import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import config from "../../common/config";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});

const AssignmentSyncReminder = ({ user, shouldShowFunc }) => {
  const isOrgOrOrgUnitAdmin =
    user.usertype === config.ORGADMIN_USERTYPE ||
    user.usertype === config.ORGUNITADMIN_USERTYPE;
  const myRobotUrl =
    user && isOrgOrOrgUnitAdmin ? "/admin/my-robots" : "/robots";
  const myRobotPageName = user && isOrgOrOrgUnitAdmin ? "My Robots" : "Robots";

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Assignment Saved</p>
            <ClosePopupBtn closePopupFunc={shouldShowFunc} />
          </div>

          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <p>Successfully saved the assignment!</p>
            <p>
              <b>
                Remember to sync your robots if your latest changes impact
                active assignments.{" "}
              </b>
              You can initiate a sync from the Robots page for robots that are
              online.
            </p>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <Link to={myRobotUrl} className='btn btn-primary btn-sm'>
                {`Go to ${myRobotPageName} page`}
              </Link>
              <button
                style={{ minWidth: "100px", margin: "auto" }}
                onClick={() => {
                  shouldShowFunc(false);
                }}
                className='btn btn-sm btn-primary'
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps))(AssignmentSyncReminder);
