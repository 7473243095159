// Auth stuff
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const HOME_REGISTER = "HOME_REGISTER";
export const HAS_ROBOTS = "HAS_ROBOTS";
// Organization-level settings
export const USER_ORG_SETTINGS = "USER_ORG_SETTINGS";
export const UPDATE_USER_SCHOOLS = "UPDATE_USER_SCHOOLS";

// New learner stuff
export const ADD_ROW = "ADD_ROW";
export const CLEAR_ROWS = "CLEAR_ROWS";
export const DELETE_ROW = "DELETE_ROW";
export const UPDATE_ROW = "UPDATE_ROW";

// General GUI state stuff
export const SHOWSIDEBAR = "SHOWSIDEBAR";
export const SHOWPASSWORDRESET = "SHOWPASSWORDRESET";
export const FORCEPASSWORDRESET = "FORCEPASSWORDRESET";
export const LESSONVIEWERTAB = "LESSONVIEWERTAB";

// System Status
export const WIFISTATUS = "WIFISTATUS";
export const UNSYNCED_LESSONS = "UNSYNCED_LESSONS";
export const UNSYNCED_LESSONS_BY_LEARNER = "UNSYNCED_LESSONS_BY_LEARNER";
export const SYNC_REFRESHED = "SYNC_REFRESHED";
export const QSYNCER_SYNCING = "QSYNCER_SYNCING";
export const LAST_SUCCESS_SYNC = "LAST_SUCCESS_SYNC";
export const ROBOT_TYPE = "ROBOT_TYPE";
export const SECURITY_QUESTION = "SECURITY_QUESTION";
export const SYNC_BTN_CLICKED = "SYNC_BTN_CLICKED";

// Home Version
export const STUDENT_COUNT = "STUDENT_COUNT";
export const CLASS_ID = "CLASS_ID";
export const STUDENT_CAP = "STUDENT_CAP";

// Cloud Super Admin
export const SALE_MODAL_HEADER = "SALE_MODAL_HEADER";
export const REFRESH_SALE_DETAIL = "REFRESH_SALE_DETAIL";
export const REFRESH_SUBSCRIPTION_ITEMS = "REFRESH_SUBSCRIPTION_ITEMS";

// Cloud Dashboards
export const CLASSES_TO_REFRESH = "CLASSES_TO_REFRESH";

export const SET_EXPIRED_AND_EXPIRING_ROBOTS =
  "SET_EXPIRED_AND_EXPIRING_ROBOTS";
export const SET_HAS_RENEWALS_WITHOUT_ROBOTS =
  "SET_HAS_RENEWALS_WITHOUT_ROBOTS";
