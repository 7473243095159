import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withAPI from '../../services/api';
import FAW from '../../containers/faw';
import FA from '../../containers/fa';
import './RobotRow.scss';


const RobotRow = ({ api, rbt, tagId, match, refreshList }) => {
  const [shouldSpin, setShouldSpin] = useState(false);

  if (tagId) {
    tagId = tagId;
  } else {
    tagId = match.params.tagId;
  }

  const addRobot = () => {
    setShouldSpin(true);
    console.log("Applying tag ",tagId,"to robot ",rbt.serial)
    api.applyRobotTagToRobot(tagId, rbt.serial).then((resp) => {
      setShouldSpin(false);
      refreshList();
    })
    .catch((err) => {
      console.log("Error applying UserTag to Learner: ",err)
    });
  }


  return (
    <tr key={rbt.serial}>
      <td>
        <span>{rbt.serial && rbt.wifi}</span>
      </td>
      <td>
        <span>{rbt.serial && rbt.mac}</span>
      </td>
      <td>
        <span>{rbt.serial && rbt.serial}</span>
      </td>
      <td>
        <span>{rbt.serial && rbt.code_ver}</span>
      </td>
      <td name="Actions">
        <span>
          <div className="datatable_actions">
            <div className="d-flex align-items-start common-align-button action-btns">
                {shouldSpin &&
                  <button className="btn btn-md btn-secondary" ><FA color="white" icon="spinner" spin /></button>
                }
                {!shouldSpin && rbt.robot_tags.includes(parseInt(tagId)) &&
                  <button className="btn btn-md btn-primary" ><FAW icon="check"/></button>
                }
                {!shouldSpin && !rbt.robot_tags.includes(parseInt(tagId)) &&
                  <button className="btn btn-md btn-secondary"
                    onClick={() => {addRobot()}}
                    ><FAW icon="plus"/></button>
                }
            </div>
          </div>
        </span>
      </td>
    </tr>
  )
}

export default compose(
  withRouter,
  withAPI
)(RobotRow);
