import React, {useState} from "react";
import ImpactStatsRow from './ImpactStatsRow';
import "./UsageStatsRow.scss";

const LearnerImpactStats = ({data}) => {
  
  const [currentChartInd, setCurrentChartInd] = useState(0);

  return (
    <div className='learner-impact-stats'>

      {data && data.timestamps.length === 0 &&
        <div>
          <div className='row batch-row'>
            <div className='col-12'>
              <p>
                No Impact Stats available. Impact Stats are updated daily once
                learners have started syncing their lessons.
              </p>
            </div>
          </div>
        </div>
      }

      {data && data.timestamps.length > 0 && (
        <div
          className='carousel_container carousel_charts'
        >
          <div>
            <div className='carousel_charts_wrapper'>
              {currentChartInd === 0 &&
                <ImpactStatsRow
                  subject={'All'}
                  allData={data}
                  variant='learnerLevel'
                />
              }
              {currentChartInd === 1 &&
                <ImpactStatsRow
                  subject={'Math'}
                  allData={data}
                  variant='learnerLevel'
                />
              }
              {currentChartInd === 2 &&
                <ImpactStatsRow
                  subject={'Reading'}
                  allData={data}
                  variant='learnerLevel'
                />
              }
            </div>

            {/* carousel buttons */}
            <div className='carousel_btns_container mt-2'>
              {[0, 1, 2].map((ind) => {
                return (
                  <button
                    key={ind}
                    className={`carousel_btn ${
                      currentChartInd === ind ? "selected" : "unselected"
                    }`}
                    onClick={() => setCurrentChartInd(ind)}
                  />
                )
              })}
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default LearnerImpactStats;
