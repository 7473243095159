import {
  HAS_ROBOTS,
  UPDATE_USER_SCHOOLS,
} from "../actionTypes";

const initialState = () => {
  let user = null;
  let userId = null;
  let hasRobots = null;
  let hasAccounts = null;
  let localBotsOnly = null;
  let forcePWReset = null;
  let orgStatus = null;
  try {
    user = JSON.parse(sessionStorage.getItem("user"));
    console.log(user);
    userId = user ? user.user.id : null;
    hasRobots = JSON.parse(sessionStorage.getItem("hasRobots"));
    hasAccounts = JSON.parse(sessionStorage.getItem("hasAccounts"));
    localBotsOnly = JSON.parse(sessionStorage.getItem("localBotsOnly"));
    forcePWReset = JSON.parse(sessionStorage.getItem("forcePWReset"));
    orgStatus = sessionStorage.getItem("orgStatus");
  } catch(err) {
    // Something is wrong with our user obj in local storage.
    console.warn('Could not parse stored user obj', err)
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("hasRobots");
    sessionStorage.removeItem("hasAccounts");
    sessionStorage.removeItem("localBotsOnly");
    sessionStorage.removeItem("orgStatus");
  }

  return {
    user: user,
    userId: userId,
    token: sessionStorage.getItem("authToken") || null,
    hasRobots: hasRobots,
    hasAccounts: hasAccounts,
    localBotsOnly: localBotsOnly,
    forcePWReset: forcePWReset,
    orgStatus: orgStatus,
  }
}

const auth = (state = initialState(), action) => {
  switch (action.type) {
    case 'REGISTER':
      console.log(action.payload);
      // Reread stuff from sessionStorage since
      // it's not in the reg payload.
      return Object.assign({}, state, {
        ...initialState(),
        hasRobots: action.payload.hasRobots,
        hasAccounts: action.payload.hasAccounts,
        localBotsOnly: action.payload.localBotsOnly,
        forcePWReset: action.payload.forcePWReset,
        orgStatus: action.payload.orgStatus,
      })
    case 'LOGIN':
      console.log(action.payload);
      return Object.assign({}, state, {
        user: action.payload.user,
        userId: action.payload.user.user.id,
        token: action.payload.token,
        hasRobots: action.payload.hasRobots,
        hasAccounts: action.payload.hasAccounts,
        localBotsOnly: action.payload.localBotsOnly,
        forcePWReset: action.payload.forcePWReset,
        orgStatus: action.payload.orgStatus,
      })
    case HAS_ROBOTS:
      return Object.assign({}, state, {
        hasRobots: action.payload
      });
    case UPDATE_USER_SCHOOLS:
      return {
        ...state,
        user: {
          ...state.user,
          orgunits: action.payload,
        }
      };
    case 'LOGOUT':
      // Blank out user and token:
      return Object.assign({}, state, initialState())
    default:
      return state
  }
}

export default auth;
