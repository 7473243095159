import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {compose} from "redux";
import config from "../../common/config";
import {sortedArray, useTitle} from "../../common/utils";
import AddStudentsModal from "../../components/TeacherDashboard/AddStudentsModal";
import ClassDeleteModal from "../../components/TeacherDashboard/ClassDeleteModal.js";
import NewClassButton from "../../components/TeacherDashboard/NewClassButton";
import FA from "../../containers/fa";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";
import Class from "../TeacherDashboard/Class";
import "./Dashboard.scss";
const mapStateToProps = (state, ownProps?) => ({
  robotType: state.status.robotType,
});

const Dashboard = ({api, robotType}) => {
  useTitle("Dashboard");

  const [classes, setClasses] = useState([]);
  const [ready, setReady] = useState(false);
  const [shouldShowClassDeleteModal, setShouldShowClassDeleteModal] =
    useState(null);
  const [classesExpanded, setClassesExpanded] = useState([]);
  const [classToEdit, setClassToEdit] = useState(null);
  const [editableClassName, setEditableClassName] = useState("");
  const [autoClick, setAutoClick] = useState(false);
  const [classToggleEnabled, setClassToggleEnabled] = useState(true);
  const [refreshClasses, setRefreshClasses] = useState(false);
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  const [classToAddStudents, setClassToAddStudents] = useState({
    id: null,
    name: null,
  });
  const [refreshForNewLearners, setRefreshForNewLearners] = useState(null);

  const location = useLocation();

  const fetchClassData = () => {
    api.fetchObjectsForTeacher().then((objects) => {
      setClasses(sortedArray(objects.usertags, "created_at"));
      setReady(true);
    });
  };

  // initial load
  useEffect(() => {
    fetchClassData();
  }, []);

  // refreshClasses toggles on when a learner is added. This turns it off
  useEffect(() => {
    if (refreshClasses) {
      setRefreshClasses(false);
    }
  }, [refreshClasses]);

  // ensures enough time passes for the expand/close animation for classes
  useEffect(() => {
    if (!classToggleEnabled) {
      setTimeout(() => {
        setClassToggleEnabled(true);
      }, 600);
    }
  }, [classToggleEnabled]);

  useEffect(() => {
    const searchStr = window.location.search;
    const qParams = new URLSearchParams(searchStr);
    const openTgt = qParams.get("open");
    if (openTgt === "addClass") {
      setAutoClick(true);
    }
  }, [location]);

  const saveNewName = (clsId) => {
    api
      .patchUserTagInfo(clsId, {name: editableClassName})
      .then(() => {
        setClassToEdit(null);
        setEditableClassName("");
        fetchClassData();
      })
      .catch((e) => {
        console.log(e);
        setClassToEdit(null);
      });
  };

  const renderClasses = (classList) => {
    return classList.map((cls) => (
      <div
        id={`class-${cls.id}`}
        key={cls.id}
        style={{
          width: "100%",
          borderRadius: "5px 5px 0px 0px",
          marginBottom: "20px",
        }}
      >
        <div
          className='card batch_listings'
          style={{marginBottom: "0px", border: "none"}}
        >
          <div className='card-body text-center class-header-grid'>
            {classToEdit === cls.id ? (
              <div style={{display: "flex", flexFlow: "row"}}>
                <input
                  type='text'
                  aria-label='New Class Name'
                  name='new-class-name'
                  onChange={(e) => setEditableClassName(e.target.value)}
                  value={editableClassName}
                />
                <button
                  style={{marginLeft: "1em"}}
                  className='btn btn-sm btn-primary'
                  onClick={() => saveNewName(cls.id)}
                >
                  Save
                </button>
                &nbsp;
                <button
                  style={{marginLeft: "1em"}}
                  className='btn btn-sm btn-danger'
                  onClick={() => setClassToEdit(null)}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div
                className='class-name-and-triangle-grid'
                onClick={() => {
                  if (classesExpanded.indexOf(cls.id) === -1) {
                    if (!classToggleEnabled) {
                      return;
                    }
                    setClassToggleEnabled(false);
                    setClassesExpanded([cls.id, ...classesExpanded]);
                    setTimeout(() => {
                      document.getElementById(
                        "learners-wrapper-" + cls.id
                      ).style.overflow = "";
                    }, 500);
                  } else {
                    if (!classToggleEnabled) {
                      return;
                    }
                    setClassToggleEnabled(false);
                    setClassesExpanded(
                      classesExpanded.filter((item) => item !== cls.id)
                    );
                    document.getElementById(
                      "learners-wrapper-" + cls.id
                    ).style.overflow = "hidden";
                  }
                }}
              >
                <div
                  className='assignment-name-and-triangle-grid'
                  style={{
                    width: "fit-content",
                    paddingLeft: "10px",
                    gridGap: "2px",
                  }}
                >
                  <button
                    className={`triangle-${
                      classesExpanded.indexOf(cls.id) !== -1 ? "up" : "down"
                    }`}
                  />
                </div>
                {cls.name} &nbsp;
              </div>
            )}

            <div style={{width: "fit-content", justifySelf: "end"}}>
              {cls.clever_synced && (
                <>
                  <FA icon='exclamation-circle' />
                  <span className='ml-1 text-muted'>Clever-synced classes cannot be edited</span>
                </>
              )}
              <button
                disabled={cls.clever_synced}
                aria-label='Add Students'
                data-title='Add Students'
                style={{backgroundColor: "transparent"}}
                onClick={() => {
                  setShowAddStudentsModal(true);
                  setClassToAddStudents({id: cls.id, name: cls.name});
                }}
                className='btn btn-small'
              >
                <FA icon='user-plus' color='#4B4B4B' />
              </button>

              <button
                disabled={cls.clever_synced}
                aria-label='Edit Class Name'
                data-title='Edit Class Name'
                className='btn btn-small'
                style={{backgroundColor: "transparent"}}
                onClick={() => {
                  setClassToEdit(cls.id);
                  setEditableClassName(cls.name);
                }}
              >
                <FA icon='edit' color='#4B4B4B' />
              </button>

              <Link
                aria-label='View Reports'
                data-title='View Reports'
                className='btn btn-small'
                to={{
                  pathname: `/user/reports/${cls.id}`,
                  state: {
                    rerouteObject: cls,
                    rerouteObjectType: "class",
                    rerouteSource: "myClass",
                  },
                }}
              >
                <FA icon='chart-bar' color='#4B4B4B' />
              </Link>

              {shouldShowClassDeleteModal === cls.id && (
                <ClassDeleteModal
                  shouldShowFunc={setShouldShowClassDeleteModal}
                  clsId={cls.id}
                  refreshList={fetchClassData}
                  clsName={cls.name}
                />
              )}
              <button
                disabled={cls.clever_synced}
                aria-label='Delete Class'
                data-title='Delete Class'
                className='btn btn-small'
                style={{backgroundColor: "transparent"}}
                onClick={() => setShouldShowClassDeleteModal(cls.id)}
              >
                <FA icon='trash' color='#4B4B4B' />
              </button>
            </div>
          </div>
        </div>

        {showAddStudentsModal && classToAddStudents.id === cls.id && (
          <AddStudentsModal
            setShowModal={setShowAddStudentsModal}
            classToAddStudents={classToAddStudents}
            setClassToAddStudents={setClassToAddStudents}
            assignClassId={cls.id}
            setRefreshForNewLearners={setRefreshForNewLearners}
          />
        )}

        <Class
          clsId={cls.id}
          classesExpanded={classesExpanded}
          setClassesExpanded={setClassesExpanded}
          classes={classes}
          refreshForNewLearners={refreshForNewLearners}
          setRefreshForNewLearners={setRefreshForNewLearners}
        />
      </div>
    ));
  };

  if (robotType === config.ABII_HOME) {
    return null;
  }

  return (
    <div>
      <MainDashboard>
        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <p>Teacher Dashboard</p>
            <div className='d-flex justify-content-end'></div>
          </div>
          <div className='common_table_padding'>
            {ready && classes.length > 0 && (
              <div className='row'>
                <div className='col-md-4'>
                  <NewClassButton
                    disabled={!ready}
                    refreshList={fetchClassData}
                  />
                </div>
              </div>
            )}
            {ready ? (
              !classes.length && (
                <div>
                  <p className='bold_font text-center'>
                    Welcome! You don't have any classes or learners yet! Click
                    here to add a class and learners.
                  </p>
                  <div className='text-center'>
                    <NewClassButton
                      disabled={!ready}
                      autoClick={autoClick}
                      refreshList={fetchClassData}
                    />
                  </div>
                </div>
              )
            ) : (
              <div>
                <h4>
                  <FA color='gray' icon='spinner' spin /> Loading classes...
                </h4>
              </div>
            )}
            <div className='row batch-row'>{renderClasses(classes)}</div>
          </div>
        </div>
      </MainDashboard>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(Dashboard);
