import React from "react";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import {Bar} from "react-chartjs-2";
import {roundValues} from '../../common/utils';

ChartJS.register(
  Tooltip, Legend, Title,
  CategoryScale, LinearScale, BarElement
);




const ImpactStatsOvertime = ({
    subject, allData, stackedChartData, variant, learnerLevel
  }) => {
  
  // returns a Bar element

  const lowerSubject = subject.toLowerCase();
  const maxCount = 12;
  let dataToUse = {};
  let learnerCounts = allData['learner_counts'];
  let subjectData = allData[lowerSubject];

  if (variant === 'summary') {
    // grab last 12 items from the arrays
    const totalCount = stackedChartData.labels.length;
    const startInd = Math.max(totalCount - maxCount, 0);
    dataToUse.labels = stackedChartData.labels.slice(startInd, totalCount);
    const newDatasets = [];
    for (let i = 0; i < stackedChartData.datasets.length; i++) {
      let tmp = {...stackedChartData.datasets[i]};
      let tmpData = stackedChartData.datasets[i].data;
      tmpData = tmpData.slice(startInd, totalCount);
      tmp.data = tmpData;
      newDatasets.push(tmp)
    }
    dataToUse.datasets = newDatasets;
    if (learnerCounts) {
      learnerCounts = learnerCounts.slice(startInd, totalCount)
    }
    // also slice off subjectData
    subjectData = subjectData.slice(startInd, totalCount)
    console.log(dataToUse)
  } else {
    dataToUse = stackedChartData;
  }

  const stackedChartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Mastery Stats Over Time' + (learnerLevel ? ` (${subject})` : ''),
        font: {
          size: 15,
        },
        padding: {
          top: 10,
          bottom: 35,
        },
      },
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        formatter: (value, ctx) => {
          if (!isNaN(value)) {
            return value + '%'
          } else {
            return ''
          }
        },
        // color: '#fff',
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function(context) {
            const labelField = context.dataset.labelField
            const rawCount = subjectData[context.dataIndex][labelField];
            const totalCount = Object.values(subjectData[context.dataIndex]).reduce((a, b) => a + b);
            let label;
            if (learnerCounts) {
              const learnerCount = learnerCounts[context.dataIndex];
              const avgValue = roundValues(rawCount / learnerCount, 1);
              label = `${context.dataset.label}: ${rawCount}/${totalCount} standards (${avgValue} per learner)`
            } else {
              label = `${context.dataset.label}: ${rawCount}/${totalCount} standards`
            }
            return label;
          },
          afterTitle: function(context) {
            // console.log(context)
            // context[0] is just one of the stakced bars
            if (!learnerCounts) {
              return
            }
            const learnerCount = learnerCounts[context[0].dataIndex];
            return `(${learnerCount} active learners)`
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        grid: {
          display: false,
          padding: 30,
        },
        max: 100,
        min: 0,
        stacked: true,
        ticks: {
          callback: (yValue) => {
            return Math.round(yValue) + "%";
          },
        },
        beginAtZero: true,
        suggestedMax: 100,
      },
    },
  };

  return (
    <Bar options={stackedChartOptions} data={dataToUse} />
  )

}

export default ImpactStatsOvertime;
