import React, {useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import config from "../../common/config";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import StateSelector from "../InputFields/StateSelector";
import CreateSuperAdminSchoolModal from "./Schools/CreateSuperAdminSchoolModal";
import "./CreateEditContactModal.scss";

const mapStateToProps = (state, ownProps?) => ({});

const CreateEditContactModal = ({
  api,
  contactToEdit,
  setContactToEdit,
  creatingNewContact,
  setCreatingNewContact,
  orgId,
  fetchContacts,
  fetchAdminSchoolsForFilter,
}) => {
  const EMPTYFORM = {
    address1: "",
    address2: null,
    city: null,
    email: null,
    first_name: "",
    last_name: "",
    notes: null,
    organization: "",
    phone: null,
    position: "",
    position_detail: null,
    primary_contact: false,
    school_or_facility: null,
    state: null,
    zipcode: null,
  };

  const FIELDS = {
    first_name: {icon: "id-card", placeholder: "First Name", required: true},
    last_name: {icon: "id-card", placeholder: "Last Name", required: true},
    address1: {icon: "building", placeholder: "Address 1", required: false},
    email: {icon: "envelope-open", placeholder: "Email", required: true},
    address2: {icon: "building", placeholder: "Address 2", required: false},
    phone: {icon: "phone", placeholder: "Phone #", required: false},
    city: {icon: "building", placeholder: "City", required: false},
    position: {icon: "building", placeholder: "Position", required: false},
    state: {icon: "building", placeholder: "State", required: false},
    position_detail: {
      icon: "building",
      placeholder: "Position Title/Description",
      required: false,
    },
    school_or_facility: {
      icon: "building",
      placeholder: "School",
      required: false,
    },
    zipcode: {icon: "building", placeholder: "Zipcode", required: false},
    notes: {icon: "building", placeholder: "Notes (Optional)", required: false},
  };

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const [newContactVals, setNewContactVals] = useState({...contactToEdit});
  const [errors, setErrors] = useState({...EMPTYFORM});
  const [showPopup, setShowPopup] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(true);
  const [schoolsInOrg, setSchoolsInOrg] = useState([]);
  const [isValidForm, setIsValidForm] = useState(false);
  const [showCreateSchoolModal, setShowCreateSchoolModal] = useState(false);

  useState(() => {
    console.log(contactToEdit);
    if (contactToEdit) {
      setNewContactVals(contactToEdit);
    }
  }, [contactToEdit]);

  useState(() => {
    if (creatingNewContact) {
      setErrors(EMPTYFORM);
      setNewContactVals(EMPTYFORM);
      setContactToEdit(EMPTYFORM);
    }
  }, []);

  const fetchAdminSchools = () => {
    api
      .fetchSuperAdminSchools(orgId)
      .then((resp) => {
        console.log(resp);
        setSchoolsInOrg(resp);
        fetchAdminSchoolsForFilter();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useState(() => {
    if (orgId) {
      fetchAdminSchools();
    }
  }, [orgId]);

  let validateFormInputs = (newFormInputs) => {
    let errorsCopy = {...errors};
    let newIsValid = true;

    Object.entries(newFormInputs).map(([key, entry]) => {
      if (!FIELDS[key]) {
        return null;
      }
      if (FIELDS[key].required && !entry) {
        // blank required field
        errorsCopy[key] = "*Required";
        newIsValid = false;
      } else {
        if (key === "email" && newFormInputs.email.length > 0) {
          // check for invalid email format
          let emailErr = validEmailRegex.test(newFormInputs.email)
            ? ""
            : "Must be a valid email address";
          errorsCopy[key] = emailErr;
          if (emailErr) {
            newIsValid = false;
          }
        } else {
          // is valid non-email
          errorsCopy[key] = "";
        }
      }
    });
    setIsValidForm(newIsValid);
    setErrors(errorsCopy);
  };

  useState(() => {
    validateFormInputs(newContactVals);
  }, [newContactVals]);

  function handleInput(e, field, fieldName, value = null) {
    // Make deep copy of formInput
    let formCopy = JSON.parse(JSON.stringify(newContactVals));
    formCopy[fieldName] = value ? value : e.target.value;
    if (fieldName === "position" && formCopy[fieldName] !== "other") {
      formCopy.position_detail = null;
    }
    setNewContactVals(formCopy);
    setSaved(false);
    validateFormInputs(formCopy);
  }

  const createNewContact = () => {
    if (newContactVals) {
      setSaving(true);
      setContactToEdit(newContactVals);
      api
        .createContact(orgId, {...newContactVals, organization: orgId})
        .then((resp) => {
          console.log(resp);
          setSaving(false);
          setSaved(true);
          setShowPopup(false);
          setContactToEdit(null);
          setCreatingNewContact(false);
          fetchContacts();
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
        });
    }
  };

  const saveChangesToContact = () => {
    if (newContactVals) {
      setSaving(true);
      setContactToEdit(newContactVals);
      api
        .patchContactInfo(orgId, newContactVals.id, {
          ...newContactVals,
          organization: orgId,
        })
        .then((resp) => {
          console.log(resp);
          setSaving(false);
          setSaved(true);
          fetchContacts();
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
        });
    }
  };

  const renderInputField = (field, fieldName) => {
    if (fieldName === "state") {
      return (
        <StateSelector
          value={field ? field : ""}
          onChangeHandle={(e) => {
            handleInput(e, field, fieldName);
          }}
        />
      );
    } else if (fieldName === "org_level") {
      return (
        <select
          className='form-control'
          value={field ? field : ""}
          onChange={(e) => handleInput(e, field, fieldName)}
        >
          <option value='' disabled>
            Select Org Level
          </option>
          <option value={config.ORG_LEVEL_SCHOOL}>School-level</option>
          <option value={config.ORG_LEVEL_DISTRICT}>District-level</option>
        </select>
      );
    } else if (fieldName === "position") {
      return (
        <select
          className='form-control'
          value={field ? field : ""}
          onChange={(e) => handleInput(e, field, fieldName)}
        >
          <option value='' disabled>
            Select a position
          </option>
          <option value='superintendent'>Superintendent</option>
          <option value='principal'>Principal</option>
          <option value='teacher'>Teacher</option>
          <option value='IT_or_sysadmin'>IT or Systems Admin</option>
          <option value='administrator'>Administrator</option>
          <option value='accounting'>Accounting</option>
          <option value='other'>Other</option>
        </select>
      );
    } else if (
      fieldName === "position_detail" &&
      newContactVals.position === "other"
    ) {
      return (
        <input
          className='form-control input-underline input-lg'
          placeholder={FIELDS[fieldName].placeholder}
          value={field ? field : ""}
          onChange={(e) => handleInput(e, field, fieldName)}
        />
      );
    } else if (fieldName === "school_or_facility") {
      return (
        <select
          className='form-control'
          value={field ? field : ""}
          onChange={(e) => {
            if (e.target.value === "add_school") {
              setShowCreateSchoolModal(true);
              return;
            }
            handleInput(e, field, fieldName);
          }}
        >
          <option value='' disabled>
            Select a school
          </option>
          {schoolsInOrg.map((val, k) => {
            console.log(k);
            console.log(val);
            return (
              <option key={k} value={val.id}>
                {val.name}
              </option>
            );
          })}
          <option value='add_school'>Add a school...</option>
        </select>
      );
    } else {
      return (
        <input
          className='form-control input-underline input-lg'
          required={FIELDS[fieldName].required}
          placeholder={FIELDS[fieldName].placeholder}
          value={field ? field : ""}
          style={{
            border:
              FIELDS[fieldName].required && !field ? "1px solid #db2269" : "",
          }}
          onChange={(e) => handleInput(e, field, fieldName)}
        />
      );
    }
  };

  const renderFormFromContact = () => {
    if (contactToEdit === null) {
      console.log("contactToEdit IS NULL");
      return null;
    }

    return (
      <div id='edit_contact_fields_container' className='visible-scrollbar'>
        {showCreateSchoolModal && (
          <CreateSuperAdminSchoolModal
            orgId={orgId}
            shouldShowFunc={setShowCreateSchoolModal}
            refreshList={fetchAdminSchools}
          />
        )}
        {contactToEdit && newContactVals && (
          <div className='create_edit_fields_grid'>
            {Object.keys(FIELDS).map((k) => {
              // cannot show the position_detail field if position is not 'other'
              if (
                newContactVals.position !== "other" &&
                k === "position_detail"
              ) {
                return null;
              }
              return (
                <div
                  key={k}
                  className={`contact_field ${k}`}
                  style={{margin: "5px"}}
                >
                  <div className='create_org_hr_error_container'>
                    <p
                      className='create_org_fields_hr'
                      style={{
                        marginBottom: "0px",
                        maxWidth: "350px",
                        fontWeight: "bold",
                      }}
                    >
                      {FIELDS[k].required ? "*" : ""}
                      {FIELDS[k].placeholder}
                    </p>
                    <p
                      className='org_form_errors'
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      {errors[k]}
                    </p>
                  </div>

                  <div className='org_form_icons mx-auto'>
                    <label>
                      <FA icon={FIELDS[k].icon} />
                    </label>
                    {renderInputField(newContactVals[k], k)}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {showPopup || contactToEdit || creatingNewContact ? (
        <div className='contacts_popup_graybox mt-4' style={{display: "block"}}>
          <div id='make_contact_popup_whitebox'>
            <div
              className='common_heading d-flex justify-content-between align-items-center'
              style={{padding: "10px 10px"}}
            >
              <h5 style={{marginBottom: "0px"}}>
                {!creatingNewContact
                  ? "Edit Contact: " +
                    contactToEdit["first_name"] +
                    contactToEdit["last_name"]
                  : "Create New Contact"}
              </h5>

              <div
                id={`saving-msg-${saving}`}
                className='alert alert-success'
                role='alert'
              >
                <p>
                  <FA color='gray' icon='spinner' spin /> Saving Changes...
                </p>
              </div>

              <div
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  width: "fit-content",
                }}
                onClick={() => {
                  setShowPopup(false);
                  setContactToEdit(null);
                  setCreatingNewContact(false);
                }}
              >
                <FA color='gray' icon='times' />
              </div>
            </div>
            {renderFormFromContact()}
            <div
              style={{display: 'flex', width: 'fit-content', margin: 'auto'}}
            >
              <button
                style={{
                  display: "flex",
                  width: "fit-content",
                  padding: "10px",
                  margin: "10px",
                }}
                className='btn btn-sm btn-primary'
                onClick={
                  creatingNewContact ? createNewContact : saveChangesToContact
                }
                disabled={saved || !isValidForm || saving}
              >
                {saved
                  ? "No changes to save"
                  : saving
                  ? "Saving..."
                  : "Save Changes"}
              </button>

              <button
                style={{
                  display: "flex",
                  width: "fit-content",
                  padding: "10px",
                  margin: "10px",
                }}
                className='btn btn-sm btn-danger'
                onClick={() => {
                  setShowPopup(false);
                  setContactToEdit(null);
                  setCreatingNewContact(false);
                }}
                disabled={saving}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default compose(
  connect(mapStateToProps),
  withAPI
)(CreateEditContactModal);
