import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import withAPI from '../../services/api';
import FAW from '../../containers/faw';
import FA from '../../containers/fa';

import './RobotRow.scss';


const TeacherRow = ({ api, usr, tagId, refreshList, match }) => {

  const [shouldSpin, setShouldSpin] = useState(false);

  if (tagId) {
    tagId = tagId;
  } else {
    tagId = parseInt(match.params.tagId);
  }

  const handleAdd = () => {
    setShouldSpin(true);
    api.applyRobotTagToUser(match.params.tagId, usr.id)
    .then(() => {
      setShouldSpin(false);
      refreshList();
    });
  }

  console.log("TeacherRow has User: ",usr)

  return (
    <tr key={usr.id}>
      <td>
        <span>{usr.id && usr.first_name}</span>
      </td>
      <td>
        <span>{usr.id && usr.last_name}</span>
      </td>
      <td>
        <span>{usr.id && usr.username}</span>
      </td>
      <td name="Actions">
        <span>
          <div className="datatable_actions">
            <div className="d-flex align-items-start common-align-button action-btns">
                {shouldSpin &&
                  <button className="btn btn-md btn-secondary" ><FA color="white" icon="spinner" spin /></button>
                }
                {!shouldSpin && usr.userprofile.robot_tags.includes(tagId) &&
                  <button className="btn btn-md btn-primary" ><FAW icon="check"/></button>
                }
                {!shouldSpin && !usr.userprofile.robot_tags.includes(tagId) &&
                  <button className="btn btn-md btn-secondary"
                    onClick={() => {handleAdd()}}
                    ><FAW icon="plus"/></button>
                }
            </div>
          </div>
        </span>
      </td>
    </tr>
  )
}

export default compose(
  withAPI,
  withRouter
)(TeacherRow);
