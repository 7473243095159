import { fetchWithError } from './http';
import config from '../common/config';
import { debugLog } from '../common/utils';
import {
  login,
  studentCap as StudentCapA
} from '../redux/actions';

const POPUP_WIDTH = 400;
const POPUP_HEIGHT = 426;
const ACCESS_TOKEN_CLEVER_EVENT = 'ACCESS_TOKEN_CLEVER_EVENT'

function jsonToURI(json) {
  return encodeURIComponent(JSON.stringify(json));
}
function uriToJSON(urijson) {
  return JSON.parse(decodeURIComponent(urijson));
}

export const OAuthProviders = {
  google: {
    CLIENT_ID: '127288955795-njqka4j2odpr3j40rqcmcr32mbn5ju1v.apps.googleusercontent.com',
    SCOPE: 'profile email',
    REDIRECT_URI: `${config.frontendBaseUrl}/google-login/`,
    OAUTH_LOGIN_ENDPOINT: 'https://accounts.google.com/o/oauth2/auth',
    OAUTH_LOGIN_PARAMS: function () {
      return [
        `client_id=${this.CLIENT_ID}`,
        `redirect_uri=${this.REDIRECT_URI}`,
        `scope=${this.SCOPE}`,
        `prompt=consent`,
        `response_type=code`,
      ];
    },
    ACCESS_CODE_SUBMIT_URI: `${config.apiBaseUrl}/api/oauth/google/`,
    ACCESS_CODE_SUBMIT_PARAMS: function (code) {
      return [`code=${code}`];
    },
    OAUTH_CONNECT_SUBMIT_URL: `${config.apiBaseUrl}/api/oauth/connect/google/`,
    USERPROFILE_GET_URI: `${config.apiUrl}/login-from-sso/`,
  },
  /* ABii by Van Robotics (SSO + Rostering) */
  cleverSecureSync: {
    APP_NAME: 'clever_securesync',
    OAUTH_LOGIN_LAUNCH_PAGE: '/clever-securesync-login/',
    CLIENT_ID: `${config.cleverSecureSyncClientId}`,
    /* DEV app */
    //CLIENT_ID: 'db3fd3ac8d18c7bef093',
    /* PROD app */
    //CLIENT_ID: '',
    REDIRECT_URI: `${config.frontendBaseUrl}/clever-securesync-login/`,
    OAUTH_LOGIN_ENDPOINT: 'https://clever.com/oauth/authorize',
    OAUTH_LOGIN_PARAMS: function () {
      return [
        `client_id=${this.CLIENT_ID}`,
        `redirect_uri=${this.REDIRECT_URI}`,
        `response_type=code`,
      ];
    },
    ACCESS_CODE_SUBMIT_URI: `${config.apiBaseUrl}/api/oauth/clever-securesync/`,
    ACCESS_CODE_SUBMIT_PARAMS: function (code) {
      return [`code=${code}`];
    },
    OAUTH_CONNECT_SUBMIT_URL: `${config.apiBaseUrl}/api/oauth/connect/clever-securesync/`,
    USERPROFILE_GET_URI: `${config.apiUrl}/login-from-sso/`,
  },
  /* ABii by Van Robotics (SSO only) */
  cleverSSOOnly: {
    APP_NAME: 'clever_sso',
    OAUTH_LOGIN_LAUNCH_PAGE: '/clever-sso-login/',
    CLIENT_ID: `${config.cleverSSOClientId}`,
    /* DEV app */
    //CLIENT_ID: '692330256dcea02a436e',
    /* PROD app */
    //CLIENT_ID: 'b1d0c0de2b226b03783b',
    REDIRECT_URI: `${config.frontendBaseUrl}/clever-sso-login/`,
    OAUTH_LOGIN_ENDPOINT: 'https://clever.com/oauth/authorize',
    OAUTH_LOGIN_PARAMS: function () {
      return [
        `client_id=${this.CLIENT_ID}`,
        `redirect_uri=${this.REDIRECT_URI}`,
        `response_type=code`,
        `return_unshared=true`,
      ];
    },
    ACCESS_CODE_SUBMIT_URI: `${config.apiBaseUrl}/api/oauth/clever-sso/`,
    ACCESS_CODE_SUBMIT_PARAMS: function (code) {
      return [`code=${code}`];
    },
    OAUTH_CONNECT_SUBMIT_URL: `${config.apiBaseUrl}/api/oauth/connect/clever-sso/`,
    USERPROFILE_GET_URI: `${config.apiUrl}/login-from-sso/`,
  },
};

export class OAuthService {
  constructor(provider) {
    this.provider = provider;
  }

  makeAuthUrl({state}) {
    const queryParamList = this.provider.OAUTH_LOGIN_PARAMS();
    // Add additional parameters if necessary
    if (state) {
      queryParamList.push(`state=${state}`);
    }
    const queryParams = queryParamList.join("&")
    return `${this.provider.OAUTH_LOGIN_ENDPOINT}?${queryParams}`;
  }

  /* Not currently in use, but possibly in the future as part of a popup-based
   * SSO flow
   *
  launchPopup({state}) {
    const left = window.screen.width / 2 - POPUP_WIDTH / 2,
      top = window.screen.height / 2 - POPUP_HEIGHT / 2;
    try {
      debugLog("Opening popup:",this.makeAuthUrl({state}));
      const win = window.open(
        this.makeAuthUrl({state}),
        "ABii's World Login",
        "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
          POPUP_WIDTH +
          ", height=" +
          POPUP_HEIGHT +
          ", top=" +
          top +
          ", left=" +
          left
      );
      if (win) win.opener = window;
    } catch (e) {
      // Popup is probably already open...
      debugLog("Failed to open auth popup");
    }
  }

  listenToMessageEvent() { 
    const result = new Promise((resolve, reject) => {
      const windowEventHandler = (event: MessageEvent) => {
        debugLog("incoming event:");
        debugLog("event.source:",event.source);
        debugLog("event.origin:",event.origin);
        debugLog("event.data:",event.data);
        let hash = event.data;
        debugLog("windowEventHandler received hash:",hash);
        if (hash.type === ACCESS_TOKEN_CLEVER_EVENT) {
          const token = hash.access_token;
          resolve(token);
          window.removeEventListener("message", windowEventHandler);
        } else if (hash.type == "error") {
          console.error(hash.message);
          reject(hash.message);
          window.removeEventListener("message", windowEventHandler);
        }
        //event.source.postMessage("Response from opener", event.origin);
      };
      window.addEventListener("message", windowEventHandler, false);
    })
    return result;
  }

  //processToken(token, callbackFn: (token: string) => void) {
  processToken(token) {
    if (!window.opener) {
      // Probably don't run this processToken function as opener, so this is probably irrelevant
      //token && callbackFn(token);
      //debugLog("!window.opener and ran callbackFn");
      //return;
    }
    if (!token) {
      window.opener.postMessage(
        {
          type: "error",
          message: "No Access Token Found."
        },
        window.location.origin
      );
      window.close();
      return;
    }

    // If we're opened in the popup and have an access token, post it to the opener
    debugLog("Sending token to opener:",token);
    var resp = {
      type: ACCESS_TOKEN_CLEVER_EVENT,
      access_token: token
    };
    debugLog("Sending message to opener:",resp);
    window.opener.postMessage(
      resp,
      window.location.origin,
    );
    debugLog("Would normally close window now");
    //window.close();
  }
  * End functions that are part of popup-based SSO flow
  */

  redirectToLogin({state}) {
    console.log("Redirecting to:", this.makeAuthUrl({state}));
    window.location.href = this.makeAuthUrl({state});
  }

  findTokenInHash(hash: string): string | null {
    const matchedResult = decodeURIComponent(hash.match(/access_token=([^&]+)/));
    return matchedResult && matchedResult[1];
  }

  tradeOAuthCodeWithBackend(oauthCode, stateParams, {shouldOverrideRedirectUri, connectingToExisting}) {
    // Submit OAuth access code to backend
    // Expecting an auth token for comm_management_backend in response
    // But might receive a ValidationError instead. Will need calling function to handle that.
    return new Promise((resolve, reject) => {
      if (shouldOverrideRedirectUri) {
        debugLog("Overriding redirect_uri:",this.provider.REDIRECT_URI);
        var qParams = [
          `redirect_uri=${this.provider.REDIRECT_URI}`,
        ]
      } else {
        var  qParams = []
      }
      var qParamList = qParams.join('&');
      const data = {
        code: oauthCode,
        //redirect_uri: this.provider.REDIRECT_URI,
      };
      if (stateParams && stateParams.organization_code) data.organization_code = stateParams.organization_code;
      if (stateParams && stateParams.user_code) data.user_code = stateParams.user_code;
        
      const http = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      };
      fetchWithError(`${this.provider.ACCESS_CODE_SUBMIT_URI}?${qParamList}`, http).then((res) => {
        debugLog("Expecting a token in reponse:",res);
        resolve(res.key);
      }).catch((e) => {
        debugLog("Error returned from backend:",e);
        reject(e);
      });
    });
  }

  connectExternalWithExisting(oauthCode, stateParams) {
    // Should link external User's account with the existing, logged-in User
    // Submit OAuth access code to backend along with logged-in user's authToken
    return new Promise((resolve, reject) => {
      const authToken = sessionStorage.getItem("authToken");
      debugLog("Grabbed authToken:",authToken);
      debugLog("Sending LinkCode: ",stateParams.link_code);
      const data = {
        code: oauthCode,
      };
      const http = {
        method: 'POST',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
          'X-LINK': `LinkCode ${stateParams.link_code}`,
        },
        body: JSON.stringify(data)
      };
      fetchWithError(`${this.provider.OAUTH_CONNECT_SUBMIT_URL}`, http).then((res) => {
        debugLog("Not yet sure what to expect in response:", res);
        resolve(res);
      }).catch((e) => {
        debugLog("Error returned from backend:",e);
        reject(e);
      });
    });
  }

  grabUserProfileFromBackend(token, extra_params) {
  // takes our authToken and submits a json object of any extra params to
  // backend in exchange for UserProfile information
    return new Promise((resolve, reject) => {
      const http = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(extra_params)
      }
      debugLog('Submitting:', http, ' to ', this.provider.USERPROFILE_GET_URI);
      fetchWithError(`${this.provider.USERPROFILE_GET_URI}`, http).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      });
    });
  }

}
