import {
  SALE_MODAL_HEADER,
  REFRESH_SALE_DETAIL,
  REFRESH_SUBSCRIPTION_ITEMS,
} from "../actionTypes";

const initialState = {
  saleDetailId: null,
  saleDetailOrgInfo: {},
  renewSubscriptionIds: null,
  refreshSaleDetail: false,
  refreshSubscriptionItems: false,
};

const superAdmin = (state = initialState, action) => {
  switch (action.type) {
    case SALE_MODAL_HEADER:
      return Object.assign({}, state, {
        saleDetailId: action.payload.id,
        saleDetailOrgInfo: action.payload.orgInfo,
        renewSubscriptionIds: action.payload.renewSubscriptionIds,
      });
    case REFRESH_SALE_DETAIL:
      return Object.assign({}, state, {
        refreshSaleDetail: action.payload,
      });
    case REFRESH_SUBSCRIPTION_ITEMS:
      return Object.assign({}, state, {
        refreshSubscriptionItems: action.payload,
      });
    default:
      return state;
  }
};

export default superAdmin;
