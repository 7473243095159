import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {sortedArray} from "../../common/utils";
import EditStudentModal from "../../components/ManageAllLearners/EditStudentModal.js";
import LearnerRow from "../../components/TeacherDashboard/LearnerRow";
import InfoPopup from "../../components/UtilComps/InfoPopup";
import FA from "../../containers/fa";
import {classesToRefresh as classesToRefreshA} from "../../redux/actions";
import withAPI from "../../services/api";
import "../ManageAllLearners/Dashboard.scss";
import "./Class.scss";

const mapStateToProps = (state, ownProps?) => ({
  refreshClassArr: state.classes.refreshClassArr,
  user: state.auth.user,
});

const Class = ({
  dispatch,
  api,
  user,
  refreshClassArr,
  clsId,
  clsName,
  classesExpanded,
  setClassesExpanded,
  classes,
  refreshForNewLearners,
  setRefreshForNewLearners,
  teacherAcctPageLearnersInfo = null, // for classes on Manage Teacher pages
  userType = null, // for classes on Manage Teacher pages
}) => {
  const [cls, setClass] = useState({});
  const [isNewLoad, setIsNewLoad] = useState(true);
  const [learners, setLearners] = useState([]);
  const [editLearner, setEditLearner] = useState(null);
  const [shouldSpin, setShouldSpin] = useState(false);
  const [error, setError] = useState(null);
  const [showAutosave, setShowAutosave] = useState(false);
  const [autosaveSuccess, setAutosaveSuccess] = useState(null);
  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    header: "",
    text: "",
  });
  const [sortType, setSortType] = useState("first_name");
  const [sortAsc, setSortAsc] = useState(true);
  const [isNotInExpandedClass, setisNotInExpandedClass] = useState(false);

  const fetchLearners = () => {
    if (user && !shouldSpin) {
      setShouldSpin(true);
      api.fetchUserTagInfo(clsId).then((c) => {
        setClass(c);
      });

      api
        .fetchUserTagAssociations(clsId)
        .then((objects) => {
          const newLearners = sortedArray(objects.learners, sortType, sortAsc);
          setLearners(newLearners);
          setShouldSpin(false);
          setError(null);
          dispatch(
            classesToRefreshA({
              action: "remove",
              classes: [clsId],
            })
          );
        })
        .catch((err) => {
          setShouldSpin(false);
          setError(err.message);
        });
    }
  };

  // initial load
  useEffect(() => {
    fetchLearners();
  }, []);

  useEffect(() => {
    if (classesExpanded && classesExpanded.indexOf(clsId) === -1) {
      // class is not expanded
      setisNotInExpandedClass(true);
    } else {
      // class is in the list of expanded
      setisNotInExpandedClass(false);
    }
  }, [classesExpanded]);

  // refreshForNewLearners is updated with a class id when a student is added
  useEffect(() => {
    if (clsId === refreshForNewLearners) {
      fetchLearners();
      setRefreshForNewLearners(null);
    }
  }, [refreshForNewLearners]);

  useEffect(() => {
    setLearners(sortedArray(learners, sortType, sortAsc));
  }, [sortType, sortAsc]);

  const getIdsInList = (obj) => {
    let ids = [];
    for (let i = 0; i < obj.length; i++) {
      ids.push(obj[i].id);
    }
    return ids;
  };

  useEffect(() => {
    if (autosaveSuccess) {
      dispatch(
        classesToRefreshA({
          action: "add",
          classes: getIdsInList(classes),
        })
      );
    }
  }, [autosaveSuccess]);

  useEffect(() => {
    if (refreshClassArr.includes(clsId)) {
      fetchLearners();
    }
  }, [refreshClassArr]);

  const handleColumnHeaderClick = (column) => {
    if (sortType === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortType(column);
      setSortAsc(false);
    }
  };

  const getHeaderClasses = (column) => {
    let classes = "sortable";
    if (sortType === column) {
      if (sortAsc === false) {
        classes += " headerSortDown";
      } else {
        classes += " headerSortUp";
      }
    } else {
      classes += " headerSort";
    }
    return classes;
  };

  const getClassHeight = () => {
    if (isNewLoad && learners.length !== 0) {
      setIsNewLoad(false);
      if (classesExpanded.indexOf(clsId) === -1) {
        setClassesExpanded([...classesExpanded, clsId]);
      }
      return `${learners.length * 200 + 160}px`;
    }
    if (classesExpanded.indexOf(clsId) !== -1) {
      return `${learners.length * 200 + 160}px`;
    }
    return "0px";
  };

  return (
    <div
      className='class_info_container'
      style={{
        border: "1px solid rgb(221 221 221)",
        borderTop: "2px solid rgb(34 167 215)",
        padding: "0px 10px 0px",
      }}
    >
      {editLearner && (
        <EditStudentModal
          editLearner={editLearner}
          setEditLearner={setEditLearner}
          refreshLearners={fetchLearners}
          classId={clsId}
        />
      )}

      {showPopupInfo && (
        <InfoPopup popupInfo={popupInfo} setShowPopupInfo={setShowPopupInfo} />
      )}

      <div id={`autosave-${showAutosave}`}>
        <FA
          color={autosaveSuccess === false ? "#CB444A" : "#4D734D"}
          icon='spinner'
          spin
        />
        <p className={`autosave-caption-${autosaveSuccess}`}>
          {autosaveSuccess === null
            ? " Saving changes..."
            : autosaveSuccess
            ? " Saved"
            : " Autosave failed."}
        </p>
      </div>

      <div>
        <div
          id={`learners-wrapper-${clsId}`}
          style={{
            overflow:
              classesExpanded.indexOf(clsId) === -1 ? "hidden" : "unset",
            maxHeight: getClassHeight(),
            transition: "max-height 0.5s ease",
          }}
        >
          <div className='row'>
            <div className='col-12'>
              {error && (
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              )}
              {shouldSpin && (
                <h4>
                  <FA color='gray' icon='spinner' spin /> Loading Learners...
                </h4>
              )}

              {!shouldSpin && (
                <div className='card' style={{marginBottom: "0px"}}>
                  <div
                    className='progress-linear school_listing manage_learner_table visible-scrollbar'
                    role='progressbar'
                  >
                    <div
                      style={{
                        minWidth: teacherAcctPageLearnersInfo
                          ? "430px"
                          : "580px",
                      }}
                    >
                      <div
                        className={
                          teacherAcctPageLearnersInfo
                            ? "manage_teacher_classes_row"
                            : "class-grid-row-caption-container-teacher"
                        }
                      >
                        <p />
                        <p
                          className={getHeaderClasses("last_name")}
                          onClick={() => {
                            handleColumnHeaderClick("last_name");
                          }}
                          style={{paddingLeft: "10px"}}
                        >
                          Name
                        </p>
                        <span
                          id='learner_username_hr'
                          className={getHeaderClasses("username")}
                          onClick={() => {
                            handleColumnHeaderClick("username");
                          }}
                        >
                          <p style={{padding: "0px"}}>Username</p>
                        </span>
                        <span
                          id='avg_lessons_hr'
                          className={getHeaderClasses("recent_weekly_lesson")}
                        >
                          <p
                            onClick={() => {
                              handleColumnHeaderClick("recent_weekly_lesson");
                            }}
                          >
                            Recent Lessons
                          </p>{" "}
                          <button
                            className='info-circle-btn'
                            disabled={isNotInExpandedClass}
                            onClick={() => {
                              setPopupInfo({
                                header: "Recent Lessons",
                                text: `This represents the number of
                                lessons taken by this learner in the last 30 days.
                                An average of at least 1 lesson per week
                                (4 lessons in the past 30 days)
                                indicates that recommended usage is met.`,
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FA
                              icon='info-circle'
                              className='info-circle robots_table_hr'
                            />
                          </button>
                        </span>

                        {/* Manage Teacher pages only */}
                        {teacherAcctPageLearnersInfo && (
                          <span>
                            <p style={{padding: "0px"}}>Total Lessons</p>
                          </span>
                        )}

                        {/* Manage Teacher pages don't display grade info or
                        pw info. */}
                        {!teacherAcctPageLearnersInfo && <span>Password</span>}
                        {!teacherAcctPageLearnersInfo && (
                          <span>Math Level</span>
                        )}
                        {!teacherAcctPageLearnersInfo && (
                          <span>Reading Level</span>
                        )}
                        {!teacherAcctPageLearnersInfo && (
                          <span className='hide_on_ipad'>
                            Grade Locked
                            <button
                              className='info-circle-btn'
                              disabled={isNotInExpandedClass}
                              onClick={() => {
                                setPopupInfo({
                                  header: "Grade Lock",
                                  text: `When a learner is grade locked, they cannot take any
                              lessons below or above their specified grade levels for
                              their subjects. This setting can be toggled on and off.`,
                                });
                                setShowPopupInfo(true);
                              }}
                            >
                              <FA
                                icon='info-circle'
                                className='info-circle robots_table_hr'
                              />
                            </button>
                          </span>
                        )}

                        <span className='hide_on_ipad'>Actions</span>
                      </div>
                      {learners.length > 0 ? (
                        learners.map((lrn, index) => (
                          <LearnerRow
                            key={lrn.id}
                            index={index + 1}
                            lrn={lrn}
                            api={api}
                            classId={clsId}
                            clsName={clsName}
                            setShowAutosave={setShowAutosave}
                            setAutosaveSuccess={setAutosaveSuccess}
                            setShowPopupInfo={setShowPopupInfo}
                            showPopupInfo={showPopupInfo}
                            setShouldSpin={setShouldSpin}
                            setEditLearner={setEditLearner}
                            teacherAcctPageLearnersInfo={
                              teacherAcctPageLearnersInfo
                            }
                            isNotInExpandedClass={isNotInExpandedClass}
                          />
                        ))
                      ) : (
                        <p>No learners.</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <p
            style={{
              fontSize: "12px",
              cursor: "pointer",
              textAlign: "center",
              width: "fit-content",
              margin: "auto auto 5px",
            }}
            onClick={() =>
              document.getElementById("class-" + cls.id).scrollIntoView()
            }
          >
            <u>{cls.name}: Back to top</u>
          </p>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(Class);
