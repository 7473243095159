import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Gate from '../partials/Gate';
import RegistrationForm from '../components/RegistrationForm';

const Register = (props) => {
  return (
    <Gate boxWidthClass="col-lg-5 col-md-8">
      <div className="login_box">
        <div className="form-group">
          <h1>Welcome!</h1>
          <p>Enter your information below to create a new account.</p>
	        <RegistrationForm />
        </div>
      </div>
    </Gate>
  );
}

export default connect()(Register);
