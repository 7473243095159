import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { compose } from "redux";
import { useDebounce } from "use-debounce";
import config from "../../common/config";
import { useTitle } from "../../common/utils";
import RobotRow from "../../components/ManageAllRobots/RobotRow.js";
import RobotSummary from "../../components/ManageAllRobots/RobotSummary.js";
import UpdateAllRobotsOnlineModal from "../../components/ManageAllRobots/UpdateAllRobotsOnlineModal.js";
import InfoPopup from "../../components/UtilComps/InfoPopup";
import PaginationBar from "../../components/UtilComps/PaginationBar.js";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import MainDashboard from "../../partials/Main";
import { updateHasRobots } from "../../redux/actions";
import {
  getAllSchoolInfo,
  editableSubset,
} from "../../components/InputFields/helpers";
import withAPI from "../../services/api";
import CheckboxFilter from "../ManageSubscriptions/Filters/CheckboxFilter";
import "./Dashboard.scss";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  hasRobots: state.auth.hasRobots,
});

const Dashboard = ({ api, user, dispatch, hasRobots, match }) => {
  const ownRobotsOnly = () => {
    if (match.path.includes("my-robots")) {
      return true;
    } else if (user && user.usertype === config.TEACHER_USERTYPE) {
      return true;
    } else {
      return false;
    }
  };

  let title = ownRobotsOnly() ? "My Robots" : "Robots";
  useTitle(title);

  const [robots, setRobots] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [historyLoaded, setHistoryLoaded] = useState(false);
  const [robotSummaryStats, setRobotSummaryStats] = useState(null);
  const [allTeachers, setAllTeachers] = useState([]);
  const [shouldSpin, setShouldSpin] = useState(true);
  const [activatedRobotCount, setActivatedRobotCount] = useState(null);
  const [sortType, setSortType] = useState("recent_lesson_count");
  const [sortAsc, setSortAsc] = useState(false);
  const [sortTouched, setSortTouched] = useState(false);
  const [refreshRobotList, setRefreshRobotList] = useState(false);
  const [refreshRobotVersions, setRefreshRobotVersions] = useState(false);
  const [infoText, setInfoText] = useState(null);
  const [showUpdateAllModal, setShowUpdateAllModal] = useState(false);
  const [updateAllInProgress, setUpdateAllInProgress] = useState(false);
  // filters
  const [filterExpiredRobots, setFilterExpiredRobots] = useState(false);
  const [filterExpiringRobots, setFilterExpiringRobots] = useState(false);
  const [filterExcludeExpiredRobots, setFilterExcludeExpiredRobots] =
    useState(false);
  const [filterVersion, setFilterVersion] = useState(-1);
  const [debouncedFilterVersion] = useDebounce(filterVersion, 1000);
  const [robotFilterString, setRobotFilterString] = useState(null);
  const [debouncedRobotFilterString] = useDebounce(robotFilterString, 1000);
  const [filterSchoolOptions, setFilterSchoolOptions] = useState([]);
  const [filterSchoolSelected, setFilterSchoolSelected] = useState("");
  const [debouncedFilterSchoolSelected] = useDebounce(
    filterSchoolSelected,
    1000
  );

  const handleExcludeFilterChange = (val) => {
    setFilterExcludeExpiredRobots(val);
    setFilterExpiredRobots(false);
    setFilterExpiringRobots(false);
  };
  const handleExpiringRobotsFilterChange = (val) => {
    setFilterExpiringRobots(val);
    setFilterExcludeExpiredRobots(false);
    setFilterExpiredRobots(false);
  };
  const handleExpiredRobotsFilterChange = (val) => {
    setFilterExpiredRobots(val);
    setFilterExcludeExpiredRobots(false);
    setFilterExpiringRobots(false);
  };

  const fetchRobots = (pageNum, forceCalcStats = null) => {
    const calcStats =
      forceCalcStats || (robotSummaryStats === null ? true : false);
    let queryParams = {
      q: debouncedRobotFilterString || "",
      sort: (sortAsc ? "" : "-") + sortType,
      own_robots: ownRobotsOnly(),
      calc_stats: calcStats,
      page_size: pageSize,
      get_expiring_robots: filterExpiringRobots,
      get_expired_robots: filterExpiredRobots,
      get_non_expired_robots: filterExcludeExpiredRobots,
      orgunit: filterSchoolSelected,
    };

    if (pageNum) {
      queryParams["page"] = pageNum;
    }

    // skip nulls,  empty strings, false values
    queryParams = Object.entries(queryParams).filter(([k, v]) => v);

    setShouldSpin(true);

    api.fetchAllVisibleRobots(queryParams).then((objects) => {
      setRobots(objects.results);
      console.log("objects.results", objects.results);
      delete objects["results"];
      if (calcStats) {
        setRobotSummaryStats(objects.stats);
      }
      delete objects["stats"];
      setPaginationInfo(objects);
      setShouldSpin(false);
      console.log("ManageRobots Dashboard got robots: ", objects);
    });
  };

  useEffect(() => {
    getAllSchoolInfo(api).then((allSchoolValues) => {
      setFilterSchoolOptions(editableSubset(allSchoolValues, user));
    });
  }, [user]);

  // Check source page
  let history = useHistory();
  useEffect(() => {
    if (
      history.location.state &&
      user.usertype !== config.SUPERADMIN_USERTYPE &&
      history.location.state.from === "usage_stat"
    ) {
      setSortType("recent_lesson_count");
      setSortAsc(true);
      setHistoryLoaded(true);
    } else if (
      history.location.state &&
      user.usertype === config.SUPERADMIN_USERTYPE &&
      history.location.state.from === "usage_stat"
    ) {
      setSortType("last_synced_on");
      setSortAsc(true);
      setHistoryLoaded(true);
    } else {
      setHistoryLoaded(true);
    }
  }, [history]);

  useEffect(() => {
    if (historyLoaded) {
      fetchRobots();
    }
  }, [refreshRobotList, refreshRobotVersions, pageSize, historyLoaded]);

  useEffect(() => {
    // Only school org admins need this and is passed into RobotRow
    // for assignment
    if (
      user &&
      (user.usertype === config.ORGADMIN_USERTYPE) |
        (user.usertype === config.ORGUNITADMIN_USERTYPE)
    ) {
      const queryParams = {
        show_all: true,
        sort: "email",
      };
      api.fetchAllVisibleUsers(queryParams).then((objects) => {
        setAllTeachers(objects);
      });
    }
    setReloadUpdateStatusTrigger(reloadUpdateStatusTrigger + 1);
  }, [refreshRobotList]);

  useEffect(() => {
    if (
      user &&
      user.usertype === config.SUPERADMIN_USERTYPE &&
      activatedRobotCount === null
    ) {
      api.fetchRobotsActivationCount().then((res) => {
        setActivatedRobotCount(res.robot_count);
      });
    }
  }, [user]);

  useEffect(() => {
    api.fetchObjectsForTeacher().then((objects) => {
      // Update hasRobots in both sessionStorage and redux accordingly
      const hasRobotsTmp = { ...hasRobots };
      hasRobotsTmp["user"] = objects.robots.length > 0 ? true : false;
      if (hasRobotsTmp["user"] !== hasRobots["user"]) {
        sessionStorage.setItem("hasRobots", JSON.stringify(hasRobotsTmp));
        dispatch(updateHasRobots(hasRobotsTmp));
      }
    });
  }, []);

  // non-text based filtering options
  useEffect(() => {
    setFilterVersion(filterVersion + 1);
  }, [filterExpiredRobots, filterExpiringRobots, filterExcludeExpiredRobots]);

  /* TRACK UPDATEMONKEY PROGRESS */
  const [previousProgress, setPreviousProgress] = useState({});
  const [totalProgress, setTotalProgress] = useState({});
  const [reloadUpdateStatusTrigger, setReloadUpdateStatusTrigger] = useState(0);
  const [progressCheckCounter, setProgressCheckCounter] = useState(0);
  const [updateAllEnded, setUpdateAllEnded] = useState(false);
  const totalProgressRef = useRef(totalProgress);
  totalProgressRef.current = totalProgress;
  const progressCheckCounterRef = useRef(progressCheckCounter);
  progressCheckCounterRef.current = progressCheckCounter;
  const updateAllInProgressRef = useRef(updateAllInProgress);
  updateAllInProgressRef.current = updateAllInProgress;

  function debugLog(...args) {
    if (config.isDevelopment) {
      console.log(...args);
    }
  }

  const getUpdateMonkeyProgress = () =>
    new Promise((resolve) => {
      setProgressCheckCounter(0);
      api
        .checkUpdateAllOnlineRobotsProgress()
        .then((progress) => {
          if (!progress.message) {
            const tmpTotalProgress = {
              message: null,
            };
            debugLog("Got unexpected progress message from backend.");
            debugLog("Let popup construct a default.");
            setTotalProgress(tmpTotalProgress);
          } else {
            if (!progress.message.running) {
              // UpdateMonkey is done!
              debugLog("UpdateMonkey is not running.");
              setUpdateAllInProgress(false);
            } else if (progress.message.running) {
              // UpdateMonkey is running!
              debugLog(
                "UpdateMonkey is still running. Check again in a few seconds."
              );
              setUpdateAllInProgress(true);
              setUpdateAllEnded(false);
            }
            let currentProgress = totalProgressRef.current;
            if (
              progress &&
              currentProgress &&
              progress.message &&
              currentProgress.message
            ) {
              let runningBefore = currentProgress.message.running;
              let runningNow = progress.message.running;
              debugLog(
                "runningBefore: ",
                runningBefore,
                " and runningNow: ",
                runningNow
              );
              if (runningBefore && !runningNow) {
                setUpdateAllEnded(true);
              }
            }
            setPreviousProgress(Object.assign({}, currentProgress));
            setTotalProgress(progress);
          }
          resolve();
        })
        .catch((error) => {
          // Error from GET progress
          console.log("Error checking progress:", error);
          resolve();
        });
    });

  function getCounter() {
    // Not required to be a separate function, just for readability
    return progressCheckCounterRef.current;
  }
  function getUpdateAllInProgress() {
    return updateAllInProgressRef.current;
  }

  const primaryTrackerFunction = () =>
    new Promise((resolve) => {
      debugLog(
        "[primary trackProgress] updateAllInProgress: ",
        getUpdateAllInProgress()
      );
      debugLog(
        "[primary trackProgress] progressCheckCounterRef.current: ",
        getCounter()
      );
      if (getUpdateAllInProgress() || getCounter() === 10) {
        debugLog("primary trackProgress loop grabbing progress...");
        getUpdateMonkeyProgress().then(() => {
          resolve();
        });
        setProgressCheckCounter(1);
      } else {
        setProgressCheckCounter(getCounter() + 1);
        resolve();
      }
    });

  function trackProgress({ primary }) {
    // If not primary, run once
    // If primary, work with counter. If updateInProgress or counter at 10, hit API
    // If update not in progress, still check occasionally, but much less frequently
    // If primary, recur in 3 seconds after waiting for endpoint to return progress
    if (primary) {
      primaryTrackerFunction().then(() => {
        // Recur the function in 3 secs
        setTimeout(function () {
          trackProgress({ primary: true });
        }, 3000);
      });
    } else {
      // Run once, don't recur
      getUpdateMonkeyProgress();
    }
  }

  useEffect(() => {
    // Start trackProgress loop on page load, or otherwise just do it once, right now.
    if (
      user &&
      [config.ORGADMIN_USERTYPE, config.ORGUNITADMIN_USERTYPE].includes(
        user.usertype
      )
    ) {
      if (reloadUpdateStatusTrigger === 0) {
        setProgressCheckCounter(10);
        trackProgress({ primary: true });
      } else if (reloadUpdateStatusTrigger > 0) {
        trackProgress({ primary: false });
      }
    }
  }, [reloadUpdateStatusTrigger]);

  useEffect(() => {
    if (totalProgress.message && previousProgress.message) {
      // Did any robots complete their update successfully?
      const newlySuccessfulRobots = totalProgress.message.robots.reduce(
        (accum, newProg) => {
          let rbtOldStatus = previousProgress.message.robots.filter(
            (rbtProg) => rbtProg.serial === newProg.serial
          );
          debugLog("newlySuccessfulRobots reduce looking at accum:", accum);
          debugLog(
            "newlySuccessfulRobots reduce comparing oldProg:",
            rbtOldStatus
          );
          debugLog("newlySuccessfulRobots reduce against newProg:", newProg);
          if (
            newProg.status === "SUCCESS" &&
            rbtOldStatus[0] &&
            rbtOldStatus[0].status &&
            rbtOldStatus[0].status !== "SUCCESS"
          ) {
            return accum.concat(newProg.serial);
          } else {
            return accum;
          }
        },
        []
      );
      if (newlySuccessfulRobots.length > 0) {
        debugLog("newlySuccessfulRobots:", newlySuccessfulRobots);
        triggerRobotVersionRefresh();
      }
    }
  }, [totalProgress, previousProgress]);

  /* END TRACK UPDATEMONKEY PROGRESS */

  // sort rows
  useEffect(() => {
    if (sortTouched === true || debouncedRobotFilterString !== null) {
      fetchRobots(null);
    }
  }, [sortType, sortAsc]);

  useEffect(() => {
    if (
      debouncedRobotFilterString !== null ||
      debouncedFilterVersion > 0 ||
      debouncedFilterSchoolSelected !== null
    ) {
      fetchRobots(null, true);
    }
  }, [
    debouncedRobotFilterString,
    debouncedFilterVersion,
    debouncedFilterSchoolSelected,
  ]);

  function triggerRobotListRefresh() {
    console.log("Triggering Refresh of Robot List");
    setRefreshRobotList(!refreshRobotList);
  }

  function triggerRobotVersionRefresh() {
    console.log("Triggering Refresh on Robot Versions");
    setRefreshRobotVersions(!refreshRobotVersions);
  }

  const handleColumnHeaderClick = (column) => {
    setSortTouched(true);
    if (sortType === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortType(column);
      setSortAsc(false);
    }
  };

  const getHeaderClasses = (column) => {
    let classes = "sortable";
    if (sortType === column) {
      if (sortAsc === false) {
        classes += " headerSortDown";
      } else {
        classes += " headerSortUp";
      }
    } else {
      classes += " headerSort";
    }
    return classes;
  };

  const orgAdminMyRobotsView = () => {
    if (user && user.usertype === config.ORGADMIN_USERTYPE && ownRobotsOnly()) {
      return true;
    } else {
      return false;
    }
  };

  const schoolOrgAdminView = () => {
    if (
      user &&
      (user.usertype === config.ORGADMIN_USERTYPE) |
        (user.usertype === config.ORGUNITADMIN_USERTYPE) &&
      user.organization.category === config.ABII_SCHOOL.toLowerCase()
    ) {
      return true;
    }
    return false;
  };

  const superAdminView = () => {
    if (user && user.usertype === config.SUPERADMIN_USERTYPE) {
      return true;
    }
    return false;
  };

  const teacherView = () => {
    if (user && user.usertype === config.TEACHER_USERTYPE) {
      return true;
    }
    return false;
  };

  //can memoize this if it becomes a performance issue
  const showRobotFilters = () => {
    if (user && user.usertype === config.SUPERADMIN_USERTYPE) {
      return true;
    }

    const hasExpiredOrExpiringRobots =
      robots.filter((r) => r.expiring_soon || r.expired).length > 0;
    return !!(
      hasExpiredOrExpiringRobots ||
      filterExcludeExpiredRobots ||
      filterExpiringRobots ||
      filterExpiredRobots
    );
  };

  const resetFilters = () => {
    setRobotFilterString("");
    setFilterSchoolSelected("");
  };

  return (
    <div>
      <MainDashboard>
        {user && user.usertype === config.SUPERADMIN_USERTYPE && (
          <RobotSummary
            // for now only export for super admins
            canExport={true}
          />
        )}
        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <h4>Robots</h4>
            {(superAdminView() || ownRobotsOnly()) && (
              <div className='d-flex justify-content-end'>
                <label className='mr-2 pt-2'>
                  <FA icon='search' />
                </label>
                &nbsp;
                <input
                  name='search-robots'
                  placeholder='Search ...'
                  className='form-control input-underline'
                  value={robotFilterString || ""}
                  onChange={(e) =>
                    setRobotFilterString(e.target.value.toLowerCase())
                  }
                />
                {schoolOrgAdminView() && !ownRobotsOnly() && (
                  <button
                    className='btn btn-primary ml-3'
                    onClick={() => {
                      api.exportRobots();
                    }}
                  >
                    Export Robots
                  </button>
                )}
              </div>
            )}
          </div>

          <div className='robot_dashboard_bg'>
            <div style={{ marginBottom: "0px" }}>
              <div className='common_table_padding' style={{ width: "100%" }}>
                {user &&
                  ["orgadmin", "orgunitadmin"].includes(user.usertype) &&
                  !ownRobotsOnly() && (
                    <div className='d-flex justify-content-center w-100 mb-3'>
                      <button
                        className='btn btn-sm btn-primary'
                        style={{ fontSize: "24px" }}
                        disabled={false}
                        onClick={() => {
                          setShowUpdateAllModal(true);
                        }}
                      >
                        <FAW icon='sync' />
                        &nbsp;
                        {updateAllInProgress ? (
                          <React.Fragment>
                            {"Update/Sync In Progress. Click for details..."}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {"Update/Sync Robots Online Now"}
                          </React.Fragment>
                        )}
                      </button>
                    </div>
                  )}
                {!superAdminView() && (
                  <p style={{ textAlign: "left", margin: "0.5rem 0 0.5rem 0" }}>
                    {`This is ${
                      ownRobotsOnly()
                        ? "a list of your robots."
                        : "the robots page."
                    } When a robot is online, you can click the Update/Sync 
                  button to start updating the robot and syncing learner data. The Update/Sync button will be 
                  disabled if your robot is off, does not appear to be 
                  online, or if an update/sync is already in progress.`}
                  </p>
                )}

                {!superAdminView() && !ownRobotsOnly() && (
                  <div className='table-filter-wrapper p-3 w-100 mb-2'>
                    <div id='filters_fields_robots_container'>
                      <h5 style={{ marginBottom: "0px" }}>Filters:</h5>

                      <TextField
                        margin='normal'
                        placeholder='Search ...'
                        name='search'
                        size='medium'
                        value={robotFilterString || ""}
                        onChange={(e) => {
                          setRobotFilterString(e.target.value.toLowerCase());
                        }}
                      />
                      <FormControl style={{ minWidth: "120px" }}>
                        <InputLabel id='school-filter-label'>
                          School:
                        </InputLabel>
                        <Select
                          labelId='school-filter-label'
                          id='school-filter-select'
                          value={filterSchoolSelected}
                          onChange={(e) => {
                            setFilterSchoolSelected(e.target.value);
                            triggerRobotListRefresh();
                          }}
                        >
                          <MenuItem value={""}>All</MenuItem>
                          {filterSchoolOptions.map((ou) => (
                            <MenuItem value={ou.id}>{ou.name}</MenuItem>
                          ))}
                          <MenuItem value={"none"}>None</MenuItem>
                        </Select>
                      </FormControl>

                      <button
                        className='btn btn-primary btn-sm mt-3 reset_filters_btn'
                        style={{ justifySelf: "baseline" }}
                        onClick={() => resetFilters()}
                      >
                        Reset Filters
                      </button>
                    </div>
                  </div>
                )}

                {robotSummaryStats && (
                  <div className='common_border w-100 mb-3'>
                    {/* Info popup */}
                    {infoText && (
                      <InfoPopup
                        popupInfo={infoText}
                        setShowPopupInfo={setInfoText}
                      />
                    )}

                    <div className='robot_summary_stats_and_filter'>
                      <div>
                        <p>
                          {"Total Robot Count:"}&nbsp;
                          {paginationInfo && paginationInfo.count}
                        </p>
                      </div>
                      <div>
                        <p>
                          {"Robots Online Now:"}&nbsp;
                          {robotSummaryStats.online_now_count}
                          &nbsp;
                        </p>
                        <button
                          className='info-circle-btn'
                          onClick={() => {
                            setInfoText({
                              header: "Robots Online Now",
                              text: "This represents the number of robots that are connected to our servers right now.",
                            });
                          }}
                        >
                          <FA
                            icon='info-circle'
                            className='info-circle'
                            title='Number of robots that are connected to our servers right now.'
                          />
                        </button>
                      </div>
                      {paginationInfo && (
                        <div>
                          <p>
                            {"Robots Online Recently:"}&nbsp;
                            {robotSummaryStats.active_robot_stat.count} /{" "}
                            {paginationInfo.count} (
                            {robotSummaryStats.active_robot_stat.perc}%)
                          </p>
                          <button
                            className='info-circle-btn'
                            onClick={() => {
                              setInfoText({
                                header: "Robots Online Recently",
                                text: "This represents the number of robots that have done an online check-in by syncing user or lesson data in the past 30 days.",
                              });
                            }}
                          >
                            <FA
                              icon='info-circle'
                              className='info-circle'
                              title='Number of robots that have checked-in online in the past 30 days.'
                            />
                          </button>
                        </div>
                      )}
                      <div>
                        <p>
                          {"Active Learners:"}&nbsp;
                          {robotSummaryStats.active_learner_count}
                        </p>
                        <button
                          className='info-circle-btn'
                          onClick={() => {
                            setInfoText({
                              header: "Active Learners",
                              text: "This represents the number of learners with lesson activity in the past 30 days.",
                            });
                          }}
                        >
                          <FA
                            icon='info-circle'
                            className='info-circle'
                            title='Number of learners with lesson activity in the past 30 days.'
                          />
                        </button>
                      </div>
                      {activatedRobotCount > 0 && (
                        <div>
                          <p>
                            {"Activated (Batch 2 robots):"}&nbsp;
                            {activatedRobotCount}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {robotSummaryStats && showRobotFilters() && (
                  <div className='common_border w-100 mb-3'>
                    <div className='robot_summary_stats_and_filter'>
                      <div>
                        <span
                          style={{
                            display: "flex",
                            flexFlow: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FormControl
                            style={{ alignItems: "center" }}
                            className='left-data-title'
                          >
                            <p style={{ margin: "0px" }}>
                              {"Subscriptions Expired/Expiring Count: "}&nbsp;
                              {robotSummaryStats.expiring_count}
                              {(schoolOrgAdminView() || teacherView()) && (
                                <button
                                  className='info-circle-btn'
                                  onClick={() => {
                                    setInfoText({
                                      header: "Expired/Expiring Robots",
                                      html: (
                                        <div>
                                          <p>
                                            This represents the number of robots
                                            that either has an expired
                                            subscription, or one that is soon to
                                            expire, without a renewal already
                                            setup. Robots that do not have an
                                            active subscription are not able to
                                            enjoy the latest software and lesson
                                            content updates.
                                          </p>
                                          {schoolOrgAdminView() ? (
                                            <p>
                                              To manage your Premium Care
                                              subscriptions, navigate to the
                                              <strong>
                                                {" "}
                                                Subscription{" "}
                                              </strong>{" "}
                                              section on the
                                              <Link to={"/admin/my-org/"}>
                                                {" "}
                                                My Organization{" "}
                                              </Link>{" "}
                                              page.
                                            </p>
                                          ) : (
                                            <p>
                                              Please contact your organization's
                                              admin to let them know your robots
                                              need to be renewed.
                                            </p>
                                          )}
                                        </div>
                                      ),
                                    });
                                  }}
                                >
                                  <FA
                                    icon='info-circle'
                                    className='info-circle'
                                    title='Number of robots with expired or expiring subscriptions.'
                                  />
                                </button>
                              )}
                            </p>
                          </FormControl>
                          <CheckboxFilter
                            filterLabel='Only show robots expiring soon'
                            checked={filterExpiringRobots}
                            setChecked={handleExpiringRobotsFilterChange}
                            popupHeaderText='Expiring Robots'
                            popupBodyText='Filters for robots with subscriptions that are expiring in the next 60 days.'
                          />
                          <CheckboxFilter
                            filterLabel='Only show expired robots'
                            checked={filterExpiredRobots}
                            setChecked={handleExpiredRobotsFilterChange}
                            popupHeaderText='Expired Robots'
                            popupBodyText='Filters for robots with expired subscriptions.'
                          />
                          <CheckboxFilter
                            filterLabel='Hide expired robots'
                            checked={filterExcludeExpiredRobots}
                            setChecked={handleExcludeFilterChange}
                            popupHeaderText='Hide Expired Robots'
                            popupBodyText='Filters for robots with active subscriptions.'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <PaginationBar
                  paginationInfo={paginationInfo}
                  fetchPageFunc={fetchRobots}
                  loading={shouldSpin}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                />
                <div
                  className='card progress-linear table-scroll-wrapper'
                  style={{ marginBottom: "0rem", minHeight: "200px" }}
                >
                  <div
                    id={`robots_table_hrs_row${
                      user.usertype === config.TEACHER_USERTYPE ||
                      orgAdminMyRobotsView()
                        ? "_my_robots"
                        : superAdminView()
                        ? "_super_admin"
                        : ""
                    }`}
                  >
                    <div>
                      <p> </p>
                    </div>

                    <div
                      className={`${getHeaderClasses(
                        "online"
                      )} robots_table_hr`}
                      onClick={() => {
                        handleColumnHeaderClick("online");
                      }}
                    >
                      <p>Online now</p>
                    </div>

                    <div
                      className='th_sortable_container robots_table_hr'
                      onClick={() => {
                        handleColumnHeaderClick("wifi");
                      }}
                    >
                      <p
                        className={getHeaderClasses("wifi")}
                        style={{ width: "100%" }}
                      >
                        Wifi name
                      </p>
                    </div>

                    <div
                      className='th_sortable_container robots_table_hr'
                      onClick={() => {
                        handleColumnHeaderClick("code_ver");
                      }}
                    >
                      <p className={getHeaderClasses("code_ver")}>Version</p>
                    </div>

                    <div
                      className='th_sortable_container robots_table_hr'
                      onClick={() => {
                        handleColumnHeaderClick("end_date");
                      }}
                    >
                      <p className={getHeaderClasses("end_date")}>
                        Expiration Date
                      </p>
                    </div>

                    <div
                      className='th_sortable_container robots_table_hr'
                      onClick={() => {
                        handleColumnHeaderClick("last_code_update");
                      }}
                    >
                      <p className={getHeaderClasses("last_code_update")}>
                        Last Software Update
                      </p>
                    </div>

                    <div
                      className='th_sortable_container robots_table_hr'
                      onClick={() => {
                        handleColumnHeaderClick("last_lesson_update");
                      }}
                    >
                      <p className={getHeaderClasses("last_lesson_update")}>
                        Last Lesson Update
                      </p>
                    </div>

                    <div
                      className='th_sortable_container robots_table_hr'
                      onClick={() => {
                        handleColumnHeaderClick("last_synced_on");
                      }}
                    >
                      <p className={getHeaderClasses("last_synced_on")}>
                        Last Online Check-in
                      </p>
                    </div>

                    {schoolOrgAdminView() && !orgAdminMyRobotsView() && (
                      <div>
                        <p
                          style={{
                            display: "flex",
                            width: "min-content",
                            alignItems: "center",
                            marginLeft: "14px",
                          }}
                        >
                          Active Learners
                          <button
                            className='info-circle-btn'
                            onClick={() => {
                              setInfoText({
                                header: "Active Learners",
                                text: "This represents the number of learners that took lessons in the past 30 days.",
                              });
                            }}
                          >
                            <FA
                              icon='info-circle'
                              className='info-circle robots_table_hr'
                              title='Number of learners that took lessons in the past 30 days.'
                            />
                          </button>
                        </p>
                      </div>
                    )}
                    {schoolOrgAdminView() && !orgAdminMyRobotsView() && (
                      <div
                        className='th_sortable_container'
                        onClick={() => {
                          handleColumnHeaderClick("recent_lesson_count");
                        }}
                      >
                        <p
                          className={getHeaderClasses("recent_lesson_count")}
                          style={{ width: "min-content" }}
                        >
                          Recent Lessons
                          <button
                            className='info-circle-btn'
                            onClick={(e) => {
                              e.stopPropagation();
                              setInfoText({
                                header: "Recent Lessons",
                                text: "This represents the number of lessons taken in the past 30 days.",
                              });
                            }}
                          >
                            <FA
                              icon='info-circle'
                              className='info-circle robots_table_hr'
                              title='Number of lessons taken in the past 30 days.'
                            />
                          </button>
                        </p>
                      </div>
                    )}

                    {superAdminView() && (
                      <div
                        className='th_sortable_container robots_table_hr'
                        onClick={() => {
                          handleColumnHeaderClick("cloud_accounts");
                        }}
                      >
                        <p className={getHeaderClasses("cloud_accounts")}>
                          Cloud Accounts?
                        </p>
                      </div>
                    )}

                    {superAdminView() && (
                      <div
                        className='th_sortable_container robots_table_hr'
                        onClick={() => {
                          handleColumnHeaderClick("organization__name");
                        }}
                      >
                        <p
                          style={{ width: "100%" }}
                          className={getHeaderClasses("organization__name")}
                        >
                          Organization
                        </p>
                      </div>
                    )}

                    {superAdminView() && (
                      <div className='robots_table_hr'>
                        <p style={{ width: "100%" }}>School</p>
                      </div>
                    )}

                    {superAdminView() && (
                      <div
                        className='th_sortable_container robots_table_hr'
                        onClick={() => {
                          handleColumnHeaderClick("organization__category");
                        }}
                      >
                        <p
                          className={getHeaderClasses("organization__category")}
                        >
                          Org Type
                        </p>
                      </div>
                    )}

                    {superAdminView() && (
                      <div
                        className='th_sortable_container robots_table_hr'
                        onClick={() => {
                          handleColumnHeaderClick("batch_name");
                        }}
                      >
                        <p className={getHeaderClasses("batch_name")}>Batch</p>
                      </div>
                    )}

                    {schoolOrgAdminView() && !orgAdminMyRobotsView() && (
                      <div className='hide_on_ipad'>
                        <p style={{ width: "100%" }}>School</p>
                      </div>
                    )}
                    {schoolOrgAdminView() && !orgAdminMyRobotsView() && (
                      <div className='robots_table_hr hide_on_ipad'>
                        <div
                          className='d-flex justify-content-between'
                          style={{ width: "100%" }}
                        >
                          <p>Teacher Info</p>
                        </div>
                      </div>
                    )}

                    {schoolOrgAdminView() && !orgAdminMyRobotsView() && (
                      <div className='robots_table_hr hide_on_ipad'>
                        <div className='d-flex justify-content-between'>
                          <p className='mr-2'>Actions</p>
                        </div>
                      </div>
                    )}

                    {(user.usertype === config.TEACHER_USERTYPE ||
                      orgAdminMyRobotsView()) && (
                      <div className='robots_table_hr'>
                        <p style={{ textAlign: "center" }}>Update/Sync</p>
                      </div>
                    )}
                  </div>

                  {paginationInfo &&
                    robots.map((rbt, ind) => (
                      <RobotRow
                        ind={paginationInfo.start_index + ind}
                        key={rbt.serial}
                        rbt={rbt}
                        teachers={allTeachers}
                        schoolOrgAdminView={schoolOrgAdminView()}
                        orgAdminMyRobotsView={orgAdminMyRobotsView()}
                        superAdminView={superAdminView()}
                        refreshFunction={triggerRobotListRefresh}
                        showOnlineNow={true}
                        updateNowDisabled={updateAllInProgress}
                        usertype={user.usertype}
                      />
                    ))}
                  {robots.length === 0 && shouldSpin && (
                    <div style={{ width: "100%" }}>
                      <FA color='gray' icon='spinner' spin /> Loading robots ...
                    </div>
                  )}
                  {robots.length === 0 && !shouldSpin && (
                    <div style={{ width: "100%" }}>No robots found.</div>
                  )}
                </div>
                <PaginationBar
                  paginationInfo={paginationInfo}
                  fetchPageFunc={fetchRobots}
                  loading={shouldSpin}
                  pageSize={pageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </MainDashboard>

      {showUpdateAllModal && (
        <UpdateAllRobotsOnlineModal
          shouldShowFunc={setShowUpdateAllModal}
          reloadRobotListFunc={triggerRobotVersionRefresh}
          setUpdateInProgress={setUpdateAllInProgress}
          updateEnded={updateAllEnded}
          totalProgress={totalProgress}
          previousProgress={previousProgress}
          qString={debouncedRobotFilterString}
        />
      )}
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(Dashboard);
