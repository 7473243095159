import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState, useRef } from "react";
import { compose } from "redux";
import config from "../../../../../common/config";
import FA from "../../../../../containers/fa";
import withAPI from "../../../../../services/api";
import "../CreateAssignmentSteps.scss";
import StepOneLessonRow from "./StepOneLessonRow";
import SearchInput from "../../../../InputFields/SearchInput";
import { getLessonSources } from "../../../../InputFields/helpers";

import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, unitDropdownPlaceholder, theme) {
  return {
    fontWeight:
      unitDropdownPlaceholder.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    whiteSpace: "unset",
    wordBreak: "break-word",
  };
}

const CreateAssignmentStepOne = ({
  display,
  lessons,
  setLessons,
  selectedLessons,
  setSelectedLessons,
  setShowSelectedLessons,
  currentPage,
  api,
  setPopupInfo,
  setShowPopupInfo,
}) => {
  const theme = useTheme();
  const [unitSelections, setUnitSelections] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [unitOptions, setUnitOptions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [unitDropdownPlaceholder, setUnitDropdownPlaceholder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [searchDesc, setSearchDesc] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [lessonSourceOptions, setLessonSourceOptions] = useState([]);
  const [selectedLessonSource, setSelectedLessonSource] = useState(
    config.LESSON_SOURCE_INTERNAL
  );
  const [openCourses, setOpenCourses] = useState(false);
  const [openItems, setOpenItems] = useState({});
  const [courseDisplayName, setCourseDisplayName] = useState("Courses");

  useEffect(() => {
    getLessonSources(api, true).then((lessonSources) => {
      setLessonSourceOptions(lessonSources);
    });
  }, []);

  /**
   * handleUnitChange handles the multiselect unit dropdown changes and pushes
   * a new list of acceptable units for the table.
   * @param {object} event
   */
  const handleUnitChange = (event) => {
    let placeholderVal = "";
    for (let i = 0; i < event.target.value.length; i++) {
      placeholderVal = `${placeholderVal} ${event.target.value[i].unit_name}${
        i < event.target.value.length - 1 ? ", " : ""
      }`;
    }
    setUnitDropdownPlaceholder(placeholderVal);
    setUnitSelections(event.target.value);
  };

  /**
   * Scrapes the pairs of selected lessons and counts how many tuts and asms
   * are selected.
   * @returns {int} number of selected lessons
   */
  const getNumberOfLessonsSelected = () => {
    let number = 0;
    for (let i = 0; i < selectedLessons.length; i++) {
      if (selectedLessons[i].tutorial.selected) number++;
      if (selectedLessons[i].assessment.selected) number++;
    }
    return number;
  };

  /**
   * fetchCoursesAndUnits sets the course list for the dropdown.
   */
  const fetchCoursesAndUnits = () => {
    setErrorMsg("");
    setLoading(true);
    api
      .fetchCoursesAndUnits()
      .then((res) => {
        setCourses(res);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setErrorMsg(
          "An error occurred while fetching courses. Please try again later."
        );
      });
  };

  /**
   * findIfSelected determines if a lesson pair is selected during population
   * of the table, but it won't run if the Clear Selections btn was pressed.
   * @param {object} item, the lesson pair fetched
   * @returns {object} of booleans, one for the tut and one for the asm
   */
  const findIfSelected = (item) => {
    let res = { tutorial: false, assessment: false };
    for (let i = 0; i < selectedLessons.length; i++) {
      if (
        selectedLessons[i].tutorial.selected &&
        selectedLessons[i].tutorial.lesson_id === item.tutorial.lesson_id
      ) {
        res.tutorial = true;
      }

      if (
        selectedLessons[i].assessment.selected &&
        item.assessment &&
        selectedLessons[i].assessment.lesson_id === item.assessment.lesson_id
      ) {
        res.assessment = true;
      }
    }
    return res;
  };

  /**
   *
   * @param {Array} unitIds list of unit ids included in the currently selected
   * course
   * @param {boolean} selectionsCleared
   */
  const fetchPairedLessons = (unitIds, selectionsCleared = false) => {
    setErrorMsg("");
    api
      .fetchPairedLessons(unitIds, selectedLessonSource)
      .then((res) => {
        let allLessons = res.map((item, index) => {
          let selectedStatus = !selectionsCleared
            ? findIfSelected(item)
            : { tutorial: false, assessment: false };
          let standard = item.standard.startsWith("CCSS")
            ? item.standard.slice(18)
            : item.standard;
          return {
            key: index,
            assessment: {
              ...item.assessment,
              type: "Assessment",
              unit: item.unit,
              course_name: item.course_name,
              standard: standard,
              standard_description: item.standard_description,
              selected: selectedStatus.tutorial,
            },
            course_name: item.course_name,
            standard: standard,
            standard_description: item.standard_description,
            tutorial: {
              ...item.tutorial,
              type: "Tutorial",
              unit: item.unit,
              course_name: item.course_name,
              standard: standard,
              standard_description: item.standard_description,
              selected: selectedStatus.assessment,
            },
            unit: item.unit,
          };
        });
        setLessons(allLessons);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setErrorMsg(
          "An error occurred while fetching lessons. Please try again later."
        );
      });
  };

  /**
   * getLessons is required for the unit dropdown and the lessons table.
   * @returns {object array} an array of lesson info for the chosen course.
   */
  const getLessons = (selectionsCleared = false) => {
    setLoading(true);
    if (!selectedCourse) {
      setLoading(false);
      return;
    }
    if (config.SEL_COURSE_IDS.includes(selectedCourse.course_id)) {
      let units = [];
      for (let i = 0; i < selectedCourse.units.length; i++) {
        units = [...units, selectedCourse.units[i].unit_id];
      }
      fetchPairedLessons(units, selectionsCleared);
    } else {
      let units = [];
      for (let i = 0; i < selectedCourse.units.length; i++) {
        units = [...units, selectedCourse.units[i].unit_id];
      }
      fetchPairedLessons(units, selectionsCleared);
    }
  };

  useEffect(() => {
    fetchCoursesAndUnits();
  }, []);

  useEffect(() => {
    getLessons();
  }, [selectedCourse, selectedLessonSource]);

  useEffect(() => {
    let number = getNumberOfLessonsSelected();
    if (number <= 10 && number > 0) {
      setSuccessMsg(
        `${number} lesson${number > 1 ? "s" : ""} added to assignment.`
      );
      setErrorMsg("");
    } else {
      setSuccessMsg("");
    }
  }, [selectedLessons]);

  useEffect(() => {
    setSuccessMsg("");
  }, [currentPage]);

  /**
   * mapLessonsByFilters populates the table of lessons.
   * @returns {Array} Rows of lessons that satisfy the filters, if they exist.
   */
  const mapLessonsByFilters = () => {
    let count = 0;
    let unitSelectionsNames = unitSelections.map(
      (value, index) => value.unit_name
    );

    let filteredLessons = lessons.filter((lesson) =>
      lesson.standard_description.toLowerCase().includes(searchDesc)
    );
    let displayedLessons = filteredLessons.map((value, index) => {
      let satisfiesFilters = true;
      if (
        (selectedCourse && value.course_id !== selectedCourse.id) ||
        (unitSelectionsNames.length > 0 &&
          unitSelectionsNames.indexOf(value.unit) === -1)
      ) {
        satisfiesFilters = false;
      }

      if (satisfiesFilters) {
        count = count + 2;
        return (
          <StepOneLessonRow
            key={index}
            index={index}
            numInDisplayTable={count - 1}
            lesson={value}
            lessons={lessons}
            setLessons={setLessons}
            selectedLessons={selectedLessons}
            setSelectedLessons={setSelectedLessons}
            numberOfLessonsSelected={getNumberOfLessonsSelected()}
            setErrorMsg={setErrorMsg}
            setPopupInfo={setPopupInfo}
            setShowPopupInfo={setShowPopupInfo}
          />
        );
      }
      return null;
    });
    return displayedLessons;
  };

  const dropdownRef = useRef(null);

  const handleCoursesClick = () => {
    setOpenCourses(!openCourses);
  };

  const handleItemClick = (item) => {
    // Find the currently open item
    const currentOpenItem = Object.keys(openItems).find(
      (key) => openItems[key] === true
    );

    // Set the open state for the selected item and the current open item
    setOpenItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
      [currentOpenItem]: false,
    }));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenCourses(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const coursePlusGradeLevel = [
    {
      primary: "MATH",
      subItems: courses
        .filter((course) => course.course_name.startsWith("MATH"))
        .map((course, index) => {
          if (index === 0) {
            return `${course.course_name.split(" ")[0]} K`;
          } else {
            return `${course.course_name.split(" ")[0]} ${index}`;
          }
        }),
    },
    {
      primary: "READING",
      subItems: courses
        .filter((course) => course.course_name.startsWith("READING"))
        .map((course, index) => {
          if (index === 0) {
            return `${course.course_name.split(" ")[0]} K`;
          } else {
            return `${course.course_name.split(" ")[0]} ${index}`;
          }
        }),
    },
    {
      primary: "SEL",
      subItems: courses
        .filter((course) => course.course_name.startsWith("SEL"))
        .map((course, index) => {
          if (index === 0) {
            return `${course.course_name.split(" ")[0]} K-2`;
          } else {
            return `${course.course_name.split(" ")[0]} 3-5`;
          }
        }),
    },
  ];

  const handleCourseChange = (selectedSubItem) => {
    // Find the selected course by matching the course name with the selectedSubItem
    const selectedCourse = courses.find(
      (course) => course.course_name === selectedSubItem
    );

    if (selectedCourse) {
      setSelectedCourse(selectedCourse);
      setUnitOptions(selectedCourse.units);
      setCourseDisplayName(selectedSubItem);
      setUnitSelections([]);

      // Collapse all other courses and their subitems, including the top-level courses dropdown
      setOpenCourses(false);
      for (const course in openItems) {
        setOpenItems((prevState) => ({
          ...prevState,
          [course]: false,
        }));
      }
    }
  };

  return (
    <div
      id='create_assignment_step_one'
      className='create_assignment_step_content'
      style={{ display: display }}
    >
      <div className='create_assignment_step_desc_container'>
        <p>
          Lesson Options: Select up to 10 lessons from this list by clicking
          their row or checkmarks. You can also select lessons from different
          courses, grade levels, and units.
        </p>
        {errorMsg ? (
          <div className='alert alert-danger' role='alert'>
            {errorMsg}
          </div>
        ) : successMsg ? (
          <div className='alert alert-success' role='alert'>
            {successMsg}
          </div>
        ) : null}
      </div>

      <div className='two_col_grid'>
        <div className='filter_container'>
          <div className='filter_description'>
            <FormControl sx={{ width: "100%" }} size='small'>
              <SearchInput
                placeholder='Search description...'
                searchTem={searchDesc}
                setSearchTerm={setSearchDesc}
              />
            </FormControl>
          </div>
          <div className='filter_course' ref={dropdownRef}>
            <FormControl sx={{ width: "100%" }} size='small'>
              <List
                component='nav'
                sx={{
                  bottom: 8,
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                <ListItemButton
                  sx={{
                    height: "40px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "white",
                      borderRadius: "4px",
                      borderColor: "black",
                    },
                  }}
                  onClick={handleCoursesClick}
                >
                  <ListItemText primary={courseDisplayName} />
                  {openCourses ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCourses} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {coursePlusGradeLevel.map((course, index) => (
                      <div key={index}>
                        <ListItemButton
                          sx={{
                            pl: 4,
                            backgroundColor: "white",
                            "&:hover": {
                              backgroundColor: "#f0f0f0",
                            },
                          }}
                          onClick={() => handleItemClick(course.primary)}
                        >
                          <ListItemText primary={course.primary} />
                          {openItems[course.primary] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={openItems[course.primary]}
                          timeout='auto'
                          unmountOnExit
                        >
                          <List component='div' disablePadding>
                            {course.subItems.map((subItem, subIndex) => (
                              <ListItemButton
                                key={subIndex}
                                sx={{
                                  pl: 8,
                                  backgroundColor: "white",
                                  "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                  },
                                }}
                                onClick={() => handleCourseChange(subItem)}
                              >
                                <ListItemText primary={subItem} />
                              </ListItemButton>
                            ))}
                          </List>
                        </Collapse>
                      </div>
                    ))}
                  </List>
                </Collapse>
              </List>
            </FormControl>
          </div>

          <div className='filter_lesson_source'>
            <FormControl sx={{ width: "100%" }} size='small'>
              <InputLabel>Lesson Source</InputLabel>
              <Select
                value={selectedLessonSource}
                label='Lesson Source'
                onChange={(e) => setSelectedLessonSource(e.target.value)}
                options={lessonSourceOptions}
              >
                {lessonSourceOptions.map((opt, index) => (
                  <MenuItem key={index} value={opt.value}>
                    {console.log("VALUE.LESSONSOURCE: " + opt.label)}
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className='filter_unit_filter'>
            <FormControl sx={{ width: "100%" }} size='small'>
              <Select
                multiple
                displayEmpty
                size='small'
                value={unitSelections}
                onChange={handleUnitChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Add a unit filter...</em>;
                  }
                  let placeholderVal = "";
                  for (let i = 0; i < unitSelections.length; i++) {
                    placeholderVal = `${placeholderVal} ${
                      unitSelections[i].unit_name
                    }${i < unitSelections.length - 1 ? ", " : ""}`;
                  }
                  return placeholderVal;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value=''>
                  <em>
                    {selectedCourse
                      ? "Add a unit filter..."
                      : "Select a course to begin."}
                  </em>
                </MenuItem>
                {unitOptions.map((value) => (
                  <MenuItem
                    key={value.unit_id}
                    value={value}
                    style={getStyles(
                      value.unit_name,
                      unitDropdownPlaceholder,
                      theme
                    )}
                  >
                    {value.unit_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className='buttons_right_of_filters'>
          <button
            className='first_button btn btn-sm btn-primary'
            onClick={() => setShowSelectedLessons(true)}
          >
            View selected lessons ({getNumberOfLessonsSelected()})
          </button>

          <button
            className='second_button btn btn-sm btn-danger'
            onClick={() => {
              if (!selectedCourse || selectedLessons.length === 0) return;
              setSelectedLessons([]);
              getLessons(true);
            }}
          >
            <FA color='white' icon='times' /> Clear all selections
          </button>
        </div>
      </div>

      <div id='unit_selection_flexbox'>
        {unitSelections.map((value, index) => (
          <div key={index} className='unit_selection'>
            {value.unit_name}{" "}
            <button
              className='unit_selection_cancel_btn'
              onClick={() => {
                if (unitSelections.length === 1) {
                  setUnitSelections([]);
                  return;
                }
                setUnitSelections(
                  unitSelections.filter((item) => item !== value)
                );
              }}
            >
              <FA icon='times' color='#495057' />
            </button>
          </div>
        ))}
      </div>

      <div
        className={`create_assignment_table_container visible-scrollbar ${
          !selectedCourse || lessons.length === 0 ? "no_content" : "has_content"
        }`}
      >
        {/* TODO: put into its own component */}
        <div className='choose_lesson_table'>
          <div className='choose_lesson_table_header'>
            <p></p>
            <p>Course</p>
            <p>Unit</p>
            <p style={{ textAlign: "center" }}>Standard</p>
            <p style={{ textAlign: "center" }}>Type</p>
            <p>Lesson Name</p>
            <p style={{ textAlign: "center" }}>Preview</p>
            <p style={{ textAlign: "center" }}>Selected</p>
          </div>
          <div className='choose_lesson_table_rows_container visible-scrollbar'>
            {loading ? (
              <div className='no_lessons_to_select'>
                <FA icon='spinner' spin /> Loading...
              </div>
            ) : lessons.length > 0 &&
              (selectedCourse || unitSelections.length > 0) ? (
              mapLessonsByFilters()
            ) : !selectedCourse ? (
              <div className='no_lessons_to_select'>
                Select a course to begin.
              </div>
            ) : lessons.length === 0 ? (
              <div className='no_lessons_to_select'>No lessons available.</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI)(CreateAssignmentStepOne);
