import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import FA from '../containers/fa';
import FAW from '../containers/faw';

import './WiFiForm.css';
import withAPI from '../services/api';

const ABiiResourceStatus = ({api}) => {

  const [accessStatus, setAccessStatus] = useState('');
  const [wifiStatus, setWifiStatus] = useState('');
  const [hasError, setHasError] = useState(false);

  const [accessSpin, setAccessSpin] = useState(false);

  const checkWifi = (fullCheck=false, forceCheck=false) => new Promise( (resolve) => {
    api.fetchWifiStatus(fullCheck, forceCheck).then((status) => {
      console.log(status)

      let newAccessState = {};
      if (fullCheck === true) {
        // First two not really used right now
        if (status['critical_cons'] && status['critical_cons']['connected'] != null) {
          newAccessState.hasCriticalAccess = status['critical_cons']['connected'];
        }
        let criticalStatusMessage = null;
        if (status['critical_cons'] && status['critical_cons']['msg']) {
          criticalStatusMessage = status['critical_cons']['msg'];
          if (status['critical_cons']['status'] === 'IS_CHECKING') {
            criticalStatusMessage = "Controller is checking for access... Please try again.";
          }
          newAccessState.criticalStatusMessage = criticalStatusMessage;
        }
        if (status['critical_cons'] && status['critical_cons']['comm_management_http_con'] != null) {
          newAccessState.http_comm_management = status['critical_cons']['comm_management_http_con'];
        }
        if (status['critical_cons'] && status['critical_cons']['abii_update_http_con'] != null) {
          newAccessState.http_abii_update = status['critical_cons']['abii_update_http_con'];
        }
        if (status['critical_cons'] && status['critical_cons']['abii_update_ssh_con'] != null) {
          newAccessState.ssh_abii_update = status['critical_cons']['abii_update_ssh_con'];
        }
        if (status['critical_cons'] && status['critical_cons']['has_internet'] != null) {
          newAccessState.hasInternet = status['critical_cons']['has_internet'];
        }
        if (status['critical_cons'] && status['critical_cons']['dns_can_resolve'] != null) {
          newAccessState.DNSresolved = status['critical_cons']['dns_can_resolve'];
        }
      }
      
      let newState = {}
      if (status['associated']===true) {
        newState.associated = true
        setWifiStatus(newState)
        setHasError(false);
        if (fullCheck === true && newAccessState !== {}) {
          setAccessStatus(newAccessState);
        }
      } else {
        setHasError(false);
        setWifiStatus({
          associated: false,
        })
      }
      // set next checking depending on status
      if (status['critical_cons'] && status['critical_cons']['status'] === 'IS_CHECKING') {
        setHasError(false);
        resolve('is_checking');
      } else {
        setHasError(false);
        resolve('standard');
      }
    }).catch((error) => {
      console.log(error);
      console.log('Error checking wifi');
      resolve('standard');
      setWifiStatus({
        associated: false,
      })
      setHasError(true);
    })
  })

  // useEffect(() => {

  //   const fetchWifiScheduler = (fullCheck=true, forceCheck=true) => {
  //     checkWifi(fullCheck, forceCheck).then( (data) => {
  //       console.log(data)
  //     });
  //   };
  //   fetchWifiScheduler();
  // }, []);


  const [infoText, setInfoText] = useState('');
  const [showInfoModal, setShowInfoModal] = useState(false);  

  const showMoreInfo = (infoSection) => {
    if (infoSection === 'dns') {
      setInfoText("ABii must be able to resolve domain names with DNS requests");
    } else if (infoSection === 'general') {
      setInfoText("ABii must be able to access content on the internet");
    } else if (infoSection === 'http-comm') {
      setInfoText("ABii must be able to complete an HTTP request to comm-management.abiis-world.com. \
              Please whitelist this domain in any content filtering software you are using, \
              and ensure that your firewall will allow outgoing HTTP/HTTPS connections through ports 80 and 443");
    } else if (infoSection === 'http-update') {
      setInfoText("ABii must be able to complete an HTTP request to abii-update.abiis-world.com. \
              Please whitelist this domain in any content filtering software you are using, \
              and ensure that your firewall will allow outgoing HTTP/HTTPS connections through ports 80 and 443");
    } else if (infoSection === 'ssh-update') {
      setInfoText("ABii must be able to establish an ssh connection to abii-update.abiis-world.com. \
              Please whitelist this domain in any content filtering software you are using, \
              and ensure that your firewall will allow outgoing\
              ssh connections through ports 22 and 80");
    }
    setShowInfoModal(true);
  }


  const renderErrorDiv = () => {
    return(
      <div style={{"width": "100%"}}>
        <span>
          <h3> An error has occurred checking for connection status. </h3>
        </span>              
      </div>
    )
  }

  const renderCriticalConn = () => {

    return(
      <div style={{"width": "100%"}}>
        {!accessSpin ?
        <span>
          {accessStatus.DNSresolved != null && 
            <span> 
              {accessStatus.DNSresolved ? 
                <span> <FAW icon="check" className="has-wifi status-icon" /></span> :
                <span> <FAW icon="times" className="no-wifi status-icon" /></span>} 
              DNS resolution 
              <FAW icon="info-circle" className="info-circle" onClick={() => {showMoreInfo('dns')}} onBlur={()=>{setShowInfoModal(false)}}/>: 
              <span className="resource-access-value"> 
                {accessStatus.DNSresolved ? 
                  <span> Yes</span> :
                  <span> No</span>} 
              </span> <br/>
            </span>
          }
          {accessStatus.hasInternet != null && 
            <span> 
              {accessStatus.hasInternet ? 
                <span> <FAW icon="check" className="has-wifi status-icon" /></span> :
                <span> <FAW icon="times" className="no-wifi status-icon" /></span>} 
              General Internet Connection 
              <FAW icon="info-circle" className="info-circle" onClick={() => {showMoreInfo('general')}} onBlur={()=>{setShowInfoModal(false)}}/>:
              <span className="resource-access-value">
                {accessStatus.hasInternet ?
                <span> Yes</span> :
                <span> No</span>} 
              </span> <br/>
            </span>
          }
          {accessStatus.http_comm_management != null && 
            <span>
              {accessStatus.http_comm_management ? 
                <span> <FAW icon="check" className="has-wifi status-icon" /></span> :
                <span> <FAW icon="times" className="no-wifi status-icon" /></span>} 
                HTTP "comm-management.abiis-world.com" 
              <FAW icon="info-circle" className="info-circle" onClick={() => {showMoreInfo('http-comm')}} onBlur={()=>{setShowInfoModal(false)}}/>:
              <span className="resource-access-value">
                {accessStatus.http_comm_management ?
                  <span> Yes</span> :
                  <span> No</span>} 
              </span> <br/>
            </span>
          }
          {accessStatus.http_abii_update != null && 
            <span> 
              {accessStatus.http_abii_update ? 
                <span> <FAW icon="check" className="has-wifi status-icon" /></span> :
                <span> <FAW icon="times" className="no-wifi status-icon" /></span>} 
                HTTP "abii-update.abiis-world.com" 
              <FAW icon="info-circle" className="info-circle" onClick={() => {showMoreInfo('http-update')}} onBlur={()=>{setShowInfoModal(false)}}/>:
              <span className="resource-access-value"> 
                {accessStatus.http_abii_update ?
                  <span> Yes</span> :
                  <span> No</span>} 
              </span> <br/>
            </span>
          }
          {accessStatus.ssh_abii_update != null && 
            <span>
              {accessStatus.ssh_abii_update ? 
                <span> <FAW icon="check" className="has-wifi status-icon" /></span> :
                <span> <FAW icon="times" className="no-wifi status-icon" /></span>} 
                SSH "abii-update.abiis-world.com" 
              <FAW icon="info-circle" className="info-circle" onClick={() => {showMoreInfo('ssh-update')}} onBlur={()=>{setShowInfoModal(false)}}/>:
              <span className="resource-access-value">
                {accessStatus.ssh_abii_update ?
                  <span> Yes</span> :
                  <span> No</span>}
              </span> <br/>
            </span>
          }
          <button id="check-access-btn" className="btn btn-lg fs-14 btn-primary" type="button" onClick={() => {
            setAccessSpin(true);
            checkWifi(true, true).then( (data) => {
              setAccessSpin(false);
            });
          }}> Refresh Access Status </button>
        </span>              
          :
        <span>
          {accessStatus.DNSresolved != null && 
            <span> DNS resolution: 
            <span className="resource-access-value"> --- </span> <br/> </span>
          }
          {accessStatus.hasInternet != null && 
            <span> General Internet connection: 
            <span className="resource-access-value"> --- </span> <br/> </span>
          }
          {accessStatus.http_comm_management != null && 
            <span> HTTP "comm-management.abiis-world.com":
            <span className="resource-access-value"> --- </span> <br/> </span>
          }
          {accessStatus.http_abii_update != null && 
            <span> HTTP "abii-update.abiis-world.com":
            <span className="resource-access-value"> --- </span> <br/> </span>
          }
          {accessStatus.ssh_abii_update != null && 
            <span> SSH "abii-update.abiis-world.com":
            <span className="resource-access-value"> --- </span> <br/> </span>
          }
          <button id="check-access-btn" className="btn btn-lg fs-14 btn-primary" type="button">
          <FAW icon="spinner" spin /> Checking Access... </button>
        </span> 
        }
      </div>
    )
  }

return(
    <div className="col-12">

      <div className="row">
        <div className="col-12">
          <Link to="/setup-wifi">&laquo; Back</Link>
        </div>
      </div>

      <div className="row" id="access-status-box">
        {(!wifiStatus.associated && !hasError)?
          <h4> <FA icon="spinner" spin /> Checking access... Please make sure you are connected to Wifi network. </h4>
          :
          renderCriticalConn()
        }
        {hasError && renderErrorDiv()}
      </div>

      {showInfoModal &&
        <div id="info-modal" onClick={() => {setShowInfoModal(false)}}>
          <div id="info-modal-inner" onClick={(e) => {e.stopPropagation()}}>
            <p><FAW icon="times" className="info-close-btn" onClick={() => {setShowInfoModal(false)}} /> </p>
            <p className="info-modal-text"> {infoText} </p>
          </div>
        </div>
      }

    </div>
  );
}



export default compose(
  withAPI
)(ABiiResourceStatus);
