import React, {useState, useEffect} from "react";
import {compose} from "redux";
import withAPI from "../../services/api";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SettingsInfoPopup from "../UtilComps/SettingsInfoPopup";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const OrgSyncSettingsModal = ({
  api,
  orgId,
  orgSettings,
  refreshOrgInfo,
  setShowModal,
}) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    let _settings = null;

    const defaultSettings = {
      syncEnabled: false,
      passwordGeneration: '1234',
      usernameGeneration: 'name'
    };

    if (orgSettings) {
      _settings = {...orgSettings};
    } else {
      _settings = {...defaultSettings};
    }

    setSyncEnabled(_settings.clever_sync_enabled);
    setPasswordGeneration(_settings.clever_learner_password_scheme);
    setUsernameGeneration(_settings.clever_learner_username_scheme);
    setOrguidGeneration(_settings.clever_learner_orguid_scheme);
  }, [orgSettings]);

  const settingsNameMap = {
    '1234': '1234',
    'student_number': 'Student Number',
    'state_id': 'State ID',
    'sis_id': 'SIS ID',
    'clever_id': 'Clever ID',
    'district_username': 'District Username',
    'name': 'Name',
    'email': 'Email',
    'email_before_at': 'Email before @'
  }

  const mapSettingToLabel = (setting) => {
    return settingsNameMap[setting];
  };

  const [syncEnabled, setSyncEnabled] = useState(false);
  const [passwordGeneration, setPasswordGeneration] = useState('1234');
  const [usernameGeneration, setUsernameGeneration] = useState('name');
  const [orguidGeneration, setOrguidGeneration] = useState('student_number');

  const passwordGenerationChoices = ["1234", "student_number", "state_id", "sis_id"];
  const usernameGenerationChoices = ["name", "email", "email_before_at",
    "district_username", "student_number", "state_id", "sis_id"];
  const orguidGenerationChoices = ["clever_id", "student_number", "state_id", "sis_id"];

  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    header: "",
    text: "",
  });

  const handleSubmit = () => {
    setError(null);
    setSaving(true);
    setSuccess(false);

    const data = {
      settings: {
        clever_sync_enabled: syncEnabled,
        clever_learner_password_scheme: passwordGeneration,
        clever_learner_username_scheme: usernameGeneration,
        clever_learner_orguid_scheme: orguidGeneration,
      },
    };

    api
      .patchOrganizationInfo(orgId, data)
      .then((resp) => {
        setSaving(false);
        setSuccess(true);
        refreshOrgInfo();
      })
      .catch((err) => {
        setSaving(false);
        setError("Something went wrong... Please try again later.");
        console.log("API error'd", err);
      });
  };
  const handleSyncEnabledChange = (e) => {
    setSyncEnabled(!syncEnabled);
  };

  const handlePasswordGenerationChange = (e) => {
    console.log(e.target.value);
    setPasswordGeneration(e.target.value);
  };

  const handleUsernameGenerationChange = (e) => {
    console.log(e.target.value);
    setUsernameGeneration(e.target.value);
  };

  const handleOrguidGenerationChange = (e) => {
    console.log(e.target.value);
    setOrguidGeneration(e.target.value);
  };

  const getSyncHelpText = (settingChoice) => {
    if (settingChoice === "syncEnabled") {
      return (
        <div>
          <strong>Sync Enabled</strong>
          <p>
            This setting will enable/disable syncing data from the Clever
            system. If disabled, the data will remain but changes to the data
            will not be synced.
          </p>
        </div>
      );
    } else if (settingChoice === "passwordGeneration") {
      return (
        <div>
          <strong>Password Generation options: </strong>
          <ul>
            <li>
              <b>1234: </b>This will set learners' passwords to '1234'.
            </li>
            <li>
              <b>Student Number: </b>This will set learners' passwords to their
              student number, if it's available in Clever. Otherwise, it will
              default to '1234'.
            </li>
            <li>
              <b>State ID: </b>This will set learners' passwords to their state
              ID, if it's available in Clever. Otherwise, it will default to
              '1234'.
            </li>
            <li>
              <b>SIS ID: </b>This will set learners' passwords to their SIS ID.
              This is described by Clever as the "internal student identifier
              from source system" and is guaranteed to be present in the Clever
              data.
            </li>
          </ul>
        </div>
      );
    } else if (settingChoice === "usernameGeneration") {
      return (
        <div>
          <strong>Username Generation options: </strong>
          <ul>
            <li>
              <b>Name: </b>This will generate a username for each learner based
              on their full name. If another learner exists with the same name,
              then it will append numbers on the username to make it unique.
            </li>
            <li>
              <b>Email: </b>This will set every learners' username to their
              email, if it's available in Clever. Otherwise, it will default to
              their SIS ID.
            </li>
            <li>
              <b>Email before @: </b>This will set every learners' username to
              the portion of their email before the @ symbol, if they have an
              email address in Clever. If not, it will default to their SIS ID.
            </li>
            <li>
              <b>District Username: </b>This will set every learners' username
              to their district username, if it's available in Clever.
              Otherwise, it will default to their SIS ID.
            </li>
            <li>
              <b>Student Number: </b>This will set every learners' username to
              their student number, if it's available in Clever. Otherwise, it
              will default to their SIS ID.
            </li>
            <li>
              <b>State ID: </b>This will set every learners' username to their
              state ID, if it's available in Clever. Otherwise, it will default
              to their SIS ID.
            </li>
            <li>
              <b>SIS ID: </b>This will set every learners' username to their
              SIS ID. This is described by Clever as the "internal student
              identifier from source system" and is guaranteed to be present in
              the Clever data.
            </li>
          </ul>
        </div>
      );
    } else if (settingChoice === "orguidGeneration") {
      return (
        <div>
          <strong>Organization UID Generation options: </strong>
          <ul>
            <li>
              <b>Clever ID: </b>This will set every learners' user ID to their
              unique ID in the Clever system.
            </li>
            <li>
              <b>Student Number: </b>This will set every learners' user ID to
              their student number, if it's available in Clever. Otherwise, it
              will default to their Clever ID.
            </li>
            <li>
              <b>State ID: </b>This will set every learners' user ID to
              their state ID, if it's available in Clever. Otherwise, it
              will default to their Clever ID.
            </li>
            <li>
              <b>SIS ID: </b>This will set every learners' user ID to their SIS
              ID. This is described by Clever as the "internal student
              identifier from source system" and is guaranteed to be present in
              the Clever data.
            </li>
          </ul>
        </div>
      );
    }
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        {showPopupInfo && (
          <SettingsInfoPopup
            popupInfo={popupInfo}
            setShowPopupInfo={setShowPopupInfo}
          />
        )}

        <div className='common_border'>
          {/* Header row */}
          <div className='common_heading'>
            <p>Sync Settings</p>
            <ClosePopupBtn closePopupFunc={setShowModal} />
          </div>

          {/* Main body */}
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <div>
                  <table className='table table-sm'>
                    <tbody>

                      <tr>
                        <td className='item-label'>
                          Sync Enabled&nbsp;
                          <span
                            onClick={() => {
                              setPopupInfo({
                                header: "Sync Enabled Description",
                                html: getSyncHelpText("syncEnabled"),
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FAW icon='info-circle' className='info-circle' />
                          </span>
                        </td>
                        <td>
                          <Checkbox
                            style={{color: '#20a8d8'}}
                            checked={syncEnabled}
                            onChange={handleSyncEnabledChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='item-label'>
                          Password Generation&nbsp;
                          <span
                            onClick={() => {
                              setPopupInfo({
                                header: "Password Generation Description",
                                html: getSyncHelpText("passwordGeneration"),
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FAW icon='info-circle' className='info-circle' />
                          </span>
                        </td>
                        <td>
                          <Select
                            variant='standard'
                            displayEmpty={true}
                            style={{width: "250px"}}
                            value={passwordGeneration || ""}
                            onChange={handlePasswordGenerationChange}
                          >
                            {passwordGenerationChoices.map((choice, i) => {
                              return (
                                <MenuItem key={i} value={choice}>
                                  {passwordGeneration === choice ? (
                                    <span>
                                      {mapSettingToLabel(choice)}
                                      <FAW
                                        icon='check'
                                        style={{color: "green"}}
                                        className='ml-4'
                                      />
                                    </span>
                                  ) : (
                                    <span>{mapSettingToLabel(choice)}</span>
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </td>
                      </tr>

                      <tr>
                        <td className='item-label'>
                          Username Generation&nbsp;
                          <span
                            onClick={() => {
                              setPopupInfo({
                                header: "Username Generation Description",
                                html: getSyncHelpText("usernameGeneration"),
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FAW icon='info-circle' className='info-circle' />
                          </span>
                        </td>
                        <td>
                          <Select
                            variant='standard'
                            displayEmpty={true}
                            style={{width: "250px"}}
                            value={usernameGeneration || ""}
                            onChange={handleUsernameGenerationChange}
                          >
                            {usernameGenerationChoices.map((choice, i) => {
                              return (
                                <MenuItem key={i} value={choice}>
                                  {usernameGeneration === choice ? (
                                    <span>
                                      {mapSettingToLabel(choice)}
                                      <FAW
                                        icon='check'
                                        style={{color: "green"}}
                                        className='ml-4'
                                      />
                                    </span>
                                  ) : (
                                    <span>{mapSettingToLabel(choice)}</span>
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </td>
                      </tr>

                      <tr>
                        <td className='item-label'>
                          Organization UID Generation&nbsp;
                          <span
                            onClick={() => {
                              setPopupInfo({
                                header: "Organization UID Generation Description",
                                html: getSyncHelpText("orguidGeneration"),
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FAW icon='info-circle' className='info-circle' />
                          </span>
                        </td>
                        <td>
                          <Select
                            variant='standard'
                            displayEmpty={true}
                            style={{width: "250px"}}
                            value={orguidGeneration || ""}
                            onChange={handleOrguidGenerationChange}
                          >
                            {orguidGenerationChoices.map((choice, i) => {
                              return (
                                <MenuItem key={i} value={choice}>
                                  {orguidGeneration === choice ? (
                                    <span>
                                      {mapSettingToLabel(choice)}
                                      <FAW
                                        icon='check'
                                        style={{color: "green"}}
                                        className='ml-4'
                                      />
                                    </span>
                                  ) : (
                                    <span>{mapSettingToLabel(choice)}</span>
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </td>
                      </tr>

                      <tr></tr>
                    </tbody>
                  </table>

                  <div className='d-flex'>
                    {!saving && (
                      <button
                        className='btn btn-sm btn-primary mr-2'
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Save Changes
                      </button>
                    )}
                    {saving && (
                      <button className='btn btn-sm btn-primary mr-2'>
                        <FA className='mr-2' icon='spinner' spin />
                        Saving ...
                      </button>
                    )}
                    <button
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>

                  {error && (
                    <div className='alert alert-danger my-2'>{error}</div>
                  )}

                  {success && (
                    <div className='alert alert-success my-2'>
                      Clever sync settings saved successfully!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI)(OrgSyncSettingsModal);
