import React from "react";
import {
  getDataColor,
  getHeaderClasses,
} from "../../../ConsolidatedReportsUtilities";

import {
  getGridTemplateColumns,
  getMinWidthOfRows,
} from "../LearnerScoresTableUtilities";
/**
 * createLessonAvgRow creates the final row of the table, which displays the
 * average for each lesson. Only learners who took the lesson count.
 * @returns {html} the last row, the averages for the lessons
 */
const LessonAveragesRow = ({
  filteredLessons,
  hasBorder,
  handleColumnHeaderClick,
  setSortedLearnerId,
  setSortLessonAvgsAsc,
  sortLessonAvgsAsc,
  sortType,
  setSortingStandardsAsc,
}) => {
  let avgs = filteredLessons.map((value, index) => {
    return (
      <div
        key={index}
        className={`data score row_averages ${getDataColor(value.average)}`}
        style={{
          borderRight: hasBorder(index, filteredLessons.length),
        }}
      >
        <p>{value.average !== null ? value.average : "-"}</p>
      </div>
    );
  });
  return (
    <div
      className='reports_row'
      style={{
        gridTemplateColumns: getGridTemplateColumns(filteredLessons),
        minWidth: getMinWidthOfRows(filteredLessons),
        borderTop: "2px solid rgba(0,0,0,0.4)",
      }}
      onClick={() => {
        handleColumnHeaderClick(filteredLessons, "lessonAverage");
        setSortedLearnerId(null);
        setSortLessonAvgsAsc(!sortLessonAvgsAsc);
        setSortingStandardsAsc(null);
      }}
    >
      <p
        className={`name lesson_avg_label ${getHeaderClasses(
          "lessonAverage",
          sortType,
          sortLessonAvgsAsc
        )}`}
      >
        <b>Average</b>
      </p>
      {avgs}
    </div>
  );
};

export default LessonAveragesRow;
