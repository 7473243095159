import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";

const UpdateRobotModal = ({ api, rbt, shouldShowFunc, statusReloadFunc }) => {
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [confirmingUpdate, setConfirmingUpdate] = useState(false);
  const [spinUpdate, setSpinUpdate] = useState(false);
  const [triggerResult, setTriggerResult] = useState(null);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [robotStatusInfo, setRobotStatusInfo] = useState(null);
  const [updateAllowed, setUpdateAllowed] = useState(true);

  useEffect(() => {
    console.log("Robot info:", rbt);
    // Check for a regent status message
    api
      .fetchCachedRobotStatus(rbt.serial)
      .then((status_json) => {
        if (status_json && !status_json.status) {
          // No status message in cache. Do we want to do anything about that?
        }
        setRobotStatusInfo(status_json.status);
        setLoadingStatus(false);
      })
      .catch((e) => {
        console.log("fetchRobotSTatusFile Error'd:", e);
        setLoadingStatus(false);
      });
  }, []);

  useEffect(() => {
    if (robotStatusInfo && robotStatusInfo.update_allowed !== null) {
      setUpdateAllowed(robotStatusInfo.update_allowed);
    }
  }, [robotStatusInfo]);

  function handleUpdate() {
    if (confirmingUpdate) {
      setTriggerResult(null);
      setTriggerSuccess(false);
      setConfirmingUpdate(false);
      setSpinUpdate(true);
      const updateOptDefaults = {
        serial: rbt.serial,
        lesson: true,
        code: true,
      };

      api
        .UpdateOnlineRobot(updateOptDefaults)
        .then((resp) => {
          console.log("Hit update endpoint successfully");
          console.log("Resp:", resp);
          setSpinUpdate(false);
          if (resp.status === "error") {
            setTriggerResult({
              success: false,
              message: resp.message,
            });
          } else if (resp.status === "success") {
            setTriggerResult({
              success: true,
              message: resp.message,
            });
            setTriggerSuccess(true);
            statusReloadFunc();
          } else {
            console.log(
              "Don't know how to handle this response from the backend..."
            );
          }
        })
        .catch((e) => {
          console.log("Error pinging Robot.");
          console.log(e);
          setSpinUpdate(false);
          setTriggerResult({
            success: false,
            message: "Error triggering an update: " + e,
          });
        });
    } else {
      setConfirmingUpdate(true);
    }
  }

  function buttonText() {
    if (confirmingUpdate) {
      return "Please Confirm";
    } else if (spinUpdate) {
      return (
        <React.Fragment>
          <FA color='white' icon='spinner' spin />
          &nbsp;{" Update/Sync in progress..."}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <FA color='white' icon='sync' />
          &nbsp;{`Update/Sync ${rbt.wifi} now`}
        </React.Fragment>
      );
    }
  }

  function ConfirmationModal({ shouldShowFunc, handleSubmit }) {
    return (
      <React.Fragment>
        <div className='pdf-upload'></div>
        <div className='pdf-upload-wrapper-small'>
          <div className='common_border'>
            <div className='common_heading' style={{ margin: "0px" }}>
              <p>{"Confirm Update/Sync"}</p>
              <button
                className='pdf_popup_close'
                onClick={() => shouldShowFunc(false)}
              >
                <FA icon='times' />
              </button>
            </div>

            <div className='common_dashboard_bg' style={{ margin: "0px" }}>
              <div className='row'>
                <div className='col-12'>
                  {
                    "While updating/syncing, this robot will be unavailable for use and may need to reboot."
                  }
                  <br />
                  {"Are you sure you want to update/sync this robot now?"}
                  <br />
                  <div className='d-flex mt-4'>
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={() => {
                        handleSubmit();
                        shouldShowFunc(false);
                      }}
                    >
                      {"Yes, update/sync now"}
                    </button>
                    <button
                      className='btn btn-sm btn-danger ml-4'
                      onClick={() => {
                        shouldShowFunc(false);
                      }}
                    >
                      {"Not now"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className='pdf-upload'></div>
      <div
        className='pdf-upload-wrapper'
        style={{ display: "block", position: "fixed" }}
      >
        <div className='common_border'>
          <div className='common_heading' style={{ margin: "0px" }}>
            <p>
              {"This robot, "}
              {rbt.wifi}
              {", is currently online"}
            </p>
            <button
              className='pdf_popup_close'
              onClick={() => shouldShowFunc(false)}
            >
              <FA icon='times' />
            </button>
          </div>
          <div className='common_dashboard_bg' style={{ margin: "0px" }}>
            <div className='row'>
              <div className='col-12'>
                {!rbt.can_update && (
                  <div className='row ml-3'>
                    <FA
                      color='red'
                      icon='exclamation-circle'
                      className='mr-2'
                    />
                    This robot cannot be updated, as its subscription has
                    expired.
                  </div>
                )}

                {rbt.can_update && (
                  <React.Fragment>
                    {loadingStatus && (
                      <div className='row ml-3'>
                        <FA color='black' icon='spinner' spin />
                        &nbsp;{"Loading status..."}
                      </div>
                    )}

                    {!loadingStatus && !triggerSuccess && (
                      <React.Fragment>
                        {updateAllowed ? (
                          <React.Fragment>
                            {spinUpdate ? (
                              <div>
                                <FA color='black' icon='spinner' spin />
                                &nbsp;{"Triggering an update/sync..."}
                              </div>
                            ) : (
                              <div>
                                {triggerResult?.success ? (
                                  <React.Fragment>
                                    <FA color='black' icon='spinner' spin />
                                    &nbsp;{"Update/Sync in progress..."}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {`This robot can be updated/synced now.
                                      This will update your robot's software and lesson content, and keep learner data in sync.`}
                                  </React.Fragment>
                                )}
                              </div>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {
                              "Updating/Syncing is not allowed when a robot is currently in use, or when an update/sync is already in progress."
                            }
                          </React.Fragment>
                        )}

                        <div
                          className='my-3 d-flex align-items-center update-robot-modal-btn'
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <button
                            className='btn btn-md btn-primary mr-3'
                            style={{ minWidth: "200px" }}
                            onClick={() => {
                              handleUpdate();
                            }}
                            disabled={
                              !updateAllowed ||
                              spinUpdate ||
                              triggerResult?.success
                            }
                          >
                            {buttonText()}
                          </button>
                        </div>

                        {confirmingUpdate &&
                          ConfirmationModal({
                            shouldShowFunc: setConfirmingUpdate,
                            handleSubmit: handleUpdate,
                          })}
                      </React.Fragment>
                    )}

                    {triggerResult?.success && (
                      <div className='alert alert-success'>
                        {
                          "Successfully started an update/sync on this robot. You may safely close this box."
                        }
                      </div>
                    )}

                    {triggerResult && !triggerResult.success && (
                      <div className='alert alert-danger'>
                        {
                          "Failed to start an update/sync. Make sure that this robot is not in use and has a strong connection to the internet before trying again."
                        }
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default compose(withRouter, withAPI)(UpdateRobotModal);
