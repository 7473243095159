import React, { useCallback } from "react";
import { compose } from 'redux';
import { OAuthProviders, OAuthService } from '../../services/oauth';
import withAPI from '../../services/api';

const LoginWithGoogleButton = ({api, linkToExisting}) => {

  function jsonToURI (json) {
    return encodeURIComponent(JSON.stringify(json));
  }

  const handleGoogleLogin = useCallback(async () => {
    if (linkToExisting) {
      try {
        console.log("Linking to existing...")
        const provider = new OAuthService(OAuthProviders.google);
        api.createUserCode().then((resp) => {
          const state = {
            link_to_existing: true,
            link_code: resp.usercode,
            slug: sessionStorage.getItem('authToken')
          }
          provider.redirectToLogin({state:jsonToURI(state)})
        }).catch((e) => {
          console.error(e);
        });
      } catch (e) {
        console.error(e);
      };
    } else {
      try {
        var url = OAuthProviders.google.OAUTH_LOGIN_LAUNCH_PAGE
        console.log("Redirecting to:",url);
        window.location.assign(url);
      } catch (e) {
        console.error(e);
      };
    };
  }, []);

  return (
    <div id='login_with_google'
      onClick={handleGoogleLogin}
    >
      <span class="icon"></span>
      {linkToExisting
      ? <span class="buttonText">{"Google"}</span>
      : <span class="buttonText">
          {"Log in with Google"}
        </span>
      }
    </div>
  );
}

export default compose(withAPI)(LoginWithGoogleButton)
