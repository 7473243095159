import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import withAPI from "../../services/api";
import BulkAddRobotsModal from "./BulkAddRobotsModal.js";
import "./RobotRow.scss";

const RobotRow = ({
  api,
  rbt,
  orgId,
  match,
  refreshList,
  toggleRobotToAdd,
  enableBulkAdd,
  sourceOrgInfo,
}) => {
  const [shouldSpin, setShouldSpin] = useState(false);
  const [checked, setChecked] = useState(false);

  const [showConfirmAddModal, setShowConfirmAddModal] = useState(false);

  if (orgId) {
    orgId = orgId;
  } else {
    orgId = match.params.orgId;
  }

  const addRobot = () => {
    if (
      rbt.assignment &&
      rbt.assignment.organization &&
      rbt.assignment.organization !== "Unassigned"
    ) {
      if (
        window.confirm(
          "Robot already assigned to another organization. Assign to this Org instead?"
        )
      ) {
        setShowConfirmAddModal(true);
      }
    } else {
      setShowConfirmAddModal(true);
    }
  };

  useEffect(() => {
    if (enableBulkAdd === false) {
      setChecked(false);
    }
  }, [enableBulkAdd]);

  const toggleRobotBulkAdd = () => {
    setChecked(!checked);
    toggleRobotToAdd(rbt.serial);
  };

  return (
    <tr key={rbt.serial}>
      <td>
        <span>{rbt.serial && rbt.wifi}</span>
      </td>
      <td>
        <span>{rbt.serial && rbt.mac}</span>
      </td>
      <td>
        <span>{rbt.serial && rbt.serial}</span>
      </td>
      <td>
        <span>{rbt.serial && rbt.code_ver}</span>
      </td>
      <td>
        <span>{rbt.organization?.name}</span>
      </td>
      <td name='Actions'>
        <span>
          <div className='datatable_actions'>
            <div className='d-flex common-align-button action-btns'>
              {shouldSpin && (
                <button className='btn btn-md btn-secondary mx-auto'>
                  <FA color='white' icon='spinner' spin />
                </button>
              )}
              {!shouldSpin && rbt.organization_id === parseInt(orgId) && (
                <button className='btn btn-md btn-primary mx-auto'>
                  <FAW icon='check' />
                </button>
              )}
              {!shouldSpin &&
                rbt.organization_id !== parseInt(orgId) &&
                !enableBulkAdd && (
                  <button
                    className='btn btn-md btn-primary mx-auto'
                    onClick={() => {
                      addRobot();
                    }}
                  >
                    <FAW icon='plus' />
                  </button>
                )}
              {!shouldSpin &&
                rbt.organization_id !== parseInt(orgId) &&
                enableBulkAdd && (
                  <input
                    type='checkbox'
                    name='rbt-checkbox'
                    className='form-control'
                    value={checked}
                    onClick={() => {
                      toggleRobotBulkAdd();
                    }}
                  />
                )}
            </div>
          </div>

          {showConfirmAddModal && (
            <BulkAddRobotsModal
              rbtSerials={[rbt.serial]}
              shouldShowFunc={setShowConfirmAddModal}
              refreshList={refreshList}
              orgName={sourceOrgInfo.name}
            />
          )}
        </span>
      </td>
    </tr>
  );
};

export default compose(withRouter, withAPI)(RobotRow);
