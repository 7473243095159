/**
 * selectOrDeselectLesson handles adding and removing lessons from the list of
 * selected lessons. It also triggers updates to the table, so the currently
 * viewably lessons in the selected course highlight and select/deselect their
 * checkbox.
 * @param {Array} lessons, the currently selected course's table contents
 * @param {Function} setLessons, to update the table
 * @param {Array} selectedLessons, the list of pairs with a tut, asm, or both
 * selected
 * @param {Function} setSelectedLessons, to update the list of selection pairs
 * @param {String} type, indicating if a tut or an asm was clicked
 * @param {Object} lesson, the lesson clicked
 */
export const selectOrDeselectLesson = (
  lessons,
  setLessons,
  selectedLessons,
  setSelectedLessons,
  type,
  lesson = null
) => {
  // hard copies
  let newLesson = JSON.parse(JSON.stringify(lesson));
  let newLessons = JSON.parse(JSON.stringify(lessons));
  let newSelectedLessons = JSON.parse(JSON.stringify(selectedLessons));

  if (type === "Tutorial") {
    newLesson.tutorial.selected = !newLesson.tutorial.selected;
  } else {
    // asm
    newLesson.assessment.selected = !newLesson.assessment.selected;
  }
  // find comparable index, if it exists in the currently selected class
  for (let i = 0; i < newLessons.length; i++) {
    if (
      newLessons[i].tutorial.lesson_id === newLesson.tutorial.lesson_id ||
      newLessons[i].assessment.lesson_id === newLesson.assessment.lesson_id
    ) {
      newLessons[i] = newLesson;
    }
  }
  setLessons(newLessons);

  if (newLesson.tutorial.selected || newLesson.assessment.selected) {
    let addingNewLesson = true;
    for (let i = 0; i < newSelectedLessons.length; i++) {
      if (
        newSelectedLessons[i].tutorial.lesson_id ===
        newLesson.tutorial.lesson_id
      ) {
        // the lesson already exists and needs to be updated.
        addingNewLesson = false;
        newSelectedLessons[i] = newLesson;
      }
    }

    if (addingNewLesson) {
      // appending to the end
      setSelectedLessons([...newSelectedLessons, newLesson]);
    } else {
      // applying edit
      setSelectedLessons(newSelectedLessons);
    }
  } else {
    // removing a lesson
    let filteredSelectedLessons = newSelectedLessons.filter(
      (item) => item.tutorial.lesson_id !== newLesson.tutorial.lesson_id
    );

    setSelectedLessons(filteredSelectedLessons);
  }
};

/**
 * This func ensures that if a learner is in multiple classes for
 * this teacher, they are also selected/deselected in those classes.
 * @param {*} newLearners, the new set to be displayed in the table
 * @param {int} selectedClass, the id of the class currently selected
 * @param {int} learnerIndex, the index in the class
 * @param {*} learner, the learner clicked
 * @param {*} selectAll true if the Select All btn was clicked
 * @returns
 */
export const syncSelectedInOtherClasses = (
  newLearners,
  selectedClass,
  learnerIndex,
  learner,
  selectAll
) => {
  let userTags = newLearners[selectedClass][learnerIndex].user_tags;
  for (let i = 0; i < userTags.length; i++) {
    // list of the user's classes
    let classChecked = newLearners[userTags[i]];
    if (classChecked) {
      // if the class belongs to this teacher, it won't be undefined
      for (let a = 0; a < classChecked.length; a++) {
        if (classChecked[a].user_id === learner.user_id) {
          // the learner selected is the same as the one in this class
          newLearners[userTags[i]][a].selected = selectAll
            ? true
            : !learner.selected;
        }
      }
    }
  }
  return newLearners;
};

/**
 * buildNewSelectedLearnersList builds a new list of selected learners by
 * iterating through learners and grabbing learner ids and the classes they're
 * in.
 * @param {Object} newLearners An object containing arrays of learner objects.
 * The keys are class ids.
 * @param {Function} setSelectedLearners
 */
export const buildNewSelectedLearnersList = (
  newLearners,
  setSelectedLearners
) => {
  let keys = Object.keys(newLearners);
  let newLearnerClassUserIds = [];
  let newLearnerClassUsers = [];

  for (let i = 0; i < keys.length; i++) {
    let newLearnerClass = newLearners[keys[i]].filter((item) => item.selected);
    for (let i = 0; i < newLearnerClass.length; i++) {
      if (newLearnerClassUserIds.indexOf(newLearnerClass[i].user_id) === -1) {
        newLearnerClassUserIds = [
          ...newLearnerClassUserIds,
          newLearnerClass[i].user_id,
        ];
        newLearnerClassUsers = [
          ...newLearnerClassUsers,
          {
            id: newLearnerClass[i].id,
            user_id: newLearnerClass[i].user_id,
            classes: newLearnerClass[i].user_tags,
          },
        ];
      }
    }
  }

  setSelectedLearners(newLearnerClassUsers);
};

/**
 * selectOrDeselectLearner
 * @param {Array} learners, the current list in the table
 * @param {int} selectedClass, the id of the class currently selected
 * @param {Object} learner, the learner clicked
 * @param {Function} setLearners, to set the learners in the table
 * @param {Function} setSelectedLearners, to update the list of selected
 * learners
 * @param {Boolean} selectAll, true if the Select All btn was clicked
 * @returns {Object} a learner for the Select All loop
 */
export const selectOrDeselectLearner = (
  learners,
  selectedClass,
  learner,
  setLearners,
  setSelectedLearners,
  selectAll = false
) => {
  let newLearners = JSON.parse(JSON.stringify(learners)); // hard copy
  let learnerIndex = null;
  if (selectedClass) {
    learnerIndex = newLearners[selectedClass].findIndex(
      (x) => x.id === learner.id
    );

    if (selectAll) {
      newLearners[selectedClass][learnerIndex].selected =
        !newLearners[selectedClass][learnerIndex].selected;
      return newLearners[selectedClass][learnerIndex];
    }

    newLearners = syncSelectedInOtherClasses(
      newLearners,
      selectedClass,
      learnerIndex,
      learner,
      selectAll
    );
  } else {
    // this change is triggered from the SelectedLearnersPopup
    // we have to find every instance of the learner.
    let newLearnersKeys = Object.keys(newLearners);

    for (let i = 0; i < newLearnersKeys.length; i++) {
      let currentClass = newLearners[newLearnersKeys[i]];

      for (let a = 0; a < currentClass.length; a++) {
        if (JSON.stringify(currentClass[a]) === JSON.stringify(learner)) {
          newLearners[newLearnersKeys[i]][a].selected = false;
        }
      }
    }
  }

  setLearners(newLearners);
  buildNewSelectedLearnersList(newLearners, setSelectedLearners);
};

export const getGradeLevel = (currentLearner) => {
  if (!currentLearner || !currentLearner.grade_info) return "unset";
  if (
    currentLearner.grade_info !== null &&
    currentLearner.grade_info.grade_level !== undefined && 
    currentLearner.grade_info.grade_level !== null
  ) {
    if (currentLearner.grade_info.grade_level === 0) return "K";
    return currentLearner.grade_info.grade_level;
  } else {
    return "unset";
  }
};
