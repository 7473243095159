import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import CheckboxFilter from "../../containers/ManageSubscriptions/Filters/CheckboxFilter";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import SubscriptionRenewalRows from "./SubscriptionRenewalRows";
import "./SubscriptionStyles.scss";

import { saleModalHeader as saleModalHeaderA } from "../../redux/actions";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const SubscriptionSelectionModal = ({ dispatch, setShowModal, orgId, api }) => {
  const [error, setError] = useState(false);
  const [spin, setSpin] = useState(true);
  const [subscriptions, setSubscriptions] = useState(null);
  const [orgInfo, setOrgInfo] = useState(null);
  const [selectedSubscriptionIds, setSelectedSubscriptionIds] = useState(
    new Set()
  );
  const [showProcessedRenewals, setShowProcessedRenewals] = useState(false);

  const fetchOrgSubscriptions = () => {
    const queryParams = { orgId: orgId, for_renewal: true };
    api
      .fetchSubscriptions(queryParams)
      .then((res) => {
        const subscriptionsOrderedByRenewalDate = res.results.sort((a, b) => {
          if (a.last_renewed_on == null) {
            return -1;
          }
          if (b.last_renewed_on == null) {
            return 1;
          }
          return new Date(a.last_renewed_on) - new Date(b.last_renewed_on);
        });
        setSubscriptions(subscriptionsOrderedByRenewalDate);
        setOrgInfo(res.org_info);
        setSpin(false);
      })
      .catch((e) => {
        setError("Something went wrong... Please try again.");
        setSpin(false);
      });
  };

  useEffect(() => {
    fetchOrgSubscriptions();
  }, []);

  const handleConfirmSelection = (currentIds) => {
    dispatch(
      saleModalHeaderA({
        id: "newRenew",
        orgInfo: {
          orgId: orgId,
          orgType: orgInfo.org_type,
          taxRate: orgInfo.tax_rate,
          orgLevel: orgInfo.org_level,
        },
        renewSubscriptionIds: [...currentIds],
      })
    );
    setShowModal(false);
  };

  const getCurrentSubsAndPendingRenewals = () => {
    return subscriptions.filter(
      (sub) => sub.processed_renewal == null || sub.pending_renewal
    );
  };

  const handleProcessedRenewalsFilterChange = (isChecked) => {
    setShowProcessedRenewals(isChecked);
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper-large'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Select Subscriptions to Renew</p>
            <div
              className='pdf_popup_close'
              onClick={() => {
                setShowModal(false);
              }}
            >
              <FA icon='times' />
            </div>
          </div>
          <div
            id='renew_subscriptions_popup_contents'
            className='common_dashboard_bg visible-scrollbar'
          >
            {error && (
              <div>
                <div className='alert alert-danger'>{error}</div>
                <div className='d-flex'>
                  <button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className='btn btn-sm btn-primary'
                  >
                    Ok
                  </button>
                </div>
              </div>
            )}

            {spin && (
              <div className='alert alert-info'>
                <FA color='gray' icon='spinner' spin className='mr-2' />
                Loading Subscriptions...
              </div>
            )}

            {!spin && subscriptions && (
              <>
                {subscriptions.length > 0 && (
                  <div id='renew_subscriptions_popup_contents_grid'>
                    <CheckboxFilter
                      filterLabel='Show Previously Processed Renewals'
                      checked={showProcessedRenewals}
                      setChecked={handleProcessedRenewalsFilterChange}
                      checkBoxHoverText='Show previously renewed subscriptions'
                    />
                    <div
                      className='visible-scrollbar'
                      style={{
                        width: "100%",
                        flexGrow: "1",
                        overflowY: "auto",
                      }}
                    >
                      <SubscriptionRenewalRows
                        subscriptions={
                          showProcessedRenewals
                            ? subscriptions
                            : getCurrentSubsAndPendingRenewals()
                        }
                        includeSelection={true}
                        selectedSubscriptionIds={selectedSubscriptionIds}
                        setSelectedSubscriptionIds={setSelectedSubscriptionIds}
                      />
                    </div>
                    <button
                      className='btn btn-sm btn-primary mt-2'
                      disabled={selectedSubscriptionIds.size === 0}
                      onClick={() => {
                        handleConfirmSelection(selectedSubscriptionIds);
                      }}
                    >
                      Create Sale to Renew Selected Items
                    </button>
                  </div>
                )}
                {subscriptions.length == 0 && <div>No subscriptions</div>}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(mapStateToProps),
  withAPI
)(SubscriptionSelectionModal);
