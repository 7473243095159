import TextField from '@material-ui/core/TextField';
import React from "react";
import FA from "../../containers/fa";

const StudentNameTextField = ({
    placeholder, value, setterFunc,
    infoIconAction}) => {
  return (
    <div className='student_name_flex_container mr-2'>
      <span>
        {placeholder}
        {infoIconAction &&
          <FA
            icon='info-circle'
            className='info-circle'
            title="What's this?"
            onClick={() => {infoIconAction()}}
          />
        }
      </span>
      <TextField
        name={placeholder}
        aria-label={placeholder}
        className='w-100'
        margin='none'
        placeholder={placeholder}
        variant='outlined'
        size='small'
        value={value}
        onChange={(e) => {
          setterFunc(e.target.value)
        }}
      />
    </div>

  );
};

export default StudentNameTextField