import React, {useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import withAPI from "../../services/api";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  userId: state.auth.userId,
});

const RemoveObjectModal = ({
  setShowModal,
  reloadObjects,
  api,
  objectType,
  removalParams,
  user,
  subscriptionType,
}) => {
  const [error, setError] = useState(false);

  /**
   * handleRemoveObject calls the api to remove the selected user. This will
   * trigger an update to the table.
   */
  function handleRemoveObject() {
    let data = {
      subscription_type: subscriptionType,
      object_type: "userprofile",
      action: "remove",
      object_list: [removalParams.toRemove],
      org_id: user.organization.id,
    };
    api
      .manageSubscriptionObjects(data)
      .then((value) => {
        reloadObjects();
        setShowModal(false);
      })
      .catch((e) => {
        console.log("Error'd:", e);
        setError(e);
      });
  }

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Remove {objectType}</p>
            <ClosePopupBtn closePopupFunc={setShowModal} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && (
                  <div>
                    <div className='alert alert-danger'>
                      Something went wrong removing the {objectType}.
                    </div>
                    <div className='d-flex'>
                      <button
                        onClick={() => {
                          setShowModal(false);
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}

                {!error && (
                  <div>
                    <p>
                      Are you sure you want to remove this {objectType} from the
                      subscription?
                    </p>
                    <div className='d-flex'>
                      <button
                        onClick={() => handleRemoveObject()}
                        className='btn btn-sm btn-danger'
                      >
                        Yes, remove {objectType}
                      </button>
                      <button
                        onClick={() => {
                          setShowModal(false);
                        }}
                        className='btn btn-sm btn-primary ml-4'
                      >
                        No, take me back
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(RemoveObjectModal);
