import React, { useState } from "react";
import "./ExpandedAssignmentInfo.scss";
import ScoresByLearnerTable from "./ScoresByLearnerTable";
import ScoresByLessonTable from "./ScoresByLessonTable";

const ExpandedAssignmentInfo = ({
  assignment,
  setLessonForPopupInfo,
  setShowPopupInfo,
}) => {
  const [currentLesson, setCurrentLesson] = useState(null);
  const [currentLearner, setCurrentLearner] = useState(null);

  // for ensuring the scrollbar on the dropdown menu items is visible.
  const scrollStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      width: "calc(100% - 20px)",
    }),

    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "6px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "inset 0 0 0px white",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#666",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: state.isSelected
        ? "#E5F0FF"
        : state.isFocused
        ? "rgba(189,197,209,.3)"
        : "white",
    }),
  };

  const getInfoText = (lesson) => {
    let infoText = truncatedStandard(lesson.standard_code);
    const courseName = lesson.course_name;
    if (courseName.startsWith("SEL")) {
      infoText = `${lesson.lesson_name} (${courseName})`;
    }
    return infoText;
  };

  /**
   * truncatedStandard cuts off the ends of the standard text, leaving only
   * the XX.X.X.X value.
   * @param {String} standard
   * @returns {String} standard, with the ends cut off
   */
  const truncatedStandard = (standard) => {
    return standard.startsWith("CCSS") ? standard.slice(18) : standard;
  };

  /**
   * findScore searches the scores and finds the score which
   * matches the chosen learner and chosen lesson. Will always result in one
   * filtered out score (unless there are duplicate scores for a lesson, which
   * shouldn't happen).
   * @param {int} lessonId
   * @param {int} learnerId
   * @param {Object} assignment
   * @returns
   */
  const findScore = (lessonId, learnerId, assignment, is_external) => {
    let filter = assignment.performances.filter(
      (value) =>
        value.lesson_id === lessonId &&
        value.learner_id === learnerId &&
        value.is_external === is_external
    );
    if (filter.length !== 0) {
      return Math.round(filter[0].performance * 1000) / 10;
    }
    return "-";
  };

  return (
    <div className='expanded_assignment_whitebox'>
      <div className='expanded_assignment_contents'>
        <div className='expanded_assignment_white_interior'>
          <ScoresByLessonTable
            truncatedStandard={truncatedStandard}
            assignment={assignment}
            currentLesson={currentLesson}
            currentLearner={currentLearner}
            setCurrentLesson={setCurrentLesson}
            findScore={findScore}
            setLessonForPopupInfo={setLessonForPopupInfo}
            setShowPopupInfo={setShowPopupInfo}
            scrollStyles={scrollStyles}
          />
          <ScoresByLearnerTable
            getInfoText={getInfoText}
            assignment={assignment}
            currentLearner={currentLearner}
            currentLesson={currentLesson}
            setCurrentLearner={setCurrentLearner}
            findScore={findScore}
            setLessonForPopupInfo={setLessonForPopupInfo}
            setShowPopupInfo={setShowPopupInfo}
            scrollStyles={scrollStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpandedAssignmentInfo;
