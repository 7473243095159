import React from 'react';
import { withRouter } from 'react-router-dom';

import Gate from '../partials/Gate';
import LearnerLoginForm from '../components/LearnerLoginForm';

const Login = ({history, match, router}) => {
  return (
    <Gate>
      <div className="login_box">
        <div className="form-group">
          <button className="btn btn-sm btn-primary" onClick={() => history.goBack()}>&laquo; Back</button>
          <h3>{match.params.learnerUsername}</h3>
        </div>
        <LearnerLoginForm />
      </div>
    </Gate>
  )
}

export default withRouter(Login);
