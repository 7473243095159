import PropTypes from "prop-types";
import FA from "../../containers/fa";
import { getDateTimeStringPerGivenFormat } from "../../common/utils";

//this component is used for rendering expiration status icons for both Robots and Subscriptions
const ExpirationStatus = ({ obj, containerStyle }) => {
  const formattedExpirationDate = obj.end_date
    ? getDateTimeStringPerGivenFormat(obj.end_date, "M/D/YYYY")
    : "";

  return (
    <>
      {obj.end_date ? (
        <div style={containerStyle}>
          {formattedExpirationDate}
          <span style={{ marginLeft: "3px", position: "relative" }}>
            {obj.pending_renewal ? (
              <span assignment-data-title='Pending Renewal'>
                <FA icon='clock' />
              </span>
            ) : obj.expired ? (
              <span assignment-data-title='Subscription expired'>
                <FA color='red' icon='exclamation-circle' />
              </span>
            ) : obj.expiring_soon ? (
              <span assignment-data-title='Subscription expires soon'>
                <FA color='#ffc107' icon='exclamation-circle' />
              </span>
            ) : (
              ""
            )}
          </span>
        </div>
      ) : (
        <div>N/A</div>
      )}
    </>
  );
};

export default ExpirationStatus;

ExpirationStatus.propTypes = {
  obj: PropTypes.object.isRequired,
  containerStyle: PropTypes.object,
};
