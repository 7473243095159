import moment from "moment";
import React, { useState } from "react";
import FA from "../../../../../containers/fa";
import { getGradeLevel } from "../../CreateAssignmentFlowUtilities";
import "./CreateAssignmentStepFour.scss";
import SelectedLessonInfoPopup from "./SelectedLessonInfoPopup";

const CreateAssignmentStepFour = ({
  display,
  newName,
  newStartTime,
  newEndTime,
  learners,
  selectedLearners,
  selectedLessons,
  numOfSelectedLessons,
}) => {
  const [showingLessonInfo, setShowingLessonInfo] = useState(null);

  const mapLessons = () => {
    let count = 0;
    return selectedLessons.map((value, index) => {
      if (value.tutorial.selected) count++;
      if (value.assessment.selected) count++;
      const standard = value.course_name.startsWith("SEL")
        ? value.standard_description
        : value.standard;

      return (
        <div key={index}>
          {value.tutorial.selected && (
            <div className={`step_four_lessons_table_row tut_row`}>
              <p>{!value.assessment.selected ? count : count - 1}</p>
              <p>{value.course_name}</p>
              <p>Tutorial</p>
              <p>{standard}</p>
              <p>
                <button
                  className='btn btn-sm btn-primary'
                  style={{ margin: "4px" }}
                  onClick={() => setShowingLessonInfo(value.tutorial)}
                >
                  <FA icon='search' color='white' />{" "}
                </button>
              </p>
            </div>
          )}

          {value.assessment.selected && (
            <div className={`step_four_lessons_table_row asm_row`}>
              <p style={{ marginLeft: "-31px" }}>{count}</p>
              <p>{value.course_name}</p>
              <p>Assessment</p>
              <p>{standard}</p>
              <p>
                <button
                  className='btn btn-sm btn-primary'
                  style={{ margin: "4px" }}
                  onClick={() => setShowingLessonInfo(value.assessment)}
                >
                  <FA icon='search' color='white' />{" "}
                </button>
              </p>
            </div>
          )}
        </div>
      );
    });
  };

  const mapLearners = () => {
    return selectedLearners.map((value, index) => {
      let currentLearner = null;
      for (let i = 0; i < value.classes.length; i++) {
        // iterate through the list of class ids the learner is in
        let currentClass = learners[value.classes[i]];
        if (currentClass) {
          // class belongs to the user
          for (let a = 0; a < currentClass.length; a++) {
            if (currentClass[a].user_id === value.user_id) {
              // we have a match
              currentLearner = currentClass[a];
              break;
            }
          }
        }
      }
      return (
        <div
          className={`step_four_learners_table_row ${
            index % 2 === 0 ? "gray_row" : "light_gray_row"
          }`}
          key={index}
        >
          <p>{index + 1}</p>
          <p>
            {currentLearner && currentLearner.user.first_name}{" "}
            {currentLearner && currentLearner.user.last_name}
          </p>
          <p>
            {currentLearner && currentLearner.lessons_completed_from_list}/
            {numOfSelectedLessons}
          </p>
          <p>{getGradeLevel(currentLearner)}</p>
        </div>
      );
    });
  };

  return (
    <div
      id='create_assignment_step_four'
      className='create_assignment_step_content'
      style={{ display: display }}
    >
      {showingLessonInfo && (
        <SelectedLessonInfoPopup
          lesson={showingLessonInfo}
          setShowingLessonInfo={setShowingLessonInfo}
        />
      )}

      <h4>Does this look correct?</h4>
      <p>
        Please review your selections. If everything looks good, click the
        “Create Assignment” button to assign the lessons to your learners.
      </p>

      <div className='step_four_lesson_name_date_container'>
        <h6>
          <b>Lesson Name:</b>
        </h6>

        <h5>{newName}</h5>
        <p>
          {moment(newStartTime).format("MMM. Do, YYYY")} -{" "}
          {newEndTime
            ? moment(newEndTime).format("MMM. Do, YYYY")
            : "(No end date)"}
        </p>
      </div>
      <div id='step_four_tables_container' className='visible-scrollbar'>
        <p style={{ gridArea: "a" }}>
          <b>Your selected lessons:</b>
        </p>

        <p style={{ gridArea: "b" }}>
          <b>Your selected learners:</b>
        </p>
        <div
          id='step_four_lessons_table'
          className='visible-scrollbar'
          style={{ gridArea: "c" }}
        >
          <div id='step_four_lessons_table_header'>
            <p></p>
            <p>Course</p>
            <p>Type</p>
            <p>Standard</p>
            <p />
          </div>

          {mapLessons()}
        </div>

        <div
          id='step_four_learners_table'
          className='visible-scrollbar'
          style={{ gridArea: "d" }}
        >
          <div id='step_four_learners_table_header'>
            <p></p>
            <p>Learner Name</p>
            <p>Lessons Completed From Selected</p>
            <p>Grade</p>
          </div>

          {mapLearners()}
        </div>
      </div>
    </div>
  );
};

export default CreateAssignmentStepFour;
