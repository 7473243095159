import { combineReducers } from "redux";

import auth from "./auth.js";
import addStudents from "./addStudents.js";
import gui from "./gui.js";
import status from "./status.js";
import homeVersion from "./homeVersion.js";
import superAdmin from "./superAdmin.js";
import classes from "./classes.js";
import robot from "./robot.js";

export default combineReducers({
  auth,
  addStudents,
  gui,
  status,
  homeVersion,
  superAdmin,
  classes,
  robot,
});
