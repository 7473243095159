import MomentUtils from "@date-io/moment";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import config from "../../common/config";
import FA from "../../containers/fa";
import { saleModalHeader as saleModalHeaderA } from "../../redux/actions";
import withAPI from "../../services/api";
import DefaultGrayIconWithHoverText from "../UtilComps/DefaultGrayIconWithHoverText";
import ExpirationStatus from "../UtilComps/ExpirationStatus";
import SubscriptionStatus from "../UtilComps/SubscriptionStatus";
import "./SubscriptionStyles.scss";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});

const SubscriptionRow = ({
  user,
  dispatch,
  api,
  subscription,
  nullRow,
  refreshSubscriptionList,
  isSuperAdmin,
  fetchAll,
  extraFields,
  setShowSAUpdateReminder,
  saleIsFinalizedState,
  showRenewalWithoutRobotsIcon,
}) => {
  // For now, just assume we will only have 1 SubscriptionLimit returned
  // Backend is flexible enough that we might want to extend to more SubscriptionLimits
  // in the future, in which case we would need to rework some of these
  const [editMode, setEditMode] = useState(false);
  const [newExpiration, setNewExpiration] = useState(
    subscription ? subscription.end_date : null
  );

  //We can probably remove this state, since we can just use subscription.active
  //and we are no longer allowing manual editing of the active field
  const [isActive, setIsActive] = useState(
    subscription ? subscription.active : null
  );

  const subscriptionType = subscription.subscription_type;
  const subscriptionLabelMap = config.SUBSCRIPTION_TYPE_DISPLAY_NAMES;
  const allowedCount = subscription
    ? subscription.subscriptionlimit_set[0].max_objects
    : 0;

  useEffect(() => {
    if (subscription) {
      setNewExpiration(subscription.end_date);
      //We can probably remove this state, since we can just use subscription.active
      setIsActive(subscription.active);
    }
  }, [subscription]);

  const handleSave = () => {
    const subData = {
      active: isActive,
      end_date: newExpiration,
    };
    api.editSubscription(subscription.id, subData).then((res) => {
      console.log("successfully modified subscription: ");
      console.log(res);
      refreshSubscriptionList();
      setEditMode(false);
      if (
        user.usertype === config.SUPERADMIN_USERTYPE &&
        setShowSAUpdateReminder
      ) {
        setShowSAUpdateReminder(true);
      }
    });
  };

  if (nullRow === true) {
    return (
      <div>
        <div colSpan={5}>No Subscriptions Available.</div>
      </div>
    );
  }

  const getRowClassNames = () => {
    let res = fetchAll
      ? "subscription_row_data_all"
      : user.usertype === config.SUPERADMIN_USERTYPE
      ? "super_admin_subscription_row_data"
      : "subscription_row_data";
    return res;
  };

  const isLCTSub = subscriptionType === config.LCT_SUB_TYPE;

  return (
    <div className={getRowClassNames()} key={subscription.id}>
      {fetchAll && (
        <>
          <div>
            <p style={{ textAlign: "left" }}>
              {subscription.organization_name}
            </p>
          </div>
          <div>
            <p style={{ textAlign: "left" }}>
              {subscriptionLabelMap[subscriptionType]}
            </p>
          </div>
        </>
      )}

      {isSuperAdmin && (
        <div>
          <p style={{ textAlign: "left" }}>{subscription.sale}</p>
        </div>
      )}

      {subscription.subscription_type ===
      config.ABII_CONTENT_PACKAGE_SUB_TYPE ? (
        <div style={{ textAlign: "left" }}>
          <p>{subscription.acp.name}</p>
        </div>
      ) : (
        <div style={{ textAlign: "left" }}>
          {subscriptionLabelMap[subscriptionType]}
        </div>
      )}

      <div style={{ textAlign: "left" }}>
        {subscription && <SubscriptionStatus subscription={subscription} />}
      </div>

      {editMode ? (
        <div className='date-picker' style={{ textAlign: "left" }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              value={newExpiration}
              onChange={setNewExpiration}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "80%" }} />
              )}
            />
          </MuiPickersUtilsProvider>
        </div>
      ) : (
        <div style={{ display: "flex", margin: "0px", textAlign: "left" }}>
          <ExpirationStatus
            obj={subscription}
            containerStyle={{ margin: "0px 0px 0px 7px" }}
          />
        </div>
      )}

      {subscription && (
        <div>
          <p style={{ textAlign: "left" }}>{allowedCount}</p>
        </div>
      )}

      {/* Will replace the Used column data, since LCT doesn't use this. */}
      {subscriptionType == config.LCT_SUB_TYPE && <div />}

      {extraFields && extraFields.includes("utilized_count") && !isLCTSub && (
        <div>
          {subscription && (
            <p style={{ textAlign: "left" }}>{subscription.utilized_count}</p>
          )}
        </div>
      )}

      <div style={{ textAlign: "left" }}>
        {isSuperAdmin && (
          <React.Fragment>
            {editMode ? (
              <div>
                <span style={{ position: "relative", width: "fit-content" }}>
                  <button
                    subscription-data-title='Save'
                    onClick={handleSave}
                    className='btn btn-sm btn-primary m-1'
                  >
                    <FA color='white' icon='save' />
                  </button>{" "}
                </span>
                <span style={{ position: "relative", width: "fit-content" }}>
                  <button
                    subscription-data-title='Cancel'
                    onClick={() => {
                      setEditMode(false);
                    }}
                    className='btn btn-sm btn-danger m-1'
                  >
                    <FA color='white' icon='times' />
                  </button>{" "}
                </span>
              </div>
            ) : (
              <span style={{ position: "relative" }}>
                <button
                  className={`btn btn-sm ${
                    saleIsFinalizedState ? "btn-primary" : "btn-secondary"
                  } m-1`}
                  subscription-data-title={
                    saleIsFinalizedState
                      ? "Edit Status/Expiration Date"
                      : "Cannot edit status/expiration date: invoice not sent/paid."
                  }
                  onClick={(event) => {
                    if (!saleIsFinalizedState) {
                      event.preventDefault();
                      return;
                    }
                    setEditMode(true);
                  }}
                >
                  <FA color='white' icon='edit' />
                </button>
              </span>
            )}
          </React.Fragment>
        )}

        {/* TODO: probably will need to create separate link/component/api call for abii content package subs */}
        {!editMode && !isLCTSub && (
          <span style={{ position: "relative" }}>
            <Link
              className={`btn btn-sm ${
                saleIsFinalizedState ? "btn-primary" : "btn-secondary"
              } m-1`}
              to={`/admin/subscription/premiumcare/${subscription.id}`}
              subscription-data-title={
                saleIsFinalizedState
                  ? "Manage Subscription"
                  : "Cannot manage subscription: invoice not sent/paid."
              }
              onClick={(event) => {
                if (!saleIsFinalizedState) {
                  event.preventDefault();
                  return;
                }
                dispatch(
                  saleModalHeaderA({
                    id: null,
                    orgInfo: {},
                  })
                );
              }}
            >
              <FA color='white' icon='tasks' />
            </Link>
          </span>
        )}

        {!editMode && subscription.pending_renewal && (
          <span style={{ position: "relative" }}>
            <Link
              className='btn btn-sm btn-primary m-1 '
              to={`/renewal/${subscription.pending_renewal}`}
              subscription-data-title={
                !isLCTSub ? "Manage Renewal" : "View Renewal"
              }
              onClick={() => {
                dispatch(
                  saleModalHeaderA({
                    id: null,
                    orgInfo: {},
                  })
                );
              }}
            >
              <FA color='white' icon={!isLCTSub ? "search-plus" : "search"} />
            </Link>
          </span>
        )}

        {!editMode && !isLCTSub && (
          <>
            {showRenewalWithoutRobotsIcon ? (
              <DefaultGrayIconWithHoverText
                hoverText='Assign robots to your renewed subscription'
                iconClassName='exclamation-circle'
                iconColor='red'
              />
            ) : isActive &&
              subscription.is_renewal &&
              subscription.utilized_count < allowedCount ? (
              <DefaultGrayIconWithHoverText
                hoverText='Can Assign Robots'
                iconClassName='info-circle'
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(SubscriptionRow);
