import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import config from "../../common/config";
import {
  roundValues,
  sortedArray,
  useTitle,
  removePrefix,
  getUserRoleLabel,
} from "../../common/utils";
import { allowedUsertypeOptions } from "../../components/InputFields/helpers";
import UserDeleteModal from "../../components/AdminDashboard/UserDeleteModal";
import RobotRow from "../../components/ManageAllRobots/RobotRow";
import ImpactStatsInfoIcon from "../../components/UsageImpactStats/ImpactStatsInfoIcon";
import ImpactStatsLatest from "../../components/UsageImpactStats/ImpactStatsLatest";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";
import "../ManageAllRobots/Dashboard.scss";
import FA from "../fa";
import FAW from "../faw";
import TeacherAccountClasses from "./TeacherAccountClasses";
import "./TeacherAccountPage.scss";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const TeacherAccountPage = ({ api, match, user }) => {
  useTitle("Teacher Detail");
  const [shouldSpin, setShouldSpin] = useState(true);
  const [error, setError] = useState(null);
  const [fullAccountInfo, setFullAccountInfo] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [classNameMap, setClassNameMap] = useState({});
  const [hasCloudAccountsBot, setHasCloudAccountsBot] = useState(null);
  const [currentChartInd, setCurrentChartInd] = useState(0);
  const [refreshClasses, setRefreshClasses] = useState(false);
  const [totalStudents, setTotalStudents] = useState({});
  const [classList, setClassList] = useState([]);

  let history = useHistory();
  const teacherId = match.params.teacherId;

  const countStudents = (learners) => {
    const total_students = {};
    learners.map((item) => {
      item.user_tags.map((clss) => {
        total_students[clss] = total_students[clss]
          ? total_students[clss] + 1
          : 1;
      });
    });
    if (total_students !== totalStudents) {
      setTotalStudents(total_students);
    }
  };

  useEffect(() => {
    if (teacherId || refreshClasses) {
      api
        .fetchObjectsForTeacher(teacherId)
        .then((res) => {
          console.log("fetchTeacherAccountInfo:", res);
          let classes = res.usertags.filter(
            (item) => item.is_classroom === true
          );
          console.log("classes pre sort", classes);
          classes = sortedArray(classes, "created_at", true);
          console.log("classes post sort", classes);
          setClassList(classes);
          const classMap = {};
          classes.forEach((el, ind) => (classMap[el.id] = el.name));
          setClassNameMap(classMap);
          console.log("res.usertags", res.usertags);
          setFullAccountInfo({
            ...res,
            classes: classes,
            learners: sortedArray(res.learners, "first_name", true),
          });
          countStudents(sortedArray(res.learners, "first_name", true));
          setShouldSpin(false);
        })
        .catch((e) => {
          console.log("[ERROR] fetchTeacherAccountInfo:", e);
          setError("Something went wrong... Please try again.");
          setShouldSpin(false);
        });
      setRefreshClasses(false);
    }
  }, [teacherId, refreshClasses]);

  useEffect(() => {
    if (fullAccountInfo) {
      if (
        fullAccountInfo.robots &&
        fullAccountInfo.robots.filter((e) => e.cloud_accounts === true).length >
          0
      ) {
        setHasCloudAccountsBot(true);
      } else {
        setHasCloudAccountsBot(false);
      }
    }
  }, [fullAccountInfo]);

  const deleteAllowed = () => {
    // Deleting this User shall be allowed if the logged-in user has usertype with sufficient permissions
    return (
      allowedUsertypeOptions(user.usertype)
        .map((ut) => ut.value)
        .includes(fullAccountInfo.basic_info.usertype)
    );
  };

  const emailWithoutPrefix = useMemo(() => {
    if (fullAccountInfo?.basic_info?.email) {
      return removePrefix(fullAccountInfo.basic_info.email, "placeholder+");
    }
    return null;
  }, [fullAccountInfo?.basic_info?.email]);

  const renderBasicInfo = () => {
    const userRoleLabel = getUserRoleLabel(fullAccountInfo.basic_info.usertype);

    return (
      <div
        id='teacher_basic_info'
        className={
          hasCloudAccountsBot !== null
            ? "has_cloud_accts_bot"
            : "no_cloud_accts_bot"
        }
      >
        <div className='teacher_basic_info_field'>
          <p>Name: {fullAccountInfo.basic_info.name}</p>
        </div>
        <div className='teacher_basic_info_field'>
          <p>Email: {emailWithoutPrefix}</p>
        </div>
        <div className='teacher_basic_info_field'>
          <p>Schools: {fullAccountInfo.basic_info.school.join(", ")}</p>
        </div>
        <div className='teacher_basic_info_field'>
          <p>User Role: {userRoleLabel}</p>
        </div>
        {hasCloudAccountsBot !== null && (
          <div className='teacher_basic_info_field'>
            <p>
              Assigned Cloud Accounts Robots:{" "}
              {hasCloudAccountsBot ? "Yes" : "No"}
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderRobots = () => {
    if (fullAccountInfo.robots.length === 0) {
      return <p>No robots assigned to this user.</p>;
    }
    return (
      <div className='table table-striped'>
        <div id='robots_table_hrs_row_manage_teacher'>
          <p>Wifi name</p>
          <p>Serial</p>
          <p>Code Version</p>
          <p>Expiration Date</p>
          <p>Last Software Update</p>
          <p>Last Lesson Update</p>
          <p>Last Online Check-in</p>
        </div>

        {fullAccountInfo.robots.map((rbt) => (
          <RobotRow //DONE
            key={rbt.serial}
            rbt={rbt}
            orgAdminManageTeacherView={true}
          />
        ))}
      </div>
    );
  };

  const renderSubjectPieChart = (
    subjectData,
    subject,
    learnerCount,
    chartNumber,
    currentChartInd
  ) => {
    const pieLabels = [
      "Mastered Outright",
      "Mastered After Use",
      "Not Yet Mastered",
    ];
    const backgroundColors = [
      config.STANDARD_MASTERY_COLORS.mastered_outright,
      config.STANDARD_MASTERY_COLORS.mastered_eventually,
      config.STANDARD_MASTERY_COLORS.not_mastered,
    ];
    const pieData = {
      labels: pieLabels,
      datasets: [
        {
          // label: `${subject} Stats`,
          data: [
            subjectData["mastered_outright_count"],
            subjectData["mastered_eventually_count"],
            subjectData["not_mastered_count"],
          ],
          backgroundColor: backgroundColors,
          hoverOffset: 4,
        },
      ],
      extra: {
        learnerCount: learnerCount,
      },
    };

    let avgMastered =
      subjectData["mastered_outright_count"] +
      subjectData["mastered_eventually_count"];

    avgMastered = roundValues(avgMastered / learnerCount, 1);
    let newLeft = -(currentChartInd - chartNumber) * 300 - 300;
    return (
      <div
        className={`summary-and-chart chart_${chartNumber}`}
        style={{ left: newLeft }}
      >
        <div style={{ height: "350px" }}>
          <ImpactStatsLatest
            pieChartData={pieData}
            subject={subject}
            avgMastered={avgMastered}
          />
        </div>
      </div>
    );
  };

  const renderImpactStats = () => {
    const keys = [
      "mastered_outright_count",
      "mastered_eventually_count",
      "not_mastered_count",
    ];
    const stats = fullAccountInfo.impact_stats.stats;
    const learnerCount = fullAccountInfo.impact_stats.learner_count;
    let mathSum = 0;
    let readingSum = 0;
    const mathData = {};
    const readingData = {};
    const allData = {};
    keys.forEach((key, i) => {
      mathSum += stats[`math_${key}`] || 0;
      readingSum += stats[`reading_${key}`] || 0;
      mathData[key] = stats[`math_${key}`] || 0;
      readingData[key] = stats[`reading_${key}`] || 0;
      allData[key] =
        (stats[`math_${key}`] || 0) + (stats[`reading_${key}`] || 0);
    });
    if (mathSum + readingSum === 0) {
      return (
        <p className='mt-2'>
          No stats available. Impact Stats are updated daily once learners have
          started syncing their lessons.
        </p>
      );
    }

    return (
      <div className='row batch-row mt-2'>
        <div
          className='carousel_container carousel_charts'
          style={{ width: "100%" }}
        >
          <div>
            <div className='carousel_charts_wrapper teacher_acct_carousel'>
              {renderSubjectPieChart(
                allData,
                "All",
                learnerCount,
                1,
                currentChartInd
              )}
              {mathSum > 0 ? (
                renderSubjectPieChart(
                  mathData,
                  "Math",
                  learnerCount,
                  2,
                  currentChartInd
                )
              ) : (
                <div id='empty-pie-text'>
                  <p>No stats for Math standards available yet.</p>
                </div>
              )}
              {readingSum > 0 ? (
                renderSubjectPieChart(
                  readingData,
                  "Reading",
                  learnerCount,
                  mathSum > 0 ? 3 : 2,
                  currentChartInd
                )
              ) : (
                <div id='empty-pie-text'>
                  <p>No stats for Reading standards available yet.</p>
                </div>
              )}
            </div>

            {/* carousel buttons */}
            <div className='carousel_btns_container mt-2'>
              {[0, 1, 2].map((ind) => {
                return (
                  <div
                    key={ind}
                    className={`carousel_btn ${
                      currentChartInd === ind ? "selected" : "unselected"
                    }`}
                    onClick={() => setCurrentChartInd(ind)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSubscriptions = () => {
    const subs = fullAccountInfo.subscriptions;
    const customLessons = fullAccountInfo.basic_info.custom_lessons;
    if (subs.length === 0) {
      return <p>No subscriptions.</p>;
    }
    return (
      <table className='table table-striped'>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th></th>
            <th>Subscription Type</th>
            <th>Additional Info</th>
          </tr>
        </thead>
        <tbody>
          {subs.map((item, index) => (
            <tr key={index} style={{ textAlign: "center" }}>
              <td>{index + 1}</td>
              <td>{config.SUBSCRIPTION_TYPE_DISPLAY_NAMES[item.name]}</td>
              <td>
                {item.name === "lct" && customLessons ? (
                  <span> {customLessons} lessons created </span>
                ) : (
                  "N/A"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className='manage-teacher'>
      <MainDashboard>
        {showDeleteModal && (
          <UserDeleteModal
            setShowDeleteModal={setShowDeleteModal}
            userToDelete={teacherId}
            fetchUsers={() => history.goBack()}
          />
        )}

        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <h4>Teacher Account</h4>
            &nbsp;
            <button
              className='btn btn-sm btn-primary'
              onClick={() => history.goBack()}
            >
              {" "}
              Back{" "}
            </button>
          </div>

          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && (
                  <div className='alert alert-danger' role='alert'>
                    {error}
                  </div>
                )}
                {shouldSpin && (
                  <div>
                    <h4>
                      <FA color='gray' icon='spinner' spin /> Loading Details...
                    </h4>
                  </div>
                )}

                {fullAccountInfo && (
                  <div>
                    <div className='progress-linear' role='progressbar'>
                      <h4> Basic Info </h4>
                      {renderBasicInfo()}
                      <h4 className='mt-4'> Robots </h4>
                      {renderRobots()}

                      <TeacherAccountClasses
                        fullAccountInfo={fullAccountInfo}
                        classes={classList}
                        classNameMap={classNameMap}
                        setRefreshClasses={setRefreshClasses}
                        shouldSpin={shouldSpin}
                        teacherId={teacherId}
                        totalStudents={totalStudents}
                      />

                      <span className='mt-4 h4'> Impact Stats </span>
                      <ImpactStatsInfoIcon variant={"pieOnly"} />
                      {renderImpactStats()}

                      <h4 className='mt-4'> Subscriptions </h4>
                      {renderSubscriptions()}

                      {deleteAllowed() &&
                        <button
                          className='btn btn-sm btn-danger admin_settings_btn'
                          data-title='Remove Teacher'
                          onClick={() => {
                            setShowDeleteModal(true);
                          }}
                        >
                          <FAW icon='trash' /> Remove Teacher
                        </button>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MainDashboard>
    </div>
  );
};

export default compose(
  connect(mapStateToProps),
  withAPI,
)(TeacherAccountPage);
