import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import withAPI from '../../services/api';
import config from '../../common/config';

import FA from '../../containers/fa';


const LearnerAudioEditModal = ({api, shouldShowFunc, learnerId}) => {
  const [userAudioObjectId, setUserAudioObjectId] = useState(null);
  const [newAudio, setNewAudio] = useState("");
  const [currentAudio, setCurrentAudio] = useState("");
  const [currentAudioUrl, setCurrentAudioUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    api.fetchUserAudioStatus(learnerId).then((res) => {
      console.log(res)
      if (res.length > 0) {
        let data = res[0];
        setCurrentAudio(data.file.file_content);
        setCurrentAudioUrl(data.audio_file_url);
        setUserAudioObjectId(data.id)
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handleEdit = () => {
    setError(null);
    setSuccess(null);
    if (userAudioObjectId === null) {
      setError('Something went wrong. Please try again.')
      return
    }
    if (newAudio === null || newAudio === '') {
      setError('Please enter new audio content.')
      return
    }
    if (newAudio.length > 75) {
      setError('Please limit the audio content to 75 characters.')
      return 
    }
    setLoading(true);
    api.setUserAudio(userAudioObjectId, newAudio).then((res) => {
      setCurrentAudio(res.file.file_content);
      setCurrentAudioUrl(res.audio_file_url);
      setNewAudio("");
      setSuccess('Audio content updated!')
      setLoading(false);
    }).catch(() => {
      setError("Something went wrong resetting learner's audio.");
      setLoading(false);
    });
  }
  return (
    <div className="pdf-upload">
      <div className="edit-audio-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Change how ABii says your name!</p>
            <div className="pdf_popup_close" onClick={() => shouldShowFunc(false)}><FA icon="times" /></div>
          </div>
          <div className="reset_pw_modal common_dashboard_bg edit-audio-modal">
            <div className="row">
            {loading ? 
              <div className="col-12">
                <h4><FA color="gray" icon="spinner" spin /> Loading ...</h4>
              </div>
              :
              <div className="col-12">
                <p><strong>Change how ABii says your name! </strong>  
                ABii will occasionally use the learner’s name during each session.
                In case ABii has a hard time pronouncing your learner’s name correctly,
                try changing the name to a phonetic spelling!
                After your next sync, ABii will use the new pronunciation.
                You can update this as much as needed to get it right! 
                Remember to press the Sync button or reboot your robot for the
                new name to take effect.
                </p>
                {currentAudio &&
                  <div className="row">
                    <div className="col-6">
                      <h5> Current Spelling </h5>
                      <p>{currentAudio}</p>
                    </div>
                    <div className="col-6">
                      <h5> Preview Audio </h5>
                      <div className="audio-wrapper">
                      <audio controls controlsList="nodownload">
                        <source src={config.commBackendBaseUrl + currentAudioUrl} type="audio/wav" />
                        Your browser does not support the audio tag.
                      </audio>
                      </div>
                    </div>
                  </div>
                }

                <div className="form-content form-group mt-3">
                  <p><strong>To update the pronunciation, type in the new spelling below:</strong></p>
                  <input
                    aria-label='Enter New Spelling'
                    name="new-audio-spelling"
                    className="form-control"
                    type="text"
                    placeholder="Enter New Spelling" value={newAudio} onChange={(e) => setNewAudio(e.target.value)}/>
                  <div>
                    <button onClick={handleEdit} className="btn fs-14 btn-primary col-md-5 form-control mt-2" >Save Changes</button>
                    <button onClick={() => shouldShowFunc(false)} className="btn btn-secondary fs-14 col-md-5 form-control mt-2 float-right" >Close</button>
                  </div>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                {success && <div className="alert alert-success">{success}</div>}
                <div className="mt-4 mb-4">
                  <p><strong>Note:</strong>  <br/> Here are a few examples for names
                  that may not be pronounced correctly: <br/>
                  Anais --> could be spelled as “Ahnayees” <br/>
                  Andrea --> could be spelled as “Andreeah” depending on the individual’s preference<br/>
                  Isla --> could be spelled as “Ayelah”</p>
                </div>
              </div>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
)}

export default withAPI(LearnerAudioEditModal);
