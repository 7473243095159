import React, {useEffect, useState} from 'react';
import config from "../../common/config";

import { authService } from '../../services/auth';

const NoRobotModal = ({ category, usertype, closeModal }) => {

  const confirmLogout = () => {
    authService.logout().then(() => {
      document.location.href = "/";
    })
  }

  const handleClose = () => {
    if (usertype === config.ORGADMIN_USERTYPE) {
      confirmLogout();
    } else {
      closeModal();
    }
  }

  const [messageType, setMessageType] = useState(null);

  useEffect(() => {
    console.log(usertype, category)
    if (usertype === config.ORGADMIN_USERTYPE && category === config.ABII_SCHOOL.toLowerCase()) {
      setMessageType('school_orgadmin');
    } else if (usertype === config.TEACHER_USERTYPE && category === config.ABII_HOME.toLowerCase()) {
      setMessageType('home_teacher');
    } else if (usertype === config.TEACHER_USERTYPE && category === config.ABII_SCHOOL.toLowerCase()) {
      setMessageType('school_teacher');
    }
  }, [usertype, category])

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper-large">
        <div className="common_border">
          <div className="common_heading">
            {usertype === config.ORGADMIN_USERTYPE &&
              <p>Please Activate Your Robots</p>
            }
            {usertype === config.TEACHER_USERTYPE &&
              <p>No Robots Assigned</p>
            }
          </div>
          <div className="common_dashboard_bg" style={{maxHeight: '525px', overflowY: 'scroll'}}>

            {usertype === config.ORGADMIN_USERTYPE &&
              <h4>Please Activate Your Robots</h4>
            }
            {usertype === config.TEACHER_USERTYPE &&
              <h4>No Robots Assigned</h4>
            }

            {messageType === 'school_orgadmin' &&
              <div>
                <p>
                  Hello! You have successfully created an administrator account,
                  but you don't have any activated robots yet!
                </p>
                <p>
                  Please follow the instructions in your welcome email to activate your robots.
                  Once you're done, you can come back to accounts.abiis-world.com and login to see your dashboard.
                </p>
              </div>
            }

            {messageType === 'home_teacher' &&
              <div>
                <p>
                  Hello! You have successfully created a parent account,
                  but you don't have any activated robots yet!
                </p>
                <p>
                  Please follow the instructions in your welcome email to activate your robots.
                  Once you're done, you can come back to accounts.abiis-world.com and login to see your dashboard.
                </p>
              </div>
            }

            {messageType === 'school_teacher' &&
              <div>
                <p>
                  Welcome! You don't have any robots assigned to you. Please ask your admin
                  (whoever is in charge of managing the robots at your school) to assign you a robot.
                </p>
                <p>
                  In the meantime, you can set up your class and add your learners.
                  Until you have a robot assigned to you, learners will not be able to use the robots.
                </p>
              </div>
            }


            <button className="btn btn-primary" onClick={handleClose}>Got it</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoRobotModal;
