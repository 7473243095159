import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withAPI from '../../../services/api';
import FAW from '../../../containers/faw';
import FA from '../../../containers/fa';

import { 
  saleModalHeader as saleModalHeaderA,
  refreshSaleDetail as refreshSaleDetailA,
  refreshSubscriptionItems as refreshSubscriptionItemsA,
} from '../../../redux/actions';


const mapStateToProps = (state, ownProps?) => ({
  saleDetailId: state.superAdmin.saleDetailId,
  refreshSaleDetail: state.superAdmin.refreshSaleDetail,
  refreshSubscriptionItems: state.superAdmin.refreshSubscriptionItems,
});


const DeleteSaleModal = ({
  api, dispatch, saleId, refreshSaleDetail, setShouldShow,
  refreshSubscriptionItems }) => {

  const [shouldSpin, setShouldSpin] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const handleDeleteSale = () => {
    setShouldSpin(true);
    setSuccess(false);
    setError(false);
    api.deleteSale(saleId).then(()=> {
      setSuccess(true);
      setShouldSpin(false);
    })
    .catch((e) => {
      setError(true);
      setErrMsg(e)
      setShouldSpin(false);
    })
  }

  const handleAfterSuccess = () => {
    // reset all salemodal data
    dispatch(saleModalHeaderA({
        id: null,
        orgInfo: {},
      }));
    // trigger sale table refresh
    dispatch(refreshSaleDetailA(!refreshSaleDetail));
    // trigger subscription table refresh
    dispatch(refreshSubscriptionItemsA(!refreshSubscriptionItems));
  }

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Remove Sale</p>
            {!success &&
              <div className="pdf_popup_close" onClick={() => {setShouldShow(false);}}><FA icon="times" /></div>
            }
          </div>
          <div className="common_dashboard_bg table-scroll-wrapper" style={{maxHeight: '525px'}}>
            

          {!success &&
            <div>
              <div className="row">
                <div className="col-12">
                  <h5> Are you sure you want to remove this Sale? </h5>
                </div>
              </div>

              {!shouldSpin &&
                <div className="row">
                  <div className="col-12">
                    <button
                      onClick={handleDeleteSale}
                      className="btn btn-sm btn-primary mr-3">
                      Yes, remove
                    </button>
                    <button
                      onClick={() => {setShouldShow(false);}}
                      className="btn btn-sm btn-danger">
                      No, cancel
                    </button>
                  </div>
                </div>
              }
            </div>
          }

            <div className="row">
              <div className="col-12">
                {error && <div className="alert alert-danger">{errMsg}</div>}

                {success &&
                  <div>
                    <div className="alert alert-success" role="alert">Successfully removed the sale.</div>
                    <button className='btn btn-primary' onClick={handleAfterSuccess}>
                      Got it
                    </button>
                  </div>
                }

                {shouldSpin &&
                  <div className="alert alert-info" role="alert">Processing removal request ...</div>
                }
              </div>
            </div>


            



          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(DeleteSaleModal);
