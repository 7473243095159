import React from "react";
import FA from "../../containers/fa";

const InfoModal = ({infoText, setInfoText}) => {
  // special cases (if not in list, just display infoText directly)
  const specialCases = ["exactFilterMatch"];

  if (infoText) {
    return (
      <div className='pdf-upload'>
        <div className='pdf-upload-wrapper-large'>
          <div className='common_border'>
            <div className='common_heading'>
              <p>Current Status</p>
              <div
                className='pdf_popup_close'
                onClick={() => {
                  setInfoText(null);
                }}
              >
                <FA icon='times' />
              </div>
            </div>

            <div
              className='common_dashboard_bg table-scroll-wrapper'
              style={{maxHeight: "525px"}}
            >
              {infoText === "exactFilterMatch" && (
                <>
                  <div>
                    If unchecked,
                    <ul>
                      <li>
                        Filters by all sales that have gone through the selected
                        status;
                      </li>
                      <li>
                        If specified, time period filter applies to the time of
                        the status change.
                      </li>
                    </ul>
                  </div>
                  <div>
                    If checked,
                    <ul>
                      <li>Filters by the current status of the sale;</li>
                      <li>
                        If specified, time period filter applies to the "Last
                        Updated" time of the sale.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      Does <strong>not</strong> apply to "Has Returns" status.
                      If "Has Returns" is selected, all sales that have at least
                      one return in the specified time period will be displayed.
                    </p>
                  </div>
                </>
              )}
              {!specialCases.includes(infoText) && (
                <p> {infoText} </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default InfoModal;
