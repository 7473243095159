import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FA from "../fa";
import Class from "../TeacherDashboard/Class";
import "./TeacherAccountClasses.scss";
import { compose } from "redux";
import { connect } from "react-redux";
import config from "../../common/config";
import withAPI from "../../services/api";
import ClassDeleteModal from "../../components/TeacherDashboard/ClassDeleteModal";
import AddStudentsModal from "../../components/TeacherDashboard/AddStudentsModal";
import NewClassButton from "../../components/TeacherDashboard/NewClassButton";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});

const TeacherAccountClasses = ({
  user,
  fullAccountInfo,
  classes,
  classNameMap,
  setRefreshClasses,
  shouldSpin,
  teacherId,
  totalStudents,
  api,
}) => {
  const [refreshForNewLearners, setRefreshForNewLearners] = useState(null);
  const [classesExpanded, setClassesExpanded] = useState([]);
  const [classToggleEnabled, setClassToggleEnabled] = useState(true);
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  const [classToAddStudents, setClassToAddStudents] = useState({
    id: null,
    name: null,
  });
  const [learnerClassesAndTotalLessons, setLearnerClassesAndTotalLessons] =
    useState({});
  const [shouldShowClassDeleteModal, setShouldShowClassDeleteModal] =
    useState(null);

  const [classToEdit, setClassToEdit] = useState(null);
  const [editableClassName, setEditableClassName] = useState("");
  // initial load
  useEffect(() => {
    renderLearners();
  }, []);

  useEffect(() => {
    renderLearners();
  }, [fullAccountInfo.learners]);

  useEffect(() => {
    if (!classToggleEnabled) {
      setTimeout(() => {
        setClassToggleEnabled(true);
      }, 600);
    }
  }, [classToggleEnabled]);

  const saveNewName = (clsId) => {
    api
      .patchUserTagInfo(clsId, { name: editableClassName })
      .then(() => {
        setClassToEdit(null);
        setEditableClassName("");
        setRefreshClasses(true);
      })
      .catch((e) => {
        console.log(e);
        setClassToEdit(null);
      });
  };

  const renderLearners = () => {
    const learners = fullAccountInfo.learners;
    let learnersInfo = {};
    learners.forEach((item, index) => {
      learnersInfo[item.id] = {
        classes: item.user_tags
          .map((item) => classNameMap[item] || null)
          .filter((item) => item !== null)
          .join(", "),
        total_lessons: item.total_lessons,
      };
    });
    setLearnerClassesAndTotalLessons(learnersInfo);
  };

  const renderClasses = () => {
    if (classes.length === 0) {
      return <p>No classes.</p>;
    }
    return classes.map((item, index) => (
      <div
        id={`class-${item.id}`}
        key={item.id}
        style={{
          width: "100%",
          borderRadius: "5px 5px 0px 0px",
          marginBottom: "20px",
        }}
      >
        <div
          className='card batch_listings'
          style={{ marginBottom: "0px", border: "none" }}
        >
          <div className='card-body text-center class-header-grid'>
            {classToEdit === item.id ? (
              <div style={{ display: "flex", flexFlow: "row" }}>
                <input
                  type='text'
                  onChange={(e) => setEditableClassName(e.target.value)}
                  value={editableClassName}
                />
                <button
                  style={{ marginLeft: "1em" }}
                  className='btn btn-sm btn-primary'
                  onClick={() => saveNewName(item.id)}
                >
                  Save
                </button>
                &nbsp;
                <button
                  style={{ marginLeft: "1em" }}
                  className='btn btn-sm btn-danger'
                  onClick={() => setClassToEdit(null)}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div
                className='class-name-and-triangle-grid'
                onClick={() => {
                  if (classesExpanded.indexOf(item.id) === -1) {
                    if (!classToggleEnabled) {
                      return;
                    }
                    setClassToggleEnabled(false);
                    setClassesExpanded([item.id, ...classesExpanded]);
                    setTimeout(() => {
                      document.getElementById(
                        "learners-wrapper-" + item.id
                      ).style.overflow = "";
                    }, 500);
                  } else {
                    if (!classToggleEnabled) {
                      return;
                    }
                    setClassToggleEnabled(false);
                    setClassesExpanded(
                      classesExpanded.filter((listItem) => listItem !== item.id)
                    );
                    document.getElementById(
                      "learners-wrapper-" + item.id
                    ).style.overflow = "hidden";
                  }
                }}
              >
                <div
                  className='assignment-name-and-triangle-grid'
                  style={{ paddingLeft: "10px" }}
                >
                  <button
                    className={`triangle-${
                      classesExpanded.indexOf(item.id) !== -1 ? "up" : "down"
                    }`}
                  />
                </div>
                <span>{item.name}</span>{" "}
                <h6 className='total_students_p'>
                  {totalStudents[item.id] || 0} Learners
                </h6>
              </div>
            )}

            <div style={{ width: "fit-content", justifySelf: "end" }}>
              {item.clever_synced && (
                <>
                  <FA icon='exclamation-circle' />
                  <span className='ml-1 text-muted'>Clever-synced classes cannot be edited</span>
                </>
              )}
              {user.usertype !== config.SUPERADMIN_USERTYPE && (
                <button
                  disabled={item.clever_synced}
                  data-title='Add Learners'
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => {
                    setShowAddStudentsModal(true);
                    setClassToAddStudents({ id: item.id, name: item.name });
                  }}
                  className='btn btn-small'
                >
                  <FA icon='user-plus' color='#4B4B4B' />
                </button>
              )}
              {user.usertype !== config.SUPERADMIN_USERTYPE && (
                <button
                  disabled={item.clever_synced}
                  data-title='Edit Class Name'
                  className='btn btn-small'
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => {
                    setClassToEdit(item.id);
                    setEditableClassName(item.name);
                  }}
                >
                  <FA icon='edit' color='#4B4B4B' />
                </button>
              )}
              <Link
                data-title='View Reports'
                className='btn btn-small'
                to={{
                  pathname: `/user/reports/${item.id}`,
                  state: { rerouteObject: item, rerouteObjectType: "class" },
                }}
              >
                <FA icon='chart-bar' color='#4B4B4B' />
              </Link>

              {shouldShowClassDeleteModal === item.id &&
                user.usertype !== config.SUPERADMIN_USERTYPE && (
                  <ClassDeleteModal
                    shouldShowFunc={setShouldShowClassDeleteModal}
                    clsId={item.id}
                    refreshList={setRefreshClasses}
                    clsName={item.name}
                  />
                )}
              {user.usertype !== config.SUPERADMIN_USERTYPE && (
                <button
                  disabled={item.clever_synced}
                  data-title='Delete Class'
                  className='btn btn-small'
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => setShouldShowClassDeleteModal(item.id)}
                >
                  <FA icon='trash' color='#4B4B4B' />
                </button>
              )}
            </div>
          </div>
        </div>

        {showAddStudentsModal && classToAddStudents.id === item.id && (
          <AddStudentsModal
            setShowModal={setShowAddStudentsModal}
            classToAddStudents={classToAddStudents}
            setClassToAddStudents={setClassToAddStudents}
            assignClassId={item.id}
            setRefreshForNewLearners={setRefreshForNewLearners}
            setRefreshClasses={setRefreshClasses}
          />
        )}
        <Class
          clsId={item.id}
          clsName={item.name}
          classesExpanded={classesExpanded}
          setClassesExpanded={setClassesExpanded}
          classes={classes}
          refreshForNewLearners={refreshForNewLearners}
          setRefreshForNewLearners={setRefreshForNewLearners}
          teacherAcctPageLearnersInfo={learnerClassesAndTotalLessons}
        />
      </div>
    ));
  };

  // show add class button for org admins and orgunit admins if they are modifying userroles beneath them
  const showAddClassButton =
    fullAccountInfo.basic_info.clever_synced !== true &&
    user.usertype === config.ORGADMIN_USERTYPE ||
    (user.usertype === config.ORGUNITADMIN_USERTYPE &&
      fullAccountInfo.basic_info.usertype != config.ORGADMIN_USERTYPE);

  return (
    <div>
      <div className="mt-4" style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
        <h4>
          {" "}
          Classes 
          {showAddClassButton && (
            <NewClassButton
              disabled={shouldSpin}
              refreshList={setRefreshClasses}
              manageTeacherPageId={teacherId}
              extraBtnClasses={"btn-sm"}
            />
          )}
        </h4>
        {fullAccountInfo.basic_info.clever_synced && (
          <>
            <div className="ml-2 py-2 alert alert-warning">
              <FA icon='exclamation-circle' />
              <span className="ml-2">Clever-synced classes cannot be edited</span>
            </div>
          </>
        )}
      </div>
      {renderClasses()}
    </div>
  );
};

export default compose(
  connect(mapStateToProps),
  withAPI
)(TeacherAccountClasses);
