import React from "react";
import FA from "../../../../containers/fa";

const ProfileStatsInfoModal = ({showInfoModal, setShowInfoModal}) => {
  if (!showInfoModal) return null;
  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper-large'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Learner Profile Calculations</p>
            <div
              className='pdf_popup_close'
              onClick={() => {
                setShowInfoModal(false);
              }}
            >
              <FA icon='times' />
            </div>
          </div>

          <div
            className='common_dashboard_bg table-scroll-wrapper'
            style={{maxHeight: "525px"}}
          >
            <p>
              1. Profile stats are based on the latest sync; please sync your
              robot(s) for the most up-to-date stats;{" "}
            </p>
            <p>
              2. Accuracy of info in the "Performance by Day of Week" and
              "Performance by Time of Day" charts depends on whether the robot
              is connected to WiFi;
            </p>
            <p>
              3. Depending on whether a lesson was taken before or after a
              particular software update, all lessons may not be factored into
              the stats presented.
            </p>
            <p>
              4. Custom lessons are not included in Learner Profile
              calculations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileStatsInfoModal;
