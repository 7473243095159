import React, {useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import "./OrgContactsModal.scss";

const mapStateToProps = (state, ownProps?) => ({});

const DeleteContactModal = ({
  api,
  orgId,
  contactToDelete,
  setContactToDelete,
  fetchContacts,
}) => {
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(null);

  const deleteContact = () => {
    setDeleting(true);
    api
      .deleteContact(orgId, contactToDelete.id)
      .then((resp) => {
        console.log(resp);
        fetchContacts();
        setDeleting(false);
        setContactToDelete(null);
      })
      .catch((err) => {
        console.log(err);
        setDeleting(false);
        setError(err);
      });
  };

  return (
    <div id='delete_popup_graybox'>
      <div id='delete_popup_whitebox'>
        <div className='common_heading d-flex justify-content-between align-items-center'
          style={{padding: '10px 10px'}}
        >
          <h6 style={{marginBottom: "0px"}}>
            Delete {contactToDelete.first_name} {contactToDelete.last_name}
          </h6>

          <div
            id={`deleting-msg-${deleting}`}
            className='alert alert-success'
            role='alert'
          >
            <p>
              <FA color='gray' icon='spinner' spin /> Deleting contact...
            </p>
          </div>

          {error && (
            <div
              id='deleting-msg-true'
              className='alert alert-danger'
              role='alert'
            >
              <p>{error}</p>
            </div>
          )}

          <div
            style={{
              cursor: "pointer",
              padding: "5px",
              width: "fit-content",
            }}
            onClick={() => {
              setContactToDelete(null);
              setError(null);
            }}
          >
            <FA color='gray' icon='times' />
          </div>
        </div>

        <div>
          <h5 style={{textAlign: 'center', margin: '10px'}}>
            Are you sure you want to delete this contact? This action cannot be
            undone.
          </h5>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <button
              style={{
                width: "fit-content",
                margin: "5px 5px 10px 5px"
              }}
              className='btn btn-sm btn-primary'
              onClick={deleteContact}
            >
              Yes, delete contact
            </button>
            <button
              style={{
                width: "fit-content",
                margin: "5px 5px 10px 5px"
              }}
              className='btn btn-sm btn-danger'
              onClick={() => setContactToDelete(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(DeleteContactModal);
