import React, { useCallback } from "react";
import { connect } from "react-redux";
import { compose } from 'redux';
import { OAuthProviders, OAuthService } from '../../services/oauth';
import withAPI from '../../services/api';
import { debugLog } from '../../common/utils';

const mapStateToProps = (state, ownProps?) => ({
  orgSettings: state.status.userOrgSettings,
});

const LoginWithCleverButton = ({api, orgSettings, linkToExisting}) => {

  function jsonToURI (json) {
    return encodeURIComponent(JSON.stringify(json));
  }

  const selectProviderFromSettings = () => {
    if (
      orgSettings &&
      orgSettings.default_sso_provider
    ) {
      if (orgSettings.default_sso_provider === 'clever_sso') {
        return new OAuthService(OAuthProviders.cleverSSOOnly);
      } else if (orgSettings.default_sso_provider === 'clever_securesync') {
        return new OAuthService(OAuthProviders.cleverSecureSync);
      }
    } else if (
      orgSettings.allowed_sso_providers.length > 0 && 
      orgSettings.allowed_sso_providers.includes('clever_securesync')
    ) {
      return new OAuthService(OAuthProviders.cleverSecureSync);
    } else {
      return new OAuthService(OAuthProviders.cleverSSOOnly);
    }
  };

  const handleCleverLogin = useCallback(async () => {
    if (linkToExisting) {
      try {
        debugLog("Linking to existing...")
        const provider = selectProviderFromSettings();
        api.createUserCode()
        .then((resp) => {
          const state = {
            link_to_existing: true,
            link_code: resp.usercode,
            slug: sessionStorage.getItem('authToken')
          }
          //debugLog("createUserCode returned:",resp);
          //debugLog("Redirecting with state:",state);
          //debugLog("Redirecting with state:",jsonToURI(state));
          provider.redirectToLogin({state:jsonToURI(state)})
        })
        .catch((e) => {
          console.error(e);
        });
      } catch (e) {
        console.error(e);
      };
    } else {
      try {
        var url = OAuthProviders.cleverSSOOnly.OAUTH_LOGIN_LAUNCH_PAGE
        debugLog("Redirecting to:",url);
        window.location.assign(url);
      } catch (e) {
        console.error(e);
      };
    };
  }, []);

  return (
    <button id='login_with_clever'
      type="button"
      onClick={() => {handleCleverLogin()}}
    >
      <div className="a">
        <h2>C</h2>
      </div>
      {linkToExisting
      ? <div className="b">
          Clever
        </div>
      : <div className="b">
          Log in with Clever
        </div>
      }
    </button>
  );
}

export default compose(connect(mapStateToProps), withAPI)(LoginWithCleverButton);
