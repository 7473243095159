import React, {useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import FA from "../fa";
import "./ConsolidatedReportsSearchBar.scss";

const ConsolidatedReportsSearchBar = ({
  setActiveTab,
  classes,
  allLearners,
  setSelectedSearchVal,
}) => {
  const [options, setOptions] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);

  const searchOptions = (val) => {
    if (!val) {
      setSearchResults([]);
      setSearching(false);
      return;
    }
    let results = options.filter(
      (value) =>
        value.label &&
        value.label.toLowerCase().indexOf(val.toLowerCase()) !== -1
    );
    setSearchResults(results);
    setSearching(false);
  };

  const [debouncedSearchVal] = useDebounce(searchVal, 500);

  useEffect(() => {
    searchOptions(debouncedSearchVal);
  }, [debouncedSearchVal]);

  useEffect(() => {
    if (searchVal) setSearching(true);
  }, [searchVal]);
 
  const buildOptions = () => {
    let newOptionsList = [];
    let sids = [];
    if (!classes) return;
    for (let i = 0; i < classes.length; i++) {
      newOptionsList.push({
        ...classes[i],
        type: "class",
        label: classes[i].name,
        value: classes[i].id,
      });
    }

    for (let i = 0; i < allLearners.length; i++) {
      // iterate through every allLearners entry learners list
      for (let a = 0; a < allLearners[i].learners.length; a++) {
        if (sids.indexOf(allLearners[i].learners[a].sid) === -1) {
          sids.push(allLearners[i].learners[a].sid);
          newOptionsList.push({...allLearners[i].learners[a], type: "learner"});
        }
      }
    }
    console.log("newOptionsList", newOptionsList);
    setOptions(newOptionsList);
  };

  useEffect(() => {
    buildOptions();
  }, [classes, allLearners]);

  return (
    <div id='reports_search_bar_container' style={{width: "300px"}}>
      <div className='d-flex justify-content-end'>
        <label className='mr-2 pt-2'>
          <FA icon='search' />{" "}
        </label>
        &nbsp;
        <input
          placeholder='Search for a class/learner...'
          name='search-class-or-learner'
          className='form-control input-underline'
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
          onBlur={() => {
            setTimeout(() => {
              setSearchResults([]);
            }, 400);
          }}
          onFocus={() => {
            if (debouncedSearchVal) searchOptions(debouncedSearchVal);
          }}
        />
      </div>

      {searching ? (
        <div id='reports_search_bar_results'>
          <div>
            <FA icon='spinner' spin /> Searching...
          </div>
        </div>
      ) : searchResults.length !== 0 ? (
        <div id='reports_search_bar_results' className='visible-scrollbar'>
          {searchResults.map((value, index) => {
            return (
              <div
                key={index}
                className='search_option'
                onClick={() => {
                  console.log("changing tab");
                  setActiveTab(value.type);
                  setSelectedSearchVal(value);
                }}
              >
                {value.label} <span>- {value.type}</span>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ConsolidatedReportsSearchBar;
