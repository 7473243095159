import React, { useState } from "react";
import { compose } from "redux";

import withAPI from "../../services/api";

import SchoolDeleteModal from "../../components/ManageAllSchools/SchoolDeleteModal";
import FAW from "../../containers/faw";
import FA from "../../containers/fa";
import "./SchoolRow.scss";

const SchoolRow = ({ api, school, refreshSchoolList }) => {
  const [deleteSpin, setDeleteSpin] = useState(false);
  const [saveSpin, setSaveSpin] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editableValue, setEditableValue] = useState(school.name);
  const [permValue, setPermValue] = useState(school.name);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    console.log("Handling Delete...");
    setShowDeleteModal(true);
  };

  const handlePatch = () => {
    setSaveSpin(true);
    let data = { name: editableValue, action: { name: "set" } };
    api
      .patchOrgUnitInfo(school.id, data)
      .then((resp) => {
        setSaveSpin(false);
        setEditable(false);
        setPermValue(editableValue);
        refreshSchoolList();
        console.log("patch API returned", resp);
      })
      .catch((err) => {
        setSaveSpin(false);
        console.log("API error'd:", err);
      });
  };

  const renderDeleteModal = () => {
    return showDeleteModal ? (
      <SchoolDeleteModal
        setShowDeleteModal={setShowDeleteModal}
        schoolToDelete={school}
        fetchSchools={refreshSchoolList}
        customWarning={
          school.num_teachers !== 0
            ? "This School contains one or more Users. Are you sure you want to delete it? The Users will be unaffected, but this action cannot be reversed."
            : null
        }
      />
    ) : null;
  };

  const renderDeleteButton = () => {
    if (school.num_teachers > 0) {
      return (
        <button
          aria-label='Cannot Delete: Teachers still assigned to school.'
          className='btn btn-sm btn-secondary mr-2'
          data-title='Cannot Delete: Teachers still assigned to school.'
        >
          <FAW icon='trash' />
        </button>
      );
    } else {
      return (
        <button
          className='btn btn-sm btn-danger mr-2'
          aria-label='Delete School'
          data-title='Delete School'
          onClick={() => {
            handleDelete();
          }}
        >
          <FAW icon='trash' />
        </button>
      );
    }
  };

  return editable ? (
    <tr key='name'>
      <td>
        <input
          type='text'
          aria-label='Edit School Name'
          name='edit-school-name'
          placeholder={permValue}
          value={editableValue}
          onChange={(e) => setEditableValue(e.target.value)}
        />
      </td>
      <td name='Actions'>
        <span>
          <div className='datatable_actions'>
            <div className='d-flex align-items-start common-align-button action-btns'>
              {saveSpin && (
                <button
                  className='btn btn-sm btn-primary'
                  data-title='Saving...'
                >
                  <FA color='white' icon='spinner' spin />
                </button>
              )}
              {!saveSpin && school.id && (
                <button
                  aria-label='Save School'
                  className='btn btn-sm btn-primary mr-2'
                  data-title='Save School'
                  onClick={() => {
                    handlePatch();
                  }}
                >
                  <FA icon='save' color='white' />
                </button>
              )}
              <button
                aria-label='Cancel'
                className='btn btn-sm btn-primary mr-2'
                data-title='Cancel'
                onClick={() => setEditable(false)}
              >
                <FA color='white' icon='times' />
              </button>
              {deleteSpin && (
                <button className='btn btn-sm btn-danger'>
                  <FA color='white' icon='spinner' spin />
                </button>
              )}
              {!deleteSpin && school.id && renderDeleteButton()}
            </div>
          </div>
        </span>
      </td>
      <td>{renderDeleteModal()}</td>
    </tr>
  ) : (
    <tr key='name'>
      <td>
        <span>{school.name}</span>
      </td>
      <td name='Actions'>
        <span>
          <div className='datatable_actions'>
            <div className='d-flex align-items-start common-align-button action-btns'>
              <button
                aria-label='Edit School'
                className='btn btn-sm btn-primary'
                data-title='Edit School'
                onClick={() => {
                  setEditable(true);
                }}
              >
                <FA icon='edit' color='white' />
              </button>
              {deleteSpin && (
                <button className='btn btn-sm btn-danger'>
                  <FA color='white' icon='spinner' spin />
                </button>
              )}
              {!deleteSpin && school.id && renderDeleteButton()}
            </div>
          </div>
        </span>
      </td>
      <td>{renderDeleteModal()}</td>
    </tr>
  );
};

export default compose(withAPI)(SchoolRow);
