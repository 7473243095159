import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FA from "../../../containers/fa";
import withAPI from "../../../services/api";
import RobotSection from "./RobotSection";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});

const AddRobotPopup = ({
  api,
  user,
  objInfo,
  parentType,
  refreshParent,
  setShowAddRobotPopup,
}) => {
  let allowedCount;
  let usedCount;
  let selectionCountLimit;

  if (parentType === "renewal") {
    allowedCount =
      objInfo.new_subscription.subscriptionlimit_set[0].max_objects;
    usedCount = objInfo.pending_robots.length;
    selectionCountLimit = allowedCount - usedCount;
  } else if (parentType === "subscription") {
    allowedCount = objInfo.subscriptionlimit_set[0].max_objects;
    usedCount = objInfo.utilized_count;
    selectionCountLimit = allowedCount - usedCount;
  }

  return (
    <div className='pdf-upload'>
      <div
        id='add_robot_to_subscription_btns_container'
        className='pdf-upload-wrapper-large'
      >
        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <p>Add robots to {parentType}</p>
            <div
              className='pdf_popup_close'
              onClick={() => {
                setShowAddRobotPopup(false);
              }}
            >
              <FA icon='times' />
            </div>
          </div>
        </div>

        <div
          id='robot_search_popup_body'
          className='common_border'
          style={{ height: "fit-content" }}
        >
          <div
            id='robot_search_common_dashboard_bg'
            className='common_dashboard_bg'
          >
            <div>
              <p className='mb-1'>
                <span>Total slots in {parentType}: </span>
                <span className='font-weight-bold ml-2'>{allowedCount}</span>
              </p>
              <p className='mb-1'>
                <span>Slots used: </span>
                <span className='font-weight-bold ml-2'>{usedCount}</span>
              </p>
              <p>
                Select up to
                <span className='font-weight-bold mx-2'>
                  {selectionCountLimit}
                </span>
                robots from the list below to add to the current {parentType}.
              </p>
              {parentType === "renewal" && (
                <p>
                  A star icon (<FA color='#ffc107' icon='star' />) indicates
                  that the robot is from the original subscription(s)
                </p>
              )}
            </div>

            {objInfo && (
              <RobotSection
                variant='addRobotPopup'
                objInfo={objInfo}
                refreshParent={refreshParent}
                parentType={parentType}
                setShowParent={setShowAddRobotPopup}
                selectionCountLimit={selectionCountLimit}
                showSelectAllButton
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(AddRobotPopup);
