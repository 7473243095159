import Chart from "chart.js/auto";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Bar, Line} from "react-chartjs-2";
import withAPI from "../../services/api";
import "./UsageStatsRow.scss";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {formatLabel, formatTitle} from "./UsageStatsUtilities";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";
Chart.register(ChartDataLabels);

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const PopupChart = ({
  showPopup,
  setShowPopup,
  chartData,
  activeChart,
  chartType,
  chartEl,
  data,
  user,
}) => {
  // If chartEl is provided, just render the chart element directly,
  // and by-pass all other props/calcs
  // These assume activeChart ends with Perc or Count
  const isPercent = activeChart && activeChart.endsWith("Perc") ? true : false;
  const chartTitle =
    chartData && chartData.typeOfData.replace("Perc", "").replace("Count", "");

  const dataLabelsFormatter = (value) => {
    if (value === null) {
      return null;
    }
    if (chartData && chartData.typeOfData.indexOf("Perc") !== -1) {
      return `${value}%`;
    }
    return value;
  };

  let optionsData = {
    responsive: true,
    maintainAspectRatio: false,
    radius: 7,
    pointHoverRadius: 10,
    lineTension: 0.5,
    hover: {
      mode: "index",
      intersect: false,
    },
    animation: {
      duration: 0,
    },
    title: {
      display: true,
      text: "text",
      fontSize: 20,
    },
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        anchor: "end",
        align: "top",
        formatter: dataLabelsFormatter,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: chartData ? chartData.chartCaption : "",
        font: {
          size: 15,
        },
        padding: {
          top: 10,
          bottom: 22,
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: function (context) {
            return formatTitle(context);
          },
          label: function (context) {
            return formatLabel(user, data, context, chartData.typeOfData);
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Sliding 30-day Window of Use",
        },
      },
      y: {
        grid: {
          display: false,
          padding: 30,
        },
        title: {
          display: true,
          text: chartData ? chartData.chartYcaption : "",
        },
        ticks: {
          callback: (yValue) => {
            if (isPercent) {
              return Math.round(yValue) + "%";
            } else {
              return Math.round(yValue);
            }
          },
        },
        beginAtZero: true,
        suggestedMax: 100,
      },
    },
  };

  useEffect(() => {
    console.log("chart type changed");
  }, [chartType]);

  return (
    <div className='pdf-upload' style={{display: showPopup ? "block" : "none"}}>
      <div
        id='impact_stats_chart_popup'
        className='pdf-upolad-wrapper'
        style={{position: "fixed", height: "fit-content"}}
      >
        <div className='common_border'>
          <div className='common_heading'>
            <p>{chartTitle}</p>
            <ClosePopupBtn closePopupFunc={setShowPopup} />
          </div>
          <div
            id='popup_chart_grid'
            className='reset_pw_modal common_dashboard_bg'
          >
            <div
              className='visible-scrollbar'
              style={{width: "100%", overflowX: "auto"}}
            >
              <div
                style={{
                  width: `${
                    chartData ? chartData.parsedData.length * 60 : 60
                  }px`,
                  minWidth: "100%",
                  height: "100%",
                }}
              >
                {chartData && chartType === "bar" ? (
                  <Bar data={chartData} options={optionsData} />
                ) : chartData && chartType === "line" ? (
                  <Line data={chartData} options={optionsData} />
                ) : null}
                {chartEl && chartEl}
              </div>
            </div>
            <button
              className='btn btn-lg fs-14 btn-primary'
              style={{width: "fit-content"}}
              onClick={() => {
                setShowPopup(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(PopupChart);
