import React, {useState, useEffect} from "react";
import {compose} from "redux";
import {withRouter, Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import withAPI from "../../services/api";
import config from "../../common/config";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import StateSelector from "../InputFields/StateSelector";
import "./CreateOrgForm.scss";

const CreateOrgForm = ({api, history, setShowCreateOrgForm}) => {
  const defaultRole = "Org Type";
  const emptyForm = {
    name: {
      val: "",
      reqd: true,
      placeholder: "Organization Name",
      icon: "id-card",
    },
    contact_name: {
      val: "",
      reqd: true,
      placeholder: "Contact Name",
      icon: "id-card",
    },
    contact_state: {
      val: "",
      reqd: false,
      placeholder: "State",
      icon: "building",
    },
    acc_contact_name: {
      val: "",
      reqd: false,
      placeholder: "Accounting Contact Name",
      icon: "id-card",
    },
    position: {
      val: "",
      reqd: false,
      placeholder: "Contact Role",
      icon: "id-card",
    },
    email: {
      val: "",
      reqd: true,
      placeholder: "Email",
      icon: "envelope-open",
    },
    acc_contact_email: {
      val: "",
      reqd: false,
      placeholder: "Accounting Contact Email",
      icon: "envelope-open",
    },
    phone: {val: "", reqd: false, placeholder: "Phone", icon: "phone"},
    acc_contact_phone: {
      val: "",
      reqd: false,
      placeholder: "Accounting Contact Phone",
      icon: "phone",
    },
    address1: {
      val: "",
      reqd: false,
      placeholder: "Address 1",
      icon: "building",
    },
    address2: {
      val: "",
      reqd: false,
      placeholder: "Address 2",
      icon: "building",
    },
    city: {val: "", reqd: false, placeholder: "City", icon: "building"},
    state: {val: "", reqd: true, placeholder: "State", icon: "building"},
    zipcode: {val: "", reqd: false, placeholder: "Zipcode", icon: "building"},
    district: {val: "", reqd: false, placeholder: "District", icon: "building"},
    notes: {
      val: "",
      reqd: false,
      placeholder: "Notes (Optional)",
      icon: "building",
    },
    org_level: {
      val: "",
      reqd: false,
      placeholder: "Org Level",
      icon: "building",
    },
  };

  const [selectedRole, setSelectedRole] = useState(defaultRole);
  const [formInput, setFormInput] = useState(emptyForm);
  const [errors, setErrors] = useState(emptyForm);
  const [error, setError] = useState(null);
  const [spin, setSpin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allOrgNames, setAllOrgNames] = useState([]);
  const [isValidForm, setIsValidForm] = useState(false);

  useEffect(() => {
    api.fetchAllOrgNames().then((resp) => {
      setAllOrgNames(resp);
    });
  }, []);

  const defaultState = "------";

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    if (errors.email.val.length > 0) {
      setSpin(false);
      setError(errors.email.val);
    } else if (
      selectedRole === defaultRole ||
      formInput.email.val === "" ||
      formInput.name.val === "" ||
      formInput.state.val === "" ||
      formInput.state.val === defaultState
    ) {
      setSpin(false);
      setError("Category, Organization Name, Email, and State are required.");
    } else {
      setSpin(true);
      let orgInfo = {...formInput};
      orgInfo["category"] = {val: selectedRole};
      let first_name = "";
      let last_name = "";
      let contact_name_arr = formInput.contact_name.val.split(" ");
      if (contact_name_arr) {
        first_name = contact_name_arr[0];
        if (contact_name_arr.length > 1) {
          last_name = contact_name_arr[1];
        }
      }

      let data = {
        name: formInput.name.val,
        district: formInput.district.val,
        org_level: formInput.org_level.val,
        category: selectedRole,
        state: formInput.state.val,
        contacts: [
          {
            first_name: first_name,
            last_name: last_name,
            email: formInput.email.val,
            phone: formInput.phone.val,
            address1: formInput.address1.val,
            address2: formInput.address2.val,
            city: formInput.city.val,
            state: formInput.contact_state.val,
            zipcode: formInput.zipcode.val,
            position: formInput.position.val,
            notes: formInput.notes.val,
            primary_contact: true,
          },
        ],
      };

      if (
        formInput.acc_contact_name.val &&
        (formInput.acc_contact_email.val || formInput.acc_contact_phone.val)
      ) {
        // append an accounting contact
        console.log("appending accounting...");

        contact_name_arr = formInput.acc_contact_name.val.split(" ");
        if (contact_name_arr) {
          first_name = contact_name_arr[0];
          if (contact_name_arr.length > 1) {
            last_name = contact_name_arr[1];
          }
        }
        console.log(data.contacts);
        data.contacts = [
          ...data.contacts,
          {
            first_name: first_name,
            last_name: last_name,
            email: formInput.acc_contact_email.val,
            phone: formInput.acc_contact_phone.val,
            position: "accounting",
          },
        ];
        console.log(data.contacts);
      }
      api
        .createOrganization(data)
        .then((resp) => {
          setSpin(false);
          let orgId = resp.organization.id;
          history.push(`/super/org/${orgId}`);
          // Go to the page to View/Edit the new Organization
        })
        .catch((err) => {
          setSpin(false);
          console.log("error:", err);
          setError(err.message);
        });
    }
  };

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  useEffect(() => {
    validateFormInputs();
  }, [formInput]);

  useEffect(() => {
    if (selectedRole === "home" && formInput.org_level.val !== "") {
      updateForm(null, "org_level", "");
    }
  }, [selectedRole]);

  function updateForm(e, field, value = null) {
    let formCopy = {...formInput};
    let newInfo;
    let newValue;
    if (value !== null) {
      newValue = value;
    } else {
      newValue = e.target.value;
    }
    newInfo = {...{...formCopy[field]}, val: newValue};
    formCopy[field] = newInfo;
    setFormInput(formCopy);
  }

  const validateFormInputs = () => {
    let errorsCopy = {...errors};
    let newIsValid = true;
    Object.entries(formInput).map(([key, entry]) => {
      if (entry.reqd && !entry.val) {
        // blank required field
        let newInfo = {...{...errorsCopy[key]}, val: "*Required"};
        newIsValid = false;
        errorsCopy[key] = newInfo;
      } else {
        if (key === "email" && formInput.email.val.length > 0) {
          // check for invalid email format
          let emailErr = validEmailRegex.test(formInput.email.val)
            ? ""
            : "Must be a valid email address";
          let newInfo = {...{...errorsCopy[key]}, val: emailErr};
          newIsValid = emailErr ? false : true;
          errorsCopy[key] = newInfo;
        } else {
          // is valid non-email
          let newInfo = {...{...errorsCopy[key]}, val: ""};
          errorsCopy[key] = newInfo;
        }
      }
    });
    setIsValidForm(newIsValid);
    setErrors(errorsCopy);
  };

  function renderInputField(field) {
    if (field === "state") {
      return (
        <StateSelector
          value={formInput["state"].val}
          onChangeHandle={(e) => {
            updateForm(e, "state");
          }}
        />
      );
    } else if (field === "contact_state") {
      return (
        <StateSelector
          value={formInput["contact_state"].val}
          onChangeHandle={(e) => {
            updateForm(e, "contact_state");
          }}
        />
      );
    } else if (field === "org_level") {
      return (
        <select
          className='form-control'
          disabled={selectedRole !== "school"}
          value={formInput[field].val}
          onChange={(e) => updateForm(e, field)}
        >
          <option value='' disabled>
            Select Org Level
          </option>
          <option value={config.ORG_LEVEL_SCHOOL}>School-level</option>
          <option value={config.ORG_LEVEL_DISTRICT}>District-level</option>
        </select>
      );
    } else if (field === "position") {
      return (
        <select
          className='form-control'
          value={formInput[field].val}
          onChange={(e) => updateForm(e, field)}
        >
          <option value='' disabled>
            Select a position
          </option>
          <option value='superintendent'>Superintendent</option>
          <option value='principal'>Principal</option>
          <option value='teacher'>Teacher</option>
          <option value='IT_or_sysadmin'>IT or Systems Admin</option>
          <option value='administrator'>Administrator</option>
          <option value='accounting'>Accounting</option>
          <option value='other'>Other</option>
        </select>
      );
    } else {
      return (
        <input
          className='form-control input-underline input-lg'
          style={{border: errors[field].val ? '1px solid #db2269' : ''}}
          required={formInput[field].reqd}
          placeholder={formInput[field].placeholder}
          value={formInput[field].val}
          onChange={(e) => updateForm(e, field)}
        />
      );
    }
  }

  return (
    <div className='contacts_popup_graybox'>
      <div id='create_org_popup_whitebox'>
        <div className='common_heading d-flex justify-content-between align-items-center'>
          <h5 style={{marginBottom: "0px"}}>Create Organization</h5>
          <div
            style={{
              cursor: "pointer",
              padding: "5px",
              width: "fit-content",
            }}
            onClick={() => {
              setShowCreateOrgForm(false);
            }}
          >
            <FA color='gray' icon='times' />
          </div>
        </div>

        {!isLoading ? (
          <form id='create_org_form' autoComplete='off'>
            {error && (
              <div className='row my-2'>
                <div className='alert alert-danger w-100' role='alert'>
                  {error}
                </div>
              </div>
            )}

            <div>
              <h5 style={{marginBottom: "0px"}}>Org Information:</h5>
              <div id='create_org_info_container'>
                <div className='create_org_fields'>
                  <div className='create_org_hr_error_container'>
                    <h6
                      style={{marginBottom: "0px", width: "fit-content"}}
                      className='create_org_fields_hr'
                    >
                      *Org type:
                    </h6>
                    <span className='org_form_errors'>
                      {!selectedRole || selectedRole === "Org Type"
                        ? "*Required"
                        : ""}
                    </span>
                  </div>

                  <div className='org_form_icons mx-auto'>
                    <label>
                      <FA icon='users' />
                    </label>
                    <select
                      className='form-control input-underline input-lg'
                      style={{border: !selectedRole || selectedRole === "Org Type"  ? '1px solid #db2269' : ''}}
                      required
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                    >
                      <option disabled key={defaultRole} value={defaultRole}>
                        {defaultRole}
                      </option>
                      <option key={"school"} value={"school"}>
                        {"school"}
                      </option>
                      <option key={"home"} value={"home"}>
                        {"home"}
                      </option>
                    </select>
                  </div>
                </div>

                {["district", "state", "org_level"].map((k) => (
                  <div key={k} className='create_org_fields'>
                    {/* Other */}

                    <div className='create_org_hr_error_container'>
                      <h6 style={{marginBottom: "0px"}}>
                        {(formInput[k].reqd ? '*' : '') + formInput[k].placeholder + ":"}
                      </h6>

                      <span className='org_form_errors'>{errors[k].val}</span>
                    </div>

                    <div className='org_form_icons mx-auto'>
                      <label>
                        <FA icon={formInput[k].icon} />
                      </label>
                      {renderInputField(k)}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div id='create_org_name_container'>
              <div>
                <div className='create_org_hr_error_container'>
                  <h6
                    style={{marginBottom: "0px", width: "fit-content"}}
                    className='create_org_fields_hr'
                  >
                    *Org Name:
                  </h6>

                  <span className='org_form_errors'>{errors.name.val}</span>
                </div>
                <div className='org_form_icons mx-auto'>
                  <label>
                    <FA icon={formInput["name"].icon} />
                  </label>
                  <Autocomplete
                    freeSolo
                    style={{border: errors["name"].val ? '1px solid #db2269' : '', borderRadius: '5px'}}
                    fullWidth={true}
                    options={allOrgNames}
                    onInputChange={(e, newValue) =>
                      updateForm(e, "name", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin='none'
                        placeholder={formInput["name"].placeholder}
                        required={formInput["name"].reqd}
                        variant='outlined'
                        size='small'
                        value={formInput["name"].val}
                      />
                    )}
                  />
                </div>
              </div>
              <p
                style={{
                  textAlign: "center",
                  marginBottom: "0px",
                  alignSelf: "center",
                }}
              >
                Existing organizations will show up in the dropdown. If an
                organization already exists, please use the View / Manage 
                Org page by closing this popup and clicking on the 
                magnifying glass button next to the org.
              </p>
            </div>
            <div
              id='create_org_contacts_container'
              className='visible-scrollbar'
            >
              <h5>Primary Contact:</h5>
              <div className='create_org_contact_inputs_container'>
                {[
                  "contact_name",
                  "address1",
                  "email",
                  "address2",
                  "phone",
                  "city",
                  "position",
                  "contact_state",
                  "zipcode",
                  "notes",
                ].map((k) => (
                  <div key={k} className='create_org_fields'>
                    {/* Other */}

                    <div className='create_org_hr_error_container'>
                      <h6
                        style={{marginBottom: "0px", width: "fit-content"}}
                        className='create_org_fields_hr'
                      >
                        {(formInput[k].reqd ? '*' : '') + formInput[k].placeholder + ":"}
                      </h6>

                      <span className='org_form_errors'>{errors[k].val}</span>
                    </div>
                    <div className='org_form_icons mx-auto'>
                      <label>
                        <FA icon={formInput[k].icon} />
                      </label>
                      {renderInputField(k)}
                    </div>
                  </div>
                ))}
              </div>

                <h5 style={{marginTop: '10px'}}>
                  Accounting Contact (Optional):
                </h5>

              <div className='create_org_contact_inputs_container'>
                {[
                  "acc_contact_name",
                  "acc_contact_email",
                  "acc_contact_phone",
                ].map((k) => (
                  <div key={k} className='create_org_fields'>
                    {/* Other */}
                    <div className='create_org_hr_error_container'>
                      <h6 style={{marginBottom: "0px"}}>
                        {(formInput[k].reqd ? '*' : '') + formInput[k].placeholder + ":"}
                      </h6>

                      <span className='org_form_errors'>{errors[k].val}</span>
                    </div>
                    <div className='org_form_icons mx-auto'>
                      <label>
                        <FA icon={formInput[k].icon} />
                      </label>
                      {renderInputField(k)}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div id='create_org_footer'>
              <p
                style={{
                  width: "calc(100% - 40px)",
                  marginLeft: "20px",
                  textAlign: "center",
                  marginBottom: "0px",
                }}
              >
                Additional contacts can be added later on the View/Manage Orgs
                page.
              </p>
              <div id='create_org_btns_container'>
                <button
                  type='submit'
                  disabled={!isValidForm}
                  onClick={handleSubmit}
                  className='btn btn-md btn-primary'
                  style={{margin: "5px"}}
                  data-title={!isValidForm ? "Form is incomplete" : ""}
                >
                  {spin ? (
                    <FAW icon='spinner' spin />
                  ) : (
                    <span ngif='!icon_show' className='mx-auto'>
                      Create Org
                    </span>
                  )}
                </button>

                <button
                  onClick={() => setShowCreateOrgForm(false)}
                  className='btn btn-md btn-danger'
                  style={{margin: "5px"}}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        ) : (
          <h4>
            <FA color='gray' icon='spinner' spin /> Loading Form...
          </h4>
        )}
      </div>
    </div>
  );
};

export default compose(withAPI, withRouter)(CreateOrgForm);
