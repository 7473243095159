import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./LearnerBySession.scss";
import withAPI from "../../../../services/api";
import GroupedLessonsSelect from "./GroupedLessonsSelect";
import LearnerBySessionCarousel from "./LearnerBySessionCarousel";
import FA from "../../../../containers/fa";
import config from "../../../../common/config";
import LessonContextTextInfo from "../../LessonContextTextInfo";
import { compose } from "redux";
import { connect } from "react-redux";
import PdfDownloadBtn from "../../ClassTab/PdfDownloadBtn";
import NoGroupedLessonsErrMsg from "../ReportsErrMsgs/NoGroupedLessonsErrMsg";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  robotType: state.status.robotType,
  homeClassId: state.homeVersion.homeClassId,
});

const LearnerBySession = ({
  error,
  ready,
  activeLearner,
  api,
  user,
  setShowOverlay,
}) => {
  const isHomeVersion =
    user && user.organization.category === config.ABII_HOME.toLowerCase();

  const [fetchingLessons, setFetchingLessons] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [pairings, setPairings] = useState({});
  const [fetchingLessonsErr, setFetchingLessonsErr] = useState(null);
  const [lessonInstances, setLessonInstances] = useState([]);
  const [selectedLessonInstance, setSelectedLessonInstance] = useState("");
  const [lessonContextInfo, setLessonContextInfo] = useState(null);
  const [selectedLessonName, setSelectedLessonName] = useState("");
  const [selectedLessonId, setSelectedLessonId] = useState("");
  const [classAverageData, setClassAverageData] = useState(null);
  const [learnerLessonReport, setLearnerLessonReport] = useState(null);
  const [attentionNotRecorded, setAttentionNotRecorded] = useState(false);
  const [learnerFetchErr, setLearnerFetchErr] = useState(null);
  const [noGroupLessons, setNoGroupLessons] = useState(false);

  const [latestInstance, setLatestInstance] = useState(null);

  useEffect(() => {
    if (!activeLearner) return;
    setFetchingLessons(true);
    setSelectedLessonId("");
    setSelectedLessonInstance("");
    setLessonContextInfo(null);
    setNoGroupLessons(false);
    setLearnerFetchErr(null);

    api
      .fetchLessonsForLearner(activeLearner.sid)
      .then((lessons) => {
        // next, get this set of lessons processed by comm_management_backend
        // lessons now lesson_ids (with label)
        console.log("LESSONS taken by the learner:", lessons);
        let postData = { lessons: lessons["lesson_ids"] };
        let latestLesson = lessons["latest_lesson"];
        if (latestLesson) {
          setSelectedLessonId(latestLesson);
        }
        fetchGroupLessons(postData);
      })
      .catch((err) => {
        console.log(err.message);
        setFetchingLessons(false);
        setFetchingLessonsErr(
          `Something went wrong fetching lessons for this learner.
          Please make sure you are connected to the internet and try again
          in a few seconds.`
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLearner]);

  /**
   * fetchGroupLessons uses the lessons for the learner and gets the groups
   * for those lessons to build the lesson select.
   * @param {Object} postData {lessons: lessons}
   */
  const fetchGroupLessons = (postData) => {
    api
      .groupLessons(postData)
      .then((res) => {
        setFetchingLessons(false);
        setFetchingLessonsErr(null);
        if (res.grouped_lessons) {
          setLessons(res.grouped_lessons);
        } else {
          setNoGroupLessons(true);
          setLessons([]);
        }
        setPairings(res.pairings);
      })
      .catch((err) => {
        setLearnerFetchErr(
          `Something went wrong fetching lessons for this learner.
          Please make sure you are connected to the internet and try again
          in a few seconds.`
        );
        setLessons([]);
        setFetchingLessons(false);
        console.log(err);
      });
  };

  const updateLessonData = (lessonId, lessonName) => {
    setSelectedLessonId(lessonId);
    setSelectedLessonInstance("");
    setLessonInstances([]);
    setSelectedLessonName(lessonName);

    api
      .fetchLearnerInstances(lessonId, activeLearner.sid)
      .then((lessonInstances) => {
        setLessonInstances(lessonInstances);
        console.log("LESSON INSTANCES", lessonInstances);
        let latest_session = lessonInstances[lessonInstances.length - 1];
        let latest_instance = {
          value: latest_session.lesson_uuid,
          label: `${latest_session.date} ${latest_session.time} v${latest_session.version} - ${latest_session.name}`,
          lessonName: `${latest_session.name}`,
        };
        setLatestInstance(latest_instance);
      })
      .catch((err) => {
        console.log(err.message);
        setFetchingLessonsErr(err.message);
      });

    api
      .fetchLessonReportContextData(lessonId)
      .then((lci) => {
        setLessonContextInfo(lci);
      })
      .catch((err) => {
        console.log(err.message);
        setFetchingLessonsErr(err.message);
      });
  };

  const updateLessonInstanceData = (e) => {
    setSelectedLessonInstance(e);
    const lessonInstance = lessonInstances.find(
      (x) => x.lesson_uuid == e.value
    );
    if (!lessonInstance) return;

    const versionId = lessonInstance.version;
    const lessonId = lessonInstance.lesson_id;
    const date = lessonInstance.date;
    const time = lessonInstance.time;
    const uuid = lessonInstance.lesson_uuid;

    api
      .fetchLearnerReportsForLessonInstance(
        lessonId,
        activeLearner.sid,
        versionId,
        date,
        time,
        uuid
      )
      .then((learnerReport) => {
        setLearnerLessonReport(learnerReport);
      })
      .catch((err) => {
        console.log(err.message);
        setFetchingLessonsErr(err.message);
      });

    api
      .fetchLearnerAverageReport(
        lessonId,
        activeLearner.classId,
        activeLearner.sid
      )
      .then((averageReport) => {
        setClassAverageData(averageReport);
      })
      .catch((err) => {
        console.log(err.message);
        setFetchingLessonsErr(err.message);
      });
  };

  useEffect(() => {
    updateLessonInstanceData(latestInstance);
  }, [latestInstance]);

  return (
    <div id='learner_by_session_container'>
      <div className='learner_tab_header_container'>
        <h4>
          <b>Learner Performance + Attention By Session</b>
        </h4>
        <PdfDownloadBtn
          btnCaption='Download Lesson Report...'
          enableBtn={
            selectedLessonId && selectedLessonInstance && activeLearner
          }
          setShowOverlay={setShowOverlay}
          type='learnerLessonSession'
          activeLearner={activeLearner}
          selectedLessonName={selectedLessonName}
          selectedLessonInstance={selectedLessonInstance}
        />
      </div>

      <div className='learner_tab_body'>
        {!error &&
        !fetchingLessonsErr &&
        !fetchingLessons &&
        lessons &&
        lessons.length !== 0 ? (
          <div id='learner_session_options_container'>
            <div id='learner_session_options' style={{ margin: "5px" }}>
              <div style={{ margin: "5px" }}>
                <GroupedLessonsSelect
                  groupedLessons={lessons}
                  changeHandler={updateLessonData}
                  selectedValue={selectedLessonId}
                />
              </div>

              <div style={{ margin: "5px" }}>
                <Select
                  aria-label='Select a session'
                  isSearchable={false}
                  options={lessonInstances.map((session, i) => ({
                    value: session.lesson_uuid,
                    label: `${session.date} ${session.time} v${session.version} - ${session.name}`,
                    lessonName: `${session.name}`,
                  }))}
                  isDisabled={!selectedLessonId || !ready}
                  onChange={updateLessonInstanceData}
                  value={selectedLessonInstance}
                  placeholder='Select a session'
                />
              </div>
            </div>
            {lessonContextInfo !== null && (
              <div
                id='lesson_context_info_container'
                className='lesson_context_info_container'
              >
                <LessonContextTextInfo
                  lessonContextInfo={lessonContextInfo}
                  fetchingLessons={fetchingLessons}
                  pairings={pairings}
                  updateLessonData={updateLessonData}
                  learnerLessonReport={learnerLessonReport}
                  isHomeVersion={isHomeVersion}
                />
              </div>
            )}
          </div>
        ) : fetchingLessons ? (
          <div>
            <FA color='gray' icon='spinner' spin /> Loading...
          </div>
        ) : noGroupLessons ? (
          <NoGroupedLessonsErrMsg />
        ) : error || fetchingLessonsErr || learnerFetchErr ? (
          <div>An error has occurred. Please try again later.</div>
        ) : null}

        <LearnerBySessionCarousel
          activeLearner={activeLearner}
          fetchingLessons={fetchingLessons}
          selectedLessonId={selectedLessonId}
          selectedLessonInstance={selectedLessonInstance}
          classAverageData={classAverageData}
          lessonContextInfo={lessonContextInfo}
          learnerLessonReport={learnerLessonReport}
          attentionNotRecorded={attentionNotRecorded}
          setAttentionNotRecorded={setAttentionNotRecorded}
        />
      </div>

      {selectedLessonInstance && (
        <div
          id='lesson_session_notes'
          className='alert-primary'
          style={{ borderRadius: "0.25rem", padding: "0.5rem" }}
        >
          <strong>Note:</strong>
          <ul>
            <li> Switch between graphs by clicking on the circles.</li>
            <li>
              {" "}
              Click on the data points to view the question on the line graph.
            </li>
            {lessonContextInfo && lessonContextInfo.type === "TUT" && (
              <li>
                {" "}
                Average statistics above do not include pre-assessment question
                (question 1) unless learner aced the pre-assessment question.{" "}
              </li>
            )}
            {attentionNotRecorded && (
              <li> Attention not recorded during this lesson.</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(LearnerBySession);
