import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import withAPI from "../../../services/api";
import FAW from "../../../containers/faw";
import FA from "../../../containers/fa";
import MultiSchoolReactSelect from "../../InputFields/MultiSchoolReactSelect";
import { getAllSchoolInfo } from "../../InputFields/helpers";

const CreateSuperAdminSchoolModal = ({
  api,
  orgId,
  shouldShowFunc,
  refreshList,
}) => {
  const [shouldSpin, setShouldSpin] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const [superAdminSchoolLabel, setSuperAdminSchoolLabel] = useState("");
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [createWithLabel, setCreateWithLabel] = useState(false);
  const [orgHasNoSchools, setOrgHasNoSchools] = useState(false);

  useEffect(() => {
    let params = new URLSearchParams();
    if (orgId) {
      params.append("organization", orgId);
    }
    getAllSchoolInfo(api, params).then((schools) => {
      if (schools.length > 0) {
        setCreateWithLabel(false);
      } else {
        setCreateWithLabel(true);
        setOrgHasNoSchools(true);
      }
    });
  }, [orgId]);

  const createSchoolInOrg = () => {
    if (superAdminSchoolLabel) {
      setError(false);
      setSuccess(false);
      setErrMsg(null);
      setShouldSpin(true);
      console.log("Creating school in org: ", orgId);
      let data = {
        name: superAdminSchoolLabel,
        organization: orgId,
        orgunits: selectedSchools.map((school) => school.id),
        create_orgunit_with_label: createWithLabel,
      };
      api
        .createSuperAdminSchool(data)
        .then((resp) => {
          setShouldSpin(false);
          setSuccess(true);
        })
        .catch((err) => {
          setShouldSpin(false);
          setErrMsg("Something went wrong. Please try again.");
          setError(true);
          console.log("Error Creating School: ", err);
        });
    } else {
      setError(true);
      setSuccess(false);
      setErrMsg("Buyer Label is required");
      setShouldSpin(false);
    }
  };

  const refreshAndClose = () => {
    refreshList();
    shouldShowFunc(false);
  };

  const handleSchoolChange = (selectedSchools) => {
    if (selectedSchools && selectedSchools.length > 0) {
      setSelectedSchools(
        selectedSchools.map((option) => ({
          name: option.label,
          id: option.value,
        }))
      );
    } else {
      setSelectedSchools([]);
    }
  };

  const handleSchoolBlur = () => {
    if (selectedSchools.length === 1) {
      setSuperAdminSchoolLabel(selectedSchools[0].name);
    } else {
      setSuperAdminSchoolLabel("");
    }
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Create Buyer Label</p>

            <div
              className='pdf_popup_close'
              onClick={() => shouldShowFunc(false)}
            >
              <FA icon='times' />
            </div>
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && <div className='alert alert-danger'>{errMsg}</div>}
                {success && (
                  <div className='alert alert-success' role='alert'>
                    Successfully created the school!
                  </div>
                )}
              </div>
              {!success && (
                <div className='col-12'>
                  <form>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='optionRadios'
                        id='existingSchoolsRadio'
                        value='existingSchools'
                        checked={!createWithLabel}
                        onChange={() => setCreateWithLabel(!createWithLabel)}
                        disabled={orgHasNoSchools}
                      />
                      <label
                        className='form-check-label'
                        htmlFor='existingSchoolsRadio'
                      >
                        Create a new buyer label to encompass an existing school
                        within the organization.{" "}
                        <i>
                          Choose this option if you see the school(s) you're
                          looking for in the 'School(s)' dropdown.
                        </i>
                      </label>
                    </div>
                    <b>OR</b>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='optionRadios'
                        id='newSchoolRadio'
                        value='newLabel'
                        checked={createWithLabel}
                        onChange={() => setCreateWithLabel(!createWithLabel)}
                      />
                      <label
                        className='form-check-label'
                        htmlFor='newSchoolRadio'
                      >
                        Create a new buyer label that corresponds to a new
                        school within the organization.{" "}
                        <i>
                          Choose this option if you don't see the school(s)
                          you're looking for in the 'School(s)' dropdown.
                        </i>
                      </label>
                    </div>
                  </form>
                </div>
              )}
              <div className='col-12'>
                {success && (
                  <button
                    onClick={() => refreshAndClose()}
                    className='btn btn-sm btn-success'
                  >
                    Okay
                  </button>
                )}

                {shouldSpin && (
                  <div className='alert alert-info mt-3'>
                    <FAW icon='spinner' spin /> Creating school ...
                  </div>
                )}

                {!shouldSpin && !success && (
                  <>
                    <div id='new-label-grid'>
                      {!createWithLabel && (
                        <div className='new-label-row'>
                          <div className='item-label'>
                            <label for='school-select'>School(s)</label>
                          </div>
                          <MultiSchoolReactSelect
                            value={selectedSchools}
                            handleChange={handleSchoolChange}
                            orgId={orgId}
                            handleBlur={handleSchoolBlur}
                          />
                        </div>
                      )}
                      <div className='new-label-row'>
                        <div className='item-label'>
                          <label htmlFor='super-admin-school-label'>
                            Buyer Label
                          </label>
                        </div>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Buyer Label'
                          value={superAdminSchoolLabel}
                          onChange={(e) =>
                            setSuperAdminSchoolLabel(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div className='mt-4'>
                        <button
                          onClick={createSchoolInOrg}
                          className='btn btn-sm btn-primary'
                          disabled={
                            (selectedSchools.length === 0 &&
                              !createWithLabel) ||
                            !superAdminSchoolLabel
                          }
                        >
                          Create Buyer Label
                        </button>
                        <button
                          onClick={() => shouldShowFunc(false)}
                          className='btn btn-sm btn-danger ml-2'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, withAPI)(CreateSuperAdminSchoolModal);
