import MomentUtils from "@date-io/moment";
import TextField from "@mui/material/TextField";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, {useEffect, useState} from "react";

const CreateAssignmentStepThree = ({
  display,
  newName,
  setNewName,
  newStartTime,
  setNewStartTime,
  newEndTime,
  setNewEndTime,
  setDateErr,
  pastDateRangeError,
  setPastDateRangeError
}) => {
  const [endDateError, setEndDateError] = useState(false);
  const [validStartDateErr, setValidStartDateErr] = useState(false);
  const [validEndDateErr, setValidEndDateErr] = useState(false);

  // info for future start dates

  useEffect(() => {
    /* ensures that if the start date is before the end date, regardless of
    which widget you edited, the error msgs will go away.*/

    let startTimeIsValid = moment(newStartTime).isValid();
    let endTimeIsValid =
      newEndTime === "" || newEndTime === null || moment(newEndTime).isValid();

    if (!startTimeIsValid) {
      setValidStartDateErr(true);
      setDateErr(true);
    } else {
      setValidStartDateErr(false);
    }

    if (!endTimeIsValid) {
      setValidEndDateErr(true);
      setDateErr(true);
    } else {
      setValidEndDateErr(false);
    }

    if (moment(newStartTime) > moment(newEndTime) && newEndTime) {
      // due date is before start date.
      setEndDateError(true);
      setDateErr(true);
    } else {
      setEndDateError(false);
      if (startTimeIsValid && endTimeIsValid) {
        setDateErr(false);
      }
    }

    if (
      startTimeIsValid &&
      endTimeIsValid &&
      moment(newStartTime).isBefore() &&
      moment(newEndTime).isBefore() &&
      !moment(newEndTime).isSame(new Date(), "day")
    ) {
      // the entire date range is in the past.
      setPastDateRangeError(true);
    } else {
      setPastDateRangeError(false);
    }
  }, [newEndTime, newStartTime]);

  return (
    <div
      id='create_assignment_step_three'
      className='create_assignment_step_content'
      style={{display: display, justifyContent: "center"}}
    >
      <div>
        <p style={{textAlign: "center"}}>
          Name and Date: Name your assignment, choose when your assignment
          begins, and then set a due date.{" "}
        </p>
        <div
          className='edit_assignment_field_container'
          style={{margin: "auto"}}
        >
          <p className='edit_assignment_field_container_caption'>
            Assignment Name:
          </p>

          <TextField
            size='small'
            onChange={(e) => {
              setNewName(e.target.value);
            }}
            value={newName}
            inputProps={{maxLength: 40}}
          />
        </div>

        <div style={{gridArea: "c"}}>
          <div id='edit_assignment_timeline_container'>
            <p
              className='edit_assignment_field_container_caption timeframe_caption'
              style={{gridArea: "a"}}
            >
              Timeframe:
            </p>
            <div
              className='edit_assignment_timeline_picker'
              style={{gridArea: "d", marginTop: "10px"}}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                utils={MomentUtils}
              >
                <DesktopDatePicker
                  label='Start Date'
                  value={newStartTime}
                  onChange={(value) => {
                    console.log(value);
                    setNewStartTime(value);
                  }}
                  disableMaskedInput={true}
                  inputFormat='MMM. dd, yyyy'
                  PopperProps={{
                    sx: {
                      '& .MuiPaper-root': {
                        transform: 'translate(8%, -111%) !important',
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{width: "100%"}}
                      helperText={
                        validStartDateErr
                          ? "Please enter a valid date."
                          : endDateError
                          ? "Due date cannot be before the start date."
                          : pastDateRangeError
                          ? "The date range must be current or in the future."
                          : ""
                      }
                      {...params}
                    />
                  )}
                  onError={(reason, value) => {
                    console.log("reason:", reason);
                  }}
                />
              </LocalizationProvider>
            </div>
            <div id='edit_assignment_timeline_arrow' style={{gridArea: "e"}}>
              <hr></hr>
              <p>
                <i className='arrow right'></i>
              </p>
            </div>
            <div
              className='edit_assignment_timeline_picker'
              style={{gridArea: "f", marginTop: "10px"}}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                utils={MomentUtils}
              >
                <DesktopDatePicker
                  label='Due Date (Optional)'
                  minDate={newStartTime}
                  value={newEndTime}
                  defaultValue={null}
                  onChange={(value) => {
                    setNewEndTime(value);
                  }}
                  disableMaskedInput={true}
                  inputFormat='MMM. dd, yyyy'
                  PopperProps={{
                    sx: {
                      '& .MuiPaper-root': {
                        transform: 'translate(8%, -111%) !important',
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{width: "100%"}}
                      helperText={
                        !newEndTime
                          ? ""
                          : validEndDateErr
                          ? "Please enter a valid date."
                          : endDateError
                          ? "Due date cannot be before the start date."
                          : pastDateRangeError
                          ? "The date range must be current or in the future."
                          : ""
                      }
                      {...params}
                    />
                  )}
                  onError={(reason, value) => {
                    console.log("reason:", reason);
                    if (reason === "null") setNewEndTime(null);
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        {newStartTime && moment(newStartTime).isAfter() && (
          <p
            style={{width: "100%", marginTop: "20px", textAlign: "left"}}
            className='alert alert-secondary'
          >
            Note: Learners won't be able to complete the assignment until the
            start date.
          </p>
        )}
      </div>
    </div>
  );
};

export default CreateAssignmentStepThree;
