import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";

import config from "../../common/config";
import ClassDeleteModal from "../../components/TeacherDashboard/ClassDeleteModal.js";
import NewClassButton from "../../components/TeacherDashboard/NewClassButton";
import AddStudentsModal from "../../components/TeacherDashboard/AddStudentsModal";
import { sortedArray, useTitle } from "../../common/utils";
import FA from "../../containers/fa";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";
import "../TeacherDashboard/Dashboard.scss";
import Class from "../TeacherDashboard/Class";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  robotType: state.status.robotType,
});

const ManageClasses = ({ api, robotType, user, match }) => {
  const myClassesUrl = "admin/my-classes";

  const title = match.path.includes(myClassesUrl) ? "My Classes" : "Classes";
  useTitle(title);

  const [classes, setClasses] = useState([]);
  const [myClasses, setMyClasses] = useState([]);
  const [filteredAllClasses, setFilteredAllClasses] = useState([]);
  const [filteredMyClasses, setFilteredMyClasses] = useState([]);
  const [showAllClasses, setShowAllClasses] = useState(true);
  const [ready, setReady] = useState(false);
  const [shouldShowClassDeleteModal, setShouldShowClassDeleteModal] =
    useState(null);
  const [classesExpanded, setClassesExpanded] = useState([]);
  const [classToEdit, setClassToEdit] = useState(null);
  const [editableClassName, setEditableClassName] = useState("");
  const [classToggleEnabled, setClassToggleEnabled] = useState(true);
  const [refreshClasses, setRefreshClasses] = useState(false);

  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  const [classToAddStudents, setClassToAddStudents] = useState({
    id: null,
    name: null,
  });
  const [refreshForNewLearners, setRefreshForNewLearners] = useState(null);

  const fetchClassData = () => {
    api.fetchObjectsForTeacher().then((objects) => {
      console.log("FETCHCLASSDATA FINISHED");
      setMyClasses(objects.usertags);
      setRefreshClasses(false);
    });

    api.fetchAllVisibleUserTags().then((objects) => {
      setClasses(objects);
      setReady(true);
    });
  };

  useEffect(() => {
    fetchClassData();
  }, []);

  useEffect(() => {
    console.log("-----------------------------------");
    console.log(refreshClasses);
    if (refreshClasses) {
      setRefreshClasses(false);
    }
  }, [refreshClasses]);

  useEffect(() => {
    if (!classToggleEnabled) {
      setTimeout(() => {
        setClassToggleEnabled(true);
      }, 600);
    }
  }, [classToggleEnabled]);

  useEffect(() => {
    setFilteredAllClasses(sortedArray(classes, "created_at"));
  }, [classes]);

  useEffect(() => {
    setFilteredMyClasses(sortedArray(myClasses, "created_at"));
  }, [myClasses]);

  useEffect(() => {
    if (window.location.href.includes(myClassesUrl)) {
      setShowAllClasses(false);
    } else {
      setShowAllClasses(true);
    }
  }, []);

  const isAdminAccount = () => {
    if (user) {
      if (
        [config.ORGADMIN_USERTYPE, config.ORGUNITADMIN_USERTYPE].includes(
          user.usertype
        )
      ) {
        return true;
      }
    }
    return false;
  };

  if (robotType === config.ABII_HOME) {
    return null;
  }

  const saveNewName = (clsId) => {
    api
      .patchUserTagInfo(clsId, { name: editableClassName })
      .then(() => {
        setClassToEdit(null);
        setEditableClassName("");
        fetchClassData();
      })
      .catch((e) => {
        console.log(e);
        setClassToEdit(null);
      });
  };

  const renderClasses = (classList) => {
    return classList.map((cls) => (
      <div
        id={`class-${cls.id}`}
        key={cls.id}
        style={{
          width: "100%",
          borderRadius: "5px 5px 0px 0px",
          marginBottom: "20px",
        }}
      >
        <div
          className='card batch_listings'
          style={{ marginBottom: "0px", border: "none" }}
        >
          <div className='card-body text-center class-header-grid'>
            {classToEdit === cls.id ? (
              <div style={{ display: "flex", flexFlow: "row" }}>
                <input
                  aria-label='New Class Name'
                  name='new-class-name'
                  type='text'
                  onChange={(e) => setEditableClassName(e.target.value)}
                  value={editableClassName}
                />
                <button
                  style={{ marginLeft: "1em" }}
                  className='btn btn-sm btn-primary'
                  onClick={() => saveNewName(cls.id)}
                >
                  Save
                </button>
                &nbsp;
                <button
                  style={{ marginLeft: "1em" }}
                  className='btn btn-sm btn-secondary'
                  onClick={() => setClassToEdit(null)}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div
                className='class-name-and-triangle-grid'
                onClick={() => {
                  if (classesExpanded.indexOf(cls.id) === -1) {
                    if (!classToggleEnabled) {
                      return;
                    }
                    setClassToggleEnabled(false);
                    setClassesExpanded([cls.id, ...classesExpanded]);
                    setTimeout(() => {
                      console.log("changing overflowY");
                      document.getElementById(
                        "learners-wrapper-" + cls.id
                      ).style.overflow = "";
                    }, 500);
                  } else {
                    if (!classToggleEnabled) {
                      return;
                    }
                    setClassToggleEnabled(false);
                    setClassesExpanded(
                      classesExpanded.filter((item) => item !== cls.id)
                    );
                    document.getElementById(
                      "learners-wrapper-" + cls.id
                    ).style.overflow = "hidden";
                  }
                }}
              >
                <div
                  className='assignment-name-and-triangle-grid'
                  style={{ paddingLeft: "10px" }}
                >
                  <button
                    className={`triangle-${
                      classesExpanded.indexOf(cls.id) !== -1 ? "up" : "down"
                    }`}
                  />
                </div>
                {cls.name} &nbsp;
              </div>
            )}

            <div style={{ width: "fit-content", justifySelf: "end" }}>
              <button
                aria-label='Add Students'
                data-title='Add Students'
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  setShowAddStudentsModal(true);
                  setClassToAddStudents({ id: cls.id, name: cls.name });
                }}
                className='btn btn-small'
              >
                <FA icon='user-plus' color='#4B4B4B' />
              </button>

              <button
                aria-label='Edit Class Name'
                data-title='Edit Class Name'
                className='btn btn-small'
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  setClassToEdit(cls.id);
                  setEditableClassName(cls.name);
                }}
              >
                <FA icon='edit' color='#4B4B4B' />
              </button>

              <Link
                aria-label='View Reports'
                data-title='View Reports'
                className='btn btn-small'
                to={{
                  pathname: `/user/reports/${cls.id}`,
                  state: {
                    rerouteObject: cls,
                    rerouteObjectType: "class",
                    rerouteSource: "myClass",
                  },
                }}
              >
                <FA icon='chart-bar' color='#4B4B4B' />
              </Link>

              {shouldShowClassDeleteModal === cls.id && (
                <ClassDeleteModal
                  shouldShowFunc={setShouldShowClassDeleteModal}
                  clsId={cls.id}
                  refreshList={fetchClassData}
                  clsName={cls.name}
                />
              )}
              <button
                aria-label='Delete Class'
                data-title='Delete Class'
                className='btn btn-small'
                style={{ backgroundColor: "transparent" }}
                onClick={() => setShouldShowClassDeleteModal(cls.id)}
              >
                <FA icon='trash' color='#4B4B4B' />
              </button>
            </div>
          </div>
        </div>

        {showAddStudentsModal && classToAddStudents.id === cls.id && (
          <AddStudentsModal
            setShowModal={setShowAddStudentsModal}
            classToAddStudents={classToAddStudents}
            setClassToAddStudents={setClassToAddStudents}
            assignClassId={cls.id}
            setRefreshForNewLearners={setRefreshForNewLearners}
          />
        )}
        <Class
          clsId={cls.id}
          clsName={cls.name}
          classesExpanded={classesExpanded}
          setClassesExpanded={setClassesExpanded}
          classes={classes}
          refreshForNewLearners={refreshForNewLearners}
          setRefreshForNewLearners={setRefreshForNewLearners}
        />
      </div>
    ));
  };

  return (
    <div>
      <MainDashboard>
        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <p>Manage Classes</p>
            <div className='d-flex justify-content-end'></div>
          </div>
          <div className='common_table_padding'>
            <div className='mb-2'>
              <NewClassButton
                disabled={!ready}
                addClassToCreator={window.location.href.includes(myClassesUrl)}
                refreshList={fetchClassData}
              />
            </div>
            {ready ? (
              !classes.length && (
                <div>
                  <p className='bold_font text-center'>Classes Not Available</p>
                </div>
              )
            ) : (
              <div>
                <h4>
                  <FA color='gray' icon='spinner' spin /> Loading classes...
                </h4>
              </div>
            )}

            {isAdminAccount() ? (
              <div className='row batch-row' style={{ marginBottom: "0px" }}>
                {showAllClasses
                  ? renderClasses(filteredAllClasses)
                  : renderClasses(filteredMyClasses)}
              </div>
            ) : (
              <p>
                You do not have the permissions to access the administrator
                dashboard.
              </p>
            )}
          </div>
        </div>
      </MainDashboard>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(ManageClasses);
