import React, { useEffect, useRef, useState } from "react";
import { getHeaderClasses } from "../../../ConsolidatedReportsUtilities";

const HeadersRowLessonName = ({ showStandardInfo, labelSplit, standard }) => {
  const ref = useRef(null);
  const [currentWidth, setCurrentWidth] = useState(0);

  useEffect(() => {
    setCurrentWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current, showStandardInfo]);

  // 190 - (standard len * 6) = space allocated for name;
  return (
    <span
      className={`header_row_lesson_name standards_showing_${showStandardInfo}`}
      style={{ display: "flex" }}
    >
      <p
        style={{
          maxWidth: showStandardInfo
            ? `${150 - standard.length * 6}px`
            : "160px",
          overflow: "hidden",
        }}
        ref={ref}
      >
        {labelSplit}
      </p>
      <span>
        {showStandardInfo && currentWidth >= 150 - standard.length * 6
          ? "..."
          : !showStandardInfo && currentWidth >= 160
          ? "..."
          : ""}
      </span>
    </span>
  );
};

/**
 * HeadersRow Creates the labels for the table based on the lessons
 * that are supposed to show up. Learner, Average, and Actions labels will
 * always appear, but the number of columns in between varies.
 * @returns {html} The first (labels) row
 */
const HeadersRow = ({
  filteredLessons,
  hasBorder,
  handleColumnHeaderClick,
  sortType,
  sortAsc,
  listOfUnits,
  showStandardInfo,
}) => {
  return filteredLessons.map((value, index) => {
    let label = value.label;
    let standard = listOfUnits[value.value]
      ? listOfUnits[value.value].standard
      : "";
    const standardDescription = value.standard_desc || "N/A";

    let labelSplit =
      label.indexOf("- TUT") !== -1
        ? label.split("- TUT")[0].trim()
        : label.split("- ASM")[0].trim();
    return (
      <div
        key={index}
        className='label_container'
        style={{
          borderRight: hasBorder(index, filteredLessons.length),
        }}
        onClick={() => {
          handleColumnHeaderClick(filteredLessons, label);
        }}
        data-title={
          showStandardInfo
            ? `Lesson Name: ${labelSplit}\nStandard Desc: ${standardDescription}`
            : `Standard: ${standard}\nStandard Desc: ${standardDescription}`
        }
      >
        <span className={`data label lessons_${filteredLessons.length}`}>
          {showStandardInfo && (
            <span className='header_row_standards'>{standard} </span>
          )}

          <HeadersRowLessonName
            showStandardInfo={showStandardInfo}
            labelSplit={labelSplit}
            standard={standard}
          />
        </span>
        <span
          className={getHeaderClasses(label, sortType, sortAsc)}
          style={{ textAlign: "center", justifyContent: "center" }}
        ></span>
      </div>
    );
  });
};

export default HeadersRow;
