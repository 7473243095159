import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import GradeDropdown from "../TeacherDashboard/GradeDropdown.js";
import LearnerResetPWModal from "./LearnerResetPWModal.js";
import "./LearnerRow.scss";
import clever from "../../assets/images/clever.ico";

const mapStateToProps = (state, ownProps) => ({
  orgStatus: state.auth.orgStatus,
});

const LearnerRow = ({
  lrn,
  classId,
  match,
  api,
  index,
  orgStatus,
  setShowAutosave,
  setAutosaveSuccess,
  setShowPopupInfo,
  setPopupInfo,
  setEditLearner,
}) => {
  const [showPW, setShowPW] = useState(false);
  const [shouldShowPWResetModal, setShouldShowPWResetModal] = useState(false);
  const [hackyPW, setHackyPW] = useState(null);
  const [lock, setLock] = useState(
    lrn.grade_info === null || typeof lrn.grade_info === "undefined"
      ? false
      : lrn.grade_info.lock
  );

  const getDefaultGradeVal = (json, val, isLabel) => {
    if (json === null || typeof json === "undefined") {
      return "unset";
    } else if (lrn.grade_info[val] === "unset") {
      return "unset";
    } else if (lrn.grade_info[val] === 0 && isLabel) {
      return "Grade K";
    } else if (!isLabel) {
      return lrn.grade_info[val];
    }
    return "Grade " + lrn.grade_info[val];
  };

  const [math_grade, setMath_grade] = useState({});
  const [reading_grade, setReading_grade] = useState({});

  const gradeIsUnset = (subject) => {
    if (subject === "math") {
      return math_grade.value === null || math_grade.value === "unset";
    } else if (subject === "reading") {
      return reading_grade.value === null || reading_grade.value === "unset";
    }
  };

  useEffect(() => {
    setMath_grade({
      label: getDefaultGradeVal(lrn.grade_info, "effective_math", true),
      value: getDefaultGradeVal(lrn.grade_info, "effective_math", false),
    });
    setReading_grade({
      label: getDefaultGradeVal(lrn.grade_info, "effective_reading", true),
      value: getDefaultGradeVal(lrn.grade_info, "effective_reading", false),
    });
  }, [lrn.grade_info]);

  const setReadingAndMath = (changeType, obj) => {
    if (changeType === "math") {
      setMath_grade(obj);
      if (gradeIsUnset("reading")) {
        setReading_grade(obj);
      }
    } else {
      setReading_grade(obj);
      if (gradeIsUnset("math")) {
        setMath_grade(obj);
      }
    }
  };

  const saveGradeInfoChanges = (changeType, newVals) => {
    // updateLearnerGradeInfo
    let new_grade_info = null;
    setAutosaveSuccess(null);
    setShowAutosave(true);
    const isMathGrade = changeType === "math";
    const isReadingGrade = changeType === "reading";

    // get reading grades
    const getReadingGrade = () => {
      if (isMathGrade) {
        if (gradeIsUnset("reading")) {
          return newVals.value;
        } else {
          return reading_grade.value;
        }
      } else if (isReadingGrade) {
        return newVals.value;
      } else {
        // lock change
        return reading_grade.value;
      }
    };

    // get math grades
    const getMathGrade = () => {
      if (isReadingGrade) {
        if (gradeIsUnset("math")) {
          return newVals.value;
        } else {
          return math_grade.value;
        }
      } else if (isMathGrade) {
        return newVals.value;
      } else {
        // lock change
        return math_grade.value;
      }
    };

    // OTHER grades will be updated on backend according to reading
    new_grade_info = {
      assigned_math: getMathGrade(),
      effective_math: getMathGrade(),
      assigned_reading: getReadingGrade(),
      effective_reading: getReadingGrade(),
      lock: !isReadingGrade && !isMathGrade ? newVals : lock,
    };

    api
      .updateLearnerGradeInfo(lrn.user.id, new_grade_info)
      .then((resp_json) => {
        setAutosaveSuccess(true);
        setShowAutosave(false);
      })
      .catch((e) => {
        console.log(e);
        setAutosaveSuccess(false);
        setShowAutosave(false);
      });
  };

  if (classId) {
    classId = classId;
  } else if (match.params.clsId) {
    classId = match.params.clsId;
  } else {
    classId = "by-learner";
  }

  const showLessonCountNA = () => {
    if (lrn.recent_weekly_lesson === null) {
      return true;
    } else {
      return false;
    }
  };

  const findAvgWeeklyLessonBgColor = (val) => {
    if (orgStatus !== "active") {
      return "";
    }
    if (val >= 4) {
      return "#D9ECDB";
    } else if (val === 0) {
      return "#F3D8DA";
    } else if (val > 0 && val < 4) {
      return "#FDF2D1";
    }
    return "";
  };

  const displayedPassword = hackyPW || lrn.student_pwd;

  const roundedRecentLessons =
    lrn && Math.round(lrn.recent_weekly_lesson * 10 * (30 / 7)) / 10;

  const getIcon = () => {
    if (orgStatus !== "active") {
      return "";
    }
    if (roundedRecentLessons >= 4) {
      return <FA icon='check' className='has-wifi status-icon' />;
    } else {
      if (roundedRecentLessons === 0) {
        return <FA icon='times' className='no-wifi status-icon' />;
      } else {
        return (
          <FA
            icon='exclamation-circle'
            color='#ffc107'
            className='status-icon'
          />
        );
      }
    }
  };

  return (
    <div key={lrn.id} className='class-grid-row-fields-container'>
      <p style={{padding: "0px"}}>{index}</p>
      <p style={{paddingLeft: "0px"}}>
        {lrn.user && lrn.user.last_name + ", " + lrn.user.first_name}
        {lrn.clever_synced && (
          <img
            style={{width: '1rem', marginLeft: '0.25rem'}}
            alt='clever'
            title='Clever-synced'
            src={clever}
          />
        )}
      </p>

      <p
        style={{
          display: "-webkit-box",
          WebkitLineClamp: "3",
          WebkitBoxOrient: "vertical",
          alignSelf: "center",
          hyphens: "auto",
          overflow: "hidden",
        }}
      >
        {lrn.user &&
          lrn.assigned_teachers.map((teacher) => {
            return <span>{teacher.last_name + ", " + teacher.first_name}</span>;
          })}
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            lrn && !showLessonCountNA()
              ? findAvgWeeklyLessonBgColor(roundedRecentLessons)
              : "#f2f2f2",
        }}
      >
        {lrn && !showLessonCountNA() ? (
          <span>
            {roundedRecentLessons}
            <p style={{ padding: "0px 0px 0px 5px" }}>{getIcon()}</p>
          </span>
        ) : (
          <p>N/A</p>
        )}
      </div>

      <p style={{ paddingLeft: "0px", justifyContent: "center" }}>
        {lrn.total_lessons}
      </p>

      <p>
        {lrn && lrn.classrooms.length ? (
          <FAW icon='check' className='has-wifi status-icon' />
        ) : (
          "No"
        )}
      </p>

      <div style={{ padding: "10px 0px 10px 0px" }}>
        <div className='showPW'>
          <button
            className='btn btn-sm btn-primary btn-eye'
            onClick={() => setShowPW(!showPW)}
            title='Show Password'
            placement='top'
          >
            <FA icon='eye' />
          </button>
          {showPW ? (
            <span title={displayedPassword.length > 6 ? displayedPassword : ""}>
              <input
                type='text'
                disabled
                className='form-control input-lg'
                value={displayedPassword}
              />
            </span>
          ) : (
            <input
              type='password'
              disabled
              className='form-control input-lg text-truncate'
              value={displayedPassword}
            />
          )}
        </div>
      </div>

      <div style={{ padding: "10px 0px 10px 0px" }}>
        <GradeDropdown
          placeholder='Select a math level'
          setterFunc={setReadingAndMath}
          value={{ value: math_grade.value, label: `${math_grade.label}` }}
          saveGradeInfoChanges={saveGradeInfoChanges}
          changeType='math'
        />
      </div>
      <div style={{ padding: "10px 0px 10px 0px" }}>
        <GradeDropdown
          placeholder='Select a reading level'
          setterFunc={setReadingAndMath}
          value={{
            value: reading_grade.value,
            label: `${reading_grade.label}`,
          }}
          saveGradeInfoChanges={saveGradeInfoChanges}
          changeType='reading'
        />
      </div>
      <span className='hide_on_ipad' style={{ justifyContent: "center" }}>
        <input
          type='checkbox'
          checked={lock}
          disabled={gradeIsUnset("math") || gradeIsUnset("reading")}
          onChange={(e) => {
            setLock(e.target.checked);
            saveGradeInfoChanges("lock", e.target.checked);
          }}
        />
      </span>

      <div style={{ padding: "10px 0px 10px 0px" }} name='Actions'>
        <div className='show_on_ipad'>
          <div>
            Grade Locked
            <FAW
              icon='info-circle'
              className='info-circle'
              onClick={() => {
                setPopupInfo({
                  header: "Grade Lock",
                  text: `When a learner is grade locked, they cannot take any
                    lessons below or above their specified grade levels for
                    their subjects. This setting can be toggled on and off.`,
                });
                setShowPopupInfo(true);
              }}
            />
          </div>
          <input
            name='grade-lock-checkbox'
            aria-label='Checkbox for: Grade Lock'
            type='checkbox'
            checked={lock}
            disabled={gradeIsUnset("math") || gradeIsUnset("reading")}
            onChange={(e) => {
              setLock(e.target.checked);
              saveGradeInfoChanges("lock", e.target.checked);
            }}
          />
        </div>

        {shouldShowPWResetModal && (
          <LearnerResetPWModal
            setPWFunc={setHackyPW}
            shouldShowFunc={setShouldShowPWResetModal}
            learnerId={lrn.id}
          />
        )}

        <div className='datatable_actions'>
          <div className='d-flex align-items-start common-align-button action-btns'>
            <Link
              className='btn btn-md btn-primary'
              class-btn-data-title='View Reports'
              to={{
                pathname: `/user/reports/${lrn.id}`,
                state: {
                  rerouteObject: {
                    first_name: lrn.user.first_name,
                    label: `${lrn.user.first_name} ${lrn.user.last_name}`,
                    last_name: lrn.user.last_name,
                    sid: lrn.id,
                    type: "learner",
                    username: lrn.user.username,
                  },
                  rerouteObjectType: "learner",
                },
              }}
            >
              <FAW icon='chart-bar' />
            </Link>
            <button
              className='btn btn-md btn-primary'
              onClick={() => setShouldShowPWResetModal(!shouldShowPWResetModal)}
              class-btn-data-title='Reset Password'
            >
              <FAW icon='key' />
            </button>
            {lrn.user && lrn.user.username && (
              <button
                class-btn-data-title='Edit/Delete Learner'
                className='btn btn-md btn-primary'
                onClick={() => setEditLearner(lrn)}
              >
                <FAW icon='edit' />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withRouter)(LearnerRow);
