import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {default as FA, default as FAW} from "../../../../../containers/fa";
import withAPI from "../../../../../services/api";
import {
  buildNewSelectedLearnersList,
  selectOrDeselectLearner,
  syncSelectedInOtherClasses
} from "../../CreateAssignmentFlowUtilities";
import "../CreateAssignmentSteps.scss";
import StepTwoLearnersRow from "./StepTwoLearnersRow";

const CreateAssignmentStepTwo = ({
  display,
  selectedLessons,
  setShowSelectedLearners,
  api,
  learners,
  setLearners,
  selectedLearners,
  setSelectedLearners,
  currentPage,
  setPopupInfo,
  setShowPopupInfo,
}) => {
  const [selectedClass, setSelectedClass] = useState("");
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clearedSelections, setClearedSelections] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [numOfSelectedLessons, setNumOfSelectedLessons] = useState(0);
  const [selectedAllInClass, setSelectedAllInClass] = useState(false);
  /**
   * fetchClassData fetches the user's learners. It takes in the ids of the
   * selected lessons to determine how many the learners have already
   * completed.
   */
  function fetchClassData() {
    setErrorMsg(false);
    setLoading(true);
    let newLearners = {};
    let newClasses = [];
    let lessonIds = [];

    // creating list of selected lesson ids for the api fetch
    let count = 0;
    for (let i = 0; i < selectedLessons.length; i++) {
      if (selectedLessons[i].tutorial.selected) {
        count++;
        lessonIds = [...lessonIds, selectedLessons[i].tutorial.lesson_id];
      }
      if (selectedLessons[i].assessment.selected) {
        count++;
        lessonIds = [...lessonIds, selectedLessons[i].assessment.lesson_id];
      }
    }

    setNumOfSelectedLessons(count);

    api
      .fetchLearnersForMultiClasses({lesson_ids: lessonIds})
      .then((objects) => {
        newClasses = objects.map((value) => ({
          id: value.class_id,
          name: value.class_name,
        }));
        setClasses(newClasses);
        let selectedLearnersIds = selectedLearners.map(
          (value) => value.user_id
        );
        for (let i = 0; i < objects.length; i++) {
          newLearners[objects[i].class_id] = objects[i].learners.map(
            (value) => ({
              ...value,
              selected:
                selectedLearnersIds.indexOf(value.user_id) !== -1
                  ? true
                  : false,
            })
          );
        }
        console.log("learners fetched:", newLearners);
        setLearners(newLearners);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setErrorMsg(
          `Something went wrong fetching class data. Please make sure you are
              connected to the internet and try again in a few seconds.`
        );
        setLoading(false);
      });
  }

  useEffect(() => {
    if (currentPage === 1 || currentPage === 3) {
      fetchClassData();
    }
    setSuccessMsg("");
  }, [currentPage]);

  useEffect(() => {
    if (selectedLearners.length) {
      setSuccessMsg(
        `${selectedLearners.length} learner${
          selectedLearners.length > 1 ? "s" : ""
        } added to assignment.`
      );
    } else {
      setSuccessMsg("");
    }
  }, [selectedLearners]);

  useEffect(() => {
    if (selectedLearners.length === 0 && clearedSelections) {
      fetchClassData();
      setClearedSelections(false);
    }
  }, [selectedLearners, clearedSelections]);

  /**
   * mapLearnersByClass populates the table.
   * @returns {Array} The learner row for the table
   */
  const mapLearnersByClass = () => {
    let numberOfSelected = 0;
    let displayedLearners = learners[selectedClass].map((value, index) => {
      /* counting the number of selected learners ensures that the
      Select/Deselect btn will alternate between select all and deselect all
      at the right time.
      */
      if (value.selected) numberOfSelected++;
      if (index === learners[selectedClass].length - 1) {
        if (
          numberOfSelected !== learners[selectedClass].length &&
          selectedAllInClass
        ) {
          setSelectedAllInClass(false);
        } else if (
          numberOfSelected === learners[selectedClass].length &&
          !selectedAllInClass
        ) {
          setSelectedAllInClass(true);
        }
      }

      return (
        <StepTwoLearnersRow
          key={index}
          selectedClass={selectedClass}
          learner={value}
          numInDisplayTable={index + 1}
          learners={learners}
          setLearners={setLearners}
          setSelectedLearners={setSelectedLearners}
          numOfSelectedLessons={numOfSelectedLessons}
        />
      );
    });
    return displayedLearners;
  };

  /**
   * selectAll runs when the Select All btn is clicked. It iterates through
   * the current class's learners and selects them. It also triggers an update
   * for the table, which will then highlight all the learners.
   */
  const selectAll = () => {
    if (!selectedClass || learners[selectedClass].length === 0) return;
    let newLearners = JSON.parse(JSON.stringify(learners)); // hard copy

    for (let i = 0; i < learners[selectedClass].length; i++) {
      // selects all in the current class.
      newLearners[selectedClass][i] = selectOrDeselectLearner(
        learners,
        selectedClass,
        learners[selectedClass][i],
        setLearners,
        setSelectedLearners,
        true
      );

      newLearners = syncSelectedInOtherClasses(
        newLearners,
        selectedClass,
        i,
        learners[selectedClass][i],
        true
      );
    }

    setLearners(newLearners);
    buildNewSelectedLearnersList(newLearners, setSelectedLearners);
  };

  return (
    <div
      id='create_assignment_step_two'
      className='create_assignment_step_content'
      style={{display: display}}
    >
      <div className='two_col_grid create_assignment_step_desc_container'>
        <p>
          Learner Options: Select learners from this list by clicking their
          checkmarks, or choose a class to filter learners.
        </p>
        {errorMsg ? (
          <div className='alert alert-danger' role='alert'>
            {errorMsg}
          </div>
        ) : successMsg ? (
          <div className='alert alert-success' role='alert'>
            {successMsg}
          </div>
        ) : null}
      </div>
      <div className='two_col_grid'>
        <div className='flex_row_wrap'>
          <FormControl sx={{width: 220, maxWidth: "100%"}} size='small'>
            <InputLabel id='demo-simple-select-label'>
              Select a class
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedClass}
              label='Select a class'
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              {classes.map((val, index) => (
                <MenuItem key={index} value={val.id}>
                  {val.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div
          className='flex_row_wrap'
          style={{
            justifySelf: "flex-end",
            alignItems: "flex-start",
            justifyContent: "flex-end",
          }}
        >
          <button
            className='btn btn-sm btn-primary'
            onClick={() => setShowSelectedLearners(true)}
          >
            View all selected learners ({selectedLearners.length})
          </button>

          <button
            className='btn btn-sm btn-primary'
            onClick={() => {
              if (!selectedAllInClass) {
                selectAll();
                setSelectedAllInClass(true);
              } else {
                setSelectedLearners([]);
                setClearedSelections(true);
                setSelectedAllInClass(false);
              }
            }}
          >
            {!selectedAllInClass ? "Select all" : "Deselect all"}
          </button>
        </div>
      </div>{" "}
      <div
        className={`create_assignment_table_container visible-scrollbar ${
          !selectedClass || learners.length === 0 ? "no_content" : "has_content"
        }`}
      >
        <div className='choose_learner_table'>
          <div className='choose_learner_table_header'>
            <p></p>
            <p>Learner Name</p>
            <p>Username</p>
            <div className='lessons_completed_learner_data'>
              <p style={{textAlign: "center"}}>
                Lessons completed from lesson selections
              </p>

              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  padding: "0px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setPopupInfo({
                    header: `Lessons completed from lesson selections`,
                    text: `This field indicates how many lessons the learner 
                    has already completed from the ones you selected on the 
                    previous page.`,
                  });
                  setShowPopupInfo(true);
                }}
              >
                <FAW
                  icon='info-circle'
                  className='info-circle'
                  style={{margin: "0px"}}
                />
              </button>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
              <p style={{textAlign: "center"}}>Grade</p>
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  padding: "0px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setPopupInfo({
                    header: `Grade`,
                    text: `This field indicates which grade the learner is in.
                    If you want to change this setting, you can change it by
                    finding the learner on the Classes page and clicking the
                    "Edit/Delete Learner" button.`,
                  });
                  setShowPopupInfo(true);
                }}
              >
                <FAW
                  icon='info-circle'
                  className='info-circle'
                  style={{margin: "0px"}}
                />
              </button>
            </div>
            <p style={{textAlign: "center"}}>Selected</p>
          </div>
          <div className='choose_learner_table_rows_container visible-scrollbar'>
            {loading ? (
              <div className='no_lessons_to_select'>
                <FA icon='spinner' spin /> Loading...
              </div>
            ) : learners[selectedClass] &&
              learners[selectedClass].length !== 0 ? (
              mapLearnersByClass()
            ) : !selectedClass ? (
              <div className='no_lessons_to_select'>
                Select a class to begin.
              </div>
            ) : (
              <div className='no_lessons_to_select'>No learners available.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI)(CreateAssignmentStepTwo);
