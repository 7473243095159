import {
  SET_EXPIRED_AND_EXPIRING_ROBOTS,
  SET_HAS_RENEWALS_WITHOUT_ROBOTS,
} from "../actionTypes";

const initialState = {
  expiredRobots: null,
  expiringRobots: null,
  hasRenewalsWithoutRobots: false,
};

const status = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPIRED_AND_EXPIRING_ROBOTS:
      return Object.assign({}, state, {
        expiredRobots: action.payload.expiredRobots,
        expiringRobots: action.payload.expiringRobots,
      });
    case SET_HAS_RENEWALS_WITHOUT_ROBOTS:
      return Object.assign({}, state, {
        hasRenewalsWithoutRobots: action.payload,
      });
    default:
      return state;
  }
};

export default status;
