import React, { useEffect, useState } from "react";
import Select from "react-select";
import { sortedArray } from "../../../common/utils";
import FAW from "../../../containers/faw";
import { getGradeLevel } from "../CreateAssignment/CreateAssignmentFlowUtilities";

const ScoresByLearnerTable = ({
  getInfoText,
  assignment,
  currentLearner,
  currentLesson,
  setCurrentLearner,
  findScore,
  setLessonForPopupInfo,
  setShowPopupInfo,
  scrollStyles,
}) => {
  const [average, setAverage] = useState("-");
  const [lessonsCompleted, setLessonsCompleted] = useState(null);

  /**
   * getAverage sets the average for the current learner's assigned lesson
   * scores.
   * @returns none
   */
  const getAverage = () => {
    if (!assignment || !assignment.performances || currentLearner === null)
      return;
    let scores = assignment.performances;
    if (scores.length === 0) return "-";
    let scoresSum = "-";
    let count = 0;
    for (let i = 0; i < assignment.lessons.length; i++) {
      let score = findScore(
        assignment.lessons[i].lesson_id,
        assignment.learners[currentLearner].id,
        assignment,
        assignment.lessons[i].is_external
      );
      if (score && score !== "-") {
        count++;
        if (scoresSum === "-") {
          scoresSum = score;
        } else {
          scoresSum = scoresSum + score;
        }
      }
    }

    setAverage(
      scoresSum === "-" ? scoresSum : Math.round((scoresSum / count) * 10) / 10
    );
  };

  /**
   * getNumberOfLessonsCompleted counts how many scores the learner has for the
   * assigned lessons.
   * @returns none
   */
  const getNumberOfLessonsCompleted = () => {
    if (currentLearner === null || !assignment) return;
    let learner = assignment.learners[currentLearner];
    let listOfLearnerScores = assignment.performances.filter(
      (value) => value.learner_id === learner.id
    );
    setLessonsCompleted(listOfLearnerScores.length);
  };

  /**
   * buildLearnerDropdownOptions creates the dropdown list for the learner
   * dropdown input.
   * @returns {Object} The displayed label in the dropdown option, plus the
   * value.
   */
  const buildLearnerDropdownOptions = () => {
    return assignment.learners.map((value, index) => {
      // console.log('value', value);
      return {
        value: index,
        label: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {value.last_name}, {value.first_name}
            </div>
            <div style={{ color: "green" }}>{value.done ? "Done" : ""}</div>
          </div>
        ),
      };
    });
  };

  useEffect(() => {
    getAverage();
    getNumberOfLessonsCompleted();
  }, [currentLearner]);

  /**
   * buildRow creates a row in the table for a score.
   * @param {Object} lesson
   * @param {int} index
   * @returns {html} the row in the table, containing the lesson standard and
   * score value.
   */
  const buildRow = (lesson, index) => {
    let score = findScore(
      lesson.lesson_id,
      assignment.learners[currentLearner].id,
      assignment,
      lesson.is_external
    );
    return (
      <div
        key={index}
        className='learners_lessons_list_table_row'
        style={{ backgroundColor: index % 2 === 0 ? "#F2F2F2" : "white" }}
      >
        {/* TODO: create a component for this text and info button */}
        <div
          style={{
            borderRight: "1px solid #cbcbcb",
            paddingRight: "10px",
          }}
        >
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            {getInfoText(lesson)} - {lesson.type}
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                padding: "0px",
                marginLeft: "5px",
              }}
              onClick={() => {
                setLessonForPopupInfo(lesson);
                setShowPopupInfo(true);
              }}
            >
              <FAW icon='info-circle' className='info-circle' />
            </button>
          </div>
        </div>
        <div>
          <p
            className={`data ${
              score <= 50
                ? "red"
                : score <= 75
                ? "yellow"
                : score <= 100
                ? "green"
                : ""
            }`}
          >
            {`${score !== "-" ? score + "%" : score}`}
          </p>
        </div>
      </div>
    );
  };

  /**
   * buildTableRows sorts scores by standard and populates the table.
   * @returns {Array} array of rows for the table, sorted by standard.
   */
  const buildTableRows = () => {
    let rows = assignment.lessons.map((lesson, index) => {
      return buildRow(lesson, index);
    });
    return sortedArray(rows, "standard_code", true, false);
  };

  return (
    <div
      className={`expanded_assignment_graybox learner_graybox ${
        currentLesson === null && currentLearner === null
          ? "maxHeight-graybox-empty"
          : "maxHeight-graybox-filled"
      }`}
    >
      <p style={{ textAlign: "center" }}>
        <b>Scores by learner</b>
      </p>

      <div style={{ margin: "5px" }}>
        <Select
          id='assignment_select_lesson_dropdown'
          styles={scrollStyles}
          isSearchable={false}
          onChange={(selected) => {
            setCurrentLearner(selected.value);
          }}
          defaultValue={{ value: "None", label: "None" }}
          placeholder='Select a filter'
          options={buildLearnerDropdownOptions()}
        />

        {currentLearner !== null ? (
          <div className='info_table'>
            <p>
              <b>Username: </b>
              {assignment.learners[currentLearner].username}
            </p>
            <p>
              <b>Grade: </b>
              {getGradeLevel(assignment.learners[currentLearner])}
            </p>

            <p>
              <b>Lessons Completed in Assignment: </b>
              {lessonsCompleted}/{assignment.lessons.length}
            </p>
          </div>
        ) : (
          <p style={{ margin: "10px", textAlign: "center" }}>
            Choose a learner from the dropdown to begin.
          </p>
        )}
      </div>

      {currentLearner !== null && (
        <div className='learners_lessons_list_table_container'>
          <div className='learners_lessons_list_table_hr'>
            <p
              className='header_label'
              style={{ borderRight: "1px solid #cbcbcb" }}
            >
              <b>Lessons</b>
            </p>
            <div style={{ textAlign: "center" }}>
              <p>
                <b>Score</b>
              </p>
            </div>
          </div>

          <div className='learners_lessons_list_table_rows_container visible-scrollbar'>
            {buildTableRows()}
          </div>

          <div
            className='learners_lessons_list_table_ft'
            style={{
              padding:
                assignment.lessons.length >= 8 ? "0px 18px 0px 10px" : "",
            }}
          >
            <p
              className='footer_label'
              style={{ borderRight: "1px solid #cbcbcb" }}
            >
              <b>Average</b>
            </p>
            <p
              className={`data ${
                average <= 50
                  ? "red"
                  : average <= 75
                  ? "yellow"
                  : average <= 100
                  ? "green"
                  : ""
              }`}
            >
              {average === "-" ? average : `${average}%`}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScoresByLearnerTable;
