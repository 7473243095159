import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import React, {useState} from "react";
import InfoPopup from "../../../components/UtilComps/InfoPopup";
import FA from "../../../containers/fa.js";

const CheckboxFilter = ({
  disabled = false,
  checkBoxHoverText,
  popupHeaderText,
  popupBodyText,
  popupBtnDangerouslySetHtmlText,
  filterLabel,
  checked,
  setChecked,
}) => {
  const [popupInfo, setPopupInfo] = useState({
    header: "",
    text: "",
  });
  const [showPopupInfo, setShowPopupInfo] = useState(false);

  const handleInfoButtonClick = () => {
    setPopupInfo({
      header: popupHeaderText,
      text: popupBodyText,
      dangerouslySetHtml: popupBtnDangerouslySetHtmlText,
    });
    setShowPopupInfo(true);
  };

  return (
    <>
      {showPopupInfo && (
        <InfoPopup popupInfo={popupInfo} setShowPopupInfo={setShowPopupInfo} />
      )}
      <FormControl
        style={{alignItems: "center"}}
        className='left-data-title'
        assignment-data-title={checkBoxHoverText}
      >
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={checked}
              disabled={disabled}
              onChange={() => setChecked(!checked)}
            />
          }
          label={
            <span style={{fontSize: "14px"}}>
              {filterLabel}
              {popupHeaderText && popupBodyText && (
                <button
                  className='info-circle-btn'
                  onClick={handleInfoButtonClick}
                >
                  <FA icon='info-circle' className='info-circle' />
                </button>
              )}
            </span>
          }
          labelPlacement='end'
          style={{margin: "0px"}}
        />
      </FormControl>
    </>
  );
};

CheckboxFilter.propTypes = {
  checkBoxHoverText: PropTypes.string,
  popupHeaderText: PropTypes.string,
  popupBodyText: PropTypes.string,
  filterLabel: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
};

export default CheckboxFilter;
