import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { authService } from '../services/auth';
import { withRouter, useLocation } from 'react-router-dom';
import withAPI from '../services/api';

import {
  register,
  studentCap as studentCapA,
  } from '../redux/actions';
import config from '../common/config';

import FA from '../containers/fa';
import FAW from '../containers/faw';

import './RegistrationForm.scss';

const RegistrationForm = ({api, history, dispatch, robotType}) => {

  const [isTeacher, setTeacher] = useState(true);

  const [orgCode, setOrgCode] = useState('');
  const [orgCodeTouched, setOrgCodeTouched] = useState(false);
  const [validOrgCode, setValidOrgCode] = useState(null);
  const [orgType, setOrgType] = useState(null);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pass, setPass] = useState('');
  const [pass_conf, setPassConf] = useState('');

  const [error, setError] = useState(null);
  const [spin, setSpin] = useState(false);
  const [errors, setErrors] = useState({'orgCode':'',
                                        'email':'',
                                        'username':'',
                                        'pass':'',
                                        'pass_conf':''})

  const [isLoading, setIsLoading] = useState(true);

  // read org code from get Param
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  console.log("orgCode:",query.get("orgCode"))

  const handleSubmit = (e) => {
    e.preventDefault();
    if ( errors.orgCode.length > 0 ) {
      setSpin(false);
      setError(errors.orgCode)
    } else if ( errors.email.length > 0 ) {
      setSpin(false);
      setError(errors.email)
    } else if ( errors.username.length > 0 ) {
      setSpin(false);
      setError(errors.username)
    } else if ( errors.pass.length > 0 ) {
      setSpin(false);
      setError(errors.pass)
    } else if ( errors.pass_conf.length > 0 ) {
      setSpin(false);
      setError(errors.pass_conf)
    } else if (
      ( orgCode === '' || email === '' || username === ''
        || pass === '' || firstName == '' || lastName == '') )
      {
        setSpin(false);
        setError("All fields are required.")
      }
    else {
      setSpin(true);
      authService.register({ email, username, pass, orgCode, firstName, lastName })
      .then(([user, token, organization, hasRobots, hasAccounts, localBotsOnly, forcePWReset, orgStatus]) => {
        setSpin(false);
        // dispatch things
        dispatch(register(email, username, pass, hasRobots, hasAccounts, localBotsOnly, forcePWReset, orgStatus));
        setUsername('');
        setPass('');
        setError(null);
        // Redirect to proper dashboard
        // only school org admin & home orgadmin/teacher?
        if (user.organization.category === config.ABII_HOME.toLowerCase()) {
          console.log("Redirecting to Parent Dashboard")
          history.push('/home/dashboard');
        } else if (user.usertype === config.ORGADMIN_USERTYPE && user.organization.category === config.ABII_SCHOOL.toLowerCase()){
          history.push('/admin/dashboard');
        } else {
          // Whelp.
        }
      }).catch((e) => {
        setSpin(false);
        // Rough way to parse ValidationError message
        let err = e.message.split(':')
        let arr = err[err.length-1].split(',')
        if (arr.length > 0) {
          setError(
            <React.Fragment>
              {arr.map((val) => (
                <React.Fragment>{val}<br/></React.Fragment>
              ))}
            </React.Fragment>
          );
        } else {
          setError(e.message);
          console.log("error:",e);
        }
      })
    }
  }


  // Not sure if we want to allow manual orgocde edits
  useEffect(() => {
    setIsLoading(true);
    if (!orgCode && orgCodeTouched === false) {
      setOrgCode(query.get("orgCode") || '')
    }
    if (orgCode.length === 12) {
      api.queryOrgCode(orgCode).then((resp) => {
       console.log("Valid OrgCode");
        if (resp.orgType === 'school') {
          /* Make shallow copy of errors, replacing orgCode element with '' */
          setValidOrgCode(true);
        } else if (resp.orgType === 'home') {
          setValidOrgCode(true);
        }
        setIsLoading(false);
      }).catch((e) => {
        console.log("Invalid OrgCode");
        setValidOrgCode(false);
        setIsLoading(false);
      });
    } else {
      setValidOrgCode(false);
      setIsLoading(false);
    }
  }, [orgCode])

  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  const containsUppercaseRegex = new RegExp("^(?=.*[A-Z])");
  const containsLowercaseRegex = new RegExp("^(?=.*[a-z])");
  const containsNumberRegex = new RegExp("^(?=.*\\d)");

  useEffect(() => {
    let emailErr = validEmailRegex.test(email) ? '' : 'Include a valid email address';
    setErrors({...errors, email: emailErr});
  }, [email])

  useEffect(() => {
    if (pass.length > 0 && pass.length < 8) {
      var pw1Error = 'Password must be at least 8 characters long';
    } else if (pass.length && !containsLowercaseRegex.test(pass)) {
      var pw1Error = 'Password must contain a lowercase letter';
    } else if (pass.length && !containsUppercaseRegex.test(pass)) {
      var pw1Error = 'Password must contain an uppercase letter';
    } else if (pass.length && !containsNumberRegex.test(pass)) {
      var pw1Error = 'Password must contain a number 0-9';
    } else {
      var pw1Error = '';
    }
    var pw2Error = pass === pass_conf ? '' : 'Password fields must match'
    setErrors({...errors, pass: pw1Error, pass_conf: pw2Error})
  }, [pass, pass_conf])


  return (
    <form autoComplete="off">
      <div className="form-content form-group container">
        <div className="row">
          {error &&
            <div className="alert alert-danger w-100" role="alert">{error}</div>
          }
        </div>

        {isLoading 
        ? <div><h4><FA color="gray" icon="spinner" spin /> Loading Activation Form...</h4></div>
        : <React.Fragment>
            {validOrgCode
            ? <div>
                <div className="row flex-column">
                  <div className="form_icons mx-auto">
                    <label><FA icon="id-card" /></label>
                    <input
                      required
                      className="form-control input-underline input-lg"
                      placeholder="First Name"
                      type="text"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }} />
                  </div>
                </div>

                <div className="row flex-column">
                  <div className="form_icons mx-auto">
                    <label><FA icon="id-card" /></label>
                    <input
                      required
                      className="form-control input-underline input-lg"
                      placeholder="Last Name"
                      type="text"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }} />
                  </div>
                </div>

                <div className="row flex-column">
                  <div className="form_icons mx-auto">
                    <label><FA icon="envelope-open" /></label>
                    <input
                      required
                      className="form-control input-underline input-lg"
                      placeholder="Email Address"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setUsername(e.target.value);
                      }} />
                  </div>
                  {errors.email.length > 0 &&
                    <span className='error'>{errors.email}</span>
                  }
                </div>
                <div className="row flex-column">
                  <div className="form_icons mx-auto">
                    <label><FA icon="lock" /></label>
                    <input required className="form-control input-underline input-lg"
                           placeholder="Password" type="password" value={pass} onChange={(e) => setPass(e.target.value)}/>
                  </div>
                  {errors.pass.length > 0 &&
                    <span className='error'>{errors.pass}</span>
                  }
                </div>
                <div className="row flex-column">
                  <div className="form_icons mx-auto">
                    <label><FA icon="lock" /></label>
                    <input required className="form-control input-underline input-lg"
                           placeholder="Confirm Password" type="password" value={pass_conf} onChange={(e) => setPassConf(e.target.value)}/>
                  </div>
                  {errors.pass_conf.length > 0 &&
                    <span className='error'>{errors.pass_conf}</span>
                  }
                </div>
                <div className="row d-flex justify-content-between align-items-center">
                  <button type="submit" onClick={handleSubmit} className="btn btn-lg fs-14 btn-primary col-md-5 mx-auto  mt-2">
                    {spin
                      ? <FAW icon="spinner" spin />
                      : <span ngif="!icon_show" className="mx-auto">Register</span>
                    }
                  </button>
                </div>
              </div>
            : <div className='error alert alert-danger'>Invalid Url</div>
            }
          </React.Fragment>
        }

      </div>
    </form>
  );
}

export default compose(
  withAPI,
  withRouter,
  //connect(mapStateToProps)
)(RegistrationForm);
