import React, { useState } from 'react';
import FAW from '../../containers/faw';
import FA from '../../containers/fa';



const OrgSnapshotRows = ({ snapshot }) => {

  return (
    <React.Fragment>
      <tr className='h5 font-weight-bold' style={{borderTop: '#868e96 3px solid'}}>
        <td>
          Snapshot
          <span
            style={{fontSize: '80%'}}
            data-title="Snapshot items (except Robots) are only accurate for Cloud Accounts">
            <FAW icon="info-circle" className="info-circle" />
          </span>
        </td>
        <td>Total</td>
        <td>Activated / Delivered</td>
      </tr>
      <tr>
        <td>Robots</td>
        <td>{snapshot.robots.total}</td>
        <td>{snapshot.robots.activated}</td>
      </tr>
      <tr>
        <td>LCT</td>
        <td>{snapshot.lct.total}</td>
        <td>{snapshot.lct.assigned}</td>
      </tr>
      <tr>
        <td>PDs</td>
        <td>{snapshot.pds.total}</td>
        <td>{snapshot.pds.delivered}</td>
      </tr>
      <tr>
        <td>Teacher Accounts</td>
        <td>{snapshot.teachers.total}</td>
        <td> - </td>
      </tr>
      <tr style={{borderBottom: '#868e96 3px solid'}}>
        <td>Enrolled Learners</td>
        <td>{snapshot.enrolled_learners.total || '-'}</td>
        <td>{snapshot.enrolled_learners.enrolled || '-'}</td>
      </tr>
    </React.Fragment>
  )
}

export default OrgSnapshotRows;
