import React, { useEffect } from 'react';
import Gate from '../partials/Gate';
import withAPI from '../services/api';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = (state, ownProps?) => ({
  token: state.auth.token,
})


const LoggedOut = ({ api, token }) => {

  return (
    <Gate>
      <div className="logged-out-box">
        <h3>
          You have been logged out due to inactivity.
        </h3>
	<Link to="/login">Login</Link>
      </div>
    </Gate>
  )
}

export default compose(
  connect(mapStateToProps),
  withAPI,
)(LoggedOut);
