import MomentUtils from "@date-io/moment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FA from "../../containers/fa";
import { saleModalHeader as saleModalHeaderA } from "../../redux/actions";
import withAPI from "../../services/api";
import InfoModal from "../AppModals/InfoModal";
import PaginationBar from "../UtilComps/PaginationBar.js";
import "./OrgSalesTable.scss";
import SalesInfoRow from "./Sales/SalesInfoRow";

const mapStateToProps = (state, ownProps?) => ({
  refreshSaleDetail: state.superAdmin.refreshSaleDetail,
});

const OrgSalesTable = ({
  api,
  dispatch,
  orgId,
  orgType,
  orgLevel,
  taxRate,
  refreshSaleDetail,
  allSale,
  canCollapse,
}) => {
  const [ready, setReady] = useState(false);
  const [sales, setSales] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const [saleSummaryStats, setSaleSummaryStats] = useState(null);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState("");
  const [currentStatusMatch, setCurrentStatusMatch] = useState(false);
  const [filterOrgName, setFilterOrgName] = useState("");
  const [filterState, setFilterState] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [infoText, setInfoText] = useState(null);

  useEffect(() => {
    // TODO: confirm list is refreshed after new sale creation / status change / ..
    refreshSalesList(null, true);
  }, [refreshSaleDetail]);

  useEffect(() => {
    if (pageSize) {
      // no need to redo calcs just for new page size
      refreshSalesList(null);
    }
  }, [pageSize]);

  const buildFilterQueryParams = () => {
    const params = {
      status: filterStatus,
      from_date: startDate?.toISOString(),
      to_date: endDate?.toISOString(),
      by_current_status: currentStatusMatch,
      org_name: filterOrgName,
      state: filterState,
      category: filterCategory,
      page_size: pageSize,
    };
    return params;
  };

  const refreshSalesList = (pageNum, forceCalcStats = false) => {
    // If orgId is null, on AllSales page
    console.log(orgId);
    const calcStats =
      !orgId && (forceCalcStats || (saleSummaryStats === null ? true : false));

    let queryParams = buildFilterQueryParams();
    queryParams.calc_stats = calcStats;
    queryParams.page = pageNum;
    queryParams.orgId = orgId;

    // skip nulls,  empty strings, false values
    queryParams = Object.entries(queryParams).filter(([k, v]) => v);

    console.log("Asking for (simple) Sales in org:", queryParams);
    setReady(false);
    api.fetchSaleList(queryParams).then((res) => {
      setSales(res.results);
      delete res["results"];
      if (calcStats) {
        setSaleSummaryStats(res.stats);
      }
      delete res["stats"];
      setPaginationInfo(res);
      setReady(true);
    });
  };

  const resetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterStatus("");
    setFilterOrgName("");
    setFilterState("");
    setFilterCategory("");
    setCurrentStatusMatch(false);
  };

  const handleExportSales = () => {
    // send the list of filtered sale IDs to backend and get a CSV back
    let data = buildFilterQueryParams();
    data.export_type = "sales_list";
    api.exportSalesFile(data);
  };

  return (
    <React.Fragment>
      <InfoModal infoText={infoText} setInfoText={setInfoText} />
      <div className='common_border'>
        <div
          className='common_heading d-flex justify-content-between align-items-center'
          style={{ cursor: "pointer" }}
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <span>
            <h4>
              {canCollapse && (
                <span className='mr-2'>
                  {collapse ? (
                    <FA color='gray' icon='chevron-right' />
                  ) : (
                    <FA color='gray' icon='chevron-down' />
                  )}
                </span>
              )}
              Sales
            </h4>
          </span>
        </div>

        {!collapse && (
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <div style={{ marginBottom: "5px" }}>
                  {orgType && orgId ? (
                    <div>
                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          dispatch(
                            saleModalHeaderA({
                              id: "new",
                              orgInfo: {
                                orgType: orgType,
                                orgId: orgId,
                                taxRate: taxRate,
                                orgLevel: orgLevel,
                              },
                            })
                          );
                        }}
                      >
                        Initiate New Sale
                      </button>
                    </div>
                  ) : (
                    <button
                      className='btn btn-primary mb-1'
                      onClick={() => {
                        handleExportSales();
                      }}
                    >
                      Export Sales
                    </button>
                  )}
                  {allSale && (
                    <div>
                      <div className='table-filter-wrapper p-3'>
                        <h5>Filters:</h5>
                        <div
                          style={{ display: "flex", alignItems: "flex-end" }}
                        >
                          <span className='mr-4'>From</span>
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            className='px-3'
                          >
                            <DatePicker
                              value={startDate}
                              onChange={setStartDate}
                              placeholder='Start date'
                            />
                          </MuiPickersUtilsProvider>

                          <span className='mx-4'>To</span>
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            className='px-3'
                          >
                            <DatePicker
                              value={endDate}
                              onChange={setEndDate}
                              placeholder='End date'
                            />
                          </MuiPickersUtilsProvider>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "stretch",
                            flexFlow: "wrap",
                          }}
                        >
                          <TextField
                            className='mr-4'
                            margin='normal'
                            placeholder='Organization/School'
                            size='medium'
                            value={filterOrgName}
                            onChange={(e) => {
                              setFilterOrgName(e.target.value);
                            }}
                          />

                          <TextField
                            className='mr-4'
                            margin='normal'
                            placeholder='State'
                            size='medium'
                            value={filterState}
                            onChange={(e) => {
                              setFilterState(e.target.value);
                            }}
                          />

                          <FormControl style={{ minWidth: "240px" }}>
                            <InputLabel id='demo-simple-select-label'>
                              Category:
                            </InputLabel>
                            <Select
                              className='mr-4'
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              value={filterCategory || ""}
                              onChange={(e) => {
                                setFilterCategory(e.target.value);
                              }}
                            >
                              <MenuItem value={""}>All</MenuItem>
                              <MenuItem value={"home"}>Home</MenuItem>
                              <MenuItem value={"school"}>School</MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl style={{ minWidth: "240px" }}>
                            <InputLabel id='demo-simple-select-label'>
                              Status:
                            </InputLabel>
                            <Select
                              className='mr-4'
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              value={filterStatus || ""}
                              onChange={(e) => {
                                setFilterStatus(e.target.value);
                              }}
                            >
                              <MenuItem value={""}>All</MenuItem>
                              <MenuItem value={"quote_sent"}>
                                Quote Sent
                              </MenuItem>
                              <MenuItem value={"po_received"}>
                                PO Received
                              </MenuItem>
                              <MenuItem value={"robots_shipped"}>
                                Robots Shipped
                              </MenuItem>
                              <MenuItem value={"invoice_sent"}>
                                Invoice Sent
                              </MenuItem>
                              <MenuItem value={"invoice_paid"}>
                                Invoice Paid
                              </MenuItem>
                              <MenuItem value={"has_returns"}>
                                Has Returns
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <div style={{ display: "inline-flex" }}>
                            <div
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                color='primary'
                                checked={currentStatusMatch}
                                onChange={(e) => {
                                  setCurrentStatusMatch(!currentStatusMatch);
                                }}
                              />
                              <p style={{ margin: "0px 10px 0px 0px" }}>
                                Current status
                              </p>
                            </div>

                            <FA
                              icon='info-circle'
                              style={{ alignSelf: "center" }}
                              className='info-circle'
                              onClick={() => {
                                setInfoText("exactFilterMatch");
                              }}
                              onBlur={() => {
                                setInfoText(null);
                              }}
                            />
                          </div>
                        </div>
                        <button
                          className='btn btn-primary btn-sm mt-3 mr-2'
                          onClick={() => {
                            refreshSalesList(null, true);
                          }}
                        >
                          Update Search
                        </button>

                        <button
                          className='btn btn-primary btn-sm mt-3'
                          onClick={() => {
                            resetFilters();
                          }}
                        >
                          Clear Filters
                        </button>
                      </div>

                      {paginationInfo && saleSummaryStats && (
                        <div className='my-3 justify-content-between d-flex px-3'>
                          <h5>Total Transactions: {paginationInfo.count}</h5>
                          <h5>
                            Total Robots Sold: {saleSummaryStats.total_robots}
                          </h5>
                          <h5>Total Sale: ${saleSummaryStats.total_sales}</h5>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <PaginationBar
                  paginationInfo={paginationInfo}
                  fetchPageFunc={refreshSalesList}
                  loading={!ready}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                />

                <div
                  id={
                    allSale
                      ? "all_sales_table_container"
                      : "org_sales_table_container"
                  }
                  className='card visible-scrollbar'
                >
                  <div
                    id={
                      allSale
                        ? "all_sales_table_hr_row"
                        : "org_sales_table_hr_row"
                    }
                    className={
                      allSale ? "all_sales_table_row" : "org_sales_table_row"
                    }
                  >
                    <div className='datatable-header-cell'>Sale Id</div>
                    <div className='datatable-header-cell'>Last Updated</div>
                    <div>Status</div>
                    {allSale ? <div>Organization</div> : <div>School/Buyer</div>}
                    <div>Robots</div>
                    <div>PDs</div>
                    {allSale ? <div>Subscriptions</div> : <div>LCTs</div>}
                    {allSale && <div>Total</div>}
                    {allSale && <div>PDs</div>}
                    {allSale && <div>IT Dept. Setup</div>}
                    {allSale && <div>State</div>}
                    <div>Actions</div>
                  </div>

                  {sales.length > 0 &&
                    sales.map((saleObj, index) => (
                      <SalesInfoRow
                        allSale={allSale}
                        parentPageOrgId={orgId}
                        key={index}
                        index={index}
                        saleObj={saleObj}
                        refreshSalesList={refreshSalesList}
                      />
                    ))}
                  {ready && sales.length === 0 && (
                    <SalesInfoRow nullRow={true} allSale={allSale} />
                  )}
                  {!ready && sales.length === 0 && (
                    <div>
                      <FA color='gray' icon='spinner' spin /> Loading Sales...
                    </div>
                  )}
                </div>

                <PaginationBar
                  paginationInfo={paginationInfo}
                  fetchPageFunc={refreshSalesList}
                  loading={!ready}
                  pageSize={pageSize}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default compose(connect(mapStateToProps), withAPI)(OrgSalesTable);
