import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter, Link} from "react-router-dom";
import {
  login,
  showPasswordReset as showPasswordResetAction,
  forcePasswordReset as forcePasswordResetAction,
} from "../redux/actions";

import Gate from "../partials/Gate";
import withAPI from "../services/api";
import {authService} from "../services/auth";

import FA from "../containers/fa";
import './Login.scss';

const ForgotUsername = ({api, dispatch, history}) => {
  const [email, setEmail] = useState("");
  const [foundUsername, setFoundUsername] = useState(false);
  const [error, setError] = useState(null);
  const [spin, setSpin] = useState(false);

  const [emailSpin, setEmailSpin] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailMsg, setEmailMsg] = useState("");

  //const [question, setQuestion] = useState("");
  //const [answer, setAnswer] = useState("");

  //const handleAnswerSubmit = () => {
  //  api.answerQuestionForUser(username, answer).then((data) => {
  //    setSpin(false);
  //    setError(null);
  //    const promise = new Promise((res, rej) => {
  //      return res(data);
  //    });
  //    authService.handleLogin(promise).then(([user, token]) => {
  //      dispatch(login(user, token));
  //      history.push('/password-reset');
  //    });

  //  }).catch((err) => {
  //    setError(err.message);
  //    setSpin(false);
  //  });

  //}

  //const handleSubmit = (e) => {
  //  e.preventDefault();
  //  setSpin(true);
  //  setError(null);

  //  if (question && answer) { handleAnswerSubmit(); }

  //  api.fetchQuestionForUser(username).then((data) => {
  //    setSpin(false);
  //    setFoundUsername(true);
  //    if (data.question != null) {
  //      data.choices.map((x) => {
  //        if (x[0] == data.question) {
  //          setQuestion(x[1]);
  //        }
  //      });
  //    } else {
  //      setError("The security question for that user is not set up.");
  //    }

  //  }).catch((error) => {
  //    setSpin(false);
  //    setError("" + error);
  //  });

  //  return false;
  //}

  const handleSendEmail = (e) => {
    e.preventDefault();
    setEmailSpin(true);
    setEmailSent(false);
    setError(null);

    api
      .requestForgotUsernameEmail(email)
      .then((data) => {
        setEmailSpin(false);
        setEmailSent(true);
        setEmailMsg(data.message);
      })
      .catch((error) => {
        setEmailSpin(false);
        setError("" + error.message);
      });
  };

  return (
    <Gate isLogin={true}>
      <div id='login_whitebox' className='login_box visible-scrollbar'>
      <Link id='login_back_btn' to="/">&laquo; Back</Link>

        <div id='login_hr_contents' className='form-group'>
          <h1>Forgot Username</h1>
          <p>
            Input an email address to send an email with your ABii&apos;s-world
            username.
          </p>
        </div>
        <form noValidate autoComplete='off'>
          <div className='form-content form-group container'>
            <div className='row' style={{marginBottom: '10px'}}>
              <div className='form_icons'>
                <label>
                  <FA icon='user' />
                </label>
                <input
                  required
                  className='form-control input-underline input-lg'
                  placeholder='Email address'
                  type='text'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            {error && (
              <div className='row'>
                <div className='alert alert-danger'>{error}</div>
              </div>
            )}
            {emailSent && (
              <div className='row'>
                <div className='alert alert-success'>{emailMsg}</div>
              </div>
            )}
            <div className='login_flex_column'>
              <button
                type='submit'
                onClick={handleSendEmail}
                className='btn btn-lg fs-14 btn-primary'
              >
                {emailSpin ? (
                  <FA color='white' icon='spinner' spin />
                ) : (
                  <span>Send Email</span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Gate>
  );
};

export default compose(withRouter, connect(), withAPI)(ForgotUsername);
