import React from 'react';
import MainDashboard from '../partials/Main';
import Gate from '../partials/Gate';
const wrapperStyle = {
  backgroundColor: '#fff',
  padding: '16px 35px',
  borderRadius: '5px'
}


const PrivacyPolicy = ({ }) => {
  
  return (
    <Gate boxWidthClass="col-md-8">
      <div className="common_table_padding">
        <div className="row batch-row">
          <div className="progress-linear">
            <div style={wrapperStyle}>
              <div className="row">
                <h1>Privacy Policy</h1>
                <p> In compliance with the <strong>Children’s Online Privacy Protection Act (COPPA)</strong>, Parents (or legal guardians) of children under 13
                 years of age must consent to collections, uses and disclosures of the personal information of their children collected by Van Robotics.
                 Vän Robotics may have collected your online contact information from your child’s teacher,
                 as well as your child’s name, in order to obtain your consent. Your permission is required for the collection, use, or disclosure
                 of your child’s personal information. We will not grant your child access to any ABii lesson tutoring unless you provide
                 us with permission. The Vän Robotics learning system collects information about your child’s name, age, performance and
                 attention during each lesson to personalize how the system teaches each subject.  We collect the minimal data required to support
                 and accelerate learning and we will not disclose or share your child’s information with any other 3rd party.
                 Your consent will be required for your child to use the robot system.</p>

                <p> Note: You may revoke your consent at any time to refuse further collection and use of your child’s information.
                If you ever desire to revoke this consent, please write down your revocation of consent and scan or take a snapshot of your
                revocation and email the signed form to info@myvanrobot.com.  
                </p>

                <p style={{width: '100%'}}>Your child’s safety is important to us!
                </p>

                <h3> TeamVan </h3>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Gate>
  );
}

export default PrivacyPolicy;
