import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../common/config';
import {useTitle} from '../../common/utils';
import withAPI from '../../services/api';
import MainDashboard from '../../partials/Main';
import AllSubscriptionsTable from '../../components/ManageAllSubscriptions/AllSubscriptionsTable';
import OrgSubscriptionsTable from '../../components/ManageAllSubscriptions/OrgSubscriptionsTable';


import FA from '../fa';

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const AllSubscriptions = ({ api, user, match }) => {


  useTitle('Subscriptions');
  let history = useHistory();

  return (
    <div>
      <MainDashboard>
          <div className="common_border">
            {user && (user.usertype === config.SUPERADMIN_USERTYPE) &&
              <AllSubscriptionsTable
              />
            }
            {user && (user.usertype === config.ORGADMIN_USERTYPE) &&
              <OrgSubscriptionsTable
                orgId={user.organization.id}
              />
            }
          </div>

      </MainDashboard>
    </div>
  );


}

export default compose(
  connect(mapStateToProps),
  withAPI
)(AllSubscriptions);
