const ABII_SCHOOL = "SCHOOL";
const ABII_HOME = "HOME";
const ABII_ENVIRONMENT_CHOICES = [ABII_SCHOOL, ABII_HOME];

let wifiApiUrl = process.env.REACT_APP_WIFI_API_URL;
const usingHTTPS = window.location.protocol === "https:";

if (!usingHTTPS) {
  wifiApiUrl = wifiApiUrl.replace("https", "http");
}

export default {
  apiUrl: process.env.REACT_APP_API_URL,
  wsUrl: process.env.REACT_APP_API_SOCKET_URL,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  commBackendBaseUrl: process.env.REACT_APP_COMM_MANAGEMENT_BASE_URL,
  frontendBaseUrl: process.env.REACT_APP_FRONTEND_BASE_URL,
  secureLogin: process.env.REACT_APP_API_SECURE_LOGIN,
  lessonControllerApiUrl: process.env.REACT_APP_LESSON_CONTROLLER_URL,
  wifiApiUrl: wifiApiUrl,
  qsyncerWsUrl: process.env.REACT_APP_QSYNCER_WS_URL,
  monitorAbiiWSUrl: process.env.REACT_APP_ABII_MONITOR_URL,
  staticContentUrl: process.env.REACT_APP_ABII_STATIC_URL,
  isDevelopment: process.env.REACT_APP_DEBUG === "true",
  cleverSSOClientId: process.env.REACT_APP_CLEVER_SSO_CLIENT_ID,
  cleverSecureSyncClientId: process.env.REACT_APP_CLEVER_SECURESYNC_CLIENT_ID,
  lessonViewerUrl:
    process.env.REACT_APP_LESSONVIEWER_URL || "lesson-viewer.abiis-world.com",
  lessonViewerProto: process.env.REACT_APP_LESSONVIEWER_PROTO || "https",
  siteTitle: "ABii Cloud",
  abiiEnvironment: ABII_SCHOOL,
  usingHTTPS: usingHTTPS,
  // Constants:
  ABII_ENVIRONMENT_CHOICES: ABII_ENVIRONMENT_CHOICES,
  ABII_HOME: ABII_HOME,
  ABII_SCHOOL: ABII_SCHOOL,
  ORG_LEVEL_SCHOOL: "school",
  ORG_LEVEL_DISTRICT: "district",
  HTTPS_CHECK_UPSTREAM: wifiApiUrl + "/ssl-status/",
  HTTPS_CHECK_SYSTEMREADY: wifiApiUrl + "/system-ready-check/",
  TEACHER_USERTYPE: "teacher",
  LEARNER_USERTYPE: "learner",
  ORGADMIN_USERTYPE: "orgadmin",
  ORGUNITADMIN_USERTYPE: "orgunitadmin",
  SUPERADMIN_USERTYPE: "superadmin",
  LESSONVIEWER_WINDOW_NAME: "lessonViewer",
  LESSON_SOURCE_DISTRIBUTED: "distributed",
  LESSON_SOURCE_CUSTOM: "custom",
  LESSON_SOURCE_INTERNAL: "internal",

  USERROLES: [
    {
      id: 1,
      value: "teacher",
      label: "Teacher",
      info: "A teacher will have robots in their classroom and will be able to manage their own class rosters.",
    },
    {
      id: 2,
      value: "orgadmin",
      label: "Organization Admin",
      info: "An organization admin will be able to manage and monitor robots, users, and learners across the entire organization.",
    },
    {
      id: 3,
      value: "orgunitadmin",
      label: "School Admin",
      info: "A school admin will be able to manage and monitor robots, users, and learners within the school(s) to which the school admin is assigned.",
    },
  ],

  ORG_LEVEL_SCHOOL: "school",
  ORG_LEVEL_DISTRICT: "district",

  MSG_TYPE_PUSH: "PUSH",
  MSG_TYPE_PULL: "PULL",
  MSG_TYPE_SYNC_EVENTS: "SYNC_EVENTS",
  SYNC_THRESHOLDS: {
    low: 50,
    mid: 125,
    high: 150,
  },
  MAX_HOME_STUDENTS: 3,
  FORCE_SYNC_BEFORE_DELETE: 5,
  PD_SCHEDULED: "scheduled",
  PD_DELIVERED: "delivered",
  SALE_STATUS_CREATED: "sale_created",
  SEL_COURSE_IDS: [800, 801],
  SUBSCRIPTION_TYPE_DISPLAY_NAMES: {
    lct: "LCT",
    premiumcare: "ABii Premium Care",
    abii_content_package: "ABii Content Package",
  },
  LCT_SUB_TYPE: "lct",
  PREMIUMCARE_SUB_TYPE: "premiumcare",
  ABII_CONTENT_PACKAGE_SUB_TYPE: "abii_content_package",
  ACCOUNT_SUBSCRIPTION_FILTERS: ["lct"],
  SYNC_CHECK_FREQUENCY: 5,
  SUPERADMIN_AS_ORGADMIN_POSTFIX: "tech.support@myvanrobot.com",
  TUT_PREASM_Q_IND: 2,
  STANDARD_MASTERY_COLORS: {
    mastered_outright: "rgba(171, 199, 255, 1)",
    mastered_eventually: "rgba(159, 227, 175, 1)",
    not_mastered: "rgba(252, 228, 149, 1)",
  },
};
