import React, {useState, useEffect, useRef} from "react";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import ProgressBar from "../ProgressBar";
import "./BulkAddStudentsModal.scss";
import config from "../../common/config";

const BulkAddStudentsModal = ({
  api,
  setShowModal,
  setRefreshForNewLearners,
  resetComponent,
}) => {
  const [file, setFile] = useState(null);
  const [pending, setPending] = useState(false);
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(null);
  const [locked, setLocked] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const [helpPage, setHelpPage] = useState(null);
  const [newLearners, setNewLearners] = useState(null);
  const [existingLearners, setExistingLearners] = useState(null);
  const [percentValidated, setPercentValidated] = useState(0);
  const [percentConfirmed, setPercentConfirmed] = useState(0);

  const scrollRef = useRef(null);
  const resetScroll = () => {
    scrollRef.current.scrollTo({top: 0, left: 0, behavior: "smooth"});
  };

  const closeComponent = () => {
    setShowModal(false);
  };

  const changeHandler = (event) => {
    setFile(event.target.files[0]);
  };

  const onSubmit = () => {
    setPercentValidated(0);
    setPercentConfirmed(0);
    if (file) {
      const formData = new FormData();
      console.log("file is", file);
      if (!file.name.endsWith(".csv")) {
        setFail(
          "Your file must be in CSV format. \
          Please convert your spreadsheet to a CSV to continue"
        );
        setPending(false);
        return;
      }
      formData.append("file", file);
      setPending(true);
      if (validated) {
        formData.set("confirm", true);
        api
          .createBulkLearners(formData)
          .then((response) => {
            console.log("submitted!", response);
          })
          .catch((error) => {
            console.log("fail!", error);
            setFail("Something went wrong. This is probably a network error");
            setPending(false);
          });
      } else {
        api
          .createBulkLearners(formData)
          .then((response) => {
            console.log("submitted!");
          })
          .catch((error) => {
            console.log("fail!", error);
            setFail("Something went wrong. This is probably a network error");
            setPending(false);
          });
      }
    }
  };

  useEffect(() => {
    const ws = new WebSocket(`${config.wsUrl}/?token=${api.getToken()}`);

    ws.onopen = (msg) => {
      console.log("connecting to socket", msg);
    };

    ws.onmessage = (msg) => {
      const data = JSON.parse(msg.data);
      console.log("Received message from socket:", data);
      if (data.event_type) {
        const evt = data.event_type;
        if (evt === "validation_success") {
          try {
            setNewLearners(data.content.msg.new_learners);
            setExistingLearners(data.content.msg.existing_learners);
            setValidated(true);
            setPending(false);
            // setPercentValidated(0);
            // setPercentConfirmed(0);
          } catch (e) {
            console.log("error validating:", e);
          }
        } else if (evt === "creation_success") {
          // don't forget to update main list later!!
          setRefreshForNewLearners();
          setSuccess(true);
        } else if (evt === "validation_progress") {
          setPercentValidated(data.content);
        } else if (evt === "progress_update") {
          setPercentValidated(100);
          setValidated(true);
          setPercentConfirmed(data.content);
        } else if (evt === "celery_status") {
          if (data.content === "celery_red") {
            setLocked(true);
          } else if (data.content === "task_in_progress") {
            setPending(true);
          }
          // status OK, proceed normally
          setCheckingStatus(false);
        } else if (evt === "validation_fail") {
          setCheckingStatus(false);
          setFail(errorInterpreter(data.content));
          setPending(false);
        } else if (evt === "error") {
          setFail(data.content.final_error_msg.replace(/'/g, ""));
          setPending(false);
        }
      }
    };

    ws.onerror = (err) => {
      setFail("could not connect to socket:", err);
      setLocked(true);
      setPending(false);
    };

    ws.onclose = () => {
      // not perfect. introduces console error saying memory leak
      // and overrides initial 'could not connect to socket' check
      setFail(
        "Lost contact with server! Please refresh the page to try again."
      );
      setLocked(true);
      setPending(false);
    };

    return () => {
      ws.close();
    };
  }, []);

  // const errorInterpreter = (backendInput, col = null, idx = null, field = null, list = null) => {
  const errorInterpreter = (backendMessage) => {
    console.log(
      "interpreting backend error message:",
      backendMessage.err_type,
      backendMessage
    );
    if (
      backendMessage.err_type === "BAD_COLUMN_NAME" &&
      backendMessage.column
    ) {
      return (
        <div className='compact-lists'>
          <p>
            There was an error while trying to parse column{" "}
            <b>"{backendMessage.column}"</b>. This column's name is not included
            in list of required or optional columns.
            <br />
            <br />
            For reference, the following columns are required:
            <br />
            <ul>
              <li>
                <b>student_id</b>, <b>first_name</b>, <b>last_name</b>, and{" "}
                <b>grade</b>.<br />
              </li>
            </ul>
            The following columns are optional:
            <br />
            <ul>
              <li>
                <b>username</b>, <b>student_pwd</b>, <b>reading_level</b>,{" "}
                <b>math_level</b>, and <b>teacher</b>.
              </li>
            </ul>
          </p>
          <p>Correct the following error and try again:</p>
          <ul>
            <li>
              If you intended to include <b>{backendMessage.column}</b> in your
              upload, check your column name's spelling.
            </li>
            <li>Otherwise, remove this column from your CSV file.</li>
          </ul>
        </div>
      );
    } else if (
      backendMessage.err_type === "MISSING_REQUIRED_COLUMN" &&
      backendMessage.column
    ) {
      // TODO differentiate between single missing column or multiple
      return (
        <div className='compact-lists'>
          <p>
            Your file is missing a required column:{" "}
            <b>{backendMessage.column}.</b>
            <br />
            <br />
            For reference, the following columns are required:
            <br />
            <ul>
              <li>
                <b>student_id</b>, <b>first_name</b>, <b>last_name</b>,
                <b>grade</b>, and <b>teacher</b> or <b>school</b>.<br />
              </li>
            </ul>
            The following columns are optional:
            <br />
            <ul>
              <li>
                <b>username</b>, <b>student_pwd</b>, <b>reading_level</b>,{" and"}
                <b>math_level</b>.
              </li>
            </ul>
          </p>
          <p>Correct the following error and try again:</p>
          <ul>
            <li>
              Please include the <b>{backendMessage.column}</b> column in your
              file, and check to make sure that it is spelled correctly.
            </li>
          </ul>
        </div>
      );
    } else if (
      backendMessage.err_type === "MISSING_REQUIRED_FIELD" &&
      backendMessage.field
    ) {
      return (
        <div className='compact-lists'>
          <p>
            The student in row <b>{backendMessage.index}</b> is missing a
            required field: <b>{backendMessage.field}</b>.
          </p>
          <p>Correct the following error and try again:</p>
          <ul>
            <li>
              Row <b>{backendMessage.index}</b>: enter data into the{" "}
              <b>{backendMessage.field}</b> field.
            </li>
          </ul>
        </div>
      );
    } else if (
      backendMessage.err_type === "GRADE_OUT_OF_BOUNDS" &&
      backendMessage.index
    ) {
      // TODO return exact field
      return (
        <div className='compact-lists'>
          <p>
            Row <b>{backendMessage.index}</b>'s grade, reading_level, or
            math_level does not fall within the range that we can accept. Please
            make sure that the entry in this column is a number (or a "K"). If
            you are specifying reading_level or math_level, make sure that the
            entry is between <b>K-5.</b>
          </p>
          <p>Correct the following error and try again:</p>
          <ul>
            <li>
              Row <b>{backendMessage.index}</b>: set <b>grade</b>,{" "}
              <b>reading_level</b>, and <b>math_level</b> data to an integer (or
              a <b>K</b>).
            </li>
            <li>
              Make sure that <b>reading_level</b> and <b>math_level</b> fall
              between <b>K-5</b>.
            </li>
          </ul>
        </div>
      );
    } else if (
      backendMessage.err_type === "FIRST_AND_LAST_NAME_REQUIRED" &&
      backendMessage.index
    ) {
      return (
        <div className='compact-lists'>
          <p>
            The student in row <b>{backendMessage.index}</b>
            is missing an entry in the <b>first_name</b> column and the{" "}
            <b>last_name</b> column.
          </p>
          <p>Correct the following error and try again:</p>
          <ul>
            <li>
              Row <b>{backendMessage.index}</b>: Enter data into the{" "}
              <b>first_name</b> and <b>last_name</b> fields.
            </li>
          </ul>
        </div>
      );
    } else if (
      backendMessage.err_type === "TEACHER_NOT_FOUND" &&
      backendMessage.index
    ) { 
      return (
        <div className='compact-lists'>
          <p>
            Row <b>{backendMessage.index}</b> references a teacher with an
            unrecognized email.
          </p>
          <p>Correct the following error and try again:</p>
          <ul>
            <li>
              Row <b>{backendMessage.index}</b>: The <b>teacher</b> field
              should contain the email address of an existing teacher.
            </li>
          </ul>
        </div>
      );
    } else if (
      backendMessage.err_type === "SCHOOL_NOT_FOUND" &&
      backendMessage.index
    ) { 
      return (
        <div className='compact-lists'>
          <p>
            Row <b>{backendMessage.index}</b> references a teacher with an
            unrecognized school.
          </p>
          <p>Correct the following error and try again:</p>
          <ul>
            <li>
              Row <b>{backendMessage.index}</b>: The <b>school</b> field
              should contain the name of an existing School.
            </li>
          </ul>
        </div>
      );
    } else if (
      backendMessage.err_type === "SCHOOL_ASSIGNMENT_UNAUTHORIZED" &&
      backendMessage.index
    ) { 
      return (
        <div className='compact-lists'>
          <p>
            You must have permission to assign students to schools listed in
            the <b>school</b> column.
          </p>
          <p>Correct the following error and try again:</p>
          <ul>
            <li>
              Row <b>{backendMessage.index}</b>: The <b>school</b> field
              should contain the name of an existing School that you have
              permission to modify.
            </li>
          </ul>
        </div>
      );
    } else if (
      backendMessage.err_type === "LEARNER_MODIFICATION_UNAUTHORIZED" &&
      backendMessage.index
    ) { 
      return (
        <div className='compact-lists'>
          <p>
            You must have permission to modify all students.
          </p>
          <p>Correct the following error and try again:</p>
          <ul>
            <li>
              Row <b>{backendMessage.index}</b>: References an existing student
              that you are unauthorized to modify.
            </li>
          </ul>
        </div>
      );
    } else if (
      backendMessage.err_type === "DUPLICATE_STUDENT_ID_AND_USERNAME_PAIRS" &&
      backendMessage.error_list
    ) {
      let duplicateUsernames = backendMessage.error_list[0][1].map(
        (pair, index) => (
          <li key={index}>
            student_id: <b>{pair}</b>
          </li>
        )
      );
      return (
        <div className='compact-lists'>
          <p>
            If you have added a custom username using the optional{" "}
            <b>username</b> column, then each student's <b>username</b> must be
            either unique or empty.
            <br />
          </p>
          <p>
            The following <b>usernames</b>s contain duplicate <b>student_id</b>
            s:
          </p>
          <ul>
            <li>
              username: <b>{backendMessage.error_list[0][0]}</b>
            </li>
            <ul>{duplicateUsernames}</ul>
          </ul>
          <p>
            Check the <b>usernames</b> and their associated <b>student_id</b>{" "}
            pairs, correct or remove any duplicate usernames, and try again
            <br />
          </p>
          <br />
        </div>
      );
    } else if (
      backendMessage.err_type === "DUPLICATE_USERNAME_AND_STUDENT_ID_PAIRS" &&
      backendMessage.error_list
    ) {
      let duplicateStudentIDs = backendMessage.error_list[0][1].map(
        (pair, index) => (
          <li key={index}>
            username: <b>{pair}</b>
          </li>
        )
      );
      return (
        <div className='compact-lists'>
          <p>
            If you have added a custom username using the optional{" "}
            <b>username</b> column, then each student's <b>username</b> must be
            either unique or blank.
            <br />
          </p>
          <p>
            The following <b>student_id</b>s contain duplicate <b>username</b>s:
          </p>
          <ul>
            <li>
              student_id: <b>{backendMessage.error_list[0][0]}:</b>
            </li>
            <br />
            <ul>{duplicateStudentIDs}</ul>
          </ul>
          <p>
            Check the aforementioned <b>username</b>s and their associated{" "}
            <b>student_id</b> pairs, correct or remove any duplicate student_id,
            and try again.
            <br />
          </p>
          <br />
        </div>
      );
    } else if (backendMessage.err_type === "SERIALIZER_ERROR") {
      // Anybody up for a round of code golf? At risk of over-explaining:
      // ternary operator determines whether each variable is/is not null based on array length
      // if an array contains elements, .map() maps each element in the array onto a <li> element
      // and then returns a list of list elements, which are then used within the <ul> tag below
      const errorItems =
        backendMessage.formatted_errors.length > 0
          ? backendMessage.formatted_errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))
          : null;

      const teacherListItems =
        backendMessage.missing_teachers.length > 0
          ? backendMessage.missing_teachers.map((account, index) => (
              <li key={index}>{account}</li>
            ))
          : null;

      const aboveMaxLearners = backendMessage.above_learner_max;

      return (
        <div className='compact-lists'>
          {errorItems && (
            <>
              <p>Errors were discovered in the following rows:</p>
              <ul>{errorItems}</ul>
            </>
          )}
          {errorItems && (teacherListItems || aboveMaxLearners) && (
            <p>Additionally</p>
          )}
          {teacherListItems && (
            <>
              <p>The following teacher accounts could not be found:</p>
              <ul>{teacherListItems}</ul>
            </>
          )}
          {errorItems && teacherListItems && aboveMaxLearners && <p>and</p>}
          {aboveMaxLearners && (
            <>
              <p>
                This file exceeds your organization's maximum number of active
                student accounts.
              </p>
              <ul>
                <li>
                  Your organization is limited to a maximum of{" "}
                  <b>{aboveMaxLearners["max_enrolled_learners"]}</b> actively
                  enrolled learners.
                </li>
                <li>
                  Currently, your organization has{" "}
                  <b>{aboveMaxLearners["current_enrolled_learners"]}</b>{" "}
                  enrolled learners. This file contains{" "}
                  <b>{aboveMaxLearners["new_learners_in_file"]}</b> new
                  learners.
                </li>
              </ul>
            </>
          )}
          <p>Correct the following errors and try again:</p>
          <ul>
            {errorItems && <li>{errorItems}</li>}
            {teacherListItems && (
              <li>
                Make sure you have added all your teachers on the Users page
                before referencing them in your CSV file.
              </li>
            )}
            {aboveMaxLearners && (
              <li>
                Your number of active learners is limited by the number of
                robots in your organization. Please remove old student accounts
                from classrooms, remove <b>{aboveMaxLearners["overage"]}</b>{" "}
                teacher assignments from your CSV, or activate more robots to
                continue.
              </li>
            )}
          </ul>
        </div>
      );
    } else {
      return <p>Something went wrong. Please try again!</p>;
    }
  };

  const handleFakeLink = (e, index) => {
    e.preventDefault();
    setHelpPage(index);
    resetScroll();
  };

  const helpArr = () => {
    // access individual pages within HELP_ARR using helpPage state var as index
    const HELP_HEADERS_ARR = [
      "Creating and Updating Learners",
      "Required Fields",
      "Optional Fields",
      "Adding Learners to Classes",
      "Example Format",
    ];

    const HELP_HEADERS = HELP_HEADERS_ARR.map((head, index) => (
      <h6
        key={index}
        style={
          helpPage === index ? {fontWeight: "bold"} : {fontWeight: "normal"}
        }
      >
        <a href='#' onClick={(e) => handleFakeLink(e, index)}>
          {head}
        </a>
      </h6>
    ));

    const HELP_ARR = [
      <>
        {/* <h6 style={{fontWeight: 'bold'}}>Creating and Updating Learners:{helpPage},</h6> */}
        <p>
          This section allows you to upload a list of learners using a CSV file.
          This process can be used to create new learners or update existing
          learners. If you have already added teachers, your CSV file can be
          used to add learners to teacher classes as well.
        </p>
        <p>
          Please follow the instructions carefully and upload a CSV file that
          complies with the prescribed format to successfully add your learners.
        </p>
      </>,
      <>
        {/* <h6>Required Fields:</h6> */}
        <p>
          CSVs must be properly formatted. Your uploaded CSV should have one
          learner per row, and at minimum, a CSV must contain the following
          columns:
        </p>
        <ul>
          <li>
            <b>first_name</b> - The student’s first name.
          </li>
          <li>
            <b>last_name</b> - The student’s last name.
          </li>
          <li>
            <b>student_id</b> - A unique identifier for a student.
            <ul>
              <li>
                This should be a Student ID number that identifies the student
                as an individual within your organization, and does not change
                from year to year.
              </li>
              <li>
                If a learner with a specific <b>student_id</b> already exists
                within your organization, that student's information will be
                updated. This means that an up-to-date CSV can be used to change
                information about your organization's student body in bulk at
                the start of a new school year.
              </li>
            </ul>
          </li>
          <li>
            <b>grade</b> - The student’s grade level.
            <ul>
              <li>
                Although ABii is designed for K-5 students, this field can also
                take pre-K and grades 6-12.
              </li>
            </ul>
          </li>
          <li>
            <b>teacher</b> OR <b>school</b>
            <ul>
              <li>
                A <b>"teacher"</b> column should contain the email address of a
                teacher whose class the student should be added to.
              </li>
              <li>
                A <b>"school"</b> column should contain the name of a school to
                which the student should be assigned.
              </li>
            </ul>
          </li>
        </ul>
      </>,
      <>
        {/* <h6>Optional Fields:</h6> */}
        <p>
          There are several other <b>optional</b> fields you can set for your
          learners.
        </p>

        <p>These fields include:</p>
        <ul>
          <li>
            <b>reading_level</b> - The grade level at which a student can
            comfortably read. This field determines the reading lessons that a
            student will see while using Abii.
            <br />
            <ul>
              <li>
                If no value is provided, this will default to the student’s
                grade level.
              </li>
              <li>
                Unlike the <b>grade</b> field mentioned in the previous section,
                values within this field must fall within the <b>K-5</b> range.
                Any values outside of this range will be automatically truncated
                to fit within <b>K-5.</b>
              </li>
              <li>
                A reading_level value of <b>7</b>, for example, would be
                recorded as <b>5</b>, while a reading_level of <b>-1</b> would
                be recorded as <b>K</b>.
              </li>
            </ul>
          </li>
          <li>
            <b>math_level</b> - The student's math proficiency. This field sets
            the math lessons that a student will see while using Abii.
            <ul>
              <li>
                As with <b>reading_level</b>, this field will default to the
                student’s <b>grade</b> level if a value is not provided.
              </li>
            </ul>
          </li>
          <li>
            <b>student_pwd</b> - A custom password that a student will use to
            log in on ABii.
            <ul>
              <li>
                If no data is provided, each student’s password will default to
                1234.
              </li>
            </ul>
          </li>
          <li>
            <b>username</b> - A custom username for a student.
            <ul>
              <li>
                If this field is not provided, a username based on the student’s{" "}
                <b>first_name</b> and <b>last_name</b> fields will be generated
                automatically.
              </li>
              <li>
                If a username already exists for a different student within your
                organization, four random numbers will be added to the end of
                this field to make a unique username.
              </li>
              <li>
                <b>NOTE:</b> students select their names from a dropdown menu
                when logging in on the robot, so they do not need to type their
                usernames.
              </li>
            </ul>
          </li>
        </ul>
      </>,
      <>
        {/* <h6>Adding Learners to Classes:</h6> */}
        <p>
          You can automatically add learners to classes for teachers by
          including a column called <b>“teacher”</b> in the CSV file you upload.
          This column should contain the email address of the teacher whose
          class the student should be added to.
        </p>
        <p>
          In order for the student to be added to the teacher’s class, a teacher
          user with that email address must already exist.
        </p>
        <p>
          If you’d like to add a learner to more than one class, you can include
          the learner more than once in the uploaded file with different values
          in the <b>teacher</b> column. Learners will always be added into a
          default class named "Default Class" for each teacher. A teacher can
          manually change the name of this class after it has been created.
        </p>
        <p>
          Alternatively, you can add a learner to a school by including a
          column called “school”. In order for the student to be added to the
          school, it must already be created by your Organization Admin. You can
          add the learner to multiple schools the same way you do with teachers.
          If you specify both a teacher and a school, that learner will be added
          to that teacher’s classes and to the specified school.
        </p>
      </>,
      <>
        {/* <h6>Example Format:</h6> */}
        <p>
          You may download an example CSV to use as a template by clicking the
          "Download CSV Template" button below.
        </p>
        <p>
          This file should contain all required and optional fields. Ideally,
          you should be able to make a copy of an existing spreadsheet
          containing your students' data, modify the desired column names and
          remove all others.
        </p>
        <p>
          For best results, please export this file with UTF-8 encoding after
          editing it with spreadsheet software. Otherwise, certain characters
          (including accented letters in names) might be misinterpreted.
        </p>
        <p>
          Please contact Van Robotics for assistance at{" "}
          <b>support@myvanrobot.com</b> if you need any help with this process.
        </p>
      </>,
    ];
    return [
      HELP_HEADERS,
      HELP_HEADERS_ARR,
      HELP_ARR[helpPage],
      HELP_ARR.length - 1,
    ];
  };

  const getHelp = () => {
    if (helpPage !== null) {
      const [helpHeaderLinks, helpHeaderCaptions, helpText, limit] = helpArr();
      return (
        <>
          <div
            id='bulk_learner_help_popup_wrapper'
            className='pdf-upload-wrapper-large-offset'
          >
            <div className='common_border'>
              <div className='common_heading'>
                <p>Bulk Learner Upload: Help</p>
              </div>

              <button
                className='pdf_popup_close'
                onClick={() => {
                  setHelpPage(null);
                }}
              >
                <FA icon='times' />
              </button>
              <div id='help-grid-and-btns-container'>
                <div className='help-grid-container'>
                  <h6 className='help-number'>
                    Page {helpPage + 1} of {limit + 1}
                  </h6>
                  <div className='help-topbar'>
                    <h5>{helpHeaderCaptions[helpPage]}</h5>
                  </div>
                  <div className='help-sidebar'>
                    <ul>{helpHeaderLinks}</ul>
                  </div>
                  <div ref={scrollRef} className='help-main visible-scrollbar'>
                    {helpText}
                  </div>
                </div>

                <div id='help-grid-buttons'>
                  <button
                    className='btn btn-md btn-primary m-1'
                    onClick={() => {
                      api.fetchBulkLearnerTemplate();
                    }}
                  >
                    <FA icon='download' color='white' /> Download CSV Template
                  </button>
                  <button
                    className='btn btn-md btn-primary m-1'
                    disabled={helpPage <= 0}
                    onClick={() => {
                      setHelpPage(helpPage - 1);
                      resetScroll();
                    }}
                  >
                    Previous
                  </button>
                  <button
                    className='btn btn-md btn-primary m-1'
                    disabled={helpPage >= limit}
                    onClick={() => {
                      setHelpPage(helpPage + 1);
                      resetScroll();
                    }}
                  >
                    Next
                  </button>
                  <button
                    className='btn btn-md btn-primary m-1'
                    onClick={() => {
                      setHelpPage(null);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const submissionRender = () => {
    if (checkingStatus) {
      return (
        <>
          <div>
            <h6>
              <FA color='gray' icon='spinner' spin /> Loading Status...
            </h6>
          </div>
        </>
      );
    } else if (fail) {
      return (
        <>
          <div className='bulk_learner_information'>
            <div className='alert alert-danger'>
              Your file could not be processed. Please check your template for
              formatting issues or missing data.
            </div>
            <div style={{whiteSpace: "pre-line"}}>{fail}</div>
            <div className='alert alert-info'>
              Refer to the <b>Help</b> section for more information on
              formatting your CSV file. If you are still having trouble, contact
              Van Robotics at <b>support@myvanrobot.com</b> for help.
            </div>
          </div>
          <button
            className='btn btn-md btn-primary m-1'
            onClick={() => {
              setHelpPage(0);
            }}
          >
            <FA icon='question' color='white' /> Help
          </button>
          <button
            className='btn btn-md btn-primary mr-2'
            onClick={() => {
              resetComponent();
            }}
          >
            Reset
          </button>
        </>
      );
    } else if (locked) {
      return (
        <>
          <div className='bulk_learner_information'>
            <div className='alert alert-danger'>
              Server unavailable.
              <br />
              Please contact Van Robotics at <b>support@myvanrobot.com</b> for
              assistance.
            </div>
          </div>
          <button
            className='btn btn-md btn-primary mr-2'
            onClick={() => {
              closeComponent();
            }}
          >
            Close
          </button>
        </>
      );
    } else if (success) {
      return (
        <>
          <div className='bulk_learner_information'>
            <div className='alert alert-success'>
              <h6 style={{margin: "0px"}}>
                Success! Your file has been processed successfully.
              </h6>
            </div>
            <p>
              You may close this popup to see an updated list of learners.
              <br />
              You can use the "Manage Teacher" button on the "Users" page to
              view classes for each of your teachers.
            </p>
          </div>
          <button
            className='btn btn-md btn-primary mr-2'
            onClick={() => {
              closeComponent();
            }}
          >
            Close
          </button>
        </>
      );
    } else if (pending) {
      let percentComplete = percentValidated;
      if (validated) {
        percentComplete = Math.round(
          (percentValidated * 3) / 10 + (percentConfirmed * 7) / 10
        );
      }
      return (
        <>
          <div>
            <h6>Progress:</h6>
            <ProgressBar completed={percentComplete} />
          </div>

          <p>
            {!validated
              ? "Validating your file..."
              : "Entering student data..."}
          </p>
          {validated && (
            <div className='alert alert-info'>
              This may take a while if your file contains a large number of
              students. Feel free to leave this window open and check back
              later!
            </div>
          )}
        </>
      );
    } else if (validated) {
      return (
        <>
          <div className='bulk_learner_information'>
            <div className='alert alert-success'>
              <h6 style={{margin: "0px"}}>File validated!</h6>
            </div>
            <>
              <h6>
                This operation will <b>ADD {newLearners}</b> learners
              </h6>
              <h6>
                This operation will <b>UPDATE {existingLearners}</b> learners
              </h6>
            </>
            <p>Confirm and submit?</p>
          </div>
          <button
            className='btn btn-md btn-primary mr-2'
            disabled={pending}
            onClick={onSubmit}
          >
            {!pending ? "Confirm Data" : "Submitting..."}
          </button>
          <button
            className='btn btn-md btn-primary mr-2'
            disabled={pending}
            onClick={() => {
              resetComponent();
            }}
          >
            Go Back
          </button>
        </>
      );
    } else {
      return (
        <>
          <h6>Create or update a list of students using a CSV.</h6>
          <p>Click 'Help' for instructions</p>
          <div>
            <input type='file' accept='.csv' name='' onChange={changeHandler} />
            <br />
            <br />
            <button
              className='btn btn-md btn-success m-1'
              disabled={!file}
              onClick={onSubmit}
            >
              Submit
            </button>
            <button
              className='btn btn-md btn-primary m-1'
              onClick={() => {
                api.fetchBulkLearnerTemplate();
              }}
            >
              <FA icon='download' color='white' /> Download CSV Template
            </button>
            <button
              className='btn btn-md btn-primary m-1'
              onClick={() => {
                setHelpPage(0);
              }}
            >
              <FA icon='question' color='white' /> Help
            </button>
            <button
              className='btn btn-md btn-danger m-1'
              onClick={() => closeComponent()}
            >
              Cancel
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <div className='pdf-upload'>
      <div id='bulk_learner_popup_wrapper' className='pdf-upload-wrapper-large'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Bulk Learner Upload</p>
          </div>
          <div className={`common_dashboard_bg`} style={{overflowY: "auto"}}>
            {getHelp()}
            <div className='bulk_learner_content'>{submissionRender()}</div>
            <button
              className='pdf_popup_close'
              disabled={pending}
              onClick={() => {
                setShowModal(false);
              }}
            >
              <FA icon='times' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAPI(BulkAddStudentsModal);
