import React from "react";
import config from "../../common/config";
import { getDateTimeStringPerGivenFormat } from "../../common/utils";
import FA from "../../containers/fa";
import ExpirationStatus from "../UtilComps/ExpirationStatus";
import "./SubscriptionStyles.scss";

const SubscriptionRenewalRows = ({
  subscriptions,
  includeSelection,
  selectedSubscriptionIds,
  setSelectedSubscriptionIds,
}) => {
  const subscriptionLabelMap = config.SUBSCRIPTION_TYPE_DISPLAY_NAMES;
  let subscriptionsBySale = {};
  subscriptions.forEach((sub) => {
    if (subscriptionsBySale[sub.sale]) {
      subscriptionsBySale[sub.sale].push(sub);
    } else {
      subscriptionsBySale[sub.sale] = [sub];
    }
  });

  const handleCheckboxChange = (sub) => {
    // add or remove sub.id from selected array
    // and apply the same change to all subs in same sale
    const _tmp = new Set(selectedSubscriptionIds);
    const subsInSale = subscriptionsBySale[sub.sale];
    if (!_tmp.has(sub.id)) {
      subsInSale.forEach((item) => _tmp.add(item.id));
    } else {
      _tmp.delete(sub.id);
    }
    setSelectedSubscriptionIds(_tmp);
  };

  const getSubsPlusStandaloneLctSubs = () => {
    const availableSubsToRenew = [];
    Object.keys(subscriptionsBySale).forEach((sale) => {
      const subsInSale = subscriptionsBySale[sale];
      if (subsInSale.length > 1) {
        let nonLCTSubs = subsInSale.filter(
          (sub) => sub.subscription_type !== config.LCT_SUB_TYPE
        );
        if (nonLCTSubs.length > 1) {
          const premiumCareSub = nonLCTSubs.find(
            (sub) => sub.subscription_type === config.PREMIUMCARE_SUB_TYPE
          );
          if (premiumCareSub) {
            premiumCareSub.paired = true;
            premiumCareSub.paired_indented = false;
            availableSubsToRenew.push(premiumCareSub);
            nonLCTSubs = nonLCTSubs.filter(
              (sub) => sub.subscription_type !== config.PREMIUMCARE_SUB_TYPE
            );
            nonLCTSubs.forEach(
              (sub) => ((sub.paired = true), (sub.paired_indented = true))
            );
          } else {
            nonLCTSubs.forEach((sub) => (sub.paired = false));
          }
        }
        availableSubsToRenew.push(...nonLCTSubs);
      } else {
        availableSubsToRenew.push(subsInSale[0]);
      }
    });
    return availableSubsToRenew;
  };

  const subscriptionsForRenewal =
    subscriptions && subscriptions.length > 0
      ? getSubsPlusStandaloneLctSubs()
      : [];

  return (
    <div className='subscription-type-container'>
      <div className='subscription_row_renewal_hr'>
        <div>Sale Id</div>
        <div>Sub. Type</div>
        <div>Active</div>
        <div>Expiration Date</div>
        <div>Sub. Count</div>
        <div>Last Renewed</div>
        <div>{includeSelection ? "Select" : ""}</div>
      </div>

      <div id='subscription_row_renewal_rows_container'>
        {subscriptionsForRenewal.map((sub, index) => (
          <div
            className={`subscription_row_renewal_data ${
              sub.paired && !sub.paired_indented
                ? "paired"
                : sub.paired_indented
                ? "paired indented"
                : "not_paired"
            }`}
            key={sub.id}
          >
            <div>
              <p
                style={{
                  textAlign: "left",
                  marginLeft: `${
                    sub.paired && !sub.paired_indented
                      ? "0px"
                      : sub.paired_indented
                      ? "-60px"
                      : "0px"
                  }`,
                }}
              >
                {sub.sale}
              </p>
            </div>
            <div>
              <p style={{ textAlign: "left" }}>
                {sub.subscription_type === config.ABII_CONTENT_PACKAGE_SUB_TYPE
                  ? sub.acp.name
                  : subscriptionLabelMap[sub.subscription_type]}
              </p>
            </div>
            <div>
              <p>
                {sub.active ? (
                  <span>
                    Yes
                    <FA icon='check' className='has-wifi status-icon ml-1' />
                  </span>
                ) : (
                  <span>No</span>
                )}
              </p>
            </div>
            <ExpirationStatus
              containerStyle={{ textAlign: "left" }}
              obj={sub}
            />
            <div>
              <p style={{ textAlign: "left" }}>
                {sub.subscriptionlimit_set[0].max_objects}
              </p>
            </div>
            <div style={{ textAlign: "left" }}>
              <p>
                {sub.last_renewed_on
                  ? getDateTimeStringPerGivenFormat(
                      sub.last_renewed_on,
                      "MMMM Do YYYY"
                    )
                  : "-"}
              </p>
            </div>
            <div>
              {includeSelection && (
                <React.Fragment>
                  {sub.pending_renewal ? (
                    <span>Pending</span>
                  ) : (
                    <input
                      type='checkbox'
                      name='sub-checkbox'
                      className='form-control larger-checkbox'
                      checked={selectedSubscriptionIds.has(sub.id)}
                      onChange={() => {
                        handleCheckboxChange(sub);
                      }}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionRenewalRows;
