import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import config from "../common/config";
import FA from "../containers/fa";
import FAW from "../containers/faw";
import {
  login,
  //  robotType as robotTypeA,
  studentCap as studentCapA,
  userOrgSettings as userOrgSettingsA,
} from "../redux/actions";
import withAPI from "../services/api";
import {authService} from "../services/auth";
import LoginWithCleverButton from "./UtilComps/LoginWithCleverButton";

const LoginForm = ({api, history, dispatch, setCanCreateUser}) => {
  const dropdownDefault = "----------";
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState(null);
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    setSpin(false);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSpin(true);

    if (username == "" || username == dropdownDefault) {
      setSpin(false);
      setError("Please select a username.");
      return;
    }

    authService
      .login(username, pass)
      .then(
        ([
          user,
          token,
          organization,
          hasRobots,
          hasAccounts,
          localBotsOnly,
          forcePWReset,
          orgStatus,
        ]) => {
          setSpin(false);
          dispatch(
            login(
              user,
              token,
              hasRobots,
              hasAccounts,
              localBotsOnly,
              forcePWReset,
              orgStatus
            )
          );
          dispatch(studentCapA(user.student_cap));
          if (user.organization.settings) {
            dispatch(userOrgSettingsA(user.organization.settings));
          }
          setUsername("");
          setPass("");
          setError(null);
          sessionStorage.setItem("firstLogin", true);
          if (
            user.usertype === config.TEACHER_USERTYPE &&
            user.organization.category === config.ABII_SCHOOL.toLowerCase()
          ) {
            console.log("Redirecting to Teacher Dashboard");
            history.push("/teacher/dashboard");
          } else if (
            user.usertype === config.TEACHER_USERTYPE &&
            user.organization.category === config.ABII_HOME.toLowerCase()
          ) {
            console.log("Redirecting to Parent Dashboard");
            history.push("/home/dashboard");
          } else if (user.usertype === config.ORGADMIN_USERTYPE) {
            history.push("/admin/dashboard", {from: "login"});
          } else if (user.usertype === config.ORGUNITADMIN_USERTYPE) {
            history.push("/admin/dashboard", {from: "login"});
          } else if (user.usertype === config.LEARNER_USERTYPE) {
            history.push("/learner/dashboard", {from: "login"});
          } else if (user.usertype === config.SUPERADMIN_USERTYPE) {
            history.push("/super/dashboard", {from: "login"});
          } else {
            // Whelp.
          }
        }
      )
      .catch((err) => {
        setSpin(false);
        if (err.message === 'INVALID_LOGIN') {
          setError(
            <span>
              Invalid username or password.
              <br></br>
              Some accounts are only accessible via the Single Sign-On buttons below.
            </span>
          );
        } else {
          setError("Something went wrong ... Please try again.");
        }
      });
  };

  return (
    <form name='LogInForm' noValidate autoComplete='off'>
      <div id='form_container' className='form-content form-group container'>
        <div className='form_icons' id='username'>
          <label>
            <FA icon='user' />
          </label>
          <input
            required
            className='form-control input-underline input-lg'
            placeholder='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

        <div className='form_icons'>
          <label>
            <FA icon='lock' />
          </label>
          <input
            required
            className='form-control input-underline input-lg'
            placeholder='Password'
            type='password'
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
        </div>

        <Link to='/forgot-password'>Forgot your password?</Link>

        <Link to='/forgot-username'>Forgot your username?</Link>

        <div className='row d-flex justify-content-between align-items-center'>
          {error && <div className='alert alert-danger'>{error}</div>}
        </div>
        <button
          type='submit'
          onClick={handleSubmit}
          className='btn btn-lg fs-14 btn-primary'
          style={{width: "250px", maxWidth: "100%"}}
        >
          {spin ? <FAW icon='spinner' spin /> : <span>Login</span>}
        </button>

        <p style={{margin: "10px 0px 10px 0px"}}>--- OR ---</p>

        <LoginWithCleverButton />
      </div>
    </form>
  );
};

export default compose(withRouter, withAPI)(LoginForm);
