import React from "react";
import StepOneLessonRow from "./StepOne/StepOneLessonRow";
import ClosePopupBtn from "../../../UtilComps/ClosePopupBtn.js";
const SelectedLessonsPopup = ({
  shouldShowFunc,
  lessons,
  setLessons,
  selectedLessons,
  setSelectedLessons,
  setPopupInfo,
  setShowPopupInfo,
}) => {
  let count = 0;
  return (
    <div
      className='pdf-upload'
      style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
    >
      <div className='"pdf-upload-wrapper create_assignment_table_popup'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Selected Lessons</p>
            <ClosePopupBtn closePopupFunc={shouldShowFunc} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <div className='create_assignment_popup_grid'>
                  <p>
                    This is your list of selected lessons across all courses. If
                    you want to remove a lesson from this list, simply click its
                    checkbox to unselect it.
                  </p>

                  <div className='create_assignment_table_container popup_table_container visible-scrollbar'>
                    <div className='choose_lesson_table'>
                      <div className='choose_lesson_table_header'>
                        <p></p>
                        <p>Course</p>
                        <p>Unit</p>
                        <p style={{ textAlign: "center" }}>Standard</p>
                        <p style={{ textAlign: "center" }}>Type</p>
                        <p>Lesson Name</p>
                        <p style={{ textAlign: "center" }}>Preview</p>
                        <p style={{ textAlign: "center" }}>Selected</p>
                      </div>

                      {selectedLessons.length > 0 ? (
                        selectedLessons.map((value, index) => {
                          if (
                            index !== 0 &&
                            selectedLessons[index - 1].tutorial.selected &&
                            selectedLessons[index - 1].assessment.selected
                          ) {
                            // both tut and asm were selected in this entry
                            count = count + 2;
                          } else {
                            count++;
                          }

                          return (
                            <StepOneLessonRow
                              key={index}
                              index={lessons.indexOf(value)}
                              numInDisplayTable={count}
                              lesson={value}
                              lessons={lessons}
                              setLessons={setLessons}
                              selectedLessons={selectedLessons}
                              setSelectedLessons={setSelectedLessons}
                              isPopup={true}
                              setPopupInfo={setPopupInfo}
                              setShowPopupInfo={setShowPopupInfo}
                            />
                          );
                        })
                      ) : (
                        <div className='no_lessons_to_select'>
                          No lessons selected.
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gridGap: "10px",
                    }}
                  >
                    <button
                      style={{ margin: "auto" }}
                      onClick={() => {
                        count = 0;
                        shouldShowFunc(false);
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedLessonsPopup;
