import React from "react";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const CloseAssignmentConfirmModal = ({setCloseAssignment, shouldShowFunc}) => {
  return (
    <div className='pdf-upload'>
      <div
        className='pdf-upload-wrapper-large'
        style={{width: "calc(100% - 40px)"}}
      >
        <div className='common_border'>
          <div className='common_heading'>
            <p>Close Assignment?</p>
            <ClosePopupBtn closePopupFunc={shouldShowFunc} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <p style={{textAlign: "center"}}>
                  Are you sure you want to close this assignment? This cannot be
                  undone.
                </p>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    gridGap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{width: "fit-content", margin: "auto"}}
                    onClick={() => {
                      setCloseAssignment(true);
                      shouldShowFunc(false);
                    }}
                    className='btn btn-sm btn-primary'
                  >
                    Yes, close the assignment.
                  </button>
                  <button
                    style={{width: "fit-content", margin: "auto"}}
                    onClick={() => shouldShowFunc(false)}
                    className='btn btn-sm btn-danger'
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseAssignmentConfirmModal;
