import React, { useState, useEffect } from 'react';
import withAPI from '../../services/api';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import FA from '../../containers/fa';
import config from '../../common/config';
import { authService } from '../../services/auth';
import { logout } from '../../redux/actions';

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});


const DeleteSelfModal = ({api, dispatch, setShowDeleteSelf, forcePasswordReset, user}) => {
  const [error, setError] = useState(null);
  const [accountDeleted, setAccountDeleted] = useState(false);
  const [accountPassword, setAccountPassword] = useState("");
  const [deleteDisabled, setDeleteDisabled] = useState(true);

  //const deleteWithPassword = !(user && !user.can_login_with_password)
  const deleteWithPassword = (user && user.can_login_with_password)
  
  const handleDelete = () => {
    if (deleteWithPassword) {
      api.deleteAccount(accountPassword).then((res) => {
        if (res.success) {
          authService.logout().then(() => {
            setAccountDeleted(true)
          });
        } else if (res.msg) {
          setError(res.msg);
        }
      }).catch(() => { setError(true); });
    } else {
      api.deleteUser(user.user.id).then((res) => {
        authService.logout().then(() => {
          setAccountDeleted(true)
        });
      }).catch(() => { setError(true); });
    }
  }

  const handleRedirect = () => {
    dispatch(logout());
  }

  useEffect(() => {
    console.log("deleteWithPassword:",deleteWithPassword);
    if (!deleteWithPassword) {
      setDeleteDisabled(false);
    } else if (accountPassword.length > 0) {
      setDeleteDisabled(false);
    } else {
      setDeleteDisabled(true);
    }
  }, [accountPassword]);


  if (accountDeleted) {
    return (
    <div className="pdf-upload top-layer">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Remove My Account</p>
          </div>
          <div className="common_dashboard_bg">
            <div className="row">
              <div className="col-12">
                <div className="alert alert-success">
                  Your account has been deleted. Click <Link to="/" onClick={() => {handleRedirect()}}> here </Link> to navigate to the Home page.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Remove My Account</p>
            <div className="pdf_popup_close" onClick={() => setShowDeleteSelf(false)}><FA icon="times" /></div>
          </div>
          <div className="common_dashboard_bg">
            <div className="row">
              <div className="col-12">

                <p>Are you sure you want to delete your account?</p>

                {!deleteWithPassword &&
                  <React.Fragment>
                    <p className="alert-danger p-2">
                      WARNING:
                      Deleting your account will delete all of your classes.
                      The Learners in these classes will be preserved.
                      This cannot be undone.
                    </p>
                    <div className="d-flex mt-4">
                      <button 
                        onClick={() => setShowDeleteSelf(false)}
                        className="btn btn-sm btn-primary"
                      >
                        {"Take me back"}
                      </button>
                      <button
                        className="btn btn-sm btn-danger ml-4"
                        onClick={handleDelete}
                        disabled={deleteDisabled}
                      >
                        {"Delete Account"}
                      </button>
                    </div>
                  </React.Fragment>
                }

                {deleteWithPassword &&
                  <React.Fragment>
                    <p>If you are sure you want to delete this account, please type in <strong>your password</strong> to proceed.</p>

                    <p className="alert-danger p-2">
                      WARNING:
                      Deleting your account will delete all of your classes.
                      The Learners in these classes will be preserved.
                      This cannot be undone.
                    </p>

                    <input required className="form-control input-underline input-lg"
                      placeholder="Type in the password for your account" type="text" value={accountPassword}
                      onChange={(e) => setAccountPassword(e.target.value)}/>
                    <div className="d-flex mt-4">
                      <button onClick={() => setShowDeleteSelf(false)} className="btn btn-sm btn-primary" >Take me back</button>
                      <button onClick={handleDelete} className="btn btn-sm btn-danger ml-4" disabled={deleteDisabled}>Delete Account</button>
                    </div>
                  </React.Fragment>
                }

                {error && <div className="alert alert-danger mt-4">{error === true ? 'Something went wrong.' : error}</div>}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)}

export default compose(
  connect(mapStateToProps),
  withAPI,
)(DeleteSelfModal);
