import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, useHistory, withRouter } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import withAPI from '../../services/api';
import MainDashboard from '../../partials/Main';
import MergeOrgModal from '../../components/SuperAdmin/MergeOrgModal.js'


const MergeOrganization = ({api, dispatch}) => {

  let history = useHistory();
  const [allOrgNames, setAllOrgNames] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);


  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [firstOrg, setFirstOrg] = useState({'name': null, 'id': -1});
  const [secondOrg, setSecondOrg] = useState({'name': null, 'id': -1});


  useEffect(() => {
    api.fetchAllVisibleOrganizations().then((objects) => {
      setAllOrgNames(objects);
    })
  }, [refresh])

  useEffect(() => {
    console.log('firstOrg ', firstOrg)
    console.log('secondOrg ', secondOrg)
  }, [secondOrg, firstOrg])  

  const handleMergeClick = () => {
    setError(null);
    setSuccess(null);
    if (firstOrg.id < 0 || secondOrg.id < 0) {
      setError('Please select the two organizations.');
    }
    if (firstOrg.id === secondOrg.id) {
      setError('Please select two different organizations.');
    }
    setShowMergeModal(true);
  }

  return (
    <MainDashboard>
      <div className="common_boarder">
        <div className="common_heading d-flex justify-content-between align-items-center">
          <p>Merge Organization</p>
          &nbsp;
          <button className="btn btn-sm btn-primary" onClick={() => history.goBack()}> Back </button>
        </div>
        <div className="common_table_padding">
          
          <h5 className="mb-3"> Select the 2 organizations to merge: </h5>
          
          <div className="merge-org-form row">
            <div className="col-md-8 offset-md-2">
              <Autocomplete
                className="mb-2"
                fullWidth={true}
                options={allOrgNames}
                getOptionLabel={(option) => option.name}
                onChange={(e, v, r) => {
                  setFirstOrg({
                    name: v.name,
                    id: v.id,
                  })
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    margin='none'
                    placeholder="Merge From Organization"
                    required={true}
                    variant='outlined'
                  />
                )}
              />
              <Autocomplete
                className="mt-2"
                fullWidth={true}
                options={allOrgNames}
                getOptionLabel={(option) => option.name}
                onChange={(e, v, r) => {
                  setSecondOrg({
                    name: v.name,
                    id: v.id,
                  })
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    margin='none'
                    placeholder="Merge Into Organization"
                    required={true}
                    variant='outlined'
                  />
                )}
              />

              <button className="btn btn-primary my-4" onClick={()=>{handleMergeClick()}}>Merge Organizations</button>
              {error &&
                <p className="alert alert-danger">{error}</p>
              }
              {success &&
                <p className="alert alert-success">{success}</p>
              }

            </div>
          </div>

          {showMergeModal &&
            <MergeOrgModal
              shouldShowFunc={setShowMergeModal}
              triggerRefresh={setRefresh}
              mergeFrom={firstOrg}
              mergeTo={secondOrg}
            />
          }

        </div>
      </div>
    </MainDashboard>
  );
}

export default compose(
  withAPI,
  withRouter,
)(MergeOrganization);