import React from "react";
import FAW from "../../../../containers/faw";
import FA from "../../../../containers/fa";
import "./ReportsLearnerStatsTable.scss";

const ReportsLearnerStatsTable = ({
  activeLearner,
  profileStats,
  profileStatsLoaded,
  ready,
}) => {
  const roundToPerc = (num) => {
    return Math.round(num * 10000) / 100 + "%";
  };

  const toMinSec = (val) => {
    if (val < 60) {
      return Math.round(val) + " s";
    } else {
      let minutes = Math.floor(val / 60);
      let seconds = Math.floor(val % 60);
      return minutes + " m " + seconds + " s";
    }
  };

  return (
    <div>
      {activeLearner && profileStats && ready && profileStatsLoaded ? (
        <div id='reports-learner-stats-table' className='table borderless'>
          <div className='label-row' style={{borderTop: 'none'}}>
            <div className='reports-learner-stats-table-header'></div>
            <div className='reports-learner-stats-table-header'>Overall</div>
            <div className='reports-learner-stats-table-header'>Tutorials</div>
            <div className='reports-learner-stats-table-header'>
              Assessments
            </div>
          </div>

          <div className='label-row'>
            <div
              className='label-cell'
              data-title='Shows attention averaged across all lessons taken'
            >
              Avg. Attention
              <FAW icon='info-circle' className='stats-table-info' />
            </div>
            <div className='data-cell'>{roundToPerc(profileStats.overall_avg_attn)}</div>
            <div className='data-cell'>{roundToPerc(profileStats.avg_attn_tut)}</div>
            <div className='data-cell'>{roundToPerc(profileStats.avg_attn_asm)}</div>
          </div>
          <div className='label-row'>
            <div
              className='label-cell'
              data-title='Shows performance (number of questions correct out of total) averaged across all lessons taken'
            >
              Avg. Performance
              <FAW icon='info-circle' className='stats-table-info' />
            </div>
            <div className='data-cell'>{roundToPerc(profileStats.overall_avg_perf)}</div>
            <div className='data-cell'>{roundToPerc(profileStats.avg_correct_tut)}</div>
            <div className='data-cell'>{roundToPerc(profileStats.avg_correct_asm)}</div>
          </div>
          <div className='label-row'>
            <div
              className='label-cell'
              data-title='Shows average time spent on each question answered'
            >
              Avg. Screen Duration
              <FAW icon='info-circle' className='stats-table-info' />
            </div>

            <div className='data-cell'>
              {profileStats.avg_screen_duration_tut > 0
                ? toMinSec(profileStats.avg_screen_duration_tut / 1000)
                : "N/A "}
            </div>
            <div className='data-cell'>
              {profileStats.avg_screen_duration_asm > 0
                ? toMinSec(profileStats.avg_screen_duration_asm / 1000)
                : "N/A "}
            </div>
          </div>
          <div className='label-row'>
            <div
              className='label-cell'
              data-title='Shows average time spent completing each lesson'
            >
              Avg. Lesson Duration
              <FAW icon='info-circle' className='stats-table-info' />
            </div>

            <div className='data-cell'>
              {profileStats.tut_avg_lesson_duration > 0
                ? toMinSec(profileStats.tut_avg_lesson_duration)
                : "N/A "}
            </div>
            <div className='data-cell'>
              {profileStats.asm_avg_lesson_duration > 0
                ? toMinSec(profileStats.asm_avg_lesson_duration)
                : "N/A "}
            </div>
          </div>
        </div>
      ) : (!ready || !profileStatsLoaded) && activeLearner ? (
        <div>
          <FA color='gray' icon='spinner' spin /> Loading...
        </div>
      ) : !profileStats && activeLearner ? (
        <div className='row'>
          <div className='col-12'>
            <h4 style={{margin: "5px"}}> No stats available </h4>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReportsLearnerStatsTable;
