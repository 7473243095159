import {Checkbox} from "@mui/material";
import React from "react";
import {
  getGradeLevel,
  selectOrDeselectLearner
} from "../../CreateAssignmentFlowUtilities";

const StepTwoLearnersRow = ({
  learner,
  learners,
  setLearners,
  selectedClass,
  numInDisplayTable,
  setSelectedLearners,
  numOfSelectedLessons,
  isPopup = false,
}) => {
  let currentLearner = null;
  if (isPopup) {
    // display table in the popup
    for (let i = 0; i < learner.classes.length; i++) {
      // iterate through the list of class ids the learner is in
      let currentClass = learners[learner.classes[i]];
      if (currentClass) {
        // class belongs to the user
        for (let a = 0; a < currentClass.length; a++) {
          if (currentClass[a].user_id === learner.user_id) {
            // we have a match
            currentLearner = currentClass[a];
            break;
          }
          if (currentLearner) break;
        }
      }
    }
  } else {
    // display table of all learners in a class
    currentLearner = learner;
  }

  return (
    <div
      className={`choose_learner_table_option_row ${
        currentLearner.selected ? "selected_tut" : ""
      }`}
      onClick={() => {
        selectOrDeselectLearner(
          learners,
          selectedClass,
          currentLearner,
          setLearners,
          setSelectedLearners,
          false
        );
      }}
    >
      <p className='index'>{numInDisplayTable}</p>
      <p>
        {currentLearner && currentLearner.user.first_name}{" "}
        {currentLearner && currentLearner.user.last_name}
      </p>
      <p>{currentLearner && currentLearner.user.username}</p>
      <p style={{textAlign: "center"}}>
        {currentLearner && currentLearner.lessons_completed_from_list}/
        {numOfSelectedLessons}
      </p>
      <p style={{textAlign: "center"}}>{getGradeLevel(currentLearner)}</p>{" "}
      <Checkbox
        onChange={() => {
          selectOrDeselectLearner(
            learners,
            selectedClass,
            currentLearner,
            setLearners,
            setSelectedLearners,
            false
          );
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter")
            selectOrDeselectLearner(
              learners,
              selectedClass,
              currentLearner,
              setLearners,
              setSelectedLearners,
              false
            );
          return;
        }}
        checked={currentLearner && currentLearner.selected}
        size='small'
      />
    </div>
  );
};

export default StepTwoLearnersRow;
