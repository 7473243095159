import React from "react";
import "../ConsolidatedReports/ClassTab/ClassScoresPagination.scss";
const AssignmentsPagination = ({
  currentPage,
  setCurrentPage,
  numberPerPage,
  setNumberPerPage,
  numberOfAssignments,
  startIndex,
  endIndex,
}) => {
  return (
    <div id='learners_per_page_select_container'>
      <button 
        className='pagination_btn previous'
        style={{
          opacity: startIndex !== 1 && numberOfAssignments > 0 ? "1" : "0",
        }}
        disabled={!(startIndex !== 1 && numberOfAssignments > 0)}
        onClick={() => {
          if (startIndex !== 1) {
            setCurrentPage(currentPage - 1);
          }
        }}
      >
        <u>{`<< Previous`}</u>
      </button>
      <label style={{marginRight: "5px"}}>Assignments per page:</label>

      <select
        id='learners_per_page_select'
        value={numberPerPage}
        onChange={(e) => {
          setNumberPerPage(e.target.value);
          setCurrentPage(1);
        }}
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={15}>15</option>
        <option value={20}>20</option>
        <option value={25}>25</option>
        <option value={30}>30</option>
      </select>
      <p style={{margin: "0px 0px 0px 5px"}}>
        Showing assignments {startIndex} - {endIndex} (out of{" "}
        {numberOfAssignments})
      </p>
      <button 
        className='pagination_btn next'
        style={{
          opacity:
            numberOfAssignments !== endIndex && numberOfAssignments > 0
              ? "1"
              : "0",
        }}
        disabled={!(numberOfAssignments !== endIndex && numberOfAssignments > 0)}
        onClick={() => {
          if (numberOfAssignments !== endIndex ? "1" : "0") {
            setCurrentPage(currentPage + 1);
          }
        }}
      >
        <u>{`Next >>`}</u>
      </button>
    </div>
  );
};

export default AssignmentsPagination;
