import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import { connect } from "react-redux";
import { compose } from "redux";

import GradeDropdown from "../TeacherDashboard/GradeDropdown.js";
import InfoPopup from "../UtilComps/InfoPopup";
import LearnerRowDeleteModal from "./LearnerRowDeleteModal.js";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AccommodationInfoTab from "./AccommodationInfoTab.jsx";
import MultiSchoolReactSelect from "../InputFields/MultiSchoolReactSelect.js";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const EditStudentModal = ({
  api,
  editLearner,
  setEditLearner,
  refreshLearners,
  classId,
  user,
}) => {
  const [learnerID, setLearnerID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [proposedUsername, setProposedUsername] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [schools, setSchools] = useState([]);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
  const [infoText, setInfoText] = useState(null);
  const [maxTabHeight, setMaxTabHeight] = useState(null);

  const handleSave = () => {
    if (!schools || schools.length === 0) {
      setSuccess(null);
      setError("Please select a school.");
      return;
    }

    setError(null);
    setSuccess(null);
    setLoading(true);
    const data = {
      grade_info: {
        grade_level: gradeLevel.value,
      },
      org_uid: learnerID || null,
      user: {
        first_name: firstName,
        last_name: lastName,
      },
      orgunits: schools.map((school) => school.id),
    };
    if (username !== proposedUsername) {
      data.user = { ...data.user, username: proposedUsername };
    }
    api
      .patchLearnerInfo(editLearner.id, data)
      .then((updatedInfo) => {
        setSuccess("Successfully saved!");
        setEditLearner(updatedInfo);
        setSaveDisabled(true);
        refreshLearners();
        setLoading(false);
      })
      .catch((e) => {
        if (e === '["A user with that username already exists."]') {
          console.log("PARSED", JSON.parse(e));
          setError("A user with that username already exists.");
        } else {
          setError(e.message);
        }
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setShouldShowDeleteModal(true);
  };

  const handleSchoolChange = (selectedSchools) => {
    console.log(
      "handleSchoolChange received selectedSchools:",
      selectedSchools
    );
    if (selectedSchools && selectedSchools.length > 0) {
      setError(null);
      setSchools(
        selectedSchools.map((option) => ({
          name: option.label,
          id: option.value,
        }))
      );
    } else {
      setSchools([]);
    }
    setSaveDisabled(false);
  };

  const getDefaultGradeVal = (json, val, isLabel) => {
    if (json === null || typeof json === "undefined") {
      return "unset";
    } else if (
      json[val] === null ||
      json[val] === "unset" ||
      typeof json[val] === "undefined"
    ) {
      return "unset";
    } else if (json[val] === 0 && isLabel) {
      return "Grade K";
    } else if (json[val] === -1 && isLabel) {
      return "Grade pre-K";
    } else if (!isLabel) {
      return json[val];
    }
    return "Grade " + json[val];
  };

  useEffect(() => {
    if (editLearner) {
      console.log(editLearner);
      if (editLearner.org_uid) {
        setLearnerID(editLearner.org_uid);
      }
      if (editLearner.user.first_name) {
        setFirstName(editLearner.user.first_name);
      }
      if (editLearner.user.last_name) {
        setLastName(editLearner.user.last_name);
      }
      if (editLearner.user.username) {
        setUsername(editLearner.user.username);
        setProposedUsername(editLearner.user.username);
      }
      setGradeLevel({
        label: getDefaultGradeVal(editLearner.grade_info, "grade_level", true),
        value: getDefaultGradeVal(editLearner.grade_info, "grade_level", false),
      });
      if (editLearner.orgunits) {
        setSchools(editLearner.orgunits);
      }
      if (editLearner.clever_synced) {
        setDisabled(true)
      }
    }

    //make all tab panels the same height on form save
    const tabPanels = document.querySelectorAll(".react-tabs__tab-panel");
    let maxHeight = 0;

    tabPanels.forEach((panel) => {
      const height = panel.clientHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    setMaxTabHeight(maxHeight);
  }, [editLearner]);

  const setGradeLevelWrapper = (changeType, newObj) => {
    setGradeLevel(newObj);
    setSaveDisabled(false);
  };

  if (editLearner === null) return null;

  return (
    <div className='pdf-upload'>
      {/* When we consolidate the popups, we will need to inline this so future
      inputs from Mui do not get overridden. */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "565px",
          zIndex: "1050",
        }}
      >
        <div className='common_border'>
          <div className='common_heading'>
            {deleteSuccess ? (
              <p>
                Learner Removed:{" "}
                {editLearner.user &&
                  editLearner.user.last_name +
                    ", " +
                    editLearner.user.first_name}
              </p>
            ) : (
              <p>
                Edit/Delete Learner:{" "}
                {editLearner.user &&
                  editLearner.user.last_name +
                    ", " +
                    editLearner.user.first_name}
              </p>
            )}
            <button
              autoFocus
              className='pdf_popup_close'
              onClick={() => setEditLearner(null)}
            >
              <FA icon='times' />
            </button>
          </div>

          {infoText && (
            <React.Fragment>
              <InfoPopup popupInfo={infoText} setShowPopupInfo={setInfoText} />
            </React.Fragment>
          )}

          {deleteSuccess && (
            <div className='common_dashboard_bg'>
              <div className='row'>
                <div className='col'>
                  <div className='alert alert-success'>
                    Successfully removed learner {classId ? "from class" : ""}
                  </div>

                  <button
                    onClick={() => {
                      setEditLearner(null);
                    }}
                    className='btn btn-lg fs-14 btn-primary form-control'
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          )}

          {!deleteSuccess && (
            <div className='common_dashboard_bg'>
              <Tabs>
                <TabList>
                  <Tab>User Info</Tab>
                  <Tab>Accommodations</Tab>
                </TabList>
                <TabPanel style={{ minHeight: maxTabHeight }}>
                  <div className='row'>
                    <div className='col'>
                      {shouldShowDeleteModal && (
                        <LearnerRowDeleteModal
                          shouldShowFunc={setShouldShowDeleteModal}
                          learnerId={editLearner.id}
                          callback={() => {
                            setDeleteSuccess(true);
                            refreshLearners();
                          }}
                          classId={classId}
                        />
                      )}

                      {error && (
                        <div className='alert alert-danger'>{error}</div>
                      )}
                      {success && (
                        <div className='alert alert-success'>{success}</div>
                      )}
                      {disabled && (
                        <div className='alert alert-warning'>
                          <FA icon='exclamation' className='mr-2' />
                          User info is synced from Clever and cannot be edited
                        </div>
                      )}
                    </div>

                    <div className='edit_info_fields'>
                      <TextField
                        name='First Name'
                        className='w-100'
                        type='text'
                        label='First Name'
                        placeholder='First Name'
                        variant='outlined'
                        value={firstName}
                        disabled={loading || disabled}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          setSaveDisabled(false);
                        }}
                      />
                      <TextField
                        name='Last Name'
                        className='w-100'
                        type='text'
                        label='Last Name'
                        placeholder='Last Name'
                        variant='outlined'
                        disabled={loading || disabled}
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setSaveDisabled(false);
                        }}
                      />
                      <TextField
                        name='Username'
                        className='w-100'
                        type='text'
                        label='Username'
                        placeholder='Username'
                        variant='outlined'
                        disabled={loading || disabled}
                        value={proposedUsername}
                        onChange={(e) => {
                          setProposedUsername(e.target.value.toLowerCase());
                          setSaveDisabled(false);
                        }}
                      />
                      <div>
                        <TextField
                          name='Learner ID'
                          className='w-100'
                          type='text'
                          label='Learner ID'
                          placeholder='Learner ID'
                          variant='outlined'
                          value={learnerID}
                          disabled={loading || disabled}
                          onChange={(e) => {
                            setLearnerID(e.target.value);
                            setSaveDisabled(false);
                          }}
                        />
                        <span
                          className='info-circle'
                          onClick={() => {
                            setInfoText({
                              header: "Learner ID (Optional)",
                              text: `Learner ID is the unique identification number students use in school.
                                This is an optional field,
                                but providing this information helps keep student records in our system up-to-date.
                                This is typically a student ID number and does not change from year to year.`,
                            });
                          }}
                        >
                          <FA icon='info-circle' className='mr-1' />
                          What's This?
                        </span>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <GradeDropdown
                          aria-label='Select Grade Level'
                          disabled={loading || disabled}
                          placeholder='Grade Level'
                          setterFunc={setGradeLevelWrapper}
                          value={gradeLevel}
                          changeType='grade'
                          nonstandardGradesEnabled={true}
                        />
                        <div style={{ marginTop: "5px" }}>
                          <MultiSchoolReactSelect
                            handleChange={handleSchoolChange}
                            value={schools}
                            disabled={user.usertype === "teacher" || disabled}
                            header='School(s)'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='edit_info_buttons'>
                    <div>
                      <button
                        onClick={handleSave}
                        disabled={
                          saveDisabled ||
                          loading ||
                          gradeLevel.value === "unset" ||
                          proposedUsername === ""
                        }
                        className='btn btn-lg fs-14 btn-primary form-control'
                      >
                        {loading ? (
                          <span>
                            <FA
                              color='white'
                              icon='spinner'
                              spin
                              className='mr-1'
                            />
                            Saving ...
                          </span>
                        ) : (
                          "Save Changes"
                        )}
                      </button>
                    </div>

                    <div>
                      <button
                        disabled={loading}
                        onClick={() => {
                          setEditLearner(null);
                        }}
                        className='btn btn-lg fs-14 btn-warning form-control'
                      >
                        {saveDisabled ? "Close" : "Cancel"}
                      </button>
                    </div>

                    <div>
                      <button
                        disabled={loading || disabled}
                        onClick={handleDelete}
                        className='btn btn-lg fs-14 btn-danger form-control'
                      >
                        Delete Learner {classId && "From Class"}
                      </button>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel style={{ minHeight: maxTabHeight }}>
                  <AccommodationInfoTab
                    editLearner={editLearner}
                    setEditLearner={setEditLearner}
                    api={api}
                    setInfoText={setInfoText}
                    setSaveDisabled={setSaveDisabled}
                    refreshLearners={refreshLearners}
                  />
                </TabPanel>
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(EditStudentModal);
