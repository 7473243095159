import { SHOWSIDEBAR, SHOWPASSWORDRESET, FORCEPASSWORDRESET, LESSONVIEWERTAB } from "../actionTypes";

const initialState = {
  showSidebar: false,
  showPasswordReset: false,
  forcePasswordReset: false,
  lessonViewerWindowRef: null,
}

const gui = (state = initialState, action) => {
  switch (action.type) {
    case SHOWSIDEBAR:
      return Object.assign({}, state, {
        showSidebar: action.payload
      })
    case SHOWPASSWORDRESET:
      return Object.assign({}, state, {
        showPasswordReset: action.payload
      })
    case FORCEPASSWORDRESET:
      return Object.assign({}, state, {
        forcePasswordReset: action.payload
      })
    case LESSONVIEWERTAB:
      return Object.assign({}, state, {
        lessonViewerWindowRef: action.payload
      })
    default:
      return state
  }
}

export default gui;
