import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withAPI from '../../services/api';
import FAW from '../../containers/faw';
import FA from '../../containers/fa';

// Also used for single add
const MergeOrgModal = ({ api, shouldShowFunc, triggerRefresh, mergeFrom, mergeTo}) => {
  const [shouldSpin, setShouldSpin] = useState(false);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const mergeOrgsSubmit = () => {
    setError(false);
    setSuccess(false);
    setErrMsg(null);
    setShouldSpin(true);
    console.log("merging organizations ", mergeFrom.id , "to org ", mergeTo.id)
    api.mergeOrganizations(mergeFrom.id, mergeTo.id).then((objects) => {
      setSuccess(true);
      triggerRefresh(true);
    })
    .catch((err) => {
      setErrMsg("Unknown error.");
      setError(true);
      console.log("Error merging organizations:  ",err)
    });
  }

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            Confirm Organization Merge            
            <div className="pdf_popup_close" onClick={() => shouldShowFunc(false)}><FA icon="times" /></div>
          </div>
          <div className="common_dashboard_bg">
            <div className="row">
              <div className="col-12">
                {error && <div className="alert alert-danger">{errMsg}</div>}

                {success &&
                  <div className="alert alert-success" role="alert">
                    Successfully merged the organizations!
                    <Link to={`/super/org/${mergeTo.id}`}> Click here </Link> to view the merged organization.
                  </div>
                }

                {(mergeFrom.id > 0 && mergeTo.id > 0) ?
                  <div className="">
                    <div className="mb-4">
                      <p>
                        About to merge organization
                      </p>
                      <h5><strong> {mergeFrom.name} </strong></h5>
                      <p>into</p>
                      <h5><strong> {mergeTo.name} </strong></h5>
                    </div>
                    {success ?
                      <div className="mt-4">
                        <button onClick={() => shouldShowFunc(false)} className="btn btn-sm btn-success" >Okay</button>
                      </div>
                    :
                      <div className="mt-4">
                        <button onClick={() => mergeOrgsSubmit()} className="btn btn-primary" >Confirm and Merge</button>
                        <button onClick={() => shouldShowFunc(false)} className="btn btn-danger ml-2" >Cancel</button>
                      </div>
                    }
                  </div>
                :
                  <div className="">
                    <div>
                      <p>Please select the two Organizations.</p>
                    </div>
                    <div className="mt-3">
                      <button onClick={() => shouldShowFunc(false)} className="btn btn-sm btn-primary" >Okay</button>
                    </div> 
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(
  withRouter,
  withAPI
)(MergeOrgModal);
