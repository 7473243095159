import React, {useState, useEffect} from "react";
import {compose} from "redux";
import withAPI from "../../services/api";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SettingsInfoPopup from "../UtilComps/SettingsInfoPopup";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const OrgSSOSettingsModal = ({
  api,
  orgId,
  orgCleverDistrictId,
  orgSettings,
  refreshOrgInfo,
  setShowModal,
}) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [saving, setSaving] = useState(false);
  const [orgUsesClever, setOrgUsesClever] = useState(false);
  const [editCleverId, setEditCleverId] = useState(false);
  const [newCleverId, setNewCleverId] = useState(orgCleverDistrictId);

  const defaultSettings = {
    allowed_sso_providers: [],
    default_sso_provider: null,
    disable_pwd_with_sso: null,
    login_with_password_allowed: null,
    allow_external_sso_link_init: null,
  };

  const boolOrNull = (val) => {
    if ([true, false].includes(val)) {
      return val;
    }
    return null;
  };

  useEffect(() => {
    let _settings = null;
    if (orgSettings) {
      _settings = {...orgSettings};
    } else {
      _settings = {...defaultSettings};
    }
    setAllowedSSOProviders(_settings.allowed_sso_providers || []);
    setDefaultSSOProvider(_settings.default_sso_provider || null);
    setDisablePwdWithSSO(boolOrNull(_settings.disable_pwd_with_sso));
    setLoginWithPwdAllowed(boolOrNull(_settings.login_with_password_allowed));
    setAllowExtSSOLinkInit(boolOrNull(_settings.allow_external_sso_link_init));

    if (orgSettings && orgSettings.allowed_sso_providers) {
      setOrgUsesClever(
        orgSettings.allowed_sso_providers.some((provider) => provider.includes('clever'))
      );
    }
  }, [orgSettings]);

  const [allowedSSOProviders, setAllowedSSOProviders] = useState([]);
  const [defaultSSOProvider, setDefaultSSOProvider] = useState(null);
  const [disablePwdWithSSO, setDisablePwdWithSSO] = useState(null);
  const [loginWithPwdAllowed, setLoginWithPwdAllowed] = useState(null);
  const [allowExtSSOLinkInit, setAllowExtSSOLinkInit] = useState(null);

  //const ssoChoices = ["clever"];
  const ssoChoices = [
    {value: 'clever_sso', label: 'Clever SSO'},
    {value: 'clever_securesync', label: 'Clever SecureSync'},
  ]
  const yesNoChoices = [
    {value: true, label: "Yes"},
    {value: false, label: "No"},
  ];

  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    header: "",
    text: "",
  });

  const handleSubmit = () => {
    setError(null);
    setSaving(true);
    setSuccess(false);

    const data = {
      settings: {
        allowed_sso_providers: allowedSSOProviders,
        default_sso_provider: defaultSSOProvider,
        disable_pwd_with_sso: disablePwdWithSSO,
        login_with_password_allowed: loginWithPwdAllowed,
        allow_external_sso_link_init: allowExtSSOLinkInit,
      },
    };

    api
      .patchOrganizationInfo(orgId, data)
      .then((resp) => {
        setSaving(false);
        setSuccess(true);
        refreshOrgInfo();
      })
      .catch((err) => {
        setSaving(false);
        setError("Something went wrong... Please try again later.");
        console.log("API error'd", err);
      });
  };

  const handleAllowedSSOChange = (e) => {
    console.log(e.target.value);
    setAllowedSSOProviders(e.target.value);
    if (e.target.value.length === 0) {
      setDefaultSSOProvider(null);
      setDisablePwdWithSSO(null);
      setLoginWithPwdAllowed(null);
      setAllowExtSSOLinkInit(null);
    }
  };

  const handleSetOrgCleverId = () => {
    setError(null);
    setSaving(true);
    setSuccess(false);
    let payload = {
      org_id: orgId,
      clever_id: newCleverId
    };
    api
      .setOrganizationCleverId(payload)
      .then((resp_json) => {
        console.log("Set new Clever district ID");
        setSaving(false);
        setSuccess(true);
        setEditCleverId(false);
        refreshOrgInfo();
      })
      .catch((e) => {
        console.log("Error setting Org's Clever District ID...");
        console.log(e);
        setSaving(false);
        setError("Something went wrong... Please try again later.");
      });
  };

  const getSSOHelpText = (settingChoice) => {
    if (settingChoice === "allowedSSOProviders") {
      return (
        <div>
          <strong>Allowed SSO Providers: </strong>
          <p className='mb-0'>
            This is a multi-select field (Note: As of now, Clever is the only
            available option).
          </p>
          <ul>
            <li>
              When no providers are selected, no "Invite SSO User" button would
              be available for Org Admins, and Users would not be able to link
              their accounts through SSO providers on their Settings page.
            </li>
            <li>
              When no providers are selected, all other fields below would be
              unset and disabled.
            </li>
            <li>
              When at least one provider is selected, this list determines which
              SSO providers are allowed to be linked to Users in this
              Organization.
            </li>
          </ul>
        </div>
      );
    } else if (settingChoice === "defaultSSOProvider") {
      return (
        <div>
          <strong>Default SSO Provider: </strong>
          <ul>
            <li>
              The Organization Welcome email (with the Activation Code) will be
              sent with a link for the recipient to follow and automatically be
              prompted to login using the selected SSO provider.
            </li>
            <li>
              This setting determines which SSO provider is selected by default
              in the "Invite SSO User" dialog box for Org Admins.
            </li>
          </ul>
        </div>
      );
    } else if (settingChoice === "loginWithPwdAllowed") {
      return (
        <div>
          <strong>Login With Password Allowed For New Users: </strong>
          <ul>
            <li>
              When set to <strong>Yes</strong>, the "Create User" button would
              be visible to Org Admins. The "Create User" button would create a
              User with Username and Password instead of via SSO.
            </li>
            <li>
              When set to <strong>No</strong>, the "Create User" button would
              not be visible to Org Admins.
            </li>
          </ul>
        </div>
      );
    } else if (settingChoice === "disablePwdWithSSO") {
      return (
        <div>
          <strong>
            Disable User Password When Linking External To Existing Users:{" "}
          </strong>
          <p className='mb-0'>
            This setting only affects an existing User account when linking an
            external SSO account. When set to <strong>Yes</strong>, linking an
            external account via SSO will disable the existing User's password.
          </p>
        </div>
      );
    } else if (settingChoice === "allowExtSSOLinkInit") {
      return (
        <div>
          <strong>Allow "Login with [Provider]" button to automatically link to existing Van Accounts: </strong>
          <p className='mb-0'>
            By default, an SSO user must link their SSO account to an existing
            Van account by using the Link SSO Account button on their Settings
            page. When set to <strong>Yes</strong>, the user will be able to bypass
            that step and automatically link their SSO account to an existing Van
            account with a matching email address by using the Login With Provider
            button.
          </p>
        </div>
      );
    }
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        {showPopupInfo && (
          <SettingsInfoPopup
            popupInfo={popupInfo}
            setShowPopupInfo={setShowPopupInfo}
          />
        )}

        <div className='common_border'>
          {/* Header row */}
          <div className='common_heading'>
            <p>SSO Settings</p>
            <ClosePopupBtn closePopupFunc={setShowModal} />
          </div>

          {/* Main body */}
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <div>
                  <table className='table table-sm'>
                    <tbody>
                      <tr>
                        <td className='item-label'>
                          Allowed SSO Providers
                          <span
                            onClick={() => {
                              setPopupInfo({
                                header: "SSO Settings Description",
                                html: getSSOHelpText("allowedSSOProviders"),
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FAW icon='info-circle' className='info-circle' />
                          </span>
                        </td>
                        <td>
                          <Select
                            variant='standard'
                            displayEmpty={true}
                            renderValue={(value) => {
                              if (value.length === 0) {
                                return "No providers selected.";
                              }
                              return value.join(", ");
                            }}
                            style={{width: "250px"}}
                            multiple={true}
                            value={allowedSSOProviders}
                            onChange={handleAllowedSSOChange}
                          >
                            {ssoChoices.map((choice, i) => {
                              return (
                                <MenuItem key={i} value={choice.value}>
                                  {allowedSSOProviders.includes(choice.value) ? (
                                    <span>
                                      {choice.label}
                                      <FAW
                                        icon='check'
                                        style={{color: "green"}}
                                        className='ml-4'
                                      />
                                    </span>
                                  ) : (
                                    <span>{choice.label}</span>
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </td>
                      </tr>

                      <tr>
                        <td className='item-label'>
                          Default SSO Provider
                          <span
                            onClick={() => {
                              setPopupInfo({
                                header: "SSO Settings Description",
                                html: getSSOHelpText("defaultSSOProvider"),
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FAW icon='info-circle' className='info-circle' />
                          </span>
                        </td>

                        <td>
                          <Select
                            variant='standard'
                            displayEmpty={true}
                            renderValue={(value) => {
                              if (!value) {
                                if (allowedSSOProviders.length > 0) {
                                  return "Not selected.";
                                } else {
                                  return "Please select Allowed SSOs.";
                                }
                              }
                              return value;
                            }}
                            style={{width: "250px"}}
                            value={
                              defaultSSOProvider === null
                                ? ""
                                : defaultSSOProvider
                            }
                            disabled={allowedSSOProviders.length === 0}
                            onChange={(e) => {
                              setDefaultSSOProvider(e.target.value);
                            }}
                          >
                            {allowedSSOProviders.map((choice, i) => {
                              return (
                                <MenuItem key={i} value={choice}>
                                  {choice}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </td>
                      </tr>

                      {orgUsesClever &&
                        <tr>
                          <td className='item-label'>
                            {"Clever District id:"}
                          </td>
                          {editCleverId ? (
                            <td>
                              <div
                                style={{
                                }}
                              >
                                <input
                                  type='text'
                                  placeholder={"Clever District ID"}
                                  value={newCleverId}
                                  onChange={(e) => setNewCleverId(e.target.value)}
                                />
                                <button
                                  className='btn btn-sm btn-primary ml-2'
                                  onClick={() => handleSetOrgCleverId()}
                                >
                                  <FA color='white' icon='save' />
                                </button>
                                <button
                                  className='btn btn-sm btn-primary ml-2'
                                  onClick={() => setEditCleverId(false)}
                                >
                                  <FA color='white' icon='times' />
                                </button>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "3px",
                                  padding: "3px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p>{orgCleverDistrictId}</p>
                                <button
                                  className='btn btn-sm btn-primary ml-2'
                                  onClick={() => setEditCleverId(true)}
                                >
                                  <FA color='white' icon='edit' />
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      }

                      <tr>
                        <td className='item-label'>
                          Login With Password Allowed For New Users
                          <span
                            onClick={() => {
                              setPopupInfo({
                                header: "SSO Settings Description",
                                html: getSSOHelpText("loginWithPwdAllowed"),
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FAW icon='info-circle' className='info-circle' />
                          </span>
                        </td>
                        <td>
                          <Select
                            variant='standard'
                            style={{width: "250px"}}
                            value={
                              loginWithPwdAllowed === null
                                ? ""
                                : loginWithPwdAllowed
                            }
                            disabled={allowedSSOProviders.length === 0}
                            onChange={(e) => {
                              setLoginWithPwdAllowed(e.target.value);
                            }}
                          >
                            {yesNoChoices.map((choice, i) => {
                              return (
                                <MenuItem key={i} value={choice.value}>
                                  {choice.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </td>
                      </tr>
                      <tr>
                        {/* Account field */}
                        <td className='item-label'>
                          Disable User Password When Linking External To
                          Existing Users
                          <span
                            onClick={() => {
                              setPopupInfo({
                                header: "SSO Settings Description",
                                html: getSSOHelpText("disablePwdWithSSO"),
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FAW icon='info-circle' className='info-circle' />
                          </span>
                        </td>
                        <td>
                          <Select
                            variant='standard'
                            style={{width: "250px"}}
                            value={
                              disablePwdWithSSO === null
                                ? ""
                                : disablePwdWithSSO
                            }
                            disabled={allowedSSOProviders.length === 0}
                            onChange={(e) => {
                              setDisablePwdWithSSO(e.target.value);
                            }}
                          >
                            {yesNoChoices.map((choice, i) => {
                              return (
                                <MenuItem key={i} value={choice.value}>
                                  {choice.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </td>
                      </tr>
                      <tr>
                        <td className='item-label'>
                          Automatically Link to Existing Users via Login
                          With Provider Button
                          <span
                            onClick={() => {
                              setPopupInfo({
                                header: "SSO Settings Description",
                                html: getSSOHelpText("allowExtSSOLinkInit"),
                              });
                              setShowPopupInfo(true);
                            }}
                          >
                            <FAW icon='info-circle' className='info-circle' />
                          </span>
                        </td>
                        <td>
                          <Select
                            variant='standard'
                            style={{width: "250px"}}
                            value={
                              allowExtSSOLinkInit === null
                                ? ""
                                : allowExtSSOLinkInit
                            }
                            disabled={allowedSSOProviders.length === 0}
                            onChange={(e) => {
                              setAllowExtSSOLinkInit(e.target.value);
                            }}
                          >
                            {yesNoChoices.map((choice, i) => {
                              return (
                                <MenuItem key={i} value={choice.value}>
                                  {choice.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </td>
                      </tr>

                      <tr></tr>
                    </tbody>
                  </table>

                  <div className='d-flex'>
                    {!saving && (
                      <button
                        className='btn btn-sm btn-primary mr-2'
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={
                          allowedSSOProviders.length > 0 &&
                          (defaultSSOProvider === null ||
                            loginWithPwdAllowed === null ||
                            disablePwdWithSSO === null)
                        }
                      >
                        Save Changes
                      </button>
                    )}
                    {saving && (
                      <button className='btn btn-sm btn-primary mr-2'>
                        <FA className='mr-2' icon='spinner' spin />
                        Saving ...
                      </button>
                    )}
                    <button
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>

                  {error && (
                    <div className='alert alert-danger my-2'>{error}</div>
                  )}

                  {success && (
                    <div className='alert alert-success my-2'>
                      SSO settings saved successfully!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI)(OrgSSOSettingsModal);
