import { ADD_ROW, CLEAR_ROWS, DELETE_ROW, UPDATE_ROW } from "../actionTypes";


const initialState = {
  addStudentRows: []
}

const addRow = (state, action) => {
  let addStudentRows = state.addStudentRows;
  let rowId = action.payload.id;
  addStudentRows.push({id: rowId, coppa: false, name: ""});
  return Object.assign({}, state, {
    addStudentRows: addStudentRows,
  })
}

const updateRow = (state, action) => {
  let addStudentRows = state.addStudentRows;
  let rowId = action.payload.id;
  addStudentRows.forEach((val, i, arr) => {
    if (val.id === rowId)
      arr[i] = action.payload
  })

  return Object.assign({}, state, {
    addStudentRows: addStudentRows,
  })
}


const deleteRow = (state, action) => {
  let addStudentRows = state.addStudentRows;
  let rowId = action.payload.id;
  addStudentRows = addStudentRows.filter((x) => (x.id !== rowId));
  return Object.assign({}, state, {
    addStudentRows: addStudentRows,
  })
}


const addStudents = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ROWS:
      let newStateObj = Object.assign({}, state);
      newStateObj.addStudentRows = [];
      return newStateObj;
    case ADD_ROW:
      return addRow(state, action);
    case DELETE_ROW:
      return deleteRow(state, action);
    case UPDATE_ROW:
      return updateRow(state, action);
    default:
      return state
  }
}

export default addStudents;
