import Chart from "chart.js/auto";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import withAPI from "../../services/api";
import "./UsageStatsRow.scss";
import { Bar, Line } from "react-chartjs-2";
import { formatLabel, formatTitle } from "./UsageStatsUtilities";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  userId: state.auth.userId,
  securityQPending: state.status.securityQPending,
});

const UsageStatsRow = ({
  typeOfData,
  data,
  setAvg,
  activeChart,
  showPopup,
  setPopupChartData,
  isPercent = true,
  chartType,
  user,
}) => {
  const [parsedData, setParsedData] = useState([0, 0, 0, 0, 0, 0]);

  const roundNum = (num) => {
    if (isPercent) {
      return Math.round(num * 100);
    } else {
      return Math.round(num);
    }
  };

  let bgColors = ["rgba(54, 162, 235, 0.2)", "rgba(54, 162, 235, 0.2)"];
  let borderColors = ["rgba(54, 162, 235, 1)", "rgba(54, 162, 235, 1)"];

  useEffect(() => {
    let vals = [];
    let tmp_val = null;
    console.log("DATA: ", data);
    for (let i = 0; i < data.length; i++) {
      let key =
        typeOfData === "RobotsPerc"
          ? "connected_robots_perc"
          : typeOfData === "TeachersPerc"
          ? "active_teachers_perc"
          : typeOfData === "LearnersPerc"
          ? "active_learners_perc"
          : typeOfData === "RobotsCount"
          ? "active_robots_count"
          : typeOfData === "LearnersCount"
          ? "active_learners_count"
          : "active_organizations_count"; // OrganizationsCount

      // skip criteria (if it is stat not collected from start)
      if (
        (key === "connected_robots_perc" &&
          data[i]["connected_robots_count"] === null) ||
        (key === "active_learners_perc" &&
          data[i]["active_learners_count"] === 0) ||
        (key === "active_teachers_perc" && data[i]["total_teachers"] === 0)
      ) {
        tmp_val = null;
      } else {
        tmp_val = roundNum(data[i][key]);
      }

      vals = [...vals, tmp_val];

      if (i === data.length - 1) {
        setAvg(roundNum(data[i][key]));
      }
    }
    setParsedData(vals);
  }, []);

  useEffect(() => {
    if (showPopup && activeChart === typeOfData) {
      setPopupChartData({
        typeOfData: typeOfData,
        parsedData: parsedData,
        chartCaption: getChartCaption(),
        chartYcaption: getChartYCaption(),
        labels: labels,
        datasets: [
          {
            label: getChartCaption(),
            barPercentage: 0.75,
            data: parsedData,
            backgroundColor: bgColors,
            borderColor: borderColors,
            uncroppedIndexList: Array(parsedData.length + 1)
              .fill()
              .map((_, idx) => idx),
          },
        ],
      });
    }
  }, [showPopup]);

  const getChartCaption = () => {
    if (typeOfData === "RobotsPerc") {
      return "Connected Robots Over Time";
    } else if (typeOfData === "RobotsCount") {
      return "Active Robots Over Time";
    } else if (typeOfData === "TeachersPerc") {
      return "Teachers Actively Using Robots Over Time";
    } else if (typeOfData === "LearnersPerc") {
      return "Active Learners Over Time";
    } else if (typeOfData === "LearnersCount") {
      return "Active Learners Over Time";
    } else {
      return "Active School Organizations Over Time";
    }
  };

  const getChartYCaption = () => {
    if (typeOfData === "RobotsPerc") {
      return [["% of all robots"], ["that have checked-in"]];
    } else if (typeOfData === "RobotsCount") {
      return "Number of active robots";
    } else if (typeOfData === "TeachersPerc") {
      return [["% of teachers"], ["actively using robots"]];
    } else if (typeOfData === "OrganizationsCount") {
      return "Number of active organizations";
    } else if (typeOfData === "LearnersCount") {
      return "Number of active learners";
    } else {
      return [["% of learners"], ["who have taken at least one lesson"]];
    }
  };

  const dataLabelsFormatter = (value) => {
    if (value === null) {
      return null;
    }
    if (typeOfData.indexOf("Perc") !== -1) {
      return `${value}%`;
    }
    return value;
  };

  const [labels, setLabels] = useState([]);
  const chartData = {
    labels:
      labels.length <= 12
        ? labels
        : JSON.parse(JSON.stringify(labels)).slice(
            labels.length - 12,
            labels.length
          ),
    datasets: [
      {
        label: getChartCaption(),
        data:
          parsedData.length <= 12
            ? parsedData
            : JSON.parse(JSON.stringify(parsedData)).slice(
                parsedData.length - 12,
                parsedData.length
              ),
        backgroundColor: bgColors,
        borderColor: borderColors,
        uncroppedIndexList: Array(
          // Data is cropped to the last 12 entries.
          // This represents the original indexes
          parsedData.length -
            (parsedData.length >= 12 ? parsedData.length - 12 : 0) +
            1
        )
          .fill()
          .map(
            (_, idx) =>
              (parsedData.length >= 12 ? parsedData.length - 12 : 0) + idx
          ),
      },
    ],
  };

  const optionsData = {
    responsive: true,
    maintainAspectRatio: false,
    radius: 7,
    pointHoverRadius: 10,
    lineTension: 0.5,
    hover: {
      mode: "index",
      intersect: false,
    },
    animation: {
      duration: 0,
    },
    title: {
      display: true,
      text: "text",
      fontSize: 20,
    },
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        anchor: "end",
        align: "top",
        formatter: dataLabelsFormatter,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: getChartCaption(),
        font: {
          size: 15,
        },
        padding: {
          top: 10,
          bottom: 35,
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: function (context) {
            return formatTitle(context);
          },
          label: function (context) {
            return formatLabel(user, data, context, typeOfData);
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Sliding 30-day Window of Use",
        },
      },
      y: {
        grid: {
          display: false,
          padding: 30,
        },
        title: {
          display: true,
          text: getChartYCaption(),
        },
        ticks: {
          callback: (yValue) => {
            if (isPercent) {
              return Math.round(yValue) + "%";
            } else {
              return Math.round(yValue);
            }
          },
        },
        beginAtZero: true,
        suggestedMax: 100,
      },
    },
  };

  useEffect(() => {
    let newLabels = [];
    for (let i = 0; i < data.length; i++) {
      let date = new Date(data[i]["created_at"]).toString();
      date = date.split(" ");
      newLabels = [...newLabels, date[1] + " " + date[2]];
    }
    setLabels(newLabels);
  }, [data]);

  return (
    <div
      className='usage_stats_row_container'
      style={{
        display: activeChart === typeOfData ? "block" : "none",
        height: activeChart === typeOfData ? "fit-content" : "0",
      }}
    >
      <div className='usage_stats_graph_container'>
        <div
          className='chart-container'
          style={{
            width: "100%",
            height: "100%",
            minHeight: "350px",
          }}
        >
          {chartType === "bar" ? (
            <Bar data={chartData} options={optionsData} />
          ) : (
            <Line data={chartData} options={optionsData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(UsageStatsRow);
