import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import withAPI from '../../services/api';
import FAW from '../../containers/faw';
import FA from '../../containers/fa';

import './RobotRow.scss';


const TeacherRow = ({ api, usr, refreshList, match }) => {
  const [delSpin, setDelSpin] = useState(false);

  const handleRemoval = () => {
    setDelSpin(true);
    api.removeRobotTagFromUser(match.params.tagId, usr.id)
    .then(() => {
      refreshList();
    });
  }

  return (
    <tr key={usr.id}>
      <td>
        <span>{usr.id && usr.first_name}</span>
      </td>
      <td>
        <span>{usr.id && usr.last_name}</span>
      </td>
      <td>
        <span>{usr.id && usr.username}</span>
      </td>
      <td name="Actions">
        <span>
          <div className="datatable_actions">
            <div className="d-flex align-items-start common-align-button action-btns">
                { !delSpin &&
                  <button data-title="Remove from Robot Tag" className="btn btn-md btn-danger" 
                    onClick={() => handleRemoval()} ><FAW icon="trash" /></button>
                }
                { delSpin &&
                  <button data-title="Remove from Robot Tag" className="btn btn-md btn-danger" 
                    onClick={() => handleRemoval()} ><FA icon="spinner" spin /></button>
                }
            </div>
          </div>
        </span>
      </td>
    </tr>
  )
}

export default compose(
  withAPI,
  withRouter
)(TeacherRow);
