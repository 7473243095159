import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FA from "../../../containers/fa";
import withAPI from "../../../services/api";
import AddRobotPopup from "./AddRobotPopup";
import RobotSection from "./RobotSection";
import "./SubRobot.scss";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});

const RenewalDetail = ({ renewalInfo, refreshRenewal }) => {
  const [showAddRobotPopup, setShowAddRobotPopup] = useState(false);
  const allowedCount =
    renewalInfo.new_subscription.subscriptionlimit_set[0].max_objects;
  const usedCount = renewalInfo.pending_robots.length;

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <p className='mb-1'>
            The robots below will be assigned the new subscription once this
            renewal takes effect.
          </p>
          <p className='mt-2'>
            A star icon (<FA color='#ffc107' icon='star' />) indicates the robot
            is from the original subscription(s)
          </p>
          {usedCount >= allowedCount ? (
            <p className='mb-1'>
              All of the slots in this renewal have been used up. Remove robots
              from the list below to add other robots into this renewal.
            </p>
          ) : (
            <>
              {usedCount <= 0 && (
                <p>
                  This subscription has been renewed but there are no robots
                  attached. Click the 'Add Robots' button below to assign robots
                  to this subscription.
                </p>
              )}
              <button
                className='btn btn-md btn-primary'
                disabled={usedCount >= allowedCount}
                data-title={
                  usedCount >= allowedCount ? "All slots filled" : null
                }
                onClick={() => {
                  setShowAddRobotPopup(true);
                }}
              >
                Add Robots to Renewal...
              </button>
            </>
          )}
          {showAddRobotPopup && (
            <AddRobotPopup
              objInfo={renewalInfo}
              parentType='renewal'
              refreshParent={refreshRenewal}
              setShowAddRobotPopup={setShowAddRobotPopup}
            />
          )}
          <div>
            {renewalInfo && (
              <RobotSection
                objInfo={renewalInfo}
                refreshParent={refreshRenewal}
                parentType='renewal'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(RenewalDetail);
