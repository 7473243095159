import html2canvas from "html2canvas";

import {
  setExpiredAndExpiringRobots,
  setHasRenewalsWithoutRobots,
} from "../redux/actions";
import config from "./config";

export const addElement2Pdf = (pdf, elementId, yOffset = 10) =>
  new Promise((resolve) => {
    // Helper function to add elements into a pdf page being generated
    const pdfXMargin = 15;
    let data = document.getElementById(elementId);
    html2canvas(data).then((canvas) => {
      const contentDataURL = canvas.toDataURL("image/png");
      const imgProps = pdf.getImageProperties(contentDataURL);
      const pageWidth = pdf.internal.pageSize.getWidth() - 2 * pdfXMargin;
      const pageHeight = pdf.internal.pageSize.getHeight();
      let pdfWidth = pageWidth;
      let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // check if we should add page
      if (yOffset + pdfHeight > pageHeight) {
        pdf.addPage();
        yOffset = 15;
      }

      pdf.addImage(
        contentDataURL,
        "PNG",
        pdfXMargin,
        yOffset,
        pdfWidth,
        pdfHeight
      );
      const nextYOffset = yOffset + pdfHeight + 10;
      resolve(nextYOffset);
    });
  });

export const addScrollingElement2Pdf = (pdf, elementId, yOffset = 10) =>
  new Promise((resolve) => {
    const pdfXMargin = 15;
    let data = document.getElementById(elementId);

    html2canvas(data, {
      width: data.scrollWidth,
      height: data.scrollHeight,
    }).then((canvas) => {
      const contentDataURL = canvas.toDataURL("image/png");
      const imgProps = pdf.getImageProperties(contentDataURL);
      const pageWidth = pdf.internal.pageSize.getWidth() - 2 * pdfXMargin;
      const pageHeight = pdf.internal.pageSize.getHeight();
      let pdfWidth = pageWidth;
      let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // check if we should add page
      if (yOffset + pdfHeight > pageHeight) {
        pdf.addPage();
        yOffset = 15;
      }

      pdf.addImage(
        contentDataURL,
        "PNG",
        pdfXMargin,
        yOffset,
        pdfWidth,
        pdfHeight
      );
      const nextYOffset = yOffset + pdfHeight + 10;
      resolve(nextYOffset);
    });
  });

export const getUserSubscriptions = ({ api }) => {
  return api
    .fetchUserSubscriptions()
    .then((res) => {
      return res.subscriptions;
    })
    .catch((err) => {
      console.log("Error fetching user subscriptions.");
      console.log(err);
    });
};

export const setRobotDataForBanners = ({ userType, orgId, api, dispatch }) => {
  const queryParams = {
    org_id: orgId,
  };

  api
    .fetchRobotDataForBanners(queryParams)
    .then((res) => {
      dispatch(
        setExpiredAndExpiringRobots({
          expiredRobots: res.expired_robots,
          expiringRobots: res.expiring_robots,
        })
      );

      if (
        [
          config.SUPERADMIN_USERTYPE,
          config.ORGADMIN_USERTYPE,
          config.ORGUNITADMIN_USERTYPE,
        ].includes(userType)
      ) {
        api
          .checkForRenewalsWithoutRobots(orgId)
          .then((res) => {
            dispatch(
              setHasRenewalsWithoutRobots(res.has_renewals_without_robots)
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
