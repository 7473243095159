import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import config from "../../common/config";
import {updateHasRobots} from "../../redux/actions";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  userId: state.auth.userId,
  hasRobots: state.auth.hasRobots,
});

const AssignRobotModal = ({
  user,
  userId,
  hasRobots,
  api,
  dispatch,
  setShowModal,
  assignedRobots,
  setAssignedRobots,
  assignUserId,
  refreshList,
}) => {
  // Based on whether assignUserId is null, "Assign" behaves differently
  // if assignUserId is null: we're in UserCreation modal and we pass the
  // assigned robots back to parent
  // else, we directly call the backend and assign the robots to the user with id assignUserId

  const [spin, setSpin] = useState(true);
  const [submitSpin, setSubmitSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [robots, setRobots] = useState(null);
  const [orgCloudStatus, setOrgCloudStatus] = useState(null);
  const [selectedRobots, setSelectedRobots] = useState(assignedRobots || []);

  useEffect(() => {
    api
      .fetchAllUnassignedVisibleRobots()
      .then((objects) => {
        setRobots(objects.results);
        setSpin(false);
        console.log("AssignRobotModal got robots: ", objects);
      })
      .catch(() => {
        setError(true);
        setSpin(false);
      });

    api
      .fetchUserOrgHasCloud()
      .then((data) => {
        console.log("Cloud Status: ", data);
        setOrgCloudStatus(data.cloud_status);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  const handleCheckboxChange = (serial) => {
    // given checkbox event e and serial number
    // add or remove serial from selectedRobots
    const selectedRobots_tmp = [...selectedRobots];
    if (!selectedRobots_tmp.includes(serial)) {
      selectedRobots_tmp.push(serial);
    } else {
      selectedRobots_tmp.splice(selectedRobots_tmp.indexOf(serial), 1);
    }
    setSelectedRobots(selectedRobots_tmp);
  };

  const handleCurrentOrgAdminSelfAssign = () => {
    // Update hasRobots in both sessionStorage and redux accordingly
    const hasRobotsTmp = {...hasRobots};
    hasRobotsTmp["user"] = true;
    if (hasRobotsTmp["user"] !== hasRobots["user"]) {
      sessionStorage.setItem("hasRobots", JSON.stringify(hasRobotsTmp));
      dispatch(updateHasRobots(hasRobotsTmp));
    }
  };

  const handleSubmitToParent = () => {
    setAssignedRobots(selectedRobots);
    setShowModal(false);
  };

  const handleSubmitToBackend = () => {
    setError(false);
    setSubmitSpin(true);
    api
      .assignTeacherToRobot(selectedRobots, assignUserId)
      .then((resp) => {
        // if org admin assigning to self
        if (
          assignUserId === userId &&
          user.usertype === config.ORGADMIN_USERTYPE
        ) {
          handleCurrentOrgAdminSelfAssign();
        }
        setSubmitSpin(false);
        setSuccess(true);
        refreshList();
      })
      .catch((e) => {
        setSubmitSpin(false);
        setError(true);
        console.log("Error'd:", e);
      });
  };

  const handleSubmit = () => {
    if (assignUserId) {
      handleSubmitToBackend();
    } else {
      handleSubmitToParent();
    }
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper-medium'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Assign Robots</p>
            <ClosePopupBtn closePopupFunc={setShowModal} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && (
                  <div className='alert alert-danger'>
                    Something went wrong... Please try again.
                  </div>
                )}

                {success && (
                  <div className='alert alert-success'>
                    Robots assigned successfully!
                  </div>
                )}

                {spin || orgCloudStatus === null || robots === null ? (
                  <div className='alert'>
                    <FA color='gray' icon='spinner' spin />
                    Loading robots ...
                  </div>
                ) : (
                  <div>
                    <div className='mb-3 table-scroll-wrapper'>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th>Wifi name</th>
                            <th>Serial</th>
                            {orgCloudStatus === "mix" && (
                              <th>Cloud Account?</th>
                            )}
                            <th>Assign</th>
                          </tr>
                        </thead>
                        <tbody>
                          {robots.length > 0 ? (
                            robots.map((rbt) => (
                              <tr key={rbt.serial}>
                                <td>{rbt.wifi}</td>
                                <td>{rbt.serial}</td>
                                {orgCloudStatus === "mix" && (
                                  <td>{rbt.cloud_accounts ? "Yes" : "No"}</td>
                                )}
                                <td>
                                  <input
                                    type='checkbox'
                                    name='rbt-checkbox'
                                    className='form-control'
                                    checked={selectedRobots.includes(
                                      rbt.serial
                                    )}
                                    onChange={() => {
                                      handleCheckboxChange(rbt.serial);
                                    }}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan='3'>
                                No unassigned robots available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {submitSpin && (
                      <div className='alert'>
                        <FA color='gray' icon='spinner' spin />
                        Assigning robots ...
                      </div>
                    )}

                    {!submitSpin && !success && (
                      <div className='d-flex'>
                        <button
                          disabled={assignUserId && selectedRobots.length === 0}
                          onClick={() => {handleSubmit()}}
                          className='btn btn-primary mr-4'
                        >
                          Confirm
                        </button>
                        <button
                          className='btn btn-danger'
                          onClick={() => {
                            setShowModal(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {success && (
                      <button
                        className='btn btn-success'
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        Okay
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(AssignRobotModal);
