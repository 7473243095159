import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import withAPI from "../../services/api";
import MainDashboard from "../../partials/Main";
import RobotRow from "../../components/ManageAllRobots/TaggedRobotRow.js";
import TeacherRow from "../../components/ManageAllRobots/TaggedTeacherRow.js";
import FA from "../../containers/fa";
import "./Dashboard.scss";

const RobotTagPage = ({ api, match }) => {
  const [error, setError] = useState(null);
  const [shouldSpin, setShouldSpin] = useState(true);
  const [tagInfo, setTagInfo] = useState({});
  const [robots, setRobots] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [expandRobots, setExpandRobots] = useState(true);
  const [expandTeachers, setExpandTeachers] = useState(true);
  const [refreshRobots, setRefreshRobots] = useState(false);

  const [nameEditable, setNameEditable] = useState(false);
  const [descEditable, setDescEditable] = useState(false);
  const [editableName, setEditableName] = useState("");
  const [editableDesc, setEditableDesc] = useState("");
  const [teacherSortType, setTeacherSortType] = useState("wifi");

  useEffect(() => {
    api
      .fetchRobotTagInfo(match.params.tagId)
      .then((resp) => {
        setTagInfo(resp);
        setEditableName(resp.name);
        setEditableDesc(resp.desc);
        console.log("RobotTag info: ", tagInfo);
      })
      .catch((err) => {
        setShouldSpin(false);
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    api
      .fetchRobotTagAssociations(match.params.tagId)
      .then((resp) => {
        setRobots(resp.robots);
        console.log("RobotTag page got robots: ", robots);
        const sorted = sortedArray(resp.teachers, teacherSortType);
        setTeachers(sorted);
        console.log("RobotTag page got teachers: ", teachers);
        setShouldSpin(false);
        setTeacherSortType("username");
      })
      .catch((err) => {
        setShouldSpin(false);
        setError(err.message);
      });
  }, [refreshRobots]);

  function refreshRobotList() {
    setRefreshRobots(!refreshRobots);
  }
  function refreshTeacherList() {
    setRefreshRobots(!refreshRobots);
  }

  const sortedArray = (arr, colname) => {
    return [...arr].sort((a, b) => b[colname] - a[colname]);
  };

  useEffect(() => {
    const sorted = sortedArray(teachers, teacherSortType);
    setTeachers(sorted);
  }, [teacherSortType]);

  const saveNewName = (e) => {
    api.patchRobotTagInfo(tagInfo.id, { name: editableName }).then(() => {
      let newTag = tagInfo;
      newTag.name = editableName;
      setTagInfo(newTag);
      setNameEditable(false);
    });
  };
  const saveNewDesc = (e) => {
    api
      .patchRobotTagInfo(tagInfo.id, { description: editableDesc })
      .then(() => {
        let newTag = tagInfo;
        newTag.description = editableDesc;
        setTagInfo(newTag);
        setDescEditable(false);
      });
  };

  return (
    <div>
      <MainDashboard>
        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <p>
              Robot Tags > <b>{tagInfo.name}</b>
            </p>
            <div className='d-flex justify-content-end'>
              <Link className='btn btn-sm btn-primary' to='/robots'>
                Back
              </Link>
            </div>
          </div>

          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <div className='row mb-10'></div>
                {error && (
                  <div className='alert alert-danger' role='alert'>
                    {error}
                  </div>
                )}
                {shouldSpin && (
                  <div>
                    <h4>
                      <FA color='gray' icon='spinner' spin /> Loading Tag
                      Associations...
                    </h4>
                  </div>
                )}

                {!shouldSpin && (
                  <div>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <td>
                            <b>Name:</b>
                          </td>
                          <td>
                            {nameEditable ? (
                              <input
                                type='text'
                                placeholder={tagInfo.name}
                                onChange={(e) =>
                                  setEditableName(e.target.value)
                                }
                                value={editableName}
                              />
                            ) : (
                              <p>{tagInfo.id && tagInfo.name}</p>
                            )}
                          </td>
                          <td>
                            <span>
                              {!nameEditable && (
                                <button
                                  className='btn btn-md btn-secondary'
                                  onClick={() => setNameEditable(true)}
                                >
                                  <FA color='white' icon='edit' />
                                </button>
                              )}
                              {nameEditable && (
                                <span>
                                  <button
                                    className='btn btn-md btn-secondary'
                                    onClick={() => saveNewName()}
                                  >
                                    <FA color='white' icon='save' />
                                  </button>
                                  <button
                                    className='btn btn-md btn-secondary'
                                    onClick={() => setNameEditable(false)}
                                  >
                                    <FA color='white' icon='times' />
                                  </button>
                                </span>
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>
                              <b>Description:</b>
                            </span>
                          </td>
                          <td>
                            {descEditable ? (
                              <input
                                type='text'
                                placeholder={tagInfo.description}
                                onChange={(e) =>
                                  setEditableDesc(e.target.value)
                                }
                                value={editableDesc}
                              />
                            ) : (
                              <p>{tagInfo.id && tagInfo.description}</p>
                            )}
                          </td>
                          <td>
                            <span>
                              {!descEditable && (
                                <button
                                  className='btn btn-md btn-secondary'
                                  onClick={() => setDescEditable(true)}
                                >
                                  <FA color='white' icon='edit' />
                                </button>
                              )}
                              {descEditable && (
                                <span>
                                  <button
                                    className='btn btn-md btn-secondary'
                                    onClick={() => saveNewDesc()}
                                  >
                                    <FA color='white' icon='save' />
                                  </button>
                                  <button
                                    className='btn btn-md btn-secondary'
                                    onClick={() => setDescEditable(false)}
                                  >
                                    <FA color='white' icon='times' />
                                  </button>
                                </span>
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className='row d-flex justify-content-between align-items-left'>
                      <div className='row'>
                        <h5>Associated Robots</h5>
                        {!expandRobots && (
                          <button
                            className='btn btn-sm'
                            onClick={() => setExpandRobots(!expandRobots)}
                          >
                            <FA icon='plus' color='black' />
                          </button>
                        )}
                        {expandRobots && (
                          <button
                            className='btn btn-sm'
                            onClick={() => setExpandRobots(!expandRobots)}
                          >
                            <FA icon='minus' color='black' />
                          </button>
                        )}
                      </div>
                      <div className='d-flex align-items-left'>
                        {expandRobots && (
                          <Link to={"/robottag/" + tagInfo.id + "/add-robots"}>
                            <button className='btn btn-secondary'>
                              Add Robots
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                    {expandRobots && (
                      <div className='card'>
                        <div
                          className='progress-linear school_listing manage_learner_table'
                          role='progressbar'
                        >
                          <table className='table table-striped'>
                            <thead>
                              <tr>
                                <th className='datatable-header-cell sortable ng-star-inserted'>
                                  Wifi name
                                </th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {robots.map((rbt) => (
                                <RobotRow
                                  key={rbt.serial}
                                  rbt={rbt}
                                  refreshList={refreshRobotList}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {!shouldSpin && (
                  <div>
                    <div className='row d-flex justify-content-between align-items-left'>
                      <div className='row'>
                        <h5>Associated Teachers</h5>
                        {!expandTeachers && (
                          <button
                            className='btn btn-sm'
                            onClick={() => setExpandTeachers(!expandTeachers)}
                          >
                            <FA icon='plus' color='black' />
                          </button>
                        )}
                        {expandTeachers && (
                          <button
                            className='btn btn-sm'
                            onClick={() => setExpandTeachers(!expandTeachers)}
                          >
                            <FA icon='minus' color='black' />
                          </button>
                        )}
                      </div>
                      <div className='d-flex align-items-left'>
                        {expandRobots && (
                          <Link
                            to={"/robottag/" + tagInfo.id + "/add-teachers"}
                          >
                            <button className='btn btn-secondary'>
                              Add Teachers
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                    {expandTeachers && (
                      <div className='card'>
                        <div
                          className='progress-linear school_listing manage_learner_table'
                          role='progressbar'
                        >
                          <table className='table table-striped'>
                            <thead>
                              <tr>
                                <th className='datatable-header-cell sortable ng-star-inserted'>
                                  First name
                                </th>
                                <th>Last name</th>
                                <th>username</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {teachers.map((usr) => (
                                <TeacherRow
                                  key={usr.id}
                                  usr={usr}
                                  refreshList={refreshTeacherList}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MainDashboard>
    </div>
  );
};

export default compose(withAPI)(RobotTagPage);
