import React, {useState, useEffect} from "react";
import "../Carousels.scss";
import "./LearnerByTimeCarousel.scss";
import {Bar} from "react-chartjs-2";
import FA from "../../../../containers/fa";
import BarChartShadowSvg from "../../BarChartChadowSvg";
const LearnerByTimeCarousel = ({
  profileStats,
  activeLearner,
  ready,
  profileStatsLoaded,
}) => {
  const [parsedWeekdaysData, setParsedWeekdaysData] = useState([]);
  const [parsedDaytimeData, setParsedDaytimeData] = useState([]);
  const [currentChart, setCurrentChart] = useState("weekdays");

  const roundNum = (num) => {
    return Math.round(num * 100);
  };

  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // Translates to keys from backend
  const weekDayDict = {
    Mon: "mon",
    Tue: "tues",
    Wed: "wed",
    Thu: "thurs",
    Fri: "fri",
    Sat: "sat",
    Sun: "sun",
  };

  const timePeriods = ["Morning", "Afternoon", "Evening"];
  // Translates to keys from backend
  const timePeriodsDict = {
    Morning: "morning",
    Afternoon: "afternoon",
    Evening: "evening",
  };

  const weekdaysChartData = {
    labels: weekDays,
    datasets: [
      {
        label: "Performance",
        data: parsedWeekdaysData,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
      },
    ],
  };

  const daytimeChartData = {
    labels: ["Morning", "Afternoon", "Evening"],
    datasets: [
      {
        label: "Performance",
        data: parsedDaytimeData,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
      },
    ],
  };

  useEffect(() => {
    if (!profileStats || profileStats.empty) return;
    setParsedWeekdaysData(
      weekDays.map(
        (x) => roundNum(profileStats.perf_by_day[weekDayDict[x]]) || 0
      )
    );
    setParsedDaytimeData(
      timePeriods.map(
        (x) =>
          roundNum(profileStats.perf_by_time_of_day[timePeriodsDict[x]]) || 0
      )
    );
  }, [profileStats]);

  return (
    <div
      className='carousel_container carousel_charts'
      style={{gridArea: "b", width: "100%"}}
    >
      {activeLearner && profileStats && !profileStats.empty ? (
        <div>
          <div className='carousel_charts_grid'>
            <Bar
              className='carousel_chart weekdays_chart'
              data={weekdaysChartData}
              style={{left: currentChart === "weekdays" ? "0px" : "100%"}}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    ticks: {
                      callback: (yValue) => {
                        return Math.round(yValue) + "%";
                      },
                      beginAtZero: true,
                      suggestedMax: 100,
                    },
                  },
                },
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.dataset.label + ": " + context.parsed.y + "%";
                      },
                    },
                  },
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: "Performance by Day of Week",
                    font: {
                      size: 12,
                    },
                  },
                },
              }}
            />

            <Bar
              className='carousel_chart daytime_chart'
              data={daytimeChartData}
              style={{left: currentChart === "daytime" ? "0px" : "-100%"}}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    ticks: {
                      callback: (yValue) => {
                        return Math.round(yValue) + "%";
                      },
                    },
                    beginAtZero: true,
                    suggestedMax: 100,
                  },
                },
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.dataset.label + ": " + context.parsed.y + "%";
                      },
                    },
                  },
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: "Performance by Time of Day",
                    font: {
                      size: 12,
                    },
                  },
                },
              }}
            />
          </div>
          <div className='carousel_btns_container'>
            <button
              className={`carousel_btn ${
                currentChart === "weekdays" ? "selected" : "unselected"
              }`}
              onClick={() => setCurrentChart("weekdays")}
            />
            <button
              className={`carousel_btn ${
                currentChart === "daytime" ? "selected" : "unselected"
              }`}
              onClick={() => setCurrentChart("daytime")}
            />
          </div>
        </div>
      ) : !ready | !profileStatsLoaded && activeLearner ? (
        <div className='default_carousel'>
          <BarChartShadowSvg />
          <p>
            <FA color='gray' icon='spinner' spin /> Loading...
          </p>
        </div>
      ) : activeLearner && !profileStats ? (
        <div className='default_carousel'>
          <BarChartShadowSvg/>
          <p>No data available.</p>
        </div>
      ) : !activeLearner ? (
        <div className='default_carousel'>
          <BarChartShadowSvg/>
          <p>Select a learner.</p>
        </div>
      ) : (
        <div className='default_carousel'>
          <BarChartShadowSvg/>
        </div>
      )}
    </div>
  );
};

export default LearnerByTimeCarousel;
