import React from "react";
import FA from "../../containers/fa";

const MessageModal = ({error, success, clearMessages}) => {
  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>
              {success && "Success!"}
              {error && "Error"}
            </p>
            <button
              className='pdf_popup_close'
              onClick={() => {
                clearMessages();
              }}
            >
              <FA icon='times' />
            </button>
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && <div className='alert alert-danger'>{error}</div>}

                {success && (
                  <div className='alert alert-success'>{success}</div>
                )}

                <button
                  onClick={() => {
                    clearMessages();
                  }}
                  className='btn btn-primary mr-4'
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
