import React, { useState } from "react";
import Select from "react-select";
import { filterLessonsBySubjectUnit } from "../ConsolidatedReportsUtilities";

const ReportsGroupedLessonSelect = ({
  lessons,
  changeHandler,
  selectedLesson,
  activeSubject,
  activeUnit,
  ready,
}) => {
  const [activeGroup, setActiveGroup] = useState("");

  let lessonsCopy = lessons;

  if (!lessons) return null;

  let all_options = [];
  lessons.forEach((v) => {
    if (v["options"]) {
      all_options = all_options.concat(v["options"]);
    }
  });

  lessonsCopy = filterLessonsBySubjectUnit(lessons, activeSubject, activeUnit);

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    fontSize: 16,
    color: "#666",
    fontWeight: "bold",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const customStyles = {
    option: (styles, { data, isDisabled, selectProps, isSelected }) => {
      return {
        ...styles,
        display:
          activeGroup.toUpperCase() === data.group.toUpperCase()
            ? "block"
            : "none",
      };
    },
  };

  const formatGroupLabel = (data) => (
    <div
      style={groupStyles}
      onClick={() => {
        setActiveGroup(data.label);
      }}
    >
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <Select
      aria-label='Select a lesson'
      isSearchable={false}
      formatGroupLabel={formatGroupLabel}
      options={lessonsCopy}
      styles={customStyles}
      isDisabled={!ready}
      onChange={(selected) => {
        changeHandler(selected.value, selected);
      }}
      value={all_options.filter((option) => option.value === selectedLesson)}
      placeholder='Select a lesson'
    />
  );
};

export default ReportsGroupedLessonSelect;
