import React from "react";
const NoGroupedLessonsErrMsg = () => {
  return (
    <div
      className='alert alert-warning'
      style={{margin: "5px", height: "fit-content"}}
      role='alert'
    >
      <b>{`This learner has no lessons.`}</b>
      <br />
      {`When learners take lessons with ABii, synced results will be available
      on this page. If your learner has already taken lessons with ABii,
      please make sure their progress is synced to the cloud and try again.`}
    </div>
  );
};

export default NoGroupedLessonsErrMsg;
