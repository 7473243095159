import React, { useState, useEffect } from "react";
import "./LearnerOverTimeBarChart.scss";
import withAPI from "../../../../services/api";
import { Bar } from "react-chartjs-2";
import FA from "../../../../containers/fa";
import BarChartShadowSvg from "../../BarChartChadowSvg";

const LearnerOverTimeBarChart = ({ activeLearner, ready, error, api }) => {
  const [learnerReports, setLearnerReports] = useState([]);
  const [parsedPerformData, setParsedPerformData] = useState([]);
  const [parsedAttnData, setParsedAttnData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [standards, setStandards] = useState([]);
  const [units, setUnits] = useState([]);
  const [courses, setCourses] = useState([]);
  const [learnerReportsErr, setLearnerReportsErr] = useState(null);
  const [fetchingLearnerInfo, setFetchingLearnerInfo] = useState(false);
  const roundNum = (num) => {
    return Math.round(num * 100);
  };

  const parseXlabel = (x, full = false) => {
    let date = x.date.split("-");
    let newDate = [date[1], date[2], date[0]].join("-");

    let str = x.course
      ? `(${x.course}) ` + x.name + " " + newDate
      : x.name + " " + newDate;
    if (full === false) {
      // Remove leading course in parentheses with regex
      str = str.replace(/^\([^)]*\)/, "").trim();
    }
    return str;
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Performance",
        standards: standards,
        courses: courses,
        units: units,
        data: parsedPerformData,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
      },
      {
        label: "Attention",
        standards: standards,
        courses: courses,
        units: units,
        data: parsedAttnData,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
      },
    ],
  };

  useEffect(() => {
    if (learnerReports && learnerReports.length > 0) {
      setLabels(learnerReports.map((x) => parseXlabel(x)));
      setStandards(learnerReports.map((x) => x.standard));
      setCourses(learnerReports.map((x) => x.course));
      setUnits(learnerReports.map((x) => x.unit));
      setParsedPerformData(learnerReports.map((x) => roundNum(x.performance)));
      setParsedAttnData(learnerReports.map((x) => roundNum(x.attention)));
    }
  }, [learnerReports]);

  useEffect(() => {
    setLearnerReportsErr(null);
    if (!activeLearner) return;
    setFetchingLearnerInfo(true);

    api
      .fetchLearnerReportsForLearner(activeLearner.sid)
      .then((learnerReports) => {
        setLearnerReports(learnerReports);
        setFetchingLearnerInfo(false);
      })
      .catch((e) => {
        console.log(e);
        setLearnerReportsErr(e);
        setFetchingLearnerInfo(false);
      });
  }, [activeLearner]);

  return (
    <div id='learner_over_time_container'>
      <h4>
        <b>Learner Performance + Attention Over Time (All Sessions)</b>
      </h4>

      {learnerReports &&
      learnerReports.length !== 0 &&
      activeLearner &&
      !error &&
      !learnerReportsErr &&
      ready &&
      !fetchingLearnerInfo ? (
        <div className='row'>
          <div className='col-12'>
            <div className='chartWrapper'>
              <div className='chartAreaWrapper visible-scrollbar'>
                <div
                  className='chartAreaWrapper2'
                  style={{
                    minWidth: "100%",
                    width: learnerReports.length * 80 + "px",
                    height: "250px",
                  }}
                >
                  <Bar
                    data={chartData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        datalabels: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                            title: function (context) {
                              let indexOfX = context[0].parsed.x;
                              let dataset = context[0].dataset;
                              let course = dataset.courses[indexOfX];
                              let label = context[0].label;
                              let unit = dataset.units[indexOfX];
                              let standard = dataset.standards[indexOfX];
                              const barInfo = [`(${course}) ${label}`, unit];
                              // We don't have actual standards for SEL courses
                              if (course && !course.startsWith("SEL")) {
                                barInfo.push(standard);
                              }
                              return barInfo;
                            },
                            label: function (context) {
                              return (
                                context.dataset.label +
                                ": " +
                                context.parsed.y +
                                "%"
                              );
                            },
                          },
                        },
                      },
                      scales: {
                        y: {
                          ticks: {
                            callback: (yValue) => {
                              return Math.round(yValue) + "%";
                            },
                          },
                          beginAtZero: true,
                          suggestedMax: 100,
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : fetchingLearnerInfo || !ready ? (
        <div
          id='learner_over_time_chart_container'
          style={{ backgroundColor: "white" }}
        >
          <p>
            <FA color='gray' icon='spinner' spin /> Loading...
          </p>
        </div>
      ) : !activeLearner ? (
        <div id='learner_over_time_chart_container'>
          <BarChartShadowSvg />

          <p>Select a learner.</p>
        </div>
      ) : learnerReports && learnerReports.length === 0 ? (
        <div id='learner_over_time_chart_container'>
          <BarChartShadowSvg />

          <p>No lessons taken.</p>
        </div>
      ) : error || learnerReportsErr ? (
        <div id='learner_over_time_chart_container'>
          <BarChartShadowSvg />
          <p>An error occurred. Please try again later.</p>
        </div>
      ) : null}
    </div>
  );
};

export default withAPI(LearnerOverTimeBarChart);
