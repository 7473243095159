import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import FA from "../../containers/fa";
import { setHasRenewalsWithoutRobots } from "../../redux/actions";
import withAPI from "../../services/api";

const UnassignDecoupleRobotModal = ({
  api,
  rbtSerial,
  useCase,
  shouldShowFunc,
  parentObjId,
  handleConfirmSuccess,
  handleRemoveSuccess,
  dispatch,
  user,
}) => {
  const [success, setSuccess] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [decoupleData, setDecoupleData] = useState(false);

  const handleRemove = () => {
    setClicked(true);
    setError(false);
    setErrMsg(null);
    setSuccess(null);
    if (useCase === "subscription") {
      const postData = {
        object_list: [rbtSerial],
        subscription_id: parentObjId,
        object_type: "robot",
        action: "remove",
      };
      api
        .manageSubscriptionObjects(postData)
        .then((r) => {
          setSuccess(
            "Robot removed from subscription! Refreshing robot list ..."
          );
          handleRemoveSuccess();

          api
            .checkForRenewalsWithoutRobots(user.organization.id)
            .then((res) => {
              dispatch(
                setHasRenewalsWithoutRobots(res.has_renewals_without_robots)
              );
            });
        })
        .catch((e) => {
          setError(true);
          setErrMsg("Something went wrong ...");
          console.log("Error unassigning robot from subscription...");
          console.log(e);
          setClicked(false);
        });
    } else if (useCase === "renewal") {
      const patchData = {
        robots: [rbtSerial],
        action: "remove",
      };
      api
        .patchRenewalInfo(parentObjId, patchData)
        .then((r) => {
          setSuccess("Robot removed from renewal! Refreshing robot list ...");
          handleRemoveSuccess();
        })
        .catch((e) => {
          setError(true);
          setErrMsg("Something went wrong ...");
          console.log("Error unassigning robot from renewal...");
          console.log(e);
          setClicked(false);
        });
    } else if (useCase === "unassign") {
      api
        .reassignRobot(rbtSerial, null, decoupleData)
        .then((r) => {
          setSuccess("Robot unassigned!");
        })
        .catch((e) => {
          setError(true);
          setErrMsg("Something went wrong ...");
          console.log("Error unassigning robot...");
          console.log(e);
          setClicked(false);
        });
    } else if (useCase === "decouple") {
      api
        .decoupleRobotData(rbtSerial, true)
        .then((r) => {
          setSuccess("Robot data decoupled!");
        })
        .catch((e) => {
          setError(true);
          setErrMsg("Something went wrong ...");
          console.log("Error decoupling robot data...");
          console.log(e);
          setClicked(false);
        });
    }
  };

  let confirmBtnText = "";
  if (useCase === "unassign") {
    confirmBtnText = "Yes, unassign this robot";
  } else if (useCase === "decouple") {
    confirmBtnText = "Yes, decouple data from robot";
  } else if (useCase === "subscription") {
    confirmBtnText = "Yes, remove from subscription";
  } else if (useCase === "renewal") {
    confirmBtnText = "Yes, remove from renewal";
  }

  return (
    <div className='pdf-upload'>
      <div className='pdf-upolad-wrapper' style={{ display: "block" }}>
        <div className='common_border'>
          <div className='common_heading' style={{ margin: "0px" }}>
            {useCase === "decouple" ? (
              <p>Decouple Robot Data</p>
            ) : (
              <p>Unassign Robot</p>
            )}
            <button
              className='pdf_popup_close'
              onClick={() => shouldShowFunc(false)}
            >
              <FA icon='times' />
            </button>
          </div>

          <div
            className='reset_pw_modal common_dashboard_bg'
            style={{ margin: "0px" }}
          >
            <div id='info_popup_form_content' className='form-content'>
              {error && <div className='alert alert-danger'>{errMsg}</div>}

              {useCase === "decouple" && (
                <p>
                  Are you sure you want to decouple synced learner data from
                  this robot?
                </p>
              )}
              {useCase === "unassign" && (
                <p>Are you sure you want to unassign this robot?</p>
              )}
              {useCase === "subscription" && !success && (
                <p>
                  Are you sure you want to unassign this robot from this
                  subscription?
                </p>
              )}
              {useCase === "renewal" && !success && (
                <p>
                  Are you sure you want to unassign this robot from this
                  renewal?
                </p>
              )}

              {(useCase === "decouple" || useCase === "unassign") && (
                <div className='row alert alert-warning'>
                  {useCase === "unassign" && (
                    <>
                      <strong>
                        Also decouple existing data already synced to cloud:
                      </strong>
                      <input
                        className='ml-2'
                        type='checkbox'
                        checked={decoupleData}
                        onChange={() => {
                          setDecoupleData(!decoupleData);
                        }}
                      />
                      <p>
                        Checking this box will reset all usage stats specific to
                        this robot (lessons taken, learners count, etc.).
                        Consider checking the box if the robot is:
                      </p>
                    </>
                  )}

                  {useCase === "decouple" && (
                    <p>
                      This action will reset all usage stats specific to this
                      robot (lessons taken, learners count, etc.). Consider this
                      action if the robot is:
                    </p>
                  )}

                  <ul>
                    <li>A Cloud Accounts robot being returned, or;</li>
                    <li>
                      A Local Accounts robot being returned without a
                      replacement, or;
                    </li>
                    <li>
                      Being removed from a Team Van org and sent to a customer
                      as new robot.
                    </li>
                  </ul>
                  <p>
                    If this is a Local Accounts robot for which we are going to
                    send a replacement robot, please consult with the tech team
                    on necessary steps.
                  </p>
                </div>
              )}

              {success ? (
                <div>
                  <div className='row alert alert-success'>{success}</div>

                  {/* subscription and renewal will refresh parent,
                    after which the popup would be cleared */}
                  {(useCase === "unassign" || useCase === "decouple") && (
                    <div className='row'>
                      <button
                        onClick={handleConfirmSuccess}
                        className='btn btn-sm btn-primary'
                      >
                        Okay
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className='row'>
                  <button
                    disabled={clicked}
                    onClick={handleRemove}
                    className='btn btn-sm btn-danger'
                  >
                    {confirmBtnText}
                  </button>
                  <button
                    onClick={() => shouldShowFunc(false)}
                    className=' btn btn-sm btn-primary ml-2'
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, withAPI)(UnassignDecoupleRobotModal);
