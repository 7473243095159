import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import config from "../../common/config";
const ManageTeachersFilters = ({
  filterString,
  setFilterString,
  filterSchool,
  setFilterSchool,
  filterSubscription,
  setFilterSubscription,
  filterUserrole,
  setFilterUserrole,
  orgunits,
}) => {
  const resetFilters = () => {
    setFilterString("");
    setFilterSchool("");
    setFilterSubscription("");
    setFilterUserrole("");
  };

  return (
    <div className='table-filter-wrapper p-3 w-100 mb-2'>
      <div id='filters_fields_users_container'>
        <h5 style={{ marginBottom: "0px" }}>Filters:</h5>

        <TextField
          margin='normal'
          placeholder='Search ...'
          name='search'
          size='medium'
          value={filterString || ""}
          onChange={(e) => setFilterString(e.target.value.toLowerCase())}
        />

        <FormControl style={{ minWidth: "120px" }}>
          <InputLabel id='school-filter-label'>School:</InputLabel>
          <Select
            labelId='school-filter-label'
            id='school-filter-select'
            value={filterSchool || ""}
            onChange={(e) => {
              setFilterSchool(e.target.value);
            }}
          >
            <MenuItem value={""}>All</MenuItem>
            {orgunits.map((value, index) => (
              <MenuItem key={index} value={value.id}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ minWidth: "120px" }}>
          <InputLabel id='subscriptions-filter-label'>
            Subscriptions:
          </InputLabel>
          <Select
            labelId='subscriptions-filter-label'
            id='subscriptions-filter-select'
            value={filterSubscription || ""}
            onChange={(e) => {
              setFilterSubscription(e.target.value);
            }}
          >
            <MenuItem value={""}>All</MenuItem>
            {config.ACCOUNT_SUBSCRIPTION_FILTERS.map((value, index) => (
              <MenuItem key={index} value={value}>
                {config.SUBSCRIPTION_TYPE_DISPLAY_NAMES[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ minWidth: "120px" }}>
          <InputLabel id='userrole-filter-label'>User Role:</InputLabel>
          <Select
            labelId='userrole-filter-label'
            id='userrole-filter-select'
            value={filterUserrole || ""}
            onChange={(e) => {
              setFilterUserrole(e.target.value);
            }}
          >
            <MenuItem value={""}>All</MenuItem>
            {config.USERROLES.map((role) => (
              <MenuItem key={role.id} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <button
          className='btn btn-primary btn-sm mt-3 reset_filters_btn'
          style={{ justifySelf: "baseline" }}
          onClick={() => {
            resetFilters();
          }}
        >
          Reset Filters
        </button>
      </div>
    </div>
  );
};

export default ManageTeachersFilters;
