import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { useDebounce } from "use-debounce";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import "./AddStudentsModal.scss";

import StudentNameTextField from "./StudentNameTextField";
import GradeDropdown from "./GradeDropdown.js";
import InfoPopup from "../UtilComps/InfoPopup";
import MultiSchoolReactSelect from "../InputFields/MultiSchoolReactSelect";

const AddStudentsModal = ({
  api,
  setShowModal,
  assignClassId,
  classToAddStudents,
  setClassToAddStudents,
  setRefreshForNewLearners,
  setRefreshClasses = null, // for classes on Manage Teacher pages
}) => {
  const defaultError = "Something went wrong... Please try again.";
  const [spin, setSpin] = useState(true);
  const [submitSpin, setSubmitSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(defaultError);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [learnerID, setLearnerID] = useState("");
  const [consentGiven, setConsentGiven] = useState(false);
  const [debouncedFirstName] = useDebounce(firstName, 1000);
  const [debouncedLastName] = useDebounce(lastName, 1000);
  const [debouncedLearnerID] = useDebounce(learnerID, 1000);
  const [selectedLearner, setSelectedLearner] = useState(null);
  const [existingLearners, setExistingLearners] = useState([]);

  const defaultGradeInfo = {
    grade_level: null,
    assigned_math: null,
    effective_math: null,
    assigned_reading: null,
    effective_reading: null,
    assigned_other: null,
    effective_other: null,
    lock: false,
  };

  const [gradeInfo, setGradeInfo] = useState(defaultGradeInfo);
  const [mathGrade, setMathGrade] = useState("");
  const [readingGrade, setReadingGrade] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [infoText, setInfoText] = useState(null);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    setError(false);
    setSuccess(false);
    if (debouncedFirstName || debouncedLastName || debouncedLearnerID) {
      setSpin(true);
      const queryParams = {
        first_name: debouncedFirstName,
        last_name: debouncedLastName,
        org_uid: learnerID,
        show_all: true,
      };
      api
        .fetchAllVisibleLearners(queryParams)
        .then((objects) => {
          setExistingLearners(objects.results);
          setSelectedLearner(null);
          setSpin(false);
        })
        .catch(() => {
          setError(true);
          setErrorText(defaultError);
          setSpin(false);
        });
    }
  }, [debouncedFirstName, debouncedLastName, debouncedLearnerID]);

  const gradeIsSet = (grade_type) => {
    var gradeObj;
    if (grade_type === "reading") {
      gradeObj = readingGrade;
    } else if (grade_type === "math") {
      gradeObj = mathGrade;
    } else if (grade_type === "grade") {
      gradeObj = gradeLevel;
    }
    if (
      gradeObj == "" ||
      gradeObj.value === null ||
      gradeObj.value === "unset"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const updateGradeInfo = (fieldChanged) => {
    const isMathGrade = fieldChanged === "math";
    const isReadingGrade = fieldChanged === "reading";

    setGradeInfo({
      grade_level: gradeLevel.value,
      assigned_math: isMathGrade ? mathGrade.value : gradeInfo.assigned_math,
      effective_math: isMathGrade ? mathGrade.value : gradeInfo.effective_math,
      assigned_reading: isReadingGrade
        ? readingGrade.value
        : gradeInfo.assigned_reading,
      effective_reading: isReadingGrade
        ? readingGrade.value
        : gradeInfo.effective_reading,
      assigned_other: readingGrade.value > 2 ? 101 : 100,
      effective_other: readingGrade.value > 2 ? 101 : 100,
      lock: false,
    });
    if (isMathGrade) {
      if (!gradeIsSet("reading")) {
        setReadingGrade(mathGrade);
      } else if (!gradeIsSet("grade")) {
        setGradeLevel(mathGrade);
      }
    } else if (isReadingGrade) {
      if (!gradeIsSet("math")) {
        setMathGrade(readingGrade);
      } else if (!gradeIsSet("grade")) {
        setGradeLevel(readingGrade);
      }
    }
  };

  useEffect(() => {
    console.log(gradeInfo);
  }, [gradeInfo]);

  useEffect(() => {
    if (mathGrade) {
      updateGradeInfo("math");
    }
  }, [mathGrade]);

  useEffect(() => {
    if (readingGrade) {
      updateGradeInfo("reading");
    }
  }, [readingGrade]);

  useEffect(() => {
    if (gradeLevel) {
      if (gradeLevel.value === "unset") {
        return;
      }
      if (gradeIsSet("reading") && gradeIsSet("math")) {
        updateGradeInfo("grade_level");
      } else {
        const adjustedGradeLevel =
          gradeLevel.value < 0
            ? { label: "Grade K", value: 0 }
            : gradeLevel.value > 5
            ? { label: "Grade 5", value: 5 }
            : gradeLevel;
        // This should also trigger math grade set
        setReadingGrade(adjustedGradeLevel);
      }
    }
  }, [gradeLevel]);

  const setReadingMathAndGrade = (changeType, obj) => {
    if (changeType === "math") {
      setMathGrade(obj);
    } else if (changeType === "reading") {
      setReadingGrade(obj);
    } else if (changeType === "grade") {
      setGradeLevel(obj);
    }
  };

  const handleSchoolChange = (selectedSchools) => {
    if (selectedSchools && selectedSchools.length > 0) {
      setError(false);
      setSchools(
        selectedSchools.map((option) => ({
          name: option.label,
          id: option.value,
        }))
      );
    } else {
      setSchools([]);
    }
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setConsentGiven(false);
    setSelectedLearner(null);
    setExistingLearners([]);
    setMathGrade("");
    setReadingGrade("");
    setGradeLevel("");
    setLearnerID("");
    setGradeInfo(defaultGradeInfo);
    setSchools([]);
  };

  const handleSubmit = (flow) => {
    setError(false);
    setErrorText(defaultError);
    setSubmitSpin(true);

    // if adding existing learner
    if (selectedLearner) {
      api
        .applyUserTagToLearner(assignClassId, selectedLearner)
        .then((resp) => {
          setSubmitSpin(false);
          setSuccess(true);
          setRefreshForNewLearners(assignClassId);
          if (flow === "addAnother") {
            resetForm();
          } else {
            setShowModal(false);
            setClassToAddStudents &&
              setClassToAddStudents({ id: null, name: null });
          }
          if (setRefreshClasses) setRefreshClasses(true);
        })
        .catch((err) => {
          console.log("Error applying UserTag to Learner: ", err);
          setSubmitSpin(false);
          setError(true);
          if (err === '["Learner with this Learner ID already exists."]') {
            setErrorText("Learner with this Learner ID already exists.");
          } else {
            setErrorText(err.message);
          }
        });
    } else {
      // else if we adding new learner
      const learnerData = {
        coppa: consentGiven,
        name: `${firstName} ${lastName}`,
        org_uid: learnerID || null,
        grade_info: gradeInfo,
        orgunits: schools.map((school) => school.id),
      };
      // if assignClassId is null (not in class view)
      // will just create learner in the org (without assigning a class)
      api
        .createLearnersInUserTag(assignClassId, [learnerData])
        .then((resp_json) => {
          setSubmitSpin(false);
          setSuccess(true);
          setRefreshForNewLearners(assignClassId);
          if (flow === "addAnother") {
            resetForm();
          } else {
            setShowModal(false);
            setClassToAddStudents &&
              setClassToAddStudents({ id: null, name: null });
          }
          if (setRefreshClasses) setRefreshClasses(true);
        })
        .catch((err) => {
          console.log("Error applying UserTag to New Learner: ", err);
          console.log(err);
          setSubmitSpin(false);
          setError(true);
          if (err === '["Learner with this Learner ID already exists."]') {
            setErrorText("Learner with this Learner ID already exists.");
          } else {
            setErrorText(err.message);
          }
        });
    }
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper-large'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>
              {"Add Learners "}
              {classToAddStudents && ` to ${classToAddStudents.name}`}
            </p>
            <button
              className='pdf_popup_close'
              onClick={() => {
                setShowModal(false);
                setClassToAddStudents &&
                  setClassToAddStudents({ id: null, name: null });
              }}
            >
              <FA icon='times' />
            </button>
          </div>

          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && <div className='alert alert-danger'>{errorText}</div>}

                {success && (
                  <div className='alert alert-success'>
                    Learner added successfully!
                  </div>
                )}

                {infoText && (
                  <React.Fragment>
                    <InfoPopup
                      popupInfo={infoText}
                      setShowPopupInfo={setInfoText}
                    />
                  </React.Fragment>
                )}

                <div className='mb-3'>
                  <div className='table'>
                    <div className='add_student_fields_schools'>
                      <MultiSchoolReactSelect
                        handleChange={handleSchoolChange}
                        value={schools}
                        header={"School(s)"}
                        autoselect={true}
                      />
                    </div>
                    <div className='add_student_fields_container_row'>
                      <StudentNameTextField
                        placeholder={"First Name"}
                        value={firstName}
                        setterFunc={setFirstName}
                      />

                      <StudentNameTextField
                        placeholder={"Last Name"}
                        value={lastName}
                        setterFunc={setLastName}
                      />

                      <StudentNameTextField
                        placeholder={"Learner ID"}
                        value={learnerID}
                        setterFunc={setLearnerID}
                        infoIconAction={() => {
                          setInfoText({
                            header: "Learner ID (Optional)",
                            text: `Learner ID is the unique identification number students use in school.
                                This is an optional field,
                                but providing this information helps keep student records in our system up-to-date.
                                This is typically a student ID number and does not change from year to year.`,
                          });
                        }}
                      />
                    </div>

                    <div className='add_student_fields_container_row'>
                      <GradeDropdown
                        placeholder='Grade Level'
                        setterFunc={setReadingMathAndGrade}
                        value={gradeLevel}
                        addCssClass='mr-2'
                        changeType='grade'
                        nonstandardGradesEnabled={true}
                        infoIconAction={() => {
                          setInfoText({
                            header: "Grade Level",
                            text: `The learner's current grade level.`,
                          });
                        }}
                      />

                      <GradeDropdown
                        placeholder='Math Level'
                        setterFunc={setReadingMathAndGrade}
                        value={mathGrade}
                        addCssClass='mr-2'
                        changeType='math'
                        infoIconAction={() => {
                          setInfoText({
                            header: "Math Level",
                            text: `The level that the learner sees Math lessons for on the robot.`,
                          });
                        }}
                      />

                      <GradeDropdown
                        placeholder='Reading Level'
                        setterFunc={setReadingMathAndGrade}
                        value={readingGrade}
                        addCssClass='mr-2'
                        changeType='reading'
                        infoIconAction={() => {
                          setInfoText({
                            header: "Reading Level",
                            text: `The level that the learner sees Reading lessons for on the robot.`,
                          });
                        }}
                      />
                    </div>
                    <div className='parental_consent_container'>
                      <label for='consent-checkbox'>
                        <b>Has parental consent been given?*</b>
                      </label>
                      <input
                        id='consent-checkbox'
                        type='checkbox'
                        name='consent-checkbox'
                        checked={consentGiven}
                        onChange={() => {
                          setConsentGiven(!consentGiven);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {spin && (firstName || lastName || learnerID) && (
                  <div className='alert'>
                    <FA color='gray' className='mr-2' icon='spinner' spin />
                    Searching for existing learners ...
                  </div>
                )}

                {!spin && (firstName || lastName || learnerID) && (
                  <div>
                    {existingLearners.length > 0 && (
                      <div
                        className='mb-4 pt-3 table-scroll-wrapper'
                        style={{
                          overflowX: "hidden",
                          maxHeight: "350px",
                          borderTop: "solid 2px black",
                        }}
                      >
                        {assignClassId ? (
                          <h5>
                            Please select the learner you're trying to add if
                            they appear in this list.
                          </h5>
                        ) : (
                          <h5>
                            Potential matches are shown in the list below to
                            avoid creating accounts for existing learners.
                          </h5>
                        )}
                        <div className='table table-striped'>
                          <div className='found_students_table_row'>
                            <b>Learner ID:</b>
                            <b>First Name:</b>
                            <b>Last Name:</b>
                            <b>Added By:</b>
                          </div>

                          {existingLearners.length > 0 ? (
                            existingLearners.map((learner) => (
                              <div
                                className='found_students_table_row existing_student_row'
                                key={learner.id}
                              >
                                <p>{learner.org_uid}</p>
                                <p>{learner.user.first_name}</p>
                                <p>{learner.user.last_name}</p>
                                <p>{learner.created_by_email}</p>
                                {assignClassId && (
                                  <p className='text-center'>
                                    {learner.user_tags.includes(
                                      parseInt(assignClassId)
                                    ) ? (
                                      <span data-title='Already In Class'>
                                        <FA
                                          color='green'
                                          icon='check-circle'
                                          className='mr-2'
                                        />
                                      </span>
                                    ) : (
                                      <input
                                        aria-label={
                                          "Select this learner: " +
                                          learner.user.first_name +
                                          " " +
                                          learner.user.last_name
                                        }
                                        type='checkbox'
                                        name='rbt-checkbox'
                                        className='form-control medium-checkbox'
                                        checked={selectedLearner === learner.id}
                                        onChange={() => {
                                          if (selectedLearner === learner.id) {
                                            setSelectedLearner(null);
                                          } else {
                                            setSelectedLearner(learner.id);
                                          }
                                        }}
                                      />
                                    )}
                                  </p>
                                )}
                              </div>
                            ))
                          ) : (
                            <div>
                              No existing learners found. Fill in the form above
                              to create and add this learner.
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {submitSpin && (
                      <div className='alert'>
                        <FA color='gray' icon='spinner' spin />
                        Adding Learner ...
                      </div>
                    )}

                    {firstName &&
                      lastName &&
                      consentGiven &&
                      !selectedLearner && (
                        <p className='alert alert-warning'>
                          New learners are created with default password{" "}
                          <strong>1234</strong>.{" "}
                        </p>
                      )}

                    {!submitSpin && !success && (
                      <div className='text-center'>
                        <button
                          disabled={
                            (!firstName ||
                              !lastName ||
                              !consentGiven ||
                              !mathGrade ||
                              !readingGrade ||
                              schools.length === 0) &&
                            !selectedLearner
                          }
                          onClick={() => {
                            handleSubmit("finish");
                          }}
                          className='btn btn-primary mr-4'
                        >
                          Save + Finish
                        </button>
                        <button
                          disabled={
                            (!firstName ||
                              !lastName ||
                              !consentGiven ||
                              !mathGrade ||
                              !readingGrade ||
                              schools.length === 0) &&
                            !selectedLearner
                          }
                          onClick={() => {
                            handleSubmit("addAnother");
                          }}
                          className='btn btn-primary mr-4'
                        >
                          Save + Add Another Learner
                        </button>
                        <button
                          className='btn btn-secondary btn-danger'
                          onClick={() => {
                            setShowModal(false);
                            setClassToAddStudents &&
                              setClassToAddStudents({ id: null, name: null });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {success && (
                      <button
                        className='btn btn-success'
                        onClick={() => {
                          setShowModal(false);
                          setClassToAddStudents &&
                            setClassToAddStudents({ id: null, name: null });
                        }}
                      >
                        Okay
                      </button>
                    )}
                  </div>
                )}

                <div className='mt-3'>
                  <p>
                    *Please check box to confirm parental consent for the
                    learner. Without checked consent learner will not be able to
                    take lessons. For more information on COPPA compliance,
                    please
                    <Link target='_blank' to={"/privacy-policy/"}>
                      {" "}
                      read our privacy policy
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI)(AddStudentsModal);
