import React from "react";
import ClosePopupBtn from "./ClosePopupBtn";
import "./InfoPopup.scss";

const InfoPopup = ({popupInfo, setShowPopupInfo}) => {
  return (
    <div className='pdf-upload'>
      <div 
        className='pdf-upload-wrapper'
        style={{
          'transform': 'translate(-50%, 5%)',
          'width': '95%',
        }}
      >
        <div id='info_popup_common_border' className='common_border'>
          <div id='info_popup_common_heading' className='common_heading'>
            <p id='info_popup_header_text'>{popupInfo.header}</p>
            <ClosePopupBtn closePopupFunc={setShowPopupInfo} />
          </div>
          <div className='reset_pw_modal common_dashboard_bg'>
            <div id='info_popup_form_content' className='form-content'
              style={{
                'max-height': '325px',
                'overflow-y': 'auto'
              }}
            >
              {popupInfo.text && <p id='info_popup_text'>{popupInfo.text}</p>}

              {popupInfo.html && popupInfo.html}

              {popupInfo.dangerouslySetHtml && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: popupInfo.dangerouslySetHtml,
                  }}
                ></div>
              )}

              <div style={{textAlign: "center"}}>
                <button
                  autoFocus
                  className='btn btn-lg fs-14 btn-primary col-md-2'
                  onClick={() => {
                    setShowPopupInfo(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPopup;
