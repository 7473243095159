import React from 'react';

import FA from '../containers/fa';

const Logout = ({ history, color, classNames }) => {
  const onClick = (e) => {
    e.preventDefault();
    // NOTE: clearing session storage has been moved to login page
    window.location.href = "/login";
  }
  return (
    <button className="dropdown-item" onClick={onClick}>
      <FA icon="power-off" color={color ? color : "white"} />&nbsp; Log Out</button>
  );
}

export default Logout;
