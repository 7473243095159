import config from '../common/config';

// const resolveWithFull = (resp) => {
//   return new Promise((resolve, reject) => {
//     resp.json().then((resp_json) => {
//       return resolve([resp, resp_json])
//     }).catch((e) => {
//       reject(e);
//     })
//   })
// }
const resolveWithFull = (resp) => {
  return new Promise((resolve, reject) => {
    console.log("resp: ",resp);
    if (resp.status === 204) {
      console.log("204 response code")
      return resolve([resp, null])
    } else {
      resp.json().then((resp_json) => {
        return resolve([resp, resp_json])
      }).catch((e) => {
        console.log("response has no json");
        console.log("resp: ",resp);
        reject(e);
      })
    }
  })
}

const handleErr = ([resp, resp_json]) => {
  if (!resp.ok) {
    if (resp.status === 401) {
      console.log("Unauthorized handler.");
      console.log("RESPONSE HEADERS: ", resp.headers);
      console.log("RESPONSE STATUS: ", resp.status);
      console.log("RESPONSE URL: ", resp.url);
      console.log("LOCAL STORAGE: ", sessionStorage);
      console.log("auth token: ", sessionStorage['authToken']);
      console.log("user: ", sessionStorage['user']);
      console.log("org: ", sessionStorage['organization']);
      document.location.href = "/login";
    }
    let err = 'Something went wrong.'
    if (resp_json.message) {
      err = resp_json.message;
      //console.log("Throwing error message:", err);
      if (typeof(err) == 'object') {
        console.log("Throwing err object:", err)
        throw JSON.stringify(err);
      } else {
        console.log("Setting err:", err);
        throw new Error(err);
      }
    } else if (resp_json.error) {
      err = resp_json.error;
      throw new Error(err);
    /* This else if may cause problems somewhere. Not sure yet.
     * The idea is to return the error message, however the API labels it. */
    } else if (Object.keys(resp_json).length > 0) {
      err = resp_json[Object.keys(resp_json)[0]];
      if (typeof(err) != 'string' && Object.keys(err).length > 0) {
        // Go one step further if necessary
        err = err[Object.keys(err)[0]];
      }
    }
    if (typeof(err) == 'object') {
      console.log("Throwing err object:", err)
      throw JSON.stringify(err);
    } else {
      console.log("Setting err:", err);
      throw new Error(err);
    }
  }

  return [resp, resp_json];
}

const fetchWithError = (...rest) => {
  return fetch(...rest)
    .then(resolveWithFull)
    .then(handleErr)
    .then(([resp, resp_json]) => resp_json)
    .catch((e) => {
      console.log(e);
      throw e;
      //throw new Error(e);
    })
}

const fetchFileName = function (header) {
  let filename = header.match(/filename="(.+)"/)[1];
  if (!filename) {
    filename = 'download.csv';
  }
  return filename;
};

const fetchDownloadFile = (url, data) => {
  return fetch(url, data)
      .then(async res => ({
        filetype: res.headers.get('content-type'),
        filename: fetchFileName(res.headers.get('Content-Disposition')),
        blob: await res.blob()
      }))
      .then((resObj) => {
        const newBlob = new Blob([resObj.blob], { type: resObj.filetype });
        let url = window.URL.createObjectURL(newBlob);
        let a = document.createElement('a');
        a.href = url;
        a.download = resObj.filename;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e);
        throw new Error(e);
      })
}

// Specifically for launching new Lesson Viewer tabs
//export const lessonViewerWindow = null;  // global var
export function openRequestedTab({url, windowRef, windowName}) {
  console.log("Function got windowRef:",windowRef);
  console.log("Opening ",url," in context ",windowName);
  if (windowRef === null) {
    console.log("windowRef is null -> openRequestedTab opening a new window");
    windowRef = window.open(url, windowName);
    return windowRef;
  } else if (windowRef.closed) {
    console.log("windowRef is closed -> openRequestedTab opening a new window");
    windowRef = window.open(url, windowName);
    return windowRef;
  } else {
    console.log("openRequestedTab bringing existing window into focus");
    windowRef.focus();
    return windowRef;
  }
}
export function lessonViewerMainPageUrl() {
  const authToken = sessionStorage.getItem('authToken');
  const urlPath = `${config.lessonViewerUrl}/viewer/landing/`;
  const fullUrl = `${config.lessonViewerProto}://${urlPath}?token=${authToken}`;
  return fullUrl;
}
export function previewLessonUrl(lessonId) {
  const authToken = sessionStorage.getItem('authToken');
  const urlPath = `${config.lessonViewerUrl}/viewer/landing/`;
  const fullUrl = `${config.lessonViewerProto}://${urlPath}?token=${authToken}&lesson_id=${lessonId}`;
  return fullUrl;
}

export { resolveWithFull, handleErr, fetchWithError, fetchDownloadFile };
