import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


const StateSelector = ({value, onChangeHandle}) => {

  const defaultState = '------';
  const allStateOptions = [
    defaultState,
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "ID", "IL", "IN",
    "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH",
    "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT",
    "VT", "VA", "WA", "WV", "WI", "WY", "Foreign Country"]

  return (
    <Select
      labelId="demo-simple-select-label"
      className="w-100"
      value={value}
      onChange={(e) => {
        onChangeHandle(e)
      }}
    >
      {allStateOptions.map((item) => 
        <MenuItem key={item} value={item}>{item}</MenuItem>
      )}
    </Select>
  )
}


export default StateSelector;
