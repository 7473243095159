import React, {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import BarChartShadowSvg from "../BarChartChadowSvg";
import "./ClassAveragesBarChart.scss";

const ClassAveragesBarChart = ({
  classAverageData,
  selectedLesson,
  learners,
}) => {
  const [parsedAttnData, setParsedAttnData] = useState([]);
  const [parsedPerformData, setParsedPerformData] = useState([]);
  const [chartLabels, setChartLabels] = useState(["Class Average"]);

  const roundNum = (num) => {
    return Math.round(num * 100);
  };

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Attention",
        data: parsedAttnData,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
      },
      {
        label: "Performance",
        data: parsedPerformData,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
      },
    ],
  };

  useEffect(() => {
    // clear the chart if no learners took the current lesson, no lesson was
    // selected, or no learners exist.
    if (
      !classAverageData ||
      !selectedLesson ||
      !learners ||
      (learners && learners.length === 0)
    ) {
      setParsedAttnData([]);
      setParsedPerformData([]);
      setChartLabels(["Class Average"]);
      return;
    }

    let labels = ["Class Average"];
    let newClassAttnData = roundNum(classAverageData.sum_attention);
    let newClassPerformData = roundNum(classAverageData.sum_performance);
    let newStudentAttnData = [];
    let newStudentPerformData = [];
    for (let item of classAverageData.by_user) {
      labels.push(item.username);
      newStudentAttnData.push(roundNum(item.sum_attention));
      newStudentPerformData.push(roundNum(item.sum_performance));
    }
    setParsedAttnData([newClassAttnData, ...newStudentAttnData]);
    setParsedPerformData([newClassPerformData, ...newStudentPerformData]);
    setChartLabels(labels);
  }, [classAverageData, selectedLesson, learners]);

  return (
    <div id='class_averages_bar_chart_container' className='visible-scrollbar'>
      <div
        id='class_averages_bar_chart'
        className='chart-container'
        style={{
          width: "100%",
          minWidth:
            parsedAttnData.length - 1 < 6
              ? "100%"
              : `${(parsedAttnData.length - 1) * 50}px`,
          height: "350px",
        }}
      >
        {classAverageData &&
        classAverageData.by_user.length !== 0 &&
        selectedLesson &&
        learners &&
        learners.length !== 0 ? (
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: {
                    callback: (yValue) => {
                      return Math.round(yValue) + "%";
                    },
                  },
                  beginAtZero: true,
                  suggestedMax: 100,
                },
              },
              plugins: {
                datalabels: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return context.dataset.label + ": " + context.parsed.y + "%";
                    },
                  },
                },
                title: {
                  display: true,
                  text: `${selectedLesson.name} - Average Performance + Attention`,
                  font: {
                    size: 12,
                  },
                },
              },
            }}
          />
        ) : learners && learners.length === 0 ? (
          <div id='no_class_avg_data'>
            <BarChartShadowSvg />
            <p>This class has no learners.</p>
          </div>
        ) : (
          <div id='no_class_avg_data'>
            <BarChartShadowSvg />
            <p>Select a lesson.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassAveragesBarChart;
