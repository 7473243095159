import React from "react";

const LearnerPerformanceTooltip = ({
  hover,
  debouncedCurrentHoverData,
  attentionNotRecorded,
  convertQuestionTypes,
}) => {
  if (!debouncedCurrentHoverData) return null;
  console.log("debounced data", debouncedCurrentHoverData);
  const getPerformanceCaption = (performanceVal) => {
    if (performanceVal === 1) return "Correct";
    if (performanceVal === 0) return "Incorrect";
    return "N/A";
  };

  const isNoResponseScreen = (type) => {
    if (["DSP", "VID", "OPD"].indexOf(type) !== -1) {
      return true;
    }
    return false;
  };

  return (
    <div
      id='chartjs-tooltip-overtime'
      className='center'
      style={{
        display: hover ? "block" : "none",
      }}
    >
      <b>Step #{debouncedCurrentHoverData.hovered_index + 1}</b>
      <span
        style={{
          display: "grid",
          gridTemplateColumns: "max-content auto",
          alignItems: "center",
        }}
      >
        <span
          className='chartjs-tooltip-key'
          style={{
            background: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: "2px",
            marginRight: "8px",
          }}
        />
        <p style={{margin: "0px"}}>
          Performance:{" "}
          {getPerformanceCaption(debouncedCurrentHoverData.performance)}
        </p>
      </span>
      {!attentionNotRecorded && (
        <span
          style={{
            display: "grid",
            gridTemplateColumns: "max-content auto",
            alignItems: "center",
          }}
        >
          <span
            className='chartjs-tooltip-key'
            style={{
              background: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: "2px",
              marginRight: "8px",
            }}
          />
          <p style={{margin: "0px"}}>
            Attention: {debouncedCurrentHoverData.attention}%
          </p>
        </span>
      )}
      <b>Screen type:</b>{" "}
      {convertQuestionTypes(debouncedCurrentHoverData.stepQuestion.type)}
      {!isNoResponseScreen(debouncedCurrentHoverData.stepQuestion.type) && (
        <p className='line_chart_tooltip_question_text'>
          <b>Question text:</b>{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: debouncedCurrentHoverData.stepQuestion.text,
            }}
          ></span>
        </p>
      )}
    </div>
  );
};

export default LearnerPerformanceTooltip;
