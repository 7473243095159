import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import GradeDropdown from "./GradeDropdown.js";
import LearnerAudioEditModal from "./LearnerAudioEditModal.js";
import LearnerResetPWModal from "./LearnerResetPWModal.js";
import "./LearnerRow.scss";
import { connect } from "react-redux";
import config from "../../common/config";
import { compose } from "redux";
import clever from "../../assets/images/clever.ico";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  orgStatus: state.auth.orgStatus,
});

const LearnerRow = ({
  index,
  lrn,
  classId,
  match,
  user,
  orgStatus,
  api,
  setShowAutosave,
  setAutosaveSuccess,
  setShowPopupInfo,
  setShouldSpin,
  setPopupInfo,
  setEditLearner,
  teacherAcctPageLearnersInfo = null, // for manage teacher pages
  isNotInExpandedClass = false,
}) => {
  const [showPW, setShowPW] = useState(false);
  const [shouldShowPWResetModal, setShouldShowPWResetModal] = useState(false);
  const [shouldShowAudioModal, setShouldShowAudioModal] = useState(false);
  const [hackyPW, setHackyPW] = useState(null);
  const [lock, setLock] = useState(
    lrn.grade_info === null || typeof lrn.grade_info === "undefined"
      ? false
      : lrn.grade_info.lock
  );

  const getDefaultGradeVal = (json, val, isLabel) => {
    if (json === null || typeof json === "undefined") {
      return "unset";
    } else if (lrn.grade_info[val] === "unset") {
      return "unset";
    } else if (lrn.grade_info[val] === 0 && isLabel) {
      return "Grade K";
    } else if (!isLabel) {
      return lrn.grade_info[val];
    }
    return "Grade " + lrn.grade_info[val];
  };

  const [math_grade, setMath_grade] = useState({
    label: getDefaultGradeVal(lrn.grade_info, "effective_math", true),
    value: getDefaultGradeVal(lrn.grade_info, "effective_math", false),
  });
  const [reading_grade, setReading_grade] = useState({
    label: getDefaultGradeVal(lrn.grade_info, "effective_reading", true),
    value: getDefaultGradeVal(lrn.grade_info, "effective_reading", false),
  });

  const gradeIsUnset = (subject) => {
    if (subject === "math") {
      return math_grade.value === null || math_grade.value === "unset";
    } else if (subject === "reading") {
      return reading_grade.value === null || reading_grade.value === "unset";
    }
  };

  const setReadingAndMath = (changeType, obj) => {
    if (changeType === "math") {
      setMath_grade(obj);
      if (gradeIsUnset("reading")) {
        setReading_grade(obj);
      }
    } else {
      setReading_grade(obj);
      if (gradeIsUnset("math")) {
        setMath_grade(obj);
      }
    }
  };

  const saveGradeInfoChanges = (changeType, newVals) => {
    let new_grade_info = null;
    setAutosaveSuccess(null);
    setShowAutosave(true);
    const isMathGrade = changeType === "math";
    const isReadingGrade = changeType === "reading";

    const getReadingGrade = () => {
      if (isMathGrade) {
        if (gradeIsUnset("reading")) {
          return newVals.value;
        } else {
          return reading_grade.value;
        }
      } else if (isReadingGrade) {
        return newVals.value;
      } else {
        return reading_grade.value;
      }
    };

    const getMathGrade = () => {
      if (isReadingGrade) {
        if (gradeIsUnset("math")) {
          return newVals.value;
        } else {
          return math_grade.value;
        }
      } else if (isMathGrade) {
        return newVals.value;
      } else {
        return math_grade.value;
      }
    };

    new_grade_info = {
      assigned_math: getMathGrade(),
      effective_math: getMathGrade(),
      assigned_reading: getReadingGrade(),
      effective_reading: getReadingGrade(),
      lock: !isReadingGrade && !isMathGrade ? newVals : lock,
    };

    api
      .updateLearnerGradeInfo(lrn.user_id, new_grade_info)
      .then((resp_json) => {
        setAutosaveSuccess(true);
        setShowAutosave(false);
      })
      .catch((e) => {
        console.log(e);
        setAutosaveSuccess(false);
        setShowAutosave(false);
      });
  };

  if (classId) {
    classId = classId;
  } else {
    classId = match.params.clsId;
  }

  const showLessonCountNA = () => {
    if (lrn.recent_weekly_lesson === null) {
      return true;
    } else {
      return false;
    }
  };

  const findAvgWeeklyLessonBgColor = (val) => {
    if (orgStatus !== "active") {
      return "";
    }
    if (val >= 4) {
      return "#D9ECDB";
    } else if (val === 0) {
      return "#F3D8DA";
    } else if (val > 0 && val < 4) {
      return "#FDF2D1";
    }
    return "";
  };

  const roundedRecentLessons =
    lrn && Math.round(lrn.recent_weekly_lesson * 10 * (30 / 7)) / 10;

  const getIcon = () => {
    if (orgStatus !== "active") {
      return "";
    }
    if (roundedRecentLessons >= 4) {
      return <FA icon='check' className='has-wifi status-icon' />;
    } else {
      if (roundedRecentLessons === 0) {
        return <FA icon='times' className='no-wifi status-icon' />;
      } else {
        return (
          <FA
            icon='exclamation-circle'
            color='#ffc107'
            className='status-icon'
          />
        );
      }
    }
  };

  const displayedPassword = hackyPW || lrn.student_pwd;

  return (
    <div
      key={lrn.id}
      className={
        teacherAcctPageLearnersInfo
          ? "manage_teacher_classes_fields_row"
          : "class-grid-row-fields-container-teacher"
      }
    >
      <p className='webkit-line-box'>{index}</p>

      <p className='webkit-line-box' style={{ paddingLeft: "10px" }}>
        {lrn.user && lrn.user.last_name}, {lrn.user && lrn.user.first_name}
        {lrn.clever_synced && (
          <img
            style={{width: '1rem', marginLeft: '0.25rem'}}
            alt='clever'
            title='Clever-synced'
            src={clever}
          />
        )}
      </p>
      <p className='webkit-line-box'>{lrn.user && lrn.user.username}</p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "50%",
          minWidth: "fit-content",
          justifySelf: "center",
          backgroundColor:
            lrn && !showLessonCountNA()
              ? findAvgWeeklyLessonBgColor(roundedRecentLessons)
              : "#f2f2f2",
        }}
      >
        {lrn && !showLessonCountNA() ? (
          <span style={{ display: "flex" }}>
            {roundedRecentLessons}
            <p style={{ padding: "0px 0px 0px 5px" }}>{getIcon()}</p>
          </span>
        ) : (
          <span style={{ display: "flex" }}>
            <p>N/A</p>
          </span>
        )}
      </div>
      {/* Manage Teacher pages only */}
      {teacherAcctPageLearnersInfo && (
        <span>
          <p className='webkit-line-box'>
            {teacherAcctPageLearnersInfo[lrn.id]
              ? teacherAcctPageLearnersInfo[lrn.id].total_lessons
              : 0}
          </p>
        </span>
      )}

      {/* Manage Teacher pages don't display grade info or pw info. */}
      {!teacherAcctPageLearnersInfo && (
        <div style={{ padding: "10px 0px 10px 0px" }}>
          <div className='showPW'>
            <button
              className='btn btn-sm btn-primary btn-eye'
              onClick={() => setShowPW(!showPW)}
              title='Show Password'
              placement='top'
              disabled={isNotInExpandedClass}
            >
              <FA icon='eye' />
            </button>
            {showPW ? (
              <span
                title={displayedPassword.length > 6 ? displayedPassword : ""}
              >
                <input
                  type='text'
                  disabled
                  className='form-control input-lg'
                  value={displayedPassword}
                />
              </span>
            ) : (
              <input
                type='password'
                disabled
                className='form-control input-lg text-truncate'
                value={hackyPW || lrn.student_pwd}
              />
            )}
          </div>
        </div>
      )}

      {!teacherAcctPageLearnersInfo && (
        <>
          <div style={{ padding: "10px 0px 10px 0px" }}>
            <GradeDropdown
              placeholder='Select a math level'
              setterFunc={setReadingAndMath}
              value={{ value: math_grade.value, label: `${math_grade.label}` }}
              saveGradeInfoChanges={saveGradeInfoChanges}
              changeType='math'
              disabled={isNotInExpandedClass}
            />
          </div>
          <div style={{ padding: "10px 0px 10px 0px" }}>
            <GradeDropdown
              placeholder='Select a reading level'
              setterFunc={setReadingAndMath}
              value={{
                value: reading_grade.value,
                label: `${reading_grade.label}`,
              }}
              saveGradeInfoChanges={saveGradeInfoChanges}
              changeType='reading'
              disabled={isNotInExpandedClass}
            />
          </div>
          <span className='hide_on_ipad' style={{ justifyContent: "center" }}>
            <input
              type='checkbox'
              checked={lock}
              disabled={
                gradeIsUnset("reading") ||
                gradeIsUnset("math") ||
                isNotInExpandedClass
              }
              onChange={(e) => {
                setLock(e.target.checked);
                saveGradeInfoChanges("lock", e.target.checked);
              }}
            />
          </span>
        </>
      )}

      <div className='actions_btns_container_grid' name='Actions'>
        {!teacherAcctPageLearnersInfo && (
          <div className='show_on_ipad' style={{ margin: "0px 10px 0px 0px" }}>
            <div>
              Grade Locked
              <FAW
                icon='info-circle'
                className='info-circle'
                onClick={() => {
                  setPopupInfo({
                    header: "Grade Lock",
                    text: `When a learner is grade locked, they cannot take any
                  lessons below or above their specified grade levels for
                  their subjects. This setting can be toggled on and off.`,
                  });
                  setShowPopupInfo(true);
                }}
              />
            </div>
            <input
              name='grade-lock-checkbox'
              aria-label='Checkbox for: Grade Lock'
              type='checkbox'
              checked={lock}
              disabled={
                gradeIsUnset("reading") ||
                gradeIsUnset("math") ||
                isNotInExpandedClass
              }
              onChange={(e) => {
                setLock(e.target.checked);
                saveGradeInfoChanges("lock", e.target.checked);
              }}
            />
          </div>
        )}

        {shouldShowPWResetModal && (
          <LearnerResetPWModal
            setPWFunc={setHackyPW}
            learnerFirstName={lrn.user.first_name}
            learnerLastName={lrn.user.last_name}
            shouldShowFunc={setShouldShowPWResetModal}
            setAutosaveSuccess={setAutosaveSuccess}
            setShowAutosave={setShowAutosave}
            learnerId={lrn.id}
            setShouldSpin={setShouldSpin}
          />
        )}
        {shouldShowAudioModal && (
          <LearnerAudioEditModal
            shouldShowFunc={setShouldShowAudioModal}
            learnerId={lrn.id}
            setAutosaveSuccess={setAutosaveSuccess}
          />
        )}
        <span>
          <div className='datatable_actions'>
            <div className='d-flex align-items-start common-align-button action-btns'>
              {!isNotInExpandedClass ? (
                <Link
                  className='btn btn-md btn-primary learner-reports'
                  class-btn-data-title='View Reports'
                  to={{
                    pathname: `/user/reports/${lrn.id}`,
                    state: {
                      rerouteObject: {
                        first_name: lrn.user.first_name,
                        label: `${lrn.user.first_name} ${lrn.user.last_name}`,
                        last_name: lrn.user.last_name,
                        sid: lrn.id,
                        type: "learner",
                        username: lrn.user.username,
                      },
                      rerouteObjectType: "learner",
                      rerouteSource: teacherAcctPageLearnersInfo
                        ? "teacherAcct"
                        : "myClass",
                    },
                  }}
                >
                  <FAW icon='chart-bar' />
                </Link>
              ) : (
                <button
                  className='btn btn-md btn-primary learner-reports'
                  class-btn-data-title='View Reports'
                  disabled
                >
                  <FAW icon='chart-bar' />
                </button>
              )}

              {/* Manage Teacher pages don't edit learner pws or names. */}
              {!teacherAcctPageLearnersInfo && (
                <>
                  <button
                    className='btn btn-md btn-primary'
                    onClick={() =>
                      setShouldShowPWResetModal(!shouldShowPWResetModal)
                    }
                    class-btn-data-title='Reset Password'
                    disabled={isNotInExpandedClass}
                  >
                    <FAW icon='key' />
                  </button>
                  <button
                    className='btn btn-md btn-primary'
                    onClick={() =>
                      setShouldShowAudioModal(!shouldShowAudioModal)
                    }
                    class-btn-data-title='Customize Your Name'
                    disabled={isNotInExpandedClass}
                  >
                    <FAW icon='file-audio' />
                  </button>
                </>
              )}

              {user.usertype !== config.SUPERADMIN_USERTYPE &&
                lrn.user &&
                lrn.user.username && (
                  <button
                    class-btn-data-title='Edit/Delete Learner'
                    className='btn btn-md btn-primary'
                    onClick={() => setEditLearner(lrn)}
                    disabled={isNotInExpandedClass}
                  >
                    <FAW icon='edit' />
                  </button>
                )}
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps))(withRouter(LearnerRow));
