import React, {memo, useEffect, useState} from "react";
import "./ClassScoresPagination.scss";

const ClassScoresPagination = ({
  learnersPerPage,
  setLearnersPerPage,
  currentPage,
  setCurrentPage,
  numberOfPages,
  numberOfLearners,
  setSortedLearnerId,
  sortedLearnerId,
  learnersList,
}) => {
  // if the pagination dropdown changes, and the current sortedLearnerId isn't
  // in the new list (ex. the sortedLearnerId shows up on page 2 if the
  // dropdown is 5, and we change it to page 1), it changes to null and will
  // revert back when it can show up again.
  const [oldSortedLearnerId, setOldSortedLearnerId] = useState(null);
  useEffect(() => {
    if (checkForSortedLearnerIdInList()) {
      setSortedLearnerId(oldSortedLearnerId);
    } else {
      setSortedLearnerId(null);
    }
  }, [learnersList]);

  const checkForSortedLearnerIdInList = () => {
    for (let i = 0; i < learnersList.length; i++) {
      if (learnersList[i].sid === sortedLearnerId) return true;
    }
    return false;
  };

  return (
    <div id='learners_per_page_select_container'>
      <button
        className='pagination_btn previous'
        style={{
          opacity:
            learnersPerPage !== "All" && currentPage !== 0 && numberOfPages > 1
              ? "1"
              : "0",
        }}
        onClick={() => {
          if (
            learnersPerPage !== "All" &&
            currentPage !== 0 &&
            numberOfPages > 1
          ) {
            setOldSortedLearnerId(sortedLearnerId);
            setCurrentPage(currentPage - 1);
          }
        }}
      >
        <u>{`<< Previous`}</u>
      </button>
      <label style={{marginRight: "5px"}}>Learners per page:</label>

      <select
        id='learners_per_page_select'
        value={learnersPerPage}
        onChange={(e) => {
          setOldSortedLearnerId(sortedLearnerId);
          setLearnersPerPage(e.target.value);
          setCurrentPage(0);
        }}
      >
        <option value='All'>All</option>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={15}>15</option>
        <option value={20}>20</option>
        <option value={25}>25</option>
        <option value={30}>30</option>
      </select>
      <p style={{margin: "0px 0px 0px 5px"}}>
        {learnersPerPage === "All" || numberOfPages <= 1
          ? `Showing all learners`
          : `Showing learners ${currentPage * learnersPerPage + 1} - ${Math.min(
              (currentPage + 1) * learnersPerPage,
              numberOfLearners
            )} (out of ${numberOfLearners})`}
      </p>
      <button
        className='pagination_btn next'
        style={{
          opacity:
            learnersPerPage !== "All" &&
            currentPage + 1 !== numberOfPages &&
            numberOfPages > 1
              ? "1"
              : "0",
        }}
        onClick={() => {
          if (
            learnersPerPage !== "All" &&
            currentPage + 1 !== numberOfPages &&
            numberOfPages > 1
          ) {
            setOldSortedLearnerId(sortedLearnerId);
            setCurrentPage(currentPage + 1);
          }
        }}
      >
        <u>{`Next >>`}</u>
      </button>
    </div>
  );
};

export default memo(ClassScoresPagination);
