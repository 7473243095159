import React, {useEffect, useState} from "react";
import LearnerBySession from "./LearnerBySession/LearnerBySession";
import LearnerOverTimeBarChart from "./LearnerOverTime/LearnerOverTimeBarChart";
import LearnerProfile from "./LearnerProfile/LearnerProfile";
import "./LearnerTabContent.scss";
import {compose} from "redux";
import {connect} from "react-redux";
import withAPI from "../../../services/api";
import FA from "../../../containers/fa";
import config from "../../../common/config";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const LearnerTabContent = ({
  api,
  classes,
  ready,
  error,
  allLearners,
  fetchLearnersForClassData,
  selectedSearchVal,
  userType,
  rerouteObject,
  showFullView
}) => {
  const [activeLearner, setActiveLearner] = useState(null);
  const [profileStatsLoaded, setProfileStatsLoaded] = useState(false);
  const [profileStats, setProfileStats] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [customLessonsOnly, setCustomLessonsOnly] = useState(null);
  const [fetchProfileStatsErr, setFetchProfileStatsErr] = useState(null);

  const fetchProfileStats = () => {
    setProfileStatsLoaded(false);
    console.log(activeLearner);
    api
      .fetchProfileStats(activeLearner.sid)
      .then((data) => {
        console.log(data);
        // Only set data if we have data
        if (data.empty !== true) {
          setProfileStats(data);
        } else {
          setProfileStats(null);
        }

        let hasInternalLesson = false;

        // check if local learner reports are only available for custom lessons
        api
          .fetchLearnerInstances(null, activeLearner.sid)
          .then((reports) => {
            console.log("The custom reports for the user: ", reports);
            if (reports.length > 0) {
              hasInternalLesson = reports.some(function (item) {
                return item.lesson_id[0] !== "E";
              });
            }
            if (reports.length > 0 && hasInternalLesson === false) {
              setCustomLessonsOnly(true);
            } else {
              setCustomLessonsOnly(false);
            }
            console.log("custonLessonsOnly: ", customLessonsOnly);
          })
          .catch((err) => {
            console.log(err);
            setFetchProfileStatsErr(
              `Something went wrong fetching learner data. Please make sure you
              are connected to the internet and try again in a few seconds.`
            );
            setProfileStatsLoaded(true);
          });

        setProfileStatsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setFetchProfileStatsErr(
          `Something went wrong fetching learner data. Please make sure you
          are connected to the internet and try again in a few seconds.`
        );
        setProfileStatsLoaded(true);
      });
  };

  useEffect(() => {
    if (classes) {
      fetchLearnersForClassData();
    }
  }, [classes]);

  useEffect(() => {
    if (!activeLearner) return;
    fetchProfileStats();
  }, [activeLearner]);

  useEffect(() => {
    if (selectedSearchVal && selectedSearchVal.type === "learner") {
      setActiveLearner(selectedSearchVal);
    }
  }, [selectedSearchVal]);

  return (
    <div>
      <div
        id='gray_pdf_download_overlay'
        style={{display: showOverlay ? "flex" : "none"}}
      >
        <FA color='black' icon='spinner' spin /> Fetching Report...
      </div>
      <LearnerProfile
        error={error}
        fetchProfileStatsErr={fetchProfileStatsErr}
        ready={ready}
        activeLearner={activeLearner}
        setActiveLearner={setActiveLearner}
        allLearners={allLearners}
        profileStats={profileStats}
        profileStatsLoaded={profileStatsLoaded}
        userType={userType}
        rerouteObject={rerouteObject}
        showFullView={showFullView}
      />
      <LearnerOverTimeBarChart
        activeLearner={activeLearner}
        ready={ready}
        error={error}
        userType={userType}
      />
      <LearnerBySession
        error={error}
        ready={ready}
        activeLearner={activeLearner}
        setShowOverlay={setShowOverlay}
        userType={userType}
      />
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(LearnerTabContent);
