import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import TextField from "@material-ui/core/TextField";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";

import { refreshSaleDetail as refreshSaleDetailA } from "../../../redux/actions";

import withAPI from "../../../services/api";
import FA from "../../../containers/fa";
import config from "../../../common/config";

const mapStateToProps = (state, ownProps?) => ({
  refreshSaleDetail: state.superAdmin.refreshSaleDetail,
});

const SalesInfoPdTab = ({ dispatch, api, saleObj, refreshSaleDetail }) => {
  // We probably only need sale obj id
  // then we can fetch PDs separately

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [pdInfo, setPdInfo] = useState({});

  const pdValueToLabelMap = {
    pd_level_1: "Level 1",
    pd_level_2: "Level 2",
    pd_level_3: "ACE Coaching",
  };

  const resetMessages = () => {
    setError(false);
    setErrMsg(null);
    setSuccess(false);
  };

  const fetchPds = () => {
    if (saleObj && saleObj.id) {
      api
        .fetchPdsInSale(saleObj.id)
        .then((res) => {
          console.log(res);
          setPdInfo(res.data);
        })
        .catch((e) => {
          console.log("Error fetching PDs for sale: ", saleObj.id);
          console.log(e);
          setError(true);
          setErrMsg("Error fetching PDs ... Please try again!");
        });
    }
  };

  useEffect(() => {
    fetchPds();
  }, []);

  const handleSchedulePd = () => {
    resetMessages();
    const pdData = {
      pd_type: scheduleLevel,
      sale_id: saleObj.id,
      scheduled_datetime: scheduleDatetime.toISOString(),
    };
    console.log(pdData);
    api
      .schedulePd(pdData)
      .then((res) => {
        console.log(res);
        setSuccess("Successfully scheduled the PD!");
        setShowScheduleForm(false);
        fetchPds();
      })
      .catch((e) => {
        console.log("Error scheduling PD: ");
        console.log(e);
        setError(true);
        setErrMsg(String(e));
      });
  };

  const handleMarkComplete = (pdId) => {
    resetMessages();
    const pdData = {
      delivery_datetime: completionDate.toISOString(),
      delivered_by: deliveredBy,
      notes: pdNote,
      status: config.PD_DELIVERED,
    };
    console.log(pdData);
    api
      .editPd(pdId, pdData)
      .then((res) => {
        console.log(res);
        setSuccess("Successfully marked the PD as complete!");
        setShowConfirmId(null);
        fetchPds();
        dispatch(refreshSaleDetailA(!refreshSaleDetail));
      })
      .catch((e) => {
        console.log("Error marking PD as complete: ");
        console.log(e);
        setError(true);
        setErrMsg(String(e));
      });
  };

  const [showConfirmId, setShowConfirmId] = useState(null);
  const [showMoreId, setShowMoreId] = useState(null);
  const [completionDate, setCompletionDate] = useState(new Date());
  const [deliveredBy, setDeliveredBy] = useState(null);
  const [pdNote, setPdNote] = useState("");
  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const [scheduleDatetime, setScheduleDatetime] = useState(new Date());
  const [scheduleLevel, setScheduleLevel] = useState("");

  const [editMode, setEditMode] = useState(null);
  const [newDatetime, setNewDatetime] = useState(null);
  const [newDeliveredDatetime, setNewDeliveredDatetime] = useState(null);

  const [inputLocked, setInputLocked] = useState(false);

  useEffect(() => {
    if (editMode || showConfirmId) {
      setInputLocked(true);
    } else {
      setInputLocked(false);
    }
  }, [editMode, showConfirmId]);

  const resetEditFields = () => {
    setNewDeliveredDatetime(null);
    setPdNote("");
    setDeliveredBy(null);
  };

  const handleEditScheduledPD = (pdId) => {
    if (newDatetime === null) {
      console.log("No changes.");
      setEditMode(null);
    } else {
      resetMessages();
      const pdData = {
        scheduled_datetime: newDatetime.toISOString(),
      };
      api
        .editPd(pdId, pdData)
        .then((res) => {
          console.log(res);
          setSuccess("Successfully updated the PD!");
          setEditMode(null);
          resetEditFields();
          fetchPds();
        })
        .catch((e) => {
          console.log("Error re-scheduling PD: ");
          console.log(e);
          setError(true);
          setErrMsg(String(e));
        });
    }
  };

  const handleEditCompletedPD = (pdId) => {
    resetMessages();
    let newDatetime = newDeliveredDatetime;
    if (moment.isMoment(newDatetime)) {
      newDatetime = newDatetime.toISOString();
    }

    const pdData = {
      delivery_datetime: newDatetime,
      delivered_by: deliveredBy,
      notes: pdNote,
    };
    api
      .editPd(pdId, pdData)
      .then((res) => {
        console.log(res);
        setSuccess("Successfully updated the PD!");
        setEditMode(null);
        resetEditFields();
        fetchPds();
      })
      .catch((e) => {
        console.log("Error re-scheduling PD: ");
        console.log(e);
        setError(true);
        setErrMsg(String(e));
      });
  };

  const getScheduleRowStyle = (rowPdId) => {
    if (rowPdId === showConfirmId) {
      return { borderBottomStyle: "hidden", borderTop: "3px solid #868e97" };
    } else {
      return {};
    }
  };

  return (
    <div>
      {(error || success) && (
        <div className='row mb-2'>
          <div className='col-12'>
            {error && <div className='alert alert-danger'>{errMsg}</div>}

            {success && (
              <div className='alert alert-success' role='alert'>
                {success}
              </div>
            )}
          </div>
        </div>
      )}

      <div className='row mb-4'>
        <div className='col-12'>
          {pdInfo.can_schedule_pd && pdInfo.can_schedule_pd.length > 0 && (
            <div>
              {showScheduleForm ? (
                <div>
                  <button
                    className='btn btn-sm btn-warning'
                    onClick={() => {
                      handleSchedulePd();
                    }}
                    disabled={!scheduleDatetime || !scheduleLevel}
                  >
                    Confirm PD Date and Time
                  </button>
                  <button
                    className='btn btn-sm btn-danger ml-2'
                    onClick={() => {
                      setShowScheduleForm(!showScheduleForm);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    setShowScheduleForm(!showScheduleForm);
                  }}
                >
                  Schedule a PD
                </button>
              )}

              {showScheduleForm && (
                <form>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      value={scheduleDatetime}
                      onChange={setScheduleDatetime}
                      style={{ marginTop: "16px" }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    select
                    className='ml-4 w-25'
                    label='PD Level'
                    value={scheduleLevel}
                    onChange={(e) => {
                      setScheduleLevel(e.target.value);
                    }}
                  >
                    {pdInfo.can_schedule_pd.map((option) => (
                      <MenuItem key={option} value={option}>
                        {pdValueToLabelMap[option]}
                      </MenuItem>
                    ))}
                  </TextField>
                </form>
              )}
            </div>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='col-12 table-responsive'>
          <h5>Scheduled PDs</h5>
          <table className='table table-sm'>
            <tbody className='sales_detail_table_tbody'>
              {pdInfo.scheduled_pds && pdInfo.scheduled_pds.length > 0 ? (
                pdInfo.scheduled_pds.map((el) => (
                  <React.Fragment key={el.id}>
                    <tr style={getScheduleRowStyle(el.id)}>
                      <td className='item-label'>
                        {pdValueToLabelMap[el.pd_type]}
                      </td>
                      {editMode === el.id ? (
                        <td>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                              value={newDatetime || el.scheduled_datetime}
                              onChange={setNewDatetime}
                            />
                          </MuiPickersUtilsProvider>
                        </td>
                      ) : (
                        <td>
                          <p>
                            {moment
                              .utc(el.scheduled_datetime)
                              .local()
                              .format("MMMM Do YYYY, h:mm a")}
                          </p>
                        </td>
                      )}
                      <td>
                        {editMode === el.id ? (
                          <React.Fragment>
                            <button
                              title='Save'
                              onClick={() => handleEditScheduledPD(el.id)}
                              className='btn btn-sm btn-danger'
                            >
                              <FA color='white' icon='save' />
                            </button>
                            <button
                              title='Cancel'
                              onClick={() => {
                                setEditMode(null);
                              }}
                              className='btn btn-sm btn-secondary ml-2'
                            >
                              <FA color='white' icon='times' />
                            </button>
                          </React.Fragment>
                        ) : (
                          <button
                            title='Edit'
                            disabled={inputLocked}
                            onClick={() => {
                              setEditMode(el.id);
                              setNewDatetime(null);
                            }}
                            className='btn btn-sm btn-secondary'
                          >
                            <FA color='white' icon='edit' />
                          </button>
                        )}
                      </td>
                      <td style={{ float: "right" }}>
                        {showConfirmId === null && (
                          <button
                            className='btn btn-sm btn-primary'
                            disabled={inputLocked}
                            onClick={() => {
                              setShowConfirmId(el.id);
                              setDeliveredBy("");
                              setEditMode(null);
                            }}
                          >
                            <FA color='white' icon='calendar-check' />
                          </button>
                        )}
                        {showConfirmId === el.id && (
                          <React.Fragment>
                            <button
                              className='btn btn-sm btn-warning'
                              onClick={() => {
                                handleMarkComplete(el.id);
                              }}
                              disabled={!completionDate || !deliveredBy}
                            >
                              Confirm Completion
                            </button>
                            <button
                              className='btn btn-sm btn-danger ml-2'
                              onClick={() => {
                                setShowConfirmId(null);
                                setDeliveredBy("");
                              }}
                            >
                              Cancel
                            </button>
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                    {showConfirmId === el.id && (
                      <React.Fragment>
                        <tr className=''>
                          <td
                            className='item-label py-4'
                            style={{ marginTop: "16px" }}
                          >
                            Delivered On:
                          </td>
                          <td className=''>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <DateTimePicker
                                value={completionDate}
                                onChange={setCompletionDate}
                                style={{ marginTop: "16px" }}
                              />
                            </MuiPickersUtilsProvider>
                          </td>
                          <td className=''>
                            <TextField
                              label='Delivered By'
                              value={deliveredBy}
                              onChange={(e) => setDeliveredBy(e.target.value)}
                            />
                          </td>
                          <td></td>
                        </tr>
                        <tr
                          className=''
                          style={{
                            borderTopStyle: "hidden",
                            borderBottom: "3px solid #868e97",
                          }}
                        >
                          <td className='item-label pb-4'>Notes:</td>
                          <td className='item-value' colSpan='3'>
                            <TextField
                              style={{ width: "100%" }}
                              size='small'
                              multiline
                              variant='outlined'
                              minRows={3}
                              maxRows={5}
                              value={pdNote}
                              onChange={(e) => {
                                setPdNote(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td className='item-label' colSpan='3'>
                    No items available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <h5>Completed PDs</h5>
          <table className='table table-sm'>
            <tbody className='sales_detail_table_tbody'>
              {pdInfo.completed_pds && pdInfo.completed_pds.length > 0 ? (
                pdInfo.completed_pds.map((el) => (
                  <React.Fragment key={el.id}>
                    <tr>
                      <td className='item-label'>
                        {pdValueToLabelMap[el.pd_type]}
                      </td>

                      {editMode === el.id ? (
                        <td>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                              value={newDeliveredDatetime}
                              onChange={setNewDeliveredDatetime}
                            />
                          </MuiPickersUtilsProvider>
                        </td>
                      ) : (
                        <td>
                          <p>
                            {moment
                              .utc(el.delivery_datetime)
                              .local()
                              .format("MMMM Do YYYY, h:mm a")}
                          </p>
                        </td>
                      )}

                      {editMode === el.id ? (
                        <td>
                          <TextField
                            value={deliveredBy}
                            onChange={(e) => setDeliveredBy(e.target.value)}
                          />
                        </td>
                      ) : (
                        <td>{el.delivered_by}</td>
                      )}

                      <td style={{ float: "right" }}>
                        <button
                          className='btn btn-sm btn-primary'
                          onClick={() => {
                            if (showMoreId !== el.id) {
                              setShowMoreId(el.id);
                              setEditMode(null);
                              resetEditFields();
                            } else {
                              setShowMoreId(null);
                              setEditMode(null);
                              resetEditFields();
                            }
                          }}
                        >
                          {showMoreId === el.id ? (
                            <FA color='white' icon='caret-square-up' />
                          ) : (
                            <FA color='white' icon='caret-square-down' />
                          )}
                        </button>
                      </td>
                    </tr>
                    {showMoreId === el.id && (
                      <tr
                        className=''
                        style={{
                          borderTopStyle: "hidden",
                          borderBottom: "3px solid #868e97",
                        }}
                      >
                        <td className='item-label py-4'>Notes:</td>

                        {editMode === el.id ? (
                          <td className='item-value' colSpan='2'>
                            <TextField
                              style={{ width: "100%" }}
                              size='small'
                              multiline
                              variant='outlined'
                              minRows={3}
                              maxRows={5}
                              value={pdNote}
                              onChange={(e) => {
                                setPdNote(e.target.value);
                              }}
                            />
                          </td>
                        ) : (
                          <td colSpan='2'>{el.notes || "No notes."}</td>
                        )}

                        <td style={{ float: "right" }}>
                          {editMode === el.id ? (
                            <React.Fragment>
                              <button
                                title='Save'
                                onClick={() => handleEditCompletedPD(el.id)}
                                className='btn btn-sm btn-danger'
                              >
                                <FA color='white' icon='save' />
                              </button>
                              <button
                                title='Cancel'
                                onClick={() => {
                                  setEditMode(null);
                                  resetEditFields();
                                }}
                                className='btn btn-sm btn-secondary ml-2'
                              >
                                <FA color='white' icon='times' />
                              </button>
                            </React.Fragment>
                          ) : (
                            <button
                              title='Edit'
                              disabled={inputLocked}
                              onClick={() => {
                                setNewDeliveredDatetime(el.delivery_datetime);
                                setDeliveredBy(el.delivered_by);
                                setPdNote(el.notes);
                                setEditMode(el.id);
                              }}
                              className='btn btn-sm btn-secondary'
                            >
                              <FA color='white' icon='edit' />
                            </button>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td className='item-label' colSpan='3'>
                    No items available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, withAPI)(SalesInfoPdTab);
