import React, { useEffect, useState } from "react";
import Select from "react-select";
import config from "../../../../common/config";
import LearnerImpactStats from "../../../../components/UsageImpactStats/LearnerImpactStats";
import FA from "../../../../containers/fa";
import LearnerByTimeCarousel from "./LearnerByTimeCarousel";
import "./LearnerProfile.scss";
import ProfileStatsInfoModal from "./ProfileStatsInfoModal";
import ReportsLearnerStatsTable from "./ReportsLearnerStatsTable";

const LearnerProfile = ({
  error,
  fetchProfileStatsErr,
  ready,
  activeLearner,
  setActiveLearner,
  allLearners,
  profileStats,
  profileStatsLoaded,
  customLessonsOnly,
  userType,
  rerouteObject,
  showFullView,
}) => {
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);

  useEffect(() => {
    let all_options = [];
    if (!allLearners) return;
    allLearners.forEach((v) => {
      if (v["learners"]) {
        all_options = all_options.concat(v["learners"]);
      }
    });
    let organizedOptions = {};

    for (let i = 0; i < all_options.length; i++) {
      if (organizedOptions[all_options[i].classId]) {
        //class already exists
        organizedOptions[all_options[i].classId].options.push(all_options[i]);
      } else {
        organizedOptions[all_options[i].classId] = {
          classId: all_options[i].classId,
          label: all_options[i].className,
          options: [],
        };
        organizedOptions[all_options[i].classId].options.push(all_options[i]);
      }
    }
    organizedOptions = Object.keys(organizedOptions).map((value, index) => {
      return organizedOptions[value];
    });
    setDisplayedOptions(organizedOptions);

    // Find the learner with the maximum lessons
    let maxLessonsLearner = null;
    organizedOptions.forEach((group) => {
      group.options.forEach((learner) => {
        if (
          !maxLessonsLearner ||
          learner.total_lessons > maxLessonsLearner.total_lessons
        ) {
          maxLessonsLearner = learner;
        }
      });
    });

    // Set the active learner as the one with the maximum lessons
    if (!activeLearner && maxLessonsLearner) {
      setActiveLearner(maxLessonsLearner);
    }
  }, [allLearners]);

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    fontSize: 16,
    color: "#666",
    fontWeight: "bold",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const customStyles = {
    option: (styles) => {
      return {
        ...styles,
      };
    },
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <div>
      <div className='learner_tab_header_container'>
        <h4 style={{ margin: "0px" }}>
          <b>Learner Profile</b>
        </h4>
      </div>

      <div className='learner_tab_body'>
        <ProfileStatsInfoModal
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
        />
        {ready &&
        !error &&
        !fetchProfileStatsErr &&
        ((allLearners && allLearners.length !== 0) ||
          userType === config.SUPERADMIN_USERTYPE ||
          userType === config.ORGADMIN_USERTYPE ||
          userType === config.ORGUNITADMIN_USERTYPE) ? (
          <div id='learner_options_container'>
            {!rerouteObject || showFullView ? (
              <div style={{ margin: "5px", gridArea: "a" }}>
                <Select
                  aria-label='Select a learner'
                  isSearchable={false}
                  formatGroupLabel={formatGroupLabel}
                  options={displayedOptions}
                  styles={customStyles}
                  isDisabled={!ready}
                  onChange={(selected) => {
                    setActiveLearner(selected);
                  }}
                  value={activeLearner}
                  getOptionValue={(option) => option.sid}
                  placeholder='Select a learner'
                />
              </div>
            ) : (
              <h5 style={{ margin: "10px 0px 10px 0px" }}>
                Viewing learner: <b>{activeLearner && activeLearner.label}</b>
              </h5>
            )}

            <ReportsLearnerStatsTable
              activeLearner={activeLearner}
              profileStats={profileStats}
              profileStatsLoaded={profileStatsLoaded}
              ready={ready}
            />
          </div>
        ) : ready &&
          !error &&
          !fetchProfileStatsErr &&
          allLearners &&
          allLearners.length === 0 ? (
          <div
            className='alert alert-danger'
            style={{ margin: "5px", height: "fit-content" }}
            role='alert'
          >
            <b>
              Note: This feature requires at least one class and one learner.
            </b>
          </div>
        ) : ready && (error || fetchProfileStatsErr) ? (
          <div
            className='alert alert-danger'
            style={{ margin: "5px", height: "fit-content" }}
            role='alert'
          >
            {error || fetchProfileStatsErr}
          </div>
        ) : !ready ? (
          <div style={{ margin: "5px" }}>
            <FA color='gray' icon='spinner' spin /> Loading...
          </div>
        ) : null}

        <LearnerByTimeCarousel
          profileStats={profileStats}
          activeLearner={activeLearner}
          ready={ready}
          profileStatsLoaded={profileStatsLoaded}
        />

        {ready &&
          !error &&
          activeLearner &&
          !fetchProfileStatsErr &&
          ((allLearners && allLearners.length !== 0) ||
            (rerouteObject && !showFullView)) && (
            <div className='col-12' style={{ gridArea: "d" }}>
              Note:
              <ul>
                {customLessonsOnly && (
                  <li>
                    This learner has only taken custom lessons, which are not
                    included in the info presented in this table.{" "}
                  </li>
                )}
                <li>
                  The above Learner Profile does not include lessons/assessments
                  taken since the last sync. Please sync your robot(s) for the
                  most up-to-date stats.
                </li>
                <li>
                  <button
                    className='like-link'
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      padding: "0 3px 0 0",
                    }}
                    onClick={() => {
                      setShowInfoModal(true);
                    }}
                  >
                    Click here
                  </button>
                  to learn more about our Learner Profile calculations.
                </li>
              </ul>
            </div>
          )}
      </div>

      {profileStats && profileStats.impact_stats && (
        <div className='learner_impact_container'>
          <LearnerImpactStats
            data={profileStats.impact_stats}
            isConsolidatedReports={true}
          />
        </div>
      )}
    </div>
  );
};

export default LearnerProfile;
