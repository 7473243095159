import React, { useEffect, useState } from "react";
import FA from "../../containers/fa";
import moment from "moment";
import { roundValues } from "../../common/utils";

import config from "../../common/config";
import ImpactStatsOvertime from "./ImpactStatsOvertime";
import ImpactStatsLatest from "./ImpactStatsLatest";
import ImpactStatsInfoIcon from "./ImpactStatsInfoIcon";
import PopupChart from "./PopupChart";

const ImpactStatsRow = ({
  subject,
  allData,
  showBySubject,
  setShowBySubject,
  variant,
}) => {
  const [pieChartData, setPieChartData] = useState(null);
  const [stackedChartData, setStackedChartData] = useState(null);
  const [hasData, setHasData] = useState(null);
  const [avgMastered, setAvgMastered] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const lowerSubject = subject.toLowerCase();

  useEffect(() => {
    if (allData) {
      preparePieChartData(allData);
      prepareStackedChartData(allData);
    }
  }, [allData]);

  const preparePieChartData = (allData) => {
    const pieLabels = [
      "Mastered Outright",
      "Mastered After Use",
      "Not Yet Mastered",
    ];
    const backgroundColors = [
      config.STANDARD_MASTERY_COLORS.mastered_outright,
      config.STANDARD_MASTERY_COLORS.mastered_eventually,
      config.STANDARD_MASTERY_COLORS.not_mastered,
    ];
    const lastInd = allData[lowerSubject].length - 1;
    const latestData = allData[lowerSubject][lastInd];
    let lastLearnerCount = null;
    if (allData["learner_counts"]) {
      lastLearnerCount = allData["learner_counts"][lastInd];
    }
    const pieData = {
      labels: pieLabels,
      datasets: [
        {
          label: `${subject} Stats`,
          data: [
            latestData["mastered_outright_count"],
            latestData["mastered_eventually_count"],
            latestData["not_mastered_count"],
          ],
          backgroundColor: backgroundColors,
          hoverOffset: 4,
        },
      ],
      extra: {
        learnerCount: lastLearnerCount,
      },
    };
    setPieChartData(pieData);
    if (lastLearnerCount) {
      const avgValue = roundValues(
        (latestData["mastered_eventually_count"] +
          latestData["mastered_outright_count"]) /
          allData["learner_counts"][lastInd],
        1
      );
      setAvgMastered(avgValue);
    } else {
      setAvgMastered(
        latestData["mastered_eventually_count"] +
          latestData["mastered_outright_count"]
      );
    }
  };

  const prepareStackedChartData = (allData) => {
    let stackedLabels = allData.timestamps;
    stackedLabels = stackedLabels.map((item) => {
      return moment.utc(item).local().format("MMM Do");
    });

    const subjectData = allData[lowerSubject];
    const percData = subjectData.map((item) => {
      let res = {};
      let sumCount =
        item.mastered_outright_count +
        item.mastered_eventually_count +
        item.not_mastered_count;
      res.mastered_outright_count = roundValues(
        (item.mastered_outright_count / sumCount) * 100,
        1
      );
      res.mastered_eventually_count = roundValues(
        (item.mastered_eventually_count / sumCount) * 100,
        1
      );
      res.not_mastered_count = roundValues(
        (item.not_mastered_count / sumCount) * 100,
        1
      );
      return res;
    });

    const data = {
      labels: stackedLabels,
      datasets: [
        {
          label: "Mastered Outright",
          labelField: "mastered_outright_count",
          data: percData.map((item) => item.mastered_outright_count),
          backgroundColor: config.STANDARD_MASTERY_COLORS.mastered_outright,
          maxBarThickness: 128,
        },
        {
          label: "Mastered After Use",
          labelField: "mastered_eventually_count",
          data: percData.map((item) => item.mastered_eventually_count),
          backgroundColor: config.STANDARD_MASTERY_COLORS.mastered_eventually,
          maxBarThickness: 128,
        },
        {
          label: "Not Yet Mastered",
          labelField: "not_mastered_count",
          data: percData.map((item) => item.not_mastered_count),
          backgroundColor: config.STANDARD_MASTERY_COLORS.not_mastered,
          maxBarThickness: 128,
        },
      ],
    };
    setStackedChartData(data);
  };

  useEffect(() => {
    if (pieChartData) {
      if (pieChartData.datasets[0].data.reduce((a, b) => a + b) === 0) {
        setHasData(false);
      } else {
        setHasData(true);
      }
    }
  }, [pieChartData]);

  return (
    <div
      className={variant === "learnerLevel" ? "w-100" : "common_table_padding"}
      style={variant !== "learnerLevel" ? { paddingTop: "9px" } : {}}
    >
      <div className='row batch-row h-100'>
        <div className='col-12'>
          {pieChartData === null && variant !== "learnerLevel" && (
            <p>
              <FA icon='spinner' color='gray' spin />
              <span className='ml-2'>Rendering Impact Stats ...</span>
            </p>
          )}

          {hasData === false && (
            <div className='empty-stats-text'>
              <p>No stats for {subject} standards available yet.</p>
            </div>
          )}

          {hasData && (
            <div>
              <div className={"impact_stats_grid"}>
                <div className='whole-row'>
                  <span className='h4'>
                    <b>
                      {variant !== "learnerLevel" && subject !== "All"
                        ? subject
                        : ""}{" "}
                      Standards Mastery
                    </b>
                  </span>
                  <ImpactStatsInfoIcon
                    subject={subject}
                    variant={variant || "pieAndOvertime"}
                  />
                </div>

                <div className='whole-row'>
                  {variant === "learnerLevel" ? (
                    <p>
                      Learner has mastered {avgMastered}{" "}
                      {subject !== "All" ? subject : ""} standards to-date!
                    </p>
                  ) : (
                    <p>
                      On average, your active students have each mastered{" "}
                      {avgMastered} {subject !== "All" ? subject : ""} standards
                      to-date!
                    </p>
                  )}
                </div>

                <div id='pie_container'>
                  <ImpactStatsLatest
                    pieChartData={pieChartData}
                    subject={variant === "learnerLevel" ? subject : null}
                  />
                </div>

                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    minHeight: "280px",
                    margin: "auto",
                    backgroundColor: "#fff",
                    border: "1px solid #c4c4c4",
                  }}
                >
                  <button
                    className='org-actions btn btn-sm btn-primary'
                    data-title='View more...'
                    aria-label='View more'
                    onClick={() => {
                      setShowPopup(true);
                    }}
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "5px",
                      zIndex: "1",
                    }}
                  >
                    <FA icon='search' color='white' />
                  </button>

                  <ImpactStatsOvertime
                    subject={subject}
                    // for getting raw counts
                    allData={allData}
                    stackedChartData={stackedChartData}
                    variant={"summary"}
                    learnerLevel={variant === "learnerLevel"}
                  />

                  <PopupChart
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    // provide some attributes for popup
                    chartData={{
                      parsedData: stackedChartData.labels,
                      typeOfData: `${subject} Standards`,
                    }}
                    chartEl={
                      <ImpactStatsOvertime
                        subject={subject}
                        // for getting raw counts
                        allData={allData}
                        stackedChartData={stackedChartData}
                        variant={"full"}
                        learnerLevel={variant === "learnerLevel"}
                      />
                    }
                  />
                </div>
              </div>

              {setShowBySubject && (
                <button
                  style={{ width: "200px", float: "left" }}
                  className='btn btn-primary mt-4'
                  onClick={() => {
                    setShowBySubject(!showBySubject);
                  }}
                >
                  {showBySubject ? "Hide" : "Show"} Stats by Subject
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImpactStatsRow;
