import MomentUtils from "@date-io/moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import FA from "../../../containers/fa";
import {
  refreshSaleDetail as refreshSaleDetailA,
  refreshSubscriptionItems as refreshSubscriptionItemsA,
} from "../../../redux/actions";
import withAPI from "../../../services/api";
import DeleteSaleModal from "./DeleteSaleModal";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const mapStateToProps = (state, ownProps?) => ({
  refreshSaleDetail: state.superAdmin.refreshSaleDetail,
  refreshSubscriptionItems: state.superAdmin.refreshSubscriptionItems,
});

const SalesInfoStatusChanges = ({
  dispatch,
  api,
  statusChangeLogs,
  saleId,
  refreshSaleDetail,
  setTabIndex,
  refreshSubscriptionItems,
}) => {
  const classes = useStyles();
  const defaultStatus = "------";

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [showReminder, setShowReminder] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [generatedRegcode, setGeneratedRegcode] = useState(false);
  const [activatedSubscriptions, setActivatedSubscriptions] = useState(false);
  const [showDeleteSale, setShowDeleteSale] = useState(false);
  const [newStatus, setNewStatus] = useState(defaultStatus);
  const [newCreationDate, setNewCreationDate] = useState(null);
  const [editSaleStatusId, setEditSaleStatusId] = useState(null);

  const handleStatusChangeEdit = () => {
    setError(false);
    setErrMsg(null);
    const editData = {
      override_created_at: newCreationDate,
    };
    api
      .patchSaleStatus(editSaleStatusId, editData)
      .then((res) => {
        console.log(res);
        setEditSaleStatusId(null);
        setNewCreationDate(null);
        dispatch(refreshSaleDetailA(!refreshSaleDetail));
      })
      .catch((e) => {
        console.log("Error Updating Sale Status: ");
        console.log(e);
        setError(true);
        setErrMsg("Something went wrong ...");
      });
  };

  const handleStatusChangeSubmit = () => {
    setSuccess(false);
    setError(false);
    setErrMsg(null);
    if (newStatus !== defaultStatus) {
      api
        .updateSaleStatus(saleId, newStatus)
        .then((res) => {
          console.log(res);
          setSuccess(true);
          setShowConfirm(false);
          setNewStatus(defaultStatus);
          if (res.data.generated_regcode || res.data.activated_subscriptions) {
            setGeneratedRegcode(res.data.generated_regcode);
            setActivatedSubscriptions(res.data.activated_subscriptions);
          } else if (newStatus === "sale_lost") {
            setShowReminder("note");
          } else if (newStatus === "po_received") {
            setShowReminder("poNumber");
          } else {
            // trigger refresh sales obj afterwards
            dispatch(refreshSaleDetailA(!refreshSaleDetail));
          }
          dispatch(refreshSubscriptionItemsA(!refreshSubscriptionItems));
        })
        .catch((e) => {
          console.log("Error Changing Sale Status: ");
          console.log(e);
          setError(true);
          setErrMsg("Something went wrong ...");
        });
    }
  };

  const handleFinalizedStatus = (hasRegCode) => {
    if (hasRegCode) {
      // jump to Activation code tab
      setTabIndex(4);
      // trigger refresh sales obj afterwards
      dispatch(refreshSaleDetailA(!refreshSaleDetail));
    } else {
      // else it only activated subscriptions
      // jump to Subscription tab
      setTabIndex(3);
      dispatch(refreshSaleDetailA(!refreshSaleDetail));
    }
  };

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel id='demo-simple-select-label'>
                Assign New Status:
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={newStatus}
                onChange={(e) => {
                  setNewStatus(e.target.value);
                  setShowConfirm(false);
                }}
              >
                <MenuItem value={defaultStatus}>{defaultStatus}</MenuItem>
                <MenuItem value={"quote_sent"}>Quote Sent</MenuItem>
                <MenuItem value={"po_received"}>PO Received</MenuItem>
                <MenuItem value={"robots_shipped"}>Robots Shipped</MenuItem>
                <MenuItem value={"invoice_sent"}>Invoice Sent</MenuItem>
                <MenuItem value={"invoice_paid"}>Invoice Paid</MenuItem>
                <MenuItem value={"sale_lost"}>Sale Lost</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className='row col-12'>
            {!showConfirm && newStatus !== defaultStatus && (
              <button
                className='btn btn-primary btn-sm'
                onClick={() => {
                  setShowConfirm(true);
                }}
              >
                Submit New Status
              </button>
            )}

            {showConfirm && (
              <div>
                <span>
                  Are you sure you want to change the Sales Status to{" "}
                  <strong>{newStatus}</strong>?
                </span>
                <br />
                <button
                  className='btn btn-primary btn-sm'
                  onClick={handleStatusChangeSubmit}
                >
                  Confirm Change
                </button>
                <button
                  className='btn btn-danger btn-sm ml-2'
                  onClick={() => {
                    setShowConfirm(false);
                    setNewStatus(defaultStatus);
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          {error && <div className='alert alert-danger'>{errMsg}</div>}

          {success && (
            <div className='alert alert-success mb-2' role='alert'>
              Successfully updated Sale status!
            </div>
          )}
        </div>
      </div>

      {(generatedRegcode || activatedSubscriptions) && (
        <div className='row py-2'>
          <div className='col-12'>
            <div className='alert alert-info'>
              {generatedRegcode && (
                <p className='mb-1'>
                  Since this Sale includes robot and/or Premium Care sales, an
                  Activation Code has been automatically generated.
                </p>
              )}
              {activatedSubscriptions && (
                <p className='mb-1'>
                  Subscriptions under this sale (e.g. LCT and/or Premium Care)
                  have been activated now.
                </p>
              )}
              <button
                className='btn btn-primary btn-sm'
                onClick={() => handleFinalizedStatus(generatedRegcode)}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      )}

      {showReminder && (
        <div className='row py-2'>
          <div className='col-12'>
            <div className='alert alert-warning'>
              {showReminder === "note" && (
                <p className='mb-3'>
                  Reminder: Please add any relevant notes about this sale in the
                  notes section on the Sales Detail tab.
                </p>
              )}
              {showReminder === "poNumber" && (
                <p className='mb-3'>
                  Reminder: Please navigate to the Sales Detail tab and enter
                  the PO number.
                </p>
              )}
              <button
                className='btn btn-primary btn-sm'
                onClick={() => {
                  setShowReminder(null);
                  // bring user to details tab
                  setTabIndex(0);
                  // trigger refresh sales obj afterwards
                  dispatch(refreshSaleDetailA(!refreshSaleDetail));
                }}
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      )}

      <div className='row'>
        <div className='col-12'>
          <table className='table table-striped table-sm'>
            <thead>
              <tr>
                <th className='datatable-header-cell'>Date</th>
                <th>New Status</th>
                <th>Changed By</th>
                <th></th>
              </tr>
            </thead>
            <tbody className='sales_detail_table_tbody'>
              {statusChangeLogs.map((obj) => (
                <tr key={obj.id}>
                  {editSaleStatusId === obj.id ? (
                    <td>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          format='MMM D, yyyy, hh:mm a'
                          value={newCreationDate}
                          onChange={setNewCreationDate}
                          renderInput={(params) => (
                            <TextField {...params} sx={{width: "80%"}} />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </td>
                  ) : (
                    <td>
                      {moment
                        .utc(obj.created_at)
                        .local()
                        .format("MMMM Do YYYY, h:mm a")}
                    </td>
                  )}
                  <td>{obj.status_display}</td>
                  <td>{obj.modified_by_email || "-"}</td>
                  {editSaleStatusId === obj.id ? (
                    <td>
                      <button
                        title='Save'
                        onClick={handleStatusChangeEdit}
                        className='btn btn-sm btn-primary m-1'
                      >
                        <FA color='white' icon='save' />
                      </button>
                      <button
                        title='Cancel'
                        onClick={() => {
                          setEditSaleStatusId(null);
                        }}
                        className='btn btn-sm btn-danger m-1'
                      >
                        <FA color='white' icon='times' />
                      </button>
                    </td>
                  ) : (
                    <td>
                      <button
                        data-title='Edit'
                        onClick={() => {
                          setEditSaleStatusId(obj.id);
                        }}
                        className='btn btn-sm btn-primary m-1'
                      >
                        <FA color='white' icon='edit' />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
              {statusChangeLogs.length === 0 && (
                <tr>
                  <td colSpan='3'>No status changes</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {statusChangeLogs.length === 1 && (
        <button
          className='btn btn-danger'
          onClick={() => {
            setShowDeleteSale(true);
          }}
        >
          Remove This Sale
        </button>
      )}

      {showDeleteSale && (
        <DeleteSaleModal saleId={saleId} setShouldShow={setShowDeleteSale} />
      )}
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(SalesInfoStatusChanges);
