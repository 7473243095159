import React from "react";
import { connect } from "react-redux";
import MainDashboard from "../partials/Main";
import UsageStats from "../components/UsageImpactStats/UsageStats";
import ImpactStats from "../components/UsageImpactStats/ImpactStats";
import { useTitle } from "../common/utils";
import { useState } from "react";

import config from "../common/config";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const UsageImpactStats = ({ user, history }) => {
  useTitle("Dashboard");
  const [schoolsFilter, setSchoolsFilter] = useState([]);

  return (
    <div>
      <MainDashboard>
        <UsageStats
          history={history}
          user={user}
          schoolsFilter={schoolsFilter}
          setSchoolsFilter={setSchoolsFilter}
        />
        {user.usertype === config.ORGADMIN_USERTYPE && (
          <ImpactStats user={user} schoolsFilter={schoolsFilter} />
        )}
        {user.usertype === config.ORGUNITADMIN_USERTYPE && (
          <ImpactStats user={user} schoolsFilter={schoolsFilter} />
        )}
      </MainDashboard>
    </div>
  );
};

export default connect(mapStateToProps)(UsageImpactStats);
