import React, {useState} from 'react';
import FA from '../../containers/fa';
import EditRegCodeModal from './EditRegCodeModal.js';
import SendRegCodeEmailModal from './SendRegCodeEmailModal.js';


const RegistrationCodeRow = ({ regcode, nullRow, refreshRegCodeList }) => {
  const [showSendRegCodeModal, setShowSendRegCodeModal] = useState(false);
  const [showEditRegCodeModal, setShowEditRegCodeModal] = useState(false);

  const accountMap = {
    true: 'Yes',
    false: 'No',
  }

  if (nullRow === true) {
    return (
        <tr>
          <td colSpan={6}>
            No Activation Codes Available.
          </td>
        </tr>
      )
  }

  return (
    <tr key={regcode.code}>
      <td>
        {regcode.code &&
          <p>{regcode.code}</p>}
      </td>
      
      <td>
        {regcode.code &&
          <p>{regcode.max_robots}</p>}
      </td>
      
      <td>
        {regcode.code &&
          <p>{regcode.associated_robot_count}</p>}
      </td>

      <td>
        {regcode.code &&
          <p>{accountMap[regcode.cloud_accounts]}</p>
        }
      </td>

      <td>
        {regcode.code &&
          <button
            onClick={() => {setShowSendRegCodeModal(true)}}
            className="btn btn-sm btn-secondary"
            title="You will have a chance to confirm before the email is sent.">
            <FA color="white" icon="envelope"/>
          </button>}

        {showSendRegCodeModal &&
          <SendRegCodeEmailModal 
            shouldShowFunc={setShowSendRegCodeModal}
            regCodeId={regcode.id}
            orgEmail={regcode.org_email}
          />
        }
      </td>

      <td>
        {regcode.code &&
          <button
            onClick={() => {setShowEditRegCodeModal(true)}}
            className="btn btn-sm btn-secondary">
            <FA color="white" icon="edit"/>
          </button>}

        {showEditRegCodeModal &&
          <EditRegCodeModal 
            shouldShowFunc={setShowEditRegCodeModal}
            regCode={regcode}
            refreshRegCodeList={refreshRegCodeList}
          />
        }
      </td>

    </tr>
  )
}

export default RegistrationCodeRow;
