import React, { useEffect, useState } from "react";
import { compose } from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import SchoolRow from "./SchoolRow.js";
import { getAllSchoolInfo } from "../InputFields/helpers.js";

const SchoolTable = ({ api, user, setSchoolsSectionloaded }) => {
  const [shouldSpin, setShouldSpin] = useState(true);
  const [error, setError] = useState(null);
  const [schools, setSchools] = useState([]);
  const [fetchSchoolsTrigger, setFetchSchoolsTrigger] = useState(false);

  const [addingSchool, setAddingSchool] = useState(false);
  const [saveSpin, setSaveSpin] = useState(false);
  const [newSchool, setNewSchool] = useState("");

  const sortedArray = (arr, colname) => {
    const result = [...arr].sort((a, b) =>
      a[colname].localeCompare(b[colname])
    );
    return result;
  };

  function triggerSchoolRefresh() {
    setFetchSchoolsTrigger(!fetchSchoolsTrigger);
  }

  useEffect(() => {
    getAllSchoolInfo(api)
      .then((schls) => {
        setSchools(sortedArray(schls, "name"));
        setShouldSpin(false);
        console.log("SchoolTable got Schools: ", schls);
        setSchoolsSectionloaded(true);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }, [fetchSchoolsTrigger]);

  const handleCreate = () => {
    setSaveSpin(true);
    let data = { name: newSchool, organization: user.organization.id };
    api
      .createOrgUnit(data)
      .then((resp) => {
        setSaveSpin(false);
        setNewSchool("");
        setAddingSchool(false);
        triggerSchoolRefresh();
      })
      .catch((err) => {
        setSaveSpin(false);
        console.log("API error'd", err);
      });
  };

  return (
    <div className='common_border'>
      <div className='common_heading d-flex justify-content-between align-items-center'>
        <h4>Schools</h4>
      </div>

      <div className='common_dashboard_bg'>
        <div className='row'>
          <div className='col-12'>
            <div className='row mb-10'></div>
            {error && (
              <div className='alert alert-danger' role='alert'>
                {error}
              </div>
            )}
            {shouldSpin && (
              <div>
                <h4>
                  <FA color='gray' icon='spinner' spin />
                  Loading Schools...
                </h4>
              </div>
            )}
            {!shouldSpin && (
              <div className='card'>
                <div
                  className='progress-linear school_listing manage_learner_table'
                  role='progressbar'
                >
                  <table className='table table-striped table-sm'>
                    <thead>
                      <tr>
                        <th className='datatable-header-cell py-3'>
                          {addingSchool ? (
                            <span>
                              <input
                                type='text'
                                name='New School'
                                aria-label='New School'
                                placeholer='New School'
                                value={newSchool}
                                onChange={(e) => setNewSchool(e.target.value)}
                              />
                              {saveSpin ? (
                                <button className='btn btn-sm btn-secondary'>
                                  <FA color='white' icon='spinner' spin />
                                </button>
                              ) : (
                                <button
                                  className='btn btn-sm btn-primary mx-2'
                                  data-title='Save School'
                                  onClick={() => handleCreate()}
                                >
                                  <FA color='white' icon='save' />
                                </button>
                              )}
                              &nbsp;
                              <button
                                className='btn btn-sm btn-secondary'
                                data-title='Cancel'
                                onClick={() => setAddingSchool(false)}
                              >
                                <FA color='white' icon='times' />
                              </button>
                            </span>
                          ) : (
                            <button
                              className='btn btn-sm btn-primary'
                              data-title='Add School'
                              onClick={() => setAddingSchool(true)}
                            >
                              Add School
                            </button>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {schools.map((schl) => (
                        <SchoolRow
                          key={schl.id}
                          school={schl}
                          refreshSchoolList={triggerSchoolRefresh}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI)(SchoolTable);
