import React, {useState} from "react";
import Select from "react-select";
import FA from "../../containers/fa";
import InfoPopup from "../UtilComps/InfoPopup";
import Assignment from "./Assignment";
import AssignmentDeleteModal from "./AssignmentDeleteModal";
import AssignmentsPagination from "./AssignmentsPagination";
import "./AssignmentsTable.scss";
import EditAssignmentModal from "./EditAssignmentModal";

const AssignmentsTable = ({
  initialLoad,
  fetchAssignments,
  assignmentsData,
  loading,
  setFilter,
  numberPerPage,
  setNumberPerPage,
  currentPage,
  setCurrentPage,
  numberOfAssignments,
  startIndex,
  endIndex,
  showSyncReminder,
  setShowSyncReminder,
  expandedAssignments,
  setExpandedAssignments,
}) => {
  const [assignmentToDelete, setAssignmentToDelete] = useState(null);
  const [assignmentToEdit, setAssignmentToEdit] = useState(null);
  const [popupInfo, setPopupInfo] = useState({
    header: "",
    text: "",
  });
  const [showPopupInfo, setShowPopupInfo] = useState(false);

  return (
    <div>
      <div id='assignment_filter_container'>
        <div style={{display: "flex", alignItems: "center"}}>
          <p id='assignment_filter_label'>Filter:</p>

          <div style={{margin: "5px"}}>
            <Select
              id='assignment_filter'
              isSearchable={false}
              onChange={(selected) => {
                setFilter(selected.value);
                setCurrentPage(1);
              }}
              defaultValue={{value: "None", label: "None"}}
              placeholder='Select a filter'
              options={[
                {value: "", label: "None"},
                {value: "completed", label: "Completed"},
                {value: "in_progress", label: "In Progress"},
                {value: "not_started", label: "Not Started"},
                {value: "postponed", label: "Postponed"},
                {value: "closed", label: "Closed"},
              ]}
            />
          </div>
        </div>

        <p
          style={{
            width: "fit-content",
            margin: "0px",
            textAlign: "left",
          }}
          className='alert alert-secondary'
        >
          Remember to keep your scores up-to-date by syncing ABii.
        </p>
      </div>

      <div id='assignment_table_container' className='visible-scrollbar'>
        {showPopupInfo && (
          <InfoPopup
            popupInfo={popupInfo}
            setShowPopupInfo={setShowPopupInfo}
          />
        )}

        {assignmentToDelete && (
          <AssignmentDeleteModal
            assignment={assignmentToDelete}
            shouldShowFunc={setAssignmentToDelete}
            fetchAssignments={fetchAssignments}
          />
        )}

        {assignmentToEdit && !showSyncReminder && (
          <EditAssignmentModal
            assignment={assignmentToEdit}
            shouldShowFunc={setAssignmentToEdit}
            fetchAssignments={fetchAssignments}
            setShowSyncReminder={setShowSyncReminder}
          />
        )}

        <div id='assignment_table_pagination_container'>
          <AssignmentsPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            numberPerPage={numberPerPage}
            setNumberPerPage={setNumberPerPage}
            numberOfAssignments={numberOfAssignments}
            startIndex={startIndex}
            endIndex={endIndex}
          />
        </div>

        <div id='assignments_list_header'>
          <div id='assignment_name_header'>
            <p>Assignment Name</p>
          </div>

          <div className='hide_on_ipad' style={{justifyContent: "center"}}>
            <p>Status</p>
          </div>

          <div>
            <p>Created</p>
          </div>

          <div>
            <p>Time Frame</p>
          </div>

          <div>
            <p>
              Overall Completion{" "}
              <button
                className='info-circle-btn'
                onClick={() => {
                  setPopupInfo({
                    header: "Overall Completion",
                    text: `Overall completion combines the progress of all
                              assigned learners and determines how much work is left
                              until all learners complete the assigned lessons.`,
                  });
                  setShowPopupInfo(true);
                }}
              >
                <FA
                  icon='info-circle'
                  className='info-circle robots_table_hr'
                />
              </button>
            </p>
          </div>

          <div>
            <p>
              Learners Finished{" "}
              <button
                className='info-circle-btn'
                onClick={() => {
                  setPopupInfo({
                    header: "Learners Finished",
                    text: `A learner is finished with the assignment if the
                              learner has completed all lessons in the assignment.`,
                  });
                  setShowPopupInfo(true);
                }}
              >
                <FA
                  icon='info-circle'
                  className='info-circle robots_table_hr'
                />
              </button>
            </p>
          </div>

          <div>
            <p>Learners</p>
          </div>

          <div>
            <p>Lessons</p>
          </div>

          <div className='hide_on_ipad' style={{justifyContent: "center"}}>
            <p>Actions</p>
          </div>
        </div>
        {loading ? (
          <div style={{textAlign: "center", margin: "5px 0px 5px 0px"}}>
            Loading... <FA icon='spinner' spin />{" "}
          </div>
        ) : assignmentsData.length === 0 ? (
          <div style={{textAlign: "center", margin: "5px 0px 5px 0px"}}>
            No assignments found.
          </div>
        ) : (
          <div>
            {assignmentsData.map((assignment, index) => {
              return (
                <Assignment
                  initialLoad={initialLoad}
                  index={index}
                  key={index}
                  assignment={assignment}
                  expandedAssignments={expandedAssignments}
                  setExpandedAssignments={setExpandedAssignments}
                  setAssignmentToDelete={setAssignmentToDelete}
                  setAssignmentToEdit={setAssignmentToEdit}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignmentsTable;
