import React, {useState} from "react";
import FA from "../../containers/fa";
import InfoPopup from "../UtilComps/InfoPopup";

const ImpactStatsInfoIcon = ({subject, variant}) => {
  const [infoText, setInfoText] = useState(null);

  let subjectDisplay;
  if (!subject || subject === "All") {
    subjectDisplay = "Math and Reading";
  } else {
    subjectDisplay = subject;
  }

  return (
    <div style={{display: "inline-flex"}}>
      {infoText && (
        <InfoPopup popupInfo={infoText} setShowPopupInfo={setInfoText} />
      )}

      <button
        className='info-circle-btn'
        onClick={() => {
          setInfoText({
            header: `Standards Mastery Status for ${subjectDisplay}`,
            html: (
              <div>
                {variant === "pieOnly" && (
                  <p>
                    This section shows the current Standards Mastery Status for{" "}
                    {subjectDisplay}. The counts of Standards are summed across
                    all <strong>active</strong> learners in your classes.
                  </p>
                )}
                {variant === "pieAndOvertime" && (
                  <p>
                    This section shows the current Standards Mastery Status for{" "}
                    {subjectDisplay}, and how it has changed over time. The
                    counts of Standards in each snapshot are summed across all{" "}
                    <strong>active</strong> learners in your organization.
                  </p>
                )}
                {variant === "learnerLevel" && (
                  <p>
                    This section shows the current Standards Mastery Status for
                    Math and Reading, and how it has changed over time, for the
                    selected learner.
                  </p>
                )}
                <ul>
                  <li>
                    <strong>Mastered Outright: </strong>
                    Standards for which the learners have mastered on their
                    first attempt.
                  </li>
                  <li>
                    <strong>Mastered After Use: </strong>
                    Standards for which the learners failed to master on their
                    first attempt, but went on to master in later attempts.
                  </li>
                  <li>
                    <strong>Not Yet Mastered: </strong>
                    Standards for which the learners have attempted but yet to
                    master.
                  </li>
                </ul>
              </div>
            ),
          });
        }}
      >
        <FA
          icon='info-circle'
          className='info-circle robots_table_hr'
          title={"What's this"}
        />
      </button>
    </div>
  );
};

export default ImpactStatsInfoIcon;
