import {useEffect} from "react";
import {useLocation} from "react-router-dom";

/**
 * ScrollToTop ensures that the user will start at the top of the page when
 * they visit.
 * @source https://stackoverflow.com/a/61602724/9457623
 * @returns null
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}