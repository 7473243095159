import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";

const ClassDeleteModal = ({
  api,
  shouldShowFunc,
  clsName,
  clsId,
  refreshList,
}) => {
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    api
      .deleteUserTag(clsId)
      .then((data) => {
        console.log("Delete op returned: ", data);
        shouldShowFunc(false);
        setLoading(false);
        refreshList();
      })
      .catch(() => {
        setErrMsg("Unknown error.");
        setError(true);
        setLoading(false);
      });
    return;
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Delete {clsName}</p>
            <button
              className='pdf_popup_close'
              onClick={() => {
                if (loading) {
                  return;
                }
                shouldShowFunc(false);
              }}
            >
              <FA icon='times' />
            </button>
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && <div className='alert alert-danger'>{errMsg}</div>}
                {loading ? (
                  <div>
                    <p>
                      <FA color='gray' icon='spinner' spin /> Deleting class...
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>Are you sure you want to delete this class?</p>
                    <p style={{fontSize: 14, marginBottom: 24}}>
                      <strong>Note: </strong>Learners who are not in any of your
                      other classes, will be removed from your open assignments
                      (Not Started, Postponed, or In-Progress).
                    </p>
                  </div>
                )}
                <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                  <button
                    style={{width: "fit-content", margin: "auto"}}
                    disabled={loading}
                    onClick={() => shouldShowFunc(false)}
                    className='btn btn-sm btn-primary'
                  >
                    No, take me back
                  </button>
                  <button
                    style={{width: "fit-content", margin: "auto"}}
                    disabled={loading}
                    onClick={handleDelete}
                    className='btn btn-sm btn-danger'
                  >
                    Yes, delete this class
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, withAPI)(ClassDeleteModal);
