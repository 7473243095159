import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withAPI from '../services/api';
import { authService } from '../services/auth';

import FA from '../containers/fa';
import FAW from '../containers/faw';

const LearnerLoginForm = ({api, history, dispatch, match}) => {
    const [pass, setPass] = useState("");
    const [error, setError] = useState(null);
    const [spin, setSpin] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
      setSpin(true);
      authService.learnerLogin(api.getToken(), match.params.learnerUsername, pass, match.params.clsId)
        .then((resp_json) => {
          // Just like, GO there man!
          window.open(resp_json.url);
          history.goBack();
        })
        .catch((err) => {
          setSpin(false);
          if (err.type) {
            setError(err.message[0]);
          } else {
            setError(err.message);
          }
        })
    }

    useEffect(() => {
        // Whether or not to play this is checked in monitor,
        // through the learner_id passed
        api.studentloginaudio(match.params.learnerId);
    }, []);

    return (
      <form name="LogInForm" noValidate autoComplete="off">
        <div className="form-content">
          <label className="text-left bold_font">Enter Password</label>
            <div className="form-group">
                <div className="form_icons">
                    <label><FA icon="lock" /></label>
                    <input required className="form-control input-underline input-lg"
                           placeholder="Password" type="password" value={pass} onChange={(e) => setPass(e.target.value)}/>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
            </div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button type="submit" onClick={handleSubmit} className="btn btn-lg fs-14 btn-primary col-md-5">
            {spin
              ? <FAW icon="spinner" spin />
              : <span>Login</span>
            }
          </button>
        </div>
    </form>
    );
  };

export default compose(
  withRouter,
  connect(),
  withAPI,
)(LearnerLoginForm);
