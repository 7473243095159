import React, { useEffect } from 'react';
import Gate from '../partials/Gate';
import withAPI from '../services/api';
import { connect } from 'react-redux';
import { compose } from 'redux';

const WiFiLoggedOut = () => {
  return (
    <Gate>
      <div className="logged-out-box">
        <h3>
          ABii is rebooting.
        </h3>
        <p>ABii needs to reboot to setup the new WiFi connection. This may take a few minutes, so hold tight.</p>
        <p>Once ABii starts talking again, you'll need to rejoin ABii's wifi network and navigate back to the login page,
           which can be reached <a href="/">here.</a>
        </p>
      </div>
    </Gate>
  )
}

export default WiFiLoggedOut;
