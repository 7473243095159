import React, {useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import FA from "../../../containers/fa";
import "./AddUserToSubscriptionPopup.scss";
import { getUserRoleLabel } from "../../../common/utils";
const AddUserToSubscriptionPopup = ({
  subscriptionAssignmentInfo,
  handleAddObject,
  setshowAddUsersPopup,
  usedSlots,
  maxSlots,
}) => {
  const [options, setOptions] = useState([]);
  const [listOfSelectedUsers, setListOfSelectedUsers] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [debouncedSearchVal] = useDebounce(searchVal, 500);
  /**
   * searchOptions updates the searchVal when the user types in the search box,
   * which is in turn debounced and updates the list of possible users to
   * select.
   * @param {String} val Current string in the search bar.
   * @returns
   */
  const searchOptions = (val) => {
    if (val === undefined) {
      setSearchResults([]);
      setSearching(false);
      return;
    }
    let results = options.filter(
      (value) =>
        value.label &&
        value.label.toLowerCase().indexOf(val.toLowerCase()) !== -1
    );
    setSearchResults(results);
    setSearching(false);
  };

  useEffect(() => {
    searchOptions(debouncedSearchVal);
  }, [debouncedSearchVal]);

  useEffect(() => {
    if (searchVal) setSearching(true);
  }, [searchVal]);

  /**
   * buildOptions iterates through all users who aren't already assigned to the
   * subscriptions and creates an object array.
   */
  const buildOptions = () => {
    let newOptionsList = [];
    for (
      let i = 0;
      i < subscriptionAssignmentInfo.addable_objects.length;
      i++
    ) {
      let addable = subscriptionAssignmentInfo.addable_objects[i];
      newOptionsList.push({
        key: addable.id,
        id: addable.id,
        label: addable.username,
        first_name: addable.first_name,
        last_name: addable.last_name,
        usertype: addable.userprofile.usertype,
      });
    }
    setOptions(newOptionsList);
    setSearchResults(newOptionsList);
  };

  useEffect(() => {
    buildOptions();
  }, []);

  const submitUsers = () => {
    setSubmitting(true);
    handleAddObject(listOfSelectedUsers);
  };

  return (
    <div className='pdf-upload'>
      <div
        id='add_user_to_subscription_wrapper'
        className='pdf-upload-wrapper-large'
      >
        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <p>Add Users to Subscription</p>
            <div
              className='pdf_popup_close'
              onClick={() => {
                setshowAddUsersPopup(false);
              }}
            >
              <FA icon='times' />
            </div>
          </div>
        </div>

        <div
          id='user_search_popup_body'
          className='common_border'
          style={{height: "fit-content"}}
        >
          <div
            id='user_search_common_dashboard_bg'
            className='common_dashboard_bg'
          >
            <div id='user_search_bar_container'>
              <label className='mr-2 pt-2'>
                <FA icon='search' />
              </label>
              <input
                id='user_search_bar'
                placeholder='Search for a user...'
                name='search-class-or-learner'
                className='form-control input-underline'
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                onFocus={() => {
                  if (debouncedSearchVal) searchOptions(debouncedSearchVal);
                }}
              />
              {error ? (
                <div className='alert alert-danger'>{error}</div>
              ) : (
                <div className='ml-2'>
                  <b>{maxSlots - (usedSlots + listOfSelectedUsers.length)}</b> out of{" "}
                  <b>{maxSlots}</b> slots are available from active subscriptions
                  and selections.
                </div>
              )}
            </div>

            <div id='user_search_results_container'>
              <div>
                <hr style={{borderBottom: "2px solid black"}} />
                <h5>Search Results:</h5>
              </div>

              {searching ? (
                <div>
                  <FA icon='spinner' spin /> Searching...
                </div>
              ) : submitting ? (
                <div>
                  <FA icon='spinner' spin /> Submitting...
                </div>
              ) : searchResults.length !== 0 ? (
                <div
                  id='user_search_results_table'
                  className='visible-scrollbar'
                >
                  <div id='user_search_hr'>
                    <p>First Name:</p>
                    <p>Last Name:</p>
                    <p>User Type:</p>
                    <p>Username:</p>
                    <div />
                  </div>

                  {searchResults.map((user, index) => {
                    return (
                      <div
                        key={index}
                        className='user_search_option'
                        onClick={() => {
                          if (listOfSelectedUsers.indexOf(user.id) === -1) {
                            if (
                              usedSlots >= maxSlots ||
                              usedSlots + listOfSelectedUsers.length >= maxSlots
                            ) {
                              setError("Max number of slots used.");
                              return;
                            } else {
                              setError("");
                            }
                            setListOfSelectedUsers([
                              ...listOfSelectedUsers,
                              user.id,
                            ]);
                          } else {
                            setListOfSelectedUsers(
                              listOfSelectedUsers.filter((options) => {
                                return options !== user.id;
                              })
                            );
                            setError("");
                          }
                        }}
                      >
                        <p>{user.first_name}</p>
                        <p>{user.last_name}</p>
                        <p>{getUserRoleLabel(user.usertype)}</p>
                        <p>{user.label}</p>
                        <div>
                          <input
                            aria-label={
                              "Select this user: " +
                              user.first_name +
                              " " +
                              user.last_name
                            }
                            type='checkbox'
                            name='rbt-checkbox'
                            className='form-control medium-checkbox'
                            checked={
                              listOfSelectedUsers.indexOf(user.id) !== -1
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                if (
                                  usedSlots >= maxSlots ||
                                  usedSlots + listOfSelectedUsers.length >=
                                    maxSlots
                                ) {
                                  setError("Max number of slots used.");
                                  return;
                                } else {
                                  setError("");
                                }
                                setListOfSelectedUsers([
                                  ...listOfSelectedUsers,
                                  user.id,
                                ]);
                              } else {
                                setListOfSelectedUsers(
                                  listOfSelectedUsers.filter((options) => {
                                    return options !== user.id;
                                  })
                                );
                                setError("");
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>No users found.</div>
              )}
            </div>

            <div id='add_user_to_subscription_btns_container'>
              <button
                className='btn btn-md btn-primary'
                disabled={listOfSelectedUsers.length === 0}
                onClick={() => submitUsers()}
              >
                {listOfSelectedUsers.length !== 0
                  ? "Add Users"
                  : "No Users Selected"}
              </button>
              <button
                className='btn btn-md btn-danger'
                onClick={() => setshowAddUsersPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserToSubscriptionPopup;
