import React from "react";
const NoReportsForClassMsg = () => {
  return (
    <div className='alert alert-warning' style={{margin: "5px"}} role='alert'>
      <b>{`This class has no learner reports.`}</b>
      <br />
      {`When learners take lessons with ABii, synced results will be available
      on this page. If your learners have already taken lessons with ABii,
      please make sure their progress is synced to the cloud and try again.`}
    </div>
  );
};

export default NoReportsForClassMsg;