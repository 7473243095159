import React from "react";
import StepTwoLearnersRow from "./StepTwo/StepTwoLearnersRow";
import ClosePopupBtn from '../../../UtilComps/ClosePopupBtn.js';
const SelectedLearnersPopup = ({
  shouldShowFunc,
  learners,
  setLearners,
  selectedLearners,
  setSelectedLearners,
  numOfSelectedLessons,
}) => {
  return (
    <div className='pdf-upload' style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
      <div className='"pdf-upload-wrapper create_assignment_table_popup'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Selected Learners</p>
            <ClosePopupBtn closePopupFunc={shouldShowFunc} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <div className='create_assignment_popup_grid'>
                  <p>
                    This is your list of selected lessons across all courses. If
                    you want to remove a lesson from this list, simply click its
                    checkbox to unselect it.
                  </p>
                  <div className='create_assignment_table_container popup_table_container visible-scrollbar'>
                    <div className='choose_learner_table'>
                      <div className='choose_learner_table_header'>
                        <p></p>
                        <p>Learner Name</p>
                        <p>Username</p>
                        <p style={{textAlign: "center"}}>
                          Lessons completed from lesson selections
                        </p>
                        <p style={{textAlign: "center"}}>Grade</p>
                        <p style={{textAlign: "center"}}>Selected</p>
                      </div>

                      {selectedLearners.length > 0 ? (
                        selectedLearners.map((value, index) => (
                          <StepTwoLearnersRow
                            key={index}
                            learner={value}
                            learners={learners}
                            setLearners={setLearners}
                            setLessons={setLearners}
                            selectedLearners={selectedLearners}
                            setSelectedLearners={setSelectedLearners}
                            numOfSelectedLessons={numOfSelectedLessons}
                            isPopup={true}
                          />
                        ))
                      ) : (
                        <div className='no_lessons_to_select'>
                          No learners selected.
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gridGap: "10px",
                    }}
                  >
                    <button
                      style={{margin: "auto"}}
                      onClick={() => shouldShowFunc(false)}
                      className='btn btn-sm btn-primary'
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedLearnersPopup;
