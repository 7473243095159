import React, { useEffect, useState } from "react";
import ClassTabContent from "../../components/ConsolidatedReports/ClassTab/ClassTabContent";
import LearnerTabContent from "../../components/ConsolidatedReports/LearnerTab/LearnerTabContent";
import MainDashboard from "../../partials/Main";
import "./ConsolidatedReports.scss";
import { sortedArray, useTitle } from "../../common/utils";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withAPI from "../../services/api";
import ConsolidatedReportsSearchBar from "./ConsolidatedReportsSearchBar";
import { useLocation } from "react-router-dom";
import config from "../../common/config";
import RerouteObjectMsg from "./RerouteObjectMsg";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  hasRobots: state.auth.hasRobots,
});

const ConsolidatedReports = ({ api, history, user, hasRobots }) => {
  useTitle("Reports");

  let location = useLocation();
  const showFullView = location.state?.rerouteSource === "myClass";
  const isTeacher = user.usertype === config.TEACHER_USERTYPE;
  const rerouteObject = location.state?.rerouteObject;
  const rerouteObjectType = location.state?.rerouteObjectType;
  const [activeTab, setActiveTab] = useState(rerouteObjectType || "class");
  const [classes, setClasses] = useState(null);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [allLearners, setAllLearners] = useState([]);
  const [selectedSearchVal, setSelectedSearchVal] = useState(null);
  const [viewLearnerInClass, setViewLearnerInClass] = useState(false);

  /**
   * fetchClassData gets the teacher's classes and invokes the calls for course
   * data, learners, reports, and lessons.
   */
  const fetchClassData = () => {
    api
      .fetchObjectsForTeacher()
      .then((objects) => {
        let newArr = sortedArray(objects.usertags, "created_at");
        setClasses(newArr);

        console.log(newArr);
        if (!newArr || newArr.length === 0) {
          setError(`No classes.`);
          setReady(true);
          return;
        }
      })
      .catch((e) => {
        setReady(true);
        console.log(e);
        setError(
          `Something went wrong fetching class data. Please make sure you are
          connected to the internet and try again in a few seconds.`
        );
      });
  };

  /**
   * fetchLearnersForClassData gets a list of all learners in the classes.
   * @param {integer} classId
   */
  const fetchLearnersForClassData = () => {
    let classesAndLearners = [];
    const classIDs = classes.map((item) => item.id);
    if (classIDs.length > 0) {
      const postData = { class_ids: classIDs };
      api
        .fetchLearnersForMultiClasses(postData)
        .then((resp_json) => {
          console.log(resp_json);
          for (let i = 0; i < resp_json.length; i++) {
            let classData = { classId: resp_json[i].class_id };
            let learnerData = resp_json[i].learners.map((item) => {
              return {
                className: resp_json[i].class_name,
                classId: resp_json[i].class_id,
                username: item.user["username"],
                label: `${item.user["first_name"]} ${item.user["last_name"]}`,
                first_name: item.user["first_name"],
                last_name: item.user["last_name"],
                sid: item.id,
                total_lessons: item.total_lessons,
              };
            });
            classData["learners"] = learnerData;
            classesAndLearners.push(classData);
          }

          console.log("SETTING ALL LEARNERS:", classesAndLearners);
          setAllLearners(classesAndLearners);
          setReady(true);
        })
        .catch((e) => {
          setReady(true);
          console.log(e);
          setError(
            `Something went wrong fetching Learners. Please make sure you are
            connected to the internet and try again in a few seconds.`
          );
        });
    }
  };

  useEffect(() => {
    fetchClassData();
    if (rerouteObject && rerouteObjectType) {
      if (rerouteObjectType === "class") {
        setSelectedSearchVal({
          ...rerouteObject,
          type: rerouteObjectType,
          label: rerouteObject.name,
          value: rerouteObject.id,
        });
      } else if (rerouteObjectType === "learner") {
        setSelectedSearchVal({
          ...rerouteObject,
          type: rerouteObjectType,
        });
        setActiveTab("learner");
      }
    }
  }, []);

  // if we select an option from the search bar or click the View Reports
  // action btn, scroll to the top.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedSearchVal]);

  return (
    <div>
      <MainDashboard>
        <div className='common_border'>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <p>Reports</p>
            <div className='d-flex justify-content-end'>
              {rerouteObjectType && !showFullView ? (
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </button>
              ) : (
                <ConsolidatedReportsSearchBar
                  setActiveTab={setActiveTab}
                  classes={classes}
                  allLearners={allLearners}
                  setSelectedSearchVal={setSelectedSearchVal}
                />
              )}
            </div>
          </div>
          <div className='common_table_padding'>
            <div id='consolidated_reports_content'>
              {rerouteObjectType && !showFullView && (
                <RerouteObjectMsg
                  user={user}
                  hasRobots={hasRobots}
                  rerouteObjectType={rerouteObjectType}
                  rerouteObject={rerouteObject}
                />
              )}

              <div id='tabs_container'>
                {(!rerouteObjectType ||
                  rerouteObjectType === "class" ||
                  showFullView) && (
                  <button
                    className='tab'
                    style={{ height: activeTab === "class" ? "70px" : "50px" }}
                    onClick={() => setActiveTab("class")}
                  >
                    <h4>Class</h4>
                  </button>
                )}

                {(!rerouteObjectType ||
                  rerouteObjectType === "learner" ||
                  showFullView ||
                  viewLearnerInClass) && (
                  <button
                    className='tab'
                    tabindex='0'
                    style={{
                      height: activeTab === "learner" ? "70px" : "50px",
                    }}
                    onClick={() => setActiveTab("learner")}
                  >
                    <h4>Learner</h4>
                  </button>
                )}
              </div>
              <div id='consolidated_report_body'>
                <div
                  style={{ display: activeTab === "class" ? "block" : "none" }}
                >
                  <ClassTabContent
                    classes={classes}
                    ready={ready}
                    setReady={setReady}
                    error={
                      (!isTeacher && error !== "No classes.") || isTeacher
                        ? error
                        : null
                    }
                    setError={setError}
                    selectedSearchVal={selectedSearchVal}
                    setSelectedSearchVal={setSelectedSearchVal}
                    setActiveTab={setActiveTab}
                    userType={user.usertype}
                    rerouteObject={rerouteObject}
                    showFullView={showFullView}
                    setViewLearnerInClass={setViewLearnerInClass}
                  />
                </div>

                <div
                  style={{
                    display: activeTab === "learner" ? "block" : "none",
                  }}
                >
                  <LearnerTabContent
                    classes={classes}
                    ready={ready}
                    setReady={setReady}
                    error={
                      (!isTeacher && error !== "No classes.") || isTeacher
                        ? error
                        : null
                    }
                    allLearners={allLearners}
                    setAllLearners={setAllLearners}
                    fetchLearnersForClassData={fetchLearnersForClassData}
                    selectedSearchVal={selectedSearchVal}
                    userType={user.usertype}
                    rerouteObject={rerouteObject}
                    showFullView={showFullView}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainDashboard>
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(ConsolidatedReports);
