import React, { useState } from "react";
import Select from "react-select";
import FA from "../../containers/fa";

const GradeDropdown = ({
  setterFunc,
  placeholder,
  value,
  saveGradeInfoChanges,
  changeType,
  addCssClass,
  disabled,
  infoIconAction,
  nonstandardGradesEnabled = false,
}) => {
  const [closeDropdown, setCloseDropdown] = useState(true);
  const levels = [
    { value: 0, label: "Grade K" },
    { value: 1, label: "Grade 1" },
    { value: 2, label: "Grade 2" },
    { value: 3, label: "Grade 3" },
    { value: 4, label: "Grade 4" },
    { value: 5, label: "Grade 5" },
  ];
  if (nonstandardGradesEnabled) {
    levels.push({ value: "unset", label: "Select a Different Grade" });
  }

  const nonstandard_levels_prek = [{ value: -1, label: "Grade pre-K" }];
  const nonstandard_levels = [
    { value: 6, label: "Grade 6" },
    { value: 7, label: "Grade 7" },
    { value: 8, label: "Grade 8" },
    { value: 9, label: "Grade 9" },
    { value: 10, label: "Grade 10" },
    { value: 11, label: "Grade 11" },
    { value: 12, label: "Grade 12" },
  ];
  const all_levels = [
    ...nonstandard_levels_prek,
    ...levels,
    ...nonstandard_levels,
  ];

  const customStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: "30vh",
      "::-webkit-scrollbar": {
        width: "8px",
      },
      "::-webkit-scrollbar-track": {
        background: "#dedede",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#676767",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#676767",
      },
    }),
  };

  return (
    <div className={`${addCssClass}`}>
      {saveGradeInfoChanges && changeType ? null : (
        <span>
          {placeholder}
          {infoIconAction && (
            <FA
              icon='info-circle'
              className='info-circle'
              title="What's this?"
              onClick={() => {
                infoIconAction();
              }}
            />
          )}
        </span>
      )}
      {closeDropdown ? (
        <Select
          defaultMenuIsOpen={false}
          isDisabled={disabled}
          options={levels}
          aria-label={"Select a " + placeholder}
          placeholder={"Select a " + placeholder}
          onChange={(e) => {
            if (e.value === "unset") {
              // detect non-standard option and display additional grades
              setCloseDropdown(false);
            } else {
              setCloseDropdown(true);
            }
            setterFunc(changeType, { label: e.label, value: e.value });
            if (saveGradeInfoChanges && changeType) {
              // auto-update api vs save btn
              saveGradeInfoChanges(changeType, {
                label: e.label,
                value: e.value,
              });
            }
          }}
          value={value}
          menuPlacement='auto'
        />
      ) : (
        <Select
          styles={customStyles}
          defaultMenuIsOpen={true}
          menuIsOpen={true}
          isDisabled={disabled}
          options={all_levels}
          onBlur={() => setCloseDropdown(true)}
          placeholder={"Select a " + placeholder}
          onChange={(e) => {
            if (e.value === "unset") {
              // detect non-standard option and display secondary dropdown
              setCloseDropdown(false);
            } else {
              setCloseDropdown(true);
            }
            setterFunc(changeType, { label: e.label, value: e.value });
            if (saveGradeInfoChanges && changeType) {
              // auto-update api vs save btn
              saveGradeInfoChanges(changeType, {
                label: e.label,
                value: e.value,
              });
            }
          }}
          value={value}
          menuPlacement='auto'
        />
      )}
    </div>
  );
};

export default GradeDropdown;
