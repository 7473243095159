import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {useDebounce} from "use-debounce";
import config from "../../common/config";
import FA from "../../containers/fa";
import FAW from "../../containers/faw";
import withAPI from "../../services/api";
import InfoPopup from "../UtilComps/InfoPopup";
import AccountRow from "./AccountRow";
import "./OrgAccountsTable.scss";

import UserWelcomeResendModal from "../AdminDashboard/UserWelcomeResendModal";
import PaginationBar from "../UtilComps/PaginationBar.js";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const OrgAccountsTable = ({api, orgId, user, canCollapse}) => {
  const [accounts, setAccounts] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [pageSize, setPageSize] = useState(null);

  const [spin, setSpin] = useState(true);
  const [error, setError] = useState(null);
  const [filterString, setFilterString] = useState(null);
  const [debouncedFilterString] = useDebounce(filterString, 1000);
  const [sortType, setSortType] = useState("username");
  const [sortAsc, setSortAsc] = useState(false);
  const [sortTouched, setSortTouched] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const [userIdToResend, setUserIdToResend] = useState(null);
  const [emailToResend, setEmailToResend] = useState(null);
  const [checkProviders, setCheckProviders] = useState(null);
  const [showResendModal, setShowResendModal] = useState(null);

  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    header: "",
    text: "",
  });

  const fetchAccounts = (pageNum) => {
    let queryParams = {
      organization: orgId,
      sort: (sortAsc ? "" : "-") + sortType,
      q: debouncedFilterString,
      page_size: pageSize,
    };
    console.log("Asking for accounts in org:", orgId);
    const excludeLearners = user.usertype === config.SUPERADMIN_USERTYPE;
    queryParams.exclude_learners = excludeLearners;
    queryParams.page = pageNum;
    // skip nulls,  empty strings, false values
    queryParams = Object.entries(queryParams).filter(([k, v]) => v);

    setSpin(true);

    api.fetchAccountsInOrg(queryParams).then((r) => {
      setAccounts(r.results);
      delete r["results"];
      setPaginationInfo(r);
      setSpin(false);
    });
  };

  useEffect(() => {
    if (user) {
      fetchAccounts(null);
    }
  }, [user, pageSize]);

  // sort rows
  useEffect(() => {
    if (sortTouched === true || debouncedFilterString !== null) {
      fetchAccounts(null);
    }
  }, [sortType, sortAsc, debouncedFilterString]);

  const handleColumnHeaderClick = (column) => {
    setSortTouched(true);
    console.log(column, sortAsc);
    if (sortType === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortType(column);
      setSortAsc(false);
    }
  };

  const getHeaderClasses = (column) => {
    let classes = "sortable";
    if (sortType === column) {
      if (sortAsc === false) {
        classes += " headerSortDown";
      } else {
        classes += " headerSortUp";
      }
    }
    return classes;
  };

  const getActions = (account) => {
    if (account.userprofile.usertype === config.TEACHER_USERTYPE) {
      return ["email", "manage"];
    } else {
      if (user.usertype === config.SUPERADMIN_USERTYPE) {
        if (account.has_robot === true) {
          return ["manage"];
        }
      } else {
        // Org admin viewing? Display manage for all
        return ["manage"];
      }
    }
    return [];
  };

  return (
    <div className='common_border' id='org-account-wrapper'>

      {showPopupInfo && (
        <InfoPopup popupInfo={popupInfo} setShowPopupInfo={setShowPopupInfo} />
      )}

      {showResendModal && (
        <div style={{display: "block", height: "0px"}}>
          <UserWelcomeResendModal
            userIdToResend={userIdToResend}
            email={emailToResend}
            setShowResendModal={setShowResendModal}
            checkProviders={checkProviders}
          />
        </div>
      )}

      <div
        className='common_heading d-flex justify-content-between align-items-center'
        style={{cursor: "pointer"}}
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <span>
          <h4>
            {canCollapse && (
              <span className='mr-2'>
                {collapse ? (
                  <FA color='gray' icon='chevron-right' />
                ) : (
                  <FA color='gray' icon='chevron-down' />
                )}
              </span>
            )}
            Accounts
          </h4>
        </span>
      </div>

      {!collapse && (
        <div className='common_dashboard_bg'>
          <div className='row'>
            <div className='col-12'>
              {error && (
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              )}
              <div className='d-flex' style={{marginBottom: "5px"}}>
                <div className='d-flex justify-content-end'>
                  <label className='pt-2 mr-2'>
                    <FA icon='search' />
                  </label>
                  &nbsp;
                  <input
                    placeholder='Search ...'
                    className='form-control input-underline'
                    style={{minWidth: "80px"}}
                    value={filterString || ""}
                    disabled={spin}
                    onChange={(e) => setFilterString(e.target.value)}
                  />
                </div>
              </div>
              <PaginationBar
                paginationInfo={paginationInfo}
                fetchPageFunc={fetchAccounts}
                setPageSize={setPageSize}
                pageSize={pageSize}
                loading={spin}
              />
              <div className='card'>
                <div
                  className='progress-linear school_listing manage_learner_table table-scroll-wrapper'
                  role='progressbar'
                >
                  <div
                    className='table table-striped'
                    style={{minWidth: "770px"}}
                  >
                    <div id='account_row_hr'>
                      <div
                        style={{textAlign: "left"}}
                        className={getHeaderClasses("username")}
                        onClick={() => {
                          handleColumnHeaderClick("username");
                        }}
                      >
                        Username
                      </div>
                      <div
                        style={{textAlign: "left"}}
                        className={getHeaderClasses("first_name")}
                        onClick={() => {
                          handleColumnHeaderClick("first_name");
                        }}
                      >
                        First Name
                      </div>
                      <div
                        style={{textAlign: "left"}}
                        className={getHeaderClasses("last_name")}
                        onClick={() => {
                          handleColumnHeaderClick("last_name");
                        }}
                      >
                        Last Name
                      </div>
                      <div
                        style={{textAlign: "left"}}
                        className={getHeaderClasses("userprofile__usertype")}
                        onClick={() => {
                          handleColumnHeaderClick("userprofile__usertype");
                        }}
                      >
                        User Type
                      </div>
                      <div style={{textAlign: "left"}}># Robots</div>
                      <div style={{textAlign: "left"}}>Enrolled Learners</div>
                      
                      <div
                        className={getHeaderClasses(
                          "userprofile__recent_lessons"
                        )}
                      >
                        <span
                          onClick={() => {
                            handleColumnHeaderClick(
                              "userprofile__recent_lessons"
                            );
                          }}
                        >
                          Recent Lessons
                        </span>
                        <FAW
                          icon='info-circle'
                          className='info-circle'
                          onClick={() => {
                            setPopupInfo({
                              header: "Recent Lessons",
                              text: `This represents the number of
                                        lessons taken by this teacher's 
                                        active learners in the last 30 days.`,
                            });
                            setShowPopupInfo(true);
                          }}
                        />
                      </div>

                      <div style={{textAlign: "left"}}>Actions</div>
                    </div>
                    {accounts &&
                      accounts.map((account, index) => (
                        <AccountRow
                          index={index}
                          key={account.username}
                          account={account}
                          showActions={getActions(account)}
                          setEmailToResend={setEmailToResend}
                          setUserIdToResend={setUserIdToResend}
                          setShowResendModal={setShowResendModal}
                          setCheckProviders={setCheckProviders}
                        />
                      ))}
                    {!spin && accounts.length === 0 && (
                      <AccountRow
                        nullRow={true}
                        showActions={["email"]}
                        setEmailToResend={setEmailToResend}
                        setUserIdToResend={setUserIdToResend}
                        setShowResendModal={setShowResendModal}
                      />
                    )}
                    {spin && accounts.length === 0 && (
                      <div>
                        <div colSpan='100%'>Loading accounts ...</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <PaginationBar
                paginationInfo={paginationInfo}
                fetchPageFunc={fetchAccounts}
                pageSize={pageSize}
                loading={spin}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(OrgAccountsTable);
