import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import config from '../common/config'
import { debugLog } from '../common/utils';
import { authService } from '../services/auth';
import { OAuthService, OAuthProviders } from '../services/oauth';
import {
  login,
  studentCap as studentCapA,
  userOrgSettings as userOrgSettingsA,
} from '../redux/actions';
import withAPI from '../services/api';
import FA from '../containers/fa';
import FAW from '../containers/faw';

import Gate from '../partials/Gate';
import './Welcome.css';

const CleverLogin = ({ api, history, location, dispatch }) => {

  const [spin, setSpin] = useState(false);
  const [pageState, setPageState] = useState('loggingIn');
  const [oAuthToken, setOAuthToken] = useState(null);  // accounts-backend will send this to Clever
  const [authToken, setAuthToken] = useState(null);    // frontend will send this to accounts-backend
  const [decodedState, setDecodedState] = useState(null);
  const [codeRequired, setCodeRequired] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [organizationCode, setOrganizationCode] = useState('');
  const [mergeEmail, setMergeEmail] = useState('');
  const [error, setError] = useState(null);

  /* instantiate OAuthService provider instance
   * Load the provider indicated by the page's route
   */
  const loginPageSSO = 'clever-sso-login';
  const loginPageSecureSync = 'clever-securesync-login';
  const providerName = location.pathname.includes(loginPageSecureSync) ? 'cleverSecureSync' : 'cleverSSOOnly';
  const provider = new OAuthService(OAuthProviders[providerName]);
  debugLog("location:", location);
  debugLog("location.pathname.includes(securesync)", location.pathname.includes(loginPageSecureSync));
  debugLog("provider:", provider)

  //const primaryProvider = new OAuthService(OAuthProviders.cleverSSOOnly);
  //const backupProvider = new OAuthService(OAuthProviders.cleverSecureSync);

  function jsonToURI (json) { 
    return encodeURIComponent(JSON.stringify(json)); 
  }
  function uriToJSON (urijson) {
    return JSON.parse(decodeURIComponent(urijson));
  }

  // Default redirect is to send user to /clever-login/
  // When directing OAuth flow through the popup, redirect_uri should send them
  // to /clever-callback/ instead
  const [shouldOverrideRedirectUri, setShouldOverrideRedirectUri] = useState(false);

  const consumeLoginAndRedirect = ([
      user,
      token,
      organization,
      hasRobots,
      hasAccounts,
      localBotsOnly,
      forcePWReset,
      orgStatus,
  ]) => {
    dispatch(
      login(
        user,
        token,
        hasRobots,
        hasAccounts,
        localBotsOnly,
        forcePWReset,
        orgStatus,
      )
    );
    dispatch(studentCapA(user.student_cap));
    if (user.organization.settings) {
      dispatch(userOrgSettingsA(user.organization.settings));
    }
    sessionStorage.setItem('firstLogin', true);
    if (user.usertype === config.TEACHER_USERTYPE) {
      debugLog("Redirecting to Teacher Dashboard");
      history.push('/teacher/dashboard');
    } else if (user.usertype === config.ORGADMIN_USERTYPE) {
      debugLog("Redirecting to OrgAdmin Dashboard");
      history.push('/admin/dashboard');
    } else if (user.usertype === config.ORGUNITADMIN_USERTYPE) {
      debugLog("Redirecting to OrgAdmin Dashboard");
      history.push('/admin/dashboard');
    } else if (user.usertype === config.SUPERADMIN_USERTYPE) {
      debugLog("Redirecting to SuperAdmin Dashboard");
      history.push('/super/dashboard');
    } else if (user.usertype === config.LEARNER_USERTYPE) {
      debugLog("Redirecting to Learner Dashboard");
      history.push('/learner/dashboard');
    };
  };

  const grabSerializedProfile = (state, authToken) => {
      debugLog("Received token from backend:",authToken);
      var token = authToken.key
      if (token) {
        setPageState('preparingExperience');
        setAuthToken(token);
        // Grab serialized profile info from backend using just-grabbed token
        provider.grabUserProfileFromBackend(token, (state || {})).then((profile) => {
          authService.SSOLogin(profile)
          .then((login) => consumeLoginAndRedirect(login));
        }).catch((e) => {
          // Examine error.
          // User may need to confirm a Merge with existing placeholder account
          // User may need to provide a User Code
          debugLog("Caught error grabbing profile information from backend:",e);
          debugLog("message:",e.message);
          debugLog("type(message):",typeof(e.message));
          try {
            var error_obj = JSON.parse(e.message)
            debugLog("error_obj:",error_obj);
            setMergeEmail(error_obj.email);
            setPageState('merge?');
          } catch {
            debugLog("Caught error decoding error message. Wild.");
            setPageState('codeRequired');
          }
          //setError(e.error);
        });
      };
  }

  const connectWithExisting = (code, state) => {
    setPageState('loggingIn');
    provider.connectExternalWithExisting(code, state)
    .then((response) => {
      // Take authToken from backend and grab serialized profile
      grabSerializedProfile(state, response);
    }).catch((e) => {
      debugLog("Caught error in tradeOAuthCodeForToken:",e);
      if (e.message.startsWith('SyntaxError')) {
        // Backend returned a 500. Probably because of a bad code
        debugLog("Caught a SyntaxError!");
        setError("Something went wrong while our server was communicating with Clever. You may need to try logging in again.");
      } else {
        setError(e.message);
      }
    });
  }

  const parseError = (e) => {
    // Examine error. May be formatted differenttly depending on how the backend responded.
    // User may need to confirm a Merge with existing placeholder account
    // User may need to provide a User Code
    debugLog("Caught error grabbing profile information from backend:",e);
    if (e.message) {
      try {
        var error_obj = JSON.parse(e.message)
        debugLog("Caught error_obj:",error_obj);
        return error_obj;
      } catch {
        debugLog("Caught error decoding error.message.");
      }
    } else {
      try {
        error_obj = JSON.parse(e);
        debugLog("Caught error_obj:",error_obj);
        return error_obj;
      } catch {
        debugLog("Caught error in JSON.parse of:",e);
      }
    }
  };

  const handleCode = (code, state, {shouldOverrideRedirectUri}) => {
    // Submit OAuth access code to backend (along with OrganizationCode or UserCode if available)
    setPageState('loggingIn');
    provider.tradeOAuthCodeWithBackend(code, state, {shouldOverrideRedirectUri})
    .then((maybeToken) => {
      var token = maybeToken
      debugLog("Received token from backend:",token);
      if (token) {
        setPageState('preparingExperience');
        setAuthToken(token);
        // Grab serialized profile info from backend using just-grabbed token
        provider.grabUserProfileFromBackend(token, (state || {})).then((profile) => {
          authService.SSOLogin(profile)
            .then((login) => consumeLoginAndRedirect(login));
        }).catch((e) => {
          const error_obj = parseError(e);
          if (error_obj.message === 'Must confirm merge') {
            setMergeEmail(error_obj.email);
            setPageState('merge?');
          } else {
            // Not sure what to do here. Default to "Code Required" message, since that's maybe most generic.
            setPageState('codeRequired');
          }
        });
      };
    }).catch((e) => {
      debugLog("Caught error in tradeOAuthCodeForToken:",e);
      if (e.message.startsWith('SyntaxError')) {
        // Backend returned a 500. Probably because of a bad code
        debugLog("Caught a SyntaxError!");
        setError("Something went wrong while our server was communicating with Clever. You may need to try logging in again.");
      } else if (e.message.includes('Failed to exchange code')) {
        setError("Unable to verify information with Clever. Your district's SSO configuration or sharing rules may be preventing your from accessing the ABii by Van Robotics app.");
      } else {
        setError(e.message);
      }
    });
  };

  const submitCode = () => {
    setError(null);
    // Collect organization_code and user_code into new state
    var submissionState = {...decodedState, provider: provider.provider.APP_NAME}
    if (userCode) submissionState.user_code = userCode;
    if (organizationCode) submissionState.organization_code = organizationCode;
    if (!userCode && !organizationCode) {
      // No code provided. Either code is required
      setError("An Organization Code or User Code is required!");
    } else {
      debugLog("submissionState:",submissionState);
      debugLog("oAuthToken:",authToken);
      setSpin(true);
      provider.grabUserProfileFromBackend(authToken, submissionState).then((profile) => {
        // Take login_parameters and complete login process
        authService.SSOLogin(profile)
        .then((login) => consumeLoginAndRedirect(login));
      }).catch((e) => {
        // Examine error. Maybe user needs to provide user_code, registration_code
        setSpin(false);
        debugLog("Caught error grabbing profile information from backend:",e);
        setError(e.message);
      });
    }
  };

  const confirmMerge = () => {
    setError(null);
    var submissionState = {
      ...decodedState,
      provider: provider.provider.APP_NAME,
      confirm_merge: true,
    }
    provider.grabUserProfileFromBackend(authToken, submissionState).then((profile) => {
      // Take login_parameters and complete login process
      authService.SSOLogin(profile)
      .then((login) => consumeLoginAndRedirect(login));
    }).catch((e) => {
      // Examine error. Maybe user needs to provide user_code, registration_code
      setSpin(false);
      debugLog("Caught error grabbing profile information from backend:",e);
      setError(e.message);
    });
  }

  
  useEffect(() => {
    // On page load, determine whether we have an OAuth token already in our
    // url params, or whether we need to initiate the OAuth login flow
    var code = (location.search.match(/code=([^&]+)/) || [])[1];
    debugLog("Found code:",code);
    if (code) setOAuthToken(code);
    var launchLCT = (location.search.match(/launchLCT=([^&]+)/) || [])[1];
    var lctDomain = (location.search.match(/lctDomain=([^&]+)/) || [])[1];
    var state = (location.search.match(/state=([^&]+)/) || [])[1];
    var url_error = (location.search.match(/error=([^&]+)/) || [])[1];
    var url_error_desc = (location.search.match(/error_description=([^&]+)/) || [])[1];
    debugLog("Found state:",state);
    if (state) {
      var decoded = {...uriToJSON(state), provider: provider.provider.APP_NAME};
      setDecodedState(decoded);
    } else {
      var decoded = {provider: provider.provider.APP_NAME};
    };
    var referrer = document.referrer;
    debugLog("Decoded state:",decoded);
    debugLog("referrer url:",referrer);
    debugLog("window.location.origin:",window.location.origin);

    if (code) {
      // Submit code to backend (along with OrganizationCode or UserCode if available)
      if (decoded && decoded.link_to_existing) {
        debugLog("Found oauth code and usercode in URL. Linking account to existing one?");
        connectWithExisting(code, decoded);
      } else if (decoded && decoded.launchLCT) {
        // Grab authToken for this user and redirect to LCT landing page
        provider.tradeOAuthCodeWithBackend(code, state, {shouldOverrideRedirectUri: false})
        .then((maybeToken) => {
          debugLog("lctDomain: ",decoded.lctDomain);
          debugLog("lctDomain ends with abiis-world? ",decoded.lctDomain.endsWith('.abiis-world.com'));
          try {
            //var lctDom = decoded.lctDomain.endsWith('.abiis-world.com') ? decoded.lctDomain : 'lct.abiis-world.com'
            var lctDom = decoded.lctDomain
          } catch (e) {
            var lctDom = 'lct.abiis-world.com';
          }
          var lctURL = `http://${lctDom}/clever-landing/?token=${maybeToken}`
          debugLog("Redirecting to: ",lctURL);
          window.location.href = lctURL;
        }).catch((e) => {
          debugLog("Error trading oauth code for authToken...");
          debugLog(e);
        });
      } else {
        debugLog("Handling the oauth code for a standard login...");
        handleCode(code, decoded, {shouldOverrideRedirectUri: false});
      }
    } else {
      if (url_error === 'unauthorized-user') {
        // User logged into Clever, but doesn't have access to the app we used
        // for the oauth flow. We may need to try the other. In this case, we
        // don't get any of our state back from Clever, otherwise we would
        // redirect with that intact.
        setPageState('redirecting');
        debugLog("Found an error message in the URL!", url_error);
        debugLog("Found an error description:", decodeURIComponent(url_error_desc));
        if (providerName === 'cleverSSOOnly') {
          let otherAppProvider = new OAuthService(OAuthProviders['cleverSecureSync']);
          debugLog("Redirecting to...", otherAppProvider.provider.REDIRECT_URI);
          window.location.href = otherAppProvider.provider.REDIRECT_URI;
        }

      } else if (launchLCT) {
        // Redirect to Clever, but include launchLCT in page state
        var newDecoded = {...decoded, launchLCT:true}
        if (lctDomain) newDecoded.lctDomain = lctDomain
        setPageState('redirecting');
        provider.redirectToLogin({state:jsonToURI(newDecoded)});
      } else {
        // If no code is given, we need to redirect to Clever
        setPageState('redirecting');
        provider.redirectToLogin({state});
      }
    }
  }, []);

  const submitOnEnter = (e) => {
    // submit the UserCode when pressing enter key
    debugLog("handling keypress:",e.key)
    if (e.key === 'Enter') {
      submitCode();
    }
  };


  return (
    <Gate boxWidthClass="col-md-6">
      <div className="login_box form-content container form-group">
        <div className="row">
          <div className="col">

            {pageState === 'codeRequired' &&
              <React.Fragment>
                <div className="row col-12">
                  <Link to="/login">&laquo; Back</Link>
                </div>
                <p style={{"textAlign": "center"}}>
                  Your district's SSO configuration is not yet complete.
                  To complete setup, please provide the User Code included in the welcome email
                  you received from support@abiis-world.com.
                  If you don't have this, please have one of your district's Clever admins
                  contact support@myvanrobot.com to request set up for your district.
                </p>
                <div className="row d-flex justify-content-center">
                  <input 
                    className="form-control input-underline input-lg col-md-5 form_icons"
                    id="userCodeField"
                    style={{textAlign:'center'}}
                    placeholder="User Code"
                    value={userCode}
                    onKeyPress={(e) => submitOnEnter(e)}
                    onChange={(e) => {
                      debugLog("handling change.");
                      setUserCode(e.target.value);
                    }}
                  />
                </div>
                <div className="row d-flex justify-content-center align-items-center my-3">
                  <button 
                    className="btn btn-lg fs-14 btn-primary col-md-5"
                    id="submitButton"
                    type="submit"
                    onClick={() => submitCode()}
                  >
                    {spin
                      ? <React.Fragment><FAW icon="spinner"  spin />{"Processing..."}</React.Fragment>
                      : <span>Submit</span>
                    }
                  </button>
                </div>
              </React.Fragment>
            }

            {pageState === 'merge?' &&
              <React.Fragment>
                <p style={{"textAlign": "center"}}>
                  {`An invitation was previously sent to `}<strong>{`${mergeEmail}`}</strong>.<br/>
                  {`By continuing, you will accept that invitation.`}
                </p>
                <div className="row d-flex justify-content-center align-items-center my-3">
                  <button
                    className="btn btn-lg fs-14 btn-danger col-md-5 mr-3"
                    onClick={() => history.push('/login/')}
                  >
                    {"Cancel"}
                  </button>
                  <button 
                    className="btn btn-lg fs-14 btn-primary col-md-5"
                    id="submitButton"
                    type="submit"
                    onClick={() => confirmMerge()}
                  >
                    {spin
                      ? <React.Fragment><FAW icon="spinner"  spin />{"Processing..."}</React.Fragment>
                      : <span>Continue</span>
                    }
                  </button>
                </div>
              </React.Fragment>
            }

            {pageState === 'loggingIn' && !error &&
              <h1 style={{"textAlign": "center"}}>
                <FA icon="spinner" color="black" spin />&nbsp;{"Logging in..."}
              </h1>
            }

            {pageState === 'popup' && !error &&
              <h1 style={{"textAlign": "center"}}>
                {"Please login using the popup window"}
              </h1>
            }

            {pageState === 'redirecting' && !error &&
              <h1 style={{"textAlign": "center"}}>
                {"Redirecting you to Clever to login..."}
              </h1>
            }

            {pageState === 'preparingExperience' &&
              <h1 style={{"textAlign": "center"}}>
                <FA icon="spinner" color="black" spin />&nbsp;{"Preparing your experience..."}
              </h1>
            }

            {error &&
              <React.Fragment>
                <div className="row col-12">
                  <Link to="/login">&laquo; Back</Link>
                </div>
                <div className="row alert alert-danger">{error}</div>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    </Gate>
  );
}

export default compose(
  withAPI
)(CleverLogin);
