import {FormGroup} from "@material-ui/core";
import {Checkbox} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";

const SortStandardsCheckboxes = ({
  sortingStandardsAsc,
  setSortingStandardsAsc,
  setSortedLearnerId,
}) => {
  return (
    <div id='standards_switch_sort_container'>
      <FormControl>
        <FormGroup row>
          <FormControlLabel
            checked={sortingStandardsAsc && sortingStandardsAsc !== null}
            control={<Checkbox size='small' />}
            onChange={(e) => {
              setSortingStandardsAsc(true);
              setSortedLearnerId(null);
            }}
            size='small'
            label={
              <p
                style={{
                  margin: 0,
                  fontSize: "14px",
                  color: "#212529",
                  fontWeight: "lighter",
                }}
              >
                Sort Standards Asc. (A-Z)
              </p>
            }
          />
          <FormControlLabel
            checked={!sortingStandardsAsc && sortingStandardsAsc !== null}
            control={<Checkbox size='small' />}
            onChange={(e) => {
              setSortingStandardsAsc(false);
              setSortedLearnerId(null);
            }}
            size='small'
            label={
              <p
                style={{
                  margin: 0,
                  fontSize: "14px",
                  color: "#212529",
                  fontWeight: "lighter",
                }}
              >
                Sort Standards Desc. (Z-A)
              </p>
            }
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default SortStandardsCheckboxes;
