import React from 'react';
import FormControl from "@mui/material/FormControl";

const SearchInput = ({ placeholder, style, searchTerm, setSearchTerm }) => {
  return (
    <FormControl>
      <input
        placeholder={placeholder}
        className='form-control input-underline'
        style={style}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
      />
    </FormControl>
  )
}

export default SearchInput;