import React from "react";

const OnSaveErrorModal = ({ setShowOnSaveErrorModal, errMsg }) => {
  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <p>
                  An error occurred when trying to save this user's changes.
                </p>
                {errMsg &&
                  <p>{errMsg}</p>
                }
                <div className='d-flex'>
                  <button
                    onClick={() => {
                      setShowOnSaveErrorModal(false);
                    }}
                    className='btn btn-sm btn-primary'
                  >
                    Got it
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnSaveErrorModal;
