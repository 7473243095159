import React, { useEffect, useState } from "react";
import Select from "react-select";
import { compose } from "redux";
import withAPI from "../../../services/api";
import FA from "../../../containers/fa";
import "./ClassTabContent.scss";
import ReportsGroupedLessonSelect from "./ReportsGroupedLessonsSelect";
import config from "../../../common/config";
import ClassAveragesBarChart from "./ClassAveragesBarChart";
import LessonContextTextInfo from "../LessonContextTextInfo";
import LearnerScoresTable from "./LearnerScoresTable/LearnerScoresTable";
import PdfDownloadBtn from "./PdfDownloadBtn";
import ReportsNoClassesErrMsg from "./ReportsErrorMsgs/ReportsNoClassesErrMsg";
import NoReportsForClassMsg from "./ReportsErrorMsgs/NoReportsForClassMsg";
import { filterLessonsBySubjectUnit } from "../ConsolidatedReportsUtilities";
import { getLessonSources } from "../../InputFields/helpers";

const ClassTabContent = ({
  user,
  api,
  classes,
  ready,
  setReady,
  error,
  setError,
  selectedSearchVal,
  setSelectedSearchVal,
  setActiveTab,
  rerouteObject,
  showFullView,
  setViewLearnerInClass,
}) => {
  const [activeClass, setActiveClass] = useState(null);
  const [activeSubject, setActiveSubject] = useState(null);
  const [activeUnit, setActiveUnit] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState("");
  // for building the subject options
  const [coursenameDropdown, setCoursenameDropdown] = useState([]);
  // used to build unit items
  const [courseLessons, setCourseLessons] = useState(null);
  const [learners, setLearners] = useState(null);
  // scores of students in the class
  const [reports, setReports] = useState(null);
  const [reportsErr, setReportsErr] = useState(null);
  const [classFetchErr, setClassFetchErr] = useState(null);
  // data for the bar chart
  const [classAverageData, setClassAverageData] = useState(null);
  // for the table of selected lesson info
  const [lessonContextInfo, setLessonContextInfo] = useState(null);
  // the pairings are the list of options for the lesson selection.
  const [pairings, setPairings] = useState({});
  const [unitsDict, setUnitsDict] = useState({});
  const [groupingLessons, setGroupingLessons] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  // lesson source
  const [lessonSource, setLessonSource] = useState(
    config.LESSON_SOURCE_INTERNAL
  );
  const [lessonSourceOptions, setLessonSourceOptions] = useState([]);

  useEffect(() => {
    getLessonSources(api, true).then((lessonSources) => {
      setLessonSourceOptions(lessonSources);
    });
  }, [user]);

  const handleLessonSourceChange = (selectedLessonSource) => {
    setActiveUnit(null);
    setLessonContextInfo(null);
    setError(null);
    setClassFetchErr(null);
    setReportsErr(null);
    setLessonSource(selectedLessonSource.value);
  };

  useEffect(() => {
    if (activeUnit && selectedLesson && learners && classAverageData) {
      if (
        (selectedLesson.group.indexOf(activeUnit.value) === -1 &&
          activeUnit.value !== "All Units") ||
        learners.length === 0 ||
        classAverageData.by_user.length === 0
      ) {
        // Current selected lesson is not in the newly chosen unit.
        setSelectedLesson("");
        setLessonContextInfo(null);
        setClassAverageData(null);
      }
    }
  }, [activeUnit, learners]);

  /**
   * updateLessonData sets the class average and fetches the lesson report
   * context data.
   * If the AsmFromLessonContext = true, that means the new lesson is the
   * corresponding assessment from the previously selected TUT, clicked in
   * the LessonContextTextInfo table.
   * @param {object} lesson
   */
  const updateLessonData = (
    lessonValue,
    lesson,
    AsmFromLessonContext = false
  ) => {
    if (AsmFromLessonContext) {
      // asms will always have the same course name and unit as their TUT.
      let newGroup = `${lessonContextInfo.course_name} - ${lessonContextInfo.unit}`;
      setSelectedLesson({
        average: null,
        group: newGroup,
        label: lesson.name,
        value: lessonValue,
      });
    } else {
      setSelectedLesson(lesson);
    }
    const clsId = activeClass.value;
    api
      .fetchClassAverageReport(lessonValue, clsId)
      .then((averageReport) => {
        setClassAverageData(averageReport);
      })
      .catch((err) => {
        setClassFetchErr(err.message);
      });

    api
      .fetchLessonReportContextData(lessonValue)
      .then((lci) => {
        setLessonContextInfo(lci);
      })
      .catch((err) => {
        console.log(err);
        setReady(true);
        setClassFetchErr(err.message);
      });
  };

  /**
   * fetchCoursesData fetches the courses and calls upon fetchUnitsData.
   */
  const fetchCoursesData = (classId) => {
    api
      .fetchCourses(lessonSource)
      .then((resp_json) => {
        let courses = resp_json.courses.map(function (item) {
          return {
            full_name: item.display_key,
            cid: item.id,
          };
        });
        setCoursenameDropdown(courses);
        fetchUnitsData();
        fetchLearnersForClassData(classId);
      })
      .catch((e) => {
        console.log(e);
        setReady(true);
        setClassFetchErr(
          `Something went wrong fetching Courses. Please make sure you are
          connected to the internet and try again in a few seconds.`
        );
      });
  };

  /**
   * fetchUnitsData is required for the unit dropdown and the scores table.
   * @returns {object array} an array of lesson info for the chosen course.
   */
  const fetchUnitsData = () => {
    if (!activeSubject) return;
    let allCourses;
    api
      .fetchLessonsForCourse(activeSubject.value, lessonSource)
      .then((resp_json) => {
        allCourses = resp_json.map(function (item) {
          const lessonPrefix =
            lessonSource === config.LESSON_SOURCE_INTERNAL ? "I" : "E";
          return {
            lesson_id: `${lessonPrefix}${item.id.toString()}`,
            lesson_name: item.name,
            standard: item.standard.startsWith("CCSS")
              ? item.standard.slice(18)
              : item.standard,
            unit: item.unit,
          };
        });

        setCourseLessons(allCourses);
        const unitsDict = getUnitsDict(allCourses);
        setUnitsDict(unitsDict);
        setReady(true);
      })
      .catch((err) => {
        console.log(err.message);
        setReady(true);
        setClassFetchErr(
          `Something went wrong fetching the units for this course. Please make
          sure you are connected to the internet and try again in a few
          seconds.`
        );
      });
  };

  const getUnitsDict = (courses) => {
    let unitDict = {};
    for (const course of courses) {
      unitDict[course.lesson_id] = {
        standard: course.standard,
        unit: course.unit,
      };
    }
    return unitDict;
  };

  useEffect(() => {
    fetchUnitsData();
  }, [activeSubject, lessonSource]);

  /**
   * fetchLearnersForClassData gets a list of all learners in the chosen class.
   * @param {integer} classId
   */
  const fetchLearnersForClassData = (classId) => {
    api
      .fetchLearnersForClass(classId)
      .then((resp_json) => {
        let allStds = resp_json.learners.map((item) => {
          return {
            username: item.user["username"],
            name: `${item.user["first_name"]} ${item.user["last_name"]}`,
            first_name: item.user["first_name"],
            last_name: item.user["last_name"],
            sid: item.id,
          };
        });
        setLearners(allStds);
        if (allStds.length !== 0) {
          fetchReportsForClassData(classId);
        } else {
          setReady(true);
        }
      })
      .catch((e) => {
        setReady(true);
        console.log(e);
        setClassFetchErr(
          `Something went wrong fetching learners in this class. Please make
          sure you are connected to ABii’s network and try again in a few
          seconds.`
        );
      });
  };

  /**
   * fetchReportsForClassData gets a list of grades for every student and lesson.
   * @param {integer} classId
   */
  const fetchReportsForClassData = (classId) => {
    api
      .fetchOrganizedReportsForClass(classId)
      .then((resp_json) => {
        setReports(resp_json);
        fetchLessonsData(classId);
      })
      .catch((e) => {
        console.log(e);
        setReportsErr(
          `Something went wrong fetching reports for this class. Please make
          sure you are connected to ABii’s network and try again in a few
          seconds.`
        );
        setReady(true);
      });
  };

  /**
   * fetchLessonsData gets a list of the lessons learners in a class have taken.
   * @param {integer} classId
   */
  const fetchLessonsData = (classId) => {
    api
      .fetchLessonsForClass(classId, lessonSource)
      .then((lessons) => {
        let postData = { lessons: lessons };
        setGroupingLessons(true);
        console.log("LESSONS:", lessons);
        api
          .groupLessons(postData)
          .then((res) => {
            setGroupingLessons(false);
            console.log("res:", res);
            setLessons(res.grouped_lessons);
            console.log("GROUPED LESSONS:", res.grouped_lessons);
            console.log("LESSONS:", lessons);
            setPairings(res.pairings);
            setReady(true);
            setError(null);
            setClassFetchErr(null);
            setReportsErr(null);
          })
          .catch((err) => {
            console.log(err);
            setClassFetchErr(
              `Something went wrong fetching lesson info for this class.
              Please make sure you are connected to the internet and try
              again in a few seconds.`
            );
          });
      })
      .catch((err) => {
        console.log(err);
        setReady(true);
        setClassFetchErr(
          `Something went wrong fetching lesson info for this class.
          Please make sure you are connected to the internet and try
          again in a few seconds.`
        );
      });
  };

  useEffect(() => {
    if (selectedSearchVal && selectedSearchVal.type === "class") {
      setActiveClass(selectedSearchVal);
    }
  }, [selectedSearchVal]);

  useEffect(() => {
    if (lessons && lessons.length > 0) {
      let lessonChoices = filterLessonsBySubjectUnit(
        lessons,
        activeSubject,
        activeUnit
      );
      let lessonOptions = [];
      lessonChoices.forEach((v) => {
        if (v["options"]) {
          lessonOptions = lessonOptions.concat(v["options"]);
          console.log("LESSON OPTIONS:", lessonOptions);
        }
      });

      // If there are lessons, fetch reports for all options to know which lesson is most popular (taken by most learners)
      if (lessonOptions.length > 0) {
        fetchReportsForAllOptions(lessonOptions);
      }
      if (!activeUnit) {
        setActiveUnit({ value: "All Units", label: "All Units" });
      }
    }
  }, [activeSubject, activeUnit]);

  // function to fetch reports for all lesson options to find the most popular lesson
  const fetchReportsForAllOptions = async (lessonOptions) => {
    try {
      const reports = await Promise.all(
        lessonOptions.map((option) =>
          api
            .fetchClassAverageReport(option.value, activeClass.value)
            .then((report) => ({ option, report }))
            .catch((err) => ({ option, report: null, error: err }))
        )
      );

      const validReports = reports.filter(
        (result) => result.report && result.report.by_user
      );
      if (validReports.length > 0) {
        const mostPopularLesson = validReports.reduce((max, current) =>
          current.report.by_user.length > max.report.by_user.length
            ? current
            : max
        );
        console.log(
          "Most Popular Lesson (Taken by most learners):",
          mostPopularLesson
        );

        setSelectedLesson(mostPopularLesson.option);
        updateLessonData(
          mostPopularLesson.option.value,
          mostPopularLesson.option
        );
      }
    } catch (err) {
      setClassFetchErr(err.message);
    }
  };

  // Make the subject with most lessons taken by learners the default subject
  useEffect(() => {
    if (lessons && lessons.length > 0) {
      //Aggregate lesson counts by course
      const lessonCounts = lessons.reduce((subject_detail, lesson) => {
        const courseId = lesson.course_info.id;
        if (!subject_detail[courseId]) {
          subject_detail[courseId] = {
            count: 0,
            course_info: lesson.course_info,
          };
        }
        subject_detail[courseId].count += lesson.options.length;
        return subject_detail;
      }, {});

      //Find the course with the highest total lesson count
      const subjectWithMostLessons = Object.values(lessonCounts).reduce(
        (prev, current) => {
          return prev.count > current.count ? prev : current;
        }
      );

      //Set the active subject
      setActiveSubject({
        value: subjectWithMostLessons.course_info.id,
        label: subjectWithMostLessons.course_info.course,
      });
    } else {
      setActiveSubject(null);
    }
  }, [lessons]);

  useEffect(() => {
    setActiveUnit(null);
    // Make the class with most learners the default class
    if (!activeClass && classes && classes.length > 0) {
      const classWithMostLearners = classes.reduce(
        (maxClass, currentClass) =>
          currentClass.total_learners > maxClass.total_learners
            ? currentClass
            : maxClass,
        classes[0]
      );
      setActiveClass({
        value: classWithMostLearners.id,
        label: classWithMostLearners.name,
      });
      fetchCoursesData(classWithMostLearners.id);
    } else if (activeClass) {
      fetchCoursesData(activeClass.value);
    }

    if (activeClass) {
      if (selectedLesson) {
        updateLessonData(selectedLesson.value, selectedLesson);
      } else {
        setClassAverageData(null);
      }
    }
  }, [activeClass, classes, lessonSource]);

  const buildClassMenuItems = () => {
    if (!classes) {
      return null;
    }
    let items = classes.map((val, index) => {
      return { value: val.id, label: val.name };
    });
    return items;
  };

  const buildCourseSubjectItems = () => {
    const courseOptions = [];
    coursenameDropdown.forEach((v) => {
      courseOptions.push({ value: v.cid, label: v.full_name });
    });
    return courseOptions;
  };

  const buildUnitItems = () => {
    let unitOptions = [{ value: "All Units", label: "All Units" }];

    if (courseLessons !== null && reports) {
      courseLessons.forEach((lsn) => {
        unitOptions.push({ value: lsn.unit, label: lsn.unit });
      });

      unitOptions = removeDuplicateUnitOptions(unitOptions);
    }
    return unitOptions;
  };

  const removeDuplicateUnitOptions = (unitOptions) => {
    unitOptions = unitOptions.filter(
      (unitOptions, index, self) =>
        index ===
        self.findIndex(
          (t) => t.value === unitOptions.value && t.label === unitOptions.label
        )
    );
    return unitOptions;
  };

  return (
    <div>
      <div
        id='gray_pdf_download_overlay'
        style={{ display: showOverlay ? "flex" : "none" }}
      >
        <FA color='black' icon='spinner' spin /> Fetching Report...
      </div>
      <div id='class_tab_header_container'>
        <h4 style={{ margin: "0px" }}>
          <b>Learner Performance + Attention Class Average</b>
        </h4>
        <PdfDownloadBtn
          btnCaption='Download Lesson Report...'
          enableBtn={
            learners &&
            learners.length > 0 &&
            classAverageData &&
            selectedLesson &&
            selectedLesson.value
          }
          setShowOverlay={setShowOverlay}
          type='lesson'
        />
      </div>

      <div id='class_tab_body'>
        <div id='options_container'>
          {rerouteObject && (
            <h5 style={{ margin: "10px 0px 10px 5px" }}>
              Viewing class: <b>{activeClass && activeClass.label}</b>
            </h5>
          )}
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ flex: "1", margin: "5px", minWidth: "180px" }}>
              <Select
                aria-label='Select Lesson Source'
                isSearchable={false}
                isDisabled={!ready}
                placeholder='Select Lesson Source'
                options={lessonSourceOptions}
                value={lessonSourceOptions.filter(
                  (option) => option.value === lessonSource
                )}
                onChange={handleLessonSourceChange}
              />
            </div>
            {!rerouteObject || showFullView ? (
              <div style={{ flex: "1", margin: "5px", minWidth: "180px" }}>
                <Select
                  aria-label='Select a Class'
                  isSearchable={false}
                  isDisabled={!ready}
                  onChange={(selected) => {
                    setActiveClass(selected);
                    setLessons([]);
                    setReady(false);
                    setError(null);
                    setClassFetchErr(null);
                    setReportsErr(null);
                  }}
                  value={activeClass}
                  placeholder='Select a class'
                  options={buildClassMenuItems()}
                />
              </div>
            ) : null}
          </div>
          {(error || reportsErr || classFetchErr) && (
            <div>
              {/* NO CLASSES */}
              {(!classes || classes.length === 0) &&
              user.usertype !== config.SUPERADMIN_USERTYPE &&
              user.usertype !== config.ORGADMIN_USERTYPE ? (
                <ReportsNoClassesErrMsg user={user} config={config} />
              ) : reportsErr ? (
                <NoReportsForClassMsg />
              ) : (
                <div className='alert alert-danger' role='alert'>
                  {error || classFetchErr}
                </div>
              )}
            </div>
          )}

          {!error && !reportsErr && !classFetchErr && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div style={{ flex: "1", margin: "5px", minWidth: "180px" }}>
                <Select
                  aria-label='Choose a subject'
                  isSearchable={false}
                  isDisabled={!ready}
                  onChange={(selected) => {
                    setActiveSubject(selected);
                    setActiveUnit(null);
                    setSelectedLesson("");
                    setLessonContextInfo(null);
                    setReady(false);
                    setError(null);
                    setClassFetchErr(null);
                    setReportsErr(null);
                  }}
                  value={activeSubject}
                  placeholder='Choose a subject'
                  options={buildCourseSubjectItems()}
                />
              </div>
              <div style={{ flex: "1", margin: "5px", minWidth: "180px" }}>
                <Select
                  aria-label='Choose a unit'
                  isSearchable={false}
                  onChange={(selected) => {
                    setActiveUnit(selected);
                  }}
                  isDisabled={!activeSubject || !activeClass || !ready}
                  value={activeUnit}
                  placeholder='Choose a unit'
                  options={buildUnitItems()}
                />
              </div>
            </div>
          )}

          {!error && !reportsErr && !classFetchErr && (
            <div style={{ margin: "5px", minWidth: "180px" }}>
              <ReportsGroupedLessonSelect
                lessons={lessons}
                changeHandler={updateLessonData}
                selectedLesson={selectedLesson.value}
                activeSubject={activeSubject}
                activeUnit={activeUnit}
                activeClass={activeClass}
                ready={ready}
              />
            </div>
          )}
          {/* Displays the table of lesson info.
            It should not display if:
            - The class has no learners
            - No learners in the class have taken the lesson
            - the lesson id doesn't exist in the options
          */}
          {learners &&
          ready &&
          lessons &&
          lessons.length !== 0 &&
          !groupingLessons &&
          learners.length !== 0 &&
          lessonContextInfo !== null &&
          typeof pairings[selectedLesson.value] !== "undefined" ? (
            <LessonContextTextInfo
              lessonContextInfo={lessonContextInfo}
              pairings={pairings}
              updateLessonData={updateLessonData}
              consolidatedReports={true}
            />
          ) : learners && learners.length === 0 ? (
            <p className='alert alert-warning' style={{ margin: "10px" }}>
              <b>This class has no learners.</b>
            </p>
          ) : null}

          {(!ready || groupingLessons) && (
            <div>
              <FA color='gray' icon='spinner' spin /> Loading...
            </div>
          )}
        </div>

        <ClassAveragesBarChart
          classAverageData={classAverageData}
          selectedLesson={lessonContextInfo}
          learners={learners}
        />

        <LearnerScoresTable
          learnersList={learners}
          activeSubject={activeSubject}
          activeUnit={activeUnit}
          setActiveUnit={setActiveUnit}
          reports={reports}
          lessons={lessons}
          ready={ready}
          groupingLessons={groupingLessons}
          setShowOverlay={setShowOverlay}
          reportsErr={reportsErr}
          setSelectedSearchVal={setSelectedSearchVal}
          setActiveTab={setActiveTab}
          listOfUnits={unitsDict}
          activeClass={activeClass}
          setViewLearnerInClass={setViewLearnerInClass}
        />
      </div>
    </div>
  );
};

export default compose(withAPI)(ClassTabContent);
