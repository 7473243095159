import React, { useState, useEffect } from 'react';
import withAPI from '../../services/api';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import FA from '../../containers/fa';


const EditRegCodeModal = ({api, shouldShowFunc, regCode, refreshRegCodeList}) => {
  const [spin, setSpin] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [maxRobots, setMaxRobots] = useState(regCode.max_robots)
  const [cloudAccounts, setCloudAccounts] = useState(regCode.cloud_accounts)

  const handleRegCodePatch = () => {
    setSpin(true);
    setError(false);
    setSuccess(false)
    setErrMsg(null);
    const data = {
      'max_robots': maxRobots,
      'cloud_accounts': cloudAccounts,
    }
    api.RetrieveUpdateDeleteRegCode(regCode.id, data, 'patch').then((data) => {
      console.log("patchRegCode returned: ", data)
      setSuccess(true)
      refreshRegCodeList()
      setSpin(false)
    }).catch(() => {
      setErrMsg("Something went wrong... Please try again.");
      setError(true);
      setSpin(false)
    });
  }

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Modify Activation Code</p>
            <div className="pdf_popup_close" onClick={() => shouldShowFunc(false)}><FA icon="times" /></div>
          </div>
          <div className="common_dashboard_bg">
            <div className="row">
              <div className="col-12">
                {error && <div className="alert alert-danger">{errMsg}</div>}
                {success && <div className="alert alert-success">Activation Code is successfully modified!</div>}
                
                <p>Make changes to the Activation Code.</p>
    	          
                <div style={{"display":"flex", "justifyContent":"left"}}>
                  <div className="mb-2">Cloud Accounts</div>
                  <input
                    className="ml-2"
                    type="checkbox"
                    disabled={spin || success}
                    checked={cloudAccounts}
                    onChange={() => {setCloudAccounts(!cloudAccounts)}}
                  />
                </div>

                Max Robots:
                <input required className="form-control input-underline input-lg" type="number"
    	            placeholder={maxRobots} onChange={(e) => setMaxRobots(e.target.value)} value={maxRobots} />
                <div className="mt-2">
                  {!success && <button onClick={handleRegCodePatch} className="btn btn-sm btn-primary mr-2" >Submit</button>}
                  <button
                    onClick={() => shouldShowFunc(false)}
                    className={`btn btn-sm ${success ? 'btn-primary' : 'btn-danger'}`}>
                      {success ? 'Ok' : 'Cancel'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)}

export default compose(withRouter, withAPI)(EditRegCodeModal);
