import React, {useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import withAPI from "../../services/api";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const mapStateToProps = (state, ownProps) => ({
  unsyncedLessons: state.status.unsyncedLessons,
  qsyncerSyncing: state.status.qsyncerSyncing,
  robotType: state.status.robotType,
  securityQPending: state.status.securityQPending,
  syncBtnClicked: state.status.syncBtnClicked,
});

const UserPasswordResetModal = ({
  usernameToChangePw,
  userIdToChangePw,
  email,
  setShowPasswordModal,
  api,
}) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Reset Password</p>
            <ClosePopupBtn closePopupFunc={setShowPasswordModal} />
          </div>
          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {error && (
                  <div>
                    <div className='alert alert-danger'>
                      Something went wrong when sending password setup
                      instructions to {email}.
                    </div>
                    <div className='d-flex'>
                      <button
                        onClick={() => {
                          setShowPasswordModal(false);
                        }}
                        className='btn btn-md btn-primary'
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}

                {success && (
                  <div>
                    <div className='alert alert-success'>
                      Password setup instructions have been sent to {email}.
                    </div>
                    <div className='d-flex'>
                      <button
                        onClick={() => {
                          setShowPasswordModal(false);
                        }}
                        className='btn btn-md btn-primary'
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}

                {!error && !success && (
                  <div>
                    <p>
                      Would you like to scramble the password for this user? An
                      email will be sent to {email} with instructions to reset
                      the password.
                    </p>
                    <div className='d-flex'>
                      <button
                        onClick={() => {
                          setShowPasswordModal(false);
                        }}
                        className='btn btn-sm btn-primary'
                      >
                        No, take me back
                      </button>
                      <button
                        onClick={() => {
                          console.log(
                            "Triggering reset for",
                            usernameToChangePw
                          );
                          api
                            .triggerPasswordResetEmail(userIdToChangePw)
                            .then(() => {
                              console.log("updated pw");
                              setSuccess(true);
                            })
                            .catch((e) => {
                              console.log(e);
                              setError(true);
                            });
                        }}
                        className='btn btn-sm btn-danger ml-4'
                      >
                        Yes, scramble password
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(mapStateToProps),
  withAPI
)(UserPasswordResetModal);
