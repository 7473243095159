import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {compose} from "redux";
import config from "../../common/config";
import {getOrgLevelDisplay, openInNewTab, useTitle} from "../../common/utils";
import StateSelector from "../../components/InputFields/StateSelector";
import OrgAccountsTable from "../../components/ManageAllAccounts/OrgAccountsTable";
import OrgSubscriptionsTable from "../../components/ManageAllSubscriptions/OrgSubscriptionsTable.js";
import OrgContactsModal from "../../components/SuperAdmin/OrgContactsModal";
import OrgInfoRow from "../../components/SuperAdmin/OrgInfoRow";
import OrgRobotsTable from "../../components/SuperAdmin/OrgRobotsTable";
import OrgSSOSettingsModal from "../../components/SuperAdmin/OrgSSOSettingsModal";
import OrgSyncSettingsModal from "../../components/SuperAdmin/OrgSyncSettingsModal";
import OrgSalesTable from "../../components/SuperAdmin/OrgSalesTable";
import OrgSnapshotRows from "../../components/SuperAdmin/OrgSnapshotRows";
import OrgSuperAdminSchoolsTable from "../../components/SuperAdmin/Schools/OrgSuperAdminSchoolsTable";
import FAW from "../../containers/faw";
import MainDashboard from "../../partials/Main";
import {refreshSaleDetail as refreshSaleDetailA} from "../../redux/actions";
import withAPI from "../../services/api";
import FA from "../fa";
import './OrgPage.scss';

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  refreshSaleDetail: state.superAdmin.refreshSaleDetail,
});

const OrgPage = ({api, dispatch, user, refreshSaleDetail, match}) => {
  useTitle("Organization Detail");
  const [shouldSpin, setShouldSpin] = useState(true);
  const [error, setError] = useState(null);
  const [org, setOrg] = useState({});
  const [orgStatus, setOrgStatus] = useState(null);
  const nonEditableRows = ["organization_code"];
  const orderedRows = [
    "snapshot",
    "organization_code",
    "max_enrolled_learners",
    "category",
    "district",
  ];

  const rowToLabelMap = {
    name: "Organization Name",
    organization_code: "Organization Code",
    state: "State",
    max_enrolled_learners: "Enrolled Learner Limit",
    district: "School District",
    allowed_sso_providers: "Allowed SSO Providers",
    default_sso_provider: "Default SSO Provider",
    login_with_password_allowed: "Login With Password Allowed for New Users",
    disable_pwd_with_sso:
      "Disable User Password When Linking External to Existing Users",
  };

  let history = useHistory();

  const emptyOrg = orderedRows.reduce((o, key) => ({...o, [key]: ""}), {});
  const [editableOrg, setEditableOrg] = useState(emptyOrg);
  const [orgSettings, setOrgSettings] = useState({});
  const [showOrgSSOSettings, setShowOrgSSOSettings] = useState(false);
  const [showOrgSyncSettings, setShowOrgSyncSettings] = useState(false);
  const [orgCleverDistrictId, setOrgCleverDistrictId] = useState("");

  const [schoolRefreshFromContacts, setSchoolRefreshFromContacts] =
    useState(false);

  const isSuperAdmin = () => {
    if (user) {
      if (user.usertype === config.SUPERADMIN_USERTYPE) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    console.log("editableOrg", editableOrg);
  }, [editableOrg]);

  useEffect(() => {
    console.log("school refresh triggered");
    if (schoolRefreshFromContacts) {
      setSchoolRefreshFromContacts(false);
    }
  }, [schoolRefreshFromContacts]);

  const fetchOrgInfo = () => {
    api.fetchOrganizationInfo(match.params.orgId).then((resp_json) => {
      console.log("Org Info:", resp_json);
      let newOrg = orderedRows.reduce(
        (o, key) => ({...o, [key]: resp_json[key]}),
        {}
      );
      setOrg(resp_json);
      setEditableOrg(newOrg);
      setOrgSettings(resp_json.settings);
      setOrgStatus(resp_json.status);
      setShouldSpin(false);
      // if Org uses Clever, also grab the Org's corresponding Clever district ID
      if (resp_json.settings && resp_json.settings) {
        api.fetchOrganizationCleverID(match.params.orgId).then((resp_json) => {
          let uid = resp_json.clever_id;
          console.log("Got Org Clever district ID:", uid);
          setOrgCleverDistrictId(uid);
        })
        .catch((e) => {
          console.log("Error fetching Org's Clever district ID...");
          console.log(e);
        });
      };
    });
  };

  useEffect(() => {
    fetchOrgInfo();
  }, []);

  useEffect(() => {
    if (orgStatus === "active") {
      rowToLabelMap["activated_at"] = "Waiting Period Ended On";
    }
  }, [orgStatus]);

  const refreshOrgAndSales = () => {
    fetchOrgInfo();
    dispatch(refreshSaleDetailA(!refreshSaleDetail));
  };

  function handleRemoveOrg() {
    if (window.confirm("Are you sure you want to remove this organization?")) {
      api
        .removeOrganization(match.params.orgId)
        .then((resp_json) => {
          console.log("Org removed:", resp_json);
          alert("Successfully removed the organization!");
          window.location.href = "/super/dashboard";
        })
        .catch((e) => {
          console.log("Error removing org.");
          console.log(e);
          alert("Something went wrong... Please try again.");
        });
    }
  }

  function isSELDisabled() {
    if (orgSettings && orgSettings.hasOwnProperty('sel_disabled')) {
      return orgSettings.sel_disabled;
    } else {
      return false;
    }
  }

  const [editOrgName, setEditOrgName] = useState(false);
  const [newOrgName, setNewOrgName] = useState(org.name);
  const [orgName, setOrgName] = useState(org.name);
  const [orgLevel, setOrgLevel] = useState(org.org_level || "");
  const [orgState, setOrgState] = useState(org.state || "");
  const [newOrgState, setNewOrgState] = useState(org.state || "");
  const [newOrgLevel, setNewOrgLevel] = useState(org.org_level || "");
  const [editOrgLevel, setEditOrgLevel] = useState(false);
  const [editOrgState, setEditOrgState] = useState(false);
  const [saveSpin, setSaveSpin] = useState(false);
  const [orgUsesClever, setOrgUsesClever] = useState(false);

  useEffect(() => {
    setOrgName(org.name);
    setOrgLevel(org.org_level || "");
    setOrgState(org.state || "");
    setNewOrgName(org.name);
    setNewOrgLevel(org.org_level || "");
    setNewOrgState(org.state | "");

    if (org.settings?.allowed_sso_providers?.length > 0) {
      setOrgUsesClever(
        org.settings.allowed_sso_providers.some((provider) => provider.includes('clever'))
      );
    }
  }, [org]);

  const handlePatchOrgField = (field) => {
    if (!["name", "org_level", "state"].includes(field)) {
      return;
    }
    setSaveSpin(true);
    let dataValue = null;
    if (field === "name") {
      dataValue = newOrgName;
    } else if (field === "org_level") {
      dataValue = newOrgLevel;
    } else if (field === "state") {
      dataValue = newOrgState;
    }
    let data = {[field]: dataValue};
    api
      .patchOrganizationInfo(match.params.orgId, data)
      .then((resp) => {
        setSaveSpin(false);
        if (field === "name") {
          setEditOrgName(false);
          setOrgName(newOrgName);
        } else if (field === "org_level") {
          setEditOrgLevel(false);
          setOrgLevel(newOrgLevel);
          // also refresh the org info
          refreshOrgAndSales();
        } else if (field === "state") {
          setEditOrgState(false);
          setOrgState(newOrgState);
          // also refresh the org info
          refreshOrgAndSales();
        }
        console.log("API returned", resp);
      })
      .catch((err) => {
        setSaveSpin(false);
        console.log("API error'd", err);
      });
  };

  return (
    <div>
      <MainDashboard>
        <div className='common_border'>
          <div className='common_heading'>
            <div className='d-flex justify-content-between align-items-center'>
              <h4>
                {!editOrgName && orgName}
                {editOrgName && (
                  <input
                    type='text'
                    placeholder={orgName}
                    value={newOrgName}
                    onChange={(e) => setNewOrgName(e.target.value)}
                  />
                )}
                {org.has_cloud_accounts && (
                  <span
                    data-title='Has Cloud Accounts'
                    style={{fontSize: "80%"}}
                  >
                    <FAW
                      icon='cloud'
                      className='status-icon'
                      style={{color: "#2fa8d7", marginLeft: "6px"}}
                    />
                  </span>
                )}
                {org.local_bots_only && (
                  <span data-title='Local Bots Only' style={{fontSize: "80%"}}>
                    <FAW
                      icon='network-wired'
                      className='status-icon'
                      style={{color: "#2fa8d7", marginLeft: "6px"}}
                    />
                  </span>
                )}
                {!editOrgName && (
                  <span style={{fontSize: "80%", marginLeft: "18px"}}>
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={() => setEditOrgName(true)}
                    >
                      <FA color='white' icon='edit' />
                    </button>
                  </span>
                )}
                {editOrgName && (
                  <span style={{fontSize: "80%", marginLeft: "18px"}}>
                    {saveSpin ? (
                      <button className='btn btn-sm btn-secondary mr-2'>
                        <FA color='white' icon='spinner' spin />
                      </button>
                    ) : (
                      <button
                        className='btn btn-sm btn-primary mr-2'
                        onClick={() => handlePatchOrgField("name")}
                      >
                        <FA color='white' icon='save' />
                      </button>
                    )}
                    <button
                      className='btn btn-sm btn-danger'
                      onClick={() => {
                        setEditOrgName(false);
                        setNewOrgName(orgName);
                      }}
                    >
                      <FA color='white' icon='times' />
                    </button>
                  </span>
                )}
              </h4>
              &nbsp;
              <button
                className='btn btn-sm btn-primary'
                onClick={() => history.goBack()}
              >
                Back
              </button>
            </div>
            {org.category === config.ABII_HOME.toLowerCase() && <p>(Home)</p>}

            {org.category === config.ABII_SCHOOL.toLowerCase() && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                {!editOrgLevel && <p>Level: {getOrgLevelDisplay(orgLevel)}</p>}
                {!editOrgLevel && (
                  <span style={{fontSize: "80%", marginLeft: "18px"}}>
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={() => setEditOrgLevel(true)}
                    >
                      <FA color='white' icon='edit' />
                    </button>
                  </span>
                )}
                {editOrgLevel && (
                  <select
                    className='input-sm'
                    defaultValue={newOrgLevel}
                    onChange={(e) => setNewOrgLevel(e.target.value)}
                  >
                    <option value='' disabled>
                      Select
                    </option>
                    <option value={config.ORG_LEVEL_SCHOOL}>
                      School-level
                    </option>
                    <option value={config.ORG_LEVEL_DISTRICT}>
                      District-level
                    </option>
                  </select>
                )}
                {editOrgLevel && (
                  <span style={{marginLeft: "18px"}}>
                    {saveSpin ? (
                      <button className='btn btn-sm btn-secondary mr-2'>
                        <FA color='white' icon='spinner' spin />
                      </button>
                    ) : (
                      <button
                        className='btn btn-sm btn-primary mr-2'
                        disabled={!newOrgLevel}
                        onClick={() => handlePatchOrgField("org_level")}
                      >
                        <FA color='white' icon='save' />
                      </button>
                    )}
                    <button
                      className='btn btn-sm btn-danger'
                      onClick={() => {
                        setEditOrgLevel(false);
                        setNewOrgLevel(orgLevel);
                      }}
                    >
                      <FA color='white' icon='times' />
                    </button>
                  </span>
                )}
              </div>
            )}

            {org.category === config.ABII_SCHOOL.toLowerCase() && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                {!editOrgState && (
                  <p style={{marginBottom: "0px"}}>State: {orgState}</p>
                )}
                {!editOrgState && (
                  <span style={{fontSize: "80%", marginLeft: "18px"}}>
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={() => setEditOrgState(true)}
                    >
                      <FA color='white' icon='edit' />
                    </button>
                  </span>
                )}

                {editOrgState && (
                  <span>
                    <StateSelector
                      value={newOrgState ? newOrgState : ""}
                      onChangeHandle={(e) => {
                        console.log(e.target.value);
                        setNewOrgState(e.target.value);
                      }}
                    />
                  </span>
                )}

                {editOrgState && (
                  <span style={{marginLeft: "18px"}}>
                    {saveSpin ? (
                      <button className='btn btn-sm btn-secondary mr-2'>
                        <FA color='white' icon='spinner' spin />
                      </button>
                    ) : (
                      <button
                        className='btn btn-sm btn-primary mr-2'
                        disabled={!newOrgState}
                        onClick={() => handlePatchOrgField("state")}
                      >
                        <FA color='white' icon='save' />
                      </button>
                    )}
                    <button
                      className='btn btn-sm btn-danger'
                      onClick={() => {
                        setEditOrgState(false);
                        setNewOrgState(orgState);
                      }}
                    >
                      <FA color='white' icon='times' />
                    </button>
                  </span>
                )}
              </div>
            )}

            {orgLevel === config.ORG_LEVEL_SCHOOL && (
              <p>School District: {org.district || "N/A"}</p>
            )}
          </div>

          {showOrgSSOSettings && (
            <OrgSSOSettingsModal
              orgId={match.params.orgId}
              orgSettings={orgSettings}
              orgCleverDistrictId={orgCleverDistrictId}
              refreshOrgInfo={fetchOrgInfo}
              setShowModal={setShowOrgSSOSettings}
            />
          )}

          {showOrgSyncSettings && (
            <OrgSyncSettingsModal
              orgId={match.params.orgId}
              orgSettings={orgSettings}
              refreshOrgInfo={fetchOrgInfo}
              setShowModal={setShowOrgSyncSettings}
            />
          )}

          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                <div className='row mb-10'></div>
                {error && (
                  <div className='alert alert-danger' role='alert'>
                    {error}
                  </div>
                )}
                {shouldSpin && (
                  <div>
                    <h4>
                      <FA color='gray' icon='spinner' spin /> Loading Details...
                    </h4>
                  </div>
                )}

                {isSuperAdmin() && !shouldSpin && (
                  <div>
                    <OrgContactsModal
                      org={org}
                      setSchoolRefreshFromContacts={
                        setSchoolRefreshFromContacts
                      }
                    />
                    <button
                      className='btn btn-primary float-right'
                      onClick={() => {
                        openInNewTab(
                          "/super/org/as-orgadmin/" + match.params.orgId
                        );
                      }}
                    >
                      Org Admin's View
                    </button>

                    <div className='card'>
                      <div
                        className='progress-linear school_listing manage_learner_table'
                        role='progressbar'
                      >
                        <table className='table table-striped table-sm text-left table-left-align-first-column'>
                          <tbody>
                            {orderedRows.map((k) => (
                              <React.Fragment key={k}>
                                {/* snapshot handled separately */}
                                {k === "snapshot" && (
                                  <OrgSnapshotRows snapshot={org[k]} />
                                )}

                                {k !== "snapshot" && (
                                  <OrgInfoRow
                                    key={k}
                                    fieldName={k}
                                    label={rowToLabelMap[k] || k}
                                    value={org[k]}
                                    instanceId={match.params.orgId}
                                    patchAPI={api.patchOrganizationInfo}
                                    canEdit={!nonEditableRows.includes(k)}
                                    refreshParent={refreshOrgAndSales}
                                  />
                                )}
                              </React.Fragment>
                            ))}

                            <OrgInfoRow
                              key={"activated_at"}
                              fieldName={"activated_at"}
                              label={
                                orgStatus === "active"
                                  ? "Waiting Period Ended On"
                                  : "Waiting Period Ends On"
                              }
                              value={org["activated_at"]}
                              instanceId={match.params.orgId}
                              patchAPI={api.patchOrganizationInfo}
                              canEdit={true}
                              refreshParent={refreshOrgAndSales}
                            />

                            {/* SSO and SEL Settings */}
                            <tr style={{borderTop: "#868e96 3px solid"}}>
                              <td>SSO Settings</td>
                              <td>
                                {orgSettings &&
                                orgSettings.allowed_sso_providers &&
                                orgSettings.allowed_sso_providers.length > 0
                                  ? "Enabled"
                                  : "Disabled"}
                              </td>
                              <td>
                                <button
                                  className='btn btn-md btn-primary'
                                  onClick={() => setShowOrgSSOSettings(true)}
                                >
                                  Manage SSO Settings
                                </button>
                              </td>
                            </tr>

                            {orgUsesClever && 
                            orgSettings?.allowed_sso_providers?.includes('clever_securesync') &&
                            <tr>
                              <td>Clever Sync Settings</td>
                              <td>
                                {orgSettings.clever_sync_enabled
                                  ? "Enabled"
                                  : "Disabled"}
                              </td>
                              <td>
                                <button
                                  className='btn btn-md btn-primary'
                                  onClick={() => setShowOrgSyncSettings(true)}
                                >
                                  Manage Sync Settings
                                </button>
                              </td>
                            </tr>
                            }

                            <OrgInfoRow
                              key={"sel_disabled"}
                              fieldName={"sel_disabled"}
                              label={"Disable SEL Content"}
                              value={isSELDisabled()}
                              instanceId={match.params.orgId}
                              patchAPI={api.patchOrganizationInfo}
                              canEdit={true}
                              refreshParent={refreshOrgAndSales}
                              settingParams={{
                                name: 'sel_disabled',
                                type: 'boolean',
                              }}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {org && org.can_delete && (
                  <div className='row'>
                    <div className='col-md-4 offset-md-4'>
                      <button
                        className='btn btn-danger w-100'
                        onClick={() => {
                          handleRemoveOrg();
                        }}
                      >
                        {" "}
                        Remove Organization
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <OrgSalesTable
          orgId={match.params.orgId}
          orgType={org.category}
          taxRate={org.tax_rate}
          orgLevel={org.org_level}
          canCollapse={true}
        />
        <OrgSubscriptionsTable
          orgId={match.params.orgId}
          canCollapse={true}
          saleIsFinalizedState={true}
        />
        <OrgRobotsTable orgId={match.params.orgId} canCollapse={true} />
        <OrgAccountsTable orgId={match.params.orgId} canCollapse={true} />
        <OrgSuperAdminSchoolsTable
          orgId={match.params.orgId}
          canCollapse={true}
          orgLevel={org.org_level}
          schoolRefreshFromContacts={schoolRefreshFromContacts}
        />
      </MainDashboard>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(OrgPage);
