import config from "../../common/config";

export const allowedUsertypeOptions = (usertype) => {
  let allowedTypes = {
    [config.SUPERADMIN_USERTYPE]: [
      config.ORGADMIN_USERTYPE,
      config.ORGUNITADMIN_USERTYPE,
      config.TEACHER_USERTYPE,
    ],
    [config.ORGADMIN_USERTYPE]: [
      config.ORGADMIN_USERTYPE,
      config.ORGUNITADMIN_USERTYPE,
      config.TEACHER_USERTYPE,
    ],
    [config.ORGUNITADMIN_USERTYPE]: [
      config.ORGUNITADMIN_USERTYPE,
      config.TEACHER_USERTYPE,
    ],
    [config.TEACHER_USERTYPE]: [config.TEACHER_USERTYPE],
  };
  let list = config.USERROLES.filter((role) => {
    return allowedTypes[usertype].includes(role.value);
  });
  return list;
};

/* "editable" is an optional kwarg, provided in the second positional argument,
 * which is an object that gets decontructed and parsed
 * example use:  makeSchoolOptions(orgunits, { editable: false })
 */
export const makeSchoolOptions = (orgunits, { editable = true } = {}) => {
  if (orgunits) {
    return orgunits.map((ou) => ({
      label: ou.name,
      value: ou.id,
      editable: editable,
      organization: ou.organization,
    }));
  } else {
    return [];
  }
};

export const schoolOptionsToInfo = (options) => {
  if (options) {
    return options.map((option) => ({
      name: option.label,
      id: option.value,
    }));
  } else {
    return [];
  }
};

export const editableSubset = (allSchools, user) => {
  // Given a list of all visible OrgUnits and serialized user profile,
  // return the subset of those OrgUnits that the user should be able to modify
  if (
    user.usertype === config.ORGUNITADMIN_USERTYPE ||
    user.usertype === config.TEACHER_USERTYPE
  ) {
    let editableIds = user.orgunits.map((ownOU) => ownOU.id);
    return allSchools.filter((ou) => editableIds.includes(ou.id));
  } else {
    return allSchools;
  }
};

export const getAllSchoolInfo = (api, queryParams = null) => {
  return api
    .fetchOrgUnits(queryParams)
    .then((orgunits) => {
      return orgunits;
    })
    .catch((err) => {
      console.log("Error fetching orgunits: ", err);
      return [];
    });
};

export const getLessonSources = (
  api,
  showCustomLessons = false,
  showAllSources = false
) => {
  return api
    .fetchAbiiContentPackages()
    .then((res) => {
      const transformedOptions = res.map((item) => ({
        label: item.name,
        value: `abii_content_package:${item.uuid}`,
      }));

      const baseOptions = [];
      if (showAllSources) {
        baseOptions.push({
          label: "All Lessons (No Custom)",
          value: "distributed",
        });
      }
      baseOptions.push({ label: "ABii Lessons", value: "internal" });

      const lessonSources = [...baseOptions, ...transformedOptions];
      if (showCustomLessons) {
        lessonSources.push({ label: "Custom", value: "custom" });
      }

      return lessonSources;
    })
    .catch((e) => {
      console.log("Error fetching lesson sources: ", e);
      return [];
    });
};
