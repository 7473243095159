import React, { useState, useEffect } from "react";
import withAPI from "../../services/api";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import FA from "../../containers/fa";
import { debugLog } from "../../common/utils";

const UpdateAllRobotsOnlineModal = ({
  api,
  qString,
  setUpdateInProgress,
  updateEnded,
  shouldShowFunc,
  totalProgress,
  previousProgress,
}) => {
  const updateOptDefaults = {
    lesson: true,
    code: true,
  };

  const [loading, setLoading] = useState(true);
  const [loadingRobots, setLoadingRobots] = useState(true);
  const [allRobots, setAllRobots] = useState([]);
  const [updateOpts, setUpdateOpts] = useState(updateOptDefaults);
  const [spinUpdate, setSpinUpdate] = useState(false);
  const [spinCancel, setSpinCancel] = useState(false);
  const [confirmingUpdate, setConfirmingUpdate] = useState(false);
  const [updateTriggered, setUpdateTriggered] = useState(false);
  const [updateFinished, setUpdateFinished] = useState(false);
  const [updateCanceled, setUpdateCanceled] = useState(false);
  const [retryTimeout, setRetryTimeout] = useState(null);
  const [tableContents, setTableContents] = useState([]);
  const [visibleRobots, setVisibleRobots] = useState([]);
  const [progressDismissed, setProgressDismissed] = useState(false);
  const [error, setError] = useState(null);
  const [updateFinishedWithErrors, setUpdateFinishedWithErrors] =
    useState(false);
  const [robotSpecificError, setRobotSpecificError] = useState(null);

  const fetchRobots = () => {
    let queryParams = {
      show_all: true,
      simple_view: true,
      q: qString || "",
    };
    setLoadingRobots(true);
    api.fetchAllVisibleRobots(queryParams).then((objects) => {
      setAllRobots(objects.results);
      setLoadingRobots(false);
      debugLog("UpdateAllRobotsOnlineModal got robots: ", objects);
    });
  };

  useEffect(() => {
    fetchRobots();
  }, []);

  function handleUpdate() {
    if (updateFinished && !progressDismissed) {
      setProgressDismissed(true);
    } else if (confirmingUpdate) {
      setUpdateCanceled(false);
      setUpdateFinished(false);
      setUpdateFinishedWithErrors(null);
      setSpinUpdate(true);
      // Hit START endpoint
      let payload = Object.assign(
        {},
        { cmd: "START" },
        { opts: updateOpts },
        { robots: tableContents }
      );
      console.log("created payload:", payload);
      api
        .startUpdateAllOnlineRobots(payload)
        .then((resp) => {
          // On success, begin polling for progress updates
          setConfirmingUpdate(false);
          setUpdateTriggered(true);
          setTimeout(() => {
            setUpdateInProgress(true);
            setProgressDismissed(false);
          }, 3000);
        })
        .catch((e) => {
          console.log("error'd:", e);
          setConfirmingUpdate(false);
          setUpdateTriggered(false);
          setSpinUpdate(false);
          setError(e.message);
        });
    } else {
      setConfirmingUpdate(true);
      setError(null);
    }
  }

  function handleCancel() {
    setError(null);
    setSpinCancel(true);
    api
      .cancelUpdateAllOnlineRobots()
      .then((resp) => {
        if (resp.status && resp.status === "error") {
          setError(resp.status.message);
          setSpinCancel(false);
        } else if (resp.status && resp.status === "ok") {
          setUpdateCanceled(true);
          setSpinCancel(false);
          setSpinUpdate(false);
        }
      })
      .catch((e) => {
        console.log("error'd:", e);
        setError("Unknown error when attempting to cancel the process...");
      });
  }

  function handleClose() {
    shouldShowFunc(false);
    clearTimeout(retryTimeout);
  }

  /* GENERATE ARRAY FOR TABLE IN THIS MODAL */
  function defaultProgressMessageObject(rbt) {
    if (rbt.is_latest) {
      return {
        ...rbt,
        status: "SUCCESS",
        detail: "Up to date",
      };
    } else if (!rbt.can_update) {
      return {
        ...rbt,
        status: "EXPIRED",
        detail: "Subscription expired.",
      };
    } else {
      return {
        ...rbt,
        status: "None",
        detail: "Not started.",
      };
    }
  }

  const interpretProgressMsg = () =>
    new Promise((resolve) => {
      debugLog("popup received progress:", totalProgress);
      if (updateFinished && !progressDismissed) {
        setSpinUpdate(false);
        setUpdateTriggered(false);
        setLoading(false);
        resolve({ ...totalProgress });
      } else if (
        !totalProgress.message ||
        progressDismissed ||
        (!totalProgress.message.running && !updateFinished)
      ) {
        // No update in progress already. Set default progress messages
        const tmpArray = visibleRobots.map((rbt) => {
          return defaultProgressMessageObject(rbt);
        });
        const tmpTotalProgress = {
          message: {
            running: false,
            robots: tmpArray,
          },
        };
        setLoading(false);
        resolve(tmpTotalProgress);
      } else if (totalProgress.message.running) {
        setSpinUpdate(true);
        setUpdateTriggered(true);
        setLoading(false);
        resolve({ ...totalProgress });
      } else {
        /* Not sure what else could have happened, but act like we got an empty message */
        setSpinUpdate(false);
        setUpdateTriggered(false);
        setLoading(false);
        resolve({
          message: {
            running: false,
            robots: [],
          },
        });
      }
    });

  useEffect(() => {
    interpretProgressMsg().then((tmpTotalProgress) => {
      /* Generate info for table from totalProgress */
      // Re-cast the list of robot objets in the totalProgress blob as an object with keys
      if (tmpTotalProgress) {
        const progress_obj = tmpTotalProgress.message.robots.reduce(
          (accumulator, rbt) => {
            return { ...accumulator, [rbt.serial]: rbt };
          },
          {}
        );
        // For each robot in list of visible Robots, grab progress from progress_obj
        const viewableProgress = visibleRobots.map((rbt) => {
          if (progress_obj[rbt.serial]) {
            return {
              ...rbt,
              status: progress_obj[rbt.serial].status,
              detail: progress_obj[rbt.serial].detail,
            };
          } else {
            return {
              ...rbt,
              status: null,
              detail: "No status listed.",
            };
          }
        });
        setTableContents(viewableProgress);
      }
    });
  }, [visibleRobots, progressDismissed, totalProgress, updateFinished]);

  useEffect(() => {
    if (!loadingRobots) {
      if (
        (updateFinished && !progressDismissed) ||
        (totalProgress.message && totalProgress.message.running)
      ) {
        if (totalProgress.message && totalProgress.message.robots) {
          const botsInProgress = totalProgress.message.robots.map(
            (rbt) => rbt.serial
          );
          const tmpList = allRobots.filter((rbt) =>
            botsInProgress.includes(rbt.serial)
          );
          setVisibleRobots(tmpList);
        } else {
          setVisibleRobots([]);
        }
      } else {
        let onlineBots = allRobots.filter((rbt) => rbt.online);
        setVisibleRobots(onlineBots);
      }
    }
  }, [loadingRobots, progressDismissed, totalProgress, updateFinished]);

  function updateJustFinished() {
    if (
      totalProgress &&
      previousProgress &&
      totalProgress.message &&
      previousProgress.message
    ) {
      let runningBefore = previousProgress.message.running;
      let runningNow = totalProgress.message.running;
      if (runningBefore && !runningNow) {
        return true;
      } else if (!runningNow && !updateFinished && updateEnded) {
        // If update ends while modal is closed, this is how the modal will learn about it
        debugLog(
          "[updateJustFinished] recognized that update ended while modal was closed"
        );
        setUpdateFinished(true);
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    // Did update just finish, and did any of the updates fail?
    debugLog("[Modal] comparing previousProgress:", previousProgress);
    debugLog("[Modal] with totalProgress:", totalProgress);
    if (updateJustFinished()) {
      setUpdateFinished(true);
      setSpinUpdate(false);
      setUpdateTriggered(false);
    }
    if (updateJustFinished() && !updateCanceled) {
      const completeSuccess = totalProgress.message.robots.reduce(
        (accumulator, rbt) => {
          // If any of the individual robot status elements were not 'SUCCESS', return false
          return accumulator && rbt.status === "SUCCESS";
        },
        true
      );
      setUpdateFinishedWithErrors(!completeSuccess);
    }
  }, [totalProgress, previousProgress]);

  function buttonText() {
    if (updateFinished && !progressDismissed) {
      return "Dismiss update/sync details";
    } else if (confirmingUpdate) {
      return "Please Confirm";
    } else if (updateTriggered && updateCanceled) {
      return (
        <React.Fragment>
          <FA color="white" icon="spinner" spin />
          &nbsp;
          {" Update/Sync in progress..."}
        </React.Fragment>
      );
    } else if (spinUpdate) {
      return (
        <React.Fragment>
          <FA color="white" icon="spinner" spin />
          &nbsp;
          {" Updating/Syncing robots..."}
        </React.Fragment>
      );
    } else {
      return "Update/Sync these robots now";
    }
  }

  function statusToIcon(status) {
    if (status === "QUEUED") {
      return null;
    } else if (status === "WORKING") {
      return (
        <React.Fragment>
          <FA color="gray" icon="spinner" spin />
          &nbsp;&nbsp;
        </React.Fragment>
      );
    } else if (status === "SUCCESS") {
      return (
        <React.Fragment>
          <FA color="green" icon="check" />
          &nbsp;&nbsp;
        </React.Fragment>
      );
    } else if (status === "FAIL") {
      return (
        <React.Fragment>
          <FA color="red" icon="times" />
          &nbsp;&nbsp;
        </React.Fragment>
      );
    } else if (status === "DONE") {
      return (
        <React.Fragment>
          <FA color="gray" icon="question" />
          &nbsp;&nbsp;
        </React.Fragment>
      );
    } else if (status === "EXPIRED") {
      return (
        <React.Fragment>
          <FA color="red" icon="exclamation-circle" />
          &nbsp;&nbsp;
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  function renderDetail(rbt) {
    if (rbt.status == "SUCCESS") {
      return <div style={{ color: "green" }}>{rbt.detail}</div>;
    } else if (rbt.status == "FAIL") {
      return (
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => {
            setRobotSpecificError({
              header: (
                <React.Fragment>
                  {rbt.wifi}
                  {" has failed to update"}
                </React.Fragment>
              ),
              text: (
                <React.Fragment>
                  {rbt.detail}
                  <br />
                  <br />
                  {
                    "Please make sure this robot has access to the internet and is online for the entire duration of the update. If the problem persists, please contact "
                  }
                  <a href="mailto:support@myvanrobot.com">
                    {"support@myvanrobot.com."}
                  </a>
                </React.Fragment>
              ),
            });
          }}
        >
          {rbt.detail}
        </div>
      );
    } else {
      return <div>{rbt.detail}</div>;
    }
  }

  function renderProgress(rbt) {
    return (
      <div className="d-flex justify-content-left">
        <div>{statusToIcon(rbt.status)}</div>
        {renderDetail(rbt)}
      </div>
    );
  }

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper-large">
        <div className="common_border">
          <div className="common_heading">
            <p>Robots Online Now</p>
            <button
              autoFocus
              className="pdf_popup_close"
              onClick={() => handleClose()}
            >
              <FA icon="times" />
            </button>
          </div>
          <div
            className="common_dashboard_bg table-scroll-wrapper"
            style={{ maxHeight: "525px" }}
          >
            <div className="row">
              <div className="col-lg">
                {loading && (
                  <div className="row ml-3">
                    <FA color="black" icon="spinner" spin />
                    &nbsp;{"Loading status..."}
                  </div>
                )}

                {!loading && loadingRobots && (
                  <div className="row ml-3">
                    <FA color="black" icon="spinner" spin />
                    &nbsp;{"Loading robots..."}
                  </div>
                )}

                {!loading && (
                  <React.Fragment>
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}

                    {/* Pre-Update, list robots currently online */}
                    <React.Fragment>
                      {updateFinished && !progressDismissed ? (
                        <div className="row ml-3">
                          {
                            "Showing results of the previous update/sync operation."
                          }
                        </div>
                      ) : visibleRobots.length === 0 ? (
                        <div className="row ml-3">
                          {"No robots are currently online."}
                        </div>
                      ) : (
                        <div className="row ml-3">
                          {"These robots are currently online."}
                        </div>
                      )}
                      <div className="row ml-3 my-3 d-flex align-items-center">
                        <div>
                          <button
                            className="btn btn-md btn-primary mr-3"
                            style={{ minWidth: "200px" }}
                            onClick={() => {
                              handleUpdate();
                            }}
                            disabled={
                              spinUpdate ||
                              updateTriggered ||
                              visibleRobots.length === 0
                            }
                          >
                            {buttonText()}
                          </button>
                        </div>
                      </div>
                    </React.Fragment>

                    {/* During update, show CANCEL button */}
                    {updateTriggered && !updateFinished && !updateCanceled && (
                      <div className="row ml-3 d-flex align-items-center">
                        <button
                          className="btn btn-md btn-danger"
                          style={{ minWidth: "200px" }}
                          onClick={() => handleCancel()}
                          disabled={spinCancel}
                        >
                          {spinCancel ? (
                            <div>
                              <FA color="white" icon="spinner" spin />
                              &nbsp;{"Canceling process..."}
                            </div>
                          ) : (
                            <div>{"Cancel"}</div>
                          )}
                        </button>
                      </div>
                    )}

                    {/* Post-Cancel Message/Warning */}
                    {updateCanceled && (
                      <div className="alert alert-warning">
                        {updateTriggered ? (
                          <React.Fragment>
                            {
                              "Update/Sync canceled, but we can't interrupt robots that are already updating."
                            }
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {"Update/Sync canceled."}
                          </React.Fragment>
                        )}
                      </div>
                    )}

                    {/* Post-completion message*/}
                    {updateFinished &&
                      !progressDismissed &&
                      !updateCanceled &&
                      updateFinishedWithErrors !== null && (
                        <React.Fragment>
                          {updateFinishedWithErrors ? (
                            <div className="alert alert-warning">
                              {
                                "At least one robot failed to update. You may need to ensure that these robots have a strong internet connection and try again."
                              }
                            </div>
                          ) : (
                            <div className="alert alert-success">
                              {"Update/Sync operation successful!"}
                            </div>
                          )}
                        </React.Fragment>
                      )}

                    {/* Robot-specific error popup */}
                    {robotSpecificError && (
                      <React.Fragment>
                        <div className="pdf-upload">
                          <div className="pdf-upload-wrapper-small">
                            <div className="common_border">
                              <div className="common_heading">
                                <p>{robotSpecificError.header}</p>
                                <button
                                  className="pdf_popup_close"
                                  onClick={() => {
                                    setRobotSpecificError(null);
                                  }}
                                >
                                  <FA icon="times" />
                                </button>
                              </div>
                              <div className="common_dashboard_bg">
                                <div className="row">
                                  <div className="col-sm">
                                    <p style={{ textAlign: "center" }}>
                                      {robotSpecificError.text}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                  <button
                                    className="btn btn-md btn-primary"
                                    onClick={() => {
                                      setRobotSpecificError(null);
                                    }}
                                  >
                                    {"Ok"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    {/* Table of Robots */}
                    <table className="table table-sm table-striped my-3">
                      <thead>
                        <tr>
                          <th>{"Wifi"}</th>
                          <th>{"Code Version"}</th>
                          <th>{"Progress"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableContents &&
                          tableContents.map((rbt) => (
                            <tr key={rbt.serial}>
                              <td>{rbt.wifi && rbt.wifi}</td>
                              <td>
                                {rbt.code_ver && (
                                  <React.Fragment>
                                    <span className="mr-1">{rbt.code_ver}</span>
                                    {rbt.is_latest ? (
                                      <FA color="green" icon="check-circle" />
                                    ) : (
                                      <FA
                                        color="#ffc107"
                                        icon="exclamation-circle"
                                      />
                                    )}
                                  </React.Fragment>
                                )}
                              </td>
                              <td>{renderProgress(rbt)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, withAPI)(UpdateAllRobotsOnlineModal);
