import React from 'react';

import Gate from '../partials/Gate';
import ABiiResourceStatus from '../components/ABiiResourceStatus'


function ABiiResource() {
  return (
    <Gate boxWidthClass="col-lg-6 col-md-8 col-sm-12">
      <div className="login_box form-content container form-group">
        <div className="row">
          <div className="col">
            <h1 style={{"textAlign": "center"}}>Access to ABii Resources</h1>
          </div>
        </div>
        <div className="row">
          <ABiiResourceStatus />
        </div>
      </div>
    </Gate>
  );
}

export default ABiiResource;
