import React from "react";
import PropTypes from "prop-types";
import FA from "../../containers/fa";

const DefaultGrayIconWithHoverText = ({
  hoverText,
  iconClassName,
  iconColor,
}) => (
  <span style={{ position: "relative" }} subscription-data-title={hoverText}>
    <FA icon={iconClassName} color={iconColor} />
  </span>
);

DefaultGrayIconWithHoverText.propTypes = {
  hoverText: PropTypes.string,
  iconClassName: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
};

export default DefaultGrayIconWithHoverText;
