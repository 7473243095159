import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withAPI from '../../services/api';
import MainDashboard from '../../partials/Main';

import FA from '../fa';

const MyRobot = ({ api }) => {

  const [shouldSpin, setShouldSpin] = useState(true);
  const [robotInfo, setRobotInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setShouldSpin(true);
    api.fetchAllVisibleRobots().then((resp_json) => {
    // api.fetchMyRobotInfo().then((resp_json) => {
      console.log(resp_json)
      if (resp_json.results.length > 0) {
        let data = resp_json.results[0]
        if (data.serial) {
          console.log("Got Robot info:",data);
          setRobotInfo(data);
        }
      }
      setShouldSpin(false);
    })
  }, []);

  return (
    <div>
      <MainDashboard>
          <div className="common_border">
            <div className="common_heading d-flex justify-content-between align-items-center">
              <h4>My Robot</h4>
            </div>

            <div className="common_dashboard_bg">
              <div className="row">
                <div className="col-12">
                    <div className="row mb-10">
                    </div>
                    { error &&
                      <div className="alert alert-danger" role="alert">{error}</div>
                    }
                    {shouldSpin &&
                      <div><h4><FA color="gray" icon="spinner" spin /> Loading Details...</h4></div>
                    }
                    {!shouldSpin && robotInfo === null &&
                      <div>
			Unable to locate a Robot for you. <br></br><br></br>
			You may need to contact our Support team, at support@myvanrobot.com
		      </div>
                    }

                    {!shouldSpin && robotInfo !== null && <div className="card">
                        <div className="progress-linear school_listing manage_learner_table" role="progressbar">
                          <table className="table table-striped">
                            <tbody>
                              <tr key='Wifi'>
                                <td><span>Wifi</span></td>
                                <td><span>{robotInfo.wifi}</span></td>
                              </tr>
                              <tr key='Serial'>
                                <td><span>Serial</span></td>
                                <td><span>{robotInfo.serial}</span></td>
                              </tr>
                              <tr key='MAC'>
                                <td><span>MAC</span></td>
                                <td><span>{robotInfo.mac}</span></td>
                              </tr>
                              <tr key='codeVer'>
                                <td><span>Code Version</span></td>
                                <td><span>{robotInfo.code_ver}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>

      </MainDashboard>
    </div>
  );


}

export default compose(
  withAPI
)(MyRobot);
