import React, { useState, useEffect } from 'react';
import withAPI from '../../services/api';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import FA from '../../containers/fa';
import config from '../../common/config';
import { authService } from '../../services/auth';
import { logout } from '../../redux/actions';

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});


const DisconnectLinkedAccountModal = ({api, setShowSelf, refreshAccountList, linkedAccount, accountCount, user}) => {
  const [error, setError] = useState(null);
  const [disconnectAllowed, setDisconnectAllowed] = useState(true);
  const [disconnecting, setDisconnecting] = useState(false);
  const [accountDisconnected, setAccountDisconnected] = useState(false);

  const handleDisconnect = () => {
    setDisconnecting(true);
    api.removeConnectedAccount(linkedAccount.id).then((res) => {
      setAccountDisconnected(true);
      setDisconnecting(false);
    }).catch((e) => {
      setDisconnecting(false);
      setError(e);
    })
  }

  useEffect(() => {
    // If the User can't login with a password and this is their only linked
    // account, don't allow them to disconnect it or else they'll completely
    // lose access to the account
    console.log("Linked account: ",linkedAccount);
    if (accountCount === 1 && !user.can_login_with_password) {
      setDisconnectAllowed(false);
    }
  }, []);


  if (accountDisconnected) {
    return (
    <div className="pdf-upload top-layer">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Disconnect Linked Account</p>
            <div className="pdf_popup_close"
              onClick={() => {
                refreshAccountList();
                setShowSelf(false);
              }}
            >
              <FA icon="times" />
            </div>
          </div>
          <div className="common_dashboard_bg">
            <div className="row">
              <div className="col-12">
                <div className="alert alert-success">
                  Your linked account has been disconnected.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}

  return (
    <div className="pdf-upload">
      <div className="pdf-upload-wrapper">
        <div className="common_border">
          <div className="common_heading">
            <p>Disconnect Linked Account</p>
            <div className="pdf_popup_close" 
              onClick={() => setShowSelf(false)}
            >
              <FA icon="times" />
            </div>
          </div>
          <div className="common_dashboard_bg">
            <div className="row">
              <div className="col-12">

                <p>Are you sure you want to disconnect this external account?</p>

                <React.Fragment>
                  {!disconnectAllowed &&
                    <p className="alert-danger p-2">
                      WARNING:
                      If this account were disconnected, there would be no way for you to log in.
                      If you want to delete your account, use the "Permanently Delete My Account" option below.
                    </p>
                  }
                  <div className="d-flex mt-4">
                    <button 
                      onClick={() => setShowSelf(false)}
                      className="btn btn-sm btn-primary"
                    >
                      {"Take me back"}
                    </button>
                    <button
                      className="btn btn-sm btn-danger ml-4"
                      onClick={handleDisconnect}
                      disabled={!disconnectAllowed || disconnecting}
                    >
                      {"Disconnect Account"}
                    </button>
                  </div>
                </React.Fragment>

                {error && 
                  <div className="alert alert-danger mt-4">
                    {error === true ? 'Something went wrong.' : error}
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)}

export default compose(
  connect(mapStateToProps),
  withAPI,
)(DisconnectLinkedAccountModal);
