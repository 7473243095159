import React from "react";
import {Link} from "react-router-dom";
import config from "../../common/config";
const RerouteObjectMsg = ({
  user,
  hasRobots,
  rerouteObjectType,
  rerouteObject,
}) => {
  console.log("user", user);

  let isTeacher =
    user.usertype === config.TEACHER_USERTYPE ||
    (user.usertype === config.ORGADMIN_USERTYPE &&
      hasRobots.user &&
      user.organization.category !== config.ABII_HOME.toLowerCase());

  if (rerouteObjectType === "learner")
    return (
      <div
        className='alert alert-warning'
        style={{margin: "5px", height: "fit-content"}}
        role='alert'
      >
        <b>
          {`You are currently viewing reports for 
          ${rerouteObject.first_name} ${rerouteObject.last_name}.`}{" "}
        </b>
        {isTeacher && (
          <b>
            <Link to='/user/reports'>Click here</Link> to choose another class
            or learner from your classes.
          </b>
        )}
      </div>
    );
  if (rerouteObjectType === "class")
    return (
      <div
        className='alert alert-warning'
        style={{margin: "5px", height: "fit-content"}}
        role='alert'
      >
        <b>{`You are currently viewing reports for ${rerouteObject.name}.`} </b>
        {isTeacher && (
          <b>
            <Link to='/user/reports'>Click here</Link> to choose another class
            or learner from your classes.
          </b>
        )}
      </div>
    );

  return null;
};

export default RerouteObjectMsg;
