import moment from "moment";
import React, {useEffect, useState} from "react";
import {compose} from "redux";
import withAPI from "../../../services/api";
import InfoPopup from "../../UtilComps/InfoPopup";
import CirclePageIndicators from "./CirclePageIndicators";
import "./CreateAssignmentModal.scss";
import SelectedLearnersPopup from "./Steps/SelectedLearnersPopup";
import SelectedLessonsPopup from "./Steps/SelectedLessonsPopup";
import CreateAssignmentStepFour from "./Steps/StepFour/CreateAssignmentStepFour";
import CreateAssignmentStepOne from "./Steps/StepOne/CreateAssignmentStepOne";
import CreateAssignmentStepThree from "./Steps/StepThree/CreateAssignmentStepThree";
import CreateAssignmentStepTwo from "./Steps/StepTwo/CreateAssignmentStepTwo";
import ClosePopupBtn from "../../UtilComps/ClosePopupBtn";

const CreateAssignmentModal = ({
  shouldShowFunc,
  api,
  fetchAssignments,
  showSyncReminder,
  setShowSyncReminder,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showSelectedLessons, setShowSelectedLessons] = useState(false);
  const [showSelectedLearners, setShowSelectedLearners] = useState(false);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [learners, setLearners] = useState({});
  const [selectedLearners, setSelectedLearners] = useState([]);
  const [newName, setNewName] = useState("");
  const [newStartTime, setNewStartTime] = useState(null);
  const [newEndTime, setNewEndTime] = useState(null);
  const [dateErr, setDateErr] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    header: "",
    text: "",
  });
  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [numOfSelectedLessons, setNumOfSelectedLessons] = useState(0);
  const [pastDateRangeError, setPastDateRangeError] = useState(false);

  const countNumOfSelectedLessons = () => {
    let count = 0;
    for (let i = 0; i < selectedLessons.length; i++) {
      if (selectedLessons[i].tutorial.selected) count++;
      if (selectedLessons[i].assessment.selected) count++;
    }
    setNumOfSelectedLessons(count);
  };

  const getSelectedLessonIds = () => {
    let ids = [];
    for (let i = 0; i < selectedLessons.length; i++) {
      if (selectedLessons[i].tutorial.selected) {
        ids = [...ids, selectedLessons[i].tutorial.lesson_id];
      }
      if (selectedLessons[i].assessment.selected) {
        ids = [...ids, selectedLessons[i].assessment.lesson_id];
      }
    }
    return ids;
  };

  useEffect(() => {
    countNumOfSelectedLessons();
  }, [currentPage]);

  const postAssignment = () => {
    let postData = {
      lesson_ids: getSelectedLessonIds(),
      learner_ids: selectedLearners.map((value) => value.id),
      name: newName,
      start_time: moment(newStartTime).format("YYYY-MM-DD") + "T00:00:00.000Z",
      end_time:
        newEndTime == null
          ? newEndTime
          : moment(newEndTime).format("YYYY-MM-DD") + "T00:00:00.000Z",
    };

    api
      .postAssignment(postData)
      .then((res) => {
        fetchAssignments();
        setShowSyncReminder(true);
        shouldShowFunc(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // determines if we can move onto the next screen
  const checkIfContinueBtnDisabled = () => {
    if (
      (selectedLessons.length !== 0 && currentPage === 0) ||
      (selectedLearners.length !== 0 && currentPage === 1) ||
      (newStartTime &&
        newName &&
        !pastDateRangeError &&
        !dateErr &&
        currentPage === 2)
    ) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <div className='pdf-upload'>
        <div className='create_assignment_wrapper'>
          <div className='common_border'>
            <div className='common_heading'>
              <p>Create New Assignment</p>
              <ClosePopupBtn closePopupFunc={shouldShowFunc} />
            </div>

            {showSelectedLessons && (
              <SelectedLessonsPopup
                shouldShowFunc={setShowSelectedLessons}
                lessons={lessons}
                setLessons={setLessons}
                selectedLessons={selectedLessons}
                setSelectedLessons={setSelectedLessons}
                setPopupInfo={setPopupInfo}
                setShowPopupInfo={setShowPopupInfo}
              />
            )}

            {showSelectedLearners && (
              <SelectedLearnersPopup
                shouldShowFunc={setShowSelectedLearners}
                learners={learners}
                setLearners={setLearners}
                selectedLearners={selectedLearners}
                setSelectedLearners={setSelectedLearners}
                numOfSelectedLessons={numOfSelectedLessons}
              />
            )}

            {showPopupInfo && (
              <InfoPopup
                popupInfo={popupInfo}
                setShowPopupInfo={setShowPopupInfo}
              />
            )}

            {!showSyncReminder && (
              <div className='common_dashboard_bg create_assignment_content_container'>
                <div className='row' style={{height: "100%"}}>
                  <div className='col-12' style={{maxHeight: "100%"}}>
                    <div id='create_assignment_grid_container'>
                      <CirclePageIndicators currentPage={currentPage} />

                      <div id='create_assignment_page_content_container'>
                        <CreateAssignmentStepOne
                          display={currentPage === 0 ? "flex" : "none"}
                          lessons={lessons}
                          setLessons={setLessons}
                          selectedLessons={selectedLessons}
                          setSelectedLessons={setSelectedLessons}
                          setShowSelectedLessons={setShowSelectedLessons}
                          currentPage={currentPage}
                          setPopupInfo={setPopupInfo}
                          setShowPopupInfo={setShowPopupInfo}
                        />
                        <CreateAssignmentStepTwo
                          display={currentPage === 1 ? "flex" : "none"}
                          lessons={lessons}
                          setLessons={setLessons}
                          selectedLessons={selectedLessons}
                          setSelectedLessons={setSelectedLessons}
                          setShowSelectedLearners={setShowSelectedLearners}
                          learners={learners}
                          setLearners={setLearners}
                          selectedLearners={selectedLearners}
                          setSelectedLearners={setSelectedLearners}
                          currentPage={currentPage}
                          setPopupInfo={setPopupInfo}
                          setShowPopupInfo={setShowPopupInfo}
                        />
                        <CreateAssignmentStepThree
                          display={currentPage === 2 ? "flex" : "none"}
                          newName={newName}
                          setNewName={setNewName}
                          newStartTime={newStartTime}
                          setNewStartTime={setNewStartTime}
                          newEndTime={newEndTime}
                          setNewEndTime={setNewEndTime}
                          setDateErr={setDateErr}
                          pastDateRangeError={pastDateRangeError}
                          setPastDateRangeError={setPastDateRangeError}
                        />
                        <CreateAssignmentStepFour
                          display={currentPage === 3 ? "flex" : "none"}
                          newName={newName}
                          newStartTime={newStartTime}
                          newEndTime={newEndTime}
                          learners={learners}
                          selectedLearners={selectedLearners}
                          selectedLessons={selectedLessons}
                          numOfSelectedLessons={numOfSelectedLessons}
                        />
                      </div>

                      <div id='create_assignment_footer'>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gridGap: "10px",
                          }}
                        >
                          {currentPage !== 0 && (
                            <button
                              onClick={() => setCurrentPage(currentPage - 1)}
                              className='btn btn-sm btn-primary'
                            >
                              Previous
                            </button>
                          )}
                          {currentPage !== 3 && (
                            <button
                              onClick={() => setCurrentPage(currentPage + 1)}
                              className='btn btn-sm btn-primary'
                              disabled={checkIfContinueBtnDisabled()}
                            >
                              Submit & Continue
                            </button>
                          )}
                          {currentPage === 3 && (
                            <button
                              onClick={() => {
                                postAssignment();
                              }}
                              className='btn btn-sm btn-success'
                            >
                              Create Assignment
                            </button>
                          )}
                          <button
                            className='btn btn-sm btn-danger'
                            onClick={() => shouldShowFunc(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withAPI(CreateAssignmentModal));
